import { UspInfoBlock } from "@@/products/bookables/booking-wizard/bookable-details/usp-info-block";
import { Conditional } from "@@/shared/conditional";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { Product, Provider } from "@towni/common";
import { _GenericBooking_ModalButtonSection } from "./_generic-booking_modal-button-section";

type Props = {
    product: Product;
    provider: Provider;
    disabled?: boolean;
};

const _ColumnTwo = (props: Props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const oneColumn = usePageBreakpoint({ when: ["📱", "MS"] });
    const { product, provider } = props;

    return (
        <FlexColumn fillParentWidth>
            <Conditional
                when={!oneColumn}
                render={() => (
                    <>
                        <_GenericBooking_ModalButtonSection
                            product={product}
                            provider={provider}
                            disabled={props.disabled}
                        />
                        <VerticalDivider M />
                        <HorizontalLine />
                    </>
                )}
            />
            <VerticalDivider M />
            <UspInfoBlock />
        </FlexColumn>
    );
};

export { _ColumnTwo };
