import { useDiscountCodeCheck } from "@@/backoffice/for-providers/discounts/discount-code/hooks/use-discount-code-check";
import { useModal } from "@@/modals/use-modal";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { HorizontalDivider, HorizontalLine } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { css, useTheme } from "@emotion/react";
import { faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { RemSize, isOrderItemDiscount_V2, translation } from "@towni/common";
import { useEffect } from "react";
import { useCartContext } from "../multi-carts/cart.context";
import { AddDiscountModal } from "./add-discount-in-cart-modal";
import { calculateDiscountRows } from "./discount-service";

const DiscountInCartRow = () => {
    const [{ show }, modalId] = useModal("discount_code_modal__");

    const theme = useTheme();
    const { cart, removeItemFromCart, addItemToCart } = useCartContext(
        context => ({
            cart: context.cart,
            removeItemFromCart: context.removeItemFromCart,
            addItemToCart: context.addItemToCart,
        }),
    );

    const [provider, _providerQuery] = useProvider(cart.providerId);
    const discounts = cart.orderItems.filter(isOrderItemDiscount_V2);

    const codeUsed = cart.orderItems.find(isOrderItemDiscount_V2);
    const [discountCode, _discountCodeQuery] = useDiscountCodeCheck(
        provider?._id,
        codeUsed?.code,
    );

    useEffect(() => {
        if (!provider || !discountCode) return;
        // console.log("DISCOUNT IN CART ROW _ CART EFFECT _ HIDING CART");

        const items = calculateDiscountRows(
            provider._id,
            cart.orderItems.filter(oi => !isOrderItemDiscount_V2(oi)),
            discountCode,
        );

        const existing = cart.orderItems.filter(
            oi =>
                isOrderItemDiscount_V2(oi) &&
                oi.discountCodeId === discountCode._id,
        );

        const matches = existing.every(
            current =>
                isOrderItemDiscount_V2(current) &&
                items.some(
                    oi =>
                        oi.discountCodeId === current.discountCodeId &&
                        oi.price.amountIncludingVat ===
                            current.price.amountIncludingVat,
                ),
        );
        if (!matches) {
            existing.map(current => removeItemFromCart(current._id));
            items.map(current => addItemToCart(current));
        }
    }, [provider, discountCode, cart]);

    return (
        <>
            <FlexColumn crossAxis="flex-start" fillParentWidth>
                <ForEach
                    itemOf={discounts}
                    getKey={oi => oi._id}
                    divider={<HorizontalLine fillParentWidth />}>
                    {code => {
                        return (
                            <>
                                <FlexRow
                                    fillParentWidth
                                    padding={{ leftRight: 20, topBottom: 15 }}>
                                    <FlexRow fillParentWidth>
                                        <TextBox
                                            text={translation({
                                                sv: "Rabatt tillagd:",
                                                en: "Discount added:",
                                            })}
                                            size="S"
                                            color={theme.colors.danger}
                                        />
                                        <HorizontalDivider XS />
                                        <TextBox
                                            text={code.code}
                                            size="S"
                                            color={theme.colors.black.light60}
                                            shrink={1}
                                            //lineClamp={1}
                                            styling={css`
                                                // text-overflow: ellipsis;
                                                // white-space: nowrap;
                                                overflow: hidden;
                                                display: block;
                                                overflow-wrap: "break-words";
                                                word-break: "break-words";
                                            `}
                                        />
                                    </FlexRow>
                                    <FlexRow
                                        mainAxis="flex-end"
                                        css={{
                                            marginRight: -5,
                                        }}
                                        tag="actions">
                                        <Icon
                                            icon={faTrashAlt}
                                            cursor="pointer"
                                            onClick={() => {
                                                const existing =
                                                    cart.orderItems.find(
                                                        oi =>
                                                            isOrderItemDiscount_V2(
                                                                oi,
                                                            ) &&
                                                            oi.discountCodeId ===
                                                                code.discountCodeId,
                                                    );

                                                if (existing)
                                                    removeItemFromCart(
                                                        existing._id,
                                                    );
                                            }}
                                            color={theme.colors.black.light60}
                                            size={0.9 as RemSize}
                                        />
                                    </FlexRow>
                                </FlexRow>
                                <HorizontalLine fillParentWidth />
                            </>
                        );
                    }}
                </ForEach>
            </FlexColumn>

            <FlexRow
                onClick={show}
                fillParentWidth
                padding={{ leftRight: 20, topBottom: 15 }}>
                <TextBox
                    text={translation({
                        sv: "+ Använd rabattkod",
                        en: "+ Use discount code",
                    })}
                    size="S"
                    color={theme.colors.black.light60}
                />
            </FlexRow>
            <HorizontalLine />
            <AddDiscountModal modalId={modalId} />
        </>
    );
};

export { DiscountInCartRow };
