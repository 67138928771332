import { useHomePath } from "@@/backoffice/for-providers/settings/settings/use-home-path";
import { useWizardDispatch } from "@@/pages/wizard-context";
import { ContentBlockView } from "@@/products/bookables/content-block-view";
import { useGiftCardPurchaseContext } from "@@/products/gift-card-purchase.context";
import { useCalculatedProviderStatus } from "@@/providers/calculate-provider-status";
import { CartProviderCheckModal } from "@@/providers/cart-provider-check-modal";
import { ProviderPauseCheckModal } from "@@/providers/provider-pause-check-modal";
import { browserLogger } from "@@/settings";
import { FadedBottomContainer } from "@@/shared/buttons/faded-bottom-container";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { HorizontalSectionDivider } from "@@/shared/dividers/horizontal-section-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Icon } from "@@/shared/icons/icon";
import { isRunningAsPwa } from "@@/shared/is-running-as-pwa";
import { Label } from "@@/shared/label";
import { Picture } from "@@/shared/pictures/picture";
import { ImageShimmer, TextShimmer } from "@@/shared/pictures/shimmers";
import { SlideShow } from "@@/shared/pictures/slide-show";
import { CircleSpinner } from "@@/shared/spinners/circle-spinner";
import { TextBox } from "@@/shared/text";
import { LinkTo } from "@@/shared/text/link-to";
import { ProviderLogoLinkCircle } from "@@/store-fronts/towni/sections/providers/provider-logo-link-circle";
import { linkCss } from "@@/styles/themes/default.theme";
import { usePriceFormatter } from "@@/translations/use-price-formatter";
import { useTranslate } from "@@/translations/use-translate";
import { css, useTheme } from "@emotion/react";
import {
    faClipboardCheck,
    faGift,
    faHeart,
} from "@fortawesome/pro-solid-svg-icons";
import {
    GiftCard_Product,
    MILLISECONDS,
    Provider,
    RemSize,
    SECONDS,
    isAlfredItemActive,
    isGiftCard,
    isNotUndefined,
    isUndefined,
    joinTranslatables,
    priceForHumansFactory,
    providerStatusTypeMap,
    translation,
} from "@towni/common";
import * as React from "react";
import { GiftCardPricePicker } from "./gift-card-price-picker";
import { AddGiftCardToCartWithQuantityButton } from "./gift-card.add-gift-card-to-cart-button";

type Props = {
    readonly product: GiftCard_Product | undefined;
    readonly provider: Provider | undefined;
    readonly hideLinkToShop: boolean;
};

const GiftCardPurchase = (props: Props) => {
    const { provider, product } = props;
    const theme = useTheme();
    const translate = useTranslate();
    const wizardActions = useWizardDispatch();
    const purchaseContext = useGiftCardPurchaseContext();
    const providerStatus = useCalculatedProviderStatus(provider);
    const priceFormat = usePriceFormatter();

    const disabled =
        providerStatus === providerStatusTypeMap.PAUSED ||
        (product?.status && !isAlfredItemActive(product?.status));

    const isHidden = providerStatus === providerStatusTypeMap.HIDDEN;

    const slideContent = product?.meta.images?.map(imageRef => {
        return (
            <Picture
                key={typeof imageRef === "string" ? imageRef : imageRef._id}
                imageRef={imageRef}
                width="100%"
                height="100%"
                minHeight="250px"
                imageOptions={{
                    imageWidth: theme.sizes.imageSizes.large,
                    disabled,
                    fit: true,
                }}
                fade={0.1 as SECONDS}
                loadingSpinner={
                    <CircleSpinner size={5} delayInMs={300 as MILLISECONDS} />
                }
            />
        );
    });

    React.useEffect(
        function checkIfAddToCartButtonShouldBeDisabled() {
            const addToCartDisabled =
                disabled || !purchaseContext.selectedPrice;

            if (addToCartDisabled !== purchaseContext.addToCartDisabled) {
                // Set button as disabled
                purchaseContext.setAddToCartDisabled(addToCartDisabled);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            purchaseContext.selectedPrice,
            purchaseContext.addToCartDisabled,
            purchaseContext.setAddToCartDisabled,
            disabled,
        ],
    );

    const contentBlocks = product?.skus[0].acquire.contentBlocks ?? [];
    const [, cartItemGenerationErrors] = purchaseContext.getOrderItems();

    cartItemGenerationErrors.forEach(error => {
        browserLogger.error(error);
    });

    const providerHomePath = useHomePath(provider);
    const hasPriceOptions =
        !!product?.priceSettings &&
        (product.priceSettings?.options.length > 1 ||
            !!product.priceSettings.freePricingEnabled);
    React.useEffect(() => {
        if (!product) {
            // there can be no price
            return;
        }
        if (!hasPriceOptions || !product.priceSettings) {
            // Gift card only has one price option
            purchaseContext.setSelectedPrice(product.price);
            return;
        }

        // Gift card has multiple price options
        // if there is a default value set it as preselected price in context
        if (product.priceSettings.defaultAmountInclVat) {
            purchaseContext.setSelectedPrice(
                priceForHumansFactory({
                    amountIncludingVat:
                        product.priceSettings.defaultAmountInclVat,
                    vat: product.priceSettings.vat,
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasPriceOptions, product, purchaseContext.setSelectedPrice]);

    return (
        <>
            <FlexColumn
                grow={1}
                shrink={0}
                fillParent
                mainAxis="space-between"
                crossAxis="stretch">
                <FlexColumn css={{ flexGrow: 1, flexShrink: 0 }}>
                    {slideContent && slideContent.length > 1 && (
                        // todo: vet inte hur height 110vw funkar med slideshow
                        // todo: stod 40% tidigare
                        <SlideShow
                            styling={css`
                                width: 100%;
                                height: 110vw;
                                min-height: 250px;
                                max-height: 500px;
                                flex-shrink: 0;
                            `}
                            slideContents={slideContent}
                        />
                    )}
                    {slideContent && slideContent.length === 1 && (
                        <div
                            css={{
                                width: "100%",
                                height: "110vw",
                                minHeight: 250,
                                maxHeight: 500,
                                flexShrink: 0,
                            }}>
                            {slideContent[0]}
                            <div
                                css={{
                                    position: "absolute",
                                    marginTop: -90,
                                    left: 20,
                                    zIndex: 2,
                                }}>
                                <ProviderLogoLinkCircle
                                    provider={props.provider}
                                    hideTitle
                                    size="3XS"
                                    disableLink
                                />
                            </div>
                        </div>
                    )}
                    {(isUndefined(slideContent) && (
                        <ImageShimmer height="110vw" maxHeight="500px" />
                    )) ||
                        null}
                    {slideContent && slideContent.length <= 0 && (
                        <VerticalDivider size={3.5} />
                    )}
                    <VerticalDivider M />
                    {(isUndefined(product) && (
                        <TextShimmer rowHeight="22px" rows={1} />
                    )) ||
                        null}
                    <FlexColumn fillParentWidth padding={{ leftRight: 20 }}>
                        {product?.meta.kicker && isGiftCard(product) ? (
                            <>
                                <VerticalDivider M />
                                <TextBox
                                    case={"UPPERCASE"}
                                    color={theme.colors.primary}
                                    text={product.meta.kicker}
                                    size={"XXS"}
                                    weight={"900"}
                                />
                            </>
                        ) : null}
                        <FlexRow mainAxis="space-between">
                            <TextBox
                                size={2}
                                shrink={1}
                                weight="900"
                                text={product?.meta.title}
                                userSelect="text"
                            />
                            {disabled ? (
                                <Label
                                    text={translation({
                                        sv: "Ej tillgänglig",
                                        en: "Not available",
                                    })}
                                    colorTheme="YELLOW"
                                    size="L"
                                />
                            ) : isHidden ? (
                                <Label
                                    text={translation({
                                        sv: "Gömd",
                                        en: "Hidden",
                                    })}
                                    colorTheme="RED"
                                    size="L"
                                />
                            ) : null}
                        </FlexRow>
                        <Conditional
                            when={!product?.meta.subtitle}
                            render={() => <VerticalDivider M />}
                        />
                    </FlexColumn>
                    <Conditional
                        when={typeof product === "undefined"}
                        render={() => <TextShimmer rows={2} />}
                    />
                    {product?.meta.subtitle ? (
                        <>
                            <VerticalDivider XXS />
                            <TextBox
                                text={product.meta.subtitle}
                                color={theme.colors.white.main.light50}
                                size="ML"
                                userSelect="text"
                                padding={{ leftRight: 20 }}
                            />

                            <VerticalDivider M />
                        </>
                    ) : null}
                    {product?.meta.description &&
                    isNaN(
                        Number(translate(product.meta.description).trim()),
                    ) ? (
                        <>
                            <HorizontalLine />
                            <VerticalDivider M />
                            <TextBox
                                text={product.meta.description}
                                color={theme.colors.white.main.light50}
                                userSelect="text"
                                size={1}
                                padding={{ leftRight: 20 }}
                                renderMarkdown
                                css={{
                                    "h1,h2,h3,h4": {
                                        color: theme.colors.white.main.light20
                                            .asString,
                                    },
                                }}
                            />
                            <VerticalDivider S />
                        </>
                    ) : null}
                    {product?.skus?.[0]?.articleNumber ? (
                        <>
                            <FlexRow padding={{ leftRight: 20 }}>
                                <TextBox
                                    text={"Artikelnr: "}
                                    color={theme.colors.white.main.light70}
                                    userSelect="text"
                                    size="XS"
                                />
                                <HorizontalDivider XS />
                                <TextBox
                                    text={product.skus[0].articleNumber}
                                    color={theme.colors.white.main.light70}
                                    userSelect="text"
                                    size="XS"
                                />
                            </FlexRow>
                            <VerticalDivider XS />
                        </>
                    ) : null}
                    <Conditional
                        when={provider && !props.hideLinkToShop}
                        render={() => {
                            if (!provider)
                                throw new Error("Provider is undefined");
                            return (
                                <>
                                    <VerticalDivider S />
                                    <FlexRow mainAxis="center">
                                        <LinkTo to={providerHomePath}>
                                            <TextBox
                                                text={translation({
                                                    sv: `Se fler produkter från ${translate(provider.meta.title)} `,
                                                    en: `See more products from ${translate(provider.meta.title)}`,
                                                })}
                                                size="XXS"
                                                weight="400"
                                                case="UPPERCASE"
                                                styling={linkCss(
                                                    theme.colors.white.main
                                                        .light70,
                                                )}
                                            />
                                        </LinkTo>
                                    </FlexRow>
                                    <VerticalDivider M />
                                </>
                            );
                        }}
                    />
                    <HorizontalSectionDivider />
                    {!disabled &&
                    isNotUndefined(product) &&
                    product.priceSettings &&
                    (product.priceSettings?.options.length > 1 ||
                        product.priceSettings.freePricingEnabled) ? (
                        <>
                            <GiftCardPricePicker
                                giftCard={product}
                                onSelect={purchaseContext.setSelectedPrice}
                            />
                            <HorizontalSectionDivider />
                        </>
                    ) : null}
                    <Conditional when={contentBlocks.length >= 0}>
                        <VerticalDivider M />
                        <FlexColumn fillParentWidth padding={{ leftRight: 20 }}>
                            <GiftCardBlock />
                            <VerticalDivider M />
                        </FlexColumn>

                        <ForEach
                            itemOf={contentBlocks}
                            beginWith={
                                <>
                                    <HorizontalLine />
                                    <VerticalDivider M />
                                </>
                            }
                            divider={
                                <>
                                    <VerticalDivider M />
                                    <HorizontalLine />
                                    <VerticalDivider M />
                                </>
                            }
                            getKey={contentBlock => contentBlock._id}
                            endWith={<VerticalDivider M />}>
                            {(item, index) => {
                                return (
                                    <FlexColumn
                                        key={item._id}
                                        fillParentWidth
                                        padding={{ leftRight: 20 }}>
                                        <ContentBlockView
                                            key={item._id}
                                            contentBlock={item}
                                            index={index}
                                        />
                                    </FlexColumn>
                                );
                            }}
                        </ForEach>
                    </Conditional>
                </FlexColumn>
                <Conditional
                    when={isAlfredItemActive(props.product?.status)}
                    render={() => (
                        <FadedBottomContainer position="sticky">
                            <AddGiftCardToCartWithQuantityButton
                                afterAddNavigation={() => {
                                    wizardActions.goForward();
                                }}
                                addButtonText={
                                    <FlexRow
                                        mainAxis="space-between"
                                        crossAxis="center">
                                        <TextBox
                                            text={joinTranslatables([
                                                translation({
                                                    sv: "Köp",
                                                    en: "Buy",
                                                }),
                                                ...(!purchaseContext.selectedPrice
                                                    ? []
                                                    : [
                                                          `  ${priceFormat(purchaseContext.selectedPrice)}`,
                                                      ]),
                                            ])}
                                            weight="700"
                                        />
                                    </FlexRow>
                                }
                                clearBeforeAdding={true}
                                padding={
                                    isRunningAsPwa
                                        ? {
                                              top: 15,
                                              bottom: 25,
                                              leftRight: 20,
                                          }
                                        : { all: 20 }
                                }
                            />
                        </FadedBottomContainer>
                    )}
                />
            </FlexColumn>
            <CartProviderCheckModal providerId={product?.providerId} />
            <ProviderPauseCheckModal providerId={product?.providerId} />
        </>
    );
};

const GiftCardBlock = () => {
    const theme = useTheme();
    return (
        <FlexColumn>
            <FlexRow crossAxis="flex-start">
                <FlexColumn width="28px" crossAxis="flex-start">
                    <Icon
                        icon={faClipboardCheck}
                        color={theme.colors.primary}
                        size="M"
                        padding={{ top: 2 }}
                    />
                </FlexColumn>
                <HorizontalDivider />
                <FlexColumn width="100%">
                    <FlexRow>
                        <TextBox
                            text={translation({
                                sv: "Levereras direkt",
                                en: "Delivered instantly",
                            })}
                            userSelect="text"
                            size={1}
                            weight={"700"}
                        />
                    </FlexRow>
                    <VerticalDivider XXS />
                    <TextBox
                        text={translation({
                            sv: "Ni får digital leverans direkt via e-post och sms. Done and done!",
                            en: "You get digital delivery directly via email and sms. Done and done!",
                        })}
                        userSelect="text"
                        size="S"
                        weight="400"
                        color={theme.colors.black.light30}
                        css={{
                            lineHeight: 1.3,
                        }}
                    />
                    <VerticalDivider M />
                </FlexColumn>
            </FlexRow>
            <FlexRow crossAxis="flex-start">
                <FlexColumn width="28px" crossAxis="flex-start">
                    <Icon
                        icon={faGift}
                        color={theme.colors.primary}
                        size="M"
                        padding={{ top: 2 }}
                    />
                </FlexColumn>
                <HorizontalDivider />
                <FlexColumn width="100%">
                    <FlexRow>
                        <TextBox
                            text={translation({
                                sv: "Alltid med - tappas inte bort",
                                en: "Always with you - never lost",
                            })}
                            userSelect="text"
                            size={1}
                            weight={"700"}
                        />
                    </FlexRow>
                    <VerticalDivider XXS />
                    <TextBox
                        text={translation({
                            sv: "Ingen papperslapp som tappas bort. I mobilen och molnet så är det alltid med.",
                            en: "No paper note that gets lost. In the phone and the cloud, it's always with you.",
                        })}
                        userSelect="text"
                        size="S"
                        weight="400"
                        color={theme.colors.black.light30}
                        css={{
                            lineHeight: 1.3,
                        }}
                    />
                    <VerticalDivider M />
                </FlexColumn>
            </FlexRow>
            <FlexRow crossAxis="flex-start">
                <FlexColumn width="28px" crossAxis="flex-start">
                    <Icon
                        icon={faHeart}
                        color={theme.colors.primary}
                        size={1 as RemSize}
                        padding={{ top: 2 }}
                    />
                </FlexColumn>
                <HorizontalDivider />
                <FlexColumn width="100%">
                    <FlexRow>
                        <TextBox
                            text={translation({
                                sv: "Enkelt att dela eller ge bort",
                                en: "Easy to share or give away",
                            })}
                            userSelect="text"
                            size={1}
                            weight={"700"}
                        />
                    </FlexRow>
                    <VerticalDivider XXS />
                    <TextBox
                        text={translation({
                            sv: "Ni kan direkt dela presentkortet och den som tar emot får en fin länk direkt till gåvan. Går också att skriva ut (och slå in med knorr!)",
                            en: "You can directly share the gift card and the recipient gets a nice link directly to the gift. Can also be printed (and wrapped with a bow!)",
                        })}
                        userSelect="text"
                        size="S"
                        weight="400"
                        color={theme.colors.black.light30}
                        css={{
                            lineHeight: 1.3,
                        }}
                    />
                </FlexColumn>
            </FlexRow>
        </FlexColumn>
    );
};

export { GiftCardPurchase };
