import { useProductLegacy } from "@@/products/state/queries/product/use-product";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { PaddingProps } from "@@/shared/padding";
import { SquareImage } from "@@/shared/pictures/image";
import { TextBox } from "@@/shared/text/text-box";
import { OrderItemAccommodation_V2, RemSize, translation } from "@towni/common";

type Props = {
    orderItem: OrderItemAccommodation_V2;
    padding?: PaddingProps;
};

const AccommodationReceipt_OrderDetailsHeader = (props: Props) => {
    const { orderItem } = props;
    const [product] = useProductLegacy(orderItem.productId);
    const image = product?.meta.images[0];
    return (
        <FlexRow
            padding={props.padding ?? { all: 20 }}
            css={{ label: "bookable_receipt_order_details_header" }}>
            <div css={{ width: 80, height: 80 }}>
                <SquareImage
                    imageSource={image}
                    size={5 as RemSize}
                    radius={10}
                    title={
                        product?.meta.title ??
                        translation({ sv: "Produkt", en: "Product" })
                    }
                />
            </div>
            <HorizontalDivider S />
            <FlexColumn>
                <TextBox weight="900" size="L" text={orderItem.meta.title} />
                <TextBox text={orderItem.meta.kicker} size="S" weight="400" />
            </FlexColumn>
        </FlexRow>
    );
};

export { AccommodationReceipt_OrderDetailsHeader };
