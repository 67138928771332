import { Captcha } from "@@/authentication/captcha";
import { ifApiErrorDontThrow } from "@@/authentication/phone/steps/if-api-error-dont-throw";
import { useWizardDispatch, useWizardState } from "@@/pages/wizard-context";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { HorizontalLine, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { Spacer } from "@@/shared/spacer";
import { TextBox } from "@@/shared/text";
import { ModalTitle } from "@@/shared/text/modal-title";
import { faChevronLeft, faTimes } from "@fortawesome/pro-light-svg-icons";
import { translation } from "@towni/common";
import { usePhoneSignInOrUpState } from "../phone-sign-in-or-sign-in-context";
import { usePhoneSignInInitiateCommand } from "../use-phone-sign-in-initiate-command";
import { useCaptchaContext } from "./captcha.context";

/**
 *
 *
 * @param {Props} _props
 * @return {*}
 */
const CaptchaWizardStep = () => {
    const wizardState = useWizardState();
    const wizardDispatch = useWizardDispatch();
    const captchaValue = useCaptchaContext(context => context.value);
    const setCaptchaValue = useCaptchaContext(context => context.setValue);

    const state = usePhoneSignInOrUpState();
    const phoneSignInInit = usePhoneSignInInitiateCommand();

    const initiatePhoneAuthentication = async () => {
        await phoneSignInInit
            .mutateAsync({
                phoneNumber: state.data.phoneNumber,
                signUpId: state.data._id,
                captchaValue,
            })
            .catch(ifApiErrorDontThrow);
    };

    return (
        <FlexColumn fillParent overflow="hidden">
            <FlexRow
                mainAxis="space-between"
                crossAxis="center"
                padding={{ leftRight: 20, topBottom: 20 }}>
                <Spacer basis={0.2} shrink={0}>
                    <Icon
                        onClick={
                            wizardState.isFirst
                                ? wizardDispatch.cancel
                                : () => wizardDispatch.goBack()
                        }
                        icon={!wizardState.isFirst ? faChevronLeft : faTimes}
                        size="L"
                        cursor="pointer"
                    />
                </Spacer>
                <FlexColumn basis={0.6} crossAxis="center">
                    <FlexRow crossAxis="center">
                        <ModalTitle
                            text={translation({
                                sv: "Logga in/",
                                en: "Sign in/",
                            })}
                        />
                        <ModalTitle
                            text={translation({
                                sv: "Registrering",
                                en: "Sign up",
                            })}
                        />
                    </FlexRow>
                </FlexColumn>
                <Spacer basis={0.2} shrink={0} />
            </FlexRow>
            <HorizontalLine />
            <FlexColumn
                css={{
                    padding: 20,
                    paddingTop: 40,
                }}
                overflowY="scroll"
                crossAxis="center">
                <Captcha onCaptchaValueSet={setCaptchaValue} />
                <VerticalDivider ML />
                <ButtonPrimary
                    dataTestId="confirm-humanity-button"
                    fillParentWidth
                    onClick={initiatePhoneAuthentication}
                    disabled={!state.data.phoneNumber || !captchaValue}
                    spin={phoneSignInInit.isPending}
                    padding={{ all: 15 }}>
                    <TextBox
                        text={translation({
                            sv: "Riktig människa ✌️",
                            en: "Real human ✌️",
                        })}
                        weight="700"
                    />
                </ButtonPrimary>
            </FlexColumn>
        </FlexColumn>
    );
};

export { CaptchaWizardStep };
