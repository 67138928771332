class ProviderNotFoundError extends Error {
    public readonly name = "ProviderNotFoundError" as const;
    constructor(
        public providerId: string,
        public loadingError: unknown = null,
    ) {
        super(
            `Provider not found: ${providerId}${
                loadingError
                    ? `; error: ${
                          loadingError instanceof Error
                              ? loadingError.message
                              : typeof loadingError === "string"
                                ? loadingError
                                : "Loading Error"
                      }`
                    : ""
            }`,
        );
    }
}

export { ProviderNotFoundError };
