import { useBookingAccommodationContext } from "@@/products/accommodations/booking-accommodation-context";

import { useCheckAccommodationAvailability } from "@@/products/accommodations/hooks/use-accommodation-feed";
import { useBookableResources } from "@@/resources/resources/use-resources";
import { browserLogger } from "@@/settings";
import {
    Accommodation,
    Product,
    ResourceAvailability,
    emptyArrayOf,
    findEligibleRates,
    findGraphInGraph,
    flattenGraphKeepGroups,
    isResourceGroup,
    isTimeRange,
} from "@towni/common";
import { useMemo } from "react";

/**
 * Finds the applicable rates for the resource groups in the inventory
 * and checks if the resource group is available
 */
const useCalculatedInventory = (
    product: Product,
    accommodation: Accommodation,
) => {
    const [globalResources, _] = useBookableResources(product._id);
    const checkAvailability = useCheckAccommodationAvailability(product);
    const { quantities, feed, selectedTime } = useBookingAccommodationContext(
        state => ({
            quantities: state.quantities,
            selectedTime: state.timeRange,
            feed: state.feed,
        }),
    );

    const availabilityResult = useMemo(() => {
        const timeRange = isTimeRange(selectedTime) ? selectedTime : undefined;

        const allRates = accommodation.rates;

        const allCalculatedRates = isTimeRange(selectedTime)
            ? findEligibleRates(accommodation, feed, selectedTime, [quantities])
            : undefined;

        const numberOfAccommodations = 1;

        const result = accommodation.inventory.flatMap(i => {
            // Find the corresponding global resource for the inventory item
            const resourceGraph = globalResources.find(
                globalResource =>
                    globalResource.resourceGroup._id === i.groupId,
            );
            if (!resourceGraph) return [];

            // Flatten the resource graph to get all children and groups
            const flattenedResources = flattenGraphKeepGroups(resourceGraph);
            const resourceGroups = flattenedResources.filter(isResourceGroup);

            // Filter rates that apply to any of the resources in the graph
            const applicableRates =
                allCalculatedRates?.included.filter(rate => {
                    const rateAppliesTo = rate.rateWithPrices.appliesTo;
                    return rateAppliesTo.some(
                        appliesToId =>
                            !!findGraphInGraph(resourceGraph, appliesToId),
                    );
                }) ?? [];

            // Process each resource group to determine its availability and associated rates
            const details = resourceGroups.flatMap(resourceGroup => {
                // Filter children that are part of this resource group
                const groupChildren = flattenedResources.filter(resource =>
                    resourceGroup.resources.some(
                        groupResource => groupResource._id === resource._id,
                    ),
                );

                // If any of the children are resource groups themselves, skip this group
                if (groupChildren.some(isResourceGroup)) return [];

                // Check availability for this resource group if a time range is provided
                const availabilityResult = timeRange
                    ? checkAvailability({
                          timeRange,
                          quantity: numberOfAccommodations,
                          resourceIds: [resourceGroup._id],
                          quantities,
                      })
                    : undefined;

                const applicableRatesForGroup = applicableRates.filter(rate => {
                    const rateAppliesTo = rate.rateWithPrices.appliesTo;
                    return rateAppliesTo.some(
                        appliesToId => appliesToId === resourceGroup._id,
                    );
                });

                //Checks if there are any rates that apply to this resource group but not for the current time range
                const hasRates = allRates.some(rate => {
                    const rateAppliesTo = rate.appliesTo;
                    return rateAppliesTo.some(
                        appliesToId => appliesToId === resourceGroup._id,
                    );
                });
                browserLogger.info({ hasRates, availabilityResult });

                //Remove the resource group if there are no applicable rates
                if (applicableRatesForGroup.length === 0 && !hasRates)
                    return [];

                // Structure the detailed information for this resource group
                return {
                    inventory: i,
                    resourceGroup,
                    resources: groupChildren,
                    isAvailable:
                        !!availabilityResult?.resourcesAvailability.some(
                            availability =>
                                availability.availableSubResources.length > 0 &&
                                availability.available,
                        ),
                    availability:
                        availabilityResult?.resourcesAvailability ??
                        emptyArrayOf<ResourceAvailability>(),
                    rates: applicableRatesForGroup,
                };
            });

            if (!details.length) return [];

            return {
                inventory: i,
                inventoryDetails: details,
            };
        });
        return result;
    }, [
        accommodation,
        checkAvailability,
        feed,
        globalResources,
        quantities,
        selectedTime,
    ]);

    return availabilityResult;
};

export { useCalculatedInventory };
