import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { ForEach } from "@@/shared/for-each";
import { MultiContextProvider } from "@@/shared/multi-provider";
import {
    DateRange,
    getMonthsAndYearsOf,
    IsoAndUnixTimestamp,
    IsoTimestamp,
} from "@towni/common";
import { useMemo } from "react";
import { FullDateRangeProvider } from "./date-range.context";
import { DateSelectabilityMapContextProvider } from "./date-selectability-map.context";
import { DateSelectionVt_Month } from "./date-selection-vt-month";
import { DateToggleContextProvider } from "./date-toggle.context";
import { SelectableDate } from "./selectable-date";
import { SelectableDatesUpdater } from "./selectable-dates.updater";
import { SelectedDateRangeContextProvider } from "./selected-date-range.context";
import { SelectedDateRangeUpdater } from "./selected-date-range.updater";
import { TimeZoneContextProvider } from "./time-zone.context";

type Props = {
    /** The full date range that the user can select dates from */
    readonly dateRange: DateRange;
    /** Optional current selected date range from the parents view */
    readonly selectedDateRange?: DateRange;
    /** A function that will be called when a date range is selected or unselected. NOTE! Wrap it in a `useCallback` before providing it to the component. */
    readonly onSelect: (dateRange: DateRange | undefined) => void;
    /** time zone for the dates presented, defaults to `Europe/Stockholm` when `undefined` */
    readonly timeZone: string | undefined;
    /** A function that verifies that a date range is valid and can be selected. NOTE! Wrap it in a `useCallback` before providing it to the component. */
    readonly validation: (dateRange: DateRange) => boolean;
    /** Meta data about all selectable dates, all dates are `required` to be calculated as startOfDay of the timeZone given to this component */
    readonly selectableDates: Map<IsoTimestamp, SelectableDate>;
    /** Defaults to today. When overrode, dates before the given date (instead of today) are counted as passed and to late to select */
    readonly datesArePassedBefore?: IsoAndUnixTimestamp;
};

const DateSelectionVt_v2 = (props: Props) => {
    const timeZone = props.timeZone ?? "Europe/Stockholm";
    const options = useMemo(() => ({ readonly: false }), []);
    const months = useMemo(
        () => getMonthsAndYearsOf(props.dateRange, timeZone),
        [props.dateRange, timeZone],
    );

    return (
        <MultiContextProvider
            providers={[
                [TimeZoneContextProvider, { timeZone }],
                [FullDateRangeProvider, { initialValue: props.dateRange }],
                [
                    DateSelectabilityMapContextProvider,
                    { initialValue: props.selectableDates },
                ],
                [
                    SelectedDateRangeContextProvider,
                    { initialValue: props.selectedDateRange },
                ],
                [DateToggleContextProvider, { onSelect: props.onSelect }],
            ]}>
            {/* Handle context updates from parent components... */}
            <SelectedDateRangeUpdater
                selection={props.selectedDateRange}
                timeZone={timeZone}
            />
            {/* Handle context updates from parent components... */}
            <SelectableDatesUpdater selectableDates={props.selectableDates} />
            {/* Loop through all months in the full date range and render them in the UI */}
            <ForEach
                itemOf={months}
                getKey={item => `${item.year}${item.month}`}
                divider={<VerticalDivider XL />}>
                {({ year, month }) => (
                    <DateSelectionVt_Month
                        key={`${year}${month}`}
                        year={year}
                        month={month}
                        timeZone={props.timeZone}
                        options={options}
                    />
                )}
            </ForEach>
            <VerticalDivider XXL />
            <VerticalDivider XXL />
        </MultiContextProvider>
    );
};

export { DateSelectionVt_v2 };
