import {
    ContextMenu,
    ContextMenuProps,
    ContextMenuWrapperProps,
    MenuComponent,
    MenuComponentParams,
} from "@@/shared/context-menu/context-menu";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { ImageV2 } from "@@/shared/pictures/image";
import { TextBox } from "@@/shared/text";
import { ContextMenuLinkTo } from "@@/shared/text/link-to";
import { useTheme } from "@emotion/react";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-regular-svg-icons";
import { acquirableTypeIcon, acquirableTypeName, Product } from "@towni/common";
import { useCallback } from "react";

const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
};

type Props = { product: Product };
const _ProductContextMenu = (props: ContextMenuProps<Props>) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme = useTheme();
    const image = props.product.meta.images[0];
    return (
        <FlexColumn
            crossAxis="stretch"
            mainAxis="center"
            onClick={stopPropagation}>
            <FlexColumn
                crossAxis="stretch"
                css={{ padding: 10, overflow: "hidden", borderRadius: 10 }}>
                {!image ? null : (
                    <div
                        css={{
                            position: "relative",
                            width: "100%",
                            height: 0,
                            paddingBottom: "100%",
                        }}>
                        <ImageV2
                            imageSource={props.product.meta.images[0]}
                            title={props.product.meta.title}
                            css={{
                                position: "absolute",
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                borderRadius: 5,
                                border: `1px solid ${theme.colors.default.border.asString}`,
                            }}
                        />
                    </div>
                )}
                <VerticalDivider S />
                <FlexRow mainAxis="center" fillParentWidth>
                    <TextBox
                        text={props.product.meta.title}
                        size={1.125}
                        weight="700"
                        lineClamp={2}
                        align="center"
                        css={{
                            width: "100%",
                            overflowX: "hidden",
                            maxWidth: 180,
                        }}
                    />
                </FlexRow>
                {image ? null : <VerticalDivider XS />}
            </FlexColumn>
            <HorizontalLine />
            <VerticalDivider S />
            <ContextMenuLinkTo
                actions={props.actions}
                target={`edit_product_${props.product._id}`}
                to={`/bo/providers/${props.product.providerId}/products/${props.product._id}`}
                css={{
                    "&:hover": {
                        textDecoration: "none",
                    },
                }}>
                <FlexRow
                    mainAxis="space-between"
                    css={{ paddingLeft: 20, paddingRight: 20 }}>
                    <TextBox
                        text={{
                            sv: "Redigera produkt",
                            en: "Edit product",
                        }}
                    />
                    <HorizontalDivider ML />
                    <Icon icon={faArrowUpRightFromSquare} />
                </FlexRow>
            </ContextMenuLinkTo>
            <VerticalDivider S />
            <HorizontalLine />
            <VerticalDivider S />
            <ContextMenuLinkTo
                actions={props.actions}
                to={`/products/${props.product._id}`}
                target={`show_product_${props.product._id}`}
                css={{
                    "&:hover": {
                        textDecoration: "none",
                    },
                }}>
                <FlexRow
                    mainAxis="space-between"
                    css={{ paddingLeft: 20, paddingRight: 20 }}>
                    <TextBox
                        text={{
                            sv: "Visa produkt",
                            en: "Show product",
                        }}
                    />
                    <HorizontalDivider ML />
                    <Icon icon={faArrowUpRightFromSquare} />
                </FlexRow>
            </ContextMenuLinkTo>
            <VerticalDivider S />
            <HorizontalLine />
            <VerticalDivider S />
            <FlexRow wrap="wrap" wrapMargin={5} css={{ paddingLeft: 15 }}>
                <FlexRow
                    css={{
                        backgroundColor: theme.colors.primary.light.asString,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: 3000,
                        color: theme.colors.primary.asString,
                    }}>
                    <TextBox
                        size={0.8}
                        text={
                            acquirableTypeName[props.product.acquirableAs]
                                .singular
                        }
                    />
                    <HorizontalDivider />
                    <Icon
                        size={0.8}
                        icon={acquirableTypeIcon[props.product.acquirableAs]}
                    />
                </FlexRow>
            </FlexRow>
            <VerticalDivider S />
        </FlexColumn>
    );
};

function useProductContextMenu(product: Product): MenuComponent;
function useProductContextMenu(product: undefined): undefined;
function useProductContextMenu(
    product: Product | undefined,
): MenuComponent | undefined {
    const contextMenu = useCallback(
        (props: MenuComponentParams) => {
            if (!product) return null;
            return <_ProductContextMenu {...props} product={product} />;
        },
        [product],
    );

    if (!product) return undefined;
    return contextMenu;
}

const ProductContextMenu = (props: Props & ContextMenuWrapperProps) => {
    const productContextMenu = useProductContextMenu(props.product);
    return (
        <ContextMenu menu={productContextMenu} css={props.className}>
            {props.children}
        </ContextMenu>
    );
};

export { ProductContextMenu, useProductContextMenu };
