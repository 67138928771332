import * as React from "react";
import { useState } from "react";
// Hook stolen from https://usehooks.com/useScript/
type ScriptLoadingStatus = "IDLE" | "LOADING" | "READY" | "ERROR";
const useScript = (src: string): ScriptLoadingStatus => {
    // Keep track of script status ("IDLE", "LOADING", "READY", "ERROR")
    const [status, setStatus] = useState<ScriptLoadingStatus>(
        src ? "LOADING" : "IDLE",
    );

    React.useEffect(
        () => {
            // Allow falsy src value if waiting on other data needed for
            // constructing the script URL passed to this hook.
            if (!src) {
                setStatus("IDLE");
                return;
            }

            // Fetch existing script element by src
            // It may have been added by another instance of this hook
            let script = document.querySelector(
                `script[src="${src}"]`,
            ) as HTMLScriptElement;

            // Store status in attribute on script
            // This can be read by other instances of this hook
            const setAttributeFromEvent = (event: { type: string }) => {
                script.setAttribute(
                    "data-status",
                    event.type === "load" ? "READY" : "ERROR",
                );
            };

            if (!script) {
                // Create script
                script = document.createElement("script");
                script.src = src;
                script.async = true;
                script.setAttribute("data-status", "LOADING");
                // Add script to document body
                document.body.appendChild(script);

                script.addEventListener("load", setAttributeFromEvent);
                script.addEventListener("error", setAttributeFromEvent);
            } else {
                // Grab existing script status from attribute and set to state.

                setStatus(
                    (script.getAttribute("data-status") ??
                        "READY") as ScriptLoadingStatus,
                );
            }

            // Script event handler to update status in state
            // Note: Even if the script already exists we still need to add
            // event handlers to update the state for *this* hook instance.
            const setStateFromEvent = (event: { type: string }) => {
                setStatus(event.type === "load" ? "READY" : "ERROR");
            };

            // Add event listeners
            script.addEventListener("load", setStateFromEvent);
            script.addEventListener("error", setStateFromEvent);

            // Remove event listeners on cleanup
            return () => {
                if (script) {
                    script.removeEventListener("load", setStateFromEvent);
                    script.removeEventListener("error", setStateFromEvent);
                    script.removeEventListener("load", setAttributeFromEvent);
                    script.removeEventListener("error", setAttributeFromEvent);
                }
            };
        },
        [src], // Only re-run effect if script src changes
    );

    return status;
};

export { useScript };
