import { PageNotFound } from "@@/backoffice/shared/not-found-page";
import { CartPage } from "@@/carts/cart-page";
import { CheckoutContextProvider } from "@@/carts/checkout.context";
import { CartContextProvider } from "@@/carts/multi-carts/cart.context";
import { MultiCartContextProvider } from "@@/carts/multi-carts/multi-cart.context";
import { PageLoad } from "@@/pages/page-load";
import { Wizard, wizardPage } from "@@/pages/wizard";
import { GiftCardPurchaseContextProvider } from "@@/products/gift-card-purchase.context";
import { useProduct } from "@@/products/state/queries/product/use-product";
import { useBrandingContext } from "@@/routes/shared/branding-context";
import { useBrandingBackgroundCss } from "@@/routes/shared/default-branding";
import { FlexColumn } from "@@/shared/flex-containers";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { ColorThemeProvider } from "@@/styles/app-theme-provider";
import {
    GiftCard_Product,
    ProductId,
    Provider,
    isGiftCard,
    parseSafely,
    productIdZodSchema,
    translation,
} from "@towni/common";
import { useParams } from "react-router-dom";
import { GiftCardPurchaseWizardPage } from "./gift-card-purchase-wizard-page";

type Props = {
    readonly giftCardId: ProductId;
    readonly provider: Provider;
};

const GiftCardPage = (props: Props) => {
    const paramGiftCardId = useParams<{ giftCardId?: string }>().giftCardId;
    const giftCardId = parseSafely({
        schema: productIdZodSchema,
        value: props.giftCardId ?? paramGiftCardId,
    });

    const giftCardQuery = useProduct(giftCardId);
    const giftCard = giftCardQuery.data;

    const { branding } = useBrandingContext();
    const backgroundCss = useBrandingBackgroundCss(
        branding?.product.background,
    );
    const peripheralBackgroundCss = useBrandingBackgroundCss(
        branding?.product.peripheralBackground,
    );

    if (giftCardQuery.isPending)
        return (
            <PageLoad
                text={translation({
                    sv: "Laddar presentkort...",
                    en: "Loading gift card...",
                })}
            />
        );

    if (!isGiftCard(giftCard))
        return (
            <PageNotFound
                reason={
                    giftCard?.acquirableAs
                        ? translation({
                              sv: "Du försökte ladda ett presentkort men den efterfrågade produkten är inte ett presentkort",
                              en: "You tried to load a gift card but the requested product is not a gift card",
                          })
                        : translation({
                              sv: "Kunde inte ladda presentkortet, försök igen senare",
                              en: "Could not load the gift card, please try again later",
                          })
                }
            />
        );

    return (
        <ColorThemeProvider colorTheme={branding?.colorThemes.light}>
            <WideScreenWrapper
                css={peripheralBackgroundCss}
                maxContentWidth={500}>
                <FlexColumn
                    css={[
                        {
                            height: "100%",
                            overflowY: "scroll",
                            overflowX: "hidden",
                            flexGrow: 1,
                        },
                        backgroundCss,
                    ]}>
                    <GiftCardPageWizard
                        giftCard={giftCard}
                        provider={props.provider}
                    />
                </FlexColumn>
            </WideScreenWrapper>
        </ColorThemeProvider>
    );
};

function GiftCardPageWizard(props: {
    readonly giftCard: GiftCard_Product;
    readonly provider: Provider;
}) {
    const product = props.giftCard;
    const provider = props.provider;
    return (
        <GiftCardPurchaseContextProvider
            giftCard={props.giftCard}
            provider={props.provider}>
            <MultiCartContextProvider
                contextId={`ctx_${product._id}`}
                preservationDisabled={true}>
                <CartContextProvider
                    key={provider._id}
                    providerId={provider._id}
                    doNotPreserveCart>
                    <CheckoutContextProvider>
                        <Wizard ignoreAnimation={false}>
                            {[
                                wizardPage(
                                    "product",
                                    <GiftCardPurchaseWizardPage />,
                                ),
                                wizardPage("cart", <CartPage />),
                            ]}
                        </Wizard>
                    </CheckoutContextProvider>
                </CartContextProvider>
            </MultiCartContextProvider>
        </GiftCardPurchaseContextProvider>
    );
}

export { GiftCardPage };
