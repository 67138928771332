import { useTheme } from "@emotion/react";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import { assertNever } from "@towni/common";
import { useCallback } from "react";
import { ButtonTransparent } from "../buttons_v2/button-gray";
import { Icon } from "../icons/icon";
import { TextBox } from "../text";

type HzScrollNavButtonType = "none" | "small" | "large";
const navBtnDiameter = 36;

const HzScrollNavButton = (props: {
    direction: "left" | "right";
    type: HzScrollNavButtonType;
    scrollContainer: HTMLDivElement | null;
    scrollAmountPerItem: number;
    itemWidth: number;
    scrollViewWidth: number;
    isScrollableToRight: boolean;
    bottomGap: number;
    onClick?: () => void;
}) => {
    const theme = useTheme();
    const scrollAmountPerItem = props.scrollAmountPerItem;
    const onClick = props.onClick;
    const direction = props.direction;
    const scrollContainer = props.scrollContainer;
    const onButtonClick = useCallback(() => {
        scrollContainer?.scrollBy({
            left:
                direction === "left"
                    ? props.isScrollableToRight
                        ? -scrollAmountPerItem
                        : -(
                              props.itemWidth -
                              (props.scrollViewWidth - scrollAmountPerItem)
                          )
                    : scrollAmountPerItem,
            behavior: "smooth",
        });
        onClick?.();
    }, [
        scrollContainer,
        direction,
        props.isScrollableToRight,
        props.itemWidth,
        props.scrollViewWidth,
        scrollAmountPerItem,
        onClick,
    ]);

    switch (props.type) {
        case "none":
            return null;
        case "small": {
            const icon = direction === "left" ? faChevronLeft : faChevronRight;
            return (
                <ButtonTransparent
                    onClick={onButtonClick}
                    padding={{ all: 5 }}
                    css={{
                        borderRadius: 3000,
                        width: navBtnDiameter,
                        height: navBtnDiameter,
                        marginBottom: props.bottomGap,
                        backgroundColor: theme.isLightTheme
                            ? theme.colors.white.main.withAlpha(0.7).asString
                            : theme.colors.white.main.withAlpha(0.3).asString,
                    }}>
                    <Icon
                        icon={icon}
                        fixedWidth
                        color={
                            theme.isLightTheme
                                ? theme.colors.black
                                : theme.colors.white
                        }
                    />
                </ButtonTransparent>
            );
        }
        case "large": {
            const char = direction === "left" ? "‹" : "›";
            return (
                <ButtonTransparent
                    onClick={onButtonClick}
                    padding={{ all: 5 }}
                    css={{
                        borderRadius: 3000,
                        width: navBtnDiameter,
                        height: navBtnDiameter,
                        marginBottom: props.bottomGap,
                    }}>
                    <TextBox
                        text={char}
                        weight="500"
                        size={5}
                        color={theme.colors.white}
                        css={{
                            textShadow: "0 0 5px rgba(0, 0, 0, 1)",
                        }}
                    />
                </ButtonTransparent>
            );
        }
        default:
            assertNever(props.type);
    }
};

export { HzScrollNavButton };
export type { HzScrollNavButtonType };
