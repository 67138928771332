import { apiFetchClient } from "@@/shared/fetch-client";
import { useToast } from "@@/toasts/context/toast-context";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
    RemoveStoredPaymentMethodCommand,
    support,
    translation,
} from "@towni/common";

const mainQueryKey = "payment-methods";

const removeStoredPaymentMethodCommand = (
    command: RemoveStoredPaymentMethodCommand,
) => {
    return apiFetchClient.delete<RemoveStoredPaymentMethodCommand>({
        route: `/payments/stripe/payment-methods/${command.storedPaymentMethodId}`,
        body: command,
    });
};

const useRemoveStoredPaymentMethod = (options?: {
    onSuccess?: () => void | Promise<void>;
    onError?: () => void | Promise<void>;
    disableNotifications?: boolean;
}) => {
    const toast = useToast();
    const queryClient = useQueryClient();
    const mutation = useMutation<
        unknown,
        unknown,
        RemoveStoredPaymentMethodCommand
    >({
        mutationFn: removeStoredPaymentMethodCommand,
        onSuccess: async _ => {
            await queryClient.invalidateQueries({ queryKey: [mainQueryKey] });
            await options?.onSuccess?.();
            if (!options?.disableNotifications)
                toast.success({ message: `Betalningsmetod borttagen` });
        },
        onError: async (error, _command) => {
            if (!options?.disableNotifications) {
                toast.fromError(error, {
                    messageOverrideWhenNotApiError: translation({
                        sv: `Kunde inte att ta bort betalningsmetoden. Försök igen och om felet kvarstår, kontakta ${support.towni.supportEmail}`,
                        en: `Failed to remove payment method. Try again and if the error persists, contact ${support.towni.supportEmail}`,
                    }),
                });
            }
            await options?.onError?.();
            throw error;
        },
    });

    return mutation;
};

export { useRemoveStoredPaymentMethod };
