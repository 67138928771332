import {
    QueriesOptions,
    type QueryFunction,
    type QueryKey,
    useQueries,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from "@tanstack/react-query";
import { emptyArrayOf, GetResponse, GetSingleResponse } from "@towni/common";
import { Atom, Getter } from "jotai";
import { atomWithQuery } from "jotai-tanstack-query";
import { useMemo } from "react";
import { Except } from "type-fest";
import { fetchClientAtom, useFetchClient } from "./fetch-client";

type TanstackQueryOptions<ResponseType> = Except<
    UseQueryOptions<ResponseType>,
    "queryFn" | "queryKey"
>;

type TanstackQueryOptionsForUserQueries<ResponseType> = Except<
    UseQueryOptions<ResponseType>,
    "queryFn" | "queryKey"
>;

/**
 * Helper hook that wraps the useQuery hook from Tanstack and
 * provides a custom query function that fetches a single item
 * from our api. It assumes and requires the api/server
 * to return a response in the `GetSingleResponse<ResponseType>` format.
 *
 * @param route url to fetch the data from
 * @param queryKey queryKey of the query
 * @param options additional options for the query, corresponds to tanstack query's useQuery options
 * @returns
 */
const useTanstackSingleQuery = <ResponseType extends Record<string, unknown>>(
    route: string,
    queryKey: QueryKey,
    options?: TanstackQueryOptions<ResponseType>,
): UseQueryResult<ResponseType> => {
    const fetchClient = useFetchClient();
    const queryFn: QueryFunction<ResponseType> = async ({ signal }) => {
        const data = await fetchClient.get<GetSingleResponse<ResponseType>>({
            route,
            customConfig: {
                signal,
            },
        });
        return data?.item;
    };

    return useQuery<ResponseType>({
        queryKey,
        queryFn,
        ...options,
    });
};

/**
 * Helper hook that wraps the useQuery hook from Tanstack and
 * provides a custom query function that fetches an array of items
 * from our api. It assumes and requires the api/server
 * to return a response in the `GetResponse<ResponseType>` format
 * @param route url to fetch the data from
 * @param queryKey queryKey of the query
 * @param options additional options for the query, corresponds to tanstack query's useQuery options
 * @returns
 */
const useTanstackMultiQuery = <ResponseType extends Record<string, unknown>>(
    route: string,
    queryKey: QueryKey,
    options?: TanstackQueryOptions<ResponseType[]>,
): UseQueryResult<ResponseType[]> => {
    const fetchClient = useFetchClient();
    const queryFn: QueryFunction<ResponseType[]> = async ({
        signal,
    }): Promise<ResponseType[]> => {
        const data = await fetchClient.get<GetResponse<ResponseType>>({
            route,
            customConfig: {
                signal,
            },
        });
        return data?.items ?? emptyArrayOf<ResponseType>();
    };

    return useQuery<ResponseType[]>({
        queryKey,
        queryFn,
        ...options,
    });
};
/**
 * Helper hook that wraps the useQuery hook from Tanstack and
 * provides a custom query function that fetches an array of items
 * from our api. It assumes and requires the api/server
 * to return a response in the `GetResponse<ResponseType>` format
 * @param route url to fetch the data from
 * @param queryKey queryKey of the query
 * @param options additional options for the query, corresponds to tanstack query's useQuery options
 * @returns
 */
const useTanstackMultiPostQuery = <
    Command extends Record<string, unknown>,
    ResponseType extends Record<string, unknown>,
>(
    route: string,
    queryKey: QueryKey,
    body: Command,
    options?: TanstackQueryOptions<ResponseType[]>,
): UseQueryResult<ResponseType[]> => {
    const fetchClient = useFetchClient();
    const queryFn: QueryFunction<ResponseType[]> = async ({
        signal,
    }): Promise<ResponseType[]> => {
        const data = await fetchClient.post<Command, GetResponse<ResponseType>>(
            {
                route,
                body,
                customConfig: {
                    signal,
                },
            },
        );
        return data?.items ?? emptyArrayOf<ResponseType>();
    };

    return useQuery<ResponseType[]>({
        queryKey,
        queryFn,
        ...options,
    });
};

const tanstackMultiQueryAtom = <ResponseType extends Record<string, unknown>>(
    getData: (get: Getter) => {
        route: string;
        queryKey: QueryKey;
        options?: TanstackQueryOptions<ResponseType[]>;
    },
): Atom<UseQueryResult<ResponseType[]>> => {
    return atomWithQuery<ResponseType[]>(get => {
        const { route, queryKey, options } = getData(get);
        const fetchClient = get(fetchClientAtom);
        return {
            queryKey,
            queryFn: async ({ signal }): Promise<ResponseType[]> => {
                const data = await fetchClient.get<GetResponse<ResponseType>>({
                    route,
                    customConfig: {
                        signal,
                    },
                });
                return data?.items ?? emptyArrayOf<ResponseType>();
            },
            ...options,
            initialData: emptyArrayOf<ResponseType>(),
        };
    });
};
const useTanstackMultiQueryAtom = <
    ResponseType extends Record<string, unknown>,
>(
    route: string,
    queryKey: QueryKey,
    options?: TanstackQueryOptions<ResponseType[]>,
): Atom<UseQueryResult<ResponseType[]>> => {
    const tanstackQueryAtom = useMemo(
        () =>
            tanstackMultiQueryAtom(_ => ({
                route,
                queryKey,
                options,
            })),
        [options, queryKey, route],
    );

    return tanstackQueryAtom;
};

/**
 * Helper hook that wraps the useQuery hook from Tanstack and
 * provides a custom query function that fetches `ResponseType`
 * from our api. To be used when the api doesn't return any of the default
 * `GetResponse<ResponseType>` or `GetSingleResponse<ResponseType>` formats.
 * @param route url to fetch the data from
 * @param queryKey queryKey of the query
 * @param options additional options for the query, corresponds to tanstack query's useQuery options
 * @returns
 */
const useTanstackCustomQuery = <ResponseType>(
    route: string,
    queryKey: QueryKey,
    options?: TanstackQueryOptions<ResponseType>,
): UseQueryResult<ResponseType> => {
    const fetchClient = useFetchClient();
    const queryFn: QueryFunction<ResponseType> = async ({ signal }) => {
        const data = await fetchClient.get<ResponseType>({
            route,
            customConfig: {
                signal,
            },
        });
        return data;
    };

    return useQuery<ResponseType>({
        queryKey,
        queryFn,
        ...options,
    });
};

/**
 * Helper hook that wraps the useQuery hook from Tanstack and
 * provides a custom query function that fetches a single item
 * from our api. It assumes and requires the api/server
 * to return a response in the `GetSingleResponse<ResponseType>` format.
 *
 * @param route url to fetch the data from
 * @param queryKey queryKey of the query
 * @param options additional options for the query, corresponds to tanstack query's useQuery options
 * @returns
 */
const useTanstackSingleQueries = <ResponseType extends Record<string, unknown>>(
    queryData: {
        route: string;
        queryKey: QueryKey;
        options?: TanstackQueryOptionsForUserQueries<ResponseType>;
    }[],
) => {
    type Queries = UseQueryOptions<ResponseType>[];
    // https://github.com/TanStack/query/discussions/3991#discussioncomment-3410292
    const fetchClient = useFetchClient();
    const queryOptions = queryData.map((qd): Queries[0] => {
        const { route, queryKey } = qd;
        const options: TanstackQueryOptionsForUserQueries<ResponseType> =
            qd.options ??
            ({} as TanstackQueryOptionsForUserQueries<ResponseType>);
        const queryFn: QueryFunction<ResponseType> = async ({
            signal,
        }): Promise<ResponseType> => {
            const data = await fetchClient.get<GetSingleResponse<ResponseType>>(
                {
                    route,
                    customConfig: {
                        signal,
                    },
                },
            );
            return data?.item;
        };

        return {
            queryKey,
            queryFn,
            ...options,
        } satisfies Queries[0];
    });

    return useQueries<Queries>({
        queries: queryOptions as QueriesOptions<Queries>,
    });
};

const tanstack = {
    useTanstackSingleQuery,
    useTanstackMultiQuery,
    useTanstackMultiPostQuery,
    useTanstackCustomQuery,
};

export {
    tanstack,
    tanstackMultiQueryAtom,
    useTanstackCustomQuery,
    useTanstackMultiPostQuery,
    useTanstackMultiQuery,
    useTanstackMultiQueryAtom,
    useTanstackSingleQueries,
    useTanstackSingleQuery,
};
export type { TanstackQueryOptions, TanstackQueryOptionsForUserQueries };
