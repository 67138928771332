import { useWizardDispatch } from "@@/pages/wizard-context";
import { ButtonTransparent } from "@@/shared/buttons_v2/button-gray";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { CircleSpinner } from "@@/shared/spinners/circle-spinner";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    faCcAmex,
    faCcDinersClub,
    faCcDiscover,
    faCcJcb,
    faCcMastercard,
    faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import { IconDefinition, faCreditCard } from "@fortawesome/pro-solid-svg-icons";
import {
    initiateStripePaymentCommandFactory,
    remSize,
    translation,
} from "@towni/common";
import * as React from "react";
import { CardBrand } from "../stored-payment-methods/stored-payment-method";
import { useStoredStripePaymentMethods } from "../stored-payment-methods/stored-payment-method-fetchers";
import { usePaymentDetailsContext } from "./payment-details.context";
import { useStripeFormSubmit } from "./stripe/card-form/use-stripe-submit";
import { useStripePayment } from "./stripe/stripe-payment.context";

const getCreditCardIcon = (
    cardBrand: CardBrand | undefined,
): IconDefinition => {
    switch (cardBrand) {
        case "amex":
            return faCcAmex;
        case "diners":
            return faCcDinersClub;
        case "discover":
            return faCcDiscover;
        case "jcb":
            return faCcJcb;
        case "mastercard":
            return faCcMastercard;
        case "visa":
            return faCcVisa;
        default:
            return faCreditCard;
    }
};

const PaymentTypeCardSelection = (props: {
    readonly maxHeight: number;
    readonly goForward: () => void | Promise<void>;
    readonly onSelection: () => void | Promise<void>;
}) => {
    const theme = useTheme();
    const [stripeStoredCards, cardQuery] = useStoredStripePaymentMethods();
    const { paymentDetails, selectPaymentType } = usePaymentDetailsContext(
        context => ({
            paymentDetails: context.paymentDetails,
            selectPaymentType: context.selectPaymentType,
        }),
    );
    const [stripeState, stripeActions] = useStripePayment();
    // const [_isSubmitting, _setIsSubmitting] = React.useState(false);
    const { finish } = useWizardDispatch();

    if (!paymentDetails)
        throw new Error(
            "Betalningsdetaljer måste vara satta innan betalningsmetod väljs",
        );

    const showStripeCardForm = React.useCallback(async () => {
        await props.onSelection();
        selectPaymentType({
            name: "STRIPE",
            type: "stripe_card",
        });

        if (!stripeState.intent)
            await stripeActions.createPaymentIntent(
                initiateStripePaymentCommandFactory({
                    orderGroupId: paymentDetails.orderGroupId,
                    delay: true,
                }),
                "showStripeCardForm",
            );
        await props.goForward();
    }, [paymentDetails, stripeState]);

    const card = stripeStoredCards[0];
    const [handleSubmit, _stripeErrorMessage] = useStripeFormSubmit({
        onFinish: finish,
        selectedCardExternalId: card?.externalId,
        saveCard: false,
    });

    if (cardQuery.isPending) {
        return (
            <FlexRow
                fillParentWidth
                mainAxis="center"
                padding={{ topBottom: 10 }}>
                <CircleSpinner size="XL" />
            </FlexRow>
        );
    }

    if (card) {
        return (
            <>
                <FlexRow
                    css={{
                        maxHeight: props.maxHeight,
                        border: "1px solid hsl(0, 0%, 80%)",
                        borderRadius: 5,
                    }}
                    padding={{ all: 13, left: 15 }}
                    onClick={async event => {
                        await props.onSelection();

                        const data =
                            stripeState.intent ??
                            (await stripeActions.createPaymentIntent(
                                initiateStripePaymentCommandFactory({
                                    orderGroupId: paymentDetails.orderGroupId,
                                    delay: true,
                                }),
                                "stripe_preselected_card",
                            ));
                        await handleSubmit(event, { intent: data });
                        selectPaymentType({
                            name: "STRIPE",
                            type: "stripe_preselected_card",
                        });
                    }}
                    fillParentWidth
                    mainAxis="center"
                    crossAxis="center"
                    background={{ color: theme.colors.white }}>
                    <TextBox
                        text={translation({
                            sv: `Kort ... ${card.last4}`,
                            en: `Card ... ${card.last4}`,
                            de: `Karte ... ${card.last4}`,
                        })}
                        color={theme.colors.black.light10}
                        size={1.125}
                    />
                    <HorizontalDivider XS />
                    <Icon
                        icon={getCreditCardIcon(card.brand)}
                        color={theme.colors.black.light10}
                        size={remSize(1.4)}
                    />
                </FlexRow>
                <VerticalDivider />
                <ButtonTransparent
                    fillParentWidth
                    onClick={showStripeCardForm}
                    dataTestId="another-card-btn">
                    <FlexRow
                        css={{
                            maxHeight: props.maxHeight,
                            padding: 15,
                        }}
                        mainAxis="center"
                        crossAxis="center">
                        <TextBox
                            text={translation({
                                sv: "Använd ett annat kort",
                                en: "Use another card",
                                de: "Benutze eine andere Karte",
                            })}
                            color={theme.colors.black.light10}
                            size={1}
                        />
                    </FlexRow>
                </ButtonTransparent>
            </>
        );
    }

    return (
        <FlexRow
            fillParentWidth
            mainAxis="center"
            crossAxis="center"
            padding={{ all: 13, left: 15 }}
            onClick={showStripeCardForm}
            data-testid="pay-with-stripe-card-btn"
            css={{
                maxHeight: 55,
                border: `1px solid hsl(0, 0%, 80%)`,
                borderRadius: 5,
                backgroundColor: theme.colors.white.asString,
            }}>
            <TextBox
                text={translation({
                    sv: "Betala med kort",
                    en: "Pay with card",
                    de: "Mit Karte bezahlen",
                })}
                color={theme.colors.black.light10}
            />
            <HorizontalDivider S />
            <Icon icon={faCreditCard} color={theme.colors.black.light10} />
        </FlexRow>
    );
};

export { PaymentTypeCardSelection };
