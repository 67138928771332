import { SizeName } from "@towni/common";

const dividerSizeInPx = {
    "3XL": 60,
    XXL: 50,
    XL: 40,
    L: 30,
    ML: 25,
    M: 20,
    S: 12,
    XS: 7,
    XXS: 3,
    "3XS": 1.5,
};

const getDividerSizeInPixels = (size: SizeName): number => {
    return dividerSizeInPx[size];
};

const calculateDividerSize = (
    sizes: { [key in SizeName]?: boolean },
    fallback: number,
): number => {
    const firstKeyOfEnabledSize: SizeName | undefined = Object.entries(
        sizes,
    ).filter(([_, value]) => !!value)[0]?.[0] as SizeName | undefined;
    // console.log("CALC DIVIDER", { firstKeyOfEnabledSize, sizes, fallback });
    if (!firstKeyOfEnabledSize) return fallback;
    return getDividerSizeInPixels(firstKeyOfEnabledSize);
};

export { calculateDividerSize, dividerSizeInPx, getDividerSizeInPixels };
