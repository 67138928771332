import { browserLogger } from "@@/settings";
import { emptyArrayOf, emptyMapOf } from "@towni/common";
import { createContext, useContext, useRef } from "react";
import { Except } from "type-fest";
import { StoreApi, createStore } from "zustand";
import { shallow } from "zustand/shallow";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { ModalId, modalIdFactory } from "./modal-id";
import { RegisteredModal } from "./registered-modal";

type ShowModalOptions = Except<RegisteredModal, "modalId">;

type ContextState = State & Actions;
type State = {
    modals: Map<ModalId, RegisteredModal>;
    sortOrder: ModalId[];
    portalElementId: string;
};
type Actions = {
    setModal: (payload: Partial<RegisteredModal>) => void | Promise<void>;
    removeModal: (modalId: ModalId) => void | Promise<void>;
    showModal: (
        modalId: ModalId,
        options?: Partial<ShowModalOptions>,
    ) => void | Promise<void>;
    toggleModal: (modalId: ModalId) => void | Promise<void>;
    hideModal: (modalId: ModalId) => void | Promise<void>;
    hideAll: () => void | Promise<void>;
    hideTop: () => void | Promise<void>;
    exists: (modalId: ModalId) => boolean;
    isVisibleCheck: (modalId: ModalId) => boolean;
};

const ModalContext = createContext<StoreApi<ContextState> | undefined>(
    undefined,
);

type Props = {
    portalElementId?: string;
    children: React.ReactNode;
};

const ModalContextProvider = (props: Props) => {
    const modals = emptyMapOf<ModalId, RegisteredModal>();
    const sortOrder = emptyArrayOf<ModalId>();
    const portalElementId = props.portalElementId ?? "portal";
    const store = useRef<StoreApi<ContextState>>(
        createStore<ContextState>()((set, get) => {
            const setModal = (modal: Partial<RegisteredModal>) => {
                set(state => {
                    const modalId = modal.modalId ?? modalIdFactory();
                    const current = state.modals.get(modalId);
                    if (current) {
                        // update modal
                        const modals = new Map(state.modals);
                        const update = {
                            ...current,
                            ...modal,
                        };
                        modals.set(modalId, update);
                        return {
                            ...state,
                            modals,
                        };
                    }

                    // add modal
                    const newModal: RegisteredModal = {
                        modalId: modal.modalId ?? modalIdFactory(),
                        status: modal.status ?? "SHOW",
                        backgroundCloseDisabled:
                            !!modal.backgroundCloseDisabled,
                        isFullHeightModal: modal.isFullHeightModal,
                    };

                    const modals = new Map(state.modals);
                    modals.set(newModal.modalId, newModal);

                    return {
                        ...state,
                        modals,
                    };
                });
            };
            const removeModal = (modalId: ModalId) => {
                set(state => {
                    const modals = new Map(get().modals);
                    modals.delete(modalId);
                    return {
                        ...state,
                        modals,
                        sortOrder: state.sortOrder.filter(id => id !== modalId),
                    };
                });
            };
            const showModal = (
                modalId: ModalId,
                options?: Partial<ShowModalOptions>,
            ) => {
                set(state => {
                    const modals = new Map(state.modals);
                    const modal = state.modals.get(modalId);
                    // console.log("WOW SHOW SHOW SHOW", {
                    //     modalId,
                    //     modal,
                    //     modals,
                    //     state,
                    //     options,
                    // });
                    if (!modal) {
                        // no modal to show
                        browserLogger.warn(
                            `Cannot show modal ${modalId}, since it doesn't exist`,
                            state,
                        );
                        return state;
                    }
                    const update: RegisteredModal = {
                        ...modal,
                        modalId: modal.modalId,
                        status: "SHOW",
                        backgroundCloseDisabled:
                            options?.backgroundCloseDisabled ??
                            modal.backgroundCloseDisabled,
                        isFullHeightModal:
                            options?.isFullHeightModal ??
                            modal.isFullHeightModal,
                    };
                    modals.set(modalId, update);
                    const _state: typeof state = {
                        ...state,
                        modals,
                        sortOrder: [
                            ...state.sortOrder.filter(id => id !== modalId),
                            modalId,
                        ],
                    };
                    // console.log("AFTER SHOW MODAL STATE", _state);
                    return _state;
                });
            };
            const hideModal = (modalId: ModalId) => {
                if (get().modals.get(modalId)?.status === "HIDE") return;
                set(state => {
                    const modals = new Map(state.modals);
                    const modal = state.modals.get(modalId);
                    if (!modal) return state;
                    if (modal.status === "HIDE") return state;
                    const update: RegisteredModal = {
                        ...modal,
                        modalId: modal.modalId,
                        status: "HIDE",
                    };
                    modals.set(modalId, update);
                    return {
                        ...state,
                        modals,
                    };
                });
            };
            const toggleModal = (modalId: ModalId) => {
                const modal = get().modals.get(modalId);
                if (!modal) {
                    browserLogger.warn("Modal not found", modalId);
                    return;
                }

                if (modal.status === "SHOW") hideModal(modalId);
                else showModal(modalId);
            };
            const hideAll = () => {
                set(state => {
                    const modals = new Map(state.modals);
                    const visibleModals = Array.from(modals.values()).filter(
                        modal => modal.status === "SHOW",
                    );

                    if (!visibleModals.length) return state;
                    visibleModals.forEach(modal => {
                        modals.set(modal.modalId, {
                            ...modal,
                            status: "HIDE",
                        });
                    });

                    return {
                        ...state,
                        modals,
                        sortOrder: emptyArrayOf<ModalId>(),
                    };
                });
            };
            const hideTop = () => {
                set(state => {
                    const modals = new Map(state.modals);
                    const visibleModals = Array.from(modals.values()).filter(
                        modal => modal.status === "SHOW",
                    );
                    if (!visibleModals.length) return state;
                    const modal = visibleModals.pop();
                    if (!modal) return state;
                    modals.set(modal.modalId, {
                        ...modal,
                        status: "HIDE",
                    });
                    return {
                        ...state,
                        modals,
                    };
                });
            };
            const exists = (modalId: ModalId) => get().modals.has(modalId);
            const isVisible = (modalId: ModalId) =>
                get().modals.get(modalId)?.status === "SHOW";

            return {
                modals,
                sortOrder,
                portalElementId,
                setModal,
                removeModal,
                showModal,
                toggleModal,
                hideModal,
                hideAll,
                hideTop,
                exists,
                isVisibleCheck: isVisible,
            };
        }),
    );

    return (
        <ModalContext.Provider value={store.current}>
            {props.children}
        </ModalContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useModalContext = <U extends unknown = ContextState>(
    selector: (context: ContextState) => U = context => context as unknown as U,
): U => {
    const store = useContext(ModalContext);
    if (store === undefined) {
        throw new Error(
            "useModalContext must be used within a ModalContextProvider",
        );
    }
    return useStoreWithEqualityFn(store, selector, shallow);
};

export { ModalContext, ModalContextProvider, useModalContext };
