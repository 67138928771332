import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { getCurrentStorefrontPath } from "@@/store-fronts/shared/current-store-front";
import { ClassNames, useTheme } from "@emotion/react";
import { translation } from "@towni/common";
import { MutableRefObject } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";

type Props = {
    printRef?: MutableRefObject<HTMLDivElement | null>;
    isPaymentPending: boolean;
    nextUrlOverride?: string;
    verifiedByAccessKey?: boolean;
};

const Affirmation = (props: Props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const nextPath =
        useParams<{ next: string }>().next || getCurrentStorefrontPath();
    if (props.isPaymentPending) return null;
    if (props.verifiedByAccessKey) return null;

    return (
        <div
            css={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "0 20px",
                "@media print": {
                    display: "none",
                },
            }}>
            <FlexColumn
                fillParentWidth
                crossAxis="center"
                css={{
                    maxWidth: 700,
                    borderRadius: 20,
                    padding: 20,
                    // backgroundColor: theme.colors.white.asString,
                }}>
                {/* Header */}
                <FlexRow fillParentWidth crossAxis="center" mainAxis="center">
                    <TextBox
                        padding={{ leftRight: 20 }}
                        align="center"
                        text={translation({
                            sv: `Du kommer att få ett mejl som bekräftar din bokning.`,
                            en: `You will receive an email confirming your booking.`,
                        })}
                        size={0.825}
                        weight="700"
                        color={theme.colors.white}
                        css={{
                            opacity: 0.8,
                            textAlign: "center",
                            lineHeight: "1.2rem",
                            textShadow: "0 0 100px rgba(0, 0, 0, 1)",
                        }}
                    />
                </FlexRow>
                <VerticalDivider XXS />
                <ClassNames>
                    {content => (
                        <ReactToPrint
                            copyStyles={true}
                            bodyClass={content.css({
                                overflow: "visible",
                                height: "auto",
                                width: "100%",
                                backgroundColor: "white",
                                boxSizing: "border-box",
                                justifyContent: "stretch",
                                alignItems: "center",
                                display: "flex",
                                fontFamily: theme.fontFamily,
                                fontSize: theme.sizes.base,
                                letterSpacing: "0.01em",
                                zoom: 0.9,

                                /* color: ${lightColorTheme.default.text.asString}; */
                                // backgroundColor: #eeeeee,
                                userSelect: "none",
                            })}
                            trigger={() => (
                                <TextBox
                                    padding={{ leftRight: 20 }}
                                    align="center"
                                    renderMarkdown
                                    text={translation({
                                        sv: `Denna sida fungerar som digitalt bevis, vill du *skriva ut? »*`,
                                        en: `Works as a digital confirmation, do you want to print?`,
                                    })}
                                    size={0.825}
                                    weight="700"
                                    color={theme.colors.white}
                                    css={{
                                        opacity: 0.8,
                                        textAlign: "center",
                                        lineHeight: "1.2rem",
                                        textShadow:
                                            "0 0 100px rgba(0, 0, 0, 1)",
                                    }}
                                />
                            )}
                            content={() =>
                                props.printRef ? props.printRef.current : null
                            }
                        />
                    )}
                </ClassNames>

                <VerticalDivider XL />
                <ButtonPrimary
                    onClick={() => {
                        if (props.nextUrlOverride?.startsWith("http")) {
                            window.location.href = props.nextUrlOverride;
                            return;
                        }
                        navigate(props.nextUrlOverride || nextPath || "/");
                    }}
                    padding={{ topBottom: 20, leftRight: 50 }}
                    css={{
                        borderRadius: 40,
                    }}>
                    <TextBox
                        text={translation({
                            sv: "Fortsätt",
                            en: "Continue",
                        })}
                    />
                </ButtonPrimary>
            </FlexColumn>
        </div>
    );
};

export { Affirmation };
