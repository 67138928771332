import { useContentPageBlockViewContext } from "@@/backoffice/for-providers/pages/blocks/content-blocks/content-auto-blocks/content-auto-page-block/content-page-block-view-context";
import { usePageViewContext } from "@@/backoffice/for-providers/pages/editor/page-view-context";
import { darkeningGradientReverse } from "@@/shared/bg-darkening-gradient";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { LayoutGrid } from "@@/shared/layout-grid";
import { Link } from "@@/shared/link";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text/text-box";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import {
    useIsTranslatableEmpty,
    useTranslate,
} from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { BlockContent, PageBlock } from "@towni/common";
import { GridCardContainer } from "./cards/grid-card-container";
import { getBlockContentTargetUrl } from "./shared/get-block-content-target-url";
import { inactiveCss } from "./shared/inactive-css";

type Props = {
    readonly content: [BlockContent, BlockContent, BlockContent];

    // Css
    readonly width?: React.CSSProperties["width"];
    readonly className?: string;
};

const Card__ = (props: {
    imageWidth: number;
    content: BlockContent;
    block: PageBlock;
    defaultTextShadow: string;
    defaultBorderRadius: number;
}) => {
    const isTranslatableEmpty = useIsTranslatableEmpty();
    const providerId = usePageViewContext(context => context.providerId);
    const {
        imageWidth: maxWidth,
        block,
        defaultTextShadow,
        defaultBorderRadius,
    } = props;
    const theme = useTheme();
    const isSmall = usePageBreakpoint({ when: "SMALL_SCREEN" });
    const translate = useTranslate();

    return (
        <Link
            to={getBlockContentTargetUrl(props.content, providerId)}
            title={translate(props.content.title)}
            disabled={props.content.inactive}
            css={{ cursor: "pointer" }}>
            <GridCardContainer
                aspectRatio={isSmall ? "4:3" : "1:1"}
                css={props.content.inactive ? inactiveCss : undefined}>
                <FlexColumn
                    fillParent
                    css={{
                        borderRadius: defaultBorderRadius,
                        position: "relative",
                        overflow: "hidden",
                        label: "content-grid-card-2-medium",
                    }}
                    background={{
                        fit: "COVER",
                        imageReference: props.content.poster,
                        color:
                            props.content.backgroundColor ??
                            theme.colors.default.background.asString,
                        imageSize: {
                            imageWidth: maxWidth,
                        },
                    }}>
                    <FlexColumn
                        fillParent
                        crossAxis="flex-start"
                        mainAxis="space-between"
                        padding={{ all: 20 }}
                        background={{
                            linearGradient: darkeningGradientReverse,
                        }}>
                        <FlexColumn fillParentWidth>
                            <TextBox
                                text={props.content.title}
                                lineClamp={2}
                                weight="800"
                                size={isSmall ? 1.5 : 1.3}
                                color={
                                    "textColor" in props.content &&
                                    props.content.textColor
                                        ? props.content.textColor
                                        : theme.colors.white
                                }
                                css={{
                                    overflowWrap: "break-word",
                                }}
                            />
                            <Conditional
                                when={
                                    isSmall &&
                                    !isTranslatableEmpty(props.content.subtitle)
                                }>
                                <VerticalDivider XS />
                                <TextBox
                                    text={props.content.subtitle}
                                    lineClamp={2}
                                    weight="400"
                                    size={isSmall ? 0.9 : 0.725}
                                    color={
                                        "textColor" in props.content &&
                                        props.content.textColor
                                            ? props.content.textColor
                                            : theme.colors.white
                                    }
                                    css={{
                                        textShadow: defaultTextShadow,
                                    }}
                                />
                            </Conditional>
                        </FlexColumn>
                        <FlexRow fillParentWidth mainAxis="flex-end">
                            <StoreFrontCardPill
                                text={props.content.actionText}
                                accentColor={
                                    "accentColor" in props.content &&
                                    props.content.accentColor
                                        ? props.content.accentColor
                                        : block.accentColor
                                }
                            />
                        </FlexRow>
                    </FlexColumn>
                </FlexColumn>
            </GridCardContainer>
        </Link>
    );
};

const ContentGrid_3_Medium = (props: Props) => {
    const { width } = props;
    const isSmall = usePageBreakpoint({ when: "SMALL_SCREEN" });
    const {
        pageInnerWidth: maxWidth,
        defaultGridGap,
        defaultTextShadow,
        defaultBorderRadius,
    } = usePageViewContext();
    const { block } = useContentPageBlockViewContext();

    return (
        <LayoutGrid
            gap={defaultGridGap}
            // padding={{ all: gridCardGap }}
            gridTemplateColumns={isSmall ? "1fr" : "1fr 1fr 1fr"}
            className={props.className}
            css={{
                maxWidth,
                paddingLeft: 20,
                paddingRight: 20,
                width: width ?? "100%",
                label: "content_grid_3_medium",
            }}>
            <Card__
                imageWidth={isSmall ? 500 : 500}
                content={props.content[0]}
                block={block}
                defaultTextShadow={defaultTextShadow}
                defaultBorderRadius={defaultBorderRadius}
            />
            <Card__
                imageWidth={isSmall ? 500 : 500}
                content={props.content[1]}
                block={block}
                defaultTextShadow={defaultTextShadow}
                defaultBorderRadius={defaultBorderRadius}
            />
            <Card__
                imageWidth={isSmall ? 500 : 500}
                content={props.content[2]}
                block={block}
                defaultTextShadow={defaultTextShadow}
                defaultBorderRadius={defaultBorderRadius}
            />
        </LayoutGrid>
    );
};

export { ContentGrid_3_Medium };
