import {
    DateRange,
    IsoAndUnixTimestamp,
    getDatesInDateRange,
    IsoTimestamp,
} from "@towni/common";
import { createContext, useContext, useMemo, useState } from "react";

const SelectedDateRangeContext = createContext<
    | {
          selectedDateRange: DateRange | undefined;
          selectedDatesIso: Set<IsoTimestamp>;
          firstSelected: IsoAndUnixTimestamp | undefined;
          lastSelected: IsoAndUnixTimestamp | undefined;
          setSelectedDateRange: (dateRange: DateRange | undefined) => void;
      }
    | undefined
>(undefined);
const SelectedDateRangeContextProvider = (props: {
    readonly initialValue: DateRange | undefined;
    readonly children: React.ReactNode;
}) => {
    const [selectedDateRange, setSelectedDateRange] = useState(
        () => props.initialValue,
    );
    const selectedDatesIso = useMemo(() => {
        if (!selectedDateRange) return new Set<IsoTimestamp>();
        return new Set<IsoTimestamp>(
            getDatesInDateRange(selectedDateRange).map(item => item.iso),
        );
    }, [selectedDateRange]);
    const firstSelected = useMemo(
        () => selectedDateRange?.start,
        [selectedDateRange],
    );
    const lastSelected = useMemo(
        () => selectedDateRange?.end,
        [selectedDateRange],
    );

    const state = useMemo(
        () => ({
            selectedDateRange,
            selectedDatesIso,
            firstSelected,
            lastSelected,
            setSelectedDateRange,
        }),
        [
            selectedDateRange,
            firstSelected,
            lastSelected,
            selectedDatesIso,
            setSelectedDateRange,
        ],
    );

    return (
        <SelectedDateRangeContext.Provider value={state}>
            {props.children}
        </SelectedDateRangeContext.Provider>
    );
};
const useSelectedDateRangeContext = () => {
    const context = useContext(SelectedDateRangeContext);
    if (!context) throw new Error("No SelectedDateRangeContextProvider found");
    return context;
};

export { SelectedDateRangeContextProvider, useSelectedDateRangeContext };
