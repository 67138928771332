import { pageTransition } from "@@/pages/page-navigation/page-animation-slide-and-fade";
import { MotionValue, useSpring } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";

const getViewHeight = () => {
    if (typeof window === "undefined")
        throw new Error("window cannot be undefined to use this hook");
    return Math.max(
        document.documentElement?.clientHeight ?? 0,
        window.innerHeight ?? 0,
    );
};
const use100vh = (): number => {
    const [height, setHeight] = useState(getViewHeight);
    const heightRef = useRef(height);

    const calculateHeight = useCallback(() => {
        const newHeight = getViewHeight();
        if (heightRef.current !== newHeight) {
            heightRef.current = newHeight;
            setHeight(newHeight);
        }
    }, []);

    useEffect(() => {
        window.addEventListener("resize", calculateHeight);
        return () => window.removeEventListener("resize", calculateHeight);
    }, [calculateHeight]);

    return height;
};

const use100vhMotionValue = (): MotionValue => {
    const height = useSpring(getViewHeight(), pageTransition);
    const heightRef = useRef<number>(height.get());

    const updateHeight = useCallback(() => {
        const newHeight = getViewHeight();
        if (heightRef.current !== newHeight) {
            heightRef.current = newHeight;
            height.set(newHeight);
        }
    }, [height]);

    useEffect(() => {
        window.addEventListener("resize", updateHeight);
        return () => window.removeEventListener("resize", updateHeight);
    }, [updateHeight]);

    return height;
};

export { getViewHeight, use100vh, use100vhMotionValue };
