import { Form } from "@@/shared/form/form.zustand-store";
import type {
    // Form2TextInputPossibleValueTypes,
    Form2CheckboxProps,
} from "@@/shared/form/form2-checkbox";
import { Translatable } from "@towni/common";
import { ReactNode } from "react";
import { Except } from "type-fest";
import { z } from "zod";

const stateZodObject = z.object({
    value: z.boolean(),
});
type State = z.infer<typeof stateZodObject>;
type Value = boolean;

type Props = {
    readonly initialValue?: boolean;
    readonly onChange: (value: boolean) => void;
    readonly children?: ReactNode;
    readonly text?: Translatable;
    readonly zodSchema?: z.ZodType<Value>;
} & Except<Form2CheckboxProps<State, Value>, "fieldId" | "getter" | "setter">;

const Checkbox = (props: Props) => {
    return (
        <Form
            formParams={{
                clearOnUnmount: true,
                initializeIfNotExists: {
                    zodObject: stateZodObject,
                    initialState: {
                        value: !!props.initialValue,
                    },
                },
            }}>
            {({ FormComponents }) => (
                <FormComponents.Checkbox
                    {...props}
                    fieldId="textInput"
                    text={props.text}
                    getter={data => data.value}
                    setter={(data, newValue) => {
                        data.value = !!newValue;
                        props.onChange(!!newValue);
                    }}
                />
            )}
        </Form>
    );
};

export { Checkbox };
