import { usePageNavigationState } from "@@/pages/page-navigation/page-navigation-context";
import { Circle } from "@@/shared/circle";
import { useTheme } from "@emotion/react";
import {
    faChevronLeft,
    faSpinnerThird,
    IconDefinition,
} from "@fortawesome/pro-solid-svg-icons";
import { ColorItem, Percentage } from "@towni/common";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Except } from "type-fest";
import { Icon } from "../icons/icon";
import { useIsMountedRef } from "../use-is-mounted-ref";

type Props = {
    readonly size?: number;
    /** defaults to 0.67 */
    readonly fontSizeRatio?: Percentage;
    /** defaults theme.colors.default.text.light40 */
    readonly textColor?: ColorItem;
    /** defaults theme.colors.default.background */
    readonly backgroundColor?: ColorItem;
    /** defaults faArrowLeft */
    readonly icon?: IconDefinition;
} & (
    | {
          readonly onClick: () => void;
          readonly path?: never;
      }
    | {
          readonly onClick?: never;
          readonly path: string;
      }
);

const BackButton = (props: Props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const size = props.size || 30;
    const fontSize = size * (props.fontSizeRatio ?? 0.67);
    const path = props.path;
    const propsOnClick = props.onClick;
    const [isNavigating, setIsNavigating] = useState(false);
    const isMounted = useIsMountedRef();
    const onClick = () => {
        if (!path && !propsOnClick) return;
        setIsNavigating(true);
        setTimeout(() => {
            try {
                if (typeof path === "string") navigate(path + "#goback");
                else propsOnClick?.();
                if (!isMounted.current) return;
                setIsNavigating(false);
            } finally {
                if (isMounted.current) setIsNavigating(false);
            }
        }, 0);
    };

    return (
        <Circle
            onClick={onClick}
            size={size}
            textColor={props.textColor ?? theme.colors.default.text.light40}
            backgroundColor={
                props.backgroundColor ?? theme.colors.default.background
            }
            transparent
            contentSize="L">
            <Icon
                icon={
                    isNavigating
                        ? faSpinnerThird
                        : (props.icon ?? faChevronLeft)
                }
                spin={isNavigating}
                color={props.textColor ?? theme.colors.default.text.light40}
                style={{
                    fontSize,
                }}
            />
        </Circle>
    );
};

const HistoryBackButton = (
    props: Except<Props, "onClick" | "path"> & { fallbackButton?: JSX.Element },
) => {
    const navigate = useNavigate();
    const { previous: previousPage } = usePageNavigationState();

    const fallbackButton = props.fallbackButton;

    if (!previousPage && fallbackButton) {
        return fallbackButton;
    }

    if (!previousPage && !fallbackButton) {
        return null;
    }

    return <BackButton size={props.size} onClick={() => navigate(-1)} />;
};

export { BackButton, HistoryBackButton };
