import { useWizardDispatch } from "@@/pages/wizard-context";
import { useProviderFromContext } from "@@/providers/state/contexts/use-provider-from-context";
import { VerticalDivider } from "@@/shared/dividers";
import { apiFetchClient } from "@@/shared/fetch-client";
import { FlexColumn } from "@@/shared/flex-containers";
import { Label } from "@@/shared/label";
import { useIsMountedRef } from "@@/shared/use-is-mounted-ref";
import { useToast } from "@@/toasts/context/toast-context";
import { faClock } from "@fortawesome/pro-light-svg-icons";
import { faCashRegister } from "@fortawesome/pro-solid-svg-icons";
import {
    ContactInfo,
    InitiateOnPremisePayRequestCommand,
    InitiateOnPremisePaymentCommand,
    InitiateOnPremisePaymentCommandResponse,
    OnPremisePaymentType,
    PayRequestRecipient,
    initiateOnPremisePayRequestCommandFactory,
    initiateOnPremisePaymentCommandFactory,
    isApiError,
    support,
    translation,
} from "@towni/common";
import React, { useCallback, useState } from "react";
import { usePaymentDetailsContext } from "../payment-details.context";
import { OnPremisePayRequestPaymentButton } from "./on-premise-pay-request-payment-button";
import { OnPremisePaymentButton } from "./on-premise-payment-button";
import { OnPremiseTokenizedCardPaymentButton } from "./tokenized-card/on-premise-tokenized-card-payment-button";
import { useTokenizedCardFromOrder } from "./tokenized-card/use-tokenized-card-from-order";

const OnPremisePaymentForm = () => {
    const provider = useProviderFromContext();
    const toast = useToast();
    const { finish } = useWizardDispatch();
    const [spin, setSpin] = useState<false | OnPremisePaymentType>(false);
    const paymentDetails = usePaymentDetailsContext(
        context => context.paymentDetails,
    );
    const isMounted = useIsMountedRef();
    const hasTokenizedCard = !!useTokenizedCardFromOrder(
        paymentDetails?.orderId,
    );
    const createPayRequestPaymentIntent = React.useCallback(
        async (params: {
            recipients: PayRequestRecipient[];
            orderContactInfo: ContactInfo;
        }) => {
            if (spin) return;

            setSpin("pay_request");
            const { recipients } = params;

            if (!paymentDetails?.orderGroupId) {
                throw new Error(
                    "cant create a payment intent when order group id is missing",
                );
            }

            const command = initiateOnPremisePayRequestCommandFactory({
                orderEventIds: [],
                orderGroupId: paymentDetails.orderGroupId,
                recipients,
                requesterProviderId: provider._id,
                orderContactInfo: params.orderContactInfo,
            });

            await apiFetchClient
                .post<InitiateOnPremisePayRequestCommand>({
                    route: `/payments/on-premise/pay-requests`,
                    body: command,
                })
                .then(() => {
                    finish();
                })
                .catch(error => {
                    if (isApiError(error)) {
                        toast.fromError(error);
                        return;
                    }
                    toast.danger({
                        title: translation({
                            sv: `Problem vid orderläggning`,
                            en: `Issue when placing order`,
                        }),
                        message: translation({
                            sv: `Kunde inte färdigställa ordern och skicka betalningsförfrågan, kontakta ${support.towni.supportEmail} för assistans om felet kvarstår`,
                            en: `Could not finish order and send payment request, contact ${support.towni.supportEmail} for assistance if the error persists`,
                        }),
                    });
                });

            if (isMounted.current) setSpin(false);
        },
        [
            spin,
            paymentDetails?.orderGroupId,
            provider._id,
            isMounted,
            finish,
            toast,
        ],
    );
    const createPaymentIntent = useCallback(
        (
            paymentType: Exclude<
                OnPremisePaymentType,
                "pay_request" | "tokenized_card"
            >,
        ) =>
            async () => {
                setSpin(paymentType);

                if (!paymentDetails?.orderGroupId) {
                    throw new Error(
                        "cant create a payment intent when missing order group id",
                    );
                }

                const command = initiateOnPremisePaymentCommandFactory({
                    orderGroupId: paymentDetails.orderGroupId,
                    paymentType,
                });

                await apiFetchClient
                    .post<
                        InitiateOnPremisePaymentCommand,
                        InitiateOnPremisePaymentCommandResponse
                    >({
                        route: `/payments/on-premise/intents`,
                        body: command,
                    })
                    .then(() => {
                        finish();
                    })
                    .catch(error => {
                        if (isApiError(error)) {
                            toast.fromError(error);
                            return;
                        }
                        toast.danger({
                            message: translation({
                                sv: `Problem vid orderläggning, kontakta ${support.towni.supportEmail} för assistans om felet kvarstår`,
                                en: `Issue when placing order, contact ${support.towni.supportEmail} for assistance if the error persists`,
                            }),
                        });
                    });

                if (isMounted.current) setSpin(false);
            },
        [paymentDetails?.orderGroupId, isMounted, finish, toast],
    );

    return (
        <FlexColumn
            padding={{ leftRight: 30, bottom: 30, top: 10 }}
            fillParentWidth
            shrink={0}
            crossAxis="stretch">
            <OnPremisePaymentButton
                title={translation({
                    sv: "Kund har betalat",
                    en: "Customer has paid",
                })}
                onClick={createPaymentIntent("on_premise_paid")}
                spin={spin === "on_premise_paid"}
                disabled={!!spin}
                label={
                    <Label
                        text={translation({
                            sv: "KASSABETALD",
                            en: "PAID IN CASH",
                        })}
                        colorTheme="YELLOW"
                    />
                }
                icon={faCashRegister}
            />
            <VerticalDivider XS />
            <OnPremisePaymentButton
                title={translation({
                    sv: "Kund betalar senare",
                    en: "Customer pays later",
                })}
                onClick={createPaymentIntent("on_premise_pay_later")}
                spin={spin === "on_premise_pay_later"}
                disabled={!!spin}
                label={
                    <Label
                        text={translation({
                            sv: "OBETALD",
                            en: "UNPAID",
                        })}
                        colorTheme="RED"
                    />
                }
                icon={faClock}
            />
            {!paymentDetails || !hasTokenizedCard ? null : (
                <FlexColumn css={{ flex: 1 }}>
                    <VerticalDivider XS />
                    <OnPremiseTokenizedCardPaymentButton
                        spin={spin === "tokenized_card"}
                        disabled={!!spin}
                        onSuccess={() => {
                            finish();
                        }}
                        orderGroupId={paymentDetails.orderGroupId}
                        orderId={paymentDetails.orderId}
                        amountToPayInclVat={
                            paymentDetails.price.amountIncludingVat
                        }
                    />
                </FlexColumn>
            )}
            <FlexColumn css={{ flex: 1 }}>
                <VerticalDivider XS />
                <OnPremisePayRequestPaymentButton
                    spin={spin === "pay_request"}
                    disabled={!!spin}
                    onSend={async (recipients, contactInfo) => {
                        // If we are already spinning,
                        // we are already sending a request
                        if (spin) return;

                        await createPayRequestPaymentIntent({
                            recipients,
                            orderContactInfo: contactInfo,
                        });
                    }}
                />
            </FlexColumn>
        </FlexColumn>
    );
};

export { OnPremisePaymentForm };
