import { useProductLegacy } from "@@/products/state/queries/product/use-product";
import { darkeningGradient } from "@@/shared/bg-darkening-gradient";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ImageShimmer } from "@@/shared/pictures/shimmers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { SectionArea } from "@@/shared/section-area";
import { SectionAreaCard } from "@@/shared/section-area/section-area-card";
import { TextBox } from "@@/shared/text/text-box";
import { useProductIdWithFallback } from "@@/store-fronts/shared/fallback-product-ids";
import { StoreFrontCardPill } from "@@/store-fronts/shared/store-front-card-pill";
import { css, useTheme } from "@emotion/react";
import {
    ColorSet,
    ProductId,
    RemSize,
    Translatable,
    isAlfredItemPaused,
    translation,
} from "@towni/common";
import * as React from "react";
import { Link } from "react-router-dom";

// type Props = {};
const OneSquareSection = (props: {
    productId: ProductId;
    title?: Translatable;
    subtitle?: Translatable;
    actionText?: Translatable;
    colorSet?: ColorSet;
    className?: string;
}) => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    const getProductId = useProductIdWithFallback();
    const productId = React.useMemo(() => getProductId(props.productId), []);
    const [product, productQuery] = useProductLegacy(productId);

    // Hide card if product is paused
    const isPaused = isAlfredItemPaused(product?.status);
    if (isPaused) return null;

    const productImageRef = product?.meta.images[0];
    return (
        <SectionArea
            sectionAreaId={"one-square-section_" + productId}
            className={props.className}
            padding={{ top: 10 }}>
            <Link to={`/products/${productId}`}>
                <FlexColumn
                    fillParentWidth
                    padding={{ leftRight: 20 }}
                    crossAxis="flex-start"
                    shrink={0}>
                    {productQuery.isPending ? (
                        <ImageShimmer radius={16} maxHeight="335px" />
                    ) : (
                        <SectionAreaCard
                            aspectRatio={isDesktop ? "3:2" : "1:1"}
                            radius={16}
                            background={{
                                fit: "COVER",
                                imageReference: productImageRef,
                                imageSize: {
                                    imageWidth: 500,
                                },
                            }}>
                            <FlexColumn
                                shrink={0}
                                grow={1}
                                padding={{ all: 20 }}
                                background={{
                                    linearGradient: darkeningGradient,
                                }}
                                mainAxis="space-between"
                                crossAxis="center">
                                <FlexRow fillParentWidth mainAxis="flex-end">
                                    <StoreFrontCardPill
                                        text={
                                            props.actionText ??
                                            translation({
                                                sv: "Läs mer",
                                                en: "Read more",
                                            })
                                        }
                                        colorSet={props.colorSet}
                                    />
                                </FlexRow>
                                <FlexColumn fillParentWidth shrink={0}>
                                    <TextBox
                                        text={
                                            props.title ?? product?.meta.title
                                        }
                                        weight="900"
                                        size={2 as RemSize}
                                        color={theme.colors.white}
                                        styling={css`
                                            text-shadow: 0 4px 4px
                                                rgba(0, 0, 0, 0.25);
                                        `}
                                    />
                                    <VerticalDivider />
                                    <TextBox
                                        text={
                                            props.subtitle ??
                                            product?.meta.subtitle
                                        }
                                        weight="500"
                                        size={1 as RemSize}
                                        color={theme.colors.white}
                                        styling={css`
                                            text-shadow: 0 4px 4px
                                                rgba(0, 0, 0, 0.25);
                                        `}
                                    />
                                    <Conditional
                                        when={!!product?.meta.kicker}
                                        render={() => (
                                            <>
                                                <VerticalDivider S />
                                                <TextBox
                                                    text={product?.meta.kicker}
                                                    weight="800"
                                                    case="UPPERCASE"
                                                    size={0.75 as RemSize}
                                                    color={theme.colors.white}
                                                    styling={css`
                                                        text-shadow: 0 4px 4px
                                                            rgba(0, 0, 0, 0.25);
                                                    `}
                                                />
                                            </>
                                        )}
                                    />
                                </FlexColumn>
                            </FlexColumn>
                        </SectionAreaCard>
                    )}
                </FlexColumn>
            </Link>
        </SectionArea>
    );
};

export { OneSquareSection };
