import { asDate, isoAndUnixFactory, IsoAndUnixTimestamp } from "@towni/common";
import { toZonedTime } from "date-fns-tz";
import { Except } from "type-fest";
import { EndOrPartOfRangeStatus } from "./end-or-part-of-range-data";

/**
 * Meta data about a date's selectability.
 * Contains information about if the date can be used as start, part or end of a range.
 * AsEndOfRange and asPartOfRange can be used to determine if the date can be used as part or end of a range starting at a specific date.
 */
type SelectableDate = {
    key: string;
    date: IsoAndUnixTimestamp;
    asStartOfRange: boolean;
    asEndOfRange: EndOrPartOfRangeStatus;
    asPartOfRange: EndOrPartOfRangeStatus;
    possibleRangeKeys: string[];
};

const selectableDateFactory = (
    params: Pick<SelectableDate, "date"> &
        Partial<Except<SelectableDate, "key" | "date">>,
    timeZone: string,
): SelectableDate => {
    const dateZoned = toZonedTime(asDate(params.date), timeZone);
    return {
        key: `${dateZoned.toISOString()}_${!!params.asStartOfRange}_${!!params.asEndOfRange?.possible}_${!!params.asPartOfRange?.possible}`,
        date: isoAndUnixFactory(dateZoned),
        asStartOfRange: !!params.asStartOfRange,
        asEndOfRange: params.asEndOfRange
            ? params.asEndOfRange
            : {
                  possible: false,
                  withStartAt: undefined,
              },
        asPartOfRange: params.asPartOfRange
            ? params.asPartOfRange
            : {
                  possible: false,
                  withStartAt: undefined,
              },
        possibleRangeKeys: params.possibleRangeKeys ?? [],
    };
};

export { selectableDateFactory };
export type { SelectableDate };
