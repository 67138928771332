import { css } from "@emotion/react";
import { transparentColor } from "@towni/common";

const backgroundColor = transparentColor.asString;
const scrollBarThumbColor = "#ccc";
const scrollBarThumbHoverColor = "#999";
const customThinScrollbarCss = css({
    ["&::-webkit-scrollbar-track"]: {
        backgroundColor,
        border: `4px solid ${backgroundColor}`,
        backgroundClip: "padding-box",
    },
    ["&::-webkit-scrollbar"]: {
        width: 7,
        height: 7,
        backgroundColor,
    },
    ["&::-webkit-scrollbar-thumb"]: {
        borderTop: `3px solid hsla(0, 0%, 100%, 0.000001)`,
        backgroundColor: scrollBarThumbColor,
        backgroundClip: "padding-box",
        cursor: "pointer",
    },
    ["&:hover::-webkit-scrollbar-thumb"]: {
        border: 0,
        backgroundColor: scrollBarThumbHoverColor,
        cursor: "pointer",
    },
    scrollbarColor: `${scrollBarThumbHoverColor} ${backgroundColor}`,
    scrollbarWidth: "thin",
});

export { customThinScrollbarCss };
