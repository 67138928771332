import { useTheme } from "@emotion/react";
import { Except } from "type-fest";
import type { ButtonColorPropNames, ButtonProps_v2 } from "./button";
import { Button_v2 } from "./button";

const ButtonPrimaryLight = (
    props: Except<ButtonProps_v2, ButtonColorPropNames>,
) => {
    const theme = useTheme();
    return (
        <Button_v2
            {...props}
            colorSet={
                theme.isLightTheme
                    ? {
                          main: theme.colors.primary.light,
                          text: theme.colors.primary.main,
                          border: theme.colors.primary.light,
                      }
                    : {
                          main: theme.colors.primary.dark,
                          text: theme.colors.primary.text.withAlpha(0.9),
                          border: theme.colors.primary.dark,
                      }
            }>
            {props.children}
        </Button_v2>
    );
};

export { ButtonPrimaryLight };
