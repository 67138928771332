import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { DynamicIcon } from "@@/shared/icons/dynamic-icon";
import { Icon } from "@@/shared/icons/icon";
import { CheckBoxWithText } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faLock } from "@fortawesome/pro-regular-svg-icons";
import {
    accommodationQuantityTypeMeta,
    isResourceAccommodation,
    type Resource,
    type ResourceGroup,
    type ResourceId,
} from "@towni/common";

const ResourceRow = (props: {
    resource: Exclude<Resource, ResourceGroup>;
    maxSelect?: number;
    disabled?: boolean;
    locked?: boolean;
    requestedResources: ResourceId[];
    selectResource: (resourceId: ResourceId, maxSelect?: number) => void;
    deselectResource: (resourceId: ResourceId) => void;
}) => {
    const theme = useTheme();
    const resource = props.resource;
    const isChecked = props.requestedResources.includes(resource._id);
    const isAccommodationRow = isResourceAccommodation(resource);
    const disabled = !!props.disabled || !!props.locked;
    const allowPets =
        isAccommodationRow &&
        !!resource.capacity.ANIMAL?.max &&
        resource.capacity.ANIMAL.max > 0;

    return (
        <FlexRow
            fillParentWidth
            mainAxis="space-between"
            crossAxis="center"
            css={{
                padding: "10px 20px",
                backgroundColor: isChecked
                    ? theme.isLightTheme
                        ? theme.colors.primary.light.asString
                        : theme.colors.primary.dark.value.withSaturation(0.2)
                              .asString
                    : undefined,
            }}>
            <FlexRow crossAxis="center">
                <CheckBoxWithText
                    text={props.resource.meta.title}
                    subTitle={props.resource.meta.subtitle}
                    css={{ width: "100%" }}
                    checked={isChecked}
                    disabled={disabled}
                    onChange={checked => {
                        if (checked)
                            props.selectResource(
                                props.resource._id,
                                props.maxSelect,
                            );
                        else props.deselectResource(props.resource._id);
                    }}
                />
            </FlexRow>

            <FlexRow crossAxis="center">
                <Conditional when={allowPets}>
                    <DynamicIcon
                        icon={accommodationQuantityTypeMeta.ANIMAL.iconName}
                        css={{
                            opacity: disabled ? 0.3 : 1,
                            ...(isChecked
                                ? { color: theme.colors.primary.asString }
                                : {
                                      color: theme.colors.black.light30
                                          .asString,
                                  }),
                        }}
                    />
                </Conditional>
                <HorizontalDivider S />
                <Conditional when={!!props.locked}>
                    <Icon
                        icon={faLock}
                        css={{
                            opacity: disabled ? 0.3 : 1,
                            ...(isChecked
                                ? { color: theme.colors.primary.asString }
                                : {
                                      color: theme.colors.black.light30
                                          .asString,
                                  }),
                        }}
                    />
                    <HorizontalDivider />
                </Conditional>
            </FlexRow>
        </FlexRow>
    );
};

export { ResourceRow };
