import { Toaster } from "@@/toasts/context/toast-context";
import { QueryClient } from "@tanstack/react-query";
import {
    Cart,
    DeliverySelection,
    PickUpDeliverySelection,
    translation,
    VerificationError,
    zeroPrice,
} from "@towni/common";

const calculateNewDelivery = async (
    cart: Cart,
    error: VerificationError,
    toast: Toaster,
    queryClient: QueryClient,
): Promise<DeliverySelection> => {
    for (const verificationResult of error.results) {
        switch (verificationResult.type) {
            case "SHIPPING_NOT_FOUND":
            case "SHIPPING_SLOT_NOT_FOUND":
                await queryClient.invalidateQueries({ queryKey: ["shipping"] });
                toast.warning({
                    message: translation({
                        sv: "Den valda frakten kunde inte hittas",
                        en: "You chosen delivery method could not be found",
                    }),
                });
                return {
                    method: "PICK_UP",
                    slot: undefined,
                    price: zeroPrice,
                } as PickUpDeliverySelection;

            case "SHIPPING_SLOT_IS_FULL":
                await queryClient.invalidateQueries({ queryKey: ["shipping"] });
                toast.warning({
                    message: translation({
                        sv: "Den valda frakttiden är tyvärr full",
                        en: "You chosen delivery time is full",
                    }),
                });
                return {
                    //TODO Pick a new time slot?
                    method: "PICK_UP",
                    slot: undefined,
                    price: zeroPrice,
                } as PickUpDeliverySelection;
            case "OUT_OF_DATE_DELIVERY":
                toast.warning({
                    message: translation({
                        sv: "Den valda frakttiden har tyvärr utgått",
                        en: "You chosen delivery time is full",
                    }),
                });
                return {
                    //TODO Pick a new time slot?
                    method: "PICK_UP",
                    slot: undefined,
                    price: zeroPrice,
                } as PickUpDeliverySelection;
            case "PASSED":
            default:
                break;
        }
    }

    return cart.delivery;
};
export { calculateNewDelivery };
