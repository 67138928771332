import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { Conditional } from "@@/shared/conditional";
import { HorizontalLine } from "@@/shared/dividers/horizontal-line";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { PriceTextBox, TextBox, TextSpan } from "@@/shared/text";
import { LogoCircle } from "@@/store-fronts/towni/sections/providers/provider-logo-link-circle";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { RemSize, TicketGiftCard, translation, zeroPrice } from "@towni/common";

type Props = {
    ticket: TicketGiftCard;
};

const TicketGiftCardForCustomerPrintView = ({ ticket }: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    const [provider, _] = useProvider(ticket.providerId);
    return (
        <table
            cellPadding={0}
            cellSpacing={0}
            css={{
                marginTop: 10,
                padding: 0,
                backgroundColor: "#ffffff",
                color: "#000000",
                display: "block",
                position: "relative",
                "@page": {
                    size: "A3",
                },
                zoom: "120%",
                pageBreakInside: "avoid",
                breakInside: "avoid",
                pageBreakAfter: "always",
                breakAfter: "always",
                "& tr td": {
                    width: "75%",
                    display: "block",
                    position: "relative",
                    pageBreakInside: "avoid",
                    breakInside: "avoid",
                },
            }}>
            <tbody>
                <tr>
                    <td align="center">
                        <FlexColumn
                            crossAxis="center"
                            padding={{ leftRight: 100 }}>
                            <LogoCircle
                                logo={provider?.logoImageRef}
                                size={0.5 as RemSize}
                            />
                            <VerticalDivider />
                            <TextBox
                                text={provider?.meta.title}
                                size={1.5}
                                weight="900"
                            />
                            <Conditional when={!!provider?.meta.title}>
                                <VerticalDivider />
                                <TextBox
                                    text={provider?.meta.subtitle}
                                    size={1.25}
                                    color="#999999"
                                />
                            </Conditional>
                        </FlexColumn>
                        <VerticalDivider XL />
                        <FlexColumn>
                            <TextBox
                                text={ticket.meta.title}
                                size={1.25}
                                weight="900"
                                padding={{ leftRight: 20 }}
                                color={theme.colors.black}
                            />
                            <Conditional when={!!ticket.meta.subtitle}>
                                <VerticalDivider XS />
                                <TextBox
                                    text={ticket.meta.subtitle}
                                    padding={{ leftRight: 20 }}
                                    size={1.125}
                                    color={theme.colors.black.light30}
                                />
                            </Conditional>
                            <VerticalDivider M />
                        </FlexColumn>
                    </td>
                </tr>
                <Conditional when={!!ticket.meta.description}>
                    <tr>
                        <td align="center">
                            <FlexColumn
                                fillParentWidth
                                css={{
                                    position: "relative",
                                    display: translate(
                                        ticket.meta.description,
                                    )?.trim()
                                        ? "flex"
                                        : "none",
                                }}
                                padding={{ leftRight: 100 }}
                                background={{ color: theme.colors.white }}>
                                <HorizontalLine />
                                <VerticalDivider S />
                                <TextBox
                                    text={"Information"}
                                    size={1}
                                    weight="500"
                                    color={theme.colors.black.light45}
                                />
                                <VerticalDivider XS />
                                <TextBox
                                    renderMarkdown
                                    text={ticket.meta.description}
                                    size={1}
                                    color={theme.colors.black}
                                />
                                <VerticalDivider M />
                                <HorizontalLine />
                            </FlexColumn>
                        </td>
                    </tr>
                </Conditional>
                <Conditional when={!!ticket.contentBlock?.length}>
                    <tr>
                        <td align="center">
                            {ticket.contentBlock?.map(contentBlock => {
                                return (
                                    <FlexColumn
                                        key={contentBlock._id}
                                        fillParentWidth
                                        css={{ position: "relative" }}
                                        padding={{ leftRight: 100 }}
                                        background={{
                                            color: theme.colors.white,
                                        }}>
                                        <VerticalDivider S />
                                        <TextBox
                                            text={contentBlock.title}
                                            size={1}
                                            weight="500"
                                            color={theme.colors.black.light45}
                                        />
                                        <VerticalDivider XS />
                                        <TextBox
                                            renderMarkdown
                                            text={contentBlock.content}
                                            size={1}
                                            color={theme.colors.black}
                                        />
                                        <VerticalDivider M />
                                        <HorizontalLine />
                                    </FlexColumn>
                                );
                            })}
                        </td>
                    </tr>
                </Conditional>
                <tr>
                    <td align="center">
                        <FlexColumn
                            crossAxis="center"
                            padding={{ leftRight: 100 }}>
                            <FlexRow
                                fillParentWidth
                                color={theme.colors.black.light60}
                                // background={{ color: theme.colors.black.light95 }}
                                background={{ color: theme.colors.white }}
                                minHeight={60}
                                mainAxis="center"
                                padding={{ leftRight: 25, top: 15, bottom: 8 }}>
                                <TextBox
                                    text={`Detta är en värdehandling `}
                                    size={1}
                                    align="center"
                                    spin={!provider}
                                    color={theme.colors.black.light60}
                                />
                            </FlexRow>
                        </FlexColumn>
                    </td>
                </tr>
                <tr>
                    <td align="center">
                        <FlexColumn
                            crossAxis="center"
                            padding={{ leftRight: 100 }}>
                            <VerticalDivider M />
                            <VerticalDivider S />
                            <TextBox
                                text={translation({
                                    sv: "Kod",
                                    en: "Code",
                                })}
                                align="center"
                                size={0.875}
                                color={theme.colors.black.light45}
                            />
                            <TextBox
                                size={1.1875}
                                color={theme.colors.black.light45}
                                align="center"
                                css={{ userSelect: "text" }}>
                                <TextSpan
                                    text={ticket.code.slice(0, length / 2)}
                                    css={{
                                        letterSpacing: 5,
                                        paddingRight: 5,
                                    }}
                                />
                                <TextSpan
                                    text={ticket.code.slice(length / 2)}
                                    css={{
                                        letterSpacing: 5,
                                        paddingLeft: 5,
                                    }}
                                />
                            </TextBox>
                            <VerticalDivider M />
                            <TextBox
                                text="Värde"
                                align="center"
                                size={0.875}
                                color={theme.colors.black.light45}
                            />
                            <PriceTextBox
                                price={
                                    ticket.type === "GIFTCARD"
                                        ? ticket.amount
                                        : zeroPrice
                                }
                                size={1.1875}
                                color={theme.colors.black.light45}
                                align="center"
                                css={{ userSelect: "text" }}
                            />
                        </FlexColumn>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export { TicketGiftCardForCustomerPrintView };
