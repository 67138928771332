import { useFetchClient } from "@@/shared/fetch-client";
import { useTranslate } from "@@/translations/use-translate";
import {
    MutationFunction,
    useMutation,
    useQueryClient,
} from "@tanstack/react-query";
import {
    ReservationCheckInCommand,
    ReservationCheckIn_SetPendingCommand,
    ReservationCheckOutCommand,
    ResourceReservationId,
    assertNever,
    isApiError,
    translation,
} from "@towni/common";

const useReservationCheckInMutation = () => {
    const translate = useTranslate();
    const fetchClient = useFetchClient();
    const queryClient = useQueryClient();
    const mutationFn: MutationFunction<
        unknown,
        {
            statusToSet: "check-in" | "check-out" | "pending";
            resourceReservationId: ResourceReservationId;
        }
    > = async ({ statusToSet, resourceReservationId }) => {
        switch (statusToSet) {
            case "check-in": {
                if (!resourceReservationId)
                    throw new Error("resourceReservationId is undefined");
                try {
                    const firstAttemptResponse =
                        await fetchClient.post<ReservationCheckInCommand>({
                            route: `/reservations/check-ins/check-in`,
                            body: {
                                _type: "RESERVATION_CHECK_IN_COMMAND",
                                resourceReservationId,
                            },
                        });
                    return firstAttemptResponse;
                } catch (error) {
                    if (
                        isApiError(error) &&
                        error.statusCode === 409 &&
                        error.errorCode === "CONFIRM_CHECK_IN_REQUIRED"
                    ) {
                        // Ask the user if they want to force check-in
                        // in case the resource is already checked in by
                        // another reservation
                        const confirmed = confirm(
                            translate(
                                error.reason ??
                                    translation({
                                        sv: "Resursen är redan incheckad. Vill du tvinga en incheckning och ersätta den aktiva incheckning?",
                                        en: "The resource is already checked in. Do you want to force a check-in and replace the active check-in?",
                                    }),
                            ),
                        );
                        if (!confirmed) {
                            return undefined;
                        }
                        return fetchClient.post<ReservationCheckInCommand>({
                            route: `/reservations/check-ins/check-in`,
                            body: {
                                _type: "RESERVATION_CHECK_IN_COMMAND",
                                resourceReservationId,
                                forceCheckOutOfOtherReservations: true,
                            },
                        });
                    }
                    throw error;
                }
            }
            case "check-out":
                if (!resourceReservationId)
                    throw new Error("resourceReservationId is undefined");
                return fetchClient.post<ReservationCheckOutCommand>({
                    route: `/reservations/check-ins/check-out`,
                    body: {
                        _type: "RESERVATION_CHECK_OUT_COMMAND",
                        resourceReservationId,
                    },
                });
            case "pending":
                if (!resourceReservationId)
                    throw new Error("resourceReservationId is undefined");
                return fetchClient.post<ReservationCheckIn_SetPendingCommand>({
                    route: `/reservations/check-ins/set-pending`,
                    body: {
                        _type: "RESERVATION_CHECK_IN__SET_PENDING_COMMAND",
                        resourceReservationId,
                    },
                });
            default:
                assertNever(statusToSet);
        }
    };
    return useMutation({
        mutationFn,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["check-in-statuses"],
            });
        },
    });
};

export { useReservationCheckInMutation };
