import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { Paragraph, TextBox, TextEdit } from "@@/shared/text";
import { css, useTheme } from "@emotion/react";
import { faRotateRight } from "@fortawesome/pro-regular-svg-icons";
import { translation } from "@towni/common";
import { useState } from "react";
import { useCaptchaContext } from "./phone/steps/captcha.context";

const Captcha = (props: {
    onCaptchaValueSet: (captchaValue: string) => void | Promise<void>;
}) => {
    const theme = useTheme();
    const [captchaMountId, setCaptchaMountId] = useState<string>(
        Date.now().toString(),
    );
    const [captchaValue, setCaptchaValue] = useState<string>("");
    const onCaptchaValueSet = async (captchaValue: string) => {
        setCaptchaValue(captchaValue);
        await props.onCaptchaValueSet(captchaValue);
    };
    const captchaErrorText = useCaptchaContext(context => context.errorText);
    return (
        <FlexColumn crossAxis="stretch" css={{ flex: 1 }}>
            <TextBox
                text={translation({
                    sv: "Snabbkoll. Inte robot?",
                    en: "Quick check. Not a robot?",
                })}
                align="center"
                weight="700"
                size="L"
            />
            <VerticalDivider />
            <TextBox
                text={translation({
                    sv: "Verifiera du är människa genom att skriva tecknen nedan. Tack!",
                    en: "Verify that you are human by writing the characters below. Thank you!",
                })}
                align="center"
                weight="300"
                size="ML"
            />
            <VerticalDivider L />
            <FlexRow crossAxis="stretch">
                <img
                    key={captchaMountId}
                    src={`/api/misc/captcha/640/120/70?cb=${captchaMountId}`}
                    title="captcha"
                    css={{
                        display: "block",
                        flex: 1,
                        width: 50,
                        height: 60,
                        borderRadius: 5,
                        overflow: "hidden",
                    }}
                />
                <HorizontalDivider />
                <ButtonWhite
                    onClick={async () => {
                        await props.onCaptchaValueSet("");
                        setCaptchaMountId(Date.now().toString());
                    }}>
                    <Icon icon={faRotateRight} fixedWidth size="L" />
                </ButtonWhite>
            </FlexRow>
            <VerticalDivider XS />
            <FlexRow css={{ flex: 1 }}>
                <TextEdit
                    css={{
                        flex: 1,
                    }}
                    placeholder={translation({
                        sv: "Skriv tecknen ovan här",
                        en: "Write the characters above here",
                    })}
                    value={captchaValue}
                    onChange={onCaptchaValueSet}
                    type="text"
                    inputStyling={css({
                        textTransform: "uppercase",
                        fontWeight: "700",
                        "::placeholder": {
                            opacity: 0.5,
                            textTransform: "initial",
                        },
                    })}
                />
            </FlexRow>
            <Paragraph
                content={["* ", captchaErrorText]}
                css={{
                    display: captchaErrorText ? "block" : "none",
                    fontSize: "0.825rem",
                    paddingTop: 10,
                    color: theme.colors.danger.asString,
                }}
            />
        </FlexColumn>
    );
};

export { Captcha };
