import { useMe } from "@@/authentication/me/me-context";
import { Cart_Accommodation_ResourceReservationRequestRow } from "@@/carts/accommodation-checkout/cart_resource-reservation-request-row";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { TextBox } from "@@/shared/text";
import { getProviderScope } from "@@/users/scopes";
import { useTheme } from "@emotion/react";
import {
    Resource,
    ResourceReservationRequest,
    type ProviderId,
    type ResourceReservation,
} from "@towni/common";

type Props = {
    providerId: ProviderId;
    resourceReservations: (ResourceReservation | ResourceReservationRequest)[];
    resources: Resource[];
};
//WIP copied from src/carts/bookable-checkout/resource-reservation-request-list.tsx
//
const Cart_Accommodation_ResourceReservationRequestList = (props: Props) => {
    const [me] = useMe();
    const theme = useTheme();
    const scopes = getProviderScope({
        providerId: props.providerId,
        access: "read",
    });
    const hasScope = me?.scopes.some(item => scopes.includes(item)) ?? false;

    const reservations = hasScope
        ? props.resourceReservations
        : props.resourceReservations.filter(r => r.displayResourceTitle);

    if (reservations.length === 0) return null;
    return (
        <FlexColumn
            css={{
                cursor: "default",
                paddingLeft: 20,
                paddingRight: 20,
            }}>
            <VerticalDivider S />
            <ForEach
                beginWith={
                    <>
                        <TextBox
                            text={
                                reservations.length > 1
                                    ? {
                                          sv: "Reservationer",
                                          en: "Reservations",
                                      }
                                    : "Reservation"
                            }
                            dataTestId={`bd-resource-title`}
                            color={theme.colors.white.main.light50}
                        />
                        <VerticalDivider XXS />
                    </>
                }
                divider={<VerticalDivider XXS />}
                itemOf={reservations}
                getKey={r => r._id}>
                {(reservation, index) => (
                    <Cart_Accommodation_ResourceReservationRequestRow
                        key={reservation._id}
                        resourceReservation={reservation}
                        displayTime={false}
                        index={index}
                        resource={props.resources.find(
                            r => r._id === reservation.resourceId,
                        )}
                    />
                )}
            </ForEach>
            <VerticalDivider S />
        </FlexColumn>
    );
};

export { Cart_Accommodation_ResourceReservationRequestList };
