import { timelineSessionFactory } from "@@/backoffice/for-providers/timeline/extended-session";
import { HydrateAtoms, HydrateAtomsValueMap } from "@@/shared/hydrate-atoms";
import {
    createQueryKey,
    useApiResources,
} from "@@/shared/state/use-api-resources";
import {
    tanstackMultiQueryAtom,
    useTanstackMultiPostQuery,
    type TanstackQueryOptions,
} from "@@/shared/use-tanstack-query";
import {
    BookableSession,
    emptyArrayOf,
    ProductId,
    TimeRange,
    UnixTimestamp,
    type Product,
} from "@towni/common";
import { atom, WritableAtom } from "jotai";
import { ScopeProvider } from "jotai-scope";
import { useMemo } from "react";
import { productMainQueryKey } from "../main-query-key";
import { sessionMainQueryKey } from "../main-sessions-query-key";
import { fetchSessions } from "./sessions.fetcher";

const useSessions = (productId: ProductId | undefined, timeRange?: TimeRange) =>
    useApiResources<BookableSession>(
        createQueryKey(sessionMainQueryKey, [
            productId ?? "",
            ...(timeRange
                ? [
                      timeRange.start.unix.toString(),
                      timeRange.end.unix.toString(),
                  ]
                : []),
        ]),
        fetchSessions(productId, timeRange),
        {
            enabled: !!productId,
        },
    );

const useSessionsForProducts = (
    productIds: ProductId[],
    timeRange?: TimeRange,
    options: TanstackQueryOptions<BookableSession[]> = {},
) => {
    const ids = productIds.sort() ?? emptyArrayOf<ProductId>();
    return useTanstackMultiPostQuery<
        {
            productIds: ProductId[];
            to?: UnixTimestamp;
            from?: UnixTimestamp;
        },
        BookableSession
    >(
        `/sessions/for-products`,
        ["sessions", ...ids],
        {
            productIds: ids,
            from: timeRange?.start.unix,
            to: timeRange?.end.unix,
        },
        {
            enabled: !!ids.length,
            ...options,
        },
    );
};

const _productIdsAtom = atom<ProductId[]>([]);
const _timeRangeAtom = atom<TimeRange | undefined>(undefined);
const _sessionsForProductsQueryOptionsAtom = atom<
    TanstackQueryOptions<BookableSession[]>
>({});
const sessionsForProductsQueryAtom = tanstackMultiQueryAtom<BookableSession>(
    get => {
        const productIds = get(_productIdsAtom);
        const timeRange = get(_timeRangeAtom);
        const options = get(_sessionsForProductsQueryOptionsAtom);
        return {
            route: `/sessions/for-products`,
            body: {
                productIds,
                from: timeRange?.start.unix,
                to: timeRange?.end.unix,
            },
            queryKey: [productMainQueryKey, ...productIds],
            options: { enabled: !!productIds.length, ...options },
        };
    },
);
const timelineSessionsForProductsAtom = atom(get => {
    const query = get(sessionsForProductsQueryAtom);
    const timelineSessions =
        query.data?.map(session => timelineSessionFactory(session)) ?? [];
    return timelineSessions;
});

type Props = {
    readonly productIds: ProductId[];
    readonly timeRange?: TimeRange;
    readonly queryOptions?: TanstackQueryOptions<Product[]>;
    readonly children: React.ReactNode;
};
const SessionsForProductsAtomScopeProvider = (props: Props) => {
    const atomValues = useMemo((): HydrateAtomsValueMap => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return new Map<WritableAtom<unknown, [any], unknown>, unknown>([
            [_productIdsAtom, props.productIds],
            [_timeRangeAtom, props.timeRange],
            [_sessionsForProductsQueryOptionsAtom, props.queryOptions ?? {}],
        ]);
    }, [props.queryOptions, props.productIds, props.timeRange]);

    return (
        <ScopeProvider
            atoms={[
                _productIdsAtom,
                _timeRangeAtom,
                _sessionsForProductsQueryOptionsAtom,
                sessionsForProductsQueryAtom,
                timelineSessionsForProductsAtom,
            ]}>
            <HydrateAtoms atomValues={atomValues}>
                {props.children}
            </HydrateAtoms>
        </ScopeProvider>
    );
};

const sessionsForProductsAtoms = {
    sessionsForProductsQueryAtom,
    timelineSessionsForProductsAtom,
};

export {
    sessionsForProductsAtoms,
    SessionsForProductsAtomScopeProvider,
    useSessions,
    useSessionsForProducts,
};
