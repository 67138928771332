import { css } from "@emotion/react";
import {
    blackColor,
    colorAsString,
    colorFactory,
    ColorItem,
    colorSetFactory,
    ColorTheme,
    createSizeTheme,
    defaultSize,
    transparentColor,
    whiteColor,
} from "@towni/common";
import { Transition } from "framer-motion";
import { AppTheme } from "../theme";

const defaultBorderColor = colorFactory({ hue: 0, saturation: 0, light: 0.9 });
const defaultShadowColor = colorFactory({
    hue: 0,
    saturation: 0,
    light: 0.1,
    alpha: 0.1,
});
const defaultDarkBorderColor = colorFactory({
    hue: 0,
    saturation: 0,
    light: 0.1,
});
const defaultDarkShadowColor = colorFactory({
    hue: 0,
    saturation: 0,
    light: 0.9,
    alpha: 0.1,
});
const defaultPlaceholderColor = colorFactory({
    hue: 0,
    saturation: 0,
    light: 0.6,
});
const defaultDarkPlaceholderColor = colorFactory({
    hue: 0,
    saturation: 0,
    light: 0.4,
});
const defaultColorSet = colorSetFactory({
    main: whiteColor,
    accent: colorFactory({ hue: 201, saturation: 0.98, light: 0.41 }),
    dark: colorFactory({ hue: 0, saturation: 0, light: 0.8 }),
    light: whiteColor,
    background: whiteColor,
    text: blackColor,
    placeholder: defaultPlaceholderColor,
    border: defaultBorderColor,
    shadow: defaultShadowColor,
});
const defaultDarkColorSet = colorSetFactory({
    main: blackColor,
    accent: colorFactory({ hue: 201, saturation: 0.98, light: 0.41 }),
    dark: colorFactory({ hue: 0, saturation: 0, light: 0.8 }),
    light: blackColor,
    background: colorFactory({ hue: 223, saturation: 0.09, light: 0.15 }),
    text: whiteColor,
    placeholder: defaultDarkPlaceholderColor,
    border: defaultDarkBorderColor,
    shadow: defaultDarkShadowColor,
});
const lightGrayColorSet = colorSetFactory({
    // hsl(0, 0%, 65%))
    main: colorFactory({ hue: 0, saturation: 0, light: 0.65 }),
    // hsl(0, 0%, 35%)
    dark: colorFactory({ hue: 0, saturation: 0, light: 0.35 }),
    // hsl(0, 0%, 95%)
    light: colorFactory({ hue: 0, saturation: 0, light: 0.95 }),
    // hsl(0, 0%, 95%)
    background: colorFactory({ hue: 0, saturation: 0, light: 0.95 }),
    // hsl(0, 0%, 100%)
    text: colorFactory({ hue: 0, saturation: 0, light: 1 }),
    // hsl(0, 0%, 60%)
    placeholder: defaultPlaceholderColor,
    // hsl(0, 0%, 90%)
    border: defaultBorderColor,
    // hsla(0, 0%, 10%, 10%)
    shadow: defaultShadowColor,
});
const whiteColorSet = colorSetFactory({
    // hsl(0, 0%, 100%)
    main: colorFactory({ hue: 0, saturation: 0, light: 1 }),
    // hsl(0, 0%, 80%)
    dark: colorFactory({ hue: 0, saturation: 0, light: 0.8 }),
    // hsl(0, 0%, 100%)
    light: colorFactory({ hue: 0, saturation: 0, light: 1 }),
    // hsl(0, 0%, 100%)
    background: colorFactory({ hue: 0, saturation: 0, light: 1 }),
    // hsl(0, 0%, 0%)
    text: blackColor,
    // hsl(0, 0%, 60%)
    placeholder: defaultPlaceholderColor,
    // hsl(0, 0%, 90%)
    border: defaultBorderColor,
    // hsla(0, 0%, 10%, 10%)
    shadow: defaultShadowColor,
});
const purpleColorSet = colorSetFactory({
    // hsl(262, 33%, 39%)
    main: colorFactory({ hue: 262, saturation: 0.33, light: 0.39 }),
    // hsl(262,33%, 25%)
    dark: colorFactory({ hue: 262, saturation: 0.33, light: 0.25 }),
    // hsl(262,75%, 95%)
    light: colorFactory({ hue: 262, saturation: 0.75, light: 0.95 }),
    // hsl(262,75%, 95%)
    background: colorFactory({ hue: 262, saturation: 0.75, light: 0.95 }),
    // hsl(0, 0%, 100%)
    text: colorFactory({ hue: 0, saturation: 0, light: 1 }),
    // hsl(0, 0%, 60%)
    placeholder: defaultPlaceholderColor,
    // hsl(0, 0%, 90%)
    border: defaultBorderColor,
    // hsla(0, 0%, 10%, 10%)
    shadow: defaultShadowColor,
});
const blueColorSet = colorSetFactory({
    // hsl(210, 60%, 45%)
    main: colorFactory({ hue: 210, saturation: 0.6, light: 0.45 }),
    // hsl(210, 60%, 30%)
    dark: colorFactory({ hue: 210, saturation: 0.6, light: 0.3 }),
    // hsl(210, 90%, 95%)
    light: colorFactory({ hue: 210, saturation: 0.9, light: 0.95 }),
    // hsl(210, 90%, 95%)
    background: colorFactory({ hue: 210, saturation: 0.9, light: 0.95 }),
    // hsl(0, 0%, 100%)
    text: colorFactory({ hue: 0, saturation: 0, light: 1 }),
    // hsl(0, 0%, 60%)
    placeholder: defaultPlaceholderColor,
    // hsl(0, 0%, 90%)
    border: defaultBorderColor,
    // hsla(0, 0%, 10%, 10%)
    shadow: defaultShadowColor,
});
const orangeColorSet = colorSetFactory({
    // Matching #dd9536
    main: colorFactory({ hue: 35, saturation: 0.71, light: 0.54 }),
    // Darkened shade
    dark: colorFactory({ hue: 35, saturation: 0.74, light: 0.25 }), // Assuming a darkened shade
    // Lightened shade
    light: colorFactory({ hue: 35, saturation: 0.74, light: 0.95 }), // Assuming a lightened shade
    // Background color
    background: colorFactory({ hue: 35, saturation: 0.74, light: 0.95 }), // Assuming same as light
    // Default text color
    text: colorFactory({ hue: 0, saturation: 0, light: 1 }),
    // Placeholder text color
    placeholder: defaultPlaceholderColor,
    // Default border color
    border: defaultBorderColor,
    // Default shadow color
    shadow: defaultShadowColor,
});

const lightColorTheme: ColorTheme = {
    _type: "COLOR_THEME",
    default: defaultColorSet,
    primary: colorSetFactory({
        main: colorFactory({ hue: 167, saturation: 0.86, light: 0.33 }), // hsl(167, 86%, 33%),
        background: colorFactory({ hue: 167, saturation: 0.86, light: 0.33 }), // hsl(167, 86%, 33%),
        border: colorFactory({ hue: 167, saturation: 0.86, light: 0.33 }), // hsl(167, 86%, 33%),
        dark: colorFactory({ hue: 167, saturation: 0.86, light: 0.2 }), // hsl(167, 86%, 20%),
        light: colorFactory({ hue: 167, saturation: 0.6, light: 0.95 }), // hsl(167, 60%, 95%),
        placeholder: colorFactory({ hue: 0, saturation: 0, light: 0.8 }), // hsl(0, 0%, 80%),
        shadow: colorFactory({ hue: 167, saturation: 0.86, light: 0.8 }), // hsl(167, 86%, 80%),
        text: whiteColor, // hsl(0, 0%, 100%),
    }),
    secondary: colorSetFactory({
        main: colorFactory({ hue: 338, saturation: 0.8, light: 0.6 }), // #EB4783,
        dark: colorFactory({ hue: 338, saturation: 0.8, light: 0.3 }),
        light: colorFactory({ hue: 338, saturation: 0.8, light: 0.96 }),
        background: colorFactory({ hue: 338, saturation: 0.8, light: 0.6 }),
        text: colorFactory({ hue: 0, saturation: 0, light: 1 }),
        placeholder: defaultPlaceholderColor,
        border: defaultBorderColor,
        shadow: defaultShadowColor,
    }),
    tertiary: colorSetFactory({
        main: colorFactory({ hue: 58, saturation: 0.95, light: 0.7 }), // hsl(58, 95%, 70%),
        dark: colorFactory({ hue: 58, saturation: 0.8, light: 0.7 }),
        light: colorFactory({ hue: 58, saturation: 0.95, light: 0.9 }),
        background: colorFactory({ hue: 58, saturation: 0.95, light: 0.7 }),
        text: colorFactory({ hue: 0, saturation: 0, light: 0.2 }),
        placeholder: defaultPlaceholderColor,
        border: defaultBorderColor,
        shadow: defaultShadowColor,
    }),
    quaternary: colorSetFactory({
        main: colorFactory({ hue: 206, saturation: 0.69, light: 0.7 }),
        dark: colorFactory({ hue: 206, saturation: 0.8, light: 0.7 }),
        light: colorFactory({ hue: 206, saturation: 0.69, light: 0.9 }),
        background: colorFactory({ hue: 206, saturation: 0.69, light: 0.7 }),
        text: colorFactory({ hue: 0, saturation: 0, light: 0.2 }),
        placeholder: defaultPlaceholderColor,
        border: defaultBorderColor,
        shadow: defaultShadowColor,
    }),
    purple: purpleColorSet,
    blue: blueColorSet,
    orange: orangeColorSet,
    lightGray: lightGrayColorSet,
    warning: colorSetFactory({
        main: colorFactory({ hue: 34, saturation: 0.71, light: 0.54 }),
        dark: colorFactory({ hue: 34, saturation: 0.71, light: 0.34 }),
        light: colorFactory({ hue: 58, saturation: 1, light: 0.89 }),
        // light: colorFactory({ hue: 34, saturation: 0.71, light: 0.74 }),
        background: colorFactory({ hue: 34, saturation: 0.71, light: 0.54 }),
        border: colorFactory({ hue: 34, saturation: 0.71, light: 0.54 }),
        placeholder: defaultPlaceholderColor,
        shadow: defaultShadowColor,
        text: whiteColor,
    }),
    information: colorSetFactory({
        main: colorFactory({ hue: 201, saturation: 0.98, light: 0.41 }),
        dark: colorFactory({ hue: 201, saturation: 0.98, light: 0.2 }),
        light: colorFactory({ hue: 201, saturation: 0.98, light: 0.67 }),
        background: colorFactory({ hue: 201, saturation: 0.98, light: 0.41 }),
        text: whiteColor,
        placeholder: colorFactory({ hue: 0, saturation: 0, light: 0.8 }),
        border: colorFactory({ hue: 201, saturation: 0.98, light: 0.33 }),
        shadow: colorFactory({ hue: 201, saturation: 0.98, light: 0.8 }),
    }),
    danger: colorSetFactory({
        background: colorFactory({ hue: 0, saturation: 0.79, light: 0.63 }),
        border: colorFactory({ hue: 0, saturation: 0.79, light: 0.63 }),
        placeholder: defaultPlaceholderColor,
        main: colorFactory({ hue: 0, saturation: 0.79, light: 0.63 }),
        dark: colorFactory({ hue: 0, saturation: 0.79, light: 0.43 }),
        light: colorFactory({ hue: 0, saturation: 0.68, light: 0.96 }),
        shadow: defaultShadowColor,
        text: whiteColor,
    }),
    textInput: colorSetFactory({
        background: colorFactory({ hue: 209, saturation: 0.4, light: 0.98 }),
        border: colorFactory({ hue: 240, saturation: 0.2, light: 0.92 }),
        placeholder: defaultPlaceholderColor,
        main: colorFactory({ hue: 209, saturation: 0.4, light: 0.98 }),
        dark: colorFactory({ hue: 209, saturation: 0.4, light: 0.78 }),
        light: colorFactory({ hue: 209, saturation: 0.4, light: 0.1 }),
        shadow: defaultShadowColor,
        text: colorFactory({ hue: 0, saturation: 0, light: 0 }),
    }),
    disabled: colorSetFactory({
        background: colorFactory({ hue: 0, saturation: 0, light: 0.95 }),
        border: colorFactory({ hue: 0, saturation: 0, light: 0.95 }),
        placeholder: colorFactory({ hue: 0, saturation: 0, light: 0.75 }),
        main: colorFactory({ hue: 0, saturation: 0, light: 0.95 }),
        dark: colorFactory({ hue: 0, saturation: 0, light: 0.85 }),
        light: colorFactory({ hue: 0, saturation: 0, light: 0.98 }),
        shadow: defaultShadowColor,
        text: colorFactory({ hue: 0, saturation: 0, light: 0.75 }),
    }),
    white: whiteColorSet,
    black: blackColor,
    transparent: transparentColor,
};
const darkColorTheme: ColorTheme = {
    _type: "COLOR_THEME",
    default: defaultDarkColorSet,
    disabled: colorSetFactory({
        background: colorFactory({ hue: 0, saturation: 0, light: 0.05 }),
        border: colorFactory({ hue: 0, saturation: 0, light: 0.05 }),
        placeholder: colorFactory({ hue: 0, saturation: 0, light: 0.25 }),
        main: colorFactory({ hue: 0, saturation: 0, light: 0.05 }),
        dark: colorFactory({ hue: 0, saturation: 0, light: 0.15 }),
        light: colorFactory({ hue: 0, saturation: 0, light: 0.02 }),
        shadow: defaultDarkShadowColor,
        text: colorFactory({ hue: 0, saturation: 0, light: 0.25 }),
    }),
    primary: colorSetFactory({
        main: colorFactory({ hue: 167, saturation: 0.86, light: 0.33 }), // hsl(167, 86%, 33%),
        dark: colorFactory({ hue: 167, saturation: 0.86, light: 0.2 }), // hsl(167, 86%, 20%),
        light: colorFactory({ hue: 167, saturation: 0.6, light: 0.95 }), // hsl(167, 60%, 95%),
        background: colorFactory({ hue: 167, saturation: 0.86, light: 0.33 }), // hsl(167, 86%, 33%),
        text: whiteColor, // hsl(0, 0%, 100%),
        placeholder: colorFactory({ hue: 0, saturation: 0, light: 0.8 }), // hsl(0, 0%, 80%),
        border: colorFactory({ hue: 167, saturation: 0.86, light: 0.33 }), // hsl(167, 86%, 33%),
        shadow: colorFactory({ hue: 167, saturation: 0.86, light: 0.8 }), // hsl(167, 86%, 80%),
    }),
    secondary: colorSetFactory({
        main: colorFactory({ hue: 338, saturation: 0.8, light: 0.6 }),
        dark: colorFactory({ hue: 338, saturation: 0.8, light: 0.3 }),
        light: colorFactory({ hue: 338, saturation: 0.8, light: 0.96 }),
        background: colorFactory({ hue: 338, saturation: 0.8, light: 0.6 }),
        text: colorFactory({ hue: 0, saturation: 0, light: 1 }),
        placeholder: defaultPlaceholderColor,
        border: defaultBorderColor,
        shadow: defaultShadowColor,
    }),
    tertiary: colorSetFactory({
        main: colorFactory({ hue: 58, saturation: 0.95, light: 0.7 }),
        dark: colorFactory({ hue: 58, saturation: 0.8, light: 0.7 }),
        light: colorFactory({ hue: 58, saturation: 0.95, light: 0.9 }),
        background: colorFactory({ hue: 58, saturation: 0.95, light: 0.7 }),
        text: colorFactory({ hue: 0, saturation: 0, light: 0.2 }),
        placeholder: defaultPlaceholderColor,
        border: defaultBorderColor,
        shadow: defaultShadowColor,
    }),
    quaternary: colorSetFactory({
        main: colorFactory({ hue: 206, saturation: 0.69, light: 0.7 }),
        dark: colorFactory({ hue: 206, saturation: 0.8, light: 0.7 }),
        light: colorFactory({ hue: 206, saturation: 0.69, light: 0.9 }),
        background: colorFactory({ hue: 206, saturation: 0.69, light: 0.7 }),
        text: colorFactory({ hue: 0, saturation: 0, light: 0.2 }),
        placeholder: defaultPlaceholderColor,
        border: defaultBorderColor,
        shadow: defaultShadowColor,
    }),
    purple: purpleColorSet,
    blue: blueColorSet,
    orange: orangeColorSet,
    lightGray: lightGrayColorSet,
    warning: colorSetFactory({
        main: colorFactory({ hue: 34, saturation: 0.71, light: 0.54 }),
        dark: colorFactory({ hue: 34, saturation: 0.71, light: 0.34 }),
        light: colorFactory({ hue: 58, saturation: 1, light: 0.89 }),
        background: colorFactory({ hue: 34, saturation: 0.71, light: 0.54 }),
        border: colorFactory({ hue: 34, saturation: 0.71, light: 0.54 }),
        placeholder: defaultPlaceholderColor,
        shadow: defaultShadowColor,
        text: whiteColor,
    }),
    information: colorSetFactory({
        main: colorFactory({ hue: 201, saturation: 0.98, light: 0.41 }),
        dark: colorFactory({ hue: 201, saturation: 0.98, light: 0.2 }),
        light: colorFactory({ hue: 201, saturation: 0.98, light: 0.67 }),
        background: colorFactory({ hue: 201, saturation: 0.98, light: 0.41 }),
        text: whiteColor,
        placeholder: colorFactory({ hue: 0, saturation: 0, light: 0.8 }),
        border: colorFactory({ hue: 201, saturation: 0.98, light: 0.33 }),
        shadow: colorFactory({ hue: 201, saturation: 0.98, light: 0.8 }),
    }),
    danger: colorSetFactory({
        background: colorFactory({ hue: 0, saturation: 0.79, light: 0.63 }),
        border: colorFactory({ hue: 0, saturation: 0.79, light: 0.63 }),
        placeholder: defaultPlaceholderColor,
        main: colorFactory({ hue: 0, saturation: 0.79, light: 0.63 }),
        dark: colorFactory({ hue: 0, saturation: 0.79, light: 0.43 }),
        light: colorFactory({ hue: 0, saturation: 0.68, light: 0.96 }),
        shadow: defaultShadowColor,
        text: whiteColor,
    }),
    textInput: colorSetFactory({
        background: colorFactory({ hue: 209, saturation: 0.4, light: 0.02 }),
        border: colorFactory({ hue: 240, saturation: 0.2, light: 0.08 }),
        placeholder: defaultDarkPlaceholderColor,
        main: colorFactory({ hue: 209, saturation: 0.4, light: 0.02 }),
        dark: colorFactory({ hue: 209, saturation: 0.4, light: 0.22 }),
        light: colorFactory({ hue: 209, saturation: 0.4, light: 0.9 }),
        shadow: defaultDarkShadowColor,
        text: colorFactory({ hue: 0, saturation: 0, light: 1 }),
    }),
    white: whiteColorSet,
    black: blackColor,
    transparent: transparentColor,
};
const defaulSpringConfig: Transition = {
    type: "spring",
    stiffness: 400,
    damping: 40,
    mass: 1,
};

const lightTheme: AppTheme = {
    isLightTheme: true,
    isDarkTheme: false,
    colors: lightColorTheme,
    radius: 5,
    fontFamily: "'Lato','Lucida Grande',Tahoma,Sans-Serif",
    input: { placeholder: { fontWeight: "400" } },
    sizes: createSizeTheme(defaultSize),
    spring: defaulSpringConfig,
};
const darkTheme: AppTheme = {
    isLightTheme: false,
    isDarkTheme: true,
    colors: darkColorTheme,
    radius: 5,
    fontFamily: "'Lato','Lucida Grande',Tahoma,Sans-Serif",
    input: { placeholder: { fontWeight: "400" } },
    sizes: createSizeTheme(defaultSize),
    spring: defaulSpringConfig,
};

// QUICKFIX, MOVE linkCSS TO BETTER LOCATION IN THEME
const linkCss = (color: ColorItem = lightTheme.colors.primary) => css`
    color: ${colorAsString(color)};
    :link,
    :hover,
    :visited,
    :focus,
    :active {
        color: ${colorAsString(color)};
        text-decoration: none;
    }
    :hover {
        color: ${lightTheme.colors.secondary.asString};
        text-decoration: underline;
    }
    cursor: pointer;
`;

const darkBackgroundColor: ColorItem = "17191c";

const darkGlobalCssOverride = css`
    html,
    body {
        background-color: ${darkBackgroundColor};
        color: ${darkColorTheme.default.text.asString};
    }
    input,
    select,
    textarea,
    button {
        color: ${darkColorTheme.default.text.asString};
    }
    textarea,
    input {
        ::placeholder {
            color: ${darkColorTheme.default.placeholder.asString};
        }
    }
    #root {
        background-color: ${darkColorTheme.default.background.asString};
    }
`;

const globalCss = css`
    /* Don't show arrow buttons on input of type numer: */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
        -moz-appearance: textfield; /* Firefox */
    }

    html {
        font-family: ${lightTheme.fontFamily};
        font-size: ${lightTheme.sizes.base}px;
        letter-spacing: 0.01em;
        width: 100vw;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
    }
    body {
        /* PWA */
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        overflow: hidden;

        /* Other */
        width: 100vw;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        padding: 0 !important;
        margin: 0;

        justify-content: stretch;
        align-items: center;
        display: flex;

        font-family: ${lightTheme.fontFamily};
        font-size: ${lightTheme.sizes.base}px;
        letter-spacing: 0.01em;

        /* color: ${lightColorTheme.default.text.asString}; */
        background-color: ${lightColorTheme.black.light95.asString};
        user-select: none;
    }
    a,
    div,
    body,
    head,
    header,
    footer,
    section,
    textarea,
    input {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    textarea,
    select,
    input,
    button {
        outline: none;
    }
    input,
    select,
    textarea,
    button {
        border: none;
        /* color: ${lightColorTheme.textInput.text.asString}; */
        font-family: inherit;
    }
    textarea,
    input {
        ::placeholder {
            color: ${lightColorTheme.textInput.placeholder.asString};
            font-weight: ${lightTheme.input.placeholder.fontWeight};
            font-family: ${lightTheme.fontFamily};
        }
    }
    ul,li: {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    a {
        :link,
        :hover,
        :visited,
        :focus,
        :active {
            color: "unset";
            text-decoration: none;
        }
        :hover {
            color: "unset";
            text-decoration: none;
        }
    }
    #app-container {
        position: relative;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        /* max-width: 500px; */
        min-height: 100%;
        overflow-y: visible;
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
    #root {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: ${lightTheme.sizes.pageMaxWidthDesktop}px;
        height: 100%;
        min-height: 100%;
        background-color: ${lightColorTheme.default.background.asString};
        overflow-y: hidden; /* has to be scroll, not auto */
        -webkit-overflow-scrolling: touch;
        overflow-x: hidden;
        /* box-shadow: 0px 0px 40px -20px #000000; */
    }

    #portal,
    #toasts,
    #overlays,
    #cookiebanner,
    #fixed-buttons {
        position: absolute;
        overflow: hidden;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 7777;
    }
    #overlays {
        z-index: 9999;
    }
    #toasts {
        z-index: 8888;
    }
    #fixed-buttons {
        z-index: 555;
    }
    textarea {
        resize: vertical;
    }
`;

export {
    darkBackgroundColor,
    darkColorTheme,
    darkGlobalCssOverride,
    darkTheme,
    globalCss,
    lightColorTheme,
    lightTheme,
    linkCss,
};
