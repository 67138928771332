import {
    useFetch,
    useFetchMultiple,
    useFetchMultipleForSingle,
} from "@@/shared/generic-fetchers";
import { useTanstackMultiQueryAtom } from "@@/shared/use-tanstack-query";
import { ProviderId, Section } from "@towni/common";

const mainQueryKey = "sections";
const mainRoute = "sections";

const useSection = (id?: string) =>
    useFetch<Section>(mainQueryKey, mainRoute, id);
const useSections = (ids?: string[]) =>
    useFetchMultiple<Section>(mainQueryKey, mainRoute, ids);
const useSectionsForProvider = (providerId?: ProviderId) => {
    return useFetchMultipleForSingle<Section>({
        mainQueryKey,
        mainRoute,
        fetchFor: "provider",
        id: providerId,
    });
};

const useSectionsQueryAtom = (sectionIds?: string[]) =>
    useTanstackMultiQueryAtom<Section>(
        `/sections/?ids=${sectionIds?.join(",") ?? ""}`,
        [mainQueryKey, ...(sectionIds ?? ["<unknown>"])],
        { enabled: !!sectionIds?.length },
    );
const useSectionsForProviderQueryAtom = (providerId?: ProviderId) =>
    useTanstackMultiQueryAtom<Section>(
        `/sections/for-provider/${encodeURIComponent(
            providerId ?? "<unknown>",
        )}`,
        [mainQueryKey, providerId],
        { enabled: !!providerId },
    );

export {
    useSection,
    useSections,
    useSectionsForProvider,
    useSectionsForProviderQueryAtom,
    useSectionsQueryAtom,
};
