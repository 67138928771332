import { useResourceMapQuery } from "@@/backoffice/for-providers/inventory/use-resource-map-query";
import { useStorageItemImageReference } from "@@/storage-items/queries/use-storage-item-reference";
import { AppTheme } from "@@/styles/theme";
import { Interpolation, useTheme } from "@emotion/react";
import { MappableId, ResourceId, ResourceMapConnection } from "@towni/common";
import * as React from "react";
import { useMemo } from "react";
import SVG from "react-inlinesvg";
import { useImageUrl } from "../image-url";
import {
    useFindMappableIdForResourceId,
    useHtmlElementResourceMapSelection,
} from "./use-html-element-resource-map-selection";

// const selectionColor = "#10c49e" as HexColor;

type Props = {
    width?: React.CSSProperties["width"];
    height?: React.CSSProperties["height"];
    resourceMapConnection: ResourceMapConnection;
    className?: string;
    maxSelect?: number;

    selectResource: (resourceId: ResourceId, maxSelect?: number) => void;
    deselectResource: (resourceId: ResourceId) => void;

    possibleResourceIds: Set<ResourceId>;
    requestedResources: ResourceId[];
    requiredResourceIds?: Set<ResourceId>;
};

const enabledCss: Interpolation<AppTheme> = {
    cursor: "cursor",
    opacity: 1,
    filter: "unset",
};
const disabledCss: Interpolation<AppTheme> = {
    cursor: "default",
    opacity: 0.4,
    filter: "grayscale(100%)",
};

const ResourceMapView = (props: Props) => {
    const {
        selectResource,
        possibleResourceIds,
        deselectResource,
        requestedResources,
    } = props;
    const theme = useTheme();
    const selectionColor =
        theme.colors.primary.main.value.withRelativeModifications({
            light: 0.2,
        }).asHex;
    const isSelectedCheck = (resourceId: ResourceId) =>
        props.requestedResources.includes(resourceId);

    const findMappableId = useFindMappableIdForResourceId(
        props.resourceMapConnection,
    );
    // Keep a lookup table of resources original colors
    const getSvgUrl = useImageUrl();
    const resourceMapQuery = useResourceMapQuery(
        props.resourceMapConnection.resourceMapId,
    );

    const [storageItem, _storageItemImageQuery] = useStorageItemImageReference(
        resourceMapQuery.data?.storageItemId,
    );

    const svgUrl = useMemo(
        () => getSvgUrl(storageItem),
        [getSvgUrl, storageItem],
    );
    useHtmlElementResourceMapSelection(
        props.resourceMapConnection,
        selectionColor,
        requestedResources,
    );

    // const [availableResourcesByParent] =
    //     useAvailableResourcesForProductAndSession();
    const allConnectedResourceIds = useMemo(() => {
        return Object.values(props.resourceMapConnection?.resourceMapMap || {})
            .filter(Boolean)
            .flat() as ResourceId[];
    }, [props.resourceMapConnection?.resourceMapMap]);
    const isResourceAvailable = React.useCallback(
        (resourceId: ResourceId) => {
            return possibleResourceIds.has(resourceId);
        },
        [possibleResourceIds],
    );

    const onSelect = (resourceId: ResourceId) => {
        if (!isResourceAvailable(resourceId)) return; // Do nothing if the resource is not available, it cannot be selected
        if (props.requiredResourceIds?.has(resourceId)) {
            // required, cannot be deselected
            selectResource(resourceId, props.maxSelect);
            return;
        }
        if (isSelectedCheck(resourceId)) deselectResource(resourceId);
        else selectResource(resourceId, props.maxSelect);
    };

    const svgCss: Interpolation<AppTheme> = useMemo(
        () => [
            // All mappable ids should be disabled by default
            ...(resourceMapQuery.data?.mappableIds.map(id => {
                return {
                    [`g[id='${id}']`]: disabledCss,
                };
            }) ?? []),
            allConnectedResourceIds
                .map(resourceId => {
                    const mappableId = findMappableId(resourceId);
                    const isRequired =
                        !!props.requiredResourceIds?.has(resourceId);
                    if (!mappableId) return undefined;
                    return isResourceAvailable(resourceId)
                        ? // If they are mapped and available
                          // make them clickable and show hover effect
                          {
                              [`g[id='${mappableId}']`]: enabledCss,
                              [`g[id='${mappableId}']:hover`]: {
                                  cursor: isRequired
                                      ? "not-allowed"
                                      : "pointer",
                                  opacity: isRequired
                                      ? "1 !important"
                                      : "0.75 !important",
                              },
                          }
                        : // else, just keep them disabled
                          {};
                })
                .filter(Boolean),
        ],
        [
            resourceMapQuery.data?.mappableIds,
            allConnectedResourceIds,
            findMappableId,
            props.requiredResourceIds,
            isResourceAvailable,
        ],
    );

    return (
        <div
            className={props.className}
            css={svgCss}
            onClick={event => {
                if ("id" in event.target) {
                    const element = event.target as HTMLElement;
                    const groupElement = element.closest(".selectable");

                    if (groupElement) {
                        const resourceId =
                            props.resourceMapConnection.resourceMapMap?.[
                                groupElement.id as MappableId
                            ];
                        if (resourceId) {
                            onSelect(resourceId);
                        }
                    }
                }
            }}>
            <SVG
                key={svgUrl}
                src={svgUrl ?? ""}
                width={props.width}
                height={props.height}
            />
        </div>
    );
};

export { ResourceMapView };
export type { Props as ResourceMapViewProps };
