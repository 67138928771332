import { PickedOptionalRow } from "@@/carts/bookable-checkout/picked-optional-row";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import {
    Paragraph,
    PriceTextBox,
    paragraphLineBreakFactory,
    paragraphPriceFactory,
} from "@@/shared/text";
import { useDateTimeFormatter } from "@@/shared/use-date-time-formatter";
import { useTheme } from "@emotion/react";
import {
    OrderItem,
    OrderItemAccommodationRateItem_V2,
    OrderItemAccommodation_V2,
    Price,
    TimeRange,
    datesBetweenIso,
    formatBookedTime,
    getChildAccommodationRates,
    getChildOptionals,
    granularityTranslatable,
    isOrderItemAccommodation_V2,
    priceFactory,
    sumPrices,
    zeroPrice,
} from "@towni/common";
import * as React from "react";

type Props = {
    orderItems: OrderItem[];
};

const AccommodationCost = ({ orderItems }: Props) => {
    const theme = useTheme();
    const items = orderItems.filter(isOrderItemAccommodation_V2);
    if (items.length === 0) return null;
    return (
        <FlexColumn css={{ flex: 1, label: "ACCOMMODATION_RESOURCE_COST" }}>
            {items.map(orderItem => {
                return (
                    <React.Fragment key={orderItem._id}>
                        <FlexRow
                            fillParentWidth
                            mainAxis="space-between"
                            key={orderItem._id}>
                            <Paragraph
                                content={[
                                    orderItem.meta.title,
                                    " - ",
                                    orderItem.accommodation.title,

                                    paragraphLineBreakFactory(),
                                    formatBookedTime({
                                        time: orderItem.time,
                                        currentTimezoneName: "Europe/Stockholm",
                                    }),
                                ]}
                                css={{
                                    color: theme.colors.black.light60.asString,
                                }}
                            />
                        </FlexRow>
                        <CostRow__
                            items={getChildAccommodationRates(
                                orderItem,
                                orderItems,
                            )}
                            item={orderItem}
                        />
                        <VerticalDivider size="3XS" />
                        <ForEach
                            itemOf={getChildOptionals(orderItem, orderItems)}
                            divider={<VerticalDivider size="3XS" />}>
                            {picked => <PickedOptionalRow picked={picked} />}
                        </ForEach>
                    </React.Fragment>
                );
            })}
        </FlexColumn>
    );
};

type RProps = {
    item: OrderItemAccommodation_V2;
    items: OrderItemAccommodationRateItem_V2[];
};
//Todo move
const calculateAvgNightlyPrice = (price: Price, time: TimeRange) => {
    const dates = datesBetweenIso(time.start, time.end);
    const nights = dates.length - 1;

    if (nights < 1) return [zeroPrice, 0] as const;

    const perNightPrice = priceFactory({
        amountIncludingVat: price.amountIncludingVat / nights,
        vat: price.vat,
    });

    return [perNightPrice, nights] as const;
};

const CostRow__ = (props: RProps) => {
    const theme = useTheme();
    const { items } = props;

    const [show, setShow] = React.useState(false);

    const summedPrice = sumPrices(items.map(i => i.price));
    const [perNightPrice, nights] = calculateAvgNightlyPrice(
        summedPrice,
        props.item.time,
    );
    if (!show)
        return (
            <FlexRow
                fillParentWidth
                mainAxis="space-between"
                onClick={() => setShow(true)}
                css={{
                    cursor: "pointer",
                }}>
                <Paragraph
                    content={[
                        paragraphPriceFactory({ price: perNightPrice }),
                        ` x ${nights} 
                        `,
                        granularityTranslatable(
                            "NIGHT",
                            nights > 1 ? "plural" : "singular",
                        ),
                    ]}
                    css={{
                        color: theme.colors.black.light60.asString,
                    }}
                />
                <HorizontalDivider />
                <PriceTextBox
                    price={summedPrice}
                    color={theme.colors.black.light60}
                />
            </FlexRow>
        );

    return (
        <FlexColumn>
            <ForEach itemOf={items} getKey={item => item._id}>
                {value => (
                    <FlexRow
                        fillParentWidth
                        mainAxis="space-between"
                        key={value._id}>
                        <Paragraph
                            content={[
                                value.quantity.value.toString(),
                                " x ",
                                value.meta.title,
                                // Outcommented because the meta.title contains that information
                                // " - ",
                                // formatter.formatDateMedium(value.time.start),
                            ]}
                            css={{
                                color: theme.colors.black.light60.asString,
                            }}
                        />
                        <HorizontalDivider />
                        <PriceTextBox
                            price={value.price}
                            color={theme.colors.black.light60}
                        />
                    </FlexRow>
                )}
            </ForEach>
        </FlexColumn>
    );
};

export { AccommodationCost, calculateAvgNightlyPrice };
