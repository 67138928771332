import { BookableLegacyOptional } from "@@/carts/bookable-checkout/bookable-legacy-optional";
import { Receipt_Accommodation_ResourceReservationRequestList } from "@@/receipts/accommodation/receipt_resource-reservation-request-list";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Paragraph, TextBox, paragraphTextFactory } from "@@/shared/text";
import { useDateTimeFormatter } from "@@/shared/use-date-time-formatter";
import { useTheme } from "@emotion/react";
import {
    ColorItem,
    Order,
    OrderItemAccommodation_V2,
    ResourceReservation,
    TimeRange,
    accommodationQuantityTypeMeta,
    getChildOptionals,
    joinTranslatables,
    translation,
} from "@towni/common";

type Props = {
    order: Order;
    orderItem: OrderItemAccommodation_V2;
    resourceReservations: ResourceReservation[];
    backgroundColor?: ColorItem;
};

const AccommodationReceipt_OrderItemBody = (props: Props) => {
    const theme = useTheme();
    const { order, orderItem, resourceReservations, backgroundColor } = props;

    return (
        <FlexColumn
            background={{
                color:
                    backgroundColor ??
                    theme.colors.primary.main.value.withAlpha(0.1),
            }}
            css={{ label: "bookable_receipt_order_item_body" }}>
            <VerticalDivider L />
            <FlexRow
                padding={{ leftRight: 20 }}
                mainAxis="space-between"
                crossAxis="center">
                <TextBox
                    size="L"
                    weight="800"
                    text={translation({
                        sv: "Bokningsdetaljer",
                        en: "Booking details",
                    })}
                />
                <HorizontalDivider />
            </FlexRow>
            <VerticalDivider S />
            <BookedRoom__ orderItem={orderItem} />

            <_CheckInOutTime time={orderItem.time} />
            <BookedQuantities orderItem={orderItem} />
            <Receipt_Accommodation_ResourceReservationRequestList
                resourceReservations={resourceReservations}
                providerId={orderItem.providerId}
            />
            <VerticalDivider S />

            <BookableLegacyOptional
                orderItems={getChildOptionals(orderItem, order.orderItems)}
            />
            <VerticalDivider XS />
        </FlexColumn>
    );
};

type Props2 = {
    orderItem: OrderItemAccommodation_V2;
};

const BookedQuantities = (props: Props2) => {
    const theme = useTheme();
    // const quantity = props.orderItem.quantities.map(q => {
    //     return q.quantity.value;
    // });
    // const total = sum(quantity);

    return (
        <FlexRow padding={{ leftRight: 20, topBottom: 15 }}>
            <FlexColumn width="100%">
                <TextBox
                    color={theme.colors.white.main.light50}
                    text={translation({ sv: "Gäster", en: "Guest" })}
                />
                <ForEach
                    itemOf={props.orderItem.quantities.filter(
                        r => r.quantity.value > 0,
                    )}>
                    {q => {
                        const meta = accommodationQuantityTypeMeta[q.type.type];

                        const text = joinTranslatables([
                            `${q.quantity.value} `,
                            q.quantity.value === 1
                                ? meta.name.singular
                                : meta.name.plural,
                        ]);

                        return (
                            <TextBox
                                size="ML"
                                weight="800"
                                dataTestId="bd-booked-time"
                                text={text}
                            />
                        );
                    }}
                </ForEach>
            </FlexColumn>
        </FlexRow>
    );
};
const BookedRoom__ = (props: Props2) => {
    const theme = useTheme();
    const has = !!props.orderItem.accommodation.type;
    return (
        <FlexRow padding={{ leftRight: 20, topBottom: 15 }}>
            <FlexColumn width="100%">
                <TextBox
                    color={theme.colors.white.main.light50}
                    text={translation({
                        en: "Selected accommodation:",
                        sv: "Valt boende:",
                    })}
                />

                <VerticalDivider XXS />
                <TextBox
                    size="ML"
                    weight="800"
                    dataTestId="bd-booked-time"
                    text={props.orderItem.accommodation.title}
                />

                {/* Filter for old orders */}
                {has ? (
                    <Paragraph
                        css={{
                            color: theme.colors.white.main.light50.asString,
                        }}
                        content={[
                            paragraphTextFactory({
                                text: props.orderItem.accommodation.type.title,
                            }),

                            ...(props.orderItem.accommodation.type.subType
                                ? [
                                      " - ",
                                      paragraphTextFactory({
                                          text: props.orderItem.accommodation
                                              .type.subType?.title,
                                      }),
                                  ]
                                : []),
                        ]}
                    />
                ) : null}
            </FlexColumn>
        </FlexRow>
    );
};

type Props3 = {
    time: TimeRange;
};

const _CheckInOutTime = (props: Props3) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme = useTheme();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dateTimeFormatter = useDateTimeFormatter();
    return (
        <FlexRow padding={{ leftRight: 20, topBottom: 15 }}>
            <FlexColumn width="100%">
                <TextBox
                    color={theme.colors.white.main.light50}
                    text={translation({
                        sv: "Incheckning",
                        en: "Check-in from",
                    })}
                />

                <VerticalDivider XXS />
                <TextBox
                    size="ML"
                    weight="800"
                    dataTestId="bd-booked-time-check-in"
                    text={dateTimeFormatter.formatDayDateAndTimeMedium(
                        props.time.start,
                    )}
                />
            </FlexColumn>
            <FlexColumn width="100%">
                <TextBox
                    color={theme.colors.white.main.light50}
                    text={translation({
                        sv: "Utcheckning",
                        en: "Check-out latest",
                    })}
                />
                <VerticalDivider XXS />
                <TextBox
                    size="ML"
                    weight="800"
                    dataTestId="bd-booked-time-check-out"
                    text={dateTimeFormatter.formatDayDateAndTimeMedium(
                        props.time.end,
                    )}
                />
            </FlexColumn>
        </FlexRow>
    );
};

export { AccommodationReceipt_OrderItemBody };
