import { ImageV2 } from "@@/shared/pictures/image";
import { ProgressPill } from "@@/shared/pictures/slide-show-progress-pill";
import { useTheme } from "@emotion/react";
import { DurationInSeconds, ImageRef, repeatIndex } from "@towni/common";
import { AnimatePresence, Transition, Variants, motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const slideVariants: Variants = {
    in: (direction: "forward" | "backward") => ({
        position: "absolute",
        x: direction === "forward" ? "-100%" : "100%",
        y: 0,
        opacity: 0,
    }),
    center: {
        x: "0%",
        y: 0,
        zIndex: 1,
        opacity: 1,
        transitionEnd: {
            position: "relative",
        },
    },
    out: (direction: "forward" | "backward") => ({
        position: "absolute",
        zIndex: 0,
        x: direction === "forward" ? "-100%" : "100%",
        y: 0,
    }),
};

const slideTransition: Transition = {
    x: {
        stiffness: 400,
        damping: 60,
        mass: 1,
    },
    opacity: { duration: 0.2 },
};

const ImageSlideShow__ = (props: {
    readonly duration?: DurationInSeconds;
    readonly images: ImageRef[];
}) => {
    const theme = useTheme();
    const duration = props.duration || 6;
    const images = props.images;
    const counter = useRef<number>(0);

    const [[imageIndex, direction], setCurrentSlideIndex] = useState<
        [number, "forward" | "backward"]
    >([0, "forward"]);

    const showNext = useCallback(
        (direction: "forward" | "backward" = "forward") => {
            if (images.length <= 1) return;
            const nextIndex = (imageIndex + 1) % (images?.length || 1);
            if ((images?.length || 0) > 1) {
                counter.current += 1;
                setCurrentSlideIndex([nextIndex, direction]);
            }
            return;
        },
        [imageIndex, images.length],
    );

    useEffect(() => {
        const timeout = setTimeout(showNext, duration * 1000);
        return () => clearTimeout(timeout);
    }, [duration, imageIndex, showNext]);

    const progressPills = useMemo(() => {
        return repeatIndex(images.length).map(index => (
            <ProgressPill
                key={index}
                status={
                    index === imageIndex
                        ? "ACTIVE"
                        : index < imageIndex
                          ? "FINISHED"
                          : "INACTIVE"
                }
                duration={duration * 1000}
            />
        ));
    }, [images.length, imageIndex, duration]);

    return (
        <div
            css={{
                position: "relative",
                width: "100%",
                height: "40vh",
                label: "slideshow_container",
                overflow: "hidden",
                backgroundColor: theme.colors.default.background.asString,
            }}>
            <AnimatePresence initial={false} custom={direction}>
                <motion.div
                    key={imageIndex + "-" + counter.current}
                    initial="in"
                    animate="center"
                    exit="out"
                    variants={slideVariants}
                    transition={slideTransition}
                    onClick={() => showNext()}
                    drag="x"
                    dragConstraints={{ left: 0, right: 0 }}
                    dragElastic={1}
                    style={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                    }}>
                    <ImageV2
                        key={`${imageIndex}-${images?.length ?? 0}`}
                        imageSource={images?.[imageIndex]}
                        title={"image " + (imageIndex + 1)}
                        fit="cover"
                        css={{
                            width: "100%",
                            height: "100%",
                        }}
                    />
                </motion.div>
                <div
                    css={{
                        position: "absolute",
                        left: 0,
                        bottom: 20,
                        right: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 100,
                    }}>
                    {progressPills}
                </div>
            </AnimatePresence>
        </div>
    );
};

export { ImageSlideShow__ as _ImageSlideShow };
