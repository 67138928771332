import { useWizardDispatch } from "@@/pages/wizard-context";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { CircleSpinner } from "@@/shared/spinners/circle-spinner";
import { TextBox } from "@@/shared/text";
import { initiateStripePaymentCommandFactory } from "@towni/common";
import { AnimatePresence, motion } from "framer-motion";
import * as React from "react";
import { usePaymentDetailsContext } from "../../payment-details.context";
import { useStripePayment } from "../stripe-payment.context";
import { PaymentBuyButton } from "./payment-buy-button";
import { PaymentCardElement } from "./payment-card-element";
import { PaymentErrorMessage } from "./payment-error-message";
import { PaymentSaveCardCheckbox } from "./payment-save-card-checkbox";
import { PaymentStoredCardSelection } from "./payment-stored-card-selection";
import { useStripeFormSubmit } from "./use-stripe-submit";

const PaymentCardForm = () => {
    const { finish } = useWizardDispatch();
    const paymentDetails = usePaymentDetailsContext(
        context => context.paymentDetails,
    );
    const [stripePayment, stripePaymentActions] = useStripePayment();

    const [selectedCardId, setSelectedCardId] = React.useState<
        string | undefined
    >();
    const [saveCard, setSaveCard] = React.useState<boolean>(false);
    const [handleSubmit, stripeErrorMessage] = useStripeFormSubmit({
        onFinish: finish,
        saveCard,
        selectedCardExternalId: selectedCardId,
    });

    React.useEffect(() => {
        if (
            !stripePayment.intent?.intentSecret &&
            stripePayment.status !== "creating_intent"
        ) {
            if (!paymentDetails?.orderGroupId) {
                throw new Error("missing order group id");
            }
            stripePaymentActions
                .createPaymentIntent(
                    initiateStripePaymentCommandFactory({
                        orderGroupId: paymentDetails.orderGroupId,
                        delay: true,
                    }),
                    "PAYMENT CARD FORM",
                )
                .catch(error => {
                    // TODO! handle error if any
                    throw error;
                });
        }
    }, [stripePayment.intent?.intentSecret]);

    return (
        <>
            {!stripePayment.intent?.intentSecret ? (
                <FlexColumn
                    fillParentWidth
                    crossAxis="center"
                    padding={{ all: 40 }}>
                    <TextBox text="Initierar betalning..." />
                    <VerticalDivider ML />
                    <CircleSpinner size="3XL" />
                </FlexColumn>
            ) : null}
            <AnimatePresence>
                {stripePayment.intent?.intentSecret ? (
                    <motion.div
                        layout="position"
                        style={{ width: "100%", display: "inline-block" }}>
                        <form
                            onSubmit={event => {
                                //! Hantera fel om det dyker upp några
                                void handleSubmit(event);
                            }}
                            style={{ margin: 0 }}>
                            <FlexColumn fillParentWidth>
                                <PaymentStoredCardSelection
                                    selectedExternalCardId={selectedCardId}
                                    onSelectCard={setSelectedCardId}
                                />
                                <PaymentCardElement
                                    onFocus={() => {
                                        setSelectedCardId(undefined);
                                    }}
                                    disabled={
                                        stripePayment.status === "submitting"
                                    }
                                />
                                <VerticalDivider S />
                                <PaymentSaveCardCheckbox
                                    value={saveCard}
                                    toggle={setSaveCard}
                                />
                                <PaymentErrorMessage
                                    errorMessage={stripeErrorMessage}
                                />
                                <VerticalDivider L />
                                <PaymentBuyButton
                                    submitting={
                                        stripePayment.status === "submitting"
                                    }
                                />
                                <VerticalDivider L />
                            </FlexColumn>
                        </form>
                    </motion.div>
                ) : null}
            </AnimatePresence>
        </>
    );
};

export { PaymentCardForm };
