import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormComponents } from "@@/shared/form/form-components";
import { FormIdContext, useFormId } from "@@/shared/form/form-id.context";
import { useFormFieldActions } from "@@/shared/form/use-form-field";
import { useMountEffect } from "@@/shared/use-mount-effect";
import { useTranslate } from "@@/translations/use-translate";
import { MutationOptions } from "@tanstack/react-query";
import {
    ZodObjectOf,
    asArray,
    emptyArrayOf,
    generateId,
    parseSafely,
    translation,
} from "@towni/common";
import { Draft, produce } from "immer";
import objectHash from "object-hash";
import React, { useCallback, useMemo, useState } from "react";
import { Except } from "type-fest";
import { SafeParseReturnType } from "zod";
import { create } from "zustand";
import { useUpdateEffect } from "../use-update-effect";

const nullReplacer = (value: unknown) => {
    if (value === null) return undefined;
    return value;
};
const hasher = (object: Parameters<typeof objectHash>[0]) => {
    return objectHash(object, {
        replacer: nullReplacer,
    });
};

const shallowCompareArray = <T,>(a: T[], b: T[]): boolean => {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false;
    }
    return true;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const fieldValueCache = new Map<string, any>();
const memoizeFieldValue = <State, Value>(
    formId: FormId,
    fieldId: FieldId,
    fn: (state: Partial<State>) => Value,
): ((state: Partial<State>) => Value) => {
    return (state: Partial<State>): Value => {
        const result = fn(state);
        const key = `${formId}__${fieldId}`;
        const cachedValue = fieldValueCache.get(key) as Value;
        if (Array.isArray(result) && Array.isArray(cachedValue)) {
            if (shallowCompareArray(result, cachedValue)) {
                return cachedValue;
            }
        }
        if (cachedValue === result) {
            return cachedValue;
        }
        // since we now we've just set it
        // we now it's there and must be T
        fieldValueCache.set(key, result);
        return result;
    };
};

type FormState<
    State extends Record<string, unknown> = Record<string, unknown>,
> = {
    formId: FormId;
    state: Partial<State>;
    errors: Map<FieldId, string[]>;
    touched: Map<FieldId, boolean>;
    dirty: Map<FieldId, boolean>;
    isSubmitting: boolean;
    initialState: Partial<State>;
    initialHash: string;
    hasChanges: boolean;
    formSchema: ZodObjectOf<State>;
};

type StoreState = {
    forms: Map<FormId, FormState>;
    getForm: <State extends Record<string, unknown>>(
        formId: FormId,
        initialState?: Partial<State>,
    ) => FormState<State> | undefined;
    setForm: <State extends Record<string, unknown>>(
        form: FormState<State>,
        formId: FormId,
    ) => void;
    deleteForm: (formId: FormId) => void;
    getFormHash: (formId: FormId) => string | undefined;
    updateForm: <State extends Record<string, unknown>>(
        formId: FormId,
    ) => (setter: (draft: Draft<Partial<State>>) => void) => void;
    getFieldValue: <State extends Record<string, unknown>>(
        formId: FormId,
        fieldId: FieldId,
    ) => <T>(selector: (state: Partial<State>) => T) => T | undefined;
    setIsSubmitting: (formId: FormId) => (value: boolean) => void;
    setTouched: (
        formId: FormId,
    ) => (fieldId: FieldId) => (value: boolean) => void;
    setDirty: (
        formId: FormId,
    ) => (fieldId: FieldId) => (value: boolean) => void;
    setErrors: (
        formId: FormId,
    ) => (fieldId: FieldId) => (value: string[]) => void;
    initializeForm: <State extends Record<string, unknown>>(params: {
        formId: FormId;
        initialState: State;
        zodObject: ZodObjectOf<State>;
    }) => void;
    resetForm: <State extends Record<string, unknown>>(
        formId: FormId,
    ) => (params: {
        initialState: Partial<State>;
        zodObject: ZodObjectOf<State>;
    }) => void;
    resetToInitialState: (formId: FormId) => void;
};

const useFormStore = create<StoreState>((set, get, _store) => {
    const initialState: StoreState = {
        forms: new Map(),
        initializeForm: <State extends Record<string, unknown>>(params: {
            formId: FormId;
            initialState: Partial<State>;
            zodObject: ZodObjectOf<State>;
        }) => {
            const { formId, initialState, zodObject } = params;
            if (get().forms.has(formId)) {
                throw new Error(`Form with id ${formId} already exists`);
            }
            const hash = hasher(initialState);

            const newForm: FormState = {
                formId,
                dirty: new Map(),
                errors: new Map(),
                touched: new Map(),
                isSubmitting: false,
                state: initialState,
                initialState,
                initialHash: hash,
                hasChanges: false,
                formSchema: zodObject,
            };

            set(current => {
                const next = new Map(current.forms);
                next.set(formId, newForm);
                return { ...current, forms: next };
            });
        },
        resetForm:
            <State extends Record<string, unknown>>(formId: FormId) =>
            (params: {
                initialState: Partial<State>;
                zodObject: ZodObjectOf<State>;
            }) => {
                const { initialState, zodObject } = params;
                const hash = hasher(initialState);
                const newForm: FormState = {
                    formId,
                    dirty: new Map(),
                    errors: new Map(),
                    touched: new Map(),
                    isSubmitting: false,
                    state: initialState,
                    initialState,
                    initialHash: hash,
                    hasChanges: false,
                    formSchema: zodObject,
                };
                set(current => {
                    const next = new Map(current.forms);
                    next.set(formId, newForm);
                    return { ...current, forms: next };
                });
            },
        getForm: <State extends Record<string, unknown>>(formId: FormId) => {
            // Return the form with requested state type
            return get().forms.get(formId) as FormState<State> | undefined;
        },
        setForm: (form, formId: FormId) => {
            set(current => {
                const next = new Map(current.forms);
                next.set(formId, form);
                return { ...current, forms: next };
            });
        },
        getFormHash: (formId: FormId): string | undefined => {
            const form = get().forms.get(formId);
            if (!form) return undefined;
            return hasher(form);
        },
        updateForm:
            <State extends Record<string, unknown>>(formId: FormId) =>
            (setter: (state: Draft<Partial<State>>) => void) => {
                set(current => {
                    const next = new Map(current.forms);
                    const form = next.get(formId) as
                        | FormState<State>
                        | undefined;
                    if (!form) return current; // or throw perhaps
                    const updatedState = produce(form.state, setter);
                    const hash = hasher(updatedState);
                    const hasChanges = hash !== form.initialHash;
                    const updatedForm = {
                        ...form,
                        state: updatedState,
                        hash,
                        hasChanges,
                    };
                    next.set(formId, updatedForm);
                    return { ...current, forms: next };
                });
            },
        setIsSubmitting: (formId: FormId) => (value: boolean) => {
            set(current => {
                const next = new Map(current.forms);
                const form = next.get(formId);
                if (!form) return current;
                const updatedForm = { ...form, isSubmitting: value };
                next.set(formId, updatedForm);
                return { ...current, forms: next };
            });
        },
        setDirty:
            <State extends Record<string, unknown>>(formId: FormId) =>
            (fieldId: FieldId) => {
                return (value: boolean) => {
                    set(current => {
                        const next = new Map(current.forms);
                        const form = next.get(formId) as
                            | FormState<State>
                            | undefined;
                        if (!form) return current;
                        const updatedDirty = new Map(form.dirty);
                        updatedDirty.set(fieldId, value);
                        const updatedForm = { ...form, dirty: updatedDirty };
                        next.set(formId, updatedForm);
                        return { ...current, forms: next };
                    });
                };
            },
        setTouched:
            <State extends Record<string, unknown>>(formId: FormId) =>
            (fieldId: FieldId) => {
                return (value: boolean) => {
                    set(current => {
                        const next = new Map(current.forms);
                        const form = next.get(formId) as
                            | FormState<State>
                            | undefined;
                        if (!form) return current;
                        const updatedTouched = new Map(form.touched);
                        updatedTouched.set(fieldId, value);
                        const updatedForm = {
                            ...form,
                            touched: updatedTouched,
                        };
                        next.set(formId, updatedForm);
                        return { ...current, forms: next };
                    });
                };
            },
        setErrors:
            <State extends Record<string, unknown>>(formId: FormId) =>
            (fieldId: FieldId) => {
                return (errors: string | string[] | undefined) => {
                    set(current => {
                        const next = new Map(current.forms);
                        const form = next.get(formId) as
                            | FormState<State>
                            | undefined;
                        if (!form) return current;
                        const updatedErrors = new Map(form.errors);
                        if (typeof errors === "undefined") {
                            updatedErrors.delete(fieldId);
                        } else {
                            updatedErrors.set(fieldId, asArray(errors));
                        }
                        const updatedForm = { ...form, errors: updatedErrors };
                        next.set(formId, updatedForm);
                        return { ...current, forms: next };
                    });
                };
            },
        getFieldValue:
            <State extends Record<string, unknown>>(
                formId: FormId,
                fieldId: FieldId,
            ) =>
            <T,>(getter: (state: Partial<State>) => T) => {
                const form = get().forms.get(formId) as
                    | FormState<State>
                    | undefined;
                if (!form) return undefined;
                return memoizeFieldValue<State, T>(
                    formId,
                    fieldId,
                    getter,
                )(form.state);
            },
        deleteForm: (formId: FormId) => {
            set(current => {
                const next = new Map(current.forms);
                next.delete(formId);
                return { ...current, forms: next };
            });
        },
        resetToInitialState: (formId: FormId): void => {
            set(current => {
                const next = new Map(current.forms);
                const form = next.get(formId);
                if (!form) return { ...current };
                const updatedForm = {
                    ...form,
                    state: form.initialState,
                    hasChanges: false,
                };
                next.set(formId, updatedForm);
                return { ...current, forms: next };
            });
        },
    };
    return initialState;
});

const useFormState = <State extends Record<string, unknown>>(
    formId: FormId | undefined,
    initialFormState?: Partial<State>,
) => {
    const formStore = useFormStore();
    if (!formId) return undefined;
    const form = formStore.getForm(formId, initialFormState);
    return form;
};

type FormParams<
    State extends Record<string, unknown>,
    Command extends Record<string, unknown> = State,
> = Except<Parameters<typeof useForm<State, Command>>[0], "formId"> & {
    formId?: FormId;
};
/**
 * You probably want to use useForm and FormIdProvider in combinations instead of this component.
 * But it's here if you need it.
 * Provides a form context for managing form state and commands.
 * What it does it uses useForm hook to create a form with given formId (or a generated one if none is provided).
 * It also wraps a FormIdProvider to provide the formId to the children.
 *
 * @template State - The type of the form state.
 * @template Command - Commands is used to generate mutation option helpers for tanstack query mutations. To create helpers for tanstack query mutations. It's the type of the command sent using a mutation.
 *
 * `NOTE` ClearOnMount has different defaults than the useForm hook
 * clearOnUnmount defaults to true if formId is not provided. If formId is provided it defaults to false.
 */
const Form = <
    State extends Record<string, unknown>,
    Command extends Record<string, unknown> = State,
>(props: {
    formParams: FormParams<State, Command>;
    children: (form: FormWorkingCopy<State, Command>) => React.ReactNode;
}) => {
    const [formId] = useState(
        props.formParams.formId ?? generateId<FormId>({ prefix: "form__" }),
    );
    const clearOnUnmount =
        props.formParams.clearOnUnmount ?? !props.formParams.formId;
    const form = useForm<State, Command>({
        formId,
        clearOnUnmount,
        onFormChange: props.formParams.onFormChange,
        initializeIfNotExists: props.formParams.initializeIfNotExists,
    });

    return (
        <FormIdContext.Provider value={formId}>
            {props.children(form)}
        </FormIdContext.Provider>
    );
};

type FormWorkingCopyActions<State> = {
    setFieldValue: (setter: (draft: Draft<Partial<State>>) => void) => void;
    setTouched: (fieldId: FieldId) => (value: boolean) => void;
    setDirty: (fieldId: FieldId) => (value: boolean) => void;
    setErrors: (fieldId: FieldId) => (value: string[]) => void;
    setIsSubmitting: (value: boolean) => void;
    parse: () => State;
    safeParse: () => SafeParseReturnType<Partial<State>, State>;
    parseSafely: () => State | undefined;
    deleteForm: () => void;
    resetForm: (params: {
        initialState: Partial<State>;
        zodObject: ZodObjectOf<State>;
    }) => void;
};
type FormWorkingCopy<
    State extends Record<string, unknown>,
    Command extends Record<string, unknown>,
    ResponseData = unknown,
> = {
    formId: FormId;
    formState: FormState<State>;
    formMutationOptions: FormatMutationOptionsFactory<Command, ResponseData>;
    FormComponents: ReturnType<typeof useFormComponents<State>>;
} & FormWorkingCopyActions<State>;

type FormMutationOptions<
    Command extends Record<string, unknown>,
    ResponseData,
> = Except<
    MutationOptions<ResponseData, unknown, Command>,
    "mutationFn" | "mutationKey" | "_defaulted"
>;
type FormatMutationOptionsFactory<
    Command extends Record<string, unknown>,
    ResponseData,
> = (params: {
    errorsCallback: (errors: string[]) => void;
    options?: FormMutationOptions<Command, ResponseData>;
}) => FormMutationOptions<Command, ResponseData>;

const useFormMutationOptionsWrapper = <
    Command extends Record<string, unknown>,
    ResponseData,
>(
    setIsSubmitting: ((value: boolean) => void) | undefined,
) => {
    const translate = useTranslate();
    return useCallback(
        (params: {
            errorsCallback: (errors: string[]) => void;
            options?: FormMutationOptions<Command, ResponseData>;
        }): FormMutationOptions<Command, ResponseData> => {
            const options = params.options;
            const onValidationErrors = params.errorsCallback;
            return {
                onMutate: (...parameters) => {
                    setIsSubmitting?.(true);
                    options?.onMutate?.(...parameters);
                },
                onError: (error, _command, _context) => {
                    if (error instanceof Error)
                        onValidationErrors([error.message]);
                    else
                        onValidationErrors([
                            translate(
                                translation({
                                    sv: "Ett okänt fel uppstod när resursen skulle sparas",
                                    en: "An unknown error occurred when saving the resource",
                                }),
                            ),
                        ]);
                    options?.onError?.(error, _command, _context);
                },
                onSuccess: (...parameters) => {
                    onValidationErrors(emptyArrayOf<string>());
                    options?.onSuccess?.(...parameters);
                },
                onSettled: (...parameters) => {
                    setIsSubmitting?.(false);
                    options?.onSettled?.(...parameters);
                },
            };
        },
        [setIsSubmitting, translate],
    );
};

function useForm<
    State extends Record<string, unknown>,
    Command extends Record<string, unknown>,
    ResponseData = unknown,
>(): FormWorkingCopy<State, Command, ResponseData> | undefined;
function useForm<
    State extends Record<string, unknown>,
    Command extends Record<string, unknown>,
    ResponseData = unknown,
>(params: {
    formId: FormId | undefined;
    clearOnUnmount?: boolean;
}): FormWorkingCopy<State, Command, ResponseData> | undefined;
function useForm<
    State extends Record<string, unknown>,
    Command extends Record<string, unknown>,
    ResponseData = unknown,
>(params: {
    formId: FormId | undefined;
    pending?: boolean;
    clearOnUnmount?: boolean;
    onFormChange?: (state: Partial<State>) => void;
    initializeIfNotExists: {
        /** Initialize if form doesn't exist */
        initialState: Partial<State>;
        zodObject: ZodObjectOf<State>;

        // Type 'ZodObject<{ hourMinute: ZodType<HourMinute, ZodTypeDef, unknown>; },
        // "strip", ZodTypeAny, { hourMinute: HourMinute; }, { hourMinute?: unknown; }>'
        // is not assignable to
        // type 'ZodObject<any, any, any, { hourMinute: HourMinute; },
        // Partial<{ hourMinute: HourMinute; }>>'
    };
}): FormWorkingCopy<State, Command, ResponseData>;
function useForm<
    State extends Record<string, unknown>,
    Command extends Record<string, unknown>,
    ResponseData = unknown,
>(params?: {
    formId?: FormId;
    clearOnUnmount?: boolean;
    onFormChange?: (state: Partial<State>) => void;
    initializeIfNotExists?: {
        /** Initialize if form doesn't exist */
        initialState: Partial<State>;
        zodObject: ZodObjectOf<State>;
    };
}): FormWorkingCopy<State, Command, ResponseData> | undefined {
    const formId = params?.formId;
    const initializeIfNotExists = params?.initializeIfNotExists;
    const components = useFormComponents<State>();
    const store = useFormStore();
    const _formIdFromContext = useFormId({
        doNotThrow: true,
    });
    const _formId = formId || _formIdFromContext;
    if (!_formId)
        throw new Error(
            "Form id not set, not provided as hook parameter and no FormIdContext available",
        );
    const formState = useFormState<State>(formId);
    const formFieldActions = useFormFieldActions<State>(formId);
    const formMutationOptions = useFormMutationOptionsWrapper(
        formFieldActions?.setIsSubmitting,
    );
    const result: FormWorkingCopy<State, Command, ResponseData> | undefined =
        useMemo(() => {
            if (!formState) return undefined;
            if (!formFieldActions) return undefined;
            if (!formMutationOptions) return undefined;
            return {
                formId: _formId,
                formState,
                formMutationOptions: formMutationOptions as FormWorkingCopy<
                    State,
                    Command,
                    ResponseData
                >["formMutationOptions"],
                FormComponents: components,
                ...formFieldActions,
            } satisfies FormWorkingCopy<State, Command, ResponseData>;
        }, [
            formState,
            formFieldActions,
            formMutationOptions,
            _formId,
            components,
        ]);
    useUpdateEffect(
        function onFormStateChange() {
            if (params?.onFormChange) {
                params.onFormChange(result?.formState.state || {});
            }
        },
        [result?.formState.state, params?.onFormChange],
    );
    useMountEffect(() => {
        // Delete form on unmount
        return () => {
            if (params?.clearOnUnmount) {
                if (_formId) store.deleteForm(_formId);
            }
        };
    });
    if (!result && initializeIfNotExists) {
        // If form doesn't exist
        // and initial state is provided
        // then initialize the form
        const { zodObject, initialState } = initializeIfNotExists;
        store.initializeForm({ formId: _formId, initialState, zodObject });
        const state = store.getForm<State>(_formId);
        if (!state)
            throw new Error(
                "State missing even though it should have been initialized",
            );
        return {
            formId: _formId,
            formState: state,
            formMutationOptions: formMutationOptions as FormWorkingCopy<
                State,
                Command,
                ResponseData
            >["formMutationOptions"],
            FormComponents: components,
            setFieldValue: store.updateForm<State>(_formId),
            setTouched: store.setTouched(_formId),
            setDirty: store.setDirty(_formId),
            setErrors: store.setErrors(_formId),
            setIsSubmitting: store.setIsSubmitting(_formId),
            deleteForm: () => store.deleteForm(_formId),
            resetForm: store.resetForm<State>(_formId),
            parse: () => {
                const form = store.getForm<State>(_formId);
                if (!form) throw new Error(`Form "${_formId}" not found`);
                return form.formSchema.parse(form.state);
            },
            safeParse: (): SafeParseReturnType<Partial<State>, State> => {
                const form = store.getForm<State>(_formId);
                if (!form) throw new Error(`Form "${_formId}" not found`);
                return form.formSchema.safeParse(form.state);
            },
            parseSafely: () => {
                const form = store.getForm<State>(_formId);
                if (!form) return;
                return parseSafely({
                    schema: form.formSchema,
                    value: form.state,
                });
            },
        };
    } else {
        return result;
    }
}
export { Form, useForm, useFormState, useFormStore };
export type { FormParams, FormWorkingCopyActions };
