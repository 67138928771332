import {
    BookableSession,
    calculatePossibleSessionLengths,
    TimeRange,
} from "@towni/common";

type TimelineSession = BookableSession & {
    times: TimeRange[];
};

const timelineSessionFactory = (session: BookableSession) => {
    const list = calculatePossibleSessionLengths(session);
    const extended: TimelineSession = { ...session, times: list };
    return extended;
};

export { timelineSessionFactory };
export type { TimelineSession };
