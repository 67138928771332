import { InfoBox_FlexColumn } from "@@/backoffice/shared/info-box.flex-column";
import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModalActions } from "@@/modals/use-modal-actions";
import { useBookingAccommodationContext } from "@@/products/accommodations/booking-accommodation-context";
import { Animate } from "@@/shared/animations";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { DynamicIcon } from "@@/shared/icons/dynamic-icon";
import { Icon } from "@@/shared/icons/icon";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import { OptionalAmountPicker } from "@@/shared/options/optional-amount-picker";
import { NumberTextBox, TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faHouse } from "@fortawesome/pro-regular-svg-icons";
import * as Sentry from "@sentry/react";
import {
    Accommodation,
    AccommodationQuantityType,
    Product,
    exists,
    accommodationQuantityTypeMeta as quantityMeta,
    setWithinRange,
    translation,
} from "@towni/common";
import { useEffect, useState } from "react";

const AccommodationQuantityPicker_LayoutGrid = LayoutGrid;

type Props = {
    modalId: ModalId;
    product: Product;
    accommodation: Accommodation;
};

const AccommodationQuantityPickerModal = (props: Props) => {
    const theme = useTheme();
    const modalActions = useModalActions(props.modalId);
    const {
        quantities,
        setQuantities,
        requestedResourceQuantity,
        setRequestedResourceQuantity,
        hasQuantityPickerBeenShown,
        setHasQuantityPickerBeenShown,
    } = useBookingAccommodationContext(state => ({
        quantities: state.quantities,
        requestedResourceQuantity: state.requestedResourceQuantity,
        setQuantities: state.setQuantities,
        setRequestedResourceQuantity: state.setRequestedResourceQuantity,
        hasQuantityPickerBeenShown: state.hasQuantityPickerBeenShown,
        setHasQuantityPickerBeenShown: state.setHasQuantityPickerBeenShown,
    }));

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!hasQuantityPickerBeenShown) modalActions.show();
        }, 75); // 2000 milliseconds delay

        return () => clearTimeout(timer);
    }, [modalActions, hasQuantityPickerBeenShown]);

    const availableQuantityTypes: AccommodationQuantityType[] = Object.keys(
        props.accommodation.quantities,
    ) as AccommodationQuantityType[];

    const mainAvailableQuantity = quantities.ADULT > 0 ? "ADULT" : "GUEST";
    if (!availableQuantityTypes.includes(mainAvailableQuantity)) {
        // This should never be the case, log it if it happens
        Sentry.withScope(scope => {
            scope.setExtra("productId", props.product._id);
            scope.setExtra("availableQuantityTypes", availableQuantityTypes);
            Sentry.captureMessage(
                "No guest or adult quantity type available for accommodation product.",
            );
        });
    }

    //#region Quantity titles
    const quantityTitles = [
        // Main quantity
        quantities.ADULT > 0 ? (
            <FlexRow
                key="adult-quantity"
                crossAxis="center"
                css={{
                    borderRadius: 4,
                    padding: "1px 6px",
                    border: `1px solid ${theme.colors.lightGray.asString}`,
                }}>
                <DynamicIcon size={0.825} icon={quantityMeta.ADULT.iconName} />
                <HorizontalDivider />
                <NumberTextBox value={quantities.ADULT} />
            </FlexRow>
        ) : (
            <FlexRow
                key="guest-quantity"
                crossAxis="center"
                css={{
                    borderRadius: 4,
                    padding: "1px 6px",
                    border: `1px solid ${theme.colors.lightGray.asString}`,
                }}>
                <DynamicIcon size={0.825} icon={quantityMeta.GUEST.iconName} />
                <HorizontalDivider />
                <NumberTextBox value={quantities.GUEST} />
            </FlexRow>
        ),
    ].filter(exists);

    const secondaryQuantityTitles = [
        // Secondary quantities
        quantities.CHILD > 0 ? (
            <FlexRow
                key="child-quantity"
                crossAxis="center"
                css={{
                    borderRadius: 4,
                    padding: "1px 6px",
                    border: `1px solid ${theme.colors.lightGray.asString}`,
                }}>
                <DynamicIcon size={0.825} icon={quantityMeta.CHILD.iconName} />
                <HorizontalDivider />
                <NumberTextBox value={quantities.CHILD} />
            </FlexRow>
        ) : null,
        quantities.INFANT > 0 ? (
            <FlexRow
                key="infant-quantity"
                crossAxis="center"
                css={{
                    borderRadius: 4,
                    padding: "1px 6px",
                    border: `1px solid ${theme.colors.lightGray.asString}`,
                }}>
                <DynamicIcon size={0.825} icon={quantityMeta.INFANT.iconName} />
                <HorizontalDivider />
                <NumberTextBox value={quantities.INFANT} />
            </FlexRow>
        ) : null,
        quantities.ANIMAL > 0 ? (
            <FlexRow
                key="animal-quantity"
                crossAxis="center"
                css={{
                    borderRadius: 4,
                    padding: "1px 6px",
                    border: `1px solid ${theme.colors.lightGray.asString}`,
                }}>
                <DynamicIcon size={0.825} icon={quantityMeta.ANIMAL.iconName} />
                <HorizontalDivider />
                <NumberTextBox value={quantities.ANIMAL} />
            </FlexRow>
        ) : null,
        // Accommodation quantity
        <FlexRow
            key="res-quantity"
            crossAxis="center"
            css={{
                borderRadius: 4,
                padding: "0px 6px",
                border: `1px solid ${theme.colors.lightGray.asString}`,
            }}>
            <Icon icon={faHouse} size={0.825} />
            <HorizontalDivider />
            <NumberTextBox value={requestedResourceQuantity} />
        </FlexRow>,
    ].filter(exists);
    //#endregion

    const [mainQuantityHasBeenSetManually, setMainQuantityHasBeenSetManually] =
        useState(false);

    const [showAutoMainQuantityWarning, setShowAutoMainQuantityWarning] =
        useState(false);

    const [showMainQuantityMinError, setShowAdultQuantityMinError] =
        useState(false);

    return (
        <Modal
            onHide={() => {
                setHasQuantityPickerBeenShown(true);
            }}
            maxWidth={375}
            modalId={props.modalId}
            header={
                <ModalHeader
                    modalId={props.modalId}
                    bottomBorder
                    title={translation({
                        sv: "Välj antal",
                        en: "Choose quantity",
                    })}
                />
            }>
            <FlexColumn>
                <VerticalDivider M />
                <TextBox
                    padding={{ leftRight: 20 }}
                    text={props.accommodation.quantityDescriptions.description}
                    color={theme.colors.white.main.light50}
                    userSelect="text"
                    size="S"
                    renderMarkdown
                    align="center"
                    css={{
                        "h1, h2, h3, h4": {
                            color: theme.colors.white.main.light20.asString,
                        },
                    }}
                />
                <VerticalDivider S />
                <HorizontalLine />
                <VerticalDivider M />
                <ForEach
                    itemOf={availableQuantityTypes}
                    divider={
                        <>
                            <VerticalDivider M />
                            <HorizontalLine />
                            <VerticalDivider M />
                        </>
                    }>
                    {key => {
                        const currentValue = quantities[key] ?? 0;
                        const quantity = quantityMeta[key];
                        const isMainQuantity = key === mainAvailableQuantity;
                        const isMainQuantityAdult =
                            mainAvailableQuantity === "ADULT" &&
                            key === "ADULT";
                        return (
                            <LayoutGrid
                                css={{
                                    gridTemplateColumns: "1fr 110px",
                                    padding: "0 20px",
                                }}>
                                <LayoutCell>
                                    <FlexRow crossAxis="flex-start">
                                        <DynamicIcon
                                            fixedWidth
                                            icon={quantity.iconName}
                                            size={1.25}
                                            library="solid"
                                            css={{
                                                marginTop: 3,
                                                marginLeft: -1,
                                            }}
                                        />
                                        <HorizontalDivider S />
                                        <FlexColumn>
                                            <TextBox
                                                text={quantity.name.plural}
                                                weight="500"
                                                css={{
                                                    flexGrow: 0,
                                                    flexShrink: 0,
                                                    flexBasis: "50%",
                                                    textTransform: "capitalize",
                                                }}
                                            />
                                            <TextBox
                                                text={quantity.subTitle}
                                                weight="500"
                                                color={
                                                    theme.colors.default.text
                                                        .light40
                                                }
                                                css={{
                                                    flexGrow: 0,
                                                    flexShrink: 1,
                                                    flexBasis: "50%",
                                                }}
                                                size={0.875}
                                            />
                                        </FlexColumn>
                                    </FlexRow>
                                </LayoutCell>
                                <LayoutCell>
                                    <OptionalAmountPicker
                                        count={currentValue ?? 0}
                                        minMax={{ min: 0, max: 100 }}
                                        increase={() => {
                                            setShowAdultQuantityMinError(false);
                                            setShowAutoMainQuantityWarning(
                                                false,
                                            );
                                            if (
                                                isMainQuantityAdult &&
                                                !mainQuantityHasBeenSetManually
                                            ) {
                                                setMainQuantityHasBeenSetManually(
                                                    true,
                                                );
                                            }
                                            setQuantities({
                                                ...quantities,
                                                [key]: currentValue + 1,
                                            });
                                        }}
                                        decrease={() => {
                                            setShowAutoMainQuantityWarning(
                                                false,
                                            );
                                            if (isMainQuantityAdult) {
                                                if (
                                                    currentValue ===
                                                    requestedResourceQuantity
                                                ) {
                                                    setShowAdultQuantityMinError(
                                                        true,
                                                    );
                                                    return;
                                                }
                                                if (
                                                    !mainQuantityHasBeenSetManually
                                                ) {
                                                    setMainQuantityHasBeenSetManually(
                                                        true,
                                                    );
                                                }
                                            }
                                            setQuantities({
                                                ...quantities,
                                                [key]: currentValue - 1,
                                            });
                                        }}
                                    />
                                </LayoutCell>
                                <Conditional
                                    when={
                                        isMainQuantityAdult &&
                                        showMainQuantityMinError
                                    }>
                                    <LayoutCell
                                        css={{
                                            gridColumn: "1 / -1",
                                        }}>
                                        <Animate.FadeInOutAndHeight>
                                            <VerticalDivider S />
                                            <InfoBox_FlexColumn>
                                                <TextBox
                                                    text={translation({
                                                        sv: `Eftersom du valt ${requestedResourceQuantity} boenden måste du ha minst ${requestedResourceQuantity} vuxna. En vuxen per boende. Därför kan du inte minska antalet vuxna förrän du minskat antalet boenden.`,
                                                        en: `Since you have chosen ${requestedResourceQuantity} accommodations, you must have at least ${requestedResourceQuantity} adults. One adult per accommodation. Therefore, you cannot decrease the number of adults until you have decreased the number of accommodations.`,
                                                    })}
                                                />
                                            </InfoBox_FlexColumn>
                                        </Animate.FadeInOutAndHeight>
                                    </LayoutCell>
                                </Conditional>
                            </LayoutGrid>
                        );
                    }}
                </ForEach>
                <VerticalDivider M />
                <HorizontalLine />
                <VerticalDivider M />
                <AccommodationQuantityPicker_LayoutGrid
                    css={{
                        gridTemplateColumns: "1fr 110px",
                        padding: "0 20px",
                    }}>
                    <LayoutCell>
                        <FlexRow crossAxis="flex-start">
                            <Icon
                                fixedWidth
                                icon={faHouse}
                                size={1.25}
                                css={{
                                    marginTop: 3,
                                    marginLeft: -1,
                                }}
                            />
                            <HorizontalDivider S />
                            <FlexColumn>
                                <TextBox
                                    text={translation({
                                        sv: "Boenden",
                                        en: "Accommodations",
                                    })}
                                    weight="500"
                                    css={{
                                        flexGrow: 0,
                                        flexShrink: 0,
                                        flexBasis: "50%",
                                        textTransform: "capitalize",
                                    }}
                                />
                                <TextBox
                                    text={translation({
                                        sv: "Ex. rum, campingplatser, stugor, tältplatser, ställplatser",
                                        en: "E.g. rooms, camping sites, cottages, tent sites, parking spaces",
                                    })}
                                    weight="500"
                                    color={theme.colors.default.text.light40}
                                    css={{
                                        flexGrow: 0,
                                        flexShrink: 1,
                                        flexBasis: "50%",
                                    }}
                                    size={0.875}
                                />
                            </FlexColumn>
                        </FlexRow>
                    </LayoutCell>
                    <LayoutCell>
                        <OptionalAmountPicker
                            count={requestedResourceQuantity}
                            minMax={{ min: 1, max: 100 }}
                            increase={() => {
                                setShowAdultQuantityMinError(false);

                                const newValue = requestedResourceQuantity + 1;
                                if (
                                    mainAvailableQuantity === "ADULT" &&
                                    quantities[mainAvailableQuantity] < newValue
                                ) {
                                    setMainQuantityHasBeenSetManually(false);
                                    setShowAutoMainQuantityWarning(true);
                                    setQuantities({
                                        ...quantities,
                                        [mainAvailableQuantity]: newValue,
                                    });
                                }
                                setRequestedResourceQuantity(newValue);
                            }}
                            decrease={() => {
                                setShowAdultQuantityMinError(false);

                                const newValue = setWithinRange(
                                    requestedResourceQuantity - 1,
                                    { min: 1 },
                                );
                                if (
                                    mainAvailableQuantity === "ADULT" &&
                                    quantities[mainAvailableQuantity] ===
                                        requestedResourceQuantity &&
                                    !mainQuantityHasBeenSetManually
                                ) {
                                    setShowAutoMainQuantityWarning(true);
                                    setQuantities({
                                        ...quantities,
                                        [mainAvailableQuantity]: newValue,
                                    });
                                }
                                setRequestedResourceQuantity(newValue);
                                setRequestedResourceQuantity(
                                    requestedResourceQuantity - 1,
                                );
                            }}
                        />
                    </LayoutCell>
                    <Conditional when={showAutoMainQuantityWarning}>
                        <LayoutCell
                            css={{
                                gridColumn: "1 / -1",
                            }}>
                            <Animate.FadeInOutAndHeight>
                                <VerticalDivider S />
                                <InfoBox_FlexColumn>
                                    <TextBox
                                        text={translation({
                                            sv: `Då varje boende kräver minst en vuxen ökade vi därför också antalet vuxna`,
                                            en: `Since each accommodation requires at least one adult we therefore also increased the number of adults`,
                                        })}
                                    />
                                </InfoBox_FlexColumn>
                            </Animate.FadeInOutAndHeight>
                        </LayoutCell>
                    </Conditional>
                </AccommodationQuantityPicker_LayoutGrid>
            </FlexColumn>
            <VerticalDivider M />
            <HorizontalLine />
            <VerticalDivider M />
            <FlexRow mainAxis="stretch" padding={{ leftRight: 20 }}>
                <FlexColumn css={{ flex: 1 }}>
                    <FlexRow fillParentWidth>
                        <TextBox
                            text={translation({
                                sv: "Valda antal:",
                                en: "Selected quantities:",
                            })}
                            size="S"
                        />
                    </FlexRow>
                    <VerticalDivider XXS />
                    <FlexRow
                        fillParentWidth
                        wrap="wrap"
                        css={{
                            gap: 5,
                        }}>
                        <ForEach
                            itemOf={[
                                ...quantityTitles,
                                ...secondaryQuantityTitles,
                            ]}>
                            {item => item}
                        </ForEach>
                    </FlexRow>
                    {/* <Conditional when={secondaryQuantityTitles.length > 0}>
                        <VerticalDivider S />
                        <FlexRow>
                            <ForEach
                                itemOf={secondaryQuantityTitles}
                                divider={<HorizontalDivider XXS />}>
                                {item => item}
                            </ForEach>
                        </FlexRow>
                    </Conditional> */}
                </FlexColumn>
                <HorizontalDivider M />
                <ButtonPrimary
                    radius={3000}
                    onClick={() => {
                        modalActions.hide();
                    }}>
                    <TextBox
                        text={translation({
                            sv: "Fortsätt",
                            en: "Continue",
                        })}
                        size={"M"}
                        padding={{ leftRight: 20 }}
                    />
                </ButtonPrimary>
            </FlexRow>
            <VerticalDivider M />
        </Modal>
    );
};

export { AccommodationQuantityPickerModal };
