import { PayRequestItemPriceTable } from "@@/pay-requests/pay-request-item-price-table";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { DateTimeTextBox } from "@@/shared/text/date-time-text-box";
import { LinkTo } from "@@/shared/text/link-to";
import { useUserReference } from "@@/users/state/user-reference-fetchers";
import { ClassNames, useTheme } from "@emotion/react";
import { faPrint } from "@fortawesome/pro-solid-svg-icons";
import {
    OrderGroup,
    PayRequestReceiptForCustomer,
    assertNever,
    fullName,
    translation,
} from "@towni/common";
import { MutableRefObject, ReactInstance, useMemo, useRef } from "react";
import ReactToPrint from "react-to-print";
import { ReceiptCompanyDetails } from "./receipt-company-details";

type Props = {
    readonly orderGroup: OrderGroup;
    readonly receipt: PayRequestReceiptForCustomer;
    readonly ref?: MutableRefObject<ReactInstance>;
};

/**
 * Since a regular payment receipt only can be available for
 * orders that are paid for by customer directly, we know that
 * this is the original receipt for the order group.
 * This type of payment can only be made for an order created event.
 * @param props
 * @returns
 */
const PayRequestReceipt = (props: Props) => {
    const theme = useTheme();
    const isExtraSmall = usePageBreakpoint({ when: "XS" });
    const isMobile = usePageBreakpoint({ when: "📱" });
    const { orderGroup, receipt } = props;
    const [customer, customerQuery] = useUserReference(orderGroup?.customerId);
    const [provider, _providerQuery] = useProvider(
        receipt.receiptFor.providerId,
    );

    const defPadding = 20;
    const printRef = useRef(null);

    const titleColor = theme.colors.black.light30;
    const textColor = theme.colors.black.light60;

    const title = useMemo(() => {
        switch (receipt.status) {
            case "PENDING":
                return translation({
                    sv: "Betalningsförfrågan",
                    en: "Payment request",
                });
            case "CANCELLED":
                return translation({
                    sv: "Betalningsförfrågan",
                    en: "Cancelled payment request",
                });
            case "FULFILLED":
                return translation({
                    sv: "Kvitto",
                    en: "Receipt",
                });
            default:
                assertNever(receipt.status, "Unknown receipt type");
        }
    }, [receipt.status]);

    const subTitle = useMemo(() => {
        const orderCreated = receipt.originTypes.includes("ORDER_CREATED");
        const orderChanged = receipt.originTypes.includes("ORDER_CHANGED");

        if (receipt.status !== "FULFILLED") return undefined;

        if (orderCreated && orderChanged) {
            return translation({
                sv: "Order och orderändring",
                en: "Order and order change",
            });
        }

        if (orderChanged) {
            return translation({
                sv: "Orderändring",
                en: "Order change",
            });
        }

        return undefined;
    }, [receipt.originTypes, receipt.status]);

    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "0 20px",
            }}>
            <FlexRow
                fillParentWidth
                crossAxis="stretch"
                ref={printRef}
                css={{
                    maxWidth: 700,
                    borderRadius: 20,
                    backgroundColor: theme.colors.white.asString,
                    overflow: "hidden",

                    // border:
                    //     receipt.status === "PENDING"
                    //         ? `5px solid ${theme.colors.danger.main.light75.asString}`
                    //         : "none",
                }}>
                <Conditional when={receipt.status !== "FULFILLED"}>
                    <div
                        css={{
                            flex: 1,
                            width: 20,
                            maxWidth: 20,
                            backgroundColor:
                                receipt.status === "CANCELLED"
                                    ? theme.colors.black.light90.asString
                                    : theme.colors.danger.asString,
                            filter:
                                receipt.status === "CANCELLED"
                                    ? "grayscale(100%)"
                                    : "none",
                            willChange: "filter",
                        }}
                    />
                </Conditional>
                <FlexColumn
                    css={{
                        flex: 1,
                        zoom: isMobile ? (isExtraSmall ? 0.6 : 0.8) : 1,
                    }}>
                    {/* Header */}
                    <FlexRow
                        fillParentWidth
                        crossAxis="center"
                        mainAxis="space-between"
                        css={{
                            opacity: 0.8,
                            padding: defPadding,
                            paddingTop: defPadding * 1.5,
                            paddingBottom: 10,
                        }}>
                        <FlexColumn>
                            <TextBox text={title} weight="700" size={1.125} />
                            <Conditional when={!!subTitle}>
                                <VerticalDivider XS />
                                <TextBox
                                    text={subTitle}
                                    color={textColor}
                                    weight="700"
                                    size={1.125}
                                />
                            </Conditional>
                            <VerticalDivider XXS />
                            <DateTimeTextBox
                                dateTime={receipt._createdAt}
                                userSelect="text"
                                weight="700"
                                size={0.9}
                                color={textColor}
                                forceIncludeYear
                            />
                        </FlexColumn>
                        <HorizontalDivider />
                        <FlexRow>
                            <Conditional when={receipt.status === "PENDING"}>
                                <LinkTo
                                    key={receipt.payRequestId}
                                    to={`/pay-requests/${receipt.payRequestId}`}>
                                    <TextBox
                                        text={translation({
                                            sv: "Betala här »",
                                            en: "Pay here »",
                                        })}
                                        weight="600"
                                        size={1}
                                        css={{
                                            padding: 15,
                                            paddingLeft: 25,
                                            paddingRight: 25,
                                            borderColor:
                                                theme.colors.primary.border
                                                    .asString,
                                            backgroundColor:
                                                theme.colors.primary.background
                                                    .asString,
                                            color: theme.colors.primary.text
                                                .asString,
                                            borderStyle: "solid",
                                            borderWidth: 1,
                                            borderRadius: 8,
                                            "&:hover": {
                                                textDecoration: "underline",
                                            },
                                        }}
                                    />
                                </LinkTo>
                            </Conditional>
                            <Conditional when={receipt.status === "CANCELLED"}>
                                <TextBox
                                    size={1.2}
                                    css={{
                                        borderRadius: 5,
                                        padding: 15,
                                        paddingLeft: 30,
                                        paddingRight: 30,
                                        backgroundColor:
                                            receipt.status === "CANCELLED"
                                                ? theme.colors.black.light95
                                                      .asString
                                                : theme.colors.danger.light
                                                      .asString,
                                        color:
                                            receipt.status === "CANCELLED"
                                                ? theme.colors.black.light40
                                                      .asString
                                                : theme.colors.danger.asString,
                                    }}
                                    weight="700"
                                    text={translation({
                                        sv: "Avbruten",
                                        en: "Cancelled",
                                    })}
                                />
                            </Conditional>
                            <Conditional when={receipt.status === "FULFILLED"}>
                                <ButtonWhite
                                    css={{ border: "none", paddingRight: 8 }}>
                                    <ClassNames>
                                        {content => (
                                            <ReactToPrint
                                                copyStyles={true}
                                                bodyClass={content.css({
                                                    overflow: "visible",
                                                    height: "auto",
                                                    width: "100%",
                                                    backgroundColor: "white",
                                                    boxSizing: "border-box",
                                                    justifyContent: "stretch",
                                                    alignItems: "center",
                                                    display: "flex",
                                                    fontFamily:
                                                        theme.fontFamily,
                                                    fontSize: theme.sizes.base,
                                                    letterSpacing: "0.01em",
                                                    zoom: 1,
                                                    margin: 40,

                                                    userSelect: "none",
                                                })}
                                                trigger={() => (
                                                    <Icon
                                                        icon={faPrint}
                                                        size={1.1}
                                                        css={{
                                                            "@media print": {
                                                                display: "none",
                                                            },
                                                        }}
                                                    />
                                                )}
                                                content={() =>
                                                    printRef?.current || null
                                                }
                                            />
                                        )}
                                    </ClassNames>
                                </ButtonWhite>
                            </Conditional>
                        </FlexRow>
                    </FlexRow>
                    <Conditional when={receipt.status === "PENDING"}>
                        <VerticalDivider M />
                        <FlexColumn
                            fillParentWidth
                            css={{
                                paddingLeft: defPadding,
                                paddingRight: defPadding,
                            }}>
                            <TextBox
                                text={translation({
                                    sv: "Väntar på bekräftelse att betalning fullföljts.",
                                    en: "Waiting for confirmation that the payment has been completed.",
                                })}
                                align="left"
                                color={theme.colors.black.light60}
                            />
                            <VerticalDivider L />
                        </FlexColumn>
                    </Conditional>
                    <Conditional when={receipt.status === "CANCELLED"}>
                        <VerticalDivider M />
                        <FlexColumn
                            fillParentWidth
                            css={{
                                paddingLeft: defPadding,
                                paddingRight: defPadding,
                            }}>
                            <TextBox
                                text={translation({
                                    sv: "Denna betalningsförfrågan har avbrutits",
                                    en: "This payment request has been cancelled",
                                })}
                                align="left"
                                color={theme.colors.black.light60}
                            />
                            <VerticalDivider L />
                        </FlexColumn>
                    </Conditional>
                    <Conditional when={receipt.status === "FULFILLED"}>
                        <FlexRow fillParentWidth padding={{ topBottom: 10 }}>
                            <HorizontalLine fillParentWidth />
                        </FlexRow>
                        <VerticalDivider S />
                        <FlexColumn fillParentWidth>
                            {/* <Conditional when={!!bookableOrderItems.length}>
                        <FlexColumn
                            mainAxis="space-between"
                            padding={{ leftRight: defPadding }}>
                            <ReceiptBookedResourceCostRows items={items} />
                        </FlexColumn>
                    </Conditional> */}
                            <FlexColumn
                                fillParentWidth
                                css={{
                                    paddingLeft: defPadding,
                                    paddingRight: defPadding,
                                }}>
                                <PayRequestItemPriceTable
                                    items={receipt.items}
                                    payRequestPrice={receipt.amount}
                                />
                            </FlexColumn>
                            <VerticalDivider L />
                            <FlexColumn
                                fillParentWidth
                                css={{
                                    paddingTop: 30,
                                    backgroundColor:
                                        theme.colors.primary.main.withAlpha(0.1)
                                            .asString,
                                    // theme.colors.black.light95.asString,
                                }}>
                                <FlexRow
                                    fillParentWidth
                                    mainAxis="space-between">
                                    <FlexColumn
                                        css={{
                                            height: "100%",
                                            paddingLeft: 20,
                                            maxWidth: "50%",
                                        }}
                                        crossAxis="flex-start"
                                        mainAxis="flex-end">
                                        <Conditional when={!!provider}>
                                            {provider ? (
                                                <ReceiptCompanyDetails
                                                    provider={provider}
                                                />
                                            ) : null}
                                        </Conditional>
                                        <VerticalDivider M />
                                        <FlexColumn
                                            crossAxis="flex-start"
                                            mainAxis="center">
                                            <TextBox
                                                text={translation({
                                                    sv: "Köpare",
                                                    en: "Buyer",
                                                })}
                                                case="UPPERCASE"
                                                size={0.7}
                                                weight="900"
                                                userSelect="text"
                                                color={titleColor}
                                            />
                                            <VerticalDivider XXS />
                                            <TextBox
                                                text={
                                                    customer
                                                        ? fullName(customer)
                                                        : ""
                                                }
                                                spin={customerQuery.isPending}
                                                weight="400"
                                                userSelect="text"
                                                size={0.8}
                                                color={textColor}
                                            />
                                        </FlexColumn>
                                        <VerticalDivider XXS />
                                        <FlexRow
                                            mainAxis="flex-start"
                                            crossAxis="center">
                                            <TextBox
                                                text={orderGroup.customerId}
                                                weight="400"
                                                userSelect="text"
                                                size={0.8}
                                                color={textColor}
                                            />
                                        </FlexRow>
                                    </FlexColumn>
                                    <FlexColumn
                                        css={{
                                            height: "100%",
                                            maxWidth: "50%",
                                        }}
                                        crossAxis="flex-end"
                                        mainAxis="space-between">
                                        <FlexColumn
                                            fillParentWidth
                                            crossAxis="flex-end">
                                            <TextBox
                                                text={translation({
                                                    sv: "Orderdatum",
                                                    en: "Order date",
                                                })}
                                                case="UPPERCASE"
                                                size={0.7}
                                                weight="900"
                                                userSelect="text"
                                                padding={{ leftRight: 20 }}
                                                color={titleColor}
                                            />
                                            <VerticalDivider XXS />
                                            <FlexRow
                                                mainAxis="flex-end"
                                                crossAxis="center"
                                                padding={{ leftRight: 20 }}>
                                                <DateTimeTextBox
                                                    dateTime={
                                                        orderGroup._createdAt
                                                    }
                                                    weight="400"
                                                    userSelect="text"
                                                    size={0.8}
                                                    align="right"
                                                    color={textColor}
                                                />
                                            </FlexRow>
                                        </FlexColumn>
                                        <VerticalDivider M />
                                        <FlexColumn
                                            fillParentWidth
                                            crossAxis="flex-end">
                                            <FlexRow
                                                mainAxis="flex-end"
                                                crossAxis="center"
                                                padding={{ leftRight: 20 }}>
                                                <TextBox
                                                    text={orderGroup.slug}
                                                    weight="700"
                                                    userSelect="text"
                                                    size={0.8}
                                                    align="right"
                                                    color={textColor}
                                                />
                                            </FlexRow>
                                            <VerticalDivider XXS />
                                            <FlexRow
                                                mainAxis="flex-end"
                                                crossAxis="center"
                                                padding={{ leftRight: 20 }}>
                                                <TextBox
                                                    text={orderGroup.reference}
                                                    weight="700"
                                                    userSelect="text"
                                                    size={0.8}
                                                    align="right"
                                                    color={textColor}
                                                />
                                            </FlexRow>
                                            <VerticalDivider XXS />
                                            <FlexRow
                                                mainAxis="flex-end"
                                                crossAxis="center"
                                                padding={{
                                                    leftRight: 20,
                                                }}>
                                                <TextBox
                                                    text={
                                                        receipt.receiptFor
                                                            .orderId
                                                    }
                                                    weight="400"
                                                    userSelect="text"
                                                    size={0.8}
                                                    align="right"
                                                    color={textColor}
                                                />
                                            </FlexRow>
                                        </FlexColumn>
                                    </FlexColumn>
                                </FlexRow>
                                <VerticalDivider L />
                            </FlexColumn>
                        </FlexColumn>
                    </Conditional>
                </FlexColumn>
            </FlexRow>
        </div>
    );
};

export { PayRequestReceipt };
