import { AccessDeniedPage } from "@@/backoffice/shared/access-denied-page";
import { PageNotFound } from "@@/backoffice/shared/not-found-page";
import { useOrder } from "@@/orders/order-fetchers";
import { useOrderGroup } from "@@/orders/order-groups/use-order-group";
import { PageLoad } from "@@/pages/page-load";
import { useAccessKeyIdFromUrl } from "@@/receipts/use-access-key-id-from-url";
import { isApiError, OrderGroupId, OrderId, translation } from "@towni/common";
import { ReactNode, useState } from "react";

const OrderAccessRequired = (props: {
    orderId: OrderId | undefined;
    children?: ReactNode;
}) => {
    const accessKeyId = useAccessKeyIdFromUrl();
    const orderQuery = useOrder(props.orderId, accessKeyId);

    if (!props.orderId || orderQuery.isPending)
        return (
            <PageLoad
                text={translation({
                    sv: "Laddar order",
                    en: "Loading order",
                })}
            />
        );

    if (isApiError(orderQuery.error)) {
        const error = orderQuery.error;
        const unAuthenticated = error.statusCode === 401;
        const unAuthorized = error.statusCode === 403;
        if (unAuthenticated || unAuthorized) {
            return <AccessDeniedPage />;
        }

        if (error.statusCode === 404) {
            return <PageNotFound reason={error.reason} />;
        }
    }

    if (orderQuery.error) {
        throw orderQuery.error;
    }

    return <>{props.children}</>;
};

const OrderGroupAccessRequired = (props: {
    orderGroupId: OrderGroupId | undefined;
    children?: ReactNode;
}) => {
    const accessKeyId = useAccessKeyIdFromUrl();
    const [orderGroupHasBeenLoaded, setOrderGroupHasBeenLoaded] =
        useState<unknown>();
    const [group, orderGroupQuery] = useOrderGroup(
        props.orderGroupId,
        accessKeyId,
        0,
    );

    if (
        !orderGroupHasBeenLoaded &&
        (!props.orderGroupId || orderGroupQuery.isPending)
    ) {
        return (
            <PageLoad
                text={translation({
                    sv: "Laddar order",
                    en: "Loading order",
                })}
            />
        );
    }
    const error = orderGroupQuery.error ?? orderGroupHasBeenLoaded;
    if (!group && isApiError(error)) {
        if (!orderGroupHasBeenLoaded) setOrderGroupHasBeenLoaded(error);

        const unAuthenticated = error.statusCode === 401;
        if (unAuthenticated) return <AccessDeniedPage type="unAuthenticated" />;

        const unAuthorized = error.statusCode === 403;
        if (unAuthorized) return <AccessDeniedPage type="unAuthorized" />;

        if (error.statusCode === 404) {
            return <PageNotFound reason={error.reason} />;
        }
    }

    if (orderGroupQuery.error) {
        throw orderGroupQuery.error;
    }

    return <>{props.children}</>;
};

export { OrderAccessRequired, OrderGroupAccessRequired };
