import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModalActions } from "@@/modals/use-modal-actions";
import { AccommodationDatePicker } from "@@/products/accommodations/accommodation-date-picker";
import { AccommodationQuantityPill } from "@@/products/accommodations/accommodation-pill/accommodation-pill";
import { useBookingAccommodationContext } from "@@/products/accommodations/booking-accommodation-context";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    Accommodation,
    Percentage,
    Product,
    TimeRange,
    asDate,
    datesBetweenIso,
    getLocale,
    isIsoAndUnix,
    isTimeRange,
    translation,
} from "@towni/common";
import { format, isSameMonth, isSameYear } from "date-fns";
import { motion } from "framer-motion";
import { useEffect } from "react";

type Props = {
    modalId: ModalId;
    product: Product;
    accommodation: Accommodation;
};

const formatAccommodationTimeRange = (params: {
    time: TimeRange | undefined;

    currentTimezoneName: string;
}): string => {
    const { time } = params;
    if (!time) return "";

    const start = asDate(time.start);
    const end = asDate(time.end);

    if (!start || !end) return "";

    // 1. If same month and year, show day-day month
    if (isSameMonth(start, end) && isSameYear(start, end)) {
        return `${format(start, "d", {
            locale: getLocale("sv"),
        })} - ${format(end, "d MMM", {
            locale: getLocale("sv"),
        })}`;
    }

    // 2. If different month but same year, show day month-day month
    else if (!isSameMonth(start, end) && isSameYear(start, end)) {
        return `${format(start, "d MMM", {
            locale: getLocale("sv"),
        })} - ${format(end, "d MMM", {
            locale: getLocale("sv"),
        })}`;
    }

    // 3. If different year, show day month year-day month year
    else {
        return `${format(start, "d MMM yyyy", {
            locale: getLocale("sv"),
        })} - ${format(end, "d MMM yyyy", {
            locale: getLocale("sv"),
        })}`;
    }
};

const AccommodationDatePickerModal = (props: Props) => {
    const theme = useTheme();
    const isExtraSmallScreen = usePageBreakpoint({ when: "XS" });

    const modalAction = useModalActions(props.modalId);
    const { feed, time, hasDatePickerBeenShown, setHasDatePickerBeenShown } =
        useBookingAccommodationContext(state => ({
            time: state.timeRange,
            hasDatePickerBeenShown: state.hasDatePickerBeenShown,
            setHasDatePickerBeenShown: state.setHasDatePickerBeenShown,
            feed: state.feed,
        }));

    // React.useEffect(() => {
    //     if (!hasDatePickerBeenShown) modalAction.show();
    // }, [modalAction, hasDatePickerBeenShown]);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!hasDatePickerBeenShown) modalAction.show();
        }, 75); // 2000 milliseconds delay

        return () => clearTimeout(timer);
    }, [modalAction, hasDatePickerBeenShown]);

    const selected = isTimeRange(time)
        ? formatAccommodationTimeRange({
              time,
              currentTimezoneName: "Europe/Stockholm",
          })
        : isIsoAndUnix(time)
          ? translation({
                sv: "Välj avresedatum",
                en: "Select departure date",
            })
          : undefined;

    const subSelected = () => {
        if (isTimeRange(time)) {
            const dates = datesBetweenIso(time.start, time.end);
            const numberOfNights = dates.length - 1;
            if (numberOfNights === 1) {
                return translation({
                    sv: "1 natt",
                    en: "1 night",
                });
            }
            return translation({
                sv: `${numberOfNights} nätter`,
                en: `${numberOfNights} nights`,
            });
        }
        if (isIsoAndUnix(time)) {
            const findDay = feed.dates.find(r => r.date.iso === time.iso);
            if (findDay) {
                if (findDay.settings.minimumStay === 1) {
                    return translation({
                        sv: `upp till ${findDay.settings.maximumStay} nätter`,
                        en: `up to ${findDay.settings.maximumStay} nights`,
                    });
                }

                if (
                    findDay.settings.maximumStay ===
                    findDay.settings.minimumStay
                ) {
                    return translation({
                        sv: `upp till ${findDay.settings.minimumStay} nätter`,
                        en: `up to ${findDay.settings.minimumStay} nights`,
                    });
                }

                return translation({
                    sv: `välj mellan ${findDay.settings.minimumStay}-${findDay.settings.maximumStay} nätter`,
                    en: `choose between ${findDay.settings.minimumStay}-${findDay.settings.maximumStay} nights`,
                });
            }
        }
        return "";
    };
    return (
        <Modal
            modalId={props.modalId}
            height={0.92 as Percentage}
            onHide={() => {
                setHasDatePickerBeenShown(true);
            }}
            header={
                <ModalHeader
                    modalId={props.modalId}
                    bottomBorder
                    spacerBasis={0.1}
                    title={
                        <FlexColumn>
                            <TextBox
                                align="center"
                                size={1.125}
                                weight="700"
                                color={theme.colors.default.text}
                                lineClamp={2}
                                shrink={1}
                                grow={0}
                                text={translation({
                                    sv: "Välj datum",
                                    en: "Select date",
                                })}
                            />
                            <TextBox
                                align="center"
                                size={0.875}
                                color={theme.colors.default.text.light40}
                                shrink={1}
                                grow={0}
                                text={translation({
                                    sv: "Lägg till dina resedatum för att gå vidare.",
                                    en: "Add your travel dates to continue.",
                                })}
                            />
                        </FlexColumn>
                    }
                />
            }>
            <FlexRow
                fillParentWidth
                mainAxis="center"
                css={{
                    paddingTop: 14,
                    paddingBottom: 14,
                    boxShadow: `0px 5px 10px 0px ${theme.colors.default.border.asString}`,
                }}>
                <AccommodationQuantityPill
                    product={props.product}
                    accommodation={props.accommodation}
                />
            </FlexRow>

            <FlexColumn overflow="scroll">
                <VerticalDivider M />
                <AccommodationDatePicker />
                <VerticalDivider M />

                <VerticalDivider M />
            </FlexColumn>
            <motion.div
                initial={{
                    opacity: 1,
                }}
                animate={{
                    opacity: 1,
                }}
                exit={{
                    opacity: 0,
                }}
                transition={theme.spring}
                css={{
                    display: "block",
                    position: "absolute",
                    bottom: 0,
                    width: "100%",
                    height: "auto",
                    pointerEvents: "none",
                    zIndex: 99999,
                    willChange: "filter",
                    filter: `drop-shadow(0 -15px 15px ${
                        theme.colors.black.value.withAlpha(0.1).asString
                    })`,
                }}>
                <FlexColumn
                    fillParent
                    mainAxis="flex-end"
                    crossAxis="stretch"
                    css={{
                        borderTopLeftRadius: 20,
                        borderTopRightRadius: 20,
                        overflow: "hidden",
                    }}>
                    <FlexColumn
                        crossAxis="stretch"
                        fillParentWidth
                        background={{
                            color: theme.colors.default.background.value.withRelativeModifications(
                                { light: 0.1 },
                            ),
                        }}>
                        <FlexRow
                            mainAxis="flex-end"
                            padding={{
                                all: isExtraSmallScreen ? 12 : 15,
                            }}>
                            <FlexColumn crossAxis="flex-end">
                                <TextBox
                                    text={selected}
                                    weight="500"
                                    grow={0}
                                    basis={"50%"}
                                    shrink={0}
                                    css={{
                                        textTransform: "capitalize",
                                    }}
                                />
                                <TextBox
                                    text={subSelected()}
                                    weight="500"
                                    grow={0}
                                    basis={"50%"}
                                    shrink={1}
                                    color={
                                        theme.isLightTheme
                                            ? theme.colors.default.text.light40
                                            : theme.colors.default.text.light60
                                    }
                                    size={0.875}
                                />
                            </FlexColumn>
                            <HorizontalDivider M />
                            <ButtonPrimary
                                onClick={() => {
                                    modalAction.hide();
                                }}>
                                <TextBox
                                    text={translation({
                                        sv: "Välj datum",
                                        en: "Select",
                                    })}
                                    size={"M"}
                                    padding={{ leftRight: 20 }}
                                />
                            </ButtonPrimary>
                        </FlexRow>
                    </FlexColumn>
                </FlexColumn>
            </motion.div>
        </Modal>
    );
};

export { AccommodationDatePickerModal, formatAccommodationTimeRange };
