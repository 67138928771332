import { IsoTimestamp } from "@towni/common";
import { createContext, useContext, useMemo, useState } from "react";
import { SelectableDate } from "./selectable-date";

const DateSelectabilityMapContext = createContext<
    | {
          selectabilityMap: Map<IsoTimestamp, SelectableDate>;
          setSelectabilityMap: (
              dates: Map<IsoTimestamp, SelectableDate>,
          ) => void;
      }
    | undefined
>(undefined);
const DateSelectabilityMapContextProvider = (props: {
    readonly initialValue?: Map<IsoTimestamp, SelectableDate>;
    readonly children: React.ReactNode;
}) => {
    const [selectabilityMap, setSelectabilityMap] = useState<
        Map<IsoTimestamp, SelectableDate>
    >(new Map<IsoTimestamp, SelectableDate>(props.initialValue));

    const state = useMemo(
        () => ({ selectabilityMap, setSelectabilityMap }),
        [selectabilityMap, setSelectabilityMap],
    );

    return (
        <DateSelectabilityMapContext.Provider value={state}>
            {props.children}
        </DateSelectabilityMapContext.Provider>
    );
};
const useDateSelectabilityMapContext = () => {
    const context = useContext(DateSelectabilityMapContext);
    if (!context) throw new Error("No SelectabilityMapContextProvider found");
    return context;
};

export { DateSelectabilityMapContextProvider, useDateSelectabilityMapContext };
