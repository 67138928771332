import { VerificationCodeInput } from "@@/shared/text";
import { PhoneNumber, translation } from "@towni/common";
import * as React from "react";
import { useSetUserPhoneNumber } from "../use-set-user-phone-number";

type Props = {
    codeLength: number;
    disabled?: boolean;
    phoneNumber: PhoneNumber | undefined;
    onSuccess: () => void;
};

const SetUserPhoneNumberVerificationField = (props: Props) => {
    const [errorMessage] = React.useState();
    const setPhoneNumber = useSetUserPhoneNumber({
        onSuccess: props.onSuccess,
    });

    return (
        <>
            <VerificationCodeInput
                fillParentWidth
                length={props.codeLength}
                disabled={props.disabled}
                onChange={value => {
                    if (!props.phoneNumber) return;
                    setPhoneNumber.mutate({
                        phoneNumber: props.phoneNumber,
                        verificationCode: Number.parseInt(value),
                    });
                }}
                errorMessage={
                    setPhoneNumber.isError
                        ? (setPhoneNumber.error?.message ??
                          errorMessage ??
                          translation({
                              sv: "Verifiering misslyckades",
                              en: "Verification failed",
                          }))
                        : (errorMessage ?? "")
                }
            />
        </>
    );
};

export { SetUserPhoneNumberVerificationField };
