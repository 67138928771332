import { apiFetchClient } from "@@/shared/fetch-client";
import { useToast } from "@@/toasts/context/toast-context";
import { useMutation } from "@tanstack/react-query";
import {
    CreatePhoneSignInVerifyCommandParams,
    PhoneSignInVerifyCommand,
    phoneSignInVerifyCommandFactory,
    PhoneSignInVerifyCommandResponse,
    translation,
} from "@towni/common";
import { useMe } from "../me/me-context";

const phoneSignInVerifyCommand = async (
    params: CreatePhoneSignInVerifyCommandParams,
) => {
    return await apiFetchClient.post<
        PhoneSignInVerifyCommand,
        PhoneSignInVerifyCommandResponse
    >({
        route: `/commands/verify-phone-sign-in`,
        body: phoneSignInVerifyCommandFactory(params),
    });
};

const usePhoneSignInVerifyCommand = () => {
    const [_, { meQuery }] = useMe();
    const toast = useToast();
    return useMutation<
        PhoneSignInVerifyCommandResponse,
        Error,
        CreatePhoneSignInVerifyCommandParams
    >({
        mutationFn: phoneSignInVerifyCommand,
        onSuccess: async data => {
            if (data.mode === "SIGN_IN") {
                await meQuery.refetch();
            }
        },
        onError: (error, _command, _previous) => {
            toast.fromError(error, {
                messageOverrideWhenNotApiError: translation({
                    sv: "Verifiering misslyckades. Försök igen om en liten stund.",
                    en: "Failed. Try again in a little while.",
                }),
            });
            throw error;
        },
    });
};

export { usePhoneSignInVerifyCommand };
