import { useMe } from "@@/authentication/me/me-context";
import { useToast } from "@@/toasts/context/toast-context";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserAddressId, isApiError, translation } from "@towni/common";
import { useAddressService } from "./use-address-service";

const useAddUserAddress = (params?: {
    onSuccess?: (userAddressId: UserAddressId) => void | Promise<void>;
}) => {
    const [me] = useMe();
    const toast = useToast();
    const { addAddressCommand } = useAddressService();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: addAddressCommand,

        onSuccess: async (response, command) => {
            await queryClient.invalidateQueries({
                queryKey: [me?._id, "addresses"],
            });
            if (!isApiError(response)) {
                // Could it really ever be an API ERROR in success??
                await params?.onSuccess?.(command.addressId);
            }
        },
        onError: (error, _command) => {
            if (isApiError(error)) toast.fromError(error);
            else
                toast.danger({
                    message: translation({
                        sv: "Något fick fel. Försök igen om en liten stund.",
                        en: "Something went wrong. Try again in a little while.",
                    }),
                });
            throw error;
        },
    });
};

export { useAddUserAddress };
