import { useCartContext } from "@@/carts/multi-carts/cart.context";
import { useGiftCardPurchaseContext } from "@@/products/gift-card-purchase.context";
import { AddToCartPillButton } from "@@/shared/buttons/add-to-cart-with-quantity-picker/add-to-cart-pill-button";
import { CartQuantityPicker } from "@@/shared/buttons/add-to-cart-with-quantity-picker/cart-quantity-picker";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    BackgroundStyle,
    isTranslatable,
    Padding,
    Translatable,
    unique,
} from "@towni/common";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

type Props = {
    readonly addButtonText?: Translatable | JSX.Element;
    readonly padding?: Padding;
    readonly background?: BackgroundStyle;
    readonly afterAddNavigation?: () => void;
    readonly clearBeforeAdding: boolean;
};

const AddGiftCardToCartWithQuantityButton = (props: Props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const purchaseContext = useGiftCardPurchaseContext();
    const { cart, addItemsToCart, clearCart } = useCartContext(context => ({
        cart: context.cart,
        addItemsToCart: context.addItemsToCart,
        clearCart: context.clearCart,
    }));

    const output = (
        <motion.div
            initial={{
                opacity: 0,
            }}
            animate={{
                opacity: 1,
            }}
            exit={{
                opacity: 0,
            }}
            transition={theme.spring}
            css={{
                display: "block",
                position: "absolute",
                pointerEvents: "none",
                width: "100%",
                height: "100%",
                zIndex: 9999,
                top: 0,
                left: 0,
            }}>
            <FlexColumn fillParent mainAxis="flex-end" crossAxis="stretch">
                <FlexColumn
                    crossAxis="stretch"
                    background={{
                        linearGradient: {
                            _type: "LINEAR_GRADIENT",
                            steps: [
                                {
                                    color: theme.colors.default.background,
                                    percentage: 0.5,
                                },
                                {
                                    color: theme.colors.white.main.value.withAlpha(
                                        0.001,
                                    ), // to prevent weird errors with alpha 0
                                    percentage: 1,
                                },
                            ],
                        },
                    }}>
                    <VerticalDivider XL />

                    <FlexRow
                        css={{ pointerEvents: "all" }}
                        mainAxis="space-between"
                        padding={{
                            ...props.padding,
                            top: props.padding?.top || 15,
                        }}>
                        <FlexRow css={{ flexGrow: 0 }}>
                            <CartQuantityPicker
                                quantity={purchaseContext.quantityValue}
                                decrease={purchaseContext.decreaseQuantity}
                                increase={purchaseContext.increaseQuantity}
                                disabled={false}
                            />
                        </FlexRow>
                        <HorizontalDivider />
                        <FlexRow css={{ flex: 1 }}>
                            <AddToCartPillButton
                                disabled={purchaseContext.addToCartDisabled}
                                css={{
                                    width: "100%",
                                }}
                                onClick={() => {
                                    // If there's no item to add, ignore click
                                    const [cartItems] =
                                        purchaseContext.getOrderItems();
                                    if (!cartItems?.length) return;
                                    if (props.clearBeforeAdding) clearCart();

                                    // What provider do the items in cart belong to?
                                    const cartProviderId = unique(
                                        cart?.orderItems?.map(
                                            orderItem => orderItem.providerId,
                                        ) ?? [],
                                    )[0];

                                    // If it's a different provider than current item belongs to
                                    // warn customer and ask if they want to continue/switch provider
                                    if (
                                        cartProviderId &&
                                        cartProviderId !==
                                            cartItems[0].providerId
                                    ) {
                                        alert(
                                            "Du har redan varor i din varukorg från en annan butik/restaurang. Handla gärna från flera men från en i taget. 🙂",
                                        );
                                        return;
                                    }

                                    // Add item to cart (updates if item already exists)
                                    addItemsToCart(cartItems);

                                    // Make sure cart is updated within
                                    // cart context before navigating away
                                    setTimeout(() => {
                                        // Do requested action after adding item to cart
                                        // if there is one
                                        if (props.afterAddNavigation) {
                                            props.afterAddNavigation();
                                            return;
                                        }

                                        // else go back
                                        navigate(-1);
                                    }, 0);
                                }}>
                                {typeof props.addButtonText === "undefined" ||
                                isTranslatable(props.addButtonText) ? (
                                    <FlexRow fillParentWidth mainAxis="center">
                                        <TextBox
                                            text={
                                                props.addButtonText || {
                                                    sv: "Lägg i kundkorg",
                                                    en: "Add to cart",
                                                }
                                            }
                                            weight="700"
                                        />
                                    </FlexRow>
                                ) : (
                                    props.addButtonText
                                )}
                            </AddToCartPillButton>
                        </FlexRow>
                    </FlexRow>
                </FlexColumn>
            </FlexColumn>
        </motion.div>
    );

    return output;
};

export { AddGiftCardToCartWithQuantityButton };
