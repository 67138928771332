import { CheckmarkCircleSvg } from "@@/orders/order-groups/checkmark-circle-svg";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { useBrandingContext } from "@@/routes/shared/branding-context";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { Icon } from "@@/shared/icons/icon";
import {
    Paragraph,
    ParagraphContent,
    TextBox,
    paragraphLineBreakFactory,
    paragraphTextFactory,
} from "@@/shared/text";
import { useUserReference } from "@@/users/state/user-reference-fetchers";
import { useTheme } from "@emotion/react";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import {
    CustomerId,
    Order,
    Percentage,
    UserId,
    colorFactory,
    hexColor,
    translation,
} from "@towni/common";
import { useMemo } from "react";

type Props = {
    paymentIsPending: boolean;
    customerId: UserId | CustomerId | undefined;
    order: Order | undefined;
};

const _ReceiptGreeting = (props: Props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme = useTheme();
    const { order } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { branding } = useBrandingContext();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [customer, customerQuery] = useUserReference(props.customerId);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [provider, providerQuery] = useProvider(order?.providerId);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const greetingText = useMemo((): ParagraphContent => {
        if (providerQuery.isInitialLoading)
            return [
                paragraphTextFactory({
                    text: undefined,
                    spin: true,
                    spinWidth: 200,
                    spinOpacity: 0.5 as Percentage,
                }),
                paragraphTextFactory({
                    text: undefined,
                    spin: true,
                    spinWidth: 200,
                    spinOpacity: 0.5 as Percentage,
                }),
            ];

        if (order?.hasBeenRejected) {
            return [
                // translation({
                //     sv: "Din order har blivit nekad.",
                //     en: "Your order has been rejected.",
                // }),
                // paragraphLineBreakFactory(),
                translation({
                    sv: "Kontakta ",
                    en: "Contact ",
                }),
                provider?.meta.title ??
                    translation({
                        sv: "säljaren",
                        en: "the seller",
                    }),
                translation({
                    sv: " om du behöver mer information.",
                    en: " if you need more information.",
                }),
            ];
        }

        if (order?.hasBeenCancelled) {
            return [
                translation({
                    sv: "Din order är avbokad.",
                    en: "Your order has been cancelled.",
                }),
                paragraphLineBreakFactory(),
                translation({
                    sv: "Kontakta ",
                    en: "Contact ",
                }),
                provider?.meta.title ??
                    translation({
                        sv: "säljaren",
                        en: "the seller",
                    }),
                translation({
                    sv: " om du behöver mer information.",
                    en: " if you need more information.",
                }),
            ];
        }
        if (order?.hasPassed) {
            return [
                translation({
                    sv: "Din bokningstid har passerats",
                    en: "Your booking time has passed",
                }),
            ];
        }
        if (!order?.hasBeenConfirmed) {
            return [
                translation({
                    sv: "Din order är mottagen och väntar bekräftelse från ",
                    en: "Your order has been received and is awaiting confirmation from ",
                }),
                provider?.meta.title ??
                    translation({
                        sv: "säljaren",
                        en: "the seller",
                    }),
            ];
        }

        return [
            translation({
                sv: "Din order är mottagen och bekräftad.",
                en: "Your order has been received and confirmed.",
            }),
        ];
    }, [order, provider?.meta.title, providerQuery.isInitialLoading]);

    return (
        <>
            <Conditional when={props.paymentIsPending}>
                <VerticalDivider M />
                <TextBox
                    text={translation({
                        sv: "Väntar på bekräftelse från betalningsleverantör",
                        en: "Waiting on confirmation from payment provider",
                    })}
                    css={{
                        textAlign: "center",
                        color: (branding?.colorThemes.light || theme.colors)
                            .primary.light.asString,
                        "@media print": {
                            color: (branding?.colorThemes.light || theme.colors)
                                .black.asString,
                        },
                    }}
                    weight="700"
                    spin={customerQuery.isPending}
                    size={1.125}
                    padding={{ bottom: 3 }}
                />
            </Conditional>
            <Conditional when={!props.paymentIsPending}>
                {order?.hasBeenRejected || order?.hasBeenCancelled ? (
                    <Icon
                        icon={faCircleExclamation}
                        size={4.2}
                        color={colorFactory(
                            branding?.receipt.background.textColor ||
                                hexColor("#000000"),
                        ).withAlpha(0.5)}
                        css={{
                            filter: "drop-shadow( 5px 5px 15px rgba(0, 0, 0, .1))",
                            willChange: "filter",
                        }}
                    />
                ) : (
                    <CheckmarkCircleSvg
                        size={"4rem"}
                        circleColor={colorFactory(
                            branding?.receipt.background.textColor ||
                                hexColor("#000000"),
                        ).withAlpha(0.7)}
                        checkmarkColor={branding?.receipt.background.textColor}
                        css={{
                            filter: "drop-shadow( 5px 5px 15px rgba(0, 0, 0, .1))",
                            willChange: "filter",
                        }}
                    />
                )}
                <VerticalDivider M />
                {order?.hasBeenRejected ? (
                    <TextBox
                        text={translation({
                            sv: "Order nekad",
                            en: "Order rejected",
                        })}
                        css={{
                            color: branding?.receipt.background.textColor,
                            textShadow: "0 0 100px rgba(0, 0, 0, 0.75)",
                            fontWeight: 700,
                            paddingBottom: 3,
                            fontSize: "1.125rem",
                            "@media print": {
                                color: (
                                    branding?.colorThemes.light || theme.colors
                                ).black.asString,
                            },
                        }}
                    />
                ) : (
                    <Paragraph
                        content={[
                            translation({
                                sv: "Tack",
                                en: "Thank you",
                            }),
                            customer?.firstName
                                ? ` ${customer?.firstName}!`
                                : `!`,
                        ]}
                        spin={customerQuery.isPending}
                        css={{
                            color: branding?.receipt.background.textColor,
                            textShadow: "0 0 100px rgba(0, 0, 0, 0.75)",
                            fontWeight: 700,
                            paddingBottom: 3,
                            fontSize: "1.125rem",
                            "@media print": {
                                color: (
                                    branding?.colorThemes.light || theme.colors
                                ).black.asString,
                            },
                        }}
                    />
                )}
                <VerticalDivider XXS />
                <Paragraph
                    css={{
                        maxWidth: 240,
                        fontSize: "0.9rem",
                        textAlign: "center",
                        color: branding?.receipt.background.textColor,
                        opacity: 0.8,
                        textShadow: "0 0 100px rgba(0, 0, 0, 0.75)",
                        "@media print": {
                            color: (branding?.colorThemes.light || theme.colors)
                                .black.asString,
                        },
                    }}
                    content={greetingText}
                />
            </Conditional>
        </>
    );
};

export { _ReceiptGreeting };
