import { useTheme } from "@emotion/react";
import { faFaceDotted } from "@fortawesome/pro-light-svg-icons";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { IconName, RemSize, Translatable, camelCase } from "@towni/common";
import { useEffect, useState } from "react";
import { Icon } from "./icon";

const dynamicIconCache = new Map<string, IconDefinition>();

type IconLibrary = "regular" | "solid" | "duotone" | "light" | "brand" | "thin";
type Props = {
    icon: IconName | undefined;
    spin?: boolean;
    fixedWidth?: boolean;
    className?: string;
    size?: number | RemSize;
    /** defaults to regular */
    library?: IconLibrary;
    title?: Translatable;
    fallback?: IconDefinition;
    transparentWhenNoIcon?: boolean;
};

const DynamicIcon = (props: Props) => {
    const theme = useTheme();
    const library = props.library || "regular";
    const fullIconName = `${library}_${props.icon}`;
    const [[iconName, icon], setIcon] = useState<
        [string | undefined, IconDefinition | undefined]
    >(() => {
        const iconDefinition = dynamicIconCache.get(fullIconName);
        return iconDefinition
            ? [fullIconName, iconDefinition]
            : [undefined, undefined];
    });
    useEffect(() => {
        if (iconName === fullIconName) return;
        const getLibrary = () => {
            switch (library) {
                case "regular":
                    return import("@fortawesome/pro-regular-svg-icons");
                case "solid":
                    return import("@fortawesome/pro-solid-svg-icons");
                case "duotone":
                    return import("@fortawesome/pro-duotone-svg-icons");
                case "light":
                    return import("@fortawesome/pro-light-svg-icons");
                case "brand":
                    return import("@fortawesome/free-brands-svg-icons");
                case "thin":
                    return import("@fortawesome/pro-thin-svg-icons");
                default:
                    return import("@fortawesome/pro-regular-svg-icons");
            }
        };
        getLibrary()
            .then(module => {
                const key: keyof typeof module = camelCase(
                    props.icon ?? "",
                ) as keyof typeof module;
                const iconDefinition = module[key] as
                    | IconDefinition
                    | undefined;
                if (iconDefinition && props.icon) {
                    dynamicIconCache.set(fullIconName, iconDefinition);
                }
                setIcon([
                    fullIconName,
                    iconDefinition ? iconDefinition : props.fallback,
                ]);
            })
            .catch(error => {
                throw error;
            });
    }, [props.icon, library, props.fallback, icon, iconName, fullIconName]);

    return (
        <Icon
            icon={props.transparentWhenNoIcon && !icon ? faFaceDotted : icon}
            fixedWidth={props.fixedWidth}
            size={props.size}
            spin={props.spin}
            className={props.className}
            delayRenderingInMs={!icon ? 50 : undefined}
            title={props.title}
            css={{
                color:
                    props.transparentWhenNoIcon && !icon
                        ? theme.colors.transparent.asString
                        : undefined,
            }}
        />
    );
};

export { DynamicIcon };
export type { IconLibrary };
