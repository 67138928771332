import { browserLogger } from "@@/settings";
import { apiFetchClient } from "@@/shared/fetch-client";
import { MutationFunction } from "@tanstack/react-query";
import type { GetSingleResponse, SwishSettings } from "@towni/common";
import { atom } from "jotai";
import {
    atomWithMutation,
    atomWithQuery,
    queryClientAtom,
} from "jotai-tanstack-query";
import { Except } from "type-fest";

const swishSettingsQueryAtom = atomWithQuery(() => {
    return {
        queryKey: ["swish-settings"],
        initialData: undefined,
        queryFn: async ({ signal }) => {
            try {
                const data = await apiFetchClient.get<
                    GetSingleResponse<SwishSettings>
                >({
                    route: "/settings/swish",
                    customConfig: {
                        signal,
                    },
                });
                return data.item;
            } catch (error) {
                browserLogger.warn(error);
                return null;
            }
        },
    };
});

type SetSwishSettingsCommand = Except<SwishSettings, "_id" | "_type"> & {
    _type: "SET_SWISH_SETTINGS_COMMAND";
};

const setSwishSettingsMutationAtom = atomWithMutation(get => {
    const queryClient = get(queryClientAtom);
    const mutationFn: MutationFunction<
        unknown,
        SetSwishSettingsCommand
    > = async command => {
        await apiFetchClient.post({
            route: "/settings/swish",
            body: command,
        });
    };
    return {
        mutationKey: ["swish-settings"],
        mutationFn,
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["swish-settings"],
            });
        },
    };
});

const swishSettingsAtom = atom(get => {
    const query = get(swishSettingsQueryAtom);
    return query?.data || undefined;
});

export {
    setSwishSettingsMutationAtom,
    swishSettingsAtom,
    swishSettingsQueryAtom,
};
