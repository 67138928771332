import { useBookingAccommodationContext } from "@@/products/accommodations/booking-accommodation-context";
import { browserLogger } from "@@/settings";
import { DateSelectionVt_v2 } from "@@/shared/date-time/date-time-selection/dates/date-selection-vt_v2/date-selection-vt_v2";
import {
    type EndOrPartOfRangeStatus,
    endOrPartOfRangeStatusFactory,
} from "@@/shared/date-time/date-time-selection/dates/date-selection-vt_v2/end-or-part-of-range-data";
import {
    SelectableDate,
    selectableDateFactory,
} from "@@/shared/date-time/date-time-selection/dates/date-selection-vt_v2/selectable-date";
import {
    type AccommodationAvailabilityDate,
    type AccommodationFeed,
    type DateRange,
    type DurationInDays,
    type IsoAndUnixTimestamp,
    type IsoTimestamp,
    type TimeRange,
    adjustTimeForAccommodation,
    asDate,
    dateRangeFactory,
    defaultTimeZone,
    inDays,
    isDateRangeOnlyOneDate,
    isTimeRange,
    startOfDayZoned,
    timeRangeFactory,
} from "@towni/common";
import { addDays, addMonths } from "date-fns";
import { useCallback, useMemo } from "react";

const AccommodationDatePicker = () => {
    const {
        selectedTime,
        feed,
        setSelectedTime: setTimeRange,
        cutoffDate,
    } = useBookingAccommodationContext(state => ({
        selectedTime: state.timeRange,
        setSelectedTime: state.setTime,
        feed: state.feed,
        cutoffDate: state.cutoffDate,
    }));

    const [selectableDates, firstDate, lastDate] = useMemo((): [
        selectableDates: Map<IsoTimestamp, SelectableDate>,
        firstDate: IsoAndUnixTimestamp | undefined,
        lastDate: IsoAndUnixTimestamp | undefined,
    ] => {
        browserLogger.time("selectableDates");
        const result: SelectableDate[] = [];
        // Loop through all possible check in dates
        for (
            // Start at the beginning of the feed
            let checkInDateIndex = 0;
            // Loop as long as we have dates left in the feed
            checkInDateIndex < feed.dates.length;
            // Increment the index with one day each iteration
            checkInDateIndex++
        ) {
            const checkInDate = feed.dates[checkInDateIndex];

            // Skip dates after the cutoff date
            if (cutoffDate.unix > checkInDate.date.unix) continue;

            // Can anyone check in on the current date?
            // console.log("🐙💜🐙💜🐙", checkInDate);
            if (!checkInDate.settings.canCheckIn) {
                // If not add a result that is not selectable
                // and return to continue the outer loop
                result.push(
                    selectableDateFactory(
                        { date: checkInDate.date },
                        defaultTimeZone,
                    ),
                );
                continue;
            } else {
                // We can check in this days so we'll add it as a selectable date
                // and continue to check if we can check out
                result.push(
                    selectableDateFactory(
                        {
                            date: checkInDate.date,
                            asStartOfRange: true,
                        },
                        defaultTimeZone,
                    ),
                );
            }
            // If they can check in, make sure they can check out as well
            // And that the dates in between are available
            // loop through all dates as long as they are within the min- and maximum stay
            for (
                // start at current date (not minimum stay, have to check days in between as well if any)
                let checkoutDateIndex = checkInDateIndex;
                // loop as long as we have dates left in the feed or we reach the maximum stay
                checkoutDateIndex < feed.dates.length &&
                checkoutDateIndex <=
                    checkInDateIndex + checkInDate.settings.maximumStay;
                // increment the index with one day each iteration
                checkoutDateIndex++
            ) {
                const checkoutDate = feed.dates[checkoutDateIndex];
                // If the date is not available, we can't check out now nor in the future
                if (!checkoutDate.isAvailable) {
                    // If it's not available, add a day that is not selectable
                    // and break to continue the outer loop since this inner loop
                    // is finished, there's no point in continuing
                    result.push(
                        selectableDateFactory(
                            { date: checkInDate.date },
                            defaultTimeZone,
                        ),
                    );
                    break;
                }

                // Now if we haven't reached the minimum stay, we can't check out
                // since we have to stay at least the minimum stay
                // But we have to make sure the day is available
                // which we have already checked above,
                // so if we haven't reached the minimum stay,
                // we'll just continue and check the next day
                if (
                    checkoutDateIndex - checkInDateIndex <
                    checkInDate.settings.minimumStay
                ) {
                    // We'll add the day's result as a selectable date and continue
                    result.push(
                        selectableDateFactory(
                            {
                                date: checkoutDate.date,
                                asPartOfRange: endOrPartOfRangeStatusFactory(
                                    new Set([checkInDate.date.iso]),
                                ),
                            },
                            defaultTimeZone,
                        ),
                    );
                    continue;
                }

                // If we have reached the minimum stay, we'll check if we can check out
                // If we can't check out, the day is still available and we haven't reached the
                // maximum stay, so well just continue and check the next day
                if (!checkoutDate.settings.canCheckOut) {
                    // We'll add the day's result as a selectable date and continue
                    result.push(
                        selectableDateFactory(
                            {
                                date: checkoutDate.date,
                                asPartOfRange: endOrPartOfRangeStatusFactory(
                                    new Set([checkInDate.date.iso]),
                                ),
                            },
                            defaultTimeZone,
                        ),
                    );
                    continue;
                }

                // Now we now 1) the day is available, 2) we have reached the minimum stay and
                // 3) we haven't reached the maximum stay, and 4) we can check out
                // So we can add the day's result and continue the loop to continue checking
                // for more days to check out
                result.push(
                    selectableDateFactory(
                        {
                            date: checkoutDate.date,
                            asEndOfRange: endOrPartOfRangeStatusFactory(
                                new Set([checkInDate.date.iso]),
                            ),
                        },
                        defaultTimeZone,
                    ),
                );
                continue;
            }
            result.push(
                selectableDateFactory(
                    {
                        date: checkInDate.date,
                        asStartOfRange: false,
                    },
                    defaultTimeZone,
                ),
            );
            continue;
        }
        browserLogger.timeEnd("selectableDates");
        browserLogger.time("selectableDatesMerge");

        // Merge all date results to make sure we have all meta data for
        // each date only once and mapped by the date's iso timestamp
        const { mapped, firstDate, lastDate } =
            mergeSelectableDateResults(result);
        browserLogger.timeEnd("selectableDatesMerge");
        return [mapped, firstDate, lastDate];
    }, [feed, cutoffDate.unix]);

    const selectionTimeRange = useMemo(() => {
        if (!firstDate || !lastDate) {
            // Both always has to be defined/undefined at the same time
            const today = startOfDayZoned(new Date(), defaultTimeZone);
            const in3Months = addMonths(today, 3);
            return timeRangeFactory(addDays(today, -1), in3Months);
        }
        return timeRangeFactory(firstDate, lastDate);
    }, [firstDate, lastDate]);

    browserLogger.info({
        feed,
    });

    const selectedDateRange = useMemo(() => {
        if (!selectedTime) return undefined;

        if (isTimeRange(selectedTime)) {
            const start = startOfDayZoned(
                asDate(selectedTime.start),
                defaultTimeZone,
            );
            const end = startOfDayZoned(
                asDate(selectedTime.end),
                defaultTimeZone,
            );
            return dateRangeFactory(start, end);
        }
        const start = startOfDayZoned(asDate(selectedTime), defaultTimeZone);
        return dateRangeFactory(start);
    }, [selectedTime]);
    const dateRange = dateRangeFactory(selectionTimeRange);
    const validate = useCallback(
        (dateRange: DateRange) => {
            const timeRange = timeRangeFactory(dateRange);
            const partOfFeed = getPartOfFeedForTimeRange(timeRange, feed);
            return checkIfTimeRangeIsBookable(timeRange, partOfFeed);
        },
        [feed],
    );
    const onSelect = useCallback(
        (selection: DateRange | undefined) => {
            if (isDateRangeOnlyOneDate(selection)) {
                setTimeRange(selection?.start);
                return;
            }

            if (!selection) {
                setTimeRange(undefined);
                return;
            }
            const timeRange = timeRangeFactory(selection);
            const partOfFeed = getPartOfFeedForTimeRange(timeRange, feed);
            if (partOfFeed.length <= 1) return;
            const firstDay = partOfFeed[0];
            setTimeRange(
                adjustTimeForAccommodation(
                    timeRange,
                    firstDay.settings.checkInTime,
                    firstDay.settings.checkOutTime,
                ),
            );
        },
        [feed, setTimeRange],
    );

    return (
        <DateSelectionVt_v2
            selectedDateRange={selectedDateRange}
            dateRange={dateRange}
            timeZone="Europe/Stockholm"
            validation={validate}
            selectableDates={selectableDates}
            onSelect={onSelect}
            datesArePassedBefore={cutoffDate}
        />

        // <DateSelectionVtRange
        //     initiallySelected={
        //         isTimeRange(selectedTime) ? selectedTime : undefined
        //     }
        //     timeRange={selectionTimeRange}
        //     onSelect={time => {
        //         if (isTimeRange(time)) {
        //             const partFeed = getPartFeed(feed, time);
        //             if (partFeed.length <= 1) return;

        //             //Use its settings
        //             const firstDay = partFeed[0];

        //             setTimeRange(
        //                 adjustTimeForAccommodation(
        //                     time,
        //                     firstDay.settings.checkInTime,
        //                     firstDay.settings.checkOutTime,
        //                 ),
        //             );
        //         } else {
        //             if (time) {
        //                 // Test to see if we can auto set the end date
        //                 // const partFeed = feed.dates.find(
        //                 //     d => d.date.unix === time.unix,
        //                 // );

        //                 // if (partFeed) {
        //                 //     const dates = selectableDatesForAccommodation(
        //                 //         time,
        //                 //         partFeed.settings.minimumStay,
        //                 //         partFeed.settings.maximumStay,
        //                 //     );
        //                 //     browserLogger.info({
        //                 //         dates,
        //                 //     });
        //                 //     if (dates.length === 1) {
        //                 //         const lastDay = dates[0];

        //                 //         setTimeRange(
        //                 //             adjustTimeForAccommodation(
        //                 //                 timeRangeFactory(time, lastDay),
        //                 //                 partFeed.settings.checkInTime,
        //                 //                 partFeed.settings.checkOutTime,
        //                 //             ),
        //                 //         );
        //                 //     } else {
        //                 //         setTimeRange(time);
        //                 //     }
        //                 // }

        //                 setTimeRange(time);
        //             } else {
        //                 setTimeRange(time);
        //             }
        //         }
        //     }}
        //     validate={_range => {
        //         const val = checkIfRangeIsBookable(_range, feed);

        //         return val;
        //     }}
        //     selectableDates={selectableDates}
        //     selectableDatesForTimeRange={selectableDatesForTimeRange}
        //     bookedDates={[]}
        //     cutoffDate={cutoffDate}
        // />
    );
};

type PartOfFeedForTimeRange = AccommodationAvailabilityDate[];
const getPartOfFeedForTimeRange = (
    timeRange: TimeRange,
    feed: AccommodationFeed,
): PartOfFeedForTimeRange => {
    const partFeed = feed.dates.filter(
        d =>
            d.date.unix >= timeRange.start.unix &&
            d.date.unix <= timeRange.end.unix,
    );
    return partFeed;
};

const getDaysOfRequestedBookableRange = (
    timeRangeToBook: TimeRange,
    partOfFeedForTimeRange: PartOfFeedForTimeRange,
): {
    numberOfDays: DurationInDays;
    firstDay: AccommodationAvailabilityDate;
    lastDay: AccommodationAvailabilityDate;
    allDays: AccommodationAvailabilityDate[];
    allDaysExceptFirstAndLast: AccommodationAvailabilityDate[];
    allDaysExceptLast: AccommodationAvailabilityDate[];
} => {
    // Parse the first and last day of the time range from the feed
    const numberOfDays = inDays({ seconds: timeRangeToBook.duration });
    const firstDay = partOfFeedForTimeRange[0];
    const lastDay = partOfFeedForTimeRange[partOfFeedForTimeRange.length - 1];
    const allDays = partOfFeedForTimeRange;
    const allDaysExceptFirstAndLast = allDays.slice(1, -1);
    const allDaysExceptLast = allDays.slice(0, -1); // don't know why it's all days except last, but @jakob probably knows

    return {
        numberOfDays,
        firstDay,
        lastDay,
        allDays,
        allDaysExceptFirstAndLast,
        allDaysExceptLast,
    };
};

/** Check if a time range is available to book in the provided accommodation feed */
const checkIfTimeRangeIsBookable = (
    timeRangeToBook: TimeRange,
    partOfFeedForTimeRange: PartOfFeedForTimeRange,
): boolean => {
    // If no availability for the time range in the feed, return false
    if (partOfFeedForTimeRange.length <= 1) return false;
    const { numberOfDays, firstDay, lastDay, allDaysExceptLast } =
        getDaysOfRequestedBookableRange(
            timeRangeToBook,
            partOfFeedForTimeRange,
        );

    // Check if the first and last day is are bookable
    // - Check if the first day is bookable by checking if check-in is allowed
    if (!firstDay.settings.canCheckIn) return false;
    // - Check if the last day is bookable by checking if check-out is allowed
    if (!lastDay.settings.canCheckOut) return false;

    // Check if the number of days is within the allowed range when
    // booking starting from `firstDay`
    if (numberOfDays < firstDay.settings.minimumStay) return false; // Need to stay at least `minimumStay` days, so no
    if (numberOfDays > firstDay.settings.maximumStay) return false; // Can't stay more than `maximumStay` days, so no

    // Check if all days except the last day are available
    if (allDaysExceptLast.some(day => !day.isAvailable)) return false;

    //Todo check if same resource is available all days in the range

    return true;
};

const mergeRangeStatus = (
    rangeStatus1: EndOrPartOfRangeStatus | undefined,
    rangeStatus2: EndOrPartOfRangeStatus | undefined,
): EndOrPartOfRangeStatus => {
    if (!rangeStatus1 && !rangeStatus2)
        return endOrPartOfRangeStatusFactory(new Set());
    if (!rangeStatus1 && rangeStatus2) return rangeStatus2;
    if (rangeStatus1 && !rangeStatus2) return rangeStatus1;
    return endOrPartOfRangeStatusFactory(
        new Set([
            ...(rangeStatus1?.withStartAt ?? []),
            ...(rangeStatus2?.withStartAt ?? []),
        ]),
    );
};

const mergeSelectableDateResults = (
    selectableDates: SelectableDate[],
): {
    firstDate: IsoAndUnixTimestamp;
    lastDate: IsoAndUnixTimestamp;
    mapped: Map<IsoTimestamp, SelectableDate>;
} => {
    let firstDate: IsoAndUnixTimestamp | undefined = selectableDates[0]?.date;
    let lastDate: IsoAndUnixTimestamp | undefined = selectableDates[0]?.date;
    const result = new Map<IsoTimestamp, SelectableDate>();
    for (const date of selectableDates) {
        const existing = result.get(date.date.iso);
        if (!firstDate || firstDate.unix > date.date.unix) {
            firstDate = date.date;
        }
        if (!lastDate || lastDate.unix < date.date.unix) {
            lastDate = date.date;
        }
        if (existing) {
            result.set(date.date.iso, {
                ...existing,
                asStartOfRange: existing.asStartOfRange || date.asStartOfRange,
                asEndOfRange: mergeRangeStatus(
                    existing.asEndOfRange,
                    date.asEndOfRange,
                ),
                asPartOfRange: mergeRangeStatus(
                    existing.asPartOfRange,
                    date.asPartOfRange,
                ),
            });
        } else {
            result.set(date.date.iso, date);
        }
    }

    return {
        mapped: result,
        firstDate: firstDate,
        lastDate: lastDate,
    };
};

export { AccommodationDatePicker };
