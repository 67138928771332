import { SingleContentBlockViewContextProvider } from "@@/backoffice/for-providers/pages/blocks/content-blocks/content-auto-blocks/single-content-page-block/single-content-page-block-view-context";
import { getBlockContentTargetUrl } from "@@/backoffice/for-providers/pages/blocks/content-blocks/shared/grid-views/shared/get-block-content-target-url";
import { inactiveCss } from "@@/backoffice/for-providers/pages/blocks/content-blocks/shared/grid-views/shared/inactive-css";
import { useBlockContentQuery } from "@@/backoffice/for-providers/pages/blocks/content-blocks/shared/use-block-content";
import { CallToActionButton } from "@@/backoffice/for-providers/pages/blocks/shared/call-to-action-button";
import { usePageViewContext } from "@@/backoffice/for-providers/pages/editor/page-view-context";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers/vertical-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { LayoutGrid } from "@@/shared/layout-grid";
import { Link } from "@@/shared/link";
import { TextShimmer } from "@@/shared/pictures/shimmers";
import { breakpointRange } from "@@/shared/responsiveness/breakpoint-ranges";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text/text-box";
import { useStorageItemImageReference } from "@@/storage-items/queries/use-storage-item-reference";
import {
    useIsTranslatableEmpty,
    useTranslate,
} from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { faImageLandscape } from "@fortawesome/pro-regular-svg-icons";
import {
    BlockContent,
    SingleContentPageBlock,
    assertNever,
    replacePlaceholderInTranslatable,
} from "@towni/common";
import { useMemo } from "react";

type Props = {
    readonly block: SingleContentPageBlock;
    readonly width?: React.CSSProperties["width"];
    readonly className?: string;
};

const SingleContentPageBlockView = (props: Props) => {
    const theme = useTheme();
    const { width, block } = props;
    const isTranslatableEmpty = useIsTranslatableEmpty();
    const isMobile = usePageBreakpoint({ when: "📱" });
    const isSmallScreen = usePageBreakpoint({ when: "SMALL_SCREEN" });
    const {
        pageInnerWidth,
        pageMaxWidth,
        defaultGridGap,
        // defaultTextShadow,
        providerId,
    } = usePageViewContext();
    const [imageReference] = useStorageItemImageReference(
        block.backgroundImage,
    );

    const blockContentQuery = useBlockContentQuery(block.contentId, {
        showInactive: !!block.showInactiveContent,
    });
    const blockContent = useMemo(() => {
        return blockContentQuery.data?.items[0];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        blockContentQuery.data?.items,
        block.contentId,
        block.showInactiveContent,
    ]);

    const hasAnyTitle =
        !isTranslatableEmpty(block.title) ||
        !isTranslatableEmpty(block.subtitle) ||
        !isTranslatableEmpty(block.kicker);

    const titleColor =
        block.titleColor ??
        block.textColor ??
        theme.colors.default.text.asString;
    const subtitleColor =
        block.subtitleColor ??
        block.textColor ??
        theme.colors.default.text.withAlpha(0.5).asString;
    const kickerColor =
        block.kickerColor ??
        block.textColor ??
        theme.colors.default.text.asString;

    const targetUrl = blockContent
        ? getBlockContentTargetUrl(blockContent, providerId)
        : undefined;

    const titleAlignment = (() => {
        if (block.titleAlignment === "auto") return "center";
        if (block.titleAlignment) return block.titleAlignment;
        // if (block.layout === "hzScroll") return "left";
        return "center";
    })();

    const items = useMemo(() => {
        const result = [
            <TextSquareContent
                key="text"
                block={props.block}
                blockContent={blockContent}
                targetUrl={targetUrl}
            />,
            <ImageSquareContent
                key="image"
                block={props.block}
                blockContent={blockContent}
                targetUrl={targetUrl}
            />,
        ] as const;
        return block.layout === "leftToRight"
            ? result
            : ([...result].reverse() as unknown as typeof result);
    }, [props.block, blockContent, targetUrl, block.layout]);

    return (
        <SingleContentBlockViewContextProvider block={block}>
            <FlexColumn
                fillParentWidth
                mainAxis={"flex-start"}
                crossAxis={"center"}
                background={
                    imageReference
                        ? {
                              imageReference,
                              fit: "COVER",
                              imageSize: {
                                  imageWidth: isMobile
                                      ? breakpointRange.MOBILE.max
                                      : Math.min(
                                            breakpointRange.DESKTOP.max,
                                            pageMaxWidth,
                                        ),
                              },
                          }
                        : {
                              color:
                                  block.backgroundColor ??
                                  theme.colors.default.background,
                          }
                }>
                <Conditional
                    when={blockContentQuery.isPending && !!block.contentId}>
                    {/* Loading block content */}
                    <Conditional when={hasAnyTitle}>
                        <FlexRow
                            fillParentWidth
                            mainAxis="center"
                            padding={{ all: 20 }}>
                            <FlexRow fillParentWidth maxWidth={pageInnerWidth}>
                                <TextShimmer rows={3} rowHeight={"20px"} />
                            </FlexRow>
                        </FlexRow>
                    </Conditional>
                </Conditional>
                <Conditional
                    when={!block.contentId || !blockContentQuery.isPending}>
                    {/* Finished querying block content data */}
                    <FlexColumn
                        fillParentWidth
                        maxWidth={
                            pageInnerWidth
                            // block.layout !== "hzScroll" ? pageInnerWidth : undefined
                        }
                        mainAxis={"flex-start"}
                        crossAxis={"stretch"}
                        css={{
                            paddingTop: hasAnyTitle ? 30 : 0,
                            paddingBottom: hasAnyTitle ? 20 : 0,
                            paddingLeft: isMobile ? 40 : 80,
                            paddingRight: isMobile ? 40 : 80,
                            flexShrink: 0,
                            label: `content_page_block_titles_container`,
                        }}>
                        <Conditional when={!isTranslatableEmpty(block.kicker)}>
                            <TextBox
                                text={replacePlaceholderInTranslatable(
                                    block.kicker || "",
                                    "*",
                                    "•",
                                )}
                                lineClamp={1}
                                case="UPPERCASE"
                                padding={{
                                    left: isMobile ? defaultGridGap : undefined,
                                }}
                                size={0.625}
                                weight="700"
                                align={titleAlignment}
                                color={kickerColor}
                            />
                            <VerticalDivider XXS />
                        </Conditional>
                        <Conditional when={!isTranslatableEmpty(block.title)}>
                            <TextBox
                                text={block.title}
                                padding={{
                                    left: isMobile ? defaultGridGap : undefined,
                                }}
                                size={isMobile ? 1.5 : 2}
                                weight="700"
                                align={titleAlignment}
                                color={titleColor}
                                lineClamp={2}
                            />
                        </Conditional>
                        <Conditional
                            when={!isTranslatableEmpty(block.subtitle)}>
                            <VerticalDivider XS />
                            <TextBox
                                text={block.subtitle}
                                padding={{
                                    left: isMobile ? defaultGridGap : undefined,
                                }}
                                size={1}
                                lineClamp={8}
                                align={titleAlignment}
                                color={subtitleColor}
                                css={{ opacity: 0.6, lineHeight: 1.3 }}
                            />
                        </Conditional>
                        <Conditional when={hasAnyTitle}>
                            <VerticalDivider XS />
                        </Conditional>
                    </FlexColumn>
                </Conditional>

                <Conditional
                    when={
                        !!block.contentId &&
                        !!blockContent &&
                        (!blockContent.inactive || !!block.showInactiveContent)
                    }>
                    <LayoutGrid
                        css={[
                            {
                                gridTemplateColumns: (() => {
                                    if (isMobile) return "1fr";
                                    return `1fr 1fr`;
                                })(),
                                gridTemplateRows: (() => {
                                    if (isMobile) return "1fr 1fr";
                                    return "1fr";
                                })(),
                                gap: 0,
                                width: width ?? "100%",
                                maxWidth: pageInnerWidth, // pageMaxWidth * 0.8,
                                padding: isSmallScreen ? 20 : 40,
                                paddingTop: hasAnyTitle ? 0 : 10,
                                paddingBottom: 30,
                            },
                            ...(blockContent?.inactive ? [inactiveCss] : []),
                        ]}>
                        {items[0]}
                        {items[1]}
                    </LayoutGrid>
                </Conditional>
            </FlexColumn>
        </SingleContentBlockViewContextProvider>
    );
};

const TextSquareContent = (props: {
    block: SingleContentPageBlock;
    blockContent: BlockContent | undefined;
    className?: string;
    targetUrl: string | undefined;
}) => {
    const block = props.block;
    const translate = useTranslate();
    const blockContent = props.blockContent;
    const theme = useTheme();
    const isMobile = usePageBreakpoint({ when: "📱" });
    const isTranslatableEmpty = useIsTranslatableEmpty();
    const { defaultBorderRadius } = usePageViewContext();
    const topLeftBorderRadius = (() => {
        if (block.style === "loose") return 0;
        switch (isMobile) {
            case true:
                if (block.layout === "leftToRight") {
                    // Text at the top, image at the bottom
                    return defaultBorderRadius;
                }
                if (block.layout === "rightToLeft") {
                    // Text at the bottom, image at the top
                    return 0;
                }
                assertNever(block.layout);
            // eslint-disable-next-line no-fallthrough
            case false:
            default:
                if (block.layout === "leftToRight") {
                    // Text to the left, image to the right
                    return defaultBorderRadius;
                }
                if (block.layout === "rightToLeft") {
                    // Text to the right, image to the left
                    return 0;
                }
                assertNever(block.layout);
        }
    })();
    const bottomLeftBorderRadius = (() => {
        if (block.style === "loose") return 0;
        switch (isMobile) {
            case true:
                if (block.layout === "leftToRight") {
                    // Text at the top, image at the bottom
                    return 0;
                }
                if (block.layout === "rightToLeft") {
                    // Text at the bottom, image at the top
                    return defaultBorderRadius;
                }
                assertNever(block.layout);
            // eslint-disable-next-line no-fallthrough
            case false:
            default:
                if (block.layout === "leftToRight") {
                    // Text to the left, image to the right
                    return defaultBorderRadius;
                }
                if (block.layout === "rightToLeft") {
                    // Text to the right, image to the left
                    return 0;
                }
                assertNever(block.layout);
        }
    })();
    const topRightBorderRadius = (() => {
        if (block.style === "loose") return 0;
        switch (isMobile) {
            case true:
                if (block.layout === "leftToRight") {
                    // Text at the top, image at the bottom
                    return defaultBorderRadius;
                }
                if (block.layout === "rightToLeft") {
                    // Text at the bottom, image at the top
                    return 0;
                }
                assertNever(block.layout);
            // eslint-disable-next-line no-fallthrough
            case false:
            default:
                if (block.layout === "leftToRight") {
                    // Text to the left, image to the right
                    return 0;
                }
                if (block.layout === "rightToLeft") {
                    // Text to the right, image to the left
                    return defaultBorderRadius;
                }
                assertNever(block.layout);
        }
    })();
    const bottomRightBorderRadius = (() => {
        if (block.style === "loose") return 0;
        switch (isMobile) {
            case true:
                if (block.layout === "leftToRight") {
                    // Text at the top, image at the bottom
                    return 0;
                }
                if (block.layout === "rightToLeft") {
                    // Text at the bottom, image at the top
                    return defaultBorderRadius;
                }
                assertNever(block.layout);
            // eslint-disable-next-line no-fallthrough
            case false:
            default:
                if (block.layout === "leftToRight") {
                    // Text to the left, image to the right
                    return 0;
                }
                if (block.layout === "rightToLeft") {
                    // Text to the right, image to the left
                    return defaultBorderRadius;
                }
                assertNever(block.layout);
        }
    })();

    const textColor =
        block.style === "solid"
            ? theme.colors.white.asString
            : theme.colors.default.text.asString;

    const backgroundColor = (() => {
        if (block.style === "solid") return theme.colors.black.asString;
        if (block.style === "loose") return theme.colors.transparent.asString;
        return theme.colors.default.background.asString;
    })();

    const titleAlignment = "center";
    const kicker = (() => {
        if (!blockContent) return "";
        if (!isTranslatableEmpty(block.contentKickerOverride))
            return block.contentKickerOverride;
        if (!isTranslatableEmpty(blockContent?.kicker))
            return blockContent?.kicker;
        return "";
    })();
    const title = (() => {
        if (!blockContent) return "";
        if (!isTranslatableEmpty(block.contentTitleOverride))
            return block.contentTitleOverride;
        if (!isTranslatableEmpty(blockContent?.title))
            return blockContent?.title;
        return "";
    })();
    const subtitle = (() => {
        if (!blockContent) return "";
        if (!isTranslatableEmpty(block.contentSubtitleOverride))
            return block.contentSubtitleOverride;
        if (!isTranslatableEmpty(blockContent?.subtitle))
            return blockContent?.subtitle;
        return "";
    })();

    return (
        <div
            css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                paddingBottom: "100%",
            }}>
            <Link
                to={props.targetUrl ?? ""}
                target="_blank"
                title={translate(blockContent?.title)}
                disabled={!blockContent || blockContent.inactive}
                css={{ cursor: "pointer" }}>
                <div
                    css={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: "flex",
                        alignItems: "stretch",
                        justifyContent: "stretch",
                        backgroundColor,
                        borderTopLeftRadius: topLeftBorderRadius,
                        borderBottomLeftRadius: bottomLeftBorderRadius,
                        borderTopRightRadius: topRightBorderRadius,
                        borderBottomRightRadius: bottomRightBorderRadius,
                        overflow: "hidden",
                    }}>
                    <FlexColumn
                        css={{ flex: 1, padding: 20 }}
                        mainAxis="center"
                        crossAxis="center">
                        {/* Kicker */}
                        {/* Empty state */}
                        <Conditional when={!blockContent}>
                            <TextBox
                                text={translate({
                                    sv: "(inget valt innehåll)",
                                    en: "(no content selected)",
                                })}
                                lineClamp={1}
                                case="UPPERCASE"
                                size={0.625}
                                weight="700"
                                align={titleAlignment}
                                color={textColor}
                            />
                        </Conditional>
                        <Conditional when={!!kicker}>
                            <TextBox
                                text={replacePlaceholderInTranslatable(
                                    kicker || "",
                                    "*",
                                    "•",
                                )}
                                lineClamp={1}
                                case="UPPERCASE"
                                size={0.625}
                                weight="700"
                                align={titleAlignment}
                                color={textColor}
                            />
                            <VerticalDivider XXS />
                        </Conditional>
                        <Conditional when={!!title}>
                            <TextBox
                                text={title}
                                lineClamp={2}
                                size={isMobile ? 1.5 : 2}
                                weight="700"
                                align={titleAlignment}
                                color={textColor}
                            />
                            <VerticalDivider M />
                        </Conditional>
                        <Conditional when={!!subtitle}>
                            <TextBox
                                text={subtitle}
                                lineClamp={6}
                                size={1.125}
                                align={titleAlignment}
                                color={textColor}
                                css={{ opacity: 0.6, lineHeight: 1.3 }}
                            />
                            <VerticalDivider M />
                        </Conditional>
                        {/* CTA BUTTON IF ENABLED */}
                        <Conditional
                            when={
                                blockContent &&
                                block.ctaButtonEnabled &&
                                !isTranslatableEmpty(block.ctaButtonText)
                            }
                            render={() => (
                                <CallToActionButton
                                    block={block}
                                    disabled={
                                        !blockContent || blockContent.inactive
                                    }
                                />
                            )}
                        />
                    </FlexColumn>
                </div>
            </Link>
        </div>
    );
};
const ImageSquareContent = (props: {
    block: SingleContentPageBlock;
    blockContent: BlockContent | undefined;
    className?: string;
    targetUrl: string | undefined;
}) => {
    const block = props.block;
    const blockContent = props.blockContent;
    const theme = useTheme();
    const translate = useTranslate();
    const isMobile = usePageBreakpoint({ when: "📱" });
    const { defaultBorderRadius } = usePageViewContext();
    const topLeftBorderRadius = (() => {
        if (block.style === "loose") return defaultBorderRadius;
        switch (isMobile) {
            case true:
                if (block.layout === "leftToRight") {
                    // Text at the top, image at the bottom
                    return 0;
                }
                if (block.layout === "rightToLeft") {
                    // Text at the bottom, image at the top
                    return defaultBorderRadius;
                }
                assertNever(block.layout);
            // eslint-disable-next-line no-fallthrough
            case false:
            default:
                if (block.layout === "leftToRight") {
                    // Text to the left, image to the right
                    return 0;
                }
                if (block.layout === "rightToLeft") {
                    // Text to the right, image to the left
                    return defaultBorderRadius;
                }
                assertNever(block.layout);
        }
    })();
    const bottomLeftBorderRadius = (() => {
        if (block.style === "loose") return defaultBorderRadius;
        switch (isMobile) {
            case true:
                if (block.layout === "leftToRight") {
                    // Text at the top, image at the bottom
                    return defaultBorderRadius;
                }
                if (block.layout === "rightToLeft") {
                    // Text at the bottom, image at the top
                    return 0;
                }
                assertNever(block.layout);
            // eslint-disable-next-line no-fallthrough
            case false:
            default:
                if (block.layout === "leftToRight") {
                    // Text to the left, image to the right
                    return 0;
                }
                if (block.layout === "rightToLeft") {
                    // Text to the right, image to the left
                    return defaultBorderRadius;
                }
                assertNever(block.layout);
        }
    })();
    const topRightBorderRadius = (() => {
        if (block.style === "loose") return defaultBorderRadius;
        switch (isMobile) {
            case true:
                if (block.layout === "leftToRight") {
                    // Text at the top, image at the bottom
                    return 0;
                }
                if (block.layout === "rightToLeft") {
                    // Text at the bottom, image at the top
                    return defaultBorderRadius;
                }
                assertNever(block.layout);
            // eslint-disable-next-line no-fallthrough
            case false:
            default:
                if (block.layout === "leftToRight") {
                    // Text to the left, image to the right
                    return defaultBorderRadius;
                }
                if (block.layout === "rightToLeft") {
                    // Text to the right, image to the left
                    return 0;
                }
                assertNever(block.layout);
        }
    })();
    const bottomRightBorderRadius = (() => {
        if (block.style === "loose") return defaultBorderRadius;
        switch (isMobile) {
            case true:
                if (block.layout === "leftToRight") {
                    // Text at the top, image at the bottom
                    return defaultBorderRadius;
                }
                if (block.layout === "rightToLeft") {
                    // Text at the bottom, image at the top
                    return 0;
                }
                assertNever(block.layout);
            // eslint-disable-next-line no-fallthrough
            case false:
            default:
                if (block.layout === "leftToRight") {
                    // Text to the left, image to the right
                    return defaultBorderRadius;
                }
                if (block.layout === "rightToLeft") {
                    // Text to the right, image to the left
                    return 0;
                }
                assertNever(block.layout);
        }
    })();

    const backgroundColor = (() => {
        if (block.style === "solid") return theme.colors.black.light95.asString;
        if (block.style === "loose") return theme.colors.transparent.asString;
        return theme.colors.default.background.asString;
    })();

    return (
        <div
            css={{
                flex: 1,
                display: "flex",
                alignItems: "stretch",
                justifyContent: "stretch",
                position: "relative",
                paddingBottom: "100%",
            }}>
            <Link
                to={props.targetUrl ?? ""}
                target="_blank"
                title={translate(blockContent?.title)}
                disabled={!blockContent || blockContent.inactive}
                css={{ cursor: "pointer" }}>
                <FlexColumn
                    mainAxis="center"
                    crossAxis="center"
                    css={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        left: 0,
                        right: 0,
                        display: "flex",
                        borderTopLeftRadius: topLeftBorderRadius,
                        borderBottomLeftRadius: bottomLeftBorderRadius,
                        borderTopRightRadius: topRightBorderRadius,
                        borderBottomRightRadius: bottomRightBorderRadius,
                        overflow: "hidden",
                    }}
                    background={{
                        color: blockContent?.backgroundColor ?? backgroundColor,
                        imageReference: blockContent?.poster,
                        fit: "COVER",
                    }}>
                    <Conditional when={!blockContent?.poster}>
                        <div>
                            <Icon
                                icon={faImageLandscape}
                                size={2}
                                color={theme.colors.black.light80}
                            />
                        </div>
                    </Conditional>
                </FlexColumn>
            </Link>
        </div>
    );
};

export { SingleContentPageBlockView };
