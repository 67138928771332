import { asArray, emptyArrayOf } from "@towni/common";
import { useCallback, useMemo, useRef, useState } from "react";

type UseSetStateResult<Value> = {
    add: (value: Value) => void;
    has: (value: Value) => boolean;
    reset: (resetTo?: Set<Value>) => void;
    set: Set<Value>;
    setMany: (values: Value[]) => void;
    remove: (value: Value) => void;
    values: Value[];
};

const useSet = <Value>(initialSet?: Set<Value>): UseSetStateResult<Value> => {
    const initialSetRef = useRef(initialSet);
    const [set, setSet] = useState<Set<Value>>(
        initialSetRef.current ?? new Set<Value>(),
    );

    const add = useCallback(
        (value: Value) => {
            setSet(prev => new Set(prev).add(value));
        },
        [setSet],
    );
    const has = useCallback(
        (value: Value) => {
            return set.has(value);
        },
        [set],
    );

    const setMany = useCallback(
        (values: Value[]) => {
            setSet(prev => {
                const updated = new Set(prev);
                values.forEach(value => updated.add(value));
                return updated;
            });
        },
        [setSet],
    );

    const reset = useCallback(
        (resetTo?: Set<Value>) => {
            setSet(resetTo ?? initialSetRef.current ?? new Set());
        },
        [setSet],
    );

    const remove = useCallback(
        (value: Value) => {
            setSet(prev => {
                const updated = new Set(prev);
                updated.delete(value);
                return updated;
            });
        },
        [setSet],
    );

    const values = useMemo(() => Array.from(set.values()).sort(), [set]);

    const result = useMemo(
        () => ({
            add,
            has,
            reset,
            set,
            setMany,
            remove,
            values,
        }),
        [add, has, reset, set, setMany, remove, values],
    );
    return result;
};

type UseArrayStateResult<Value> = {
    add: (value: Value) => void;
    has: (value: Value) => boolean;
    reset: (resetTo?: Array<Value>) => void;
    remove: (value: Value) => void;
    values: Value[];
};

const useArrayState = <Value>(
    initialValue?: Value[],
): UseArrayStateResult<Value> => {
    const initialSetRef = useRef(initialValue);
    const [state, setState] = useState<Array<Value>>(
        initialSetRef.current ?? emptyArrayOf<Value>(),
    );

    const add = useCallback(
        (value: Value | Value[]) => {
            setState(prev => [...prev, ...asArray(value)]);
        },
        [setState],
    );
    const has = useCallback(
        (value: Value) => {
            return state.includes(value);
        },
        [state],
    );

    const reset = useCallback(
        (resetTo?: Array<Value>) => {
            setState(resetTo ?? initialSetRef.current ?? emptyArrayOf<Value>());
        },
        [setState],
    );

    const remove = useCallback(
        (value: Value) => {
            setState(prev => {
                const updated = [...prev].filter(v => v !== value);
                return updated;
            });
        },
        [setState],
    );

    const result = useMemo(
        () => ({
            add,
            has,
            reset,
            remove,
            values: state,
        }),
        [add, has, reset, state, remove],
    );
    return result;
};

export { useArrayState, useSet };
export type { UseArrayStateResult, UseSetStateResult };
