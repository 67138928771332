/* eslint-disable react/prop-types */
// import { ReactQueryDevtools } from "react-query-devtools";
import { useMountEffect } from "@@/shared/use-mount-effect";
import { useTranslate } from "@@/translations/use-translate";
import {
    assertNever,
    exists,
    joinTranslatables,
    toTranslatableString,
    translatableString,
} from "@towni/common";
import { z } from "zod";

const svTranslateValue = (value: unknown, includeGenus = false) => {
    if (typeof value !== "string") return JSON.stringify(value);
    switch (value) {
        case "string": {
            const prefix = includeGenus ? "en " : "";
            return `${prefix}text`;
        }
        case "number": {
            const prefix = includeGenus ? "ett " : "";
            return `${prefix}nummer`;
        }
        case "bigint": {
            const prefix = includeGenus ? "ett " : "";
            return `${prefix}stort nummer`;
        }
        case "boolean":
            return "sant/falskt";
        case "symbol": {
            const prefix = includeGenus ? "en " : "";
            return `${prefix}symbol`;
        }
        case "undefined": {
            return `ingenting (odefinierat)`;
        }
        case "object": {
            const prefix = includeGenus ? "ett " : "";
            return `${prefix}objekt`;
        }
        case "function": {
            const prefix = includeGenus ? "en " : "";
            return `${prefix}funktion`;
        }
        case "map": {
            const prefix = includeGenus ? "en " : "";
            return `${prefix} lista med nycklar och värden (map)`;
        }
        case "nan": {
            return `något som inte är ett nummer`;
        }
        case "integer": {
            const prefix = includeGenus ? "ett " : "";
            return `${prefix}heltal`;
        }
        case "float": {
            const prefix = includeGenus ? "ett " : "";
            return `${prefix}flyttal`;
        }
        case "date": {
            const prefix = includeGenus ? "ett " : "";
            return `${prefix}datum`;
        }
        case "null": {
            return `ingenting (null)`;
        }
        case "array": {
            const prefix = includeGenus ? "en " : "";
            return `${prefix}lista (array)`;
        }
        case "unknown":
            return "okänt";
        case "promise": {
            const prefix = includeGenus ? "ett " : "";
            return `${prefix}löfte`;
        }
        case "void": {
            const prefix = includeGenus ? "något " : "";
            return `${prefix}ogiltigt`;
        }
        case "never": {
            return `aldrig`;
        }
        case "set": {
            const prefix = includeGenus ? "en " : "";
            return `${prefix}lista (set)`;
        }
        case "datetime":
            return "datum och tid";
        case "email": {
            const prefix = includeGenus ? "en " : "";
            return `${prefix}e-postadress`;
        }
        default:
            return value;
    }
};
const enTranslateValue = (value: unknown) => {
    if (typeof value !== "string") return JSON.stringify(value);
    return value;
};

const SetTranslatedZodErrorMap = () => {
    const translate = useTranslate();
    const getErrorMessage = (
        issue: z.ZodIssueOptionalMessage,
        ctx: z.ErrorMapCtx | { defaultError: z.ErrorMapCtx["defaultError"] },
        depth = 0,
    ): string[] | null => {
        if (depth > 10) return null;
        switch (issue.code) {
            case "custom": {
                return [ctx.defaultError];
            }
            case "invalid_arguments":
                return [issue.argumentsError.flatten().formErrors.join(", ")];
            case "invalid_date":
                return [
                    toTranslatableString({
                        sv: "Ogiltigt datum",
                        en: "Invalid date",
                    }),
                ];
            case "invalid_enum_value":
                return [
                    toTranslatableString({
                        sv: `Endast följande alternativ är giltiga: ${issue.options.join(", ")}`,
                        en: `Only the following options are valid: ${issue.options.join(", ")}`,
                    }),
                ];
            case "invalid_intersection_types":
                return [
                    toTranslatableString({
                        sv: "Ogiltig kombination av typer",
                        en: "Invalid combination of types",
                    }),
                ];
            case "invalid_literal":
                return [
                    toTranslatableString({
                        sv: `Förväntat värde: ${svTranslateValue(issue.expected)}, fick: ${svTranslateValue(issue.received === "undefined" ? "inget" : issue.received)}`,
                        en: `Expected: ${issue.expected}, received: ${issue.received}`,
                    }),
                ];
            case "invalid_return_type":
                return [
                    toTranslatableString({
                        sv: `Ogiltig returtyp: ${issue.returnTypeError.flatten().formErrors.join(", ")}`,
                        en: `Invalid return type: ${issue.returnTypeError.flatten().formErrors.join(", ")}`,
                    }),
                ];
            case "invalid_string":
                return [
                    toTranslatableString({
                        sv: `Ogiltig ${svTranslateValue(issue.validation)}`,
                        en: `Invalid ${enTranslateValue(issue.validation)}`,
                    }),
                ];
            case "invalid_type":
                return [
                    toTranslatableString({
                        // sv: `Förväntat värde: ${svTranslateValue(issue.expected)}, fick: ${svTranslateValue(issue.received === "undefined" ? "inget" : issue.received)}`,
                        sv: `Felaktigt värde. Ange ${svTranslateValue(issue.expected, true)}`,
                        en: `Expected: ${issue.expected}, received: ${issue.received}`,
                    }),
                ];
            case "invalid_union":
                return issue.unionErrors
                    .flatMap(error =>
                        error.issues
                            .flatMap(issue => getErrorMessage(issue, ctx))
                            .filter(exists),
                    )
                    .filter(exists);
            case "invalid_union_discriminator":
                return [ctx.defaultError];
            // return {
            //     message: ctx.defaultError,
            // };
            case "not_finite":
                return [
                    toTranslatableString({
                        sv: "Förväntades ett ändligt tal",
                        en: "Expected a finite number",
                    }),
                ];
            case "not_multiple_of":
                return [
                    toTranslatableString({
                        sv: `Förväntades multipel av ${issue.multipleOf}`,
                        en: `Expected multiple of ${issue.multipleOf}`,
                    }),
                ];
            case "too_big":
                if (issue.type === "string")
                    return [
                        toTranslatableString({
                            sv: `För långt (max ${issue.maximum} tecken)`,
                            en: `Too long (max ${issue.maximum} characters)`,
                        }),
                    ];
                return [
                    toTranslatableString({
                        en: `Max value ${issue.maximum}`,
                        sv: `Maxvärde ${issue.maximum}`,
                    }),
                ];
            case "too_small":
                switch (issue.type === "string") {
                    case true:
                        return [
                            toTranslatableString({
                                sv: `För kort (minst ${issue.minimum} tecken)`,
                                en: `Too short (at least ${issue.minimum} characters)`,
                            }),
                        ];
                    default: {
                        return [
                            toTranslatableString({
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                sv: `${(ctx as any)?.data} är för lite. Minsta tillåtna värde är ${issue.minimum}`,
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                en: `${(ctx as any)?.data} is too small. Minimum value is ${issue.minimum}`,
                            }),
                        ];
                    }
                }
            case "unrecognized_keys":
                return [
                    toTranslatableString({
                        sv: `Okända nycklar: ${issue.keys.join(", ")}`,
                        en: `Unrecognized keys: ${issue.keys.join(", ")}`,
                    }),
                ];
            default:
                assertNever(issue);
        }
    };

    useMountEffect(() => {
        z.setErrorMap((issue, ctx) => {
            const customMessage = issue.message
                ? translate(
                      translatableString.fromTranslatableString(issue.message),
                  )
                : null;
            if (customMessage) {
                return { message: customMessage };
            }
            const messages = getErrorMessage(issue, ctx);
            return {
                message: messages
                    ? toTranslatableString(
                          joinTranslatables(
                              messages.map(message =>
                                  translatableString.fromTranslatableString(
                                      message,
                                  ),
                              ),
                              ", ",
                          ),
                      )
                    : ctx.defaultError,
            };
        });
    });
    return null;
};

export { SetTranslatedZodErrorMap };
