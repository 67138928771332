import {
    useFetchMultipleForMultiple,
    useFetchMultipleForSingle,
} from "@@/shared/generic-fetchers";
import {
    TanstackQueryOptions,
    useTanstackMultiQuery,
} from "@@/shared/use-tanstack-query";
import {
    AccessKeyId,
    Order,
    OrderId,
    OrderStatusType,
    ProductId,
    ProviderId,
    UnixTimestamp,
} from "@towni/common";

const mainQueryKey = "orders";
const mainRoute = "orders";

const useOrder = (
    orderId?: string | null,
    accessKeyId?: AccessKeyId,
    options: TanstackQueryOptions<Order[]> = {},
) =>
    useTanstackMultiQuery<Order>(
        `/orders/${orderId}${accessKeyId ? `?ak=${accessKeyId}` : ""}`,
        [mainQueryKey, orderId],
        {
            enabled: !!orderId,
            ...options,
        },
    );

const useOrders = (
    orderIds: OrderId[] = [],
    accessKeyId?: AccessKeyId,
    options?: TanstackQueryOptions<Order[]>,
) =>
    useTanstackMultiQuery<Order>(
        `/orders/?ids=${orderIds.join(",")}${accessKeyId ? `&ak=${accessKeyId}` : ""}`,
        [mainQueryKey, ...orderIds],
        {
            enabled: !!orderIds?.length,
            ...(options ?? {}),
        },
    );

const useSearchOrdersForProvider = (params: {
    providerId?: ProviderId;
    search?: string;
    options?: TanstackQueryOptions<Order[]>;
}) => {
    const { providerId, search } = params;
    return useTanstackMultiQuery<Order>(
        `/orders/for-search/${search}${providerId ? `?providerId=${providerId}` : ""}`,
        [mainQueryKey, providerId, search],
        {
            enabled: !!search,
            ...(params.options ?? {}),
        },
    );
};
// !----
const useOrdersForProduct = (params: {
    productId: ProductId;
    includeFilter?: OrderStatusType[];
    excludeFilter?: OrderStatusType[];
    fromDate?: UnixTimestamp;
    toDate?: UnixTimestamp;
    byBookingDate?: boolean;
    options?: { enabled?: boolean };
}) => {
    const {
        productId,
        includeFilter,
        excludeFilter,
        fromDate,
        toDate,
        byBookingDate,
    } = params;
    const result = useFetchMultipleForSingle<Order>({
        mainQueryKey,
        mainRoute,
        fetchFor: "product",
        id: productId, //+ (status ? "?status=" + status.join(",") : "")
        options: {
            enabled:
                typeof params.options?.enabled === "boolean"
                    ? params.options.enabled && !!productId
                    : !!productId,
        },
        filters: {
            include: includeFilter ?? [],
            exclude: excludeFilter ?? [],
            from: (fromDate ? [fromDate] : []) as unknown as string[],
            to: (toDate ? [toDate] : []) as unknown as string[],
            byBookingDate: byBookingDate ? ["true"] : ["false"],
        },
    });
    return result;
};
// const useInfiniteOrdersForProvider = (providerId?: ProviderId) =>
//     useInfiniteFetchFor<Order>(mainQueryKey, mainRoute, "provider", providerId);
const useOrdersForProviders = (providerIds?: string[]) =>
    useFetchMultipleForMultiple<Order>(
        mainQueryKey,
        mainRoute,
        "provider",
        providerIds,
    );

const useOrdersForShipping = (shippingId?: string, refetchInterval?: number) =>
    useFetchMultipleForSingle<Order>({
        mainQueryKey,
        mainRoute,
        fetchFor: "shipping",
        id: shippingId,
        options: { refetchInterval },
    });

const useOrdersForMe = () =>
    useFetchMultipleForSingle<Order>({
        mainQueryKey,
        mainRoute,
        fetchFor: "user",
        id: "me",
    });

export {
    mainQueryKey as ordersMainQueryKey,
    useOrder,
    useOrders,
    useOrdersForMe,
    useOrdersForProduct,
    useOrdersForProviders,
    useOrdersForShipping,
    useSearchOrdersForProvider,
};
