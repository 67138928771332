import { AppTheme } from "@@/styles/theme";
import { css, Interpolation, useTheme } from "@emotion/react";
import { assertNever } from "@towni/common";
import { dateCircleSize } from "./date-circle-size";
import { DateStatus } from "./date-status";

/**
 * Calculated styling for a date cell of the date selection vt component based on its status.
 *
 * @param status - The status of the date.
 * @returns The interpolated styling for the date.
 */
const useDateStyling = (status: DateStatus): Interpolation<AppTheme> => {
    const theme = useTheme();
    const _base = css({
        display: "flex",
        width: dateCircleSize,
        height: dateCircleSize,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 3000,
        fontSize: "1em",
        backgroundColor: theme.colors.transparent.asString,
        color: theme.colors.default.text.asString,
        userSelect: "none",
    });
    switch (status) {
        case "SELECTED__SOLO":
        case "SELECTED__FIRST":
        case "SELECTED__LAST":
            return css([
                _base,
                {
                    borderWidth: 2,
                    borderStyle: "solid",
                    borderColor: theme.colors.primary.asString,
                    backgroundColor: theme.colors.primary.asString,
                    color: theme.colors.primary.text.value.asString,
                },
            ]);
        case "SELECTED__PART_OF_RANGE":
            return css([
                _base,
                {
                    border: "none",
                    color: theme.colors.black.asString,
                },
            ]);
        case "SELECTABLE__START_OF_RANGE":
        case "SELECTABLE__END_OF_RANGE":
            return css([
                _base,
                {
                    //   backgroundColor: theme.colors.primary.main.light90.asString,
                    borderWidth: 2,
                    borderStyle: "solid",
                    borderColor: theme.isLightTheme
                        ? theme.colors.primary.main.light40.asString
                        : theme.colors.primary.main.light20.asString,
                    color: theme.isLightTheme
                        ? theme.colors.black.light20.asString
                        : theme.colors.black.light60.asString,
                },
            ]);
        case "SELECTABLE__PART_OF_RANGE":
            return css([
                _base,
                {
                    cursor: "unset",
                    borderWidth: 2,
                    borderStyle: "dashed",
                    borderColor: theme.colors.primary.asString,
                    color: theme.isLightTheme
                        ? theme.colors.primary.asString
                        : theme.colors.primary.asString,
                    opacity: 1,
                    "& *": {
                        cursor: "unset",
                    },
                },
            ]);
        case "SELECTABLE__START_OF_NEW_RANGE": {
            return css([
                _base,
                {
                    borderWidth: 2,
                    borderStyle: "solid",
                    borderColor: theme.isLightTheme
                        ? theme.colors.primary.main.light40.asString
                        : theme.colors.primary.main.light20.asString,
                    color: theme.isLightTheme
                        ? theme.colors.black.light20.asString
                        : theme.colors.black.light60.asString,
                },
            ]);
        }
        case "PASSED":
            return css([
                _base,
                {
                    border: "none",
                    opacity: 0.2,
                },
            ]);
        case "UNAVAILABLE":
            return css([
                _base,
                {
                    border: "none",
                    opacity: 0.5,
                },
            ]);
        default:
            assertNever(status);
    }
};

export { useDateStyling };
