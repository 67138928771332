import { browserLogger } from "@@/settings/browser-logger";
import * as React from "react";
import { MergeExclusive } from "type-fest";
import { TextShimmer } from "./pictures/shimmers";

type PropsExist<T = unknown> = {
    readonly exists: T | undefined;
    /**
     * defaults to no_render
     */
    readonly type?: "hide" | "no_render";
    readonly children?: (definedValue: T) => React.ReactNode;
    readonly debug?: boolean;
};
type Props<T = unknown> = MergeExclusive<PropsIf, PropsExist<T>> & {
    readonly spin?: boolean;
    readonly whenLoading?: () => React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const isExistConditional = <T extends unknown = unknown>(
    props: Props<T>,
): props is PropsExist<T> => {
    return "exists" in props;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const Conditional = <T extends unknown = unknown>(props: Props<T>) => {
    if (props.spin) {
        return <>{props.whenLoading?.() ?? <TextShimmer rows={3} />}</>;
    }
    const existsValue = isExistConditional(props);
    if (!existsValue) {
        if (props.debug) browserLogger.log("conditional if", { props });
        return <ConditionalIf {...props} />;
    }
    if (props.debug) browserLogger.log("conditional exists", { props });
    const definedProps = props as PropsWhenDefined<T>;
    return <WhenDefined<T> {...definedProps} />;
};

type PropsIf = {
    /**
     * defaults to no_render
     */
    readonly type?: "hide" | "no_render";
    readonly children?: React.ReactNode;
    readonly render?: () => React.ReactNode;
    readonly else?: () => React.ReactNode;
    readonly whenAll?: boolean[];
    readonly whenSome?: boolean[];
    readonly when?: boolean;
    readonly whenNot?: boolean;
    readonly debug?: boolean;
};

const ConditionalIf = (props: PropsIf) => {
    const active = when(props);

    const renderRef = React.useRef(props.render);
    renderRef.current = props.render;

    const elseRef = React.useRef(props.else);
    elseRef.current = props.else;

    if (
        !active &&
        !elseRef.current &&
        (typeof props.type === "undefined" || props.type === "no_render")
    ) {
        return null;
    }

    const renderElse = elseRef.current?.();
    return props.type === "hide" ? (
        <>
            <div
                css={{
                    display: !active && renderElse ? "block" : "none",
                    label: "render_else",
                }}>
                {renderElse}
            </div>
            <div
                css={{
                    display: !active ? "none" : "block",
                    label: "render_active",
                }}>
                {renderRef.current?.() ?? props.children ?? null}
            </div>
        </>
    ) : (
        <>
            {active
                ? (renderRef.current?.() ?? props.children ?? null)
                : renderElse || null}
        </>
    );
};

const when = (props: PropsIf) => {
    const { when, whenNot, whenAll, whenSome } = props;

    if ("when" in props) return !!when;
    if ("whenNot" in props) return !whenNot;

    if ("whenSome" in props && whenSome !== undefined)
        return whenSome.length === 0 || whenSome.some(Boolean);

    if ("whenAll" in props && whenAll !== undefined)
        return whenAll.length === 0 || whenAll.every(Boolean);

    return false;
};

type PropsWhenDefined<T = unknown> = {
    readonly exists: T | undefined;
    /**
     * defaults to no_render
     */
    readonly type?: "hide" | "no_render";
    readonly children?: (value: NonNullable<T>) => React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const WhenDefined = <T extends unknown = unknown>(
    props: PropsWhenDefined<T>,
) => {
    const item = props.exists;
    const type = typeof props.type === "undefined" ? "no_render" : props.type;
    const childrenRef = React.useRef(props.children);
    childrenRef.current = props.children;

    if (typeof item === "undefined") {
        return null;
    }
    if (type === "hide") {
        return (
            <div style={{ display: "hidden" }}>
                {childrenRef.current?.(item as NonNullable<T>)}
            </div>
        );
    }

    return <>{childrenRef.current?.(item!)}</>;
};

export { Conditional, WhenDefined };
export type { Props as ConditionalProps };
