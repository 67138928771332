import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { paddingToCssValue } from "@@/shared/padding";
import { FieldTitle } from "@@/shared/text/field-title";
import { TextBox } from "@@/shared/text/text-box";
import { useTranslate } from "@@/translations/use-translate";
import { SerializedStyles, useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import {
    IconDefinition,
    faChevronDown,
} from "@fortawesome/pro-solid-svg-icons";
import { ColorSet, Padding, RemSize, Translatable } from "@towni/common";
import * as React from "react";

type NameValue<Value extends string | number | undefined = string> = {
    name: Translatable | number;
    value: Value;
    disabledOption?: boolean;
};

type Props<Value extends string | number | undefined = string> = {
    readonly data: NameValue<Value>[];
    readonly selectedValue: Value | undefined;
    readonly noneValue?: Value;
    readonly onSelect: (nameAndValue: NameValue<Value>) => void;
    readonly styling?: SerializedStyles;
    readonly containerCss?: SerializedStyles;
    readonly padding?: Padding;
    readonly disabled?: boolean;
    readonly title?: Translatable;
    readonly description?: Translatable;
    readonly spin?: boolean;
    readonly icon?: IconDefinition;
    readonly className?: string;
    /**
     * rem size
     * @type {(RemSize | number)}
     */
    readonly fontSize?: RemSize | number;
    /**
     * defaults to "right"
     * @type {("left" | "right")}
     */
    readonly iconPlacement?: "left" | "right";
    readonly iconColor?: string;
    readonly iconSize?: RemSize | number;
    readonly hideContainer?: boolean;
    readonly colorSet?: ColorSet;
};

const SelectPicker = <Value extends string | number | undefined = string>(
    props: Props<Value>,
) => {
    const theme = useTheme();
    const translate = useTranslate();
    const colorSet = props.colorSet ?? theme.colors.textInput;

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (props.disabled) return;
        const find = props.data.find(
            f => f.value?.toString() === e.currentTarget.value,
        );

        if (find) props.onSelect(find);
        else props.onSelect(props.data[0]);
    };

    const textColor = colorSet.text.asString;
    const disabled = !!(props.disabled || props.spin);
    const iconPlacement = props.iconPlacement || "right";
    const fontSize = props.fontSize ?? 1;
    const iconSize = props.iconSize ?? fontSize;

    return (
        <FlexColumn fillParentWidth mainAxis="flex-start">
            {props.title ? (
                <>
                    <FieldTitle
                        text={props.title}
                        css={{
                            opacity: disabled ? 0.45 : 1,
                        }}
                    />
                    <VerticalDivider XS />
                </>
            ) : null}
            <FlexRow
                crossAxis="center"
                css={[
                    { flex: 1 },
                    props.containerCss ?? {},
                    { position: "relative" },
                ]}>
                <Conditional when={iconPlacement === "left"}>
                    <Icon
                        icon={props.spin ? faSpinnerThird : props.icon}
                        fixedWidth
                        color={props.iconColor ?? theme.colors.primary.asString}
                        spin={props.spin}
                        size={iconSize}
                        style={{
                            pointerEvents: "none",
                            position: "absolute",
                            fontWeight: 700,
                            marginLeft: 10,
                            zIndex: 10,
                            opacity: disabled ? 0.45 : 1,
                        }}
                    />
                </Conditional>
                <select
                    onChange={handleChange}
                    disabled={disabled}
                    value={props.selectedValue}
                    className={props.className}
                    css={[
                        {
                            WebkitAppearance: "none",
                            width: "100%",
                            color: textColor,
                            backgroundColor: props.hideContainer
                                ? theme.colors.transparent.asString
                                : colorSet.background.asString,
                            textOverflow: "ellipsis",
                            opacity: disabled ? 0.3 : 1,
                            border: props.hideContainer
                                ? "none"
                                : `1px solid ${colorSet.border.asString}`,
                            borderRadius: theme.radius,
                            fontSize: `${fontSize}rem`,
                            padding: paddingToCssValue(
                                props.padding || {
                                    top: 10,
                                    bottom: 10,
                                    right:
                                        iconPlacement === "right"
                                            ? 36 * fontSize
                                            : 15,
                                    left:
                                        props.icon && iconPlacement === "left"
                                            ? 36 * fontSize
                                            : 15,
                                },
                            ),
                            cursor: disabled ? "default" : "pointer",
                        },
                        props.styling,
                        {
                            cursor: disabled ? "default" : "pointer",
                        },
                    ]}>
                    {props.data.map(
                        ({ name, value, disabledOption }, index) => {
                            const _name =
                                typeof name === "number"
                                    ? name.toString()
                                    : translate(name);

                            return (
                                <option
                                    key={
                                        value ?? "undefined" + index.toString()
                                    }
                                    value={value}
                                    disabled={
                                        disabled ||
                                        (disabledOption !== undefined &&
                                            disabledOption)
                                    }>
                                    {_name}
                                </option>
                            );
                        },
                    )}
                </select>
                <Conditional when={iconPlacement === "right"}>
                    <Icon
                        icon={
                            props.spin
                                ? faSpinnerThird
                                : (props.icon ?? faChevronDown)
                        }
                        fixedWidth
                        size={iconSize}
                        color={props.iconColor ?? theme.colors.primary.asString}
                        opacity={disabled ? 0.5 : 1}
                        spin={props.spin}
                        style={{
                            pointerEvents: "none",
                            position: "relative",
                            fontWeight: 700,
                            marginLeft: iconSize > fontSize ? -35 : -30,
                            zIndex: 10,
                        }}
                    />
                </Conditional>
            </FlexRow>
            {props.description ? (
                <>
                    <VerticalDivider XS />
                    <TextBox
                        text={props.description}
                        color={theme.colors.black.withAlpha(0.5).asString}
                        css={{ padding: 0 }}
                        size={0.8}
                    />
                </>
            ) : null}
        </FlexColumn>
    );
};

export { SelectPicker };
export type { NameValue as KeyValue };
