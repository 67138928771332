import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModal } from "@@/modals/use-modal";
import { ButtonPrimaryLight } from "@@/shared/buttons_v2/button-primary-light";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Icon } from "@@/shared/icons/icon";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faClone } from "@fortawesome/pro-solid-svg-icons";
import { useEffect, useState } from "react";

type Props = {
    modalId: ModalId;
    index: number;
};

type BProps = {
    index: number;
};

const TestModalButton = (props: BProps) => {
    const [{ show }, modalId] = useModal("test_modal__");
    return (
        <>
            <ButtonPrimaryLight
                padding={{ leftRight: 12, topBottom: 10 }}
                css={{ border: "none" }}
                onClick={() => show()}>
                <FlexRow>
                    <TextBox text={"Skapa en livsmodal"} />
                    <HorizontalDivider />
                    <Icon icon={faClone} />
                </FlexRow>
            </ButtonPrimaryLight>
            <TestModal modalId={modalId} index={props.index + 1} />
        </>
    );
};

// Helper to generate a random grid (0: dead, 1: alive)
const generateRandomGrid = () =>
    Array.from({ length: gridSize }, () =>
        Array.from({ length: gridSize }, () => (Math.random() > 0.3 ? 1 : 0)),
    );

// Helper to count live neighbors
const countLiveNeighbors = (grid: number[][], x: number, y: number) => {
    const neighbors = [
        [-1, -1],
        [-1, 0],
        [-1, 1],
        [0, -1],
        /* cell */ [0, 1],
        [1, -1],
        [1, 0],
        [1, 1],
    ];

    return neighbors.reduce((liveCount, [dx, dy]) => {
        const newX = x + dx;
        const newY = y + dy;

        if (newX >= 0 && newX < gridSize && newY >= 0 && newY < gridSize) {
            liveCount += grid[newX][newY];
        }

        return liveCount;
    }, 0);
};

const gridSize = 24;
// Helper to get the next grid state according to Game of Life rules
const getNextGridState = (grid: number[][]) => {
    const newGrid = grid.map((row, x) =>
        row.map((cell, y) => {
            const liveNeighbors = countLiveNeighbors(grid, x, y);

            if (cell === 1) {
                // Any live cell with 2 or 3 live neighbors survives, otherwise it dies.
                return liveNeighbors === 2 || liveNeighbors === 3 ? 1 : 0;
            } else {
                // Any dead cell with exactly 3 live neighbors becomes alive.
                return liveNeighbors === 3 ? 1 : 0;
            }
        }),
    );

    return newGrid;
};

const TestModal = (props: Props) => {
    const theme = useTheme();
    const [gridArray, setGridArray] = useState(generateRandomGrid());
    const [count, setCount] = useState(0);

    useEffect(() => {
        const SECOND = 1000;

        const timer = setTimeout(() => {
            console.log("TestModal useEffect: Update grid");

            // Update the grid based on Game of Life rules
            const nextGrid = getNextGridState(gridArray);

            setGridArray(nextGrid);

            // Increment the count (or generation number)
            setCount(old => old + 1);
        }, 1 * SECOND); // Update every 5 seconds

        return () => {
            clearTimeout(timer);
        };
    }, [gridArray]);

    return (
        <Modal
            modalId={props.modalId}
            header={
                <ModalHeader
                    modalId={props.modalId}
                    bottomBorder
                    title={"Modal number " + props.index}
                />
            }>
            <FlexColumn fillParentWidth padding={{ all: 20 }}>
                Detta är en test modal skapa en ny modal för att testa
                <TestModalButton index={props.index} />
                <LayoutGrid
                    padding={{ leftRight: 20, topBottom: 20 }}
                    css={{
                        width: "100%",
                        height: "100%",
                        gridTemplateColumns: `repeat(${24}, 1fr)`,
                        gridTemplateRows: `repeat(${24}, 1fr)`,
                        gap: "5px",
                    }}>
                    <ForEach itemOf={gridArray.flat()}>
                        {(itemOf: number, index: number) => (
                            <LayoutCell
                                onClick={() => {
                                    const newGrid = [...gridArray];
                                    newGrid[Math.floor(index / gridSize)][
                                        index % gridSize
                                    ] = itemOf === 1 ? 0 : 1;
                                    setGridArray(newGrid);
                                }}
                                key={index}
                                css={{
                                    backgroundColor:
                                        itemOf === 1
                                            ? theme.colors.primary.asString
                                            : theme.colors.primary.light
                                                  .asString,
                                    border: "1px solid #ccc",
                                    borderRadius: "10px",
                                }}>
                                <TextBox
                                    text={itemOf.toString()}
                                    color={theme.colors.primary.text}
                                />
                            </LayoutCell>
                        )}
                    </ForEach>
                </LayoutGrid>
            </FlexColumn>
        </Modal>
    );
};

export { TestModalButton };
