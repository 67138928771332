import { TextBox } from "@@/shared/text/text-box";
import { useTheme } from "@emotion/react";
import { Translatable } from "@towni/common";

type Props = {
    readonly description: Translatable | undefined;
    readonly hideDescriptionAfterInput: boolean;
    readonly hasErrors: boolean;
    readonly isDirty: boolean;
    readonly className?: string;
};

const FormFieldDescription__ = (props: Props) => {
    const theme = useTheme();
    const showDescription =
        props.description &&
        !props.hasErrors &&
        !(props.hideDescriptionAfterInput && props.isDirty);
    if (!showDescription) return null;
    return (
        <TextBox
            padding={{ leftRight: 5, bottom: 5 }}
            text={props.description}
            color={theme.colors.black.light60}
            className={props.className}
            css={{
                fontSize: "0.8em",
                userSelect: "text",
            }}
        />
    );
};

export { FormFieldDescription__ as _FormFieldDescription };
