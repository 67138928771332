import { useToast } from "@@/toasts/context/toast-context";
import { useMutation } from "@tanstack/react-query";
import {
    SetUserPhoneNumberCommand,
    setUserPhoneNumberCommandFactory,
    SetUserPhoneNumberCommandFactoryParams,
} from "@towni/common";
import { useMe } from "../authentication/me/me-context";
import { apiFetchClient } from "../shared/fetch-client";

const useSetUserPhoneNumber = (options?: { onSuccess: () => void }) => {
    const [, { meQuery }] = useMe();
    const toast = useToast();

    const setPhoneNumberCommand = async (
        params: SetUserPhoneNumberCommandFactoryParams,
    ) => {
        await apiFetchClient.post<SetUserPhoneNumberCommand>({
            route: `/users/me/phone`,
            body: setUserPhoneNumberCommandFactory(params),
        });
    };

    return useMutation<void, Error, SetUserPhoneNumberCommandFactoryParams>({
        mutationFn: setPhoneNumberCommand,
        onSuccess: async (_, input) => {
            await meQuery.refetch();
            toast.success({
                message: `Ditt nya telefonnummer ${input.phoneNumber} är verifierat och uppdaterat`,
            });
            options?.onSuccess?.();
        },
    });
};
export { useSetUserPhoneNumber };
