import { emptyArrayOf, exists, existsWithOptions } from "@towni/common";
import * as React from "react";
import { useCallback, useMemo, useRef } from "react";

type Props<T> = {
    readonly itemOf: T[] | undefined;
    readonly children?: (item: T, index: number) => React.ReactNode;
    readonly whenEmpty?: JSX.Element;
    /** How to get key to set on each item rendered */
    readonly getKey?: (item: T, index: number) => string | number;
} & (
    | {
          readonly endWith?: JSX.Element;
          readonly beginWith?: JSX.Element;
          readonly divider?: JSX.Element | ((index: number) => JSX.Element);
          readonly beginEndAndDivideWith?: never;
      }
    | {
          readonly endWith?: never;
          readonly beginWith?: never;
          readonly divider?: never;
          readonly beginEndAndDivideWith?: JSX.Element;
      }
);

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ForEach = <T extends unknown>(props: Props<T>) => {
    const getKey = useCallback((item: T, index: number) => {
        const key = props.getKey?.(item, index) ?? index;
        return key;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const items = useMemo(() => {
        return (
            props.itemOf
                ?.filter(
                    existsWithOptions({
                        allowZero: true,
                    }),
                )
                .map((item, index) => {
                    const key = getKey(item, index);
                    return [key, item] as const;
                }) ?? emptyArrayOf<[string | number, T]>()
        );
    }, [getKey, props.itemOf]);

    const dividerRef = useRef(props.divider);
    dividerRef.current = props.divider;

    const beginEndAndDivideWithRef = useRef(props.beginEndAndDivideWith);
    beginEndAndDivideWithRef.current = props.beginEndAndDivideWith;

    const elements = useMemo((): JSX.Element[] => {
        return items
            .map(([key, item], index) => {
                const isLast = index === items.length - 1;
                const dividerProp =
                    dividerRef.current ?? beginEndAndDivideWithRef.current;
                const divider = (() => {
                    if (typeof dividerProp === "undefined") return null;
                    if (typeof dividerProp === "function")
                        return dividerProp(index);
                    return dividerProp;
                })();
                const child = (() => {
                    return props.children?.(item, index);
                })();
                if (typeof child === "undefined") return null;
                return (
                    <React.Fragment key={key}>
                        {child}
                        {isLast ? null : divider}
                    </React.Fragment>
                );
            })
            .filter(exists);
    }, [items, props]);

    if (!items?.length) return props.whenEmpty ?? null;
    return (
        <>
            {props.beginWith ?? props.beginEndAndDivideWith ?? null}
            {elements}
            {props.endWith ?? props.beginEndAndDivideWith ?? null}
        </>
    );
};

const genericMemo: <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any>,
>(
    component: T,
    propsAreEqual?: (
        prevProps: React.ComponentProps<T>,
        nextProps: React.ComponentProps<T>,
    ) => boolean,
) => T & { displayName?: string } = React.memo;

/** MemoizedForEach, won't rerender unless any other prop than children and render changes */
const MemoizedForEach = genericMemo(ForEach, (prev, next) => {
    return (
        prev.beginEndAndDivideWith === next.beginEndAndDivideWith &&
        prev.beginWith === next.beginWith &&
        prev.divider === next.divider &&
        prev.endWith === next.endWith &&
        prev.itemOf === next.itemOf &&
        prev.getKey === next.getKey &&
        prev.whenEmpty === next.whenEmpty
        // prev.children === next.children &&
        // prev.render === next.render &&
    );
});

export { ForEach, MemoizedForEach };
export type { Props as ForEachProps };
