import { browserLogger } from "@@/settings";
import { asArray } from "@towni/common";
import { Component, ErrorInfo, ReactNode } from "react";

type Props = {
    children: ReactNode;
    errorsToIgnore:
        | {
              name: string | string[];
              message: string;
          }
        | {
              name: string | string[];
              message: string;
          }[];
    onIgnore?: (error: Error) => void;
};

type State = {
    hasError: boolean;
    error?: Error;
};
class ErrorFilterBoundary extends Component<Props, State> {
    public state: State = { hasError: false, error: undefined };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
        browserLogger.warn(error, errorInfo);

        // Do we care?
        const errorsToIgnore = asArray(this.props.errorsToIgnore);

        // If the error is the same as the one we want to ignore
        const ignore = errorsToIgnore.some(errorToIgnore => {
            if (Array.isArray(errorToIgnore.name)) {
                if (errorToIgnore.name.includes(error.name))
                    return errorToIgnore.message === error.message;
                return false;
            }

            if (
                errorToIgnore.name === error.name &&
                errorToIgnore.message === error.message
            )
                return true;

            return false;
        });
        if (ignore) {
            browserLogger.warn("Ignoring", error);
            this.props.onIgnore?.(error);
            return;
        }

        this.setState({ hasError: true, error });
    }

    static getDerivedStateFromError(error: Error) {
        browserLogger.warn("ErrorFilterBoundary - Error caught", error);
        // We don't want to render the ErrorPage as it is done by MainErrorBoundaries
    }

    render() {
        if (this.state.hasError) {
            // eslint-disable-next-line @typescript-eslint/only-throw-error
            throw this.state.error;
        }

        // If there is no error, render default children
        return this.props.children;
    }
}

export { ErrorFilterBoundary };
