import { useLanguageContext } from "@@/settings/language-context";
import { currentTimezoneName } from "@@/shared/current-timezone-name";
import { getNameOfMonth, IsoAndUnixTimestamp } from "@towni/common";
import { useCallback } from "react";

const useGetMonthName = () => {
    const { language } = useLanguageContext();

    const get = useCallback(
        (date: Date | IsoAndUnixTimestamp, type: "short" | "long" = "long") => {
            switch (language) {
                case "sv":
                    return getNameOfMonth(
                        date,
                        type,
                        "sv",
                        currentTimezoneName,
                    );
                case "en":
                default:
                    return getNameOfMonth(
                        date,
                        type,
                        "en",
                        currentTimezoneName,
                    );
            }
        },
        [language],
    );

    return get;
};

export { useGetMonthName };
