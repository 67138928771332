import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Paragraph, TextBox, paragraphLinkFactory } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { support, translation } from "@towni/common";
import { useEffect } from "react";
import { createPortal } from "react-dom";
import { browserLogger } from "./browser-logger";
import { useBuildVersionStore } from "./build-version.store";

const BuildVersionMajorMismatchHandler = () => {
    const theme = useTheme();
    const buildVersions = useBuildVersionStore();

    useEffect(() => {
        if (buildVersions.majorMismatch) {
            browserLogger.error(
                "Build version major mismatch",
                buildVersions.server,
                buildVersions.client,
            );
        }
    }, [buildVersions]);

    if (!buildVersions.majorMismatch) return null;
    const portalElement = document.getElementById("bluescreen");
    if (!portalElement) throw new Error("Blue screen element not found");
    return createPortal(
        <div
            css={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflow: "hidden",
                pointerEvents: "auto",
                label: "bluescreen_backdrop",
                backgroundColor:
                    theme.colors.white.main.withAlpha(0.8).asString,
            }}>
            <FlexColumn
                fillParent
                mainAxis="center"
                crossAxis="center"
                css={{ padding: 40 }}>
                <FlexColumn
                    maxWidth={400}
                    crossAxis="center"
                    css={{
                        borderRadius: 10,
                        backgroundColor: "white",
                        padding: 40,
                        boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.2)",
                        pointerEvents: "auto",
                        label: "bluescreen_dialog",
                    }}>
                    <TextBox
                        text={translation({
                            sv: "Det finns nya uppdateringar 🌱",
                            en: "There are new updates 🌱",
                        })}
                        size={1.8}
                        align="center"
                        weight="900"
                        color={theme.colors.primary}
                    />
                    <VerticalDivider L />
                    <TextBox
                        text={translation({
                            sv: "Ladda om sidan för att ta del av nya funktioner och bakom kulisserna uppdateringar. När du laddar om sker uppdateringen automatiskt.",
                            en: "Reload the page to take advantage of new features and behind the scenes updates. When you reload, the update will happen automatically.",
                        })}
                        align="center"
                        color={theme.colors.black}
                        css={{ lineHeight: "1.4rem", opacity: 0.7 }}
                    />
                    <VerticalDivider L />
                    <FlexRow mainAxis="center">
                        <ButtonPrimary
                            css={{
                                borderRadius: 3000,
                                padding: "15px 50px",
                            }}
                            onClick={() => {
                                window.location.reload();
                            }}>
                            <TextBox
                                text={translation({
                                    sv: "Ladda om för senaste version",
                                    en: "Reload for latest version",
                                })}
                                color={theme.colors.white}
                            />
                        </ButtonPrimary>
                    </FlexRow>
                    <VerticalDivider L />
                    <Paragraph
                        css={{
                            lineHeight: "1.4rem",
                            textAlign: "center",
                            opacity: 0.3,
                            color: theme.colors.black.asString,
                            "&:hover": { opacity: 0.8 },
                        }}
                        content={[
                            translation({
                                sv: "Vi älskar att höra från er och finns på ",
                                en: "We love to hear from you and are available at ",
                            }),
                            paragraphLinkFactory({
                                text: support.towni.contactEmail,
                                href: `mailto:${support.towni.contactEmail}`,
                            }),
                            translation({
                                sv: " /Towni-teamet",
                                en: " /The Towni team",
                            }),
                        ]}
                    />
                </FlexColumn>
            </FlexColumn>
        </div>,
        portalElement,
    );
};

export { BuildVersionMajorMismatchHandler };
