import * as Sentry from "@sentry/react";
import "@stripe/stripe-js";
import {
    generateId,
    generateWordSlug,
    isApiError,
    svTranslator,
} from "@towni/common";
import { createRoot } from "react-dom/client";

import { App } from "./app";

import { browserLogger } from "./settings/browser-logger";
import { towniSessionId } from "./settings/towni-session-id";
import { currentTimezoneName } from "./shared/current-timezone-name";

// eslint-disable-next-line no-console
console.log(
    "\r\n         (    (    (      (                          )  \r\n   (     )\\ ) )\\ ) )\\ )   )\\ )     *   )      (   ( /(  \r\n   )\\   (()/((()/((()/(( (()/(   ` )  /((     )\\  )\\()) \r\n((((_)(  /(_))/(_))/(_))\\ /(_))   ( )(_))\\  (((_)((_)\\  \r\n )\\ _ )\\(_)) (_))_(_))((_|_))_   (_(_()|(_) )\\___ _((_) \r\n (_)_\\(_) |  | |_ | _ \\ __|   \\  |_   _| __((/ __| || | \r\n  / _ \\ | |__| __||   / _|| |) |   | | | _| | (__| __ | \r\n /_/ \\_\\|____|_|  |_|_\\___|___/    |_| |___| \\___|_||_| \r\n                                                        \r\n",
);

browserLogger.log(
    "%cAPI ENDPOINT " + "/api",
    "font-size: 1.2em; padding: 2px 4px; background-color: #f0f0f0; color: #ff0000",
);

browserLogger.log({
    root: document.getElementById("root"),
});

const buildId = import.meta.env.VITE_BUILD_ID;
if (typeof buildId !== "string") throw new Error("Missing env var, BUILD_ID");
const buildTimestamp = import.meta.env.VITE_BUILD_TIMESTAMP;
if (typeof buildTimestamp !== "string")
    throw new Error("Missing env var, BUILD_TIMESTAMP");

Sentry.init({
    dsn: "https://edaf537be17b48e39e86d719a762ff73@o507275.ingest.sentry.io/5645406",
    release: buildId,
    environment: window.location.hostname,
    normalizeDepth: 10,
    beforeSend: (event, hint) => {
        const exception = hint.originalException;
        if (exception instanceof Error) {
            if (isApiError(exception)) {
                const reason = svTranslator(exception.reason);
                const explanation = svTranslator(exception.explanation);
                event.message =
                    reason || explanation
                        ? `${explanation ? `${explanation} ` : ""}${
                              reason ? `[Reason: ${reason}]` : ""
                          }`
                        : event.message;
            }
        }
        return event;
    },
    /**
     * We recommend adjusting this value in production, or using tracesSampler for finer control
     * This should be a float/double between 0.0 and 1.0 (inclusive) and represents the percentage chance that any given transaction will be sent to Sentry. (0.0 is a 0% chance, effectively disabling tracing, and 1.0 is a 100% chance, meaning all transactions will be sent.) This rate applies equally to all transactions; in other words, each transaction should have the same random chance of ending up with sampled = true, equal to the tracesSampleRate.
     * @param {ReturnType<typeof express>} app
     */
    tracesSampler: samplingContext => {
        // Examine provided context data (including parent decision, if any) along
        // with anything in the global namespace to compute the sample rate or
        // sampling decision for this transaction

        if (samplingContext.request?.method === "GET") {
            return 0.001;
        }

        return 1;
    },
    ignoreErrors: ["TypeError: Load failed", "Load failed"],
});
Sentry.getCurrentScope().setTags({
    "build-id": buildId,
    "build-timestamp": buildTimestamp,
    "transaction-id": `${generateWordSlug()}_${generateId({ length: 6 })}`,
    "client-timezone": currentTimezoneName,
    session_id: towniSessionId,
});

browserLogger.log({ buildId, buildTimestamp });

const root = createRoot(document.getElementById("root")!);
root.render(<App />);
