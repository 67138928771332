import { FlexRow } from "@@/shared/flex-containers";
import { Picture } from "@@/shared/pictures/picture";
import { TextBox } from "@@/shared/text/text-box";
import { useTranslate } from "@@/translations/use-translate";
import { SerializedStyles, useTheme } from "@emotion/react";
import {
    ColorItem,
    MetaImage,
    Padding,
    Percentage,
    PixelSize,
    RemSize,
    StorageItemImage,
    Translatable,
    remSize,
    setWithinPercentageRange,
} from "@towni/common";
import * as React from "react";
import { Conditional } from "../conditional";

type Props = {
    readonly title: Translatable | undefined;
    readonly image: MetaImage | StorageItemImage | undefined;
    readonly inactive?: boolean;
    readonly borderSize?: PixelSize;
    readonly borderColor?: ColorItem;
    readonly backgroundColor?: ColorItem;
    readonly padding?: Padding;
    readonly disableBlurhash?: boolean;
    readonly onClick?: (event: React.MouseEvent) => void;

    /**
     * default size 3.125rem
     *
     * @type {RemSize}
     */
    readonly size?: RemSize;
    /**
     * defaults to circle
     */
    readonly shape?: "circle" | "square";
    readonly onImageLoad?: (
        event: React.SyntheticEvent<HTMLImageElement>,
    ) => void;
    readonly styling?: SerializedStyles;
    readonly opacity?: Percentage;
    readonly className?: string;
    readonly style?: React.CSSProperties;
    readonly additionalProps?: React.HTMLAttributes<HTMLDivElement>;
};

const defaultSize: RemSize = remSize(3.125);

const Avatar = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { image, inactive } = props;
    const translate = useTranslate();
    const theme = useTheme();
    const title = React.useMemo(() => {
        return translate(props.title)
            ?.split(" ")
            .filter(word => !["&"].includes(word))
            .map(word => word.substring(0, 1))
            .join("")
            .slice(0, 2);
    }, [props.title]);

    const size = props.size ?? defaultSize;
    const sizeInPixels = theme.sizes.base * size;
    const contentSizeInPixels = sizeInPixels - (props.borderSize ?? 0) * 2;

    // console.log(props.styling);
    const shape = props.shape ?? "circle";
    const radius = shape === "circle" ? sizeInPixels / 2 : theme.radius;
    // console.log("AVATAR", props.image);
    return (
        <FlexRow
            shrink={0}
            grow={0}
            ref={ref}
            width={sizeInPixels}
            height={sizeInPixels}
            minWidth={sizeInPixels}
            minHeight={sizeInPixels}
            padding={props.padding}
            // padding={{ all: props.borderSize ?? 0 }}
            radius={radius}
            mainAxis="center"
            crossAxis="center"
            onClick={props.onClick}
            overflow="hidden"
            style={props.style}
            className={props.className}
            css={[
                {
                    border: `${props.borderSize ?? 0}px solid ${
                        theme.colors.white.asString
                    }`,
                    opacity: setWithinPercentageRange(props.opacity),
                },
                props.styling,
            ]}
            background={{
                color: inactive
                    ? theme.colors.white.main.light90
                    : (props.backgroundColor ??
                      props.borderColor ??
                      theme.colors.default.background),
            }}>
            <Conditional when={!!props.image}>
                <Picture
                    disableBlurhash={props.disableBlurhash}
                    imageRef={image}
                    fade={undefined}
                    // firstRun ? (0.5 as Seconds) : undefined}
                    onImageLoad={props.onImageLoad}
                    width={contentSizeInPixels}
                    height={contentSizeInPixels}
                    radius={radius}
                    imageOptions={{
                        imageWidth:
                            theme.sizes.imageSizes.closest(contentSizeInPixels),
                        imageHeight:
                            theme.sizes.imageSizes.closest(contentSizeInPixels),
                        disabled: !!inactive,
                        fit: true,
                    }}
                />
            </Conditional>
            <Conditional when={!props.image}>
                <TextBox
                    text={title}
                    color={theme.colors.white}
                    weight="900"
                    size={size * 0.4}
                    case="UPPERCASE"
                    align="center"
                />
            </Conditional>
        </FlexRow>
    );
});

export { Avatar };
export type { Props as AvatarProps };
