import { useTheme } from "@emotion/react";
import { colorAsString, ColorItem, Padding, SizeName } from "@towni/common";
import { paddingToCssValue } from "../padding";

type VerticalProps = { [key in SizeName]?: boolean } & {
    size?: number;
    color?: ColorItem;
    padding?: Padding;
    fillParentHeight?: boolean;
    className?: string;
};

const VerticalLine = (props: VerticalProps) => {
    const theme = useTheme();
    const { fillParentHeight } = props;
    const backgroundColor = props.color || theme.colors.default.shadow;
    const color =
        (backgroundColor && colorAsString(backgroundColor)) || "unset";

    return (
        <div
            className={props.className}
            css={{
                width: props.size ?? 4,
                minWidth: props.size ?? 1,
                maxWidth: props.size ?? 1,
                flexShrink: 0,
                backgroundColor: color,
                padding: paddingToCssValue(props.padding),
                height: fillParentHeight ? "100%" : undefined,
            }}
        />
    );
};

export { VerticalLine };
