import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { _FormFieldDescription } from "@@/shared/form/_form-field-description";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import {
    Form2NumberInput,
    PostElement,
    PreElement,
} from "@@/shared/form/form2-number-input";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { FieldTitle } from "@@/shared/text/field-title";
import { AppTheme } from "@@/styles/theme";
import { Interpolation } from "@emotion/react";
import { MinMaxRange, Padding, Translatable, translation } from "@towni/common";
import { Draft } from "immer";
import { useState } from "react";
import { ZodSchema } from "zod";

type Value = MinMaxRange | undefined;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
    readonly fieldSchema?: ZodSchema;

    readonly autoComplete?: string;
    /**
     * Optional element to be rendered before the text input field but within it's container
     * @type {JSX.Element}
     */
    readonly preElement?: PreElement;
    /**
     * Optional element to be rendered after the text input field but within it's container
     * @type {JSX.Element}
     */
    readonly postElement?: PostElement;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;

    readonly label?: Translatable;
    readonly charFilters?: RegExp | RegExp[];
    readonly labelDescription?: Translatable;
    readonly disabled?: boolean;
    readonly spin?: boolean;
    readonly containerCss?: Interpolation<AppTheme>;
    readonly inputCss?: Interpolation<AppTheme>;
    readonly padding?: Padding;
};

const Form2RangeTextInput = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        formId: props.formId,
        fieldSchema: props.fieldSchema,
    });
    if (!field)
        throw new Error(`Field ${props.fieldId} in form ${formId} not found`);

    const [_initialValue] = useState(field.value);
    useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "automatic",
    });

    return (
        <FlexColumn
            className={props.className}
            key={`${formId}_${props.fieldId}`}
            tag={`form-field_${props.fieldId}`}
            crossAxis="stretch">
            <Conditional when={!!props.label}>
                <FlexRow shrink={0} crossAxis="center" mainAxis="space-between">
                    <FieldTitle
                        htmlFor={props.fieldId}
                        padding={{ left: 2 }}
                        text={props.label ?? ""} // already checked with conditional above
                        required={field.isRequired}
                    />
                    <Conditional when={!!props.labelDescription}>
                        <HorizontalDivider XXS />
                        <FieldTitle
                            padding={{ left: 2 }}
                            text={props.labelDescription ?? ""} // already checked with conditional above
                            // color={props.labelColor}
                            weight="400"
                            size="S"
                            css={{
                                opacity: 0.5,
                            }}
                        />
                    </Conditional>
                </FlexRow>
                <VerticalDivider XS />
            </Conditional>
            <FlexRow fillParentWidth>
                <Form2NumberInput<State>
                    fieldId={`${props.fieldId}_min`}
                    label={translation({
                        sv: "min",
                        en: "min",
                    })}
                    css={{ flex: 1, flexBasis: 1 }}
                    placeholder={translation({
                        sv: "min",
                        en: "min",
                    })}
                    getter={state => props.getter(state)?.min}
                    setter={(draft, min) => {
                        const current = props.getter(draft as Partial<State>);
                        props.setter(draft, {
                            min,
                            max: current?.max,
                        });
                    }}
                    inputCss={props.inputCss}
                    containerCss={props.containerCss}
                    padding={props.padding}
                    autoComplete={props.autoComplete}
                    preElement={props.preElement}
                    postElement={props.postElement}
                    disabled={props.disabled || props.spin}
                    formId={formId}
                />
                <HorizontalDivider />
                <Form2NumberInput<State>
                    fieldId={`${props.fieldId}_max`}
                    label={translation({
                        sv: "max",
                        en: "max",
                    })}
                    css={{ flex: 1, flexBasis: 1 }}
                    placeholder={translation({
                        sv: "max",
                        en: "max",
                    })}
                    getter={state => props.getter(state)?.max}
                    setter={(draft, max) => {
                        const current = props.getter(draft as Partial<State>);
                        props.setter(draft, {
                            max,
                            min: current?.min,
                        });
                    }}
                    inputCss={props.inputCss}
                    containerCss={props.containerCss}
                    padding={props.padding}
                    autoComplete={props.autoComplete}
                    preElement={props.preElement}
                    postElement={props.postElement}
                    disabled={props.disabled || props.spin}
                    formId={formId}
                />
            </FlexRow>
            <VerticalDivider />
            <_FormFieldDescription
                description={props.description}
                hideDescriptionAfterInput={!!props.hideDescriptionAfterInput}
                hasErrors={!!field.errors.length}
                isDirty={field.dirty}
            />
        </FlexColumn>
    );
};

export { Form2RangeTextInput };
