import { IsoTimestamp } from "@towni/common";
import { SelectableDate } from "./selectable-date";
import { useDateSelectabilityMapContext } from "./date-selectability-map.context";
import { useEffect } from "react";

/**
 * If the information about the selectable dates are updated from the parents side
 * this component will update the selectable dates
 * within the context of the date selection vt component
 */
const SelectableDatesUpdater = (props: {
    selectableDates: Map<IsoTimestamp, SelectableDate>;
}) => {
    const {
        selectabilityMap: selectableDates,
        setSelectabilityMap: setSelectableDates,
    } = useDateSelectabilityMapContext();
    useEffect(() => {
        if (selectableDates === props.selectableDates) return;
        setSelectableDates(props.selectableDates);
    }, [props.selectableDates, selectableDates, setSelectableDates]);
    return null;
};

export { SelectableDatesUpdater };
