import { createContext, useContext, useMemo, useState } from "react";

/** A context to store a timeZone string for the current children */
const TimeZoneContext = createContext<
    | {
          timeZone: string;
          setTimeZone: (timeZone: string) => void;
      }
    | undefined
>(undefined);
const TimeZoneContextProvider = (props: {
    /** Defaults to "Europe/Stockholm" */
    readonly timeZone: string | undefined;
    readonly children: React.ReactNode;
}) => {
    const [timeZone, setTimeZone] = useState(
        () => props.timeZone ?? "Europe/Stockholm",
    );
    const state = useMemo(
        () => ({ timeZone, setTimeZone }),
        [timeZone, setTimeZone],
    );

    return (
        <TimeZoneContext.Provider value={state}>
            {props.children}
        </TimeZoneContext.Provider>
    );
};
const useTimeZoneContext = () => {
    const context = useContext(TimeZoneContext);
    if (!context) throw new Error("No TimeZoneContextProvider found");
    return context;
};

export { TimeZoneContextProvider, useTimeZoneContext };
