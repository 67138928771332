import { useCartContext } from "@@/carts/multi-carts/cart.context";
import { useModal } from "@@/modals/use-modal";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Icon } from "@@/shared/icons/icon";
import { Paragraph, TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import {
    OrderItem,
    OrderItemOptional_V2,
    RemSize,
    translation,
} from "@towni/common";
import { ExtrasProps } from "../product-details-page";
import { ExtrasEditOrderItemModal } from "./extras-edit-order-item-modal";

type Props = {
    orderItem: OrderItem;
    picked: OrderItemOptional_V2[];
    extras?: ExtrasProps;
    disableActions?: boolean;
    onRemove?: () => void;
    backgroundColor?: boolean;
};

const BookableExtraOrderItemRow = (props: Props) => {
    const theme = useTheme();
    const [{ show }, modalId] = useModal();
    const removeItemFromCart = useCartContext(
        context => context.removeItemFromCart,
    );
    const orderItem = props.orderItem;
    const quantityCharLength = `${orderItem.quantity.value}`.length;

    return (
        <>
            <FlexRow
                fillParentWidth
                mainAxis="space-between"
                onClick={
                    !props.disableActions
                        ? event => {
                              show();
                              event.stopPropagation();
                          }
                        : undefined
                }
                css={{
                    borderRadius: 10,
                    border: `1px solid ${theme.colors.default.border.asString}`,
                    padding: 20,
                    cursor: !props.disableActions ? "pointer" : "unset",
                    backgroundColor: props.backgroundColor
                        ? theme.isLightTheme
                            ? theme.colors.primary.light.asString
                            : theme.colors.primary.dark.asString
                        : undefined,
                }}>
                <FlexRow crossAxis="center">
                    <FlexRow
                        crossAxis="center"
                        mainAxis="center"
                        css={{
                            width:
                                quantityCharLength +
                                35 +
                                quantityCharLength * 2.5,
                            height:
                                quantityCharLength +
                                35 +
                                quantityCharLength * 2.5,
                            borderRadius: 3000,
                            backgroundColor: theme.isLightTheme
                                ? theme.colors.primary.asString
                                : theme.colors.primary.asString,
                            padding: 8,
                        }}>
                        <TextBox
                            text={`${orderItem.quantity.value}`}
                            size={0.9}
                            align="center"
                            color={theme.colors.white.asString}
                        />
                    </FlexRow>
                    <HorizontalDivider S />
                    <FlexColumn>
                        <TextBox text={orderItem.meta.title} />
                        <ForEach itemOf={props.picked} getKey={p => p._id}>
                            {(picked, _index) => (
                                <>
                                    <VerticalDivider XXS />
                                    <FlexRow>
                                        <TextBox
                                            text="+"
                                            color={theme.colors.black.light50}
                                            size={0.9}
                                        />
                                        <HorizontalDivider />
                                        <TextBox
                                            text={picked.optional.meta.title}
                                            color={theme.colors.black.light50}
                                            size={0.9}
                                        />
                                    </FlexRow>
                                </>
                            )}
                        </ForEach>
                        {(orderItem.messageToProvider && (
                            <FlexRow wrap={"wrap"}>
                                <Paragraph
                                    css={{
                                        color: theme.colors.black.light50
                                            .asString,
                                        fontSize: "0.8rem",
                                    }}
                                    content={[
                                        translation({
                                            sv: "Instruktioner: ",
                                            en: "Instructions: ",
                                        }),
                                        orderItem.messageToProvider,
                                    ]}
                                />
                            </FlexRow>
                        )) ||
                            ""}
                    </FlexColumn>
                </FlexRow>
                <Conditional when={!props.disableActions}>
                    <FlexRow>
                        <Icon
                            icon={faTimes}
                            size={1.25 as RemSize}
                            cursor="pointer"
                            padding={{ left: 10, topBottom: 10 }}
                            onClick={event => {
                                event.stopPropagation();
                                removeItemFromCart(orderItem._id);
                                props.onRemove?.();
                            }}
                        />
                    </FlexRow>
                </Conditional>
            </FlexRow>
            <ExtrasEditOrderItemModal
                modalId={modalId}
                orderItemId={props.orderItem._id}
                extras={props.extras}
            />
        </>
    );
};

export { BookableExtraOrderItemRow };
