import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useDateTimeFormatter } from "@@/shared/use-date-time-formatter";
import { useTheme } from "@emotion/react";
import {
    Resource,
    ResourceReservationRequest,
    type ResourceReservation,
} from "@towni/common";

type Props = {
    readonly resourceReservation:
        | ResourceReservation
        | ResourceReservationRequest;
    resource?: Resource;
    readonly displayTime: boolean;
    readonly index?: number;
};

const Cart_Accommodation_ResourceReservationRequestRow = (props: Props) => {
    const theme = useTheme();
    const resource = props.resource;
    const dateTimeFormatter = useDateTimeFormatter();

    if (!resource) return null;

    return (
        <FlexRow>
            <TextBox
                text={"-"}
                color={theme.colors.black.light30}
                weight="800"
                size={1.1}
            />
            <HorizontalDivider />
            <TextBox size={1.1} weight="800" text={resource.meta.title} />
            <HorizontalDivider />
            <Conditional when={props.displayTime}>
                <TextBox
                    weight="800"
                    color={theme.colors.white.main.light50}
                    text={dateTimeFormatter.formatDayDateAndTimeMedium(
                        props.resourceReservation.timeRange,
                    )}
                />
            </Conditional>
        </FlexRow>
    );
};

export { Cart_Accommodation_ResourceReservationRequestRow };
