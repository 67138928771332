import {
    useStorageItemImageReference,
    useStorageItemImageReferenceQuery,
} from "@@/storage-items/queries/use-storage-item-reference";
import { AppTheme } from "@@/styles/theme";
import { useTranslate } from "@@/translations/use-translate";
import { Interpolation, SerializedStyles, useTheme } from "@emotion/react";
import {
    ImageLoadingError,
    ImageRef,
    ImageUrlCreatorOptions,
    RemSize,
    StorageItemImageId,
    Translatable,
    isStorageItemImageId,
    parseInitials,
    translation,
} from "@towni/common";
import * as React from "react";
import { useEffect, useState } from "react";
import { Except } from "type-fest";
import { Conditional } from "../conditional";
import { useImageUrl } from "../image-url";
import { RoundedSquare } from "../rounded-square";
import { TextBox } from "../text/text-box";
import { useIsMountedRef } from "../use-is-mounted-ref";
import { useSizeTracker } from "../use-size-tracker";
import { WidthAndHeight } from "../width-and-height";
import { useBlurhash } from "./use-blurhash";

type Props = {
    readonly imageSource: ImageRef | undefined;
    readonly title: Translatable;
    readonly blurhash?: string;
    readonly style?: React.CSSProperties;
    readonly className?: string;
    // readonly fadeIn?: boolean;
    readonly fit?: React.CSSProperties["objectFit"];
    readonly onImageLoad?: (
        event: React.SyntheticEvent<HTMLImageElement>,
    ) => void;
    // Radius
    readonly radius?: number;
    readonly topRadius?: number;
    readonly bottomRadius?: number;
    readonly leftRadius?: number;
    readonly rightRadius?: number;
    readonly size?: RemSize;
    readonly imageOptions?: ImageUrlCreatorOptions;
    readonly ignoreBlurhash?: boolean;
};

const ImageFromId = (
    props: Except<Props, "imageSource" | "title"> & {
        imageSource: StorageItemImageId;
    },
) => {
    const { imageSource, ...passOnProps } = props;
    const [storageItemImage] = useStorageItemImageReference(imageSource);
    return (
        <ImageV2
            {...passOnProps}
            imageSource={storageItemImage}
            title={
                storageItemImage?.title ??
                storageItemImage?.filename ??
                translation({
                    sv: "Bild",
                    en: "Image",
                })
            }
        />
    );
};

const ImageV2 = (props: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    const getImageUrl = useImageUrl();
    const blurhashDataUrl = useBlurhash(
        typeof props.imageSource === "string"
            ? undefined
            : props.imageSource?.blurhash,
    );

    const imageSourceNeedsLoading = isStorageItemImageId(props.imageSource);
    const storageItemImageQuery = useStorageItemImageReferenceQuery(
        imageSourceNeedsLoading ? props.imageSource : undefined,
    );
    const storageItemImage = storageItemImageQuery.data;
    const imageSource = imageSourceNeedsLoading
        ? storageItemImage
        : props.imageSource;
    const [_status, setStatus] = useState<"pending" | "no_image" | "loaded">(
        "pending",
    );
    // Track if component is mounted
    const isMounted = useIsMountedRef();

    const [requestWidth, setRequestWidth] = useState<number>(() => {
        const initialWidth =
            typeof props.style?.width === "number"
                ? theme.sizes.imageSizes.closest(props.style.width)
                : typeof props.style?.minWidth === "number"
                  ? theme.sizes.imageSizes.closest(props.style.minWidth)
                  : theme.sizes.imageSizes.thumb;
        return initialWidth;
    });
    const onViewSizeChange = ({ width }: WidthAndHeight) => {
        const closestWidth = theme.sizes.imageSizes.closest(width);
        setRequestWidth(closestWidth);
    };

    const [sizeTrackedImageRef] =
        useSizeTracker<HTMLImageElement>(onViewSizeChange);

    const onImageLoad = (_element: HTMLImageElement) => {
        // console.log("IMAGEv2", "loaded");
        setStatus("loaded");
    };

    // We'll ask imgix for auto negotiated format
    // which will return avif/heif or webp for most
    // browsers/customers, and will fallback to png
    // to allow for transparency in images that doesn't
    // support avif/heif/webp and fails to negotiate
    const format = "png";

    const url = getImageUrl(imageSource, {
        imageWidth: requestWidth,
        format,
        ...(props.imageOptions ?? {}),
    });

    // Load image
    useEffect(() => {
        // console.log("IMAGEv2 UseEffect", requestWidth, imageSource);
        if (!requestWidth) return;
        if (!imageSource) {
            setStatus("no_image");
            return;
        }

        if (!url?.trim()) {
            setStatus("no_image");
            return;
        }

        new Promise<HTMLImageElement>((resolve, reject) => {
            const img = new Image();
            img.src = url;
            // console.log("IMAGEv2 loading");
            img.onload = () => {
                // console.log("IMAGEv2 onload");
                if (!isMounted.current) return;
                return resolve(img);
            };
            img.onerror = () => {
                if (!isMounted.current) return;
                return reject(
                    new ImageLoadingError("Failed to load image", format, url),
                );
            };
        })
            .then(onImageLoad)
            .catch(error => {
                if (error instanceof ImageLoadingError) {
                    if (!isMounted.current) return;
                    setStatus("no_image");
                }
                throw error;
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [format, imageSource, requestWidth, url]);

    const size = props.size ? props.size * theme.sizes.base : undefined;
    const fit = props.fit ?? props.style?.objectFit ?? "cover";
    const style: Partial<Interpolation<AppTheme>> = {
        width: size ?? props.style?.width ?? "100%",
        height: size ?? props.style?.height ?? "100%",
        display: "block",
        // opacity: status === "loaded" ? 1 : 0,
        backgroundImage:
            !props.ignoreBlurhash && blurhashDataUrl
                ? `url(${blurhashDataUrl})`
                : undefined,
        ...(props.style ?? {}),
        overflow: "hidden",
        backgroundSize: fit?.toLowerCase() ?? undefined,
        backgroundPosition:
            fit?.toLowerCase().trim() === "cover" ? "50% 50%" : "unset",
        objectFit: fit,
        borderTopLeftRadius: `${
            props.topRadius ??
            props.leftRadius ??
            props.style?.borderTopLeftRadius
        }px`,
        borderTopRightRadius: `${
            props.topRadius ??
            props.rightRadius ??
            props.style?.borderTopRightRadius
        }px`,
        borderBottomLeftRadius: `${
            props.bottomRadius ??
            props.leftRadius ??
            props.style?.borderBottomLeftRadius
        }px`,
        borderBottomRightRadius: `${
            props.bottomRadius ??
            props.rightRadius ??
            props.style?.borderBottomRightRadius
        }px`,
        borderRadius: `${props.radius ?? props.style?.borderRadius}px`,
    };

    return (
        <img
            key={url}
            ref={sizeTrackedImageRef}
            src={url}
            title={translate(props.title)}
            css={style}
            className={props.className}
        />
    );
    // }
};

const SquareImage = (props: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    const {
        width: requestedWidth,
        height: _requestedHeight,
        ...pass
    } = props.style ?? {};

    const size = props.size ? props.size * theme.sizes.base : undefined;
    const width = size ?? requestedWidth ?? "100%";
    const isPercentage = width.toString().includes("%");
    const height = size ?? (isPercentage ? undefined : width);
    const hasImageSource = !!props.imageSource;
    const titleInitials = React.useMemo(
        () => parseInitials(translate(props.title)),
        [props.title, translate],
    );

    return (
        <div
            css={{
                position: "relative",
                overflow: "hidden",
                width,
                height,
                flexShrink: 0,
                paddingBottom: isPercentage ? "100%" : undefined,
                borderTopLeftRadius:
                    props.topRadius ??
                    props.leftRadius ??
                    props.style?.borderTopLeftRadius,
                borderTopRightRadius:
                    props.topRadius ??
                    props.rightRadius ??
                    props.style?.borderTopRightRadius,
                borderBottomLeftRadius:
                    props.bottomRadius ??
                    props.leftRadius ??
                    props.style?.borderBottomLeftRadius,
                borderBottomRightRadius:
                    props.bottomRadius ??
                    props.rightRadius ??
                    props.style?.borderBottomRightRadius,
                borderRadius: props.radius ?? props.style?.borderRadius,
            }}>
            <Conditional
                when={hasImageSource}
                render={() => (
                    <ImageV2
                        {...props}
                        css={{
                            ...(pass as SerializedStyles),
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                        }}
                    />
                )}
            />
            <Conditional
                whenNot={hasImageSource}
                render={() => (
                    <RoundedSquare
                        size={40}
                        css={{
                            minWidth: width,
                            minHeight: height,
                        }}
                        backgroundColor={theme.colors.primary.main.value.withAlpha(
                            0.6,
                        )}>
                        <TextBox
                            text={titleInitials}
                            color={theme.colors.white}
                            weight="900"
                            case="UPPERCASE"
                        />
                    </RoundedSquare>
                )}
            />
        </div>
    );
};

export { ImageFromId, ImageV2, SquareImage };
export type { Props as ImageProps };
