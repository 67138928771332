import {
    DateRangePreset,
    useDefaultPastTimeRangePresets,
} from "@@/backoffice/for-providers/reports/transactions/transaction-date-presets";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModal } from "@@/modals/use-modal";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { dropDownPortalTarget } from "@@/shared/dropdown-portal-target";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { _FormFieldDescription } from "@@/shared/form/_form-field-description";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { Icon } from "@@/shared/icons/icon";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { Spacer } from "@@/shared/spacer";
import { TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { ModalTitle } from "@@/shared/text/modal-title";
import { useDateTimeFormatter } from "@@/shared/use-date-time-formatter";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { faCalendar, faTimes } from "@fortawesome/pro-regular-svg-icons";
import {
    DateRange,
    Translatable,
    asDate,
    dateRangeFactory,
    isWithinDateRange,
    isoAndUnixFactory,
    joinTranslatables,
    startOfDayZoned,
    translation,
} from "@towni/common";
import { isWithinInterval } from "date-fns";
import { Draft } from "immer";
import {
    useCallback,
    useEffect,
    useMemo,
    useState,
    useTransition,
} from "react";
import { DayPicker, Matcher, PropsBase, PropsRange } from "react-day-picker";
import "react-day-picker/dist/style.css";
import Select from "react-select";
import { Except } from "type-fest";
import { ZodSchema } from "zod";
import { ButtonTransparent } from "../buttons_v2/button-gray";
import { ButtonPrimary } from "../buttons_v2/button-primary";
import { FloatingBox, FloatingBoxActions } from "../floating-box";
import { useDayPickerDefaults } from "./shared/use-day-picker-defaults";

type Value = DateRange | undefined;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
    readonly fieldSchema?: ZodSchema;

    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;

    /** Defaults to true */
    readonly compact?: boolean;
    readonly showWeekdayNames?: boolean;
    readonly showPresetTitle?: boolean;
    readonly hideRequiredStar?: boolean;
    readonly disabled?: boolean;
    readonly customPresets?: DateRangePreset[];
    readonly datePickerProps?: Except<
        PropsBase & PropsRange,
        "selected" | "onSelect" | "mode" | "defaultMonth" | "disabled"
    >;
    readonly align?: "left" | "center";
    /** defaults to `Europe/Stockholm` */
    readonly timeZone?: string;
};

const Form2DateRangePicker = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const theme = useTheme();
    const dayPickerDefaults = useDayPickerDefaults();
    const [{ show, hide }, modalId] = useModal("day_picker_range_modal__");
    const isSmallScreen = usePageBreakpoint({ when: "SMALL_SCREEN" });
    const isCompact = props.compact ?? true;
    const translate = useTranslate();
    const [__, startTransition] = useTransition();
    const timeZone = props.timeZone?.trim() || "Europe/Stockholm";

    const defaultPresets = useDefaultPastTimeRangePresets();
    const presets = props.customPresets ?? defaultPresets;
    const presetOptions = useMemo(() => {
        return presets.map(preset => ({
            label: translate(preset.name),
            value: [
                isoAndUnixFactory(preset.from),
                isoAndUnixFactory(preset.to),
            ] as const,
        }));
    }, [presets, translate]);

    const formatter = useDateTimeFormatter();
    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        fieldSchema: props.fieldSchema,
        formId: props.formId,
    });
    if (!field)
        throw new Error(`Field ${props.fieldId} in form ${formId} not found`);

    const presetOptionSelected = useMemo(() => {
        const result = presetOptions.find(option => {
            return (
                field.value?.start.iso === option.value[0].iso &&
                field.value.end.iso ===
                    isoAndUnixFactory(
                        startOfDayZoned(asDate(option.value[1]), timeZone),
                    ).iso
            );
        });
        return result;
    }, [presetOptions, field.value, timeZone]);

    const [month, setMonth] = useState(asDate(field.value?.start));

    const hasErrors = field.errors.length > 0;
    const [pickerIsOpen, setPickerIsOpen] = useState(false);
    const validationTrigger = useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "manual",
    });
    const rangeAsText = (() => {
        if (!field.value) return undefined;
        const _formatter = (() => {
            if (props.showWeekdayNames) {
                return formatter.formatDateLong;
            }
            return formatter.formatDateMedium;
        })();
        const text = `${_formatter(field.value.start, {
            includeYearIfNotCurrent: true,
        })}${
            field.value.start.iso !== field.value.end?.iso
                ? ` - ${_formatter(field.value.end, {
                      includeYearIfNotCurrent: true,
                  })}`
                : ""
        }`;

        if (props.showPresetTitle && presetOptionSelected)
            return joinTranslatables([
                text,
                " (",
                presetOptionSelected.label,
                ")",
            ]);

        return text;
    })();

    // Custom modifiers object
    const selectedTodayAndStartOrEndOfRangeModifier: Matcher = useCallback(
        (day: Date): boolean => {
            const isSelected =
                !!field.value &&
                (day.toDateString() ===
                    asDate(field.value.start).toDateString() ||
                    day.toDateString() ===
                        asDate(field.value.end).toDateString());

            return (
                isSelected && day.toDateString() === new Date().toDateString()
            );
        },
        [field.value],
    );
    const selectedTodayAndWithinRangeModifier: Matcher = useCallback(
        (day: Date): boolean => {
            const isSelected =
                !!field.value &&
                isWithinDateRange(day, field.value) &&
                day.toDateString() !==
                    asDate(field.value.start).toDateString() &&
                day.toDateString() !== asDate(field.value.end).toDateString();

            return (
                isSelected && day.toDateString() === new Date().toDateString()
            );
        },
        [field.value],
    );

    const innerDayPicker = useCallback(
        (actions: FloatingBoxActions) => (
            <FlexColumn fillParentWidth>
                <Conditional when={!isSmallScreen}>
                    <FlexRow
                        fillParentWidth
                        mainAxis="space-between"
                        crossAxis="center"
                        css={{
                            backgroundColor:
                                theme.colors.default.background.light97
                                    .asString,
                        }}>
                        <TextBox
                            text={translation({
                                sv: "Välj ett datumspann",
                                en: "Choose a date range",
                            })}
                            size={1.2}
                            weight="700"
                            css={{
                                flex: 1,
                                padding: 20,
                            }}
                        />

                        <HorizontalLine />
                        <ButtonTransparent onClick={actions.close}>
                            <Icon icon={faTimes} fixedWidth />
                        </ButtonTransparent>
                    </FlexRow>
                </Conditional>
                <FlexRow
                    fillParentWidth
                    mainAxis="center"
                    crossAxis="center"
                    css={{ padding: 10, paddingBottom: 15 }}>
                    <DayPicker
                        {...(props.datePickerProps ?? {})}
                        mode="range"
                        disabled={props.disabled || field.isSubmitting}
                        month={month}
                        onMonthChange={setMonth}
                        selected={
                            field.value
                                ? {
                                      from: asDate(field.value.start),
                                      to:
                                          field.value.end === field.value.start
                                              ? undefined
                                              : asDate(field.value.end),
                                  }
                                : undefined
                        }
                        modifiers={{
                            selectedTodayAndWithinRange:
                                selectedTodayAndWithinRangeModifier,
                            selectedTodayAndStartOrEndOfRange:
                                selectedTodayAndStartOrEndOfRangeModifier,
                        }}
                        onDayBlur={(_date, _modifiers, _focusEvent) => {
                            field.setTouched(true);
                            validationTrigger();
                        }}
                        css={dayPickerDefaults.css}
                        styles={dayPickerDefaults.styles}
                        modifiersStyles={{
                            ...dayPickerDefaults.modifiersStyles,
                            selected: {
                                backgroundColor:
                                    theme.colors.primary.light.asString,
                                borderRadius: 0,
                                borderColor:
                                    theme.colors.primary.border.withAlpha(0.5)
                                        .asString,
                                color: theme.colors.primary.asString,
                                fontWeight: 400,
                            },
                            selectedTodayAndWithinRange: {
                                backgroundColor:
                                    theme.colors.primary.light.asString,
                                borderRadius: 0,
                                borderColor:
                                    theme.colors.primary.border.withAlpha(0.5)
                                        .asString,
                                color: theme.colors.primary.asString,
                                textDecoration: "underline",
                                fontWeight: 400,
                            },
                            selectedTodayAndStartOrEndOfRange: {
                                backgroundColor: theme.colors.primary.asString,
                                borderRadius: 0,
                                borderColor:
                                    theme.colors.primary.border.withAlpha(0.5)
                                        .asString,
                                color: theme.colors.primary.asString,
                                textDecoration: "underline",
                                fontWeight: 400,
                            },
                        }}
                        locale={dayPickerDefaults.locale}
                        onSelect={(range, selectedDate, _activeModifiers) => {
                            const prevValue = field.value;
                            const dateRange = (() => {
                                if (
                                    prevValue &&
                                    prevValue.start.iso ===
                                        isoAndUnixFactory(selectedDate).iso &&
                                    prevValue.start.iso !== prevValue.end.iso
                                ) {
                                    return dateRangeFactory(selectedDate);
                                }
                                if (range?.from && !range.to) {
                                    return dateRangeFactory(
                                        range.from,
                                        selectedDate,
                                    );
                                }
                                if (
                                    prevValue &&
                                    prevValue.start.iso === prevValue.end.iso &&
                                    range?.from &&
                                    range.to
                                ) {
                                    return dateRangeFactory(
                                        range.from,
                                        range.to,
                                    );
                                }
                                if (
                                    prevValue &&
                                    prevValue.start.iso !== prevValue.end.iso &&
                                    !isWithinInterval(selectedDate, {
                                        start: asDate(prevValue.start),
                                        end: asDate(prevValue.end),
                                    })
                                ) {
                                    return dateRangeFactory(selectedDate);
                                }
                                if (props.datePickerProps?.min) {
                                    if (!range?.to) {
                                        return undefined;
                                    }
                                }
                                if (range?.from && range.to) {
                                    return dateRangeFactory(
                                        range.from,
                                        range.to,
                                    );
                                }
                                if (range?.from && !range.to) {
                                    return dateRangeFactory(
                                        range.from,
                                        range.from,
                                    );
                                }
                                return undefined;
                            })();
                            field.setValue(dateRange);
                            field.setDirty(true);
                            field.setTouched(true);
                        }}
                    />
                </FlexRow>
                <FlexRow
                    css={{
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingBottom: 20,
                    }}>
                    <Select
                        key={presetOptionSelected?.value?.join(">")}
                        options={presetOptions}
                        isDisabled={!presetOptions.length}
                        styles={{
                            container: current => ({
                                ...current,
                                flex: 1,
                                opacity: presetOptions.length ? 1 : 0.5,
                                // width: "100%",
                            }),
                            control: (current, state) => ({
                                ...current,
                                borderRadius: 6,
                                paddingTop: 1,
                                paddingBottom: 2,
                                // width: 100,
                                flex: 1,
                                border: `1px solid ${
                                    state.isFocused
                                        ? theme.colors.textInput.border.asString
                                        : theme.colors.textInput.border.asString
                                }`,
                                backgroundColor:
                                    theme.colors.textInput.background.asString,
                                boxShadow: "none",
                                "&:hover": {
                                    border: `1px solid ${theme.colors.black.light80.asString}`,
                                },
                            }),
                            placeholder: current => ({
                                ...current,
                                color: theme.colors.textInput.placeholder
                                    .asString,
                            }),
                        }}
                        placeholder={translate(
                            translation({
                                sv: "Förval",
                                en: "Presets",
                            }),
                        )}
                        value={presetOptionSelected}
                        menuPortalTarget={dropDownPortalTarget}
                        menuPlacement="auto"
                        onChange={selection => {
                            startTransition(() => {
                                if (!selection?.value) return;
                                const startDate = startOfDayZoned(
                                    asDate(selection.value[0], timeZone),
                                );
                                const endDate = startOfDayZoned(
                                    asDate(selection.value[1]),
                                    timeZone,
                                );
                                const value = dateRangeFactory(
                                    startDate,
                                    endDate,
                                );
                                field.setValue(value);
                                setMonth(startDate);
                            });
                        }}
                    />
                </FlexRow>
                <HorizontalLine />
                <FlexRow
                    fillParentWidth
                    mainAxis={isCompact ? "stretch" : "space-between"}
                    crossAxis="stretch"
                    css={{
                        flex: 1,
                        padding: 10,
                        paddingBottom: 20,
                        paddingLeft: 20,
                        paddingRight: 20,
                    }}>
                    <Conditional
                        when={!!rangeAsText}
                        else={() => <Spacer css={{ flex: 1 }} />}>
                        <FlexColumn css={{ flex: 1 }}>
                            <TextBox
                                text={translation({
                                    sv: "Valt",
                                    en: "Selected",
                                })}
                                case="UPPERCASE"
                                css={{
                                    fontSize: "0.625rem",
                                    fontWeight: 900,
                                    padding: 5,
                                    color: theme.colors.black.light70.asString,
                                    width: "fit-content",
                                }}
                            />
                            <TextBox
                                text={rangeAsText}
                                size={0.825}
                                title={translation({
                                    sv: "Hoppa till början på valt datum/datumspann i kalendern",
                                    en: "Jump to the beginning of the selected date/date range in the calendar",
                                })}
                                onClick={() => {
                                    setMonth(asDate(field.value?.start));
                                }}
                                css={{
                                    width: "fit-content",
                                    wordBreak: "break-word",
                                    maxWidth: 210,
                                    cursor: "pointer",
                                    borderRadius: 5,
                                    padding: "5px 10px",
                                    color: theme.colors.primary.text.asString,
                                    backgroundColor:
                                        theme.colors.primary.asString,
                                }}
                            />
                        </FlexColumn>
                        <HorizontalDivider M />
                    </Conditional>

                    <Conditional when={isCompact}>
                        <ButtonPrimary
                            padding={{ leftRight: 20, topBottom: 10 }}
                            onClick={actions.close}>
                            <TextBox
                                text={translate(
                                    translation({
                                        sv: "Klar",
                                        en: "Ready",
                                    }),
                                )}
                            />
                        </ButtonPrimary>
                    </Conditional>
                </FlexRow>
            </FlexColumn>
        ),
        [
            month,
            field,
            isCompact,
            isSmallScreen,
            dayPickerDefaults,
            presetOptionSelected,
            presetOptions,
            props.datePickerProps,
            props.disabled,
            rangeAsText,
            selectedTodayAndStartOrEndOfRangeModifier,
            selectedTodayAndWithinRangeModifier,
            setMonth,
            theme.colors,
            timeZone,
            translate,
            validationTrigger,
        ],
    );

    const pickerField = useCallback(
        (actions: FloatingBoxActions) => (
            <FlexRow
                className={props.className}
                mainAxis={props.align === "center" ? "center" : "flex-start"}
                crossAxis="center"
                onClick={actions.toggle}
                css={{
                    cursor: "pointer",
                    backgroundColor: theme.colors.textInput.background.asString,
                    borderRadius: theme.radius,
                    border: `1px solid ${theme.colors.textInput.border.asString}`,
                }}>
                <TextBox
                    text={
                        rangeAsText ??
                        translation({
                            sv: "Välj ett datumspann",
                            en: "Choose a date range",
                        })
                    }
                    align={props.align ?? "left"}
                    css={{
                        flex: 1,
                        padding: 15,
                    }}
                    color={
                        !field.value
                            ? theme.colors.textInput.placeholder.asString
                            : undefined
                    }
                />
                <HorizontalDivider />
                <Icon
                    icon={faCalendar}
                    css={{ paddingRight: 15 }}
                    fixedWidth
                    opacity={props.disabled ? 0.5 : 1}
                />
            </FlexRow>
        ),
        [
            field.value,
            props.align,
            props.className,
            props.disabled,
            rangeAsText,
            theme,
        ],
    );

    useEffect(() => {
        if (!isSmallScreen) return;
        if (pickerIsOpen) show();
        else hide();
    }, [isSmallScreen, pickerIsOpen, show, hide]);

    const picker = isSmallScreen ? (
        <>
            {pickerField({
                open: () => {
                    setPickerIsOpen(true);
                },
                toggle: () => {
                    setPickerIsOpen(current => !current);
                },
                close: () => {
                    setPickerIsOpen(false);
                },
            })}
            <Modal
                modalId={modalId}
                onHide={() => {
                    setMonth(asDate(field.value?.start));
                }}
                header={
                    <ModalHeader
                        modalId={modalId}
                        bottomBorder
                        title={
                            <ModalTitle
                                text={translation({
                                    sv: "Välj ett datumspann",
                                    en: "Choose a date range",
                                })}
                            />
                        }
                    />
                }>
                <FlexColumn
                    fillParentWidth
                    crossAxis="center"
                    mainAxis="center">
                    {innerDayPicker({
                        open: () => {
                            setPickerIsOpen(true);
                        },
                        toggle: () => {
                            setPickerIsOpen(current => !current);
                        },
                        close: () => {
                            setPickerIsOpen(false);
                        },
                    })}
                </FlexColumn>
            </Modal>
        </>
    ) : (
        <FloatingBox
            isOpen={pickerIsOpen}
            content={innerDayPicker}
            trigger={pickerField}
            onClose={() => {
                setMonth(asDate(field.value?.start));
            }}
        />
    );

    return (
        <FlexColumn
            key={`${formId}_${props.fieldId}`}
            tag={`form-field_${props.fieldId}`}
            crossAxis="stretch">
            <Conditional when={!!props.label}>
                <FlexRow shrink={0} crossAxis="center" mainAxis="space-between">
                    <FieldTitle
                        htmlFor={props.fieldId}
                        padding={{ left: 2 }}
                        text={props.label ?? ""} // already checked with conditional above
                        required={!props.hideRequiredStar && field.isRequired}
                    />
                    <Conditional when={!!props.labelDescription}>
                        <HorizontalDivider XXS />
                        <FieldTitle
                            padding={{ left: 2 }}
                            text={props.labelDescription ?? ""} // already checked with conditional above
                            weight="400"
                            size="S"
                            css={{
                                opacity: 0.5,
                            }}
                        />
                    </Conditional>
                </FlexRow>
                <VerticalDivider XS />
            </Conditional>
            {!isCompact ? (
                <div
                    css={{
                        width: "100%",
                        backgroundColor: "white",
                        borderRadius: 10,
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: theme.colors.textInput.border.asString,
                    }}>
                    {innerDayPicker({
                        open: () => {
                            setPickerIsOpen(true);
                        },
                        toggle: () => {
                            setPickerIsOpen(current => !current);
                        },
                        close: () => {
                            setPickerIsOpen(false);
                        },
                    })}
                </div>
            ) : (
                <>{picker}</>
            )}
            <_FormFieldDescription
                hasErrors={hasErrors}
                isDirty={field.dirty}
                description={props.description}
                hideDescriptionAfterInput={!!props.hideDescriptionAfterInput}
                css={{
                    marginTop: 5,
                }}
            />
            <FormErrorMessages errors={field.errors} />
        </FlexColumn>
    );
};

export { Form2DateRangePicker };
