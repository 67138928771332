import { Direction, directionsMap } from "@towni/common";
import * as React from "react";
import { HorizontalDivider, HorizontalLine, VerticalDivider } from "./dividers";

type Props = {
    direction?: Direction;
    divider?: JSX.Element;
    start?: boolean | ((direction?: Direction) => JSX.Element);
    end?: boolean | ((direction?: Direction) => JSX.Element);
    modify?: (
        item: React.ReactNode,
        isFirst: boolean,
        isLast: boolean,
    ) => React.ReactNode;
    children: React.ReactNode;
};

const getDefaultDivider = (direction: Direction) =>
    direction !== directionsMap.HZ ? (
        <React.Fragment>
            <VerticalDivider M />
            <HorizontalLine fillParentWidth />
            <VerticalDivider M />
        </React.Fragment>
    ) : (
        <HorizontalDivider S />
    );

/**
 * @deprecated
 * Use `ForEach` instead
 */
const Join = ({ children, ...props }: Props) => {
    const { start, end, modify } = props;
    const direction = props.direction || directionsMap.VT;
    const divider = props.divider || getDefaultDivider(direction);
    const dividerWithKey = (key: React.Key) => (
        <React.Fragment key={key}>{divider}</React.Fragment>
    );
    if (Array.isArray(children)) {
        const output = children
            .filter(item => item !== null)
            .map((child, index) => {
                return (
                    <React.Fragment key={index}>
                        {modify
                            ? modify(
                                  child,
                                  index === 0,
                                  index === children.length - 1,
                              )
                            : child}
                        {index !== children.length - 1 && (
                            <React.Fragment key={index}>
                                {divider}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                );
            });

        if (typeof start !== "undefined" && start !== false) {
            if (start === true) output.unshift(dividerWithKey("start"));
            else output.unshift(start(direction));
        }
        if (typeof end !== "undefined" && end !== false) {
            if (end === true) output.push(dividerWithKey("end"));
            else output.push(end(direction));
        }

        return <>{output}</>;
    }
    return <>{children}</>;
};

export { Join };
