import { apiFetchClient } from "@@/shared/fetch-client";
import { useToast } from "@@/toasts/context/toast-context";
import { useMutation } from "@tanstack/react-query";
import {
    GetSingleResponse,
    PayRequestStripeCheckoutCommand,
    support,
    translation,
} from "@towni/common";

const postCommand = (command: PayRequestStripeCheckoutCommand) => {
    return apiFetchClient.post<
        PayRequestStripeCheckoutCommand,
        GetSingleResponse<{ successUrl: string }>
    >({
        route: `/api/payments/stripe/checkouts/pay-requests/${command.payRequestId}`,
        body: command,
    });
};

const useCheckoutPayRequestCommand = (options?: {
    onError?: () => void | Promise<void>;
}) => {
    const toast = useToast();
    const mutation = useMutation<
        GetSingleResponse<{ successUrl: string }>,
        unknown,
        PayRequestStripeCheckoutCommand
    >({
        mutationFn: postCommand,
        onSuccess: (data, _command) => {
            const successUrl = data.item.successUrl;
            window.location.href = successUrl;
        },
        onError: async (error, _command) => {
            toast.fromError(error, {
                messageOverrideWhenNotApiError: translation({
                    sv: `Betalning misslyckades. Om felet kvarstår, kontakta ${support.towni.supportEmail}`,
                    en: `Payment failed. If the error persists, contact ${support.towni.supportEmail}`,
                }),
            });
            await options?.onError?.();
            throw error;
        },
    });

    return mutation;
};

export { useCheckoutPayRequestCommand };
