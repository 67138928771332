import { hasStockBalance } from "@@/backoffice/for-providers/products/stock-balance/has-stock-balance";
import { useStockBalanceSku } from "@@/backoffice/for-providers/products/stock-balance/use-stock-balance-sku";
import { useCartContext } from "@@/carts/multi-carts/cart.context";
import { useProductDetailsContext } from "@@/products/product-details-context";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox, TextSpan } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    BackgroundStyle,
    MinMaxRange,
    Padding,
    Translatable,
    translation,
    unique,
} from "@towni/common";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { AddToCartPillButton } from "./add-to-cart-pill-button";
import { CartQuantityPicker } from "./cart-quantity-picker";

type Props = {
    readonly addButtonText?: Translatable;
    readonly padding?: Padding;
    readonly background?: BackgroundStyle;
    readonly afterAddNavigation?: () => void;
    readonly clearBeforeAdding: boolean;
    readonly pickLimit?: {
        readonly alreadyPicked: number;
        readonly minMax: MinMaxRange;
    };
};

const AddToCartWithQuantity = (props: Props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const state = useProductDetailsContext();
    const { cart, addItemsToCart, clearCart } = useCartContext(context => ({
        cart: context.cart,
        addItemsToCart: context.addItemsToCart,
        clearCart: context.clearCart,
    }));

    const stockBalanceEnabled: boolean =
        hasStockBalance(state.selectedSku) &&
        !!state.selectedSku.trackStockBalance;
    const [stockBalance, stockBalanceQuery] = useStockBalanceSku(
        {
            providerId: state.product.providerId,
            skuId: state.selectedSku?._id ?? state.product.skus[0]._id,
            productId: state.product._id,
            cartId: cart._id,
        },
        !stockBalanceEnabled,
    );

    const isOutOfStock =
        stockBalanceEnabled && (!stockBalance || stockBalance.quantity <= 0);
    const quantityUnavailable =
        stockBalanceEnabled &&
        (isOutOfStock ||
            !stockBalance ||
            stockBalance.quantity <
                state.quantityValue *
                    (state.product.skus[0].acquire.amount.quantity || 1));

    const totalPickedAmountOverLimit =
        state.quantityValue + (props.pickLimit?.alreadyPicked ?? 0) >
        (props.pickLimit?.minMax.max ?? Number.MAX_SAFE_INTEGER);
    const maxAmount =
        props.pickLimit?.minMax.max ??
        Number.MAX_SAFE_INTEGER - (props.pickLimit?.alreadyPicked ?? 0);
    const addButtonText = isOutOfStock
        ? translation({
              sv: "Slut i lager",
              en: "Out of stock",
          })
        : quantityUnavailable
          ? translation({
                sv: "Antal ej tillgängligt",
                en: "Quantity unavailable",
            })
          : state.addToCartDisabled
            ? translation({
                  sv: "Välj först tillval",
                  en: "First select options",
              })
            : (props.addButtonText ??
              translation({
                  sv: "Lägg i kundkorg",
                  en: "Add to cart",
              }));

    const output = (
        <motion.div
            initial={{
                opacity: 0,
            }}
            animate={{
                opacity: 1,
            }}
            exit={{
                opacity: 0,
            }}
            transition={theme.spring}
            css={{
                display: "block",
                position: "absolute",
                pointerEvents: "none",
                width: "100%",
                height: "100%",
                zIndex: 9999,
                top: 0,
                left: 0,
            }}>
            <FlexColumn fillParent mainAxis="flex-end" crossAxis="stretch">
                <FlexColumn
                    crossAxis="stretch"
                    background={{
                        linearGradient: {
                            _type: "LINEAR_GRADIENT",
                            steps: [
                                {
                                    color: theme.colors.default.background,
                                    percentage: 0.5,
                                },
                                {
                                    color: theme.colors.white.main.value.withAlpha(
                                        0.001,
                                    ), // to prevent weird errors with alpha 0
                                    percentage: 1,
                                },
                            ],
                        },
                    }}>
                    <VerticalDivider XL />
                    <Conditional
                        when={isOutOfStock || quantityUnavailable}
                        render={() => (
                            <FlexColumn
                                fillParentWidth
                                padding={{ all: 20, bottom: 0 }}>
                                <FlexColumn
                                    css={{
                                        borderRadius: 10,
                                        backgroundColor:
                                            theme.colors.black.light95.asString,
                                        padding: 15,
                                    }}>
                                    <TextBox
                                        color={theme.colors.danger}
                                        size={0.9}>
                                        <TextSpan
                                            text={
                                                "Den här produkten finns bara i ett begränsat antal. "
                                            }
                                        />
                                        <Conditional
                                            when={!isOutOfStock}
                                            render={() => (
                                                <TextSpan
                                                    text={
                                                        "Du kan försöka att beställa färre."
                                                    }
                                                    css={{ flex: 1 }}
                                                />
                                            )}
                                            else={() => (
                                                <TextSpan
                                                    text={
                                                        "Och för tillfället är den tyvärr slut."
                                                    }
                                                    css={{ flex: 1 }}
                                                />
                                            )}
                                        />
                                    </TextBox>
                                </FlexColumn>
                            </FlexColumn>
                        )}
                    />
                    <Conditional
                        when={totalPickedAmountOverLimit}
                        render={() => (
                            <FlexColumn
                                fillParentWidth
                                padding={{ all: 20, bottom: 0 }}>
                                <FlexColumn
                                    css={{
                                        borderRadius: 10,
                                        backgroundColor:
                                            theme.colors.black.light95.asString,
                                        padding: 15,
                                    }}>
                                    <TextBox
                                        color={theme.colors.danger}
                                        size={0.9}
                                        text={translation({
                                            sv:
                                                "Du kan inte lägga till fler än " +
                                                maxAmount +
                                                " st.",
                                            en:
                                                "You can't add more than " +
                                                maxAmount +
                                                " items.",
                                        })}
                                    />
                                </FlexColumn>
                            </FlexColumn>
                        )}
                    />
                    <FlexRow
                        css={{ pointerEvents: "all" }}
                        mainAxis="space-between"
                        padding={{
                            ...props.padding,
                            top: props.padding?.top || 15,
                        }}>
                        <FlexRow grow={0}>
                            <CartQuantityPicker
                                quantity={state.quantityValue}
                                decrease={state.decreaseQuantity}
                                increase={state.increaseQuantity}
                                disabled={false}
                            />
                        </FlexRow>
                        <HorizontalDivider />
                        <FlexRow css={{ flex: 1 }}>
                            <AddToCartPillButton
                                disabled={
                                    state.addToCartDisabled ||
                                    isOutOfStock ||
                                    quantityUnavailable ||
                                    totalPickedAmountOverLimit
                                }
                                spin={
                                    stockBalanceEnabled &&
                                    stockBalanceQuery.isPending
                                }
                                css={{
                                    width: "100%",
                                }}
                                onClick={() => {
                                    // If there's no item to add, ignore click
                                    const cartItems = state.getOrderItems();
                                    if (!cartItems) return;
                                    if (props.clearBeforeAdding) clearCart();

                                    // What provider do the items in cart belong to?
                                    const cartProviderId = unique(
                                        cart?.orderItems?.map(
                                            orderItem => orderItem.providerId,
                                        ) ?? [],
                                    )[0];

                                    // If it's a different provider than current item belongs to
                                    // warn customer and ask if they want to continue/switch provider
                                    if (
                                        cartProviderId &&
                                        cartProviderId !==
                                            cartItems[0].providerId
                                    ) {
                                        alert(
                                            "Du har redan varor i din varukorg från en annan butik/restaurang. Handla gärna från flera men från en i taget. 🙂",
                                        );
                                        return;
                                    }

                                    // Add item to cart (updates if item already exists)
                                    addItemsToCart(cartItems);

                                    // Make sure cart is updated within
                                    // cart context before navigating away
                                    setTimeout(() => {
                                        // Do requested action after adding item to cart
                                        // if there is one
                                        if (props.afterAddNavigation) {
                                            props.afterAddNavigation();
                                            return;
                                        }

                                        // else go back
                                        navigate(-1);
                                    }, 0);
                                }}>
                                <FlexRow fillParentWidth mainAxis="center">
                                    <TextBox
                                        text={addButtonText}
                                        weight="700"
                                    />
                                </FlexRow>
                            </AddToCartPillButton>
                        </FlexRow>
                    </FlexRow>
                </FlexColumn>
            </FlexColumn>
        </motion.div>
    );

    return output;
};

export { AddToCartWithQuantity };
