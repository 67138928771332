import { useTheme } from "@emotion/react";
import { ColorSet } from "@towni/common";
import { useMemo } from "react";

const useFormColors = (params: {
    hasErrors?: boolean;
    colorSet?: ColorSet;
    dangerColorSet?: ColorSet;
}) => {
    const theme = useTheme();
    const { hasErrors } = params;
    const colorSet = params.colorSet ?? theme.colors.textInput;
    const dangerColorSet = params.dangerColorSet ?? theme.colors.danger;
    // Colors
    const borderColor = hasErrors
        ? dangerColorSet.border.asString
        : colorSet.border.asString;
    const backgroundColor = hasErrors
        ? dangerColorSet.light.asString
        : colorSet.background.asString;
    const textColor = hasErrors
        ? dangerColorSet.asString
        : colorSet.text.asString;
    const spinnerColor = hasErrors
        ? dangerColorSet.asString
        : colorSet.placeholder.asString;

    return useMemo(
        () => ({ borderColor, backgroundColor, textColor, spinnerColor }),
        [backgroundColor, borderColor, spinnerColor, textColor],
    );
};
export { useFormColors };
