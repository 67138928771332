import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModalActions } from "@@/modals/use-modal-actions";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Join } from "@@/shared/join";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import { OptionalAmountPicker } from "@@/shared/options/optional-amount-picker";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { PriceTextBox, TextBox } from "@@/shared/text";
import { css, useTheme } from "@emotion/react";
import {
    MinMaxRange,
    Padding,
    Product,
    Provider,
    Quantity,
    QuantityPickerBookableOption,
    RReferences,
    ResourcePrice,
    ResourcePriceByTime,
    Unit,
    assertNever,
    defaultAmount,
    getResourceReferences,
    isQuantityPickerBookableOption,
    isQuantityPickerBookableOptionValue,
    isResourcePriceByTime,
    isResourcePriceFixed,
    isResourceReference,
    isResourceReferenceGroup,
    quantityPickerBookableOptionValueFactory,
    setWithinRange,
    sum,
    svTranslator,
    translation,
    zeroPrice,
} from "@towni/common";
import * as React from "react";
import { useBookingContext } from "./booking-context";

type Props = {
    readonly product: Product;
    readonly provider: Provider | undefined;
    readonly modalId: ModalId;
    readonly resourceRef: RReferences;
    readonly unit: Unit;
};
/**
 *Quick fix to get a better meta if the meta of a referencegroup is empty
 *
 * @param {RReferences} resourceRef
 * @return {*}
 */
const getMeta = (resourceRef: RReferences) => {
    if (
        isResourceReferenceGroup(resourceRef) &&
        svTranslator(resourceRef.meta.title) === ""
    ) {
        const ref = resourceRef.references.find(
            r => svTranslator(r.meta.title) !== "",
        );
        return ref?.meta ?? resourceRef.meta;
    }
    return resourceRef.meta;
};

const BookableResourceModal: React.FC<Props> = props => {
    const { resourceRef } = props;
    const isMobile = usePageBreakpoint({ when: "📱" });
    const modalAction = useModalActions(props.modalId);
    const theme = useTheme();
    const state = useBookingContext(state => ({
        hasBeenShown: state.customerHasSeenResourceModal,
        setCustomerSeenModal: state.setCustomerHasSeenResourceModal,
        optionValues: state.optionValues,
        setOptionValue: state.setOptionValue,
    }));

    const resources = isResourceReference(props.resourceRef)
        ? [props.resourceRef.resource]
        : props.resourceRef.references.flatMap(r => r.resource);

    React.useEffect(() => {
        if (!state.hasBeenShown) modalAction.show();
    }, [modalAction, state.hasBeenShown]);

    const meta = getMeta(resourceRef);
    const refs = getResourceReferences(resourceRef);
    return (
        <Modal
            modalId={props.modalId}
            //Ignore animation with mobile for first time as pagetransition makes it weird
            ignoreAnimation={!(state.hasBeenShown && isMobile)}
            header={
                <ModalHeader
                    modalId={props.modalId}
                    bottomBorder
                    title={meta.title}
                />
            }
            onHide={() => {
                state.setCustomerSeenModal(true);
            }}>
            <VerticalDivider M />
            <FlexColumn
                padding={{ leftRight: 20 }}
                tag="bookableresourcemodalcontent">
                <Conditional
                    when={!!meta.description}
                    render={() => (
                        <>
                            <TextBox
                                text={meta.description}
                                color={theme.colors.white.main.light50}
                                userSelect="text"
                                renderMarkdown
                                styling={css`
                                    h1,
                                    h2,
                                    h3,
                                    h4 {
                                        color: ${theme.colors.white.main.light20
                                            .asString};
                                    }
                                `}
                            />
                            <VerticalDivider M />
                        </>
                    )}
                    else={() => (
                        <Conditional
                            when={!!meta.subtitle}
                            render={() => (
                                <>
                                    <TextBox
                                        text={meta.subtitle}
                                        color={theme.colors.white.main.light50}
                                        userSelect="text"
                                    />
                                    <VerticalDivider M />
                                </>
                            )}
                        />
                    )}
                />
            </FlexColumn>

            <Join>
                {refs
                    .flatMap(r =>
                        r.options.filter(option =>
                            isQuantityPickerBookableOption(option),
                        ),
                    )
                    .map(option => {
                        const ref = refs.find(
                            r => r._id === option.resourceReferenceId,
                        );

                        const globalMinMax = ref?.minMax;

                        const optionValue = state.optionValues.find(
                            optionValue => optionValue.parentId === option._id,
                        );
                        if (!optionValue) return null;
                        if (
                            !!optionValue &&
                            !isQuantityPickerBookableOptionValue(optionValue)
                        )
                            return null;
                        const otherValues = state.optionValues.filter(
                            optionValue =>
                                optionValue.parentId !== option._id &&
                                optionValue.resourceReferenceId === ref?._id,
                        );
                        const summed = sum(
                            otherValues.map(v =>
                                isQuantityPickerBookableOptionValue(v)
                                    ? v.quantity.value
                                    : 0,
                            ),
                        );

                        let calculatedMinMax = option.minMax;
                        if (globalMinMax) {
                            const min = (globalMinMax.min ?? 0) - summed;
                            const max = (globalMinMax.max ?? 999999) - summed;

                            calculatedMinMax = {
                                min: setWithinRange(min, option.minMax),
                                max: setWithinRange(max, option.minMax),
                            } as MinMaxRange;
                        }

                        return (
                            <ResourceQuantityPicker
                                initialCount={optionValue?.quantity.value ?? 0}
                                padding={{
                                    leftRight: 20,
                                }}
                                key={resourceRef._id}
                                bookableOption={option}
                                minMax={calculatedMinMax}
                                onChange={value => {
                                    state.setOptionValue(
                                        quantityPickerBookableOptionValueFactory(
                                            {
                                                meta: option.meta,
                                                quantity: value,
                                                parentId: option._id,
                                                resourceId: option.resourceId,
                                                resourceReferenceId:
                                                    option.resourceReferenceId,
                                                resourcePrice:
                                                    option.resourcePrice,
                                            },
                                        ),
                                    );
                                }}
                                unit={
                                    resources.find(
                                        r => r._id === option.resourceId,
                                    )?.unit ?? props.unit
                                }
                            />
                        );
                    })}
            </Join>
            <VerticalDivider M />
            <HorizontalLine />
            <VerticalDivider M />
            <FlexRow mainAxis="flex-end" padding={{ leftRight: 20 }}>
                <ButtonPrimary
                    onClick={() => {
                        modalAction.hide();
                    }}>
                    <TextBox
                        text={translation({
                            sv: "Fortsätt",
                            en: "Continue",
                        })}
                        size={"M"}
                        padding={{ leftRight: 20 }}
                    />
                </ButtonPrimary>
            </FlexRow>
            <VerticalDivider M />
        </Modal>
    );
};

type ResourceQuantityPickerProps = {
    initialCount: number;
    bookableOption: QuantityPickerBookableOption;
    padding?: Padding;
    onChange: (quantity: Quantity) => void;
    hidePrices?: boolean;
    unit: Unit;
    minMax: MinMaxRange;
};
const ResourceQuantityPicker = (props: ResourceQuantityPickerProps) => {
    const theme = useTheme();
    const state = useBookingContext(state => ({
        isOnPremiseBooking: state.isOnPremiseBooking,
    }));
    const [count, setCount] = React.useState(props.initialCount);

    const minMax = !state.isOnPremiseBooking
        ? (props.minMax ?? props.bookableOption.minMax)
        : { min: props.bookableOption.minMax.min, max: undefined };

    const maxIsOver =
        props.bookableOption.minMax.max !== undefined &&
        count > props.bookableOption.minMax.max;

    const increase = () => {
        setCount(oldCount => setWithinRange(++oldCount, minMax));
    };
    const decrease = () => {
        setCount(oldCount => setWithinRange(--oldCount, minMax));
    };

    React.useEffect(() => {
        props.onChange({
            amount: defaultAmount,
            value: count,
        });
    }, [count]);

    const getResourcePriceText = (resourcePrice: ResourcePriceByTime) => {
        switch (resourcePrice.granularity) {
            case "MINUTE":
                return translation({
                    sv:
                        resourcePrice.step === 1
                            ? "minut"
                            : `${resourcePrice.step} minuter`,
                });
            case "HOUR":
                return translation({
                    sv:
                        resourcePrice.step === 1
                            ? "timme"
                            : `${resourcePrice.step} timmar`,
                });
            case "DAY":
                return translation({
                    sv:
                        resourcePrice.step === 1
                            ? "dag"
                            : `${resourcePrice.step} dagar`,
                    en:
                        resourcePrice.step === 1
                            ? "day"
                            : `${resourcePrice.step} days`,
                });
            case "NIGHT":
                return translation({
                    sv:
                        resourcePrice.step === 1
                            ? "natt"
                            : `${resourcePrice.step} nätter`,
                    en:
                        resourcePrice.step === 1
                            ? "night"
                            : `${resourcePrice.step} nights`,
                });
            case "SECOND":
                return translation({
                    sv:
                        resourcePrice.step === 1
                            ? "sekund"
                            : `${resourcePrice.step} sekunder`,
                });
            case "MILLISECOND":
                return translation({
                    sv:
                        resourcePrice.step === 1
                            ? "ms"
                            : `${resourcePrice.step} ms`,
                });
            default:
                assertNever(resourcePrice.granularity);
        }
    };

    const text = isResourcePriceFixed(props.bookableOption.resourcePrice)
        ? props.unit.name.sv.singular
        : getResourcePriceText(props.bookableOption.resourcePrice).sv;

    const zeroCost =
        props.bookableOption.resourcePrice.price.amountIncludingVat === 0;

    return (
        <LayoutGrid
            css={{
                gridTemplateColumns: "1fr 150px",
                padding: "0 20px",
            }}>
            <LayoutCell>
                <FlexColumn
                    fillParentHeight
                    crossAxis="flex-start"
                    mainAxis="center">
                    <TextBox
                        text={props.bookableOption.meta.title}
                        shrink={1}
                        size={1}
                        color={theme.colors.default.text}
                        padding={{ right: 5 }}
                        weight="500"
                    />
                    <Conditional when={!zeroCost}>
                        <FlexRow>
                            <PriceTextBox
                                price={getFromPrice(
                                    props.bookableOption.resourcePrice,
                                )}
                                fractions="NONE"
                                color={theme.colors.default.text.light40}
                                weight="500"
                                size={0.875}
                            />
                            <HorizontalDivider XXS />
                            <TextBox
                                text={"/ " + text}
                                shrink={1}
                                color={theme.colors.default.text.light40}
                                weight="500"
                                size={0.875}
                            />
                        </FlexRow>
                    </Conditional>
                    <Conditional when={zeroCost && !!text?.trim()}>
                        <FlexRow>
                            <TextBox
                                text={text}
                                shrink={1}
                                color={theme.colors.default.text.light40}
                                weight="500"
                                size={0.875}
                            />
                        </FlexRow>
                    </Conditional>
                </FlexColumn>
            </LayoutCell>
            <LayoutCell>
                <FlexColumn fillParentHeight mainAxis="center">
                    <FlexColumn maxWidth={200}>
                        <OptionalAmountPicker
                            count={count}
                            minMax={minMax}
                            increase={increase}
                            decrease={decrease}
                            textColor={
                                maxIsOver ? theme.colors.danger : undefined
                            }
                        />
                        <Conditional when={maxIsOver}>
                            <TextBox
                                align="center"
                                text={`⚠️ Antalet överstiger kund max (${props.bookableOption.minMax.max})`}
                                weight={"300"}
                                size="XS"
                            />
                        </Conditional>
                    </FlexColumn>
                </FlexColumn>
            </LayoutCell>
        </LayoutGrid>
    );
};

const getFromPrice = (resourcePrice: ResourcePrice) => {
    if (
        isResourcePriceFixed(resourcePrice) ||
        isResourcePriceByTime(resourcePrice)
    )
        return {
            ...resourcePrice.price,
            prefix: translation({ sv: "fr.", en: "fr." }),
        };

    return zeroPrice;
};

export { BookableResourceModal, ResourceQuantityPicker };
