import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Picture } from "@@/shared/pictures/picture";
import { TextBox } from "@@/shared/text";
import { LogoCircle } from "@@/store-fronts/towni/sections/providers/provider-logo-link-circle";
import { useTheme } from "@emotion/react";
import {
    MetaImage,
    RemSize,
    StorageItemImage,
    Translatable,
} from "@towni/common";
import { useNavigate } from "react-router-dom";

type Props = {
    backgroundImage: MetaImage | StorageItemImage;
    logo?: MetaImage | StorageItemImage;
    title: Translatable;
    subtitle: Translatable;
    description?: Translatable;
    /**
     * defaults to `left`
     */
    logoPlacement?: "left" | "right";
    link?: string;
    showText: Translatable;
};

const borderRadius = 14;

const TicketReceiptView = (props: Props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <FlexColumn
            maxWidth={310}
            minWidth={310}
            css={{
                position: "relative",
                willChange: "filter",
                borderTopLeftRadius: borderRadius,
                borderTopRightRadius: borderRadius,
                borderRadius: borderRadius,
                overflow: "hidden",
                backgroundColor: theme.colors.transparent.asString,
                filter: `drop-shadow(0px 3.80066px 9.50166px ${
                    theme.colors.black.value.withAlpha(0.15).asString
                })`,
            }}>
            <FlexRow
                mainAxis="center"
                fillParentWidth
                css={{ position: "absolute", zIndex: 15, marginTop: 48 }}>
                <ButtonWhite
                    spin={!props.link}
                    onClick={() => {
                        if (props.link) navigate(props.link);
                    }}
                    css={{
                        borderRadius: 3000,
                        willChange: "filter",
                        filter: `drop-shadow(0px 2px 1px ${
                            theme.colors.black.value.withAlpha(0.2).asString
                        })`,
                    }}>
                    <TextBox
                        text={props.showText}
                        color={theme.colors.primary}
                        weight="600"
                    />
                </ButtonWhite>
            </FlexRow>
            <TicketHeader
                logo={props.logo}
                title={props.title}
                subtitle={props.subtitle}
                link={props.link}
                backgroundImage={props.backgroundImage}
                logoPlacement={props.logoPlacement}
            />
        </FlexColumn>
    );
};

const TicketHeader = (props: {
    backgroundImage: MetaImage | StorageItemImage;
    logo?: MetaImage | StorageItemImage;
    title: Translatable;
    subtitle: Translatable;
    link?: string;

    /**
     * defaults to `left`
     */
    logoPlacement?: "left" | "right";
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { backgroundImage, logo, title, subtitle } = props;

    return (
        <FlexColumn
            fillParentWidth
            onClick={() => {
                if (props.link) navigate(props.link);
            }}
            css={{ position: "relative", minHeight: 210 }}
            background={{ color: theme.colors.white }}>
            {backgroundImage ? (
                <Picture
                    imageRef={backgroundImage}
                    minHeight={130}
                    height={130}
                    imageOptions={{ imageHeight: 130, imageWidth: 310 }}
                />
            ) : (
                <FlexRow css={{ height: 130 }} />
            )}
            <Conditional when={!!logo}>
                <FlexRow
                    fillParentWidth
                    padding={{ all: 10 }}
                    css={{ position: "relative", zIndex: 10, marginTop: -60 }}>
                    <LogoCircle
                        logo={logo}
                        size={0.5 as RemSize}
                        boxShadow={`0px 4px 10px ${
                            theme.colors.black.value.withAlpha(0.1).asString
                        }`}
                    />
                </FlexRow>
            </Conditional>
            <TextBox
                text={title}
                size={1.1875}
                weight="900"
                padding={{ leftRight: 20, top: 15 }}
                color={theme.colors.black}
            />
            <VerticalDivider XXS />
            <TextBox
                text={subtitle}
                padding={{ leftRight: 20, bottom: 15 }}
                size={0.95}
                color={theme.colors.black.light30}
            />
        </FlexColumn>
    );
};

export { TicketReceiptView };
