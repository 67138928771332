import { generateId } from "@towni/common";
import * as React from "react";

const TEXT_STATE = "TEXT_STATE";
type TEXT_STATE = typeof TEXT_STATE;

type State = {
    readonly _id: string;
    readonly _type: TEXT_STATE;
    readonly text: string;
};

const createInitialState = (text = ""): State => {
    return {
        _id: generateId({ prefix: "text__" }),
        _type: TEXT_STATE,
        text,
    };
};

type Action =
    | {
          type: "SET_TEXT";
          text: string;
      }
    | {
          type: "CLEAR_TEXT";
      };

type Dispatch = (action: Action) => void;
const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "SET_TEXT": {
            return {
                ...state,
                text: action.text,
            };
        }
        case "CLEAR_TEXT": {
            return {
                ...state,
                text: "",
            };
        }
    }
};

const TextStateContext = React.createContext<State | undefined>(undefined);
const TextDispatchContext = React.createContext<Dispatch | undefined>(
    undefined,
);

const TextProvider = (props: {
    children: React.ReactNode;
    initialText: string;
    onSetText?: (text: string) => void;
}) => {
    const [state, dispatch] = React.useReducer(
        reducer,
        createInitialState(props.initialText),
    );
    React.useEffect(() => {
        props.onSetText?.(state.text ?? "");
    }, [state.text]);

    return (
        <TextStateContext.Provider value={state}>
            <TextDispatchContext.Provider value={dispatch}>
                {props.children}
            </TextDispatchContext.Provider>
        </TextStateContext.Provider>
    );
};

const useTextState = () => {
    const context = React.useContext(TextStateContext);
    if (context === undefined) {
        throw new Error("useTextState must be used within a TextProvider");
    }
    return context;
};

const useTextDispatch = () => {
    const dispatch = React.useContext(TextDispatchContext);
    if (dispatch === undefined) {
        throw new Error("useTextDispatch must be used within a TextProvider");
    }

    const setText = (text: string) =>
        dispatch({
            type: "SET_TEXT",
            text,
        });
    const clearText = () => {
        dispatch({ type: "CLEAR_TEXT" });
    };
    return {
        clearText,
        setText,
    };
};

export { TextProvider, useTextDispatch, useTextState };
