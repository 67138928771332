import { useBrandingContext } from "@@/routes/shared/branding-context";
import { Conditional } from "@@/shared/conditional";
import { CurrentUserIcon } from "@@/shared/current-user-icon";
import { HorizontalDivider } from "@@/shared/dividers/horizontal-divider";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { useTheme } from "@emotion/react";
import { _BackButton } from "./shared/_back-button";

const ReceiptToolbar__ = () => {
    const theme = useTheme();
    const isDesktop = usePageBreakpoint({ when: "🖥" });
    const { branding } = useBrandingContext();

    return (
        <div
            css={{
                width: "100%",
                padding: 20,
                label: "back_button_area",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: (branding?.colorThemes.light || theme.colors)
                    .white.asString,
                borderBottom: "1px solid #e5e5e5",
                zIndex: 9999,
                position: "sticky",
                top: 0,
                alignSelf: "start",
                "@media print": {
                    display: "none",
                },
                ...(!isDesktop
                    ? {
                          borderBottom: "none",
                          gridColumn: 1,
                          position: "sticky",
                          top: 0,
                          alignSelf: "start",
                          backgroundColor: (
                              branding?.colorThemes.light || theme.colors
                          ).transparent.asString,
                      }
                    : {}),
            }}>
            <_BackButton />
            <Conditional when={isDesktop}>
                <HorizontalDivider />
                <CurrentUserIcon />
            </Conditional>
        </div>
    );
};

export { ReceiptToolbar__ as _ReceiptToolbar };
