import { AppTheme } from "@@/styles/theme";
import { useTranslate } from "@@/translations/use-translate";
import { Interpolation, keyframes, useTheme } from "@emotion/react";
import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    ColorItem,
    EmSize,
    Padding,
    RemSize,
    SizeName,
    Translatable,
    colorAsString,
    setWithinPercentageRange,
    setWithinRange,
} from "@towni/common";
import * as React from "react";
import { MergeExclusive } from "type-fest";
import { paddingToCssValue } from "../padding";

type Props = {
    icon: IconDefinition | IconProp | undefined;
    spinner?: React.ReactElement;
    onClick?: (event: React.MouseEvent) => void | Promise<void>;
    className?: string; // allows use of emotion css prop
    spin?: boolean;
    spinSpeed?: number;
    cursor?: React.CSSProperties["cursor"];
    style?: React.CSSProperties;
    title?: Translatable;
    fixedWidth?: boolean;
    padding?: Padding;
    opacity?: React.CSSProperties["opacity"];
    delayRenderingInMs?: number;
    onDrag?: React.DragEventHandler<SVGSVGElement>;
    onDragStart?: React.DragEventHandler<SVGSVGElement>;
    onDragStartCapture?: React.DragEventHandler<SVGSVGElement>;
    onDragCapture?: React.DragEventHandler<SVGSVGElement>;
} & MergeExclusive<
    { color?: ColorItem },
    {
        colors?: {
            // Overrides font awesome css variables for duotone svg icons
            primaryColor?: ColorItem;
            primaryOpacity?: number;
            secondaryColor?: ColorItem;
            secondaryOpacity?: number;
        };
    }
> &
    MergeExclusive<
        {
            size?: SizeName | RemSize | number;
        },
        {
            relativeSize?: EmSize;
        }
    >;

const Icon = (props: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    const size =
        typeof props.size === "string"
            ? theme.sizes.inRem[props.size]
            : props.size;
    const delayInMs = props.delayRenderingInMs ?? 0;
    const animationDuration = 200;
    const delayAnimation = delayInMs
        ? keyframes`
        0% {
            opacity: 0;
        },
        ${Math.round(
            setWithinPercentageRange(
                delayInMs / (delayInMs + animationDuration),
            ) * 100,
        )}% {
            opacity: 0;
        },
        100% {
            opacity: 1;
        }
    `
        : undefined;

    const spinAnimation = props.spin
        ? keyframes`
        0% {
            transform: rotate(0deg);
        },
        100% {
            transform: rotate(360deg);
        }
    `
        : undefined;

    const styles: Interpolation<AppTheme> = {
        fontSize: props.relativeSize
            ? `${props.relativeSize}em`
            : size
              ? `${size}rem`
              : undefined,
        width: props.fixedWidth
            ? props.relativeSize
                ? 1.25 * props.relativeSize + "em"
                : size
                  ? `${1.25 * size}rem`
                  : undefined
            : undefined,
        cursor: (props.cursor ?? props.onClick) ? "pointer" : undefined,
        opacity: props.opacity,
        padding: paddingToCssValue(props.padding),
        ...(props.colors?.primaryColor
            ? { "--fa-primary-color": colorAsString(props.colors.primaryColor) }
            : {}),
        ...(props.colors?.primaryOpacity
            ? {
                  "--fa-primary-opacity": setWithinPercentageRange(
                      props.colors.primaryOpacity,
                  ),
              }
            : {}),
        ...(props.colors?.secondaryColor
            ? {
                  "--fa-secondary-color": colorAsString(
                      props.colors.secondaryColor,
                  ),
              }
            : {}),
        ...(props.colors?.secondaryOpacity
            ? {
                  "--fa-secondary-opacity": setWithinPercentageRange(
                      props.colors.secondaryOpacity,
                  ),
              }
            : {}),
    };

    if (!props.icon) return null;
    if (props.spin && props.spinner) return props.spinner;
    const delayAnimationCss = delayAnimation
        ? `${delayAnimation} ${delayInMs + animationDuration}ms ease`
        : "";
    const spinAnimationCss = spinAnimation
        ? `${spinAnimation} ${setWithinRange(props.spinSpeed ?? 1.5, { min: 1, max: 60 })}s infinite linear`
        : "";

    return (
        <FontAwesomeIcon
            icon={props.icon}
            onDrag={props.onDrag}
            onDragStart={props.onDragStart}
            onDragCapture={props.onDragCapture}
            onDragStartCapture={props.onDragStartCapture}
            color={props.color ? colorAsString(props.color) : undefined}
            className={props.className}
            style={props.style}
            css={[
                styles,
                {
                    animation:
                        delayAnimation && spinAnimation
                            ? `${delayAnimationCss}, ${spinAnimationCss}`
                            : delayAnimation
                              ? delayAnimationCss
                              : spinAnimation
                                ? spinAnimationCss
                                : "",
                },
            ]}
            fixedWidth={props.fixedWidth}
            onClick={event => void props.onClick?.(event)}
            title={translate(props.title)}
        />
    );
};

export { Icon };
export type { Props as IconProps };
