import { discountCodeQueryKey } from "@@/backoffice/for-providers/discounts/discount-code/hooks/use-discount-code";
import { calculateCartOrderItemPrices } from "@@/carts/calculate-order-item-prices";
import { productMainQueryKey } from "@@/products/state/queries/main-query-key";
import { resourceReservationsMainQueryKey } from "@@/resource-reservations/state/main-query-key";
import { browserLogger } from "@@/settings";
import { Toaster } from "@@/toasts/context/toast-context";
import { QueryClient } from "@tanstack/react-query";
import {
    Cart,
    enTranslator,
    exists,
    isOrderItemDiscount_V2,
    support,
    svTranslator,
    translation,
    VerificationError,
} from "@towni/common";
import { calculateNewDelivery } from "./calculate-new-delivery";

const calculateNewCart = async (
    cart: Cart,
    error: VerificationError,
    toast: Toaster,
    queryClient: QueryClient,
) => {
    const getOrderItems = cart.orderItems.map(async oi => {
        for (const verificationResult of error.results) {
            switch (verificationResult.type) {
                case "PRODUCT_NOT_FOUND":
                    if (
                        (oi.acquireType === "BUYABLE" ||
                            oi.acquireType === "BOOKABLE") &&
                        oi.skuId === verificationResult.item.skuId
                    ) {
                        return null;
                    }
                    break;
                case "OPTIONAL_NOT_FOUND":
                    if (oi._id === verificationResult.item.id) {
                        toast.warning({
                            message: translation({
                                sv: `${svTranslator(
                                    verificationResult.item.title,
                                )} är slut och vi har tagit bort den ur din kundvagn.`,
                                en: `${enTranslator(
                                    verificationResult.item.title,
                                )} is currently out of stock. We have removed it from your cart.`,
                            }),
                        });

                        return null;
                    }
                    break;
                case "BAD_REQUEST_PRICE_MISMATCH":
                    if (verificationResult.item.id === oi._id) {
                        toast.warning({
                            message: translation({
                                sv: `Priset på ${svTranslator(
                                    verificationResult.item.title,
                                )} stämde inte och uppdateras i kundkorgen. Var god kontrollera och acceptera det uppdaterade priset och lägg sedan beställningen igen.`,
                                en: `Price of ${enTranslator(
                                    verificationResult.item.title,
                                )} did not correspond to current price and will be updated in your cart. Please check out and accept the new price and then place the order again.`,
                            }),
                        });

                        return {
                            ...oi,
                            pricePerItem: verificationResult.actualPricePerItem,
                            price: verificationResult.actualPrice,
                        };
                    }
                    break;
                case "BAD_REQUEST_NO_PRICE_AVAILABLE":
                    if (verificationResult.item.id === oi._id) {
                        toast.warning({
                            message: translation({
                                sv: `Priset på ${svTranslator(
                                    verificationResult.item.title,
                                )} var inte tillgängligt och vi har tagit bort den ur din kundvagn.`,
                                en: `Price of ${enTranslator(
                                    verificationResult.item.title,
                                )} was not available. We have removed it from your cart.`,
                            }),
                        });

                        return null;
                    }
                    break;
                case "BAD_REQUEST_PRICE_TO_LARGE":
                    if (verificationResult.item.id === oi._id) {
                        toast.warning({
                            message: translation({
                                sv: `Priset på ${svTranslator(
                                    verificationResult.item.title,
                                )} var satt för högt och godkändes inte. Var god kontrollera och acceptera det uppdaterade priset och lägg sedan beställningen igen.`,
                                en: `Price of ${enTranslator(
                                    verificationResult.item.title,
                                )} was set too high and was not accepted. Please check out and accept the new price and then place the order again.`,
                            }),
                        });

                        return {
                            ...oi,
                            pricePerItem: verificationResult.maxPricePerItem,
                            price: verificationResult.maxPrice,
                        };
                    }
                    break;
                case "BAD_REQUEST_PRICE_TO_SMALL":
                    if (verificationResult.item.id === oi._id) {
                        toast.warning({
                            message: translation({
                                sv: `Priset på ${svTranslator(
                                    verificationResult.item.title,
                                )} var satt för lågt och godkändes inte och uppdateras i kundkorgen. Var god kontrollera och acceptera det uppdaterade priset och lägg sedan beställningen igen.`,
                                en: `Price of ${enTranslator(
                                    verificationResult.item.title,
                                )} was set too low and was not accepted. It will be updated in your cart. Please check out and accept the new price and then place the order again.`,
                            }),
                        });

                        return {
                            ...oi,
                            pricePerItem: verificationResult.minPricePerItem,
                            price: verificationResult.minPrice,
                        };
                    }
                    break;
                case "SESSION_FULL":
                    await queryClient.invalidateQueries({
                        queryKey: [productMainQueryKey],
                    });
                    await queryClient.invalidateQueries({
                        queryKey: [resourceReservationsMainQueryKey],
                    });
                    toast.warning({
                        message: translation({
                            sv: `Den valda tiden är inte längre tillgänglig, försök med en annan tid.`,
                            en: `The picked time is no longer available `,
                        }),
                    });

                    return null;
                case "RESOURCE_IS_ALREADY_BOOKED":
                    await queryClient.invalidateQueries({
                        queryKey: [productMainQueryKey],
                    });
                    await queryClient.invalidateQueries({
                        queryKey: [resourceReservationsMainQueryKey],
                    });
                    toast.warning({
                        message: translation({
                            sv: `Den valda resursen är redan bokad.`,
                            en: `The picked resource is already booked.`,
                        }),
                    });
                    return oi;
                case "UNABLE_TO_BOOK_ALL_OPTION_VALUES":
                    await queryClient.invalidateQueries({
                        queryKey: [productMainQueryKey],
                    });
                    await queryClient.invalidateQueries({
                        queryKey: [resourceReservationsMainQueryKey],
                    });
                    toast.warning({
                        message: translation({
                            sv: `Den valda tiden är inte längre tillgänglig, se andra tider.`,
                            en: `The picked time is no longer available `,
                        }),
                        sticky: true,
                    });

                    return null;
                case "MISSING_RESERVATION_FOR_BOOKABLE":
                    await queryClient.invalidateQueries({
                        queryKey: [productMainQueryKey],
                    });
                    await queryClient.invalidateQueries({
                        queryKey: [resourceReservationsMainQueryKey],
                    });
                    toast.warning({
                        message: translation({
                            sv: `Vi har detekterat en boknings-avvikelse. Om problemet kvarstår, kontakta gärna support på ${support.towni.supportEmail}`,
                            en: `We have detected a booking-difference. If the problem persists, please contact support at ${support.towni.supportEmail}`,
                        }),
                        sticky: true,
                    });

                    return null;
                case "GIFT_CARD_AMOUNT_MISMATCH":
                    await queryClient.invalidateQueries({
                        queryKey: [productMainQueryKey],
                    });
                    await queryClient.invalidateQueries({
                        queryKey: [resourceReservationsMainQueryKey],
                    });
                    toast.warning({
                        message: translation({
                            sv: `Gick inte att dra av summan på presentkortet`,
                            en: `Unable to use gift card amount`,
                        }),
                        sticky: true,
                    });

                    return null;
                case "GIFT_CARD_OUTDATED":
                    await queryClient.invalidateQueries({
                        queryKey: [productMainQueryKey],
                    });
                    await queryClient.invalidateQueries({
                        queryKey: [resourceReservationsMainQueryKey],
                    });
                    toast.warning({
                        message: translation({
                            sv: `Presentkortet är gammalt och går ej att använda`,
                            en: `Gift card is outdated`,
                        }),
                        sticky: true,
                    });

                    return null;
                case "DISCOUNT_CODE_NOT_FOUND":
                    if (isOrderItemDiscount_V2(oi)) {
                        browserLogger.error("DISCOUNT_CODE_NOT_FOUND");
                        await queryClient.invalidateQueries({
                            queryKey: [productMainQueryKey],
                        });

                        await queryClient.invalidateQueries({
                            queryKey: [discountCodeQueryKey],
                        });
                        toast.warning({
                            message: translation({
                                sv: `Rabattkoden hittas ej`,
                                en: `Discount code not found`,
                            }),
                            sticky: true,
                        });

                        return null;
                    }
                    break;
                case "DISCOUNT_CODE_NOT_VALID":
                    if (isOrderItemDiscount_V2(oi)) {
                        browserLogger.error("DISCOUNT_CODE_NOT_VALID");
                        await queryClient.invalidateQueries({
                            queryKey: [productMainQueryKey],
                        });

                        await queryClient.invalidateQueries({
                            queryKey: [discountCodeQueryKey],
                        });
                        toast.warning({
                            message: translation({
                                sv: `Rabattkoden är inte giltig`,
                                en: `Discount code is not valid`,
                            }),
                            sticky: true,
                        });

                        return null;
                    }
                    break;
                case "PASSED":
                default:
                    break;
            }
        }
        return oi;
    });
    const orderItems = (await Promise.all(getOrderItems)).filter(exists);
    const newCartPrices = calculateCartOrderItemPrices(
        orderItems,
        cart.tip,
        cart.reservationRequests,
    );
    return {
        ...cart,
        delivery: await calculateNewDelivery(cart, error, toast, queryClient),
        orderItems: orderItems,
        ...newCartPrices,
    };
};

export { calculateNewCart };
