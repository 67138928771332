import { readCsrfToken } from "@@/authentication/read-csrf-token";
import { browserLogger } from "@@/settings";
import { isProbablyBot } from "@@/settings/bot-detection";
import { updateBuildVersionAction } from "@@/settings/build-version.store";
import * as Sentry from "@sentry/react";
import {
    ApiError,
    apiErrorFactory,
    generateRequestId,
    isApiError,
    isVerificationError,
    svTranslator,
    translation,
    VerificationError,
} from "@towni/common";
import { atom } from "jotai";
import { towniSessionId } from "../settings/towni-session-id";
import { currentTimezoneName } from "./current-timezone-name";
import { getDestinationState } from "./destination-context";

const defaultHeaders = async () => {
    const area = getDestinationState().area;
    return {
        "Content-Type": "application/json",
        "CSRF-Token": readCsrfToken() ?? "",
        "Towni-Session-Id": towniSessionId,
        "Towni-Request-Id": generateRequestId(),
        "Towni-Destination": area,
        TimeZone: currentTimezoneName,
        "build-id": import.meta.env.VITE_BUILD_ID,
        "build-timestamp": import.meta.env.VITE_BUILD_TIMESTAMP,
        bd: (await isProbablyBot()) ? "true" : "false",
    } as const;
};

const ignoredStatusCodes = [401, 404, 409, 429];

const handleFetchResponse =
    <ResponseBody = string | Blob>(
        requestId: string,
        sessionId: string,
        validator?: (data: ResponseBody) => void,
    ) =>
    async (response: Response): Promise<ResponseBody> => {
        // Handle response based on return status
        const contentType = response.headers.get("content-type")?.toLowerCase();
        const isJson = !contentType || contentType.includes("application/json");
        const isData =
            !contentType || contentType.includes("application/octet-stream");

        // Check header for build version mismatches
        const buildId = response.headers.get("build-id");
        const buildTimestamp = response.headers.get("build-timestamp");
        const buildMismatchMinor =
            response.headers.get("build-mismatch-minor") === "true";
        const buildMismatchMajor =
            response.headers.get("build-mismatch-major") === "true";
        if (buildId && buildTimestamp) {
            updateBuildVersionAction({
                serverBuildId: buildId,
                serverBuildTimestamp: buildTimestamp,
                minorMismatch: buildMismatchMinor,
                majorMismatch: buildMismatchMajor,
            });
        }

        // Read response body
        const data: ResponseBody = isJson
            ? await response.json()
            : isData
              ? await response.blob()
              : await response.text();

        if (isVerificationError(data)) {
            // Catch our own api errors
            // eslint-disable-next-line @typescript-eslint/only-throw-error
            throw data as VerificationError;
        }

        if (!response.ok) {
            const isApiErrorData = isApiError(data);
            const apiError: ApiError = isApiErrorData
                ? data
                : apiErrorFactory({
                      statusCode: response.status,
                      reason: response.statusText,
                      explanation: typeof data === "string" ? data : undefined,
                  });

            const hasIgnoredStatusCode = ignoredStatusCodes.includes(
                apiError.statusCode,
            );

            if (!hasIgnoredStatusCode) {
                // Log the error
                browserLogger.error("Api Error", data);
                Sentry.withScope(scope => {
                    scope.setTag("request_id", requestId);
                    scope.setTag("session_id", sessionId);
                    scope.setExtras({
                        from: window.location.href,
                        targetUrl: response?.url,
                        status: response?.status,
                        apiError,
                    });

                    scope.setTransactionName(`${response?.url}`.trim());

                    Sentry.captureMessage(
                        `WebClient: Api Fetch Error; ${svTranslator(
                            apiError.reason,
                        )}; ${svTranslator(apiError.explanation)}`.trim(),
                        "error",
                    );
                });
            }

            // We still always throw the error
            // so that the fetchers now it's a failed fetch
            // eslint-disable-next-line @typescript-eslint/only-throw-error
            throw apiError;
        }

        // Validate body if validator is provided
        validator?.(data);
        // Return response data or error if response not ok
        return data;
    };

const getFetchUrl = (params: { route: string; endpoint: string }): string => {
    if (!params.route) throw new Error("Route must be provided");
    if (
        (params.endpoint && params.route.startsWith(params.endpoint)) ||
        params.route.startsWith("https://")
    )
        return params.route;
    const route = !params.route.startsWith("/")
        ? "/" + params.route
        : params.route;
    const url = params.endpoint + route;
    // browserLogger.log(
    //     `Fetching ${url}, ${params.endpoint}, ${params.route}, ${
    //         import.meta.env.VITEST
    //     }`
    // );
    return url;
};

const head =
    (endpoint: string) =>
    async (params: {
        route: string;
        customConfig?: RequestInit;
        endpoint?: string;
    }): Promise<
        | { success: true; headers: Headers }
        | { success: false; headers: Headers | undefined }
    > => {
        if (!params.route) throw new Error("Route must be provided");

        // Create config
        const defHeaders = await defaultHeaders();
        const config: RequestInit = {
            ...(params.customConfig ? params.customConfig : {}),
            method: "HEAD",
            credentials: params.customConfig?.credentials ?? "same-origin",
            redirect: params.customConfig?.redirect ?? "follow",
            headers: {
                ...defHeaders,
                ...(params.customConfig?.headers
                    ? params.customConfig.headers
                    : {}),
            },
        };

        const url = getFetchUrl({ endpoint, ...params });
        try {
            const fetchResult = await window
                .fetch(url, config)
                .then(response => {
                    return {
                        success:
                            response.status >= 200 && response.status < 400,
                        headers: response.headers,
                    };
                })
                .catch(error => {
                    Sentry.withScope(scope => {
                        scope.setExtras({
                            url,
                            error: "head request failed",
                            currentLocation: window.location.href,
                        });
                        Sentry.captureException(error);
                    });
                    return {
                        success: false as const,
                        headers: undefined,
                    };
                });

            return fetchResult;
        } catch (_error) {
            return { success: false as const, headers: undefined };
        }
    };

const get =
    (endpoint: string) =>
    async <ResponseBody = string>(params: {
        route: string;
        customConfig?: RequestInit;
        endpoint?: string;
        responseBodyValidator?: (data: ResponseBody) => void;
    }): Promise<ResponseBody> => {
        if (!params.route) throw new Error("Route must be provided");

        // Create config
        const defHeaders = await defaultHeaders();
        const config: RequestInit = {
            ...(params.customConfig ? params.customConfig : {}),
            method: "GET",
            credentials: params.customConfig?.credentials ?? "same-origin",
            redirect: params.customConfig?.redirect ?? "follow",
            headers: {
                ...defHeaders,
                ...(params.customConfig?.headers
                    ? params.customConfig.headers
                    : {}),
            },
        };

        const url = getFetchUrl({ endpoint, ...params });
        return await window
            .fetch(url, config)
            .then(
                handleFetchResponse<ResponseBody>(
                    defHeaders["Towni-Request-Id"],
                    defHeaders["Towni-Session-Id"],
                    params.responseBodyValidator,
                ),
            );
    };
const send =
    (method: "POST" | "PUT") =>
    (endpoint: string) =>
    async <RequestBody = unknown, ResponseBody = unknown>(params: {
        route: string;
        body: RequestBody;
        customConfig?: RequestInit;
        endpoint?: string;
        captchaValue?: string;
        requestBodyValidator?: (data: RequestBody) => void;
        responseBodyValidator?: (data: ResponseBody) => void;
    }): Promise<ResponseBody> => {
        if (!params.route) throw new Error("Route must be provided");

        // Validate request body if validator is provided
        params.requestBodyValidator?.(params.body);

        // Create config
        const defHeaders = await defaultHeaders();
        const config: RequestInit = {
            ...(params.customConfig ? params.customConfig : {}),
            method,
            credentials: params.customConfig?.credentials ?? "same-origin",
            body: params.body ? JSON.stringify(params.body) : undefined,
            headers: {
                ...defHeaders,
                ...(params.customConfig?.headers
                    ? params.customConfig.headers
                    : {}),
                ...(params.captchaValue
                    ? { captcha: params.captchaValue }
                    : {}),
            },
        };

        const url = getFetchUrl({ endpoint, ...params });
        return await window
            .fetch(url, config)
            .then(
                handleFetchResponse<ResponseBody>(
                    defHeaders["Towni-Request-Id"],
                    defHeaders["Towni-Session-Id"],
                    params.responseBodyValidator,
                ),
            );
    };

const download =
    (endpoint: string) =>
    async <RequestBody = unknown, ResponseBody = unknown>(params: {
        route: string;
        body: RequestBody;
        customConfig?: RequestInit;
        endpoint?: string;
        requestBodyValidator?: (data: RequestBody) => void;
        responseBodyValidator?: (data: ResponseBody) => void;
    }): Promise<Blob> => {
        if (!params.route) throw new Error("Route must be provided");

        // Validate request body if validator is provided
        params.requestBodyValidator?.(params.body);

        // Create config
        const config: RequestInit = {
            ...(params.customConfig ? params.customConfig : {}),
            method: "POST",
            credentials: params.customConfig?.credentials ?? "same-origin",
            body: params.body ? JSON.stringify(params.body) : undefined,
            headers: {
                ...(await defaultHeaders()),
                ...(params.customConfig?.headers
                    ? params.customConfig.headers
                    : {}),
            },
        };

        const url = getFetchUrl({ endpoint, ...params });
        const fetchResult = await window.fetch(url, config);
        if (!fetchResult.ok) {
            const fetchResultData = await fetchResult.json();
            if (isApiError(fetchResultData)) {
                // eslint-disable-next-line @typescript-eslint/only-throw-error
                throw fetchResultData;
            }
            // eslint-disable-next-line @typescript-eslint/only-throw-error
            throw apiErrorFactory({
                statusCode: fetchResult.status,
                reason: translation({
                    sv: `Misslyckades att ladda ner fil från ${url}`,
                    en: `Failed to download file from ${url}`,
                }),
                explanation: fetchResult.statusText,
            });
        }

        return fetchResult.blob();
    };

const post = send("POST");
const put = send("PUT");

const remove =
    (endpoint: string) =>
    async <DeleteBody = unknown, ResponseBody = unknown>(params: {
        route: string;
        body: DeleteBody;
        customConfig?: RequestInit;
        endpoint?: string;
        deleteBodyValidator?: (data: DeleteBody) => void;
        responseBodyValidator?: (data: ResponseBody) => void;
    }): Promise<ResponseBody> => {
        if (!params.route) throw new Error("Route must be provided");

        // Validate delete body if validator is provided
        params.deleteBodyValidator?.(params.body);

        // Create config
        const defHeaders = await defaultHeaders();
        const config: RequestInit = {
            ...(params.customConfig ? params.customConfig : {}),
            method: "DELETE",
            credentials: params.customConfig?.credentials ?? "same-origin",
            body: params.body ? JSON.stringify(params.body) : undefined,
            headers: {
                ...defHeaders,
                ...(params.customConfig?.headers
                    ? params.customConfig.headers
                    : {}),
            },
        };

        const url = getFetchUrl({ endpoint, ...params });
        return await window
            .fetch(url, config)
            .then(
                handleFetchResponse<ResponseBody>(
                    defHeaders["Towni-Request-Id"],
                    defHeaders["Towni-Session-Id"],
                    params.responseBodyValidator,
                ),
            );
    };

const fetchClient = (endpoint: string) => {
    const _endpoint =
        import.meta.env.VITEST && !endpoint.startsWith("http")
            ? `http://localhost:${import.meta.env.VITE_PORT}/${endpoint}`
            : endpoint;
    // ^^ quick ugly fix to make tests worse, requires absolute url

    return {
        get: get(_endpoint),
        post: post(_endpoint),
        put: put(_endpoint),
        head: head(_endpoint),
        delete: remove(_endpoint),
        download: download(_endpoint),
    };
};

const backendHost = import.meta.env.VITEST
    ? import.meta.env.VITE_API_HOST_ENDPOINT
    : "";

const apiFetchClient = fetchClient(`${backendHost}/api`);

type ApiFetchClient = typeof apiFetchClient;
const useFetchClient = (): ApiFetchClient => {
    return apiFetchClient;
};

const fetchClientAtom = atom(apiFetchClient);

type FetchClient = ReturnType<typeof fetchClient>;

export {
    apiFetchClient,
    backendHost,
    fetchClient,
    fetchClientAtom,
    useFetchClient,
};
export type { ApiFetchClient, FetchClient };
