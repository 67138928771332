import { useProviderFromContext } from "@@/providers/state/contexts/use-provider-from-context";
import { fetchClientAtom } from "@@/shared/fetch-client";
import { HydrateAtoms, HydrateAtomsValueMap } from "@@/shared/hydrate-atoms";
import {
    useTanstackMultiQuery,
    type TanstackQueryOptions,
} from "@@/shared/use-tanstack-query";
import {
    emptyArrayOf,
    GetResponse,
    type AcquireType,
    type Product,
    type ProviderId,
} from "@towni/common";
import { atom, WritableAtom } from "jotai";
import { ScopeProvider } from "jotai-scope";
import { atomWithQuery } from "jotai-tanstack-query";
import { atomWithReducer } from "jotai/utils";
import { useMemo } from "react";
import { productMainQueryKey } from "../main-query-key";

const _getRoute = (providerId?: ProviderId, type?: AcquireType) =>
    `/products/for-provider/${encodeURIComponent(providerId ?? "<unknown>")}${
        type ? "?type=" + type : ""
    }`;

const useProductsForProvider = (
    providerId?: ProviderId,
    type?: AcquireType,
    options?: TanstackQueryOptions<Product[]>,
) =>
    useTanstackMultiQuery<Product>(
        _getRoute(providerId, type),
        [productMainQueryKey, providerId],
        { enabled: !!providerId, ...options },
    );

const _providerIdAtom = atom<ProviderId | undefined>(undefined);
const _productsForProviderQueryOptionsAtom = atom<
    TanstackQueryOptions<Product[]>
>({});
const productsForProviderQueryAtom = atomWithQuery<Product[]>(get => {
    const providerId = get(_providerIdAtom);
    const options = get(_productsForProviderQueryOptionsAtom);
    const fetchClient = get(fetchClientAtom);
    const route = _getRoute(providerId);
    return {
        queryKey: [productMainQueryKey, providerId],
        options: { enabled: !!providerId, ...options },
        staleTime: 1000,
        queryFn: async ({ signal }): Promise<Product[]> => {
            const data = await fetchClient.get<GetResponse<Product>>({
                route,
                customConfig: {
                    signal,
                },
            });
            return data?.items ?? emptyArrayOf<Product>();
        },
        ...options,
    };
});

export function atomWithCompare<Value>(
    initialValue: Value,
    areEqual: (prev: Value, next: Value) => boolean,
) {
    return atomWithReducer(initialValue, (prev: Value, next: Value) => {
        if (areEqual(prev, next)) {
            return prev;
        }

        return next;
    });
}

const productsForProviderAtom = atom(get => {
    const { data: products = emptyArrayOf<Product>() } = get(
        productsForProviderQueryAtom,
    );
    return products;
});
const productsForProviderIsPendingAtom = atom(get => {
    const { isPending } = get(productsForProviderQueryAtom);
    return isPending;
});

// tanstackMultiQueryAtom<Product>(get => {
//     const providerId = get(_providerIdAtom);
//     const options = get(_productsForProviderQueryOptionsAtom);
//     return {
//         route: _getRoute(providerId),
//         queryKey: [productMainQueryKey, providerId],
//         options: { enabled: !!providerId, ...options },
//     };
// });

type Props = {
    readonly providerId?: ProviderId;
    readonly queryOptions?: TanstackQueryOptions<Product[]>;
    readonly children: React.ReactNode;
};
const ProductsForProviderAtomScopeProvider = (props: Props) => {
    const provider = useProviderFromContext({
        disableThrowWhenMissingContext: true,
    });
    const providerId = props.providerId ?? provider?._id;
    if (!providerId) throw new Error("ProviderId is required");

    const atomValues = useMemo((): HydrateAtomsValueMap => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return new Map<WritableAtom<unknown, [any], unknown>, unknown>([
            [_providerIdAtom, providerId],
            [_productsForProviderQueryOptionsAtom, props.queryOptions ?? {}],
        ]);
    }, [props.queryOptions, providerId]);

    return (
        <ScopeProvider
            atoms={[
                _providerIdAtom,
                _productsForProviderQueryOptionsAtom,
                productsForProviderQueryAtom,
                productsForProviderAtom,
                productsForProviderIsPendingAtom,
            ]}>
            <HydrateAtoms atomValues={atomValues}>
                {props.children}
            </HydrateAtoms>
        </ScopeProvider>
    );
};

const productsForProviderAtoms = {
    productsAtom: productsForProviderAtom,
    isPendingAtom: productsForProviderIsPendingAtom,
};

export {
    productsForProviderAtoms,
    ProductsForProviderAtomScopeProvider,
    useProductsForProvider,
};
