import { MILLISECONDS } from "@towni/common";
import { useCallback, useEffect, useRef } from "react";

const useTimeout = (callback: () => void, delay: MILLISECONDS) => {
    const timeoutRef = useRef<NodeJS.Timeout | undefined>();
    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    const delayChange = useCallback((_delay: number) => {
        timeoutRef.current = setTimeout(callbackRef.current, _delay);
    }, []);

    const clear = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = undefined;
        }
    }, []);

    useEffect(() => {
        delayChange(delay);
        return clear;
    }, [delay, delayChange, clear]);

    const reset = useCallback(() => {
        clear();
        delayChange(delay);
    }, [clear, delay, delayChange]);

    return { reset, clear };
};

export { useTimeout };
