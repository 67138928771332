import { FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { css, useTheme } from "@emotion/react";
import { faMinus } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { ColorItem, ColorSet, MinMaxRange } from "@towni/common";
import * as React from "react";
import { Icon } from "../icons/icon";

type Props = {
    readonly count: number | "INFINITE";
    readonly minMax?: MinMaxRange;
    readonly disabled?: boolean;
    readonly increase: () => void;
    readonly decrease: () => void;
    /**
     * defaults to 1
     * @type {number}
     */
    readonly grow?: React.CSSProperties["flexGrow"];
    /**
     * defaults to 0
     * @type {number}
     */
    readonly shrink?: React.CSSProperties["flexShrink"];
    readonly basis?: React.CSSProperties["flexBasis"];
    readonly textColor?: ColorSet;
    readonly backgroundColor?: ColorItem;
};

const OptionalAmountPicker = (props: Props) => {
    const theme = useTheme();
    const { count, disabled, minMax } = props;
    const { min, max } = minMax || { min: undefined, max: undefined };

    const increaseDisabled =
        count === "INFINITE" || (typeof max === "number" && count >= max);
    const decreaseDisabled =
        count === "INFINITE" || (typeof min === "number" && count <= min);

    const increase = () => {
        if (disabled || increaseDisabled) return;
        props.increase();
    };
    const decrease = () => {
        if (disabled || decreaseDisabled) return;
        props.decrease();
    };

    const increaseDecreaseCss = css`
        display: block;
        font-size: 1rem;
        padding: 10px 15px;
        user-select: none;
        cursor: pointer;
    `;
    const increaseCss = css`
        ${increaseDecreaseCss || ""}
        ${disabled || increaseDisabled
            ? ":active { opacity: 0.2}; opacity: 0.2"
            : ":active { opacity: 0.5}; opacity: 0.7"}
    `;
    const decreaseCss = css`
        ${increaseDecreaseCss || ""}
        ${disabled || decreaseDisabled
            ? ":active { opacity: 0.2}; opacity: 0.2"
            : ":active { opacity: 0.5}; opacity: 0.7"}
    `;

    const colors = disabled
        ? {
              background: theme.colors.disabled,
              text: theme.colors.disabled.text,
          }
        : {
              background:
                  props.backgroundColor ?? theme.colors.white.main.light90,
              text: props.textColor ?? theme.colors.black,
          };

    return (
        <FlexRow
            grow={props.grow ?? 1}
            basis={props.basis}
            shrink={props.shrink ?? 0}
            mainAxis="space-between"
            crossAxis="center"
            radius={1000}
            background={{ color: colors.background }}
            color={colors.text}>
            <a css={decreaseCss} onClick={decrease}>
                <Icon icon={faMinus} />
            </a>

            <TextBox
                disableAutoTranslate
                text={count.toString()}
                weight="800"
                size={1}
                shrink={props.shrink ?? 0}
                styling={css`
                    font-variant-numeric: tabular-nums;
                `}
            />
            <a css={increaseCss} onClick={increase}>
                <Icon icon={faPlus} />
            </a>
        </FlexRow>
    );
};

export { OptionalAmountPicker };
