import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { ButtonPrimaryLight } from "@@/shared/buttons_v2/button-primary-light";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { Icon } from "@@/shared/icons/icon";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import { SquareImage } from "@@/shared/pictures/image";
import {
    paragraphButtonFactory,
    paragraphHzSpaceFactory,
    paragraphTextFactory,
} from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { Paragraph } from "@@/shared/text/paragraphs/paragraph";
import { TextBox } from "@@/shared/text/text-box";
import { UploadError } from "@@/shared/text/upload-error";
import { uploadFile } from "@@/shared/text/upload-file";
import { UploadProgress } from "@@/shared/text/upload-progress";
import { useElementSize } from "@@/shared/use-element-size";
import { useIsMountedRef } from "@@/shared/use-is-mounted-ref";
import { defaultRect } from "@@/shared/use-size-tracker";
import { useUpdateEffect } from "@@/shared/use-update-effect";
import { useImageStorageItem } from "@@/storage-items/queries/use-storage-item";
import { useToast } from "@@/toasts/context/toast-context";
import { useTheme } from "@emotion/react";
import { faCloudUpload } from "@fortawesome/pro-duotone-svg-icons";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import {
    ColorItem,
    MetaImage,
    Percentage,
    ProviderId,
    RemSize,
    StorageItemId,
    StorageItemImageContentType,
    StorageItemImageId,
    Translatable,
    imageContentTypesWithoutHeicHeif,
    isStorageItemImageId,
    remSize,
    storageItemImageIdFactory,
    translation,
    uploadIdFactory,
} from "@towni/common";
import { Draft } from "immer";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";

type Value = StorageItemImageId | undefined;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (
        draft: Draft<Partial<State>>,
        newValue: NonNullable<Value> | undefined,
    ) => void;

    readonly imageRequirementDescription?: Translatable;
    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly labelColor?: ColorItem;
    readonly placeholder?: JSX.Element;
    readonly initialImage?: MetaImage;
    readonly providerId: ProviderId | undefined;
    readonly size?: RemSize;
    readonly imageContentTypes?: StorageItemImageContentType[];
    readonly options?: {
        allowRemove?: boolean;
        /**
         * When uploading an svg, and this is true, the svg will be fixed
         * to create classes from dot notated id that figma exports.
         *
         *E.g. `.selectable"`, `.color_switching_area"`, `.text_element"` will be found and replaced with
         * `" class="selectable"`, `" class="color_switching_area"` and `" class="text_element"`.
         * @type {boolean}
         */
        figmaSvgExportFix?: boolean;
    };
};

const figmaSvgExportFix = (file: File): Promise<string> => {
    if (!file.name.toLowerCase().endsWith(".svg")) {
        throw new Error("File is not an SVG file");
    }
    const task = new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            let output = reader.result as string;
            output = output.replace(/\.selectable"/gi, '" class="selectable"');
            output = output.replace(
                /(\.color_switching_area\S*")/gi,
                '$1 class="color_switching_area"',
            );
            output = output.replace(
                /(\.text_element\S*")/gi,
                '$1 class="text_element"',
            );
            resolve(output);
            return;
        };
        reader.onerror = () => {
            // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
            reject(reader.error);
            return;
        };
        reader.onabort = () => {
            // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
            reject(reader.error);
            return;
        };
        reader.readAsText(file);
    });
    return task;
};

const Form2ImageUploadSingle = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const theme = useTheme();
    const toast = useToast();
    const imageTypes = props.imageContentTypes ?? [
        ...imageContentTypesWithoutHeicHeif,
    ];
    const [uploadId] = React.useState(uploadIdFactory);
    const [droppable, setDroppable] = React.useState(false);
    const [fileUploads, setFileUploads] = React.useState<
        {
            file: File;
            storageItemId: StorageItemImageId;
            progress: Percentage;
        }[]
    >();

    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        formId: props.formId,
    });
    if (!field)
        throw new Error(`Field ${props.fieldId} in form ${formId} not found`);

    const [initialValue] = useState(field.value);

    const [storageItemId, setStorageItemId] = React.useState<
        StorageItemImageId | undefined
    >(isStorageItemImageId(field.value) ? field.value : undefined);

    useUpdateEffect(() => {
        field.setValue(storageItemId);
        field.setTouched(true);
        field.setDirty(true);
    }, [storageItemId]);

    const [image] = useImageStorageItem(storageItemId);
    const isMounted = useIsMountedRef();

    const onProgress = React.useCallback(
        (storageItemId: StorageItemId, progress: Percentage) => {
            if (!isMounted.current) return;
            setFileUploads(uploads =>
                uploads?.map(upload =>
                    upload.storageItemId !== storageItemId
                        ? upload
                        : {
                              ...upload,
                              progress,
                          },
                ),
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setFileUploads],
    );

    const onImageSelected = useCallback(
        async (fileList: FileList): Promise<void> => {
            // Create a storageItemId
            const filtered = Array.from(fileList)
                .filter(item =>
                    imageContentTypesWithoutHeicHeif.includes(
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        item.type as unknown as any,
                    ),
                )
                .map(item => ({
                    file: item,
                    storageItemId: storageItemImageIdFactory(),
                    progress: 0 as Percentage,
                }));
            const file = filtered[0];
            if (!file) {
                toast.warning({
                    message: translation({
                        sv: "Det du försökte ladda upp var inte en bildfil",
                        en: "The file you tried to upload was not an image file",
                    }),
                    title: translation({
                        sv: "Ingen bildfil",
                        en: "No image file",
                    }),
                });
                return;
            }
            setFileUploads(uploads => [...(uploads ?? []), file]);

            const figmaFix =
                props.imageContentTypes?.length === 1 &&
                props.imageContentTypes[0] === "image/svg+xml" &&
                props.options?.figmaSvgExportFix;
            const data = figmaFix
                ? {
                      _type: "TEXT_FILE" as const,
                      name: file.file.name,
                      data: await figmaSvgExportFix(file.file),
                  }
                : file.file;

            const uploadTask = uploadFile({
                file: data,
                providerId: props.providerId,
                storageItemId: file.storageItemId,
                uploadId,
                onProgress,
            });

            uploadTask
                .then(storageItemId => {
                    if (!isMounted.current) return;
                    // Add storageItemId to form
                    setStorageItemId(storageItemId);
                    setFileUploads(uploads =>
                        uploads?.filter(
                            upload => upload.storageItemId !== storageItemId,
                        ),
                    );
                    return;
                })
                .catch(error => {
                    if (!isMounted.current) return;
                    if (error instanceof UploadError) {
                        setFileUploads(uploads =>
                            uploads?.filter(
                                upload =>
                                    upload.storageItemId !==
                                    error.storageItemId,
                            ),
                        );
                        toast.danger({
                            message: error.message,
                            sticky: true,
                        });
                        return undefined;
                    }
                    throw error;
                });
        },
        [
            props.imageContentTypes,
            props.options?.figmaSvgExportFix,
            props.providerId,
            uploadId,
            onProgress,
            toast,
            isMounted,
        ],
    );

    const onDragEvent = (
        event:
            | React.DragEvent<HTMLDivElement>
            | MouseEvent
            | TouchEvent
            | PointerEvent,
    ) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        setDroppable(true);
        onDragEvent(event);
    };
    const onDragLeave = (
        event:
            | React.DragEvent<HTMLDivElement>
            | MouseEvent
            | TouchEvent
            | PointerEvent,
    ) => {
        setDroppable(false);
        onDragEvent(event);
    };
    const onDragDrop = (event: React.DragEvent<HTMLDivElement>) => {
        onImageSelected(event.dataTransfer.files).catch(error => {
            throw error;
        });
        onDragLeave(event);
    };

    const imageToPreview = image ?? props.initialImage;
    const [containerSize, sizeTrackerRef] = useElementSize({
        fallback: defaultRect,
    });
    const wide = containerSize.width > 350;

    const gridTemplateAreas: string = wide
        ? imageToPreview
            ? fileUploads?.length
                ? // wide, preview, uploads
                  `"image-preview description" "uploads uploads"`
                : // wide, preview
                  `"image-preview description"`
            : fileUploads?.length
              ? // wide, uploads
                `"description" "uploads"`
              : // wide
                `"description"`
        : imageToPreview
          ? fileUploads?.length
              ? // narrow, preview, uploads
                `"description" "uploads" "image-preview"`
              : // narrow, preview
                `"description" "image-preview"`
          : fileUploads?.length
            ? // narrow, uploads
              `"description" "uploads"`
            : // narrow
              `"description"`;

    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        // Handle paste from clipboard
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onPaste = (ev: Event) => {
            if (document.activeElement?.id !== uploadId) return;
            ev.stopPropagation();
            const fileInput = inputRef.current;
            if (!fileInput) return;
            fileInput.files =
                (ev as ClipboardEvent).clipboardData?.files || null;
            if (fileInput.files?.length) {
                onImageSelected(fileInput.files).catch(error => {
                    throw error;
                });
            }
        };

        window.addEventListener("paste", onPaste);
        return () => {
            window.removeEventListener("paste", onPaste);
        };
    }, [onImageSelected, uploadId]);

    useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "automatic",
    });

    return (
        <FlexColumn
            ref={sizeTrackerRef}
            tag="sizeTracked"
            crossAxis="flex-start">
            <Conditional
                when={!!props.label}
                render={() => {
                    return (
                        <>
                            <FlexRow shrink={0} crossAxis="center">
                                <FieldTitle
                                    htmlFor={props.fieldId}
                                    padding={{ left: 2 }}
                                    text={props.label ?? ""} // already checked with conditional above
                                    color={props.labelColor}
                                    required={field.isRequired}
                                />
                                <Conditional when={!!props.labelDescription}>
                                    <HorizontalDivider XXS />
                                    <FieldTitle
                                        padding={{ left: 2 }}
                                        text={props.labelDescription ?? ""} // already checked with conditional above
                                        color={props.labelColor}
                                        weight="400"
                                        size="S"
                                    />
                                </Conditional>
                            </FlexRow>
                            <VerticalDivider XS />
                        </>
                    );
                }}
            />
            <LayoutGrid
                fillParentWidth
                id={uploadId}
                tabIndex={0}
                gridTemplateColumns={wide && imageToPreview ? "1fr 2fr" : "1fr"}
                css={{
                    userSelect: "auto",
                    "&:focus": {
                        backgroundColor: theme.colors.primary.light.asString,
                        borderColor: theme.colors.primary.asString,
                    },
                    ...(imageToPreview
                        ? {
                              "&:focus > div": {
                                  backgroundColor:
                                      theme.colors.primary.light.asString,
                              },
                          }
                        : {
                              "&:focus div": {
                                  backgroundColor:
                                      theme.colors.primary.light.asString,
                              },
                          }),
                    padding: 10,
                    gridTemplateAreas,
                    backgroundColor: droppable
                        ? theme.colors.primary.light.asString
                        : theme.colors.textInput.background.asString,
                    borderRadius: theme.radius,
                    borderWidth: 1,
                    borderStyle:
                        droppable || !imageToPreview ? "dashed" : "solid",
                    borderColor: droppable
                        ? theme.colors.primary.asString
                        : theme.colors.textInput.border.asString,
                    overflow: "hidden",
                }}>
                <LayoutCell css={{ gridArea: "description" }}>
                    <FlexColumn
                        fillParent
                        crossAxis="center"
                        mainAxis="center"
                        onDrag={onDragEvent}
                        onDragStart={onDragEvent}
                        onDragOver={onDragEnter}
                        onDragEnter={onDragEnter}
                        onDragEnd={onDragLeave}
                        onDragLeave={onDragLeave}
                        onDrop={onDragDrop}
                        background={{
                            color:
                                imageToPreview || !!fileUploads?.length
                                    ? theme.colors.default.background
                                    : droppable
                                      ? theme.colors.primary.light
                                      : theme.colors.textInput.background,
                        }}
                        css={{
                            padding: "10px 20px",
                            pointerEvents: "all",
                            borderRadius: theme.radius,
                            border:
                                imageToPreview || !!fileUploads?.length
                                    ? `1px dashed ${
                                          droppable
                                              ? theme.colors.primary.asString
                                              : theme.colors.textInput.border
                                                    .asString
                                      }`
                                    : "none",
                        }}>
                        <FlexColumn
                            fillParentWidth
                            maxWidth={400}
                            crossAxis="center"
                            css={{
                                pointerEvents: "none",
                            }}>
                            <LayoutGrid
                                fillParentWidth
                                gap={5}
                                css={{
                                    gridTemplateAreas: imageToPreview
                                        ? `"icon" "text"`
                                        : `"icon" "text"`,
                                }}>
                                <LayoutCell css={{ gridArea: "icon" }}>
                                    <FlexRow
                                        fillParentWidth
                                        mainAxis="center"
                                        crossAxis="flex-end">
                                        <Icon
                                            icon={faCloudUpload}
                                            size={remSize(
                                                imageToPreview ? 1.6 : 2,
                                            )}
                                            color={
                                                droppable
                                                    ? theme.colors.primary
                                                    : theme.colors.default
                                                          .placeholder
                                            }
                                        />
                                    </FlexRow>
                                </LayoutCell>
                                <LayoutCell
                                    css={{
                                        gridArea: "text",
                                        textAlign:
                                            imageToPreview && wide
                                                ? "left"
                                                : "center",
                                    }}>
                                    <input
                                        style={{ display: "none" }}
                                        type="file"
                                        ref={inputRef}
                                        multiple={false}
                                        accept={imageTypes.join(", ")}
                                        onChange={event => {
                                            const files = event.target.files;
                                            if (files === null) return;
                                            onImageSelected(files).catch(
                                                error => {
                                                    throw error;
                                                },
                                            );
                                        }}
                                    />
                                    <Conditional
                                        when={
                                            !!imageToPreview ||
                                            !!fileUploads?.length
                                        }>
                                        <Paragraph
                                            css={{
                                                fontSize: "0.9rem",
                                                fontWeight: 400,
                                                maxWidth: 250,
                                                lineHeight: "1.1rem",
                                                color: droppable
                                                    ? theme.colors.primary
                                                          .asString
                                                    : theme.colors.default
                                                          .placeholder.asString,
                                            }}
                                            content={[
                                                paragraphTextFactory({
                                                    text: translation({
                                                        sv: `Ersätt bild genom att klistra in eller släppa en ny här, eller `,
                                                        en: `Replace image by pasting or dropping a new one here or `,
                                                    }),
                                                    css: { display: "inline" },
                                                }),
                                                paragraphButtonFactory({
                                                    text: translation({
                                                        sv: `välj bild`,
                                                        en: `choose image`,
                                                    }),
                                                    textCss: {
                                                        fontSize: "0.9rem",
                                                    },
                                                    onClick: event => {
                                                        event.preventDefault();
                                                        inputRef.current?.click();
                                                    },
                                                    button: ButtonPrimaryLight,
                                                    css: {
                                                        margin: 0,
                                                        marginTop: 2,
                                                        display: "inline-block",
                                                        borderRadius: 5,
                                                        borderStyle: "solid",
                                                        borderWidth: 1,
                                                        padding: "2px 6px",
                                                        borderColor:
                                                            theme.colors.primary.main.withAlpha(
                                                                0.2,
                                                            ).asString,
                                                    },
                                                }),
                                            ]}
                                        />
                                    </Conditional>
                                    <Conditional
                                        when={
                                            !imageToPreview &&
                                            !fileUploads?.length
                                        }>
                                        <VerticalDivider XS />
                                        <Paragraph
                                            css={{
                                                textAlign:
                                                    imageToPreview && wide
                                                        ? "left"
                                                        : "center",
                                                fontSize: "1rem",
                                                fontWeight: 700,
                                                lineHeight: "1.3rem",
                                                color: droppable
                                                    ? theme.colors.primary
                                                          .asString
                                                    : theme.colors.default
                                                          .placeholder.asString,
                                            }}
                                            content={[
                                                paragraphTextFactory({
                                                    text: translation({
                                                        sv: `Klistra in eller släpp här, eller`,
                                                        en: `Paste or drop here, or `,
                                                    }),
                                                    css: {
                                                        display: "inline",
                                                        textTransform:
                                                            imageToPreview
                                                                ? "lowercase"
                                                                : undefined,
                                                    },
                                                }),
                                                paragraphHzSpaceFactory(),
                                                paragraphButtonFactory({
                                                    text: translation({
                                                        sv: `välj bild`,
                                                        en: `choose image`,
                                                    }),
                                                    onClick: event => {
                                                        event.preventDefault();
                                                        inputRef.current?.click();
                                                    },
                                                    button: ButtonPrimaryLight,
                                                    css: {
                                                        pointerEvents: "all",
                                                        margin: 0,
                                                        marginTop: 2,
                                                        display: "inline-block",
                                                        borderRadius: 5,
                                                        borderStyle: "solid",
                                                        borderWidth: 1,
                                                        padding: "2px 8px",
                                                        borderColor:
                                                            theme.colors.primary.main.withAlpha(
                                                                0.2,
                                                            ).asString,
                                                    },
                                                }),
                                            ]}
                                        />
                                        <Conditional
                                            when={
                                                !!props.imageRequirementDescription
                                            }>
                                            <VerticalDivider XXS />
                                            <TextBox
                                                text={
                                                    props.imageRequirementDescription
                                                }
                                                size={0.8}
                                                weight="400"
                                                align={
                                                    imageToPreview && wide
                                                        ? "left"
                                                        : "center"
                                                }
                                                color={
                                                    theme.colors.default
                                                        .placeholder
                                                }
                                            />
                                        </Conditional>
                                    </Conditional>
                                </LayoutCell>
                            </LayoutGrid>
                        </FlexColumn>
                    </FlexColumn>
                </LayoutCell>
                <Conditional
                    when={gridTemplateAreas.includes("uploads")}
                    render={() => {
                        return (
                            <LayoutCell
                                css={{ gridArea: "uploads", padding: 5 }}>
                                <Conditional when={!!fileUploads?.length}>
                                    <FlexColumn
                                        fillParent
                                        mainAxis="center"
                                        crossAxis="center"
                                        css={{ pointerEvents: "none" }}>
                                        <ForEach
                                            itemOf={fileUploads}
                                            beginWith={<VerticalDivider S />}
                                            divider={<VerticalDivider XS />}
                                            endWith={<VerticalDivider S />}>
                                            {upload => (
                                                <UploadProgress
                                                    fileSize={upload.file.size}
                                                    originalFilename={
                                                        upload.file.name
                                                    }
                                                    storageItemId={
                                                        upload.storageItemId
                                                    }
                                                    progress={upload.progress}
                                                />
                                            )}
                                        </ForEach>
                                    </FlexColumn>
                                </Conditional>
                            </LayoutCell>
                        );
                    }}
                />
                <Conditional
                    when={gridTemplateAreas.includes("image-preview")}
                    render={() => {
                        return (
                            <LayoutCell
                                css={{
                                    gridArea: "image-preview",
                                    padding: 10,
                                }}>
                                <Conditional when={!!imageToPreview}>
                                    <div
                                        css={{
                                            width: "100%",
                                            height: "100%",
                                            position: "relative",
                                        }}>
                                        <Conditional
                                            when={!!props.options?.allowRemove}>
                                            <FlexRow
                                                css={{
                                                    position: "absolute",
                                                    zIndex: 99,
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                }}
                                                padding={{ all: 10 }}
                                                mainAxis="flex-end">
                                                <div
                                                    onClick={() => {
                                                        field.setValue(
                                                            undefined,
                                                        );
                                                        field.setDirty(
                                                            !!initialValue,
                                                        );
                                                        field.setTouched(
                                                            !!initialValue,
                                                        );
                                                        setStorageItemId(
                                                            undefined,
                                                        );
                                                    }}
                                                    css={{
                                                        padding: 2,
                                                        borderRadius: 3000,
                                                        backgroundColor:
                                                            theme.colors.white
                                                                .asString,
                                                    }}>
                                                    <Icon
                                                        icon={faTimes}
                                                        fixedWidth
                                                        size={1 as RemSize}
                                                        color={
                                                            theme.colors.black
                                                        }
                                                    />
                                                </div>
                                            </FlexRow>
                                        </Conditional>
                                        <FlexColumn
                                            fillParent
                                            mainAxis="center"
                                            crossAxis="center"
                                            css={{
                                                position: "relative",
                                                pointerEvents: "none",
                                            }}>
                                            <SquareImage
                                                imageSource={imageToPreview}
                                                title={
                                                    !imageToPreview ||
                                                    !(
                                                        "originalFilename" in
                                                        imageToPreview
                                                    ) ||
                                                    !imageToPreview.originalFilename
                                                        ? translation({
                                                              sv: "(bild utan namn)",
                                                              en: "(image without name)",
                                                          })
                                                        : imageToPreview.originalFilename
                                                }
                                                radius={theme.radius}
                                            />
                                        </FlexColumn>
                                    </div>
                                </Conditional>
                            </LayoutCell>
                        );
                    }}
                />
            </LayoutGrid>
        </FlexColumn>
    );
};

export { Form2ImageUploadSingle };
export type { Props as Form2ImageUploadSingleProps };
