import { useAccessKeyIdFromUrl } from "@@/receipts/use-access-key-id-from-url";
import { useResource } from "@@/resources/resources/use-resources";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useDateTimeFormatter } from "@@/shared/use-date-time-formatter";
import { useTheme } from "@emotion/react";
import {
    translation,
    type ResourceReservation,
    type ResourceReservationRequest,
} from "@towni/common";

type Props = {
    readonly resourceReservation:
        | ResourceReservation
        | ResourceReservationRequest;
    readonly displayTime: boolean;
    readonly index?: number;
};

const Receipt_Accommodation_ResourceReservationRequestRow = (props: Props) => {
    const theme = useTheme();
    const dateTimeFormatter = useDateTimeFormatter();
    const ak = useAccessKeyIdFromUrl();
    const [resource, _] = useResource(props.resourceReservation.resourceId, ak);

    if (!resource) return null;

    return (
        <FlexRow padding={{ leftRight: 20, topBottom: 15 }}>
            <FlexColumn width="100%">
                <TextBox
                    //   weight="900"
                    text={translation({
                        sv: "Reservation",
                        en: "Reservation",
                    })}
                    dataTestId={`bd-resource-title-${props.index ?? 0}`}
                    color={theme.colors.white.main.light50}
                />
                <VerticalDivider XXS />

                <FlexColumn>
                    {resource ? (
                        <FlexRow wrap="wrap">
                            <HorizontalDivider />
                            <TextBox
                                text={"-"}
                                color={theme.colors.black.light30}
                                weight="800"
                                size={1.1}
                            />
                            <HorizontalDivider />
                            <TextBox
                                size={1.1}
                                weight="800"
                                text={resource.meta.title}
                            />

                            <Conditional when={props.displayTime}>
                                <HorizontalDivider />
                                <TextBox
                                    weight="800"
                                    color={theme.colors.white.main.light50}
                                    text={dateTimeFormatter.formatDayDateAndTimeMedium(
                                        props.resourceReservation.timeRange,
                                    )}
                                />
                            </Conditional>
                        </FlexRow>
                    ) : null}
                </FlexColumn>
            </FlexColumn>
        </FlexRow>
    );
};

export { Receipt_Accommodation_ResourceReservationRequestRow };
