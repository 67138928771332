import { WritableAtom } from "jotai";
import { useHydrateAtoms } from "jotai/utils";
import { ReactNode, useMemo } from "react";

type HydrateAtomsValueMap = Map<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    WritableAtom<unknown, [any], unknown>,
    unknown
>;

function HydrateAtoms({
    atomValues,
    children,
}: {
    atomValues: HydrateAtomsValueMap;
    children: ReactNode;
}) {
    useHydrateAtoms(atomValues);
    return children;
}

export { HydrateAtoms };
export type { HydrateAtomsValueMap };
