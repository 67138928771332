import { useTheme } from "@emotion/react";
import {
    autoPlacement,
    autoUpdate,
    FloatingPortal,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
} from "@floating-ui/react";
import { generateId } from "@towni/common";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

type FloatingBoxActions = {
    readonly close: () => void;
    readonly open: () => void;
    readonly toggle: () => void;
};

const FloatingBox = (props: {
    readonly isOpen?: boolean;
    readonly onOpen?: () => void;
    readonly onClose?: () => void;
    readonly trigger: React.ComponentType<FloatingBoxActions>;
    readonly content: React.ComponentType<FloatingBoxActions>;
}) => {
    const theme = useTheme();
    const [instanceId] = useState(() => generateId());
    const [isOpen, setIsOpen] = useState(props.isOpen);
    useEffect(() => {
        if (typeof props.isOpen === "undefined") return;
        setIsOpen(props.isOpen);
    }, [props.isOpen]);

    const close = useCallback(() => setIsOpen(false), [setIsOpen]);
    const open = useCallback(() => setIsOpen(true), [setIsOpen]);
    const toggle = useCallback(
        (value?: boolean) => setIsOpen(prev => value ?? !prev),
        [setIsOpen],
    );
    const actions = useMemo(
        () => ({ close, open, toggle }),
        [close, open, toggle],
    );

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            shift({
                crossAxis: true,
                mainAxis: true,
            }),
            autoPlacement({
                allowedPlacements: [
                    "bottom-start",
                    "bottom-end",
                    "top-start",
                    "top-end",
                ],
            }),
        ],
    });
    const click = useClick(context);
    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        dismiss,
    ]);

    const onOpenRef = useRef(props.onOpen);
    const onCloseRef = useRef(props.onClose);
    onOpenRef.current = props.onOpen;
    onCloseRef.current = props.onClose;

    useEffect(() => {
        if (isOpen) onOpenRef.current?.();
        else onCloseRef.current?.();
    }, [isOpen]);

    const Trigger = props.trigger;
    const Content = props.content;

    return (
        <>
            <div ref={refs.setReference} {...getReferenceProps()}>
                <Trigger key={`t_${instanceId}`} {...actions} />
            </div>
            {!isOpen ? null : (
                <FloatingPortal>
                    <div
                        ref={refs.setFloating}
                        style={{
                            ...floatingStyles,
                            overflow: "hidden",
                        }}
                        {...getFloatingProps()}
                        css={{
                            backgroundColor: "white",
                            borderRadius: 10,
                            willChange: "filter",
                            transition: "opacity 0.2s ease-in-out",
                            filter: `drop-shadow(5px 5px 10px ${theme.colors.black.light75.asString})`,
                            zIndex: 33,
                        }}>
                        <Content key={`c_${instanceId}`} {...actions} />
                    </div>
                </FloatingPortal>
            )}
        </>
    );
};

export { FloatingBox };
export type { FloatingBoxActions };
