import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { useBrandingContext } from "@@/routes/shared/branding-context";
import { useBrandingBackgroundCss } from "@@/routes/shared/default-branding";
import { Button_v2 } from "@@/shared/buttons_v2/button";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { backendHost } from "@@/shared/fetch-client";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Icon } from "@@/shared/icons/icon";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { WideScreenWrapper } from "@@/shared/widescreen-wrapper";
import { ColorThemeProvider } from "@@/styles/app-theme-provider";
import { ClassNames, useTheme } from "@emotion/react";
import { faPrint } from "@fortawesome/pro-regular-svg-icons";
import { faChevronDoubleRight } from "@fortawesome/pro-solid-svg-icons";
import { RemSize, Ticket, translation } from "@towni/common";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { TicketGiftCardForCustomerPrintView } from "./giftcard-for-customer-print-view";
import { ShareTicket } from "./share/share-ticket";
import { TicketForCustomer } from "./ticket-for-customer";
import { TicketVoucherForCustomerPrintView } from "./ticket-for-customer-print-view";

type Props = {
    tickets: Ticket[];
};

const TicketsForCustomer = ({ tickets }: Props) => {
    const theme = useTheme();
    const _isDesktop = usePageBreakpoint({ when: "🖥" });
    const navigate = useNavigate();

    const [provider] = useProvider(tickets[0]?.providerId);
    const { branding, setProviderId } = useBrandingContext();

    useEffect(() => {
        setProviderId(provider?._id);
    });

    const printRef = React.useRef(null);
    const backgroundCss = useBrandingBackgroundCss(
        branding?.receipt.background,
    );
    const peripheralBackgroundCss = useBrandingBackgroundCss(
        branding?.receipt.peripheralBackground,
    );

    const colorTheme = branding?.colorThemes.light || theme.colors;

    const onlyGiftcards = tickets.every(t => t.type === "GIFTCARD");

    return (
        <ColorThemeProvider colorTheme={branding?.colorThemes.light}>
            <WideScreenWrapper css={peripheralBackgroundCss}>
                <FlexColumn
                    grow={1}
                    shrink={0}
                    mainAxis="flex-start"
                    crossAxis="center"
                    overflowY="scroll"
                    css={backgroundCss}
                    color={theme.colors.primary.text}>
                    <VerticalDivider XL />
                    <TextBox
                        color={theme.colors.white}
                        text={
                            tickets.length > 1
                                ? translation({
                                      sv: `Dina ${
                                          onlyGiftcards
                                              ? "presentkort"
                                              : "värdebevis"
                                      } (${tickets.length} st)`,
                                      en: `Your ${
                                          onlyGiftcards
                                              ? "giftcards"
                                              : "vouchers"
                                      } (${tickets.length} items)`,
                                  })
                                : translation({
                                      sv: `Ditt ${
                                          onlyGiftcards
                                              ? "presentkort"
                                              : "värdebevis"
                                      }`,
                                      en: `Your ${
                                          onlyGiftcards ? "giftcard" : "voucher"
                                      }`,
                                  })
                        }
                        size={1}
                        css={{
                            willChange: "filter",
                            filter: `drop-shadow(
                        0px 4px 10px
                            ${theme.colors.black.value.withAlpha(0.25).asString}
                    );`,
                        }}
                    />
                    <VerticalDivider M />
                    {tickets.map((ticket, _index) => {
                        return (
                            <React.Fragment key={ticket._id}>
                                <FlexColumn
                                    padding={{ all: 40 }}
                                    shrink={0}
                                    radius={_isDesktop ? 20 : 0}
                                    css={{
                                        backgroundColor:
                                            theme.colors.white.main.value.withAlpha(
                                                0.1,
                                            ).asString,
                                    }}>
                                    <TicketForCustomer ticket={ticket} />
                                    <VerticalDivider M />
                                    <ShareTicket ticket={ticket} />
                                </FlexColumn>
                                <VerticalDivider size={"XL"} />
                            </React.Fragment>
                        );
                    })}
                    <VerticalDivider S />
                    <Conditional
                        when={!tickets.some(t => t.type === "GIFTCARD")}>
                        <ClassNames>
                            {content => (
                                <ReactToPrint
                                    copyStyles={true}
                                    bodyClass={content.css({
                                        overflow: "visible",
                                        height: "auto",
                                        width: "100%",
                                        backgroundColor: "white",
                                        boxSizing: "border-box",
                                        justifyContent: "stretch",
                                        alignItems: "center",
                                        display: "flex",
                                        fontFamily: theme.fontFamily,
                                        fontSize: theme.sizes.base,
                                        letterSpacing: "0.01em",
                                        zoom: 0.9,
                                        userSelect: "none",
                                    })}
                                    trigger={() => (
                                        <Button_v2
                                            padding={{
                                                topBottom: 15,
                                                leftRight: 25,
                                            }}
                                            colorSet={{
                                                text: colorTheme.primary.text,
                                                main: colorTheme.primary.main,
                                                border: colorTheme.primary.main,
                                            }}>
                                            <FlexRow>
                                                <Icon
                                                    icon={faPrint}
                                                    color={colorTheme.primary.text.withAlpha(
                                                        0.8,
                                                    )}
                                                />
                                                <HorizontalDivider />
                                                <TextBox
                                                    text={translation({
                                                        sv: "Skriv ut",
                                                        en: "Print",
                                                    })}
                                                />
                                            </FlexRow>
                                        </Button_v2>
                                    )}
                                    content={() => printRef.current}
                                />
                            )}
                        </ClassNames>
                    </Conditional>
                    <VerticalDivider XXL />
                    <VerticalDivider M />
                    <FlexRow fillParentWidth mainAxis="center">
                        <img
                            src={`${backendHost}/public/storage/towni-brand/logos/towni-logo-hz-white-on-dark-medium.png`}
                            style={{ display: "block", width: 80 }}
                        />
                    </FlexRow>
                    <VerticalDivider M />
                    <FlexRow>
                        <TextBox
                            onClick={() => navigate("")}
                            color={theme.colors.white}
                            text={translation({
                                sv: "Upptäck mer lokalt!",
                                en: "Discover more locally!",
                            })}
                            size={1}
                            css={{
                                willChange: "filter",
                                filter: `drop-shadow(0px 4px 10px ${
                                    theme.colors.black.value.withAlpha(0.25)
                                        .asString
                                })`,
                            }}
                        />
                        <HorizontalDivider XS />
                        <Icon
                            icon={faChevronDoubleRight}
                            size={0.7 as RemSize}
                            color={theme.colors.white}
                            style={{
                                right: 0,
                                paddingRight: 5,
                            }}
                        />
                    </FlexRow>
                    <VerticalDivider XXL />
                    <VerticalDivider XXL />
                </FlexColumn>

                <div style={{ display: "none" }}>
                    {/* Print view */}
                    <div style={{ width: "100%" }} ref={printRef}>
                        <ForEach
                            itemOf={tickets.filter(
                                ticket => ticket.status === "ACTIVE",
                            )}>
                            {ticket =>
                                ticket.type === "GIFTCARD" ? (
                                    <TicketGiftCardForCustomerPrintView
                                        key={ticket._id}
                                        ticket={ticket}
                                    />
                                ) : (
                                    <TicketVoucherForCustomerPrintView
                                        key={ticket._id}
                                        ticket={ticket}
                                        showBarCode={true}
                                    />
                                )
                            }
                        </ForEach>
                    </div>
                </div>
            </WideScreenWrapper>
        </ColorThemeProvider>
    );
};

export { TicketsForCustomer };
