import { useWizardDispatch } from "@@/pages/wizard-context";
import { VerticalDivider } from "@@/shared/dividers";
import { apiFetchClient } from "@@/shared/fetch-client";
import { FlexColumn } from "@@/shared/flex-containers";
import { CircleSpinner } from "@@/shared/spinners/circle-spinner";
import { TextBox } from "@@/shared/text";
import { useMountEffect } from "@@/shared/use-mount-effect";
import { useToast } from "@@/toasts/context/toast-context";
import {
    InitiateFreePaymentCommand,
    initiateFreePaymentCommandFactory,
    InitiateFreePaymentCommandResponse,
    isApiError,
} from "@towni/common";
import React from "react";
import { usePaymentDetailsContext } from "../payment-details.context";

const FreePaymentForm = () => {
    const toast = useToast();
    const { finish } = useWizardDispatch();
    const { paymentDetails, paymentFinished } = usePaymentDetailsContext(
        context => ({
            paymentDetails: context.paymentDetails,
            paymentFinished: context.paymentFinished,
        }),
    );

    const [status, setStatus] = React.useState<"LOADING" | "DONE" | "ERROR">(
        "LOADING",
    );
    const [submitting, setSubmitting] = React.useState(false);
    const createPaymentIntent = React.useCallback(
        async (params: InitiateFreePaymentCommand) => {
            setStatus("LOADING");
            const intentData = await apiFetchClient
                .post<
                    InitiateFreePaymentCommand,
                    InitiateFreePaymentCommandResponse
                >({
                    route: `/payments/free/intents`,
                    body: params,
                })
                .then(() => {
                    setStatus("DONE");
                    finish();
                })
                .catch(error => {
                    setStatus("ERROR");
                    if (isApiError(error)) {
                        toast.fromError(error);
                        return;
                    }
                });

            return intentData;
        },
        [],
    );
    useMountEffect(() => {
        if (!paymentDetails?.orderGroupId || paymentFinished) {
            return;
        }
        if (!submitting) {
            setSubmitting(true);
            createPaymentIntent(
                initiateFreePaymentCommandFactory({
                    orderGroupId: paymentDetails.orderGroupId,
                    delay: false,
                }),
            ).catch(error => {
                throw error;
            });
        }
    });

    if (status === "ERROR")
        return (
            <FlexColumn
                fillParentWidth
                crossAxis="center"
                padding={{ all: 40 }}>
                <TextBox text="Nånting gick fel" />
                <VerticalDivider ML />
            </FlexColumn>
        );

    return (
        <FlexColumn fillParentWidth crossAxis="center" padding={{ all: 40 }}>
            <TextBox text="Arbetar på det 🙂..." />
            <VerticalDivider ML />
            <CircleSpinner size="3XL" />
        </FlexColumn>
    );
};

export { FreePaymentForm };
