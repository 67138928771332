import { useStorageItemImageReference } from "@@/storage-items/queries/use-storage-item-reference";
import { css, SerializedStyles } from "@emotion/react";
import {
    BackgroundStyle,
    colorAsString,
    gradientToCssValue,
    isStorageItemImageId,
    LinearGradient,
    LinearGradientSimple,
    StorageItemImageId,
    whiteColor,
} from "@towni/common";
import { useCallback, useState } from "react";
import { useImageUrl } from "../image-url";

const linearGradientCss = (
    gradient?: LinearGradientSimple | LinearGradient,
) => {
    if (typeof gradient === "undefined") return "";
    return `background: ${gradientToCssValue(gradient)};`;
};

const useBackgroundStyleToCss = () => {
    const getImageUrl = useImageUrl();
    const [storageItemImageId, setStorageItemImageId] =
        useState<StorageItemImageId>();

    const [storageItemImageReference] =
        useStorageItemImageReference(storageItemImageId);

    const backgroundStyleToCss = useCallback(
        (
            background: BackgroundStyle,
            fallbackColor = whiteColor,
        ): SerializedStyles => {
            if (isStorageItemImageId(background.imageReference)) {
                setStorageItemImageId(background.imageReference);
            }
            const imageItem = isStorageItemImageId(background.imageReference)
                ? storageItemImageReference
                : background.imageReference;
            const backgroundUrl = background.imageUrl
                ? background.imageUrl
                : background.imageReference
                  ? getImageUrl(imageItem, {
                        format: "jpg",
                        ...(background.imageSize
                            ? { ...background.imageSize }
                            : {}),
                    })
                  : undefined;

            const result = css([
                {
                    backgroundColor: colorAsString(
                        background.color ?? fallbackColor.asString,
                    ),
                    backgroundImage: backgroundUrl
                        ? `url(${backgroundUrl})`
                        : undefined,
                    backgroundSize: background.fit?.toLowerCase() || "unset",
                    backgroundPosition:
                        background.fit?.toLowerCase().trim() === "cover"
                            ? "50% 50%"
                            : "unset",
                    backgroundRepeat:
                        background.repeat?.toLowerCase() || "unset",
                    borderRadius: background.borderRadius,
                },
                linearGradientCss(background.linearGradient),
            ]);

            return result;
        },
        [getImageUrl, storageItemImageReference],
    );
    return backgroundStyleToCss;
};

export { useBackgroundStyleToCss };
