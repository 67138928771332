import { Form } from "@@/shared/form/form.zustand-store";
import {
    IsoAndUnixTimestamp,
    Translatable,
    isoAndUnixFactory,
    isoAndUnixZodSchema,
    now,
    translation,
} from "@towni/common";
import { ReactNode } from "react";
import { z } from "zod";

type Props = {
    readonly initialDate?: IsoAndUnixTimestamp | Date;
    readonly onChange: (dateTime: IsoAndUnixTimestamp | undefined) => void;
    readonly children?: ReactNode;
    readonly label?: Translatable;
    readonly disabled?: boolean;
    readonly className?: string;
};

const DatePicker = (props: Props) => {
    return (
        <Form
            formParams={{
                clearOnUnmount: true,
                initializeIfNotExists: {
                    zodObject: z.object({
                        dateTime: isoAndUnixZodSchema,
                    }),
                    initialState: {
                        dateTime: props.initialDate
                            ? isoAndUnixFactory(props.initialDate)
                            : now(),
                    },
                },
            }}>
            {({ FormComponents }) => (
                <FormComponents.DatePicker
                    fieldId="date"
                    label={
                        props.label ??
                        translation({
                            sv: "Välj ett datum",
                            en: "Select a date",
                        })
                    }
                    disabled={props.disabled}
                    getter={state => state.dateTime}
                    className={props.className}
                    setter={(data, newValue) => {
                        data.dateTime = newValue;
                        props.onChange(newValue);
                    }}
                />
            )}
        </Form>
    );
};

export { DatePicker };
