import { useFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, UseQueryResult, useQuery } from "@tanstack/react-query";
import {
    DurationInSeconds,
    GetSingleResponse,
    PayRequestId,
    PayRequestStatusProjection,
    SECONDS,
} from "@towni/common";

const usePayRequestStatusQuery = (
    payRequestId: PayRequestId | undefined,
    refetchInterval: DurationInSeconds = 0 as DurationInSeconds,
): UseQueryResult<GetSingleResponse<PayRequestStatusProjection>> => {
    const fetchClient = useFetchClient();
    const fetchPayRequest: QueryFunction<
        GetSingleResponse<PayRequestStatusProjection>
    > = async ({ signal }) => {
        return await fetchClient.get<
            GetSingleResponse<PayRequestStatusProjection>
        >({
            route: `/payments/pay-requests/${encodeURIComponent(
                payRequestId ?? "",
            )}/status`,
            customConfig: {
                signal,
            },
        });
    };
    return useQuery({
        queryKey: ["pay-requests", payRequestId, "status"],
        queryFn: fetchPayRequest,
        enabled: !!payRequestId,
        refetchInterval: refetchInterval ? refetchInterval * SECONDS : false,
        refetchIntervalInBackground: false,
    });
};

export { usePayRequestStatusQuery };
