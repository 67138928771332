import { DateRange, isSameDateRangeZoned } from "@towni/common";
import { useEffect, useRef } from "react";
import { useSelectedDateRangeContext } from "./selected-date-range.context";

/**
 * If the selection is updated from the parents side
 * this component will update the selected date range
 * within the context of the date selection vt component
 */
const SelectedDateRangeUpdater = (props: {
    selection: DateRange | undefined;
    timeZone: string;
}) => {
    const { selectedDateRange, setSelectedDateRange } =
        useSelectedDateRangeContext();
    const selectedDateRangeRef = useRef(selectedDateRange);
    selectedDateRangeRef.current = selectedDateRange;

    useEffect(() => {
        const newSelection = props.selection;
        if (typeof newSelection === "undefined") {
            if (typeof selectedDateRangeRef.current === "undefined") return;
            setSelectedDateRange(undefined);
            return;
        }
        if (typeof selectedDateRangeRef.current === "undefined") {
            setSelectedDateRange(newSelection);
            return;
        }
        if (
            isSameDateRangeZoned(
                selectedDateRangeRef.current,
                newSelection,
                props.timeZone,
            )
        )
            return;
        setSelectedDateRange(props.selection);
    }, [props.selection, props.timeZone, setSelectedDateRange]);
    return null;
};

export { SelectedDateRangeUpdater };
