import { getBlockContentTargetUrl } from "@@/backoffice/for-providers/pages/blocks/content-blocks/shared/grid-views/shared/get-block-content-target-url";
import { inactiveCssWithoutHeightSetting } from "@@/backoffice/for-providers/pages/blocks/content-blocks/shared/grid-views/shared/inactive-css";
import { usePageViewContext } from "@@/backoffice/for-providers/pages/editor/page-view-context";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { HzScrollContainer } from "@@/shared/hz-scroll-container/hz-scroll-container";
import { Link } from "@@/shared/link";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { BlockContent, ContentAutoPageBlock, ProviderId } from "@towni/common";
import { useMemo } from "react";

type Props = {
    readonly block: ContentAutoPageBlock;
    readonly content: BlockContent[];
    readonly width?: React.CSSProperties["width"];
    readonly className?: string;
};

const ContentPillView = (props: Props) => {
    const { block } = props;

    switch (block.layout) {
        case "hzScroll":
            return <ContentPillsHzScroll {...props} />;
        default:
            return <ContentPillsFlexBoxWrappedCentered {...props} />;
    }
};

const ContentPillsFlexBoxWrappedCentered = (props: Props) => {
    const isSmallScreen = usePageBreakpoint({ when: "SMALL_SCREEN" });
    const { width, block, content } = props;
    const {
        pageInnerWidth: maxWidth,
        defaultGridGap,
        // defaultTextShadow,
        providerId,
    } = usePageViewContext();

    return (
        <FlexRow
            wrap="wrap"
            mainAxis="center"
            crossAxis="center"
            wrapMargin={defaultGridGap}
            css={{
                maxWidth: maxWidth * 0.85,
                width: width ?? "100%",
                padding: isSmallScreen ? 20 : 40,
                paddingTop: 10,
                paddingBottom: 30,
            }}>
            <ForEach itemOf={content} getKey={item => item._id}>
                {item => (
                    <ContentPill
                        block={block}
                        content={item}
                        providerId={providerId}
                        pillMaxWidth={maxWidth * 0.85}
                    />
                )}
            </ForEach>
        </FlexRow>
    );
};

const ContentPillsHzScroll = (props: Props) => {
    const { block, content } = props;
    const { defaultGridGap, providerId } = usePageViewContext();

    const items = useMemo(
        () =>
            content.map(item => (
                <ContentPill
                    key={item._id}
                    block={block}
                    content={item}
                    providerId={providerId}
                />
            )),
        [content, block, providerId],
    );

    return (
        <HzScrollContainer
            gap={{
                startGap: defaultGridGap,
                dividingGap: defaultGridGap,
                endGap: defaultGridGap,
                bottomGap: defaultGridGap,
            }}
            items={items}
        />
    );
};

const ContentPill = (props: {
    block: ContentAutoPageBlock;
    content: BlockContent;
    providerId: ProviderId | undefined;
    pillMaxWidth?: number;
    className?: string;
}) => {
    const theme = useTheme();
    const { block, content: item, providerId } = props;

    const textSize = ((): number => {
        const mediumSize = theme.sizes.base;
        switch (block.preferredContentSize) {
            case "S":
                return mediumSize * 0.925;
            case "L":
                return mediumSize * 1.25;
            case "M":
            default:
                return mediumSize;
        }
    })();

    return (
        <Link
            to={getBlockContentTargetUrl(item, providerId)}
            title={item.title}
            disabled={item.inactive}
            css={{
                cursor: "pointer",
                width: props.pillMaxWidth ? "fit-content" : undefined,
            }}>
            <FlexColumn
                key={item._id}
                crossAxis="center"
                className={props.className}
                css={[
                    {
                        borderRadius: 3000,
                        textAlign: "center",
                        backgroundColor: theme.colors.primary.light.asString,
                        color: theme.colors.primary.asString,
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: theme.colors.primary.light.asString,
                        padding: 15,
                        paddingBottom: 10,
                        paddingTop: 10,
                        width: props.pillMaxWidth ? "100%" : undefined,
                        maxWidth: props.pillMaxWidth,
                    },
                    ...(item.inactive ? [inactiveCssWithoutHeightSetting] : []),
                ]}>
                <TextBox
                    text={item.title}
                    css={{
                        fontSize: `${textSize}px`,
                    }}
                    lineClamp={1}
                />
            </FlexColumn>
        </Link>
    );
};

export { ContentPillView };
