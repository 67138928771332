import { useTheme } from "@emotion/react";
import {
    BackgroundStyle,
    HexColor,
    Padding,
    RemSize,
    SizeName,
    Translatable,
} from "@towni/common";
import * as React from "react";
import { VerticalDivider } from "../dividers";
import { FlexColumn } from "../flex-containers";
import { MouseEventHandler } from "../mouse-event-handler";
import { TextBox } from "../text/text-box";
import { useDimensions } from "./card-size";

type Props = {
    readonly size: SizeName | RemSize;
    readonly background?: BackgroundStyle;
    readonly className?: string;
    readonly title?: Translatable;
    /**
     * defaults to 6px
     */
    readonly onClick?: MouseEventHandler;
    readonly padding?: Padding;
    readonly "data-testid"?: string;
    readonly children?: React.ReactNode;
    readonly textColor?: HexColor;
};

const SectionAreaCircle = (props: Props) => {
    const theme = useTheme();
    const getDimensions = useDimensions();

    const dimensions = getDimensions({
        aspectRatio: "1:1",
        size: props.size,
    });
    const textColor = props.textColor ?? theme.colors.black.light30.asString;

    // Width & Height
    const width =
        typeof dimensions.width === "number"
            ? `${dimensions.width * 0.75}px`
            : `calc(${dimensions.width} * 0.75)`;
    const height =
        typeof dimensions.height === "number"
            ? `${dimensions.height * 0.75}px`
            : `calc(${dimensions.height} * 0.75)`;

    return (
        <FlexColumn
            mainAxis="flex-start"
            crossAxis="center"
            data-testid={props["data-testid"]}
            padding={props.padding}>
            <FlexColumn
                width={width}
                height={height}
                shrink={0}
                overflow="hidden"
                className={props.className}
                radius={3000}
                tag="AREA_CIRCLE"
                onClick={props.onClick}
                mainAxis="flex-start"
                background={{
                    color: theme.colors.default.background,
                    ...(props.background ? props.background : {}),
                    imageSize: {
                        imageWidth:
                            props.background?.imageSize?.imageWidth ??
                            theme.sizes.imageSizes.large,
                        imageHeight: props.background?.fit
                            ? undefined
                            : props.background?.imageSize?.imageHeight,
                    },
                }}>
                {props.children}
            </FlexColumn>
            {props.title ? (
                <>
                    <VerticalDivider XS />
                    <TextBox
                        text={props.title}
                        size="XXS"
                        align="center"
                        color={textColor}
                    />
                </>
            ) : null}
        </FlexColumn>
    );
};

export { SectionAreaCircle };
export type { Props as SectionAreaCircleProps };
