import {
    DateRange,
    dateRangeFactory,
    IsoAndUnixTimestamp,
} from "@towni/common";
import { createContext, useCallback, useContext, useMemo, useRef } from "react";
import { useDateSelectabilityMapContext } from "./date-selectability-map.context";
import { useSelectedDateRangeContext } from "./selected-date-range.context";

/**
 * Handles the logic for toggling dates in the vertical date selection component
 * provides a toggle function that can be used to toggle dates selection status (if possible)
 */
const DateToggleContext = createContext<
    | {
          toggle: (date: IsoAndUnixTimestamp) => void;
      }
    | undefined
>(undefined);
const DateToggleContextProvider = (props: {
    readonly onSelect: (selected: DateRange | undefined) => void;
    readonly children: React.ReactNode;
}) => {
    const onSelect = props.onSelect;
    const { selectabilityMap } = useDateSelectabilityMapContext();
    const { selectedDateRange, setSelectedDateRange: _setSelectedDateRange } =
        useSelectedDateRangeContext();

    const setSelectedDateRange = useCallback(
        (selected: DateRange | undefined) => {
            _setSelectedDateRange(selected);
            onSelect(selected);
        },
        [_setSelectedDateRange, onSelect],
    );

    // Toggle function doesn't need to rerender
    // So lets store the latest values of the in refs so they are available
    // in the toggle function when it's called
    const selectabilityMapRef = useRef(selectabilityMap);
    selectabilityMapRef.current = selectabilityMap;
    const selectedDateRangeRef = useRef(selectedDateRange);
    selectedDateRangeRef.current = selectedDateRange;
    const setSelectedDateRangeRef = useRef(setSelectedDateRange);
    setSelectedDateRangeRef.current = setSelectedDateRange;

    const toggle = useCallback((date: IsoAndUnixTimestamp) => {
        const _selectabilityMap = selectabilityMapRef.current;
        const _selectedDateRange = selectedDateRangeRef.current;
        const _setSelectedDateRange = setSelectedDateRangeRef.current;

        // If nothing is selected, select the date if possible
        if (!_selectedDateRange) {
            _setSelectedDateRange(dateRangeFactory(date));
            return;
        }

        // Get date metadata before we do anything else
        const currentSelectedDateRangeStartMeta = _selectabilityMap.get(
            _selectedDateRange.start.iso,
        );
        if (
            typeof currentSelectedDateRangeStartMeta?.asStartOfRange ===
            "undefined"
        )
            throw new Error("Cannot be undefined");
        const currentSelectedDateRangeEndMeta = _selectabilityMap.get(
            _selectedDateRange.end.iso,
        );
        if (
            typeof currentSelectedDateRangeEndMeta?.asStartOfRange ===
            "undefined"
        ) {
            throw new Error("Cannot be undefined");
        }
        const clickedDateMeta = _selectabilityMap.get(date.iso);
        if (typeof clickedDateMeta === "undefined")
            throw new Error("Cannot be undefined");

        // If date clicked is the same as the current selected start date
        // toggle the selection off
        if (date.iso === _selectedDateRange.start.iso) {
            _setSelectedDateRange(undefined);
            return;
        }

        // If date clicked is the same as the current selected end date
        if (date.iso === _selectedDateRange.end.iso) {
            // we'll just toggle the selection off
            _setSelectedDateRange(undefined);
            return;
        }

        // If date clicked is before the selected date range...
        if (date.unix < _selectedDateRange.start.unix) {
            // ... and the clicked date can be used as a start of a range
            if (clickedDateMeta.asStartOfRange) {
                // ...check if the current selected date range end is possible to use as
                // end date of a range starting at the clicked date
                if (
                    currentSelectedDateRangeEndMeta.asEndOfRange.withStartAt?.has(
                        date.iso,
                    )
                ) {
                    _setSelectedDateRange(
                        dateRangeFactory(date, _selectedDateRange.end),
                    );
                    return;
                }

                // Else we'll use it as a new start
                _setSelectedDateRange(dateRangeFactory(date));
                return;
            }

            // The clicked date can't be used as a start of a range
            // So we'll just toggle the original selection off.. perhaps
            _setSelectedDateRange(undefined);
            return;
        }

        // If date clicked is after the selected start date
        // (only possibility now)
        // Can the clicked date be used as an end of the range?
        if (
            date.unix > _selectedDateRange.start.unix &&
            clickedDateMeta.asEndOfRange.withStartAt?.has(
                _selectedDateRange.start.iso,
            )
        ) {
            _setSelectedDateRange(
                dateRangeFactory(_selectedDateRange.start, date),
            );
            return;
        }

        // If date clicked is in the middle of the range
        if (date.unix < _selectedDateRange.end.unix) {
            // but we already now it can't be used as an end of the range
            // then we'll just start a new selection with the selected date as start date
            _setSelectedDateRange(dateRangeFactory(date));
            return;
        }

        // If date clicked is after the selected end date of range
        // and we already know it can't be used as an end of the range (since we've checked)
        // we'll just start a new selection with the selected date as start date
        if (date.unix > _selectedDateRange.end.unix) {
            _setSelectedDateRange(dateRangeFactory(date));
            return;
        }

        // Well, don't know what to do with this date
        // let's just ignore it until we figure out what we want to do with it
        return;
    }, []);
    const state = useMemo(() => ({ toggle }), [toggle]);
    return (
        <DateToggleContext.Provider value={state}>
            {props.children}
        </DateToggleContext.Provider>
    );
};

const useDateToggleContext = () => {
    const context = useContext(DateToggleContext);
    if (!context) throw new Error("No DateToggleContextProvider found");
    return context;
};

export { DateToggleContextProvider, useDateToggleContext };
