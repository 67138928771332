import { useCompanyDetails } from "@@/backoffice/dashboard/providers/tabs/company-details/company-details-query/use-company-details-for-provider";
import { FooterPageBlockProvider } from "@@/backoffice/for-providers/pages/blocks/footer-blocks/footer-page-block-view-context";
import { CallToActionButton } from "@@/backoffice/for-providers/pages/blocks/shared/call-to-action-button";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { breakpointRange } from "@@/shared/responsiveness/breakpoint-ranges";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { useStorageItemImageReference } from "@@/storage-items/queries/use-storage-item-reference";
import { ProviderLogoLinkCircle } from "@@/store-fronts/towni/sections/providers/provider-logo-link-circle";
import { useIsTranslatableEmpty } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    FooterPageBlock,
    formatOrgNumber,
    HexColor,
    replacePlaceholderInTranslatable,
} from "@towni/common";
import { useMemo } from "react";
import { usePageViewContext } from "../../editor/page-view-context";

type Props = {
    block: FooterPageBlock;
};

const Title = TextBox;
const Subtitle = TextBox;
const MarkdownText = TextBox;
const Kicker = TextBox;

const FooterPageBlockView = (props: Props) => {
    const theme = useTheme();
    const isTranslatableEmpty = useIsTranslatableEmpty();
    const { pageMaxWidth, pageInnerWidth, providerId } = usePageViewContext();
    const [, providerQuery] = useProvider(providerId);
    const [providerCompanyDetails] = useCompanyDetails(providerId);
    const isMobile = usePageBreakpoint({ when: "📱" });
    const block = props.block;
    const [imageReference] = useStorageItemImageReference(
        block.backgroundImage,
    );

    const textSize = useMemo(() => {
        const mediumSize = theme.sizes.base;
        switch (block.textSize) {
            case "XS":
                return mediumSize * 0.75;
            case "S":
                return mediumSize * 0.825;
            case "L":
                return mediumSize * 1.25;
            case "XL":
                return mediumSize * 1.5;
            case "M":
            default:
                return mediumSize;
        }
    }, [block.textSize, theme.sizes.base]);
    const metaDataTextColor = block.metaDataTextColor ?? ("#88888" as HexColor);

    return (
        <FooterPageBlockProvider block={block}>
            <FlexColumn
                fillParentWidth
                mainAxis={"flex-start"}
                crossAxis={"center"}
                css={{
                    fontSize: textSize,
                }}
                background={
                    imageReference
                        ? {
                              imageReference,
                              fit: "COVER",
                              imageSize: {
                                  imageWidth: isMobile
                                      ? breakpointRange.MOBILE.max
                                      : Math.min(
                                            breakpointRange.DESKTOP.max,
                                            pageMaxWidth,
                                        ),
                              },
                          }
                        : {
                              color:
                                  block.backgroundColor ??
                                  theme.colors.default.background,
                          }
                }>
                <FlexColumn
                    fillParentWidth
                    mainAxis={"flex-start"}
                    crossAxis={"center"}
                    maxWidth={pageInnerWidth}
                    css={{
                        padding: 40,
                    }}>
                    <Kicker
                        text={replacePlaceholderInTranslatable(
                            block.kicker || "",
                            "*",
                            "•",
                        )}
                        case="UPPERCASE"
                        color={block.kickerColor || undefined}
                        css={{
                            fontSize: `1em`,
                        }}
                    />
                    <VerticalDivider S />
                    <Title
                        text={block.title}
                        align="center"
                        color={block.titleColor || undefined}
                        css={{
                            fontWeight: 900,
                            fontSize: `2em`,
                        }}
                    />
                    <VerticalDivider M />
                    <Subtitle
                        text={block.subtitle}
                        align="center"
                        color={block.subtitleColor || undefined}
                        css={{
                            fontSize: `1.125em`,
                        }}
                    />
                    <VerticalDivider XL />

                    {/* CTA BUTTON IF ENABLED */}
                    <Conditional
                        when={
                            block.ctaButtonEnabled &&
                            !isTranslatableEmpty(block.ctaButtonText)
                        }
                        render={() => (
                            <>
                                <CallToActionButton block={block} />
                                <VerticalDivider L />
                            </>
                        )}
                    />

                    <Conditional
                        when={!isTranslatableEmpty(block.markdownText)}
                        render={() => (
                            <>
                                <MarkdownText
                                    text={block.markdownText}
                                    align="center"
                                    color={block.markdownTextColor || undefined}
                                    renderMarkdown
                                    userSelect="text"
                                    css={{
                                        fontSize: `1.125em`,
                                    }}
                                />
                                <VerticalDivider XXL />
                            </>
                        )}
                    />

                    {/* PROVIDER LOGO */}
                    <Conditional
                        when={!!providerQuery.data}
                        render={() => (
                            <>
                                <ProviderLogoLinkCircle
                                    provider={providerQuery.data}
                                    disableLink
                                    hideTitle
                                    size="XS"
                                    textColor={metaDataTextColor}
                                />
                                <VerticalDivider S />
                            </>
                        )}
                    />
                    {/* PROVIDER ADRESS */}
                    {/* <Conditional
                        when={!!providerCompanyDetails?.address}
                        render={() => (
                            <>
                                <FlexRow>
                                    <TextBox
                                        text={formatZipCode(
                                            providerCompanyDetails.address
                                                .zipCode,
                                        )}
                                        color={
                                            theme.colors.black.withAlpha(0.5) ||
                                            undefined
                                        }
                                        css={{
                                            fontSize: `1.125em`,
                                        }}
                                    />
                                    <HorizontalDivider />
                                    <TextBox
                                        text={
                                            providerCompanyDetails.address.city
                                        }
                                        color={
                                            theme.colors.black.withAlpha(0.5) ||
                                            undefined
                                        }
                                        css={{
                                            fontSize: `1.125em`,
                                        }}
                                    />
                                </FlexRow>
                                <VerticalDivider XXS />
                            </>
                        )}
                    /> */}
                    {/* PROVIDER LEGAL NAMN - ORG NO. */}
                    <Conditional
                        when={!!providerCompanyDetails}
                        render={() => (
                            <>
                                <TextBox
                                    text={providerCompanyDetails.name}
                                    color={metaDataTextColor}
                                    css={{
                                        fontSize: `1.125em`,
                                    }}
                                />
                                <VerticalDivider XXS />
                                <Conditional
                                    when={
                                        providerCompanyDetails.name.trim() !==
                                        providerCompanyDetails.legalName
                                            .replace("AB", "")
                                            .trim()
                                    }>
                                    <TextBox
                                        text={providerCompanyDetails.legalName}
                                        color={metaDataTextColor}
                                        css={{
                                            fontSize: `1.125em`,
                                        }}
                                    />
                                    <VerticalDivider XXS />
                                </Conditional>
                                <TextBox
                                    text={{
                                        sv: `Org.nr: ${formatOrgNumber(providerCompanyDetails.organisationNumber)}`,
                                        en: `Reg No: ${formatOrgNumber(providerCompanyDetails.organisationNumber)}`,
                                    }}
                                    color={metaDataTextColor}
                                    css={{
                                        fontSize: `1.125em`,
                                    }}
                                />
                            </>
                        )}
                    />
                    <VerticalDivider XL />
                    <FlexColumn mainAxis="flex-start" crossAxis="center">
                        <TextBox
                            text={"Powered by"}
                            align="center"
                            color={metaDataTextColor}
                            css={{
                                fontSize: `1em`,
                            }}
                        />
                        <TextBox
                            text={"Destio & Towni"}
                            align="center"
                            color={metaDataTextColor}
                            css={{
                                fontSize: `1em`,
                            }}
                        />
                        <TextBox
                            align="center"
                            text={`© ${new Date().getFullYear()}`}
                            color={metaDataTextColor}
                            css={{ fontSize: `1em` }}
                        />
                    </FlexColumn>
                    <VerticalDivider XL />
                </FlexColumn>
            </FlexColumn>
        </FooterPageBlockProvider>
    );
};

export { FooterPageBlockView };
