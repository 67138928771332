import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { css, useTheme } from "@emotion/react";
import { IsoAndUnixTimestamp } from "@towni/common";
import { useMemo } from "react";
import { dateCircleSize } from "./date-circle-size";
import { useDateToggleContext } from "./date-toggle.context";
import { useDateStatus } from "./use-date-status";
import { useDateStyling } from "./use-date-styling";

type Props = {
    date: IsoAndUnixTimestamp; // Should be zoned already
    dayOfMonthIndex: number;
    dayOfWeekIndex: number;
};

const DateCell = (props: Props) => {
    const theme = useTheme();
    const status = useDateStatus(props.date);
    const styling = useDateStyling(status);
    const { toggle } = useDateToggleContext();
    const alternateBackgroundColor = useMemo(
        () =>
            theme.isLightTheme
                ? theme.colors.primary.main.value.withModifications({
                      saturation: 0.6,
                      light: 0.88,
                  })
                : theme.colors.primary.main.value.withModifications({
                      saturation: 0.5,
                      light: 0.75,
                      alpha: 0.75,
                  }),
        [theme.colors.primary.main.value, theme.isLightTheme],
    );

    //Dashed border was a test, but could not get it to work leaving it here for now
    const containerCss = css([
        {
            width: "100%",
            minWidth: dateCircleSize + 2,
            minHeight: dateCircleSize,
            //   borderTop: `1px dashed ${theme.colors.transparent.asString}`,
            //   borderBottom: `1px dashed ${theme.colors.transparent.asString}`,
        },
        (() => {
            switch (status) {
                case "SELECTED__FIRST":
                    return {
                        background: `linear-gradient(
                        90deg,
                        ${theme.colors.transparent.asString} 50%,
                        ${alternateBackgroundColor.asString} 50%
                    )`,
                    };
                case "SELECTED__LAST":
                    return {
                        background: `linear-gradient(
                        90deg,
                        ${alternateBackgroundColor.asString} 50%,
                        ${theme.colors.transparent.asString} 50%
                    )`,
                        //           borderTop: `1px dashed ${mainColor}`,
                        //           borderBottom: `1px dashed ${mainColor}`,
                    };
                case "SELECTED__PART_OF_RANGE":
                    return {
                        background: alternateBackgroundColor.asString,
                    };
                case "SELECTED__SOLO":
                    return {
                        background: theme.colors.transparent.asString,
                        //       borderTop: `1px dashed ${mainColor}`,
                        //       borderBottom: `1px dashed ${mainColor}`,
                    };
                case "SELECTABLE__END_OF_RANGE": {
                    return {
                        //     borderTop: `1px dashed ${mainColor}`,
                        //     borderBottom: `1px dashed ${mainColor}`,
                    };
                }

                default:
                    return undefined;
            }
        })(),
    ]);

    const isSelectableAsPartOfRange = status === "SELECTABLE__PART_OF_RANGE";
    const isUnavailable = status === "UNAVAILABLE";
    const isPassed = status === "PASSED";

    const onClick = (_event: React.MouseEvent) => {
        if (isSelectableAsPartOfRange) return;
        if (isUnavailable) return;
        if (isPassed) return;
        toggle(props.date);
    };

    return (
        <FlexRow
            mainAxis="center"
            crossAxis="center"
            overflow="hidden"
            css={containerCss}>
            <FlexColumn css={styling} onClick={onClick}>
                <a>
                    <div
                        css={{
                            position: "relative",
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor:
                                isUnavailable || isPassed
                                    ? "cursor"
                                    : "pointer",
                            userSelect: "none",
                            lineHeight: "1em",
                            fontSize: "1em",
                            fontWeight: 600,
                        }}>
                        {isUnavailable && (
                            <div
                                css={{
                                    position: "absolute",
                                    width: dateCircleSize * 0.8,
                                    height: 2,
                                    backgroundColor: theme.isLightTheme
                                        ? theme.colors.black.asString
                                        : theme.colors.white.asString,
                                    transform: "rotate(135deg)",
                                    opacity: 0.5,
                                    zIndex: 10,
                                }}
                            />
                        )}
                        {props.dayOfMonthIndex}
                    </div>
                </a>
            </FlexColumn>
        </FlexRow>
    );
};

export { DateCell };
