import { apiFetchClient } from "@@/shared/fetch-client";
import {
    DefinedUseQueryResult,
    InfiniteData,
    QueryKey,
    UseInfiniteQueryResult,
    UseQueryResult,
    // ArrayQueryKey,
    // InfiniteQueryConfig,
    // queryCache,
    useInfiniteQuery,
    useQuery,
} from "@tanstack/react-query";
import {
    AccessKeyId,
    GetInfiniteResponse,
    GetResponse,
    MINUTES,
    createQueryString,
    emptyArrayOf,
    isApiError,
} from "@towni/common";
import { addMilliseconds } from "date-fns";
import { useMemo } from "react";
import { queryClient } from "./queries/query-client";

type ArrayQueryKey = readonly unknown[];
const defaultStaleTime = 3 * MINUTES;
const defaultCacheTime = 30 * MINUTES;

const useFetch = <T extends { _id: string }>(
    mainQueryKey: string,
    mainRoute: string,
    id?: string,
    options?: Partial<QueryConfigLight>,
    accessKeyId?: AccessKeyId,
): readonly [T | undefined, UseQueryResult<T[] | undefined>] => {
    const [items, queryData] = useMotherOfAllFetches<T>({
        queryKey: [mainQueryKey, id ?? "none"] as ArrayQueryKey,
        route: `${mainRoute}/${id}${accessKeyId ? `?ak=${accessKeyId}` : ""}`,
        ids: id ? [id] : [],
        options: {
            ...(options ? options : {}),
            enabled: options?.enabled ?? !!id,
        },
    });
    const result = useMemo(
        () => [items?.[0] || undefined, queryData] as const,
        [items, queryData],
    );
    return result;
};

const useFetchLatestForSingle = <T extends { _id: string }>(params: {
    mainQueryKey: string;
    mainRoute: string;
    fetchFor: string;
    id?: string;
    options?: Partial<QueryConfigLight>;
    accessKeyId?: AccessKeyId;
}) => {
    const { mainQueryKey, mainRoute, fetchFor, id, options } = params;
    const [items, queryData] = useMotherOfAllFetches<T>({
        queryKey: [
            mainQueryKey,
            createFetchForQueryKey(mainQueryKey, fetchFor),
            id,
            "single",
        ] as ArrayQueryKey,
        route: `${mainRoute}/for-${fetchFor}/${id}/latest${
            params.accessKeyId ? `?ak=${params.accessKeyId}` : ""
        }`,
        ids: id ? [id] : [],
        options: {
            ...(options ? options : {}),
            enabled: options?.enabled ?? !!id,
        },
    });

    const result = useMemo(
        () => [items?.[0] || undefined, queryData] as const,
        [items, queryData],
    );
    return result;
};

const useFetchAll = <T extends { _id: string }>(
    mainQueryKey: string,
    mainRoute: string,
    options?: Partial<QueryConfigLight>,
    accessKeyId?: AccessKeyId,
) => {
    return useMotherOfAllFetches<T>({
        queryKey: [mainQueryKey] as ArrayQueryKey,
        route: `${mainRoute}${accessKeyId ? `?ak=${accessKeyId}` : ""}`,
        all: true,
        options,
    });
};

const useFetchMultiple = <T extends { _id: string }>(
    mainQueryKey: string,
    mainRoute: string,
    ids: string[] = [],
    options?: Partial<QueryConfigLight>,
    accessKeyId?: AccessKeyId,
) => {
    return useMotherOfAllFetches<T>({
        queryKey: [mainQueryKey, ...ids] as ArrayQueryKey,
        route: `${mainRoute}/?ids=${ids.join(",")}${
            accessKeyId ? `&ak=${accessKeyId}` : ""
        }`,
        ids,
        options: {
            ...(options ? options : {}),
            enabled: options?.enabled ?? !!ids?.length,
        },
    });
};

type QueryConfigLight = {
    staleTime: number;
    gcTime: number;
    enabled: boolean;
    refetchInterval: number;
};

const firstQueryKey = (queryKey: QueryKey) =>
    (typeof queryKey === "string"
        ? [queryKey]
        : [queryKey[0]]) as readonly unknown[];

const useMotherOfAllFetches = <T extends { _id: string }>(
    params: {
        queryKey: QueryKey;
        route: string;
        options?: Partial<QueryConfigLight>;
    } & (
        | {
              ids: string[];
              all?: false | undefined;
          }
        | {
              ids?: undefined;
              all: true;
          }
    ),
): readonly [
    DefinedUseQueryResult<T[]>["data"],
    DefinedUseQueryResult<T[]>,
] => {
    const { queryKey, route, ids, all } = params;

    const gcTime = params.options?.gcTime ?? defaultCacheTime;
    const staleTime = params.options?.staleTime ?? defaultStaleTime;
    const initialDataUpdatedAt = addMilliseconds(
        new Date(),
        typeof staleTime !== "undefined" ? -staleTime : 0,
    ).getTime();

    const query = useQuery<T[]>({
        queryKey,
        gcTime,
        staleTime,
        refetchInterval: params.options?.refetchInterval,
        enabled: params.options?.enabled ?? (all || false),
        initialDataUpdatedAt,
        initialData: (): T[] => {
            const oldData = queryClient
                .getQueryData<T[]>(firstQueryKey(queryKey))
                ?.filter(item => all || (item._id && ids?.includes(item._id)));
            return oldData ?? emptyArrayOf<T>();
        },
        queryFn: async ({ signal }) => {
            try {
                const data = await apiFetchClient.get<GetResponse<T>>({
                    route,
                    customConfig: { signal },
                });
                return data.items || emptyArrayOf<T>();
            } catch (error) {
                if (isApiError(error) && error.statusCode === 404) {
                    return emptyArrayOf<T>();
                }
                throw error;
            }
        },
    });

    const result = useMemo(
        () => [query.data ?? emptyArrayOf<T>(), query] as const,
        [query],
    );
    return result;
};

const createFetchForQueryKey = (
    mainQueryKey: string,
    fetchFor: string,
): QueryKey => [`${mainQueryKey}-for-${fetchFor}`];

const useFetchMultipleForSingle = <T extends { _id: string }>(params: {
    mainQueryKey: string;
    mainRoute: string;
    fetchFor: string;
    id?: string;
    options?: Partial<QueryConfigLight>;
    filters?: { [name: string]: string[] };
    accessKeyId?: AccessKeyId;
}) => {
    const { mainQueryKey, mainRoute, fetchFor, id, options } = params;
    const filters = params.filters ?? {};

    // turn filters arrays to csv strings
    const csvFilters = Object.keys(filters).reduce(
        (agg, name) => {
            agg[name] = filters[name].join(",");
            return agg;
        },
        {} as { [name: string]: string },
    );

    const queryString = createQueryString(csvFilters);

    let route = `${mainRoute}/for-${fetchFor}/${id}${queryString}`;
    if (params.accessKeyId) {
        route += route.includes("?")
            ? `&ak=${params.accessKeyId}`
            : `?ak=${params.accessKeyId}`;
    }
    return useMotherOfAllFetches<T>({
        queryKey: [
            mainQueryKey,
            ...createFetchForQueryKey(mainQueryKey, fetchFor),
            id,
            "single",
            queryString,
        ] as ArrayQueryKey,
        route,
        ids: id ? [id] : [],
        options: {
            ...(options ? options : {}),
            enabled: options?.enabled ?? !!id,
        },
    });
};

const useFetchMultipleForMultiple = <T extends { _id: string }>(
    mainQueryKey: string,
    mainRoute: string,
    fetchFor: string,
    ids: string[] = [],
    filters: { [name: string]: string[] } = {},
    enabled?: boolean,
    options?: Partial<QueryConfigLight>,
    accessKeyId?: AccessKeyId,
) => {
    const _enabled =
        enabled ||
        !!ids.length ||
        Object.keys(filters).some(name => {
            return !!filters[name]?.length;
        });

    const csvIds = ids.join(",");

    // turn filters arrays to csv strings
    const csvFilters = Object.keys(filters).reduce(
        (agg, name) => {
            if (name === "ids") return agg; // already handled in init
            agg[name] = filters[name].join(",");
            return agg;
        },
        {
            ...(ids.length || filters["ids"]?.length
                ? { ids: csvIds || filters["ids"] }
                : {}),
        } as { [name: string]: string },
    );

    const queryString = createQueryString(csvFilters);
    let route = `${mainRoute}/for-${fetchFor}/${queryString}`;
    if (accessKeyId) {
        route += route.includes("?")
            ? `&ak=${accessKeyId}`
            : `?ak=${accessKeyId}`;
    }

    return useMotherOfAllFetches<T>({
        queryKey: [
            mainQueryKey,
            ...createFetchForQueryKey(mainQueryKey, fetchFor),
            ...ids,
        ] as ArrayQueryKey,
        route,
        ids,
        options: {
            ...(options ? options : {}),
            enabled: options?.enabled ?? _enabled,
        },
    });
};

// const useInfiniteFetchFor = <T extends { _id: string }>(
//     mainQueryKey: string,
//     mainRoute: string,
//     fetchFor: string,
//     id?: string,
//     accessKeyId?: AccessKeyId,
// ): readonly [
//     T[],
//     UseInfiniteQueryResult<
//         InfiniteData<GetInfiniteResponse<T>, unknown>,
//         Error
//     >,
// ] => {
//     const queryKey: ArrayQueryKey = [
//         mainQueryKey,
//         `${mainQueryKey}-for-${fetchFor}`,
//         id,
//     ];

//     const query = useInfiniteQuery<GetInfiniteResponse<T>>({
//         queryKey,
//         queryFn: async (...all) => {
//             const cursor = all[queryKey.length];
//             // 0: "orders"
//             // 1: "orders-for-provider"
//             // 2: "provider_VIfV-cZU0AtXBBNxFQkZ"
//             // 3: undefined
//             // console.log(all);
//             const result = await apiFetchClient.get<GetInfiniteResponse<T>>({
//                 route: `${mainRoute}/for-${fetchFor}/${id}?from=${
//                     cursor ?? "0"
//                 }${accessKeyId ? `&ak=${accessKeyId}` : ""}`,
//             });

//             return result;
//         },
//         initialPageParam: 0,
//         getNextPageParam: lastPage => lastPage.nextCursor,
//         enabled: !!id,
//         // Todo: preload data on init, and update other query caches of same data
//         // Todo: on success, like above in the mother of all fetches
//     });

//     return useMemo(
//         () =>
//             [
//                 query.data?.pages?.flatMap(page => page.items) ??
//                     emptyArrayOf<T>(),
//                 query,
//             ] as const,
//         [query],
//     );
// };

export {
    createFetchForQueryKey,
    defaultCacheTime,
    defaultStaleTime,
    useFetch,
    useFetchAll,
    useFetchLatestForSingle,
    useFetchMultiple,
    useFetchMultipleForMultiple,
    useFetchMultipleForSingle,
    // useInfiniteFetchFor,
};
