import { FlexColumn } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { PageBlock, translation } from "@towni/common";
import { createContext, useContext, useMemo } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { usePageViewContext } from "./editor/page-view-context";

type State<Block extends PageBlock | unknown = unknown> = {
    block: Block;
};

type Props<Block extends PageBlock> = {
    block: Block;
    children?: React.ReactNode;
};

type ContextFactoryResult<
    Block extends PageBlock,
    DisableThrow extends true | false | undefined,
> = [
    provider: (props: Props<Block>) => JSX.Element,
    useHook: () => [DisableThrow] extends [true]
        ? State<Block> | undefined
        : State<Block>,
];

function pageBlockViewContextFactory<Block extends PageBlock>(
    disableThrowWhenContextIsMissing: true,
): ContextFactoryResult<Block, true>;
function pageBlockViewContextFactory<Block extends PageBlock>(
    disableThrowWhenContextIsMissing: false | undefined,
): ContextFactoryResult<Block, false>;
function pageBlockViewContextFactory<
    Block extends PageBlock,
>(): ContextFactoryResult<Block, false>;
function pageBlockViewContextFactory<
    Block extends PageBlock,
    DisableThrow extends boolean | undefined = undefined,
>(
    disableThrowWhenContextIsMissing?: DisableThrow,
): ContextFactoryResult<Block, DisableThrow> {
    const context = createContext<State<Block> | undefined>(undefined);
    const PageBlockViewProvider = (props: Props<Block>) => {
        const theme = useTheme();
        const onBlockClick = usePageViewContext(
            context => context.onBlockClick,
        );

        const state = useMemo(() => {
            return {
                block: props.block,
            };
        }, [props.block]);

        return (
            <ErrorBoundary
                fallbackRender={() => {
                    return (
                        <FlexColumn
                            fillParent
                            mainAxis="center"
                            crossAxis="center"
                            radius={0}
                            background={{ color: theme.colors.danger.light }}>
                            <TextBox
                                text={translation({
                                    sv: "Oops, vi verkar ha det lite struligt just nu",
                                    en: "Oops, something went wrong",
                                })}
                                padding={{ all: 10 }}
                                size={1.2}
                                align="center"
                                color={theme.colors.danger}
                            />
                        </FlexColumn>
                    );
                }}>
                <context.Provider value={state}>
                    <div
                        css={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                        onClick={event => {
                            if (event.metaKey && onBlockClick) {
                                event.preventDefault();
                                event.stopPropagation();
                                void onBlockClick(props.block._id);
                            }
                        }}>
                        {props.children}
                    </div>
                </context.Provider>
            </ErrorBoundary>
        );
    };

    function usePageBlockViewContext(): State<Block>;
    function usePageBlockViewContext(
        disableThrowWhenContextIsMissing: false | undefined,
    ): State<Block>;
    function usePageBlockViewContext(
        disableThrowWhenContextIsMissing: true,
    ): State<Block> | undefined;
    function usePageBlockViewContext(): State<Block> | undefined {
        const state = useContext(context);
        if (!state && !disableThrowWhenContextIsMissing) {
            throw new Error(
                "usePageBlockViewContext must be used within a PageBlockViewProvider",
            );
        }
        return state;
    }

    return [PageBlockViewProvider, usePageBlockViewContext];
}

const [PageBlockViewProvider, usePageBlockViewContext] =
    pageBlockViewContextFactory<PageBlock>();

export {
    pageBlockViewContextFactory,
    PageBlockViewProvider,
    usePageBlockViewContext,
};
