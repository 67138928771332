import { useCart } from "@@/carts/multi-carts/cart.context";
import {
    Discount,
    GiftCard_Product,
    OrderItem,
    OrderItemId,
    OrderItem_V2,
    PickedOptional,
    Price,
    Provider,
    Quantity,
    defaultAmount,
    emptyArrayOf,
    getChildOptionals,
    isGiftCard,
    orderItemFactory,
    orderItemOptionalFactory_V2,
    repeat,
    setWithinRange,
    sumPrices,
} from "@towni/common";
import * as React from "react";
import { useMemo } from "react";
import { StoreApi, create as zustand } from "zustand";
import { shallow } from "zustand/shallow";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { ExtrasProps } from "./product-details-page";

type State = {
    readonly provider: Provider;
    readonly giftCard: GiftCard_Product;
    readonly quantityValue: number;
    readonly orderItemToReplaceId?: OrderItemId;
    readonly pickedOptionals: PickedOptional[];
    readonly discounts: Discount[];
    readonly addToCartDisabled: boolean;
    readonly selectedPrice: Price | undefined;
};

type Actions = {
    readonly getOrderItems: () => [OrderItem_V2[], errors: string[]]; // ! Why is this a function and not a state property? /emanuel
    readonly setQuantity: (value: number) => void;
    readonly increaseQuantity: (value?: number) => void;
    readonly decreaseQuantity: (value?: number) => void;
    readonly setPickedOptionals: (pickedOptionals: PickedOptional[]) => void;
    readonly setDiscounts: (discounts: Discount[]) => void;
    readonly setAddToCartDisabled: (disabled: boolean) => void;
    readonly setSelectedPrice: (price: Price) => void;
};

type Context = State & Actions;

const GiftCardPurchaseContext = React.createContext<StoreApi<Context>>(
    undefined as unknown as StoreApi<Context>,
);

type Props = {
    readonly initialQuantity?: number;

    readonly orderItemId?: OrderItemId;
    readonly extras?: ExtrasProps;
    readonly giftCard: GiftCard_Product;
    readonly provider: Provider;
    readonly children: React.ReactNode;
};

const GiftCardPurchaseContextProvider = (props: Props) => {
    const cart = useCart();

    // Check if the current gift card is already present as
    // an order item in the cart
    const originalOrderItem = useMemo(() => {
        if (typeof props.orderItemId === "undefined") return undefined;
        const orderItem = cart.orderItems.find(
            item => item._id === props.orderItemId,
        );
        if (orderItem && !isGiftCard(orderItem))
            throw new Error("Bad order item id, not a gift card");

        return orderItem;
    }, [cart.orderItems, props.orderItemId]);

    // Make sure the products provider id match for product, provider and orderItem
    React.useEffect(() => {
        if (
            originalOrderItem &&
            (props.giftCard.providerId !== originalOrderItem.providerId ||
                props.giftCard._id !== originalOrderItem.productId)
        ) {
            throw new Error(
                "GiftCardPurchaseContextProvider: product and orderItem do not match",
            );
        }
    }, [originalOrderItem, props.giftCard]);

    const createStore = () => {
        return zustand<Context>((set, get) => {
            // Generate a default initial state
            // - If there's an original order item, check for any optionals already picked
            const optionals = originalOrderItem
                ? getChildOptionals(originalOrderItem, cart.orderItems).map(
                      o => {
                          return { ...o, quantity: o.quantity ?? 1 };
                      },
                  )
                : emptyArrayOf<PickedOptional>();

            // - initialize the state
            const state: State = {
                quantityValue: props.initialQuantity ?? 1,
                orderItemToReplaceId: props.orderItemId,
                pickedOptionals: optionals,
                discounts:
                    originalOrderItem?.discounts ?? emptyArrayOf<Discount>(),
                giftCard: props.giftCard,
                provider: props.provider,
                addToCartDisabled: true,
                selectedPrice: undefined,
            };

            // - define the actions
            const actions: Actions = {
                setAddToCartDisabled: (disabled: boolean) => {
                    set({ addToCartDisabled: disabled });
                },
                setSelectedPrice: (price: Price) => {
                    set({ selectedPrice: price });
                },
                getOrderItems: (): [OrderItem[], string[]] => {
                    const state = get();
                    const selectedPrice = state.selectedPrice;
                    const errors: string[] = [];
                    if (
                        !state.giftCard ||
                        !selectedPrice ||
                        !isGiftCard(state.giftCard) ||
                        !state.quantityValue
                    ) {
                        if (!state.giftCard)
                            errors.push("No gift card provided");
                        if (!isGiftCard(state.giftCard))
                            errors.push("Product is not a gift card");
                        if (!state.quantityValue)
                            errors.push("No quantity selected");

                        return [emptyArrayOf<OrderItem>(), errors] as const;
                    }

                    const quantity: Quantity = {
                        amount: defaultAmount,
                        value: state.quantityValue,
                    };
                    const giftCardOrderItem = orderItemFactory.giftCard({
                        giftCard: state.giftCard,
                        selectedPrice: selectedPrice,
                        discounts: state.discounts,
                        orderItemId: state.orderItemToReplaceId,
                        quantity,
                    });

                    const pickedOptionals = state.pickedOptionals ?? [];
                    const pickedOptionalsOrderItems = pickedOptionals.map(
                        pickedOption =>
                            orderItemOptionalFactory_V2({
                                pickedOption,
                                discounts: state.discounts,
                                messageToProvider: undefined,
                                productPricePerItem: selectedPrice,
                                productPrice: sumPrices(
                                    repeat(selectedPrice, state.quantityValue),
                                ),
                                parent: giftCardOrderItem,
                                quantity,
                            }),
                    );

                    return [
                        [giftCardOrderItem, ...pickedOptionalsOrderItems],
                        errors,
                    ] as const;
                },
                setQuantity: (value: number) => {
                    set({ quantityValue: setWithinRange(value, { min: 1 }) });
                },
                increaseQuantity: (value = 1) => {
                    set(state => {
                        return {
                            ...state,
                            quantityValue: setWithinRange(
                                state.quantityValue + value,
                                { min: 1 },
                            ),
                        };
                    });
                },
                decreaseQuantity: (value = 1) => {
                    set(state => ({
                        ...state,
                        quantityValue: setWithinRange(
                            state.quantityValue - value,
                            { min: 1 },
                        ),
                    }));
                },
                setPickedOptionals: (pickedOptionals: PickedOptional[]) => {
                    // TODO! verify all optionals exists on product
                    set({ pickedOptionals });
                },
                setDiscounts: (discounts: Discount[]) => {
                    // TODO! verify all discounts exists on product
                    set({ discounts });
                },
            };

            const context: Context = {
                ...state,
                ...actions,
            };

            return context;
        });
    };
    const store = React.useRef(createStore()).current;

    return (
        <GiftCardPurchaseContext.Provider value={store}>
            {props.children}
        </GiftCardPurchaseContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useGiftCardPurchaseContext = <U extends unknown = Context>(
    selector: (context: Context) => U = context => context as unknown as U,
): U => {
    const store = React.useContext(GiftCardPurchaseContext);
    if (store === undefined) {
        throw new Error(
            "useGiftCardPurchaseContext must be used within a GiftCardPurchaseContext",
        );
    }
    return useStoreWithEqualityFn(store, selector, shallow);
};

export { GiftCardPurchaseContextProvider, useGiftCardPurchaseContext };
