import { FlexAlignment, FlexRow } from "@@/shared/flex-containers";
import { paddingToCssValue } from "@@/shared/padding";
import { SerializedStyles, css, useTheme } from "@emotion/react";
import { ColorItem, Padding, colorAsString } from "@towni/common";
import * as React from "react";

type Props = {
    readonly children: React.ReactNode;
    readonly onClick: ((event: React.MouseEvent) => void) | undefined;
    readonly buttonTheme?:
        | "primary"
        | "tertiary"
        | "secondary"
        | "default"
        | "danger"
        | "warning"
        | "disabled"
        | "information";
    readonly disabled?: boolean;
    readonly padding?: Padding;
    readonly fillWidth?: boolean;
    readonly styling?: SerializedStyles;
    readonly mainAxis?: FlexAlignment;
};

type ButtonTheme = {
    background: ColorItem;
    text: ColorItem;
    borderColor: ColorItem;
};
const useButtonTheme = (
    type?:
        | "primary"
        | "tertiary"
        | "secondary"
        | "default"
        | "danger"
        | "warning"
        | "disabled"
        | "information",
): ButtonTheme => {
    const { colors } = useTheme();
    const colorSet = colors[type ?? "primary"] ?? colors.primary;
    return {
        background: colorSet.background,
        text: colorSet.text,
        borderColor: colorSet.border,
    };
};

/**
 * @deprecated prefer to use ButtonV2 or any of its variants instead
 */
const Button = (props: Props) => {
    const { disabled, onClick, children, styling, fillWidth, mainAxis } = props;
    const { radius } = useTheme();

    const paddingCssValue = paddingToCssValue(props.padding ?? { all: 15 });
    const buttonTheme = useButtonTheme(
        disabled ? "disabled" : props.buttonTheme,
    );
    const styles = css`
        display: block;
        border-radius: ${radius}px;
        border: 1px solid ${colorAsString(buttonTheme.borderColor)};
        pointer-events: auto;
        ${(!!fillWidth && "width: 100%;") || ""}
        ${(!disabled && "cursor: pointer;") || ""}
        background-color: ${colorAsString(
            buttonTheme.background,
            // disabled
            //     ? _type === "primary"
            //         ? colors.primary.light
            //         : _type === "danger"
            //         ? colors.danger.light
            //         : _type === "tertiary"
            //         ? colors.tertiary.light
            //         : colors.default.background.light80
            //     : _type === "primary"
            //     ? colors.primary
            //     : _type === "danger"
            //     ? colors.danger
            //     : _type === "tertiary"
            //     ? colors.tertiary
            //     : colors.default.background
        )};
        color: ${colorAsString(
            buttonTheme.text,
            // disabled
            //     ? colors.default.disabledText // TODO! color from theme
            //     : _type === "primary"
            //     ? colors.primary.text
            //     : _type === "danger"
            //     ? colors.danger.text
            //     : _type === "tertiary"
            //     ? colors.tertiary.text
            //     : colors.default.text
        )};
        padding: ${paddingCssValue};
        ${!disabled
            ? `
            &:active {
                opacity: 0.8;
            }
        `
            : ""}
        ${styling || ""}
    `;

    return (
        <a
            css={styles}
            onClick={event => {
                if (!disabled) onClick?.(event);
            }}>
            <FlexRow mainAxis={mainAxis || "center"}>{children}</FlexRow>
        </a>
    );
};

export { Button };
