import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useIsTranslatableEmpty } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    faChevronDown,
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/pro-solid-svg-icons";
import {
    Resource,
    ResourceGraph,
    ResourceGroup,
    ResourceId,
    isNotResourceGraph,
    isResourceGraph,
    translation,
} from "@towni/common";
import { useState } from "react";
import { ResourceGraphRows } from "./resource-graph-row";
import { ResourceRow } from "./resource-row";

const ResourcePickerList = (props: {
    graph: ResourceGraph;
    goBack: undefined | (() => void);
    maxSelect?: number;
    onGraphClick: (graph: ResourceGraph) => void;

    selectResource: (resourceId: ResourceId, maxSelect?: number) => void;
    deselectResource: (resourceId: ResourceId) => void;

    possibleResourceIds: Set<ResourceId>;
    // availableResourcesMap: Map<ResourceId, Resource[]>;
    requiredResourceIds?: Set<ResourceId>;
    requestedResources: ResourceId[];
    goNext?: () => void;
    //If the map is showing allow the list to be hidden
    hasMap?: boolean;
}) => {
    const theme = useTheme();
    const { graph } = props;
    const isTranslatableEmpty = useIsTranslatableEmpty();
    const possibleResourceIds = props.possibleResourceIds;
    const resourceGraphs = graph.children.filter(isResourceGraph);
    const otherResources = graph.children.filter(isNotResourceGraph);

    //Allow
    const [hide, setHide] = useState(false);
    // console.log("🎉", {
    //     resourceGraphs,
    //     otherResources,
    //     possibleResourceIds,
    //     required: props.requiredResourceIds,
    //     requested: props.requestedResources,
    // });
    return (
        <FlexColumn
            tag="group"
            css={{
                flexGrow: hide ? 0 : 1,
                borderTop: `1px solid ${theme.colors.default.border.asString}`,
                borderRadius: 10,
                boxShadow: `0px -5px 10px 0px ${theme.colors.default.border.asString}`,
                overflowY: "hidden",
            }}>
            <Conditional
                when={resourceGraphs.length + otherResources.length > 0}
                render={() => (
                    <>
                        <Conditional
                            when={
                                !!props.goBack ||
                                !isTranslatableEmpty(
                                    graph.resourceGroup?.meta.title,
                                )
                            }
                            render={() => (
                                <>
                                    <FlexRow
                                        fillParentWidth
                                        mainAxis={
                                            props.goBack
                                                ? "space-between"
                                                : "flex-start"
                                        }
                                        crossAxis="center"
                                        onClick={props.goBack}
                                        css={{
                                            padding: 20,
                                            cursor: props.goBack
                                                ? "pointer"
                                                : "default",
                                            backgroundColor: props.goBack
                                                ? theme.isLightTheme
                                                    ? theme.colors.primary.light
                                                          .asString
                                                    : theme.colors.primary.dark
                                                          .asString
                                                : theme.colors.default
                                                      .background.asString,
                                        }}>
                                        <Conditional
                                            when={!!props.goBack}
                                            render={() => (
                                                <Icon
                                                    icon={faChevronLeft}
                                                    size={1.125}
                                                />
                                            )}
                                        />
                                        <TextBox
                                            text={
                                                graph.resourceGroup?.meta
                                                    .title || {
                                                    sv: "(ingen titel)",
                                                    en: "(no title)",
                                                }
                                            }
                                            weight="700"
                                            size={1.125}
                                        />
                                        <Conditional
                                            when={!!props.goBack}
                                            render={() => <HorizontalDivider />}
                                        />
                                        <Conditional when={!!props.hasMap}>
                                            <FlexRow
                                                onClick={() => setHide(!hide)}
                                                fillParentWidth
                                                mainAxis="flex-end">
                                                <TextBox
                                                    text={
                                                        hide
                                                            ? translation({
                                                                  sv: "Visa lista",
                                                                  en: "Show",
                                                              })
                                                            : translation({
                                                                  sv: "Dölj lista",
                                                                  en: "Hide",
                                                              })
                                                    }
                                                    color={theme.colors.primary}
                                                    size={1}
                                                />
                                                <HorizontalDivider />
                                                <Icon
                                                    color={theme.colors.primary}
                                                    icon={
                                                        hide
                                                            ? faChevronRight
                                                            : faChevronDown
                                                    }
                                                    size={1}
                                                />
                                            </FlexRow>
                                        </Conditional>
                                    </FlexRow>
                                    <HorizontalLine />
                                </>
                            )}
                        />
                        <FlexColumn
                            fillParentWidth
                            css={{
                                overflowY: "scroll",
                                maxHeight: hide ? "50px" : undefined,
                            }}>
                            <ResourceGraphRows
                                resourceGraphs={resourceGraphs}
                                onClick={props.onGraphClick}
                            />
                            <ForEach
                                itemOf={otherResources}
                                getKey={resource => resource._id}
                                divider={<HorizontalLine />}>
                                {resource => (
                                    <ResourceRow
                                        resource={resource}
                                        maxSelect={props.maxSelect}
                                        locked={props.requiredResourceIds?.has(
                                            resource._id,
                                        )}
                                        requestedResources={
                                            props.requestedResources
                                        }
                                        selectResource={props.selectResource}
                                        deselectResource={
                                            props.deselectResource
                                        }
                                        disabled={
                                            !possibleResourceIds.has(
                                                resource._id,
                                            )
                                        }
                                    />
                                )}
                            </ForEach>
                            <HorizontalLine />
                            <VerticalDivider XL />
                            <VerticalDivider XL />

                            <VerticalDivider XL />
                        </FlexColumn>
                    </>
                )}
                else={() => (
                    <FlexRow fillParentWidth mainAxis="center">
                        <TextBox
                            text={{
                                sv: "(inga resurser)",
                                en: "(no resources)",
                            }}
                            color={theme.colors.black.light90}
                        />
                    </FlexRow>
                )}
            />
        </FlexColumn>
    );
};

export { ResourcePickerList };
