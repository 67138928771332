import { useHomePath } from "@@/backoffice/for-providers/settings/settings/use-home-path";
import { useHistoryContext } from "@@/pages/page-navigation/history-context";
import { useBrandingContext } from "@@/routes/shared/branding-context";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { useDestination } from "@@/shared/destination-context";
import { FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons";
import { Provider } from "@towni/common";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

type Props = { provider: Provider | undefined };
const BackButton__ = (props: Props) => {
    const navigate = useNavigate();
    const [, keys] = useHistoryContext();
    const destinationState = useDestination();
    const { branding } = useBrandingContext();
    const providerHomePath = useHomePath(props.provider);

    const goBackUrl = useMemo(() => {
        if (keys.length > 1) return "#goback"; // should meen it's not the first page loaded within application
        if (branding?.storefrontUrl) return branding.storefrontUrl;
        if (props.provider && destinationState?.area === "lidköping") {
            return `${providerHomePath}#goback`;
        }
        return `#goback`;
    }, [branding, destinationState, props.provider]);

    const goBack = () => {
        if (!goBackUrl) return;
        if (goBackUrl?.startsWith("http")) {
            window.location.href = goBackUrl;
            return;
        }
        if (goBackUrl === "#goback") {
            navigate(-1);
            return;
        }
        navigate(goBackUrl);
    };

    if (typeof goBackUrl === "undefined") return null;
    return (
        <ButtonWhite
            onClick={goBack}
            css={{
                borderRadius: 3000,
                minHeight: 40,
            }}>
            <FlexRow css={{ backgroundColor: "#ffffff" }}>
                <Icon icon={faChevronLeft} />
            </FlexRow>
        </ButtonWhite>
    );
};

export { BackButton__ as _BackButton };
