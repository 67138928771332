import { useCheckoutContext } from "@@/carts/checkout.context";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { ButtonPrimaryLight } from "@@/shared/buttons_v2/button-primary-light";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Inputs } from "@@/shared/form/standalones/inputs";
import { Icon } from "@@/shared/icons/icon";
import {
    EmailInput,
    Paragraph,
    TextBox,
    paragraphLineBreakFactory,
    paragraphTextFactory,
} from "@@/shared/text";
import { useToast } from "@@/toasts/context/toast-context";
import { useSelectedLanguageCode } from "@@/translations/use-selected-language-code";
import { useTheme } from "@emotion/react";
import {
    faChevronDown,
    faChevronRight,
    faSend,
} from "@fortawesome/pro-regular-svg-icons";
import {
    ContactInfo,
    PayRequestRecipient,
    PhoneNumber,
    durationInSecondsZodSchema,
    fullName,
    payRequestRecipientFactory,
    translation,
} from "@towni/common";
import { parsePhoneNumber } from "awesome-phonenumber";
import { useState } from "react";
import { Except } from "type-fest";

type Props = {
    readonly spin?: boolean;
    readonly disabled?: boolean;
    readonly initialContactInfo: ContactInfo | undefined;
    readonly onSend: (
        recipients: PayRequestRecipient[],
        contactInfo: ContactInfo,
    ) => void | Promise<void>;
};

const OnPremisePayRequestPaymentButton__ = (props: Props) => {
    const theme = useTheme();
    const toast = useToast();
    const languageCode = useSelectedLanguageCode();
    const [folded, setFolded] = useState(false);
    const initialContactInfo = props.initialContactInfo;
    const [emailAddress, setEmailAddress] = useState(
        initialContactInfo?.emailAddress || undefined,
    );
    const [phoneNumber, setPhoneNumber] = useState<PhoneNumber | undefined>(
        initialContactInfo?.phoneNumber as PhoneNumber,
    );
    const [selected, setSelected] = useState<"phone" | "email" | "both">(
        "phone",
    );

    return (
        <ButtonWhite
            renderAs="div"
            spin={props.spin}
            disabled={props.disabled}
            css={{
                flex: 1,
                width: "100%",
                padding: 20,
                backgroundColor: theme.colors.default.background.asString,
                borderColor: theme.isDarkTheme
                    ? theme.colors.black.light50.asString
                    : theme.colors.black.light80.asString,
            }}
            contentContainerCss={{
                flex: 1,
                minWidth: "100%",
            }}
            textColor={
                theme.isLightTheme
                    ? theme.colors.black.light10
                    : theme.colors.black.light90
            }
            onClick={() => {
                setFolded(folded => !folded);
            }}>
            <FlexColumn fillParentWidth>
                <FlexRow
                    mainAxis="space-between"
                    css={{
                        flex: 1,
                        cursor: "pointer",
                    }}>
                    <FlexRow>
                        <Icon icon={faSend} fixedWidth />
                        <HorizontalDivider S />
                        <TextBox
                            text={translation({
                                sv: "Skicka betallänk",
                                en: "Send payment link",
                            })}
                        />
                    </FlexRow>
                    <Icon
                        icon={folded ? faChevronRight : faChevronDown}
                        fixedWidth
                    />
                </FlexRow>
                <Conditional when={!folded}>
                    <VerticalDivider S />
                    <Paragraph
                        css={{
                            fontSize: "0.825rem",
                            color: theme.colors.default.text.withAlpha(0.35)
                                .asString,
                        }}
                        content={[
                            translation({
                                sv: `Betallänk är endast till för distansköp p.g.a. `,
                                en: `Payment link is only for distance purchases due to `,
                            }),
                            paragraphTextFactory({
                                css: {
                                    textDecoration: "underline",
                                },
                                text: translation({
                                    sv: `kassalagen.`,
                                    en: `the cash register law.`,
                                }),
                            }),
                            paragraphLineBreakFactory(),
                            translation({
                                sv: `För att ta betalt på plats, använd er betalterminal/kassa.`,
                                en: `To manage payment on premise, use your own payment terminal/cash register.`,
                            }),
                        ]}
                    />
                    <VerticalDivider M />
                    <FlexColumn
                        onClick={event => {
                            event.stopPropagation();
                        }}
                        css={{
                            flex: 1,
                            paddingLeft: 2,
                            paddingTop: 0,
                        }}>
                        <FlexRow
                            css={{
                                paddingBottom: 10,
                            }}>
                            <label>
                                <FlexRow
                                    crossAxis="center"
                                    css={{
                                        cursor: "pointer",
                                    }}>
                                    <input
                                        type="radio"
                                        name="target"
                                        checked={selected === "phone"}
                                        value={"phone"}
                                        onChange={event => {
                                            setSelected(
                                                event.target.value as
                                                    | "phone"
                                                    | "email"
                                                    | "both",
                                            );
                                        }}
                                    />
                                    <HorizontalDivider />
                                    <TextBox
                                        text={translation({
                                            sv: "SMS",
                                            en: "SMS",
                                        })}
                                    />
                                </FlexRow>
                            </label>
                            <HorizontalDivider M />
                            <label>
                                <FlexRow
                                    crossAxis="center"
                                    css={{
                                        cursor: "pointer",
                                    }}>
                                    <input
                                        type="radio"
                                        name="target"
                                        checked={selected === "email"}
                                        value={"email"}
                                        onChange={event => {
                                            setSelected(
                                                event.target.value as
                                                    | "email"
                                                    | "both",
                                            );
                                        }}
                                    />
                                    <HorizontalDivider />
                                    <TextBox
                                        text={translation({
                                            sv: "E-post",
                                            en: "Email",
                                        })}
                                    />
                                </FlexRow>
                            </label>
                            <HorizontalDivider M />
                            <label>
                                <FlexRow
                                    crossAxis="center"
                                    css={{
                                        cursor: "pointer",
                                    }}>
                                    <input
                                        type="radio"
                                        name="target"
                                        value={"both"}
                                        checked={selected === "both"}
                                        onChange={event => {
                                            setSelected(
                                                event.target.value as
                                                    | "both"
                                                    | "email",
                                            );
                                        }}
                                    />
                                    <HorizontalDivider />
                                    <TextBox
                                        text={translation({
                                            sv: "Båda",
                                            en: "Both",
                                        })}
                                    />
                                </FlexRow>
                            </label>
                        </FlexRow>
                        <Conditional
                            when={selected === "phone" || selected === "both"}>
                            <Inputs.PhoneNumberInput
                                disabled={!!props.spin}
                                initialPhoneNumber={phoneNumber as PhoneNumber}
                                onChange={({ phoneNumber }) => {
                                    setPhoneNumber(phoneNumber);
                                }}
                            />
                        </Conditional>
                        <Conditional when={selected === "both"}>
                            <VerticalDivider />
                        </Conditional>
                        <Conditional
                            when={selected === "email" || selected === "both"}>
                            <EmailInput
                                disabled={!!props.spin}
                                onChange={setEmailAddress}
                                initialValue={emailAddress}
                            />
                        </Conditional>
                        <VerticalDivider S />
                        <FlexRow
                            mainAxis="flex-end"
                            onClick={event => {
                                event.stopPropagation();
                            }}>
                            <ButtonPrimaryLight
                                disabled={
                                    !!props.spin ||
                                    !initialContactInfo ||
                                    (selected === "email" && !emailAddress) ||
                                    (selected === "both" &&
                                        (!emailAddress || !phoneNumber))
                                }
                                spin={props.spin}
                                padding={{ leftRight: 40, topBottom: 10 }}
                                onClick={async () => {
                                    if (!initialContactInfo) {
                                        toast.warning({
                                            message: translation({
                                                sv: "Ordern har ingen kontaktinformation till kunden angiven ännu",
                                                en: "The order has not yet any contact information for the customer",
                                            }),
                                            timeout:
                                                durationInSecondsZodSchema.parse(
                                                    10,
                                                ),
                                        });
                                        return;
                                    }
                                    if (selected === "email") {
                                        if (!emailAddress) {
                                            toast.warning({
                                                message: translation({
                                                    sv: "Du måste ange en e-postadress",
                                                    en: "You must enter an email address",
                                                }),
                                                timeout:
                                                    durationInSecondsZodSchema.parse(
                                                        10,
                                                    ),
                                            });
                                            return;
                                        }

                                        await props.onSend(
                                            [
                                                payRequestRecipientFactory({
                                                    type: "EMAIL",
                                                    emailAddress,
                                                    language: languageCode,
                                                    name: initialContactInfo?.firstName?.trim()
                                                        ? fullName({
                                                              firstName:
                                                                  initialContactInfo.firstName ??
                                                                  "Anonym kund",
                                                              lastName:
                                                                  initialContactInfo.lastName ??
                                                                  "",
                                                          })
                                                        : translation({
                                                              sv: "Kund",
                                                              en: "Customer",
                                                          }),
                                                }),
                                            ],
                                            initialContactInfo,
                                        );
                                    }
                                    if (selected === "phone") {
                                        const _phoneNumber = parsePhoneNumber(
                                            phoneNumber ?? "",
                                        );
                                        if (!_phoneNumber?.number?.e164) {
                                            toast.warning({
                                                message: translation({
                                                    sv: "Du måste ange ett giltigt telefonnummer",
                                                    en: "You must enter a valid phone number",
                                                }),
                                                timeout:
                                                    durationInSecondsZodSchema.parse(
                                                        10,
                                                    ),
                                            });
                                            return;
                                        }
                                        await props.onSend(
                                            [
                                                payRequestRecipientFactory({
                                                    type: "SMS",
                                                    phoneNumber: _phoneNumber
                                                        .number
                                                        .e164 as PhoneNumber,
                                                    language: languageCode,
                                                    name: initialContactInfo?.firstName?.trim()
                                                        ? fullName({
                                                              firstName:
                                                                  initialContactInfo.firstName ??
                                                                  "Anonym kund",
                                                              lastName:
                                                                  initialContactInfo.lastName ??
                                                                  "",
                                                          })
                                                        : translation({
                                                              sv: "Kund",
                                                              en: "Customer",
                                                          }),
                                                }),
                                            ],
                                            initialContactInfo,
                                        );
                                    }
                                    if (selected === "both") {
                                        if (!emailAddress) {
                                            toast.warning({
                                                message: translation({
                                                    sv: "Du måste ange en e-postadress",
                                                    en: "You must enter an email address",
                                                }),
                                                timeout:
                                                    durationInSecondsZodSchema.parse(
                                                        10,
                                                    ),
                                            });
                                            return;
                                        }
                                        const _phoneNumber = parsePhoneNumber(
                                            phoneNumber ?? "",
                                        );
                                        if (!_phoneNumber?.number?.e164) {
                                            toast.warning({
                                                message: translation({
                                                    sv: "Du måste ange ett giltigt telefonnummer",
                                                    en: "You must enter a valid phone number",
                                                }),
                                                timeout:
                                                    durationInSecondsZodSchema.parse(
                                                        10,
                                                    ),
                                            });
                                            return;
                                        }
                                        await props.onSend(
                                            [
                                                payRequestRecipientFactory({
                                                    type: "EMAIL",
                                                    emailAddress,
                                                    language: languageCode,
                                                    name: initialContactInfo?.firstName?.trim()
                                                        ? fullName({
                                                              firstName:
                                                                  initialContactInfo.firstName ??
                                                                  "Anonym kund",
                                                              lastName:
                                                                  initialContactInfo.lastName ??
                                                                  "",
                                                          })
                                                        : translation({
                                                              sv: "Kund",
                                                              en: "Customer",
                                                          }),
                                                }),
                                                payRequestRecipientFactory({
                                                    type: "SMS",
                                                    phoneNumber: _phoneNumber
                                                        .number
                                                        .e164 as PhoneNumber,
                                                    language: languageCode,
                                                    name: initialContactInfo?.firstName?.trim()
                                                        ? fullName({
                                                              firstName:
                                                                  initialContactInfo.firstName ??
                                                                  "Anonym kund",
                                                              lastName:
                                                                  initialContactInfo.lastName ??
                                                                  "",
                                                          })
                                                        : translation({
                                                              sv: "Kund",
                                                              en: "Customer",
                                                          }),
                                                }),
                                            ],
                                            initialContactInfo,
                                        );
                                    }
                                }}>
                                <TextBox
                                    text={translation({
                                        sv: "Skicka",
                                        en: "Send",
                                    })}
                                />
                            </ButtonPrimaryLight>
                        </FlexRow>
                    </FlexColumn>
                </Conditional>
            </FlexColumn>
        </ButtonWhite>
    );
};

type PublicProps = Except<Props, "initialContactInfo">;
const OnPremisePayRequestPaymentButton = (props: PublicProps) => {
    const initialContactInfo =
        useCheckoutContext(context => context.contactInfo) || undefined;
    return (
        <OnPremisePayRequestPaymentButton__
            {...props}
            initialContactInfo={initialContactInfo}
        />
    );
};

const OnPremisePayRequestPaymentWithInitialContactInfoButton = (
    props: Props,
) => {
    return <OnPremisePayRequestPaymentButton__ {...props} />;
};

export {
    OnPremisePayRequestPaymentButton,
    OnPremisePayRequestPaymentWithInitialContactInfoButton,
};
