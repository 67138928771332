import { useModal } from "@@/modals/use-modal";
import { useHasAvailableExtrasOrOptionals } from "@@/products/bookables/booking-context";
import { BookingWizardModal } from "@@/products/bookables/booking-wizard/booking-wizard-modal";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { Product, Provider, translation } from "@towni/common";
import { useSessionAvailability } from "../generic/use-sessions-available";

type Props = {
    product: Product;
    provider: Provider;
    disabled?: boolean;
};
const _TableBooking_ModalButtonSection = (props: Props) => {
    const { product, provider } = props;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [{ show }, modalId] = useModal("table_booking_button__");

    const [
        hasAvailableSessions,
        ,
        { isLoading: sessionsAvailabilityIsLoading },
        // eslint-disable-next-line react-hooks/rules-of-hooks
    ] = useSessionAvailability(product);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const hasAvailableExtrasOrOptionals = useHasAvailableExtrasOrOptionals(
        product._id,
    );
    return (
        <>
            <FlexRow fillParentWidth mainAxis="flex-end" crossAxis="center">
                <ButtonPrimary
                    disabled={props.disabled || !hasAvailableSessions}
                    spin={sessionsAvailabilityIsLoading}
                    onClick={() => {
                        show();
                    }}
                    css={{ borderRadius: 3000 }}>
                    <TextBox
                        text={
                            !hasAvailableSessions
                                ? translation({
                                      sv: "Inga tider att boka",
                                      en: "Nothing available",
                                  })
                                : hasAvailableExtrasOrOptionals
                                  ? translation({
                                        sv: "Se tider & tillval",
                                        en: "See times & extras",
                                    })
                                  : translation({
                                        sv: "Se tider & boka",
                                        en: "See times & book",
                                    })
                        }
                    />
                </ButtonPrimary>
            </FlexRow>
            <BookingWizardModal
                modalId={modalId}
                provider={provider}
                product={product}
            />
        </>
    );
};

export { _TableBooking_ModalButtonSection };
