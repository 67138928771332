import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Inputs } from "@@/shared/form/standalones/inputs";
import { Icon } from "@@/shared/icons/icon";
import { PriceTextBox, TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { useTheme } from "@emotion/react";
import { faMoneyCheckDollarPen } from "@fortawesome/pro-regular-svg-icons";
import {
    emptyArrayOf,
    generateId,
    GiftCard_Product,
    isPrice,
    Price,
    priceForHumansFactory,
    translation,
} from "@towni/common";
import React, { useCallback, useMemo, useState } from "react";
import { z } from "zod";

const GiftCardPricePicker = (props: {
    readonly giftCard: GiftCard_Product;
    readonly className?: string;
    readonly onSelect?: (price: Price) => void;
}) => {
    const theme = useTheme();
    const [manualInputKey, setManualInputKey] = useState(generateId());
    console.log("GIFT CARD", props);
    const [manualAmountSelected, setManualAmountSelected] = useState(false);
    const [selectedAmountInclVat, setSelectedAmountInclVat] = useState<
        number | undefined
    >(() => props.giftCard.priceSettings?.defaultAmountInclVat);
    const priceOptions: Price[] =
        props.giftCard.priceSettings?.options ?? emptyArrayOf<Price>();
    const onSelect = useCallback(
        (
            price: Price | number,
            manualAmount: boolean,
        ): ((_event?: React.MouseEvent) => void) =>
            (_event?: React.MouseEvent) => {
                const _priceInput = isPrice(price)
                    ? price.amountIncludingVat / 100
                    : price;
                const _price = isPrice(price)
                    ? price
                    : priceForHumansFactory({
                          amountIncludingVat: _priceInput,
                          vat: props.giftCard.priceSettings?.vat ?? 0.25,
                      });

                console.log("SELECT", {
                    price,
                    _price,
                    _priceInput,
                    manualAmount,
                });
                setSelectedAmountInclVat(_priceInput);
                if (manualAmount) {
                    setManualAmountSelected(true);
                    props.onSelect?.(_price);
                } else {
                    setManualInputKey(generateId());
                    setManualAmountSelected(false);
                    const priceOption =
                        priceOptions.find(
                            price =>
                                price.amountIncludingVat ===
                                _price.amountIncludingVat,
                        ) || _price;
                    props.onSelect?.(priceOption);
                }
            },
        [priceOptions, props],
    );
    const isAPriceOptionSelected = useMemo(
        () =>
            priceOptions.some(
                opt =>
                    !!selectedAmountInclVat &&
                    opt.amountIncludingVat === selectedAmountInclVat * 100,
            ),
        [priceOptions, selectedAmountInclVat],
    );

    const inputZodSchema =
        typeof props.giftCard.priceSettings?.maxAmountInclVat !== "undefined"
            ? z
                  .number()
                  .int()
                  .min(props.giftCard.priceSettings?.minAmountInclVat ?? 1)
                  .max(props.giftCard.priceSettings.maxAmountInclVat)
            : z
                  .number()
                  .int()
                  .min(props.giftCard.priceSettings?.minAmountInclVat ?? 1);

    if (
        !priceOptions.length &&
        !props.giftCard.priceSettings?.freePricingEnabled
    )
        return (
            <FlexRow
                fillParentWidth
                crossAxis="center"
                className={props.className}
                css={{ padding: 20 }}>
                <TextBox
                    text={{
                        sv: "Inga priser tillgängliga",
                        en: "No prices available",
                    }}
                />
            </FlexRow>
        );
    return (
        <FlexColumn fillParentWidth crossAxis="stretch" css={{ padding: 20 }}>
            <FieldTitle
                text={{
                    sv: "Välj belopp för presentkort",
                    en: "Select a value for the gift card",
                }}
                size="ML"
            />
            <VerticalDivider S />
            <FlexRow css={{ flex: 1 }} wrap="wrap" wrapMargin={5}>
                <ForEach
                    getKey={price => price.amountIncludingVat}
                    itemOf={priceOptions}>
                    {price => (
                        <GiftCardPriceOption
                            price={price}
                            selected={
                                !!selectedAmountInclVat &&
                                price.amountIncludingVat ===
                                    selectedAmountInclVat * 100
                            }
                            onClick={onSelect(
                                price.amountIncludingVat / 100,
                                false,
                            )}
                        />
                    )}
                </ForEach>
            </FlexRow>
            <Conditional
                when={!!props.giftCard.priceSettings?.freePricingEnabled}>
                <VerticalDivider size={priceOptions.length ? "M" : "XS"} />
                <FieldTitle
                    text={
                        priceOptions.length
                            ? {
                                  sv: "Eller ange egen summa",
                                  en: "Or enter your own amount",
                              }
                            : {
                                  sv: "Ange belopp för presentkortet",
                                  en: "Enter a value for the gift card",
                              }
                    }
                    weight="400"
                />
                <VerticalDivider XS />
                <FlexRow css={{ flex: 1 }}>
                    <Inputs.NumberInput
                        key={manualInputKey}
                        preElement={({ textColor, touched }) => (
                            <Icon
                                icon={faMoneyCheckDollarPen}
                                size={1.2}
                                color={textColor}
                                css={{
                                    opacity: manualAmountSelected
                                        ? 1
                                        : touched
                                          ? 1
                                          : 0.4,
                                }}
                            />
                        )}
                        css={{
                            maxWidth: 280,
                            width: 280,
                        }}
                        validationTrigger={{
                            type: "blur",
                        }}
                        // hideErrors={isAPriceOptionSelected}
                        onChange={value => {
                            const _value = value ?? selectedAmountInclVat;
                            console.log("VALUE", {
                                _value,
                                value,
                                selectedAmountInclVat,
                                props,
                            });
                            setSelectedAmountInclVat(_value);
                            if (_value && _value > 0) {
                                onSelect(_value, true)();
                            }
                        }}
                        zodSchema={inputZodSchema}
                        onFocus={() => {
                            console.log("FOCUS", {
                                selectedAmountInclVat,
                                priceOptions,
                                manualAmountSelected,
                            });
                        }}
                        colorSet={
                            manualAmountSelected && !isAPriceOptionSelected
                                ? theme.colors.primary
                                : undefined
                        }
                        placeholder={translation({
                            sv: "Ange belopp",
                            en: "Enter amount",
                        })}
                    />
                </FlexRow>
            </Conditional>
        </FlexColumn>
    );
};

type GiftCardPriceOptionProps = {
    selected: boolean;
    price: Price;
    onClick: (event: React.MouseEvent) => void;
};

const GiftCardPriceOption = (props: GiftCardPriceOptionProps) => {
    const theme = useTheme();
    return (
        <ButtonWhite
            onClick={props.onClick}
            css={{
                borderRadius: 5,
                cursor: "pointer",
                backgroundColor: props.selected
                    ? theme.colors.primary.asString
                    : theme.colors.white.main.light95.asString,
            }}>
            <FlexColumn mainAxis="center" crossAxis="center">
                <PriceTextBox
                    price={props.price}
                    size="S"
                    weight="700"
                    color={
                        props.selected
                            ? theme.colors.default.background
                            : theme.colors.white.main.light55
                    }
                />
            </FlexColumn>
        </ButtonWhite>
    );
};

export { GiftCardPricePicker };
