import { ModalId } from "@@/modals/context/modal-id";
import { useModalContext } from "@@/modals/context/modal.context";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModalActions } from "@@/modals/use-modal-actions";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { useTheme } from "@emotion/react";
import { faHashtag, faTimes, faUndo } from "@fortawesome/pro-solid-svg-icons";
import { HexColor, RemSize, Translatable, hexColor } from "@towni/common";
import copy from "copy-to-clipboard";
import * as React from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";

type Props = {
    modalId: ModalId;
    title?: Translatable;
    initialColor: HexColor;
    onSelect: (color: HexColor) => void;
    onHide: () => void;
    disableRealtime?: boolean;
};

const ColorPickerModal = (props: Props) => {
    const theme = useTheme();
    const { title, initialColor, modalId } = props;
    const [color, setColor] = React.useState<HexColor>(initialColor);
    const { hide } = useModalActions(modalId);

    const onChange = (color: string) => {
        setColor(hexColor(color));
        if (!props.disableRealtime) props.onSelect(hexColor(color));
    };
    const undo = () => {
        setColor(props.initialColor);
        props.onSelect(hexColor(props.initialColor));
    };

    const modalStatus = useModalContext(
        context => context.modals.get(modalId)?.status,
    );
    React.useEffect(() => {
        if (modalStatus === "SHOW") {
            setColor(props.initialColor);
        }
    }, [modalStatus, props.initialColor]);

    return (
        <Modal modalId={modalId} onHide={props.onHide}>
            <FlexColumn
                crossAxis="center"
                background={{ color: theme.colors.default.background }}
                css={{
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                }}>
                <ModalHeader
                    modalId={modalId}
                    title={title}
                    topLeftButton={
                        <FlexRow mainAxis="flex-start">
                            <Icon
                                icon={faTimes}
                                onClick={hide}
                                size={1.2 as RemSize}
                            />
                            <HorizontalDivider M />
                            <Icon
                                icon={faUndo}
                                onClick={undo}
                                size={1 as RemSize}
                                css={{
                                    cursor:
                                        color !== props.initialColor
                                            ? "pointer"
                                            : undefined,
                                }}
                            />
                        </FlexRow>
                    }
                    topRightButton={{
                        _type: "TOP_RIGHT_ACTION_TEXT",
                        text: "Klar",
                        action: () => {
                            props.onSelect(color);
                            hide();
                        },
                    }}
                />
                <HexColorPicker
                    color={color}
                    onChange={onChange}
                    css={{
                        width: "100% !important",
                        paddingLeft: 20,
                        paddingRight: 20,
                    }}
                />
                <VerticalDivider M />
                <FlexRow
                    mainAxis="flex-start"
                    crossAxis="center"
                    padding={{ leftRight: 15, topBottom: 0 }}
                    radius={theme.radius}
                    background={{ color: theme.colors.textInput.background }}
                    css={{
                        border: `1px solid ${theme.colors.textInput.border.asString}`,
                        fontSize: "1.2rem",
                        color: theme.colors.textInput.text.asString,
                        marginLeft: 20,
                        marginRight: 20,
                    }}>
                    <Icon
                        icon={faHashtag}
                        color={theme.colors.textInput.placeholder}
                        onClick={() => {
                            copy(color);
                        }}
                    />
                    <HorizontalDivider />

                    <HexColorInput
                        color={color}
                        onChange={onChange}
                        css={{
                            fontSize: "1.2rem",
                            fontWeight: 800,
                            padding: 10,
                            minWidth: "30%",
                            backgroundColor:
                                theme.colors.textInput.background.asString,
                            color: theme.colors.textInput.placeholder.asString,
                        }}
                    />
                </FlexRow>
                {/*                 Maybe palett
                {props.presetColors ? (
                    <FlexColumn>
                        <ForEach itemOf={props.presetColors}>
                            {presetColor => {
                                return (
                                    <button
                                        style={{
                                            background: presetColor,
                                            width: 10,
                                            height: 10,
                                        }}
                                        onClick={() => onChange(presetColor)}
                                    />
                                );
                            }}
                        </ForEach>
                    </FlexColumn>
                ) : null} */}

                <VerticalDivider L />
            </FlexColumn>
        </Modal>
    );
};

export { ColorPickerModal };
