import { usePageViewContext } from "@@/backoffice/for-providers/pages/editor/page-view-context";
import { useFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, useQuery, UseQueryResult } from "@tanstack/react-query";
import {
    BlockContent,
    ContentId,
    emptyArrayOf,
    GetResponse,
    isContentId,
    isPageBlockId,
    PageBlockId,
} from "@towni/common";
import { useMemo } from "react";

function useBlockContentQuery(
    pageBlockId: PageBlockId,
    options?: {
        showInactive?: boolean;
    },
): UseQueryResult<GetResponse<BlockContent>>;
function useBlockContentQuery(
    contentIds: ContentId[],
    options?: {
        showInactive?: boolean;
    },
): UseQueryResult<GetResponse<BlockContent>>;
function useBlockContentQuery(
    contentId: ContentId | undefined | null,
    options?: {
        showInactive?: boolean;
    },
): UseQueryResult<GetResponse<BlockContent>>;
function useBlockContentQuery(
    input: PageBlockId | ContentId[] | ContentId | undefined | null,
    options?: {
        showInactive?: boolean;
    },
): UseQueryResult<GetResponse<BlockContent>> {
    const pageId = usePageViewContext(context => context.pageId);
    const fetchClient = useFetchClient();
    const pageBlockId = isPageBlockId(input) ? input : undefined;
    const contentIds = useMemo(
        () =>
            isContentId(input)
                ? [input]
                : Array.isArray(input)
                  ? [...input].sort()
                  : emptyArrayOf<ContentId>(),
        [input],
    );
    const queryFn: QueryFunction<GetResponse<BlockContent>> = async ({
        signal,
    }) => {
        return await fetchClient.get<GetResponse<BlockContent>>({
            route: pageBlockId
                ? `/pages/${pageId}/block-content/${pageBlockId}${
                      options?.showInactive ? "?si=true" : ""
                  }`
                : `/pages/${pageId}/block-content/?ids=${contentIds?.join(
                      ",",
                  )}${options?.showInactive ? "&si=true" : ""}`,
            customConfig: { signal },
        });
    };
    const result = useQuery<GetResponse<BlockContent>>({
        queryKey: [
            "block-content",
            pageId,
            pageBlockId,
            !!options?.showInactive,
            ...contentIds,
        ],
        queryFn,
        enabled: !!pageBlockId || contentIds.length > 0,
    });
    return result;
}

export { useBlockContentQuery };
