import { useTranslate } from "@@/translations/use-translate";
import { translation } from "@towni/common";
import { Except } from "type-fest";
import type { TextBoxProps } from "./text-box";
import { TextBox } from "./text-box";

type Props = {
    value: number | undefined;
    /**
     * fallback text to show if value is undefined \
     * defaults to `"0"`
     * @type {string}
     */
    fallback?: string;
    showSuffix?: boolean;
} & Except<TextBoxProps, "text" | "children">;

const NumberTextBox = (props: Props) => {
    const { fallback, value, showSuffix, ...passOnProps } = props;
    const translate = useTranslate();
    const nmbFormat = new Intl.NumberFormat(undefined, { useGrouping: true });
    const format = nmbFormat.format.bind(nmbFormat);

    const _fallback = fallback ?? "0";

    const suffix = translate(
        translation({
            sv: " st",
            en: " pcs",
        }),
    );

    return (
        <TextBox
            text={`${value ? format(value) : _fallback}${
                showSuffix ? suffix : ""
            }`}
            {...passOnProps}
        />
    );
};

export { NumberTextBox };
