import { ButtonDangerLight } from "@@/shared/buttons_v2/button-danger-light";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { css, useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { Padding, RemSize, Translatable, translation } from "@towni/common";
import { FC } from "react";

type Props = {
    /**
     * defaults to Spara/Save
     * @type {Translatable}
     */
    actionTitle?: Translatable;
    /**
     * defaults to Avbryt/Cancel
     * @type {Translatable}
     */
    cancelTitle?: Translatable;
    disabled?: boolean;
    isLoading?: boolean;
    onCancel?: () => void | Promise<void>;
    onAction?: () => void | Promise<void>;
    /**
     * defaults to { all: 20, top: 5 }
     * @type {Padding}
     */
    padding?: Padding;
    //maybe should be its own component
    isActionDanger?: boolean;
};

const ActionAndCancelButtons: FC<Props> = props => {
    const theme = useTheme();
    const padding = {
        top: 30,
        bottom: 20,
        ...(props.padding ? props.padding : {}),
    };
    const ActionButton = props.isActionDanger
        ? ButtonDangerLight
        : ButtonPrimary;
    return (
        <FlexRow
            fillParentWidth
            shrink={0}
            //  background={{ color: theme.colors.white }}

            background={{
                linearGradient: {
                    _type: "LINEAR_GRADIENT",
                    steps: [
                        {
                            color: theme.colors.default.background,
                            percentage: 0.0,
                        },
                        {
                            color: theme.colors.default.background,
                            percentage: 0.6,
                        },
                        { color: theme.colors.transparent, percentage: 1 },
                    ],
                },
            }}
            padding={padding}
            styling={css`
                pointer-events: none;
                > {
                    pointer-events: auto;
                }
            `}
            mainAxis="space-between"
            crossAxis="center">
            <ButtonWhite
                //buttonTheme="default"
                padding={{ leftRight: 15, topBottom: 10 }}
                disabled={props.isLoading}
                css={css`
                    flex-basis: 0;
                    flex-grow: 1;
                `}
                onClick={props.onCancel}>
                <TextBox
                    text={props.cancelTitle ?? text.cancel}
                    weight="700"
                    // color={theme.isDarkTheme ? "#808182" : "#6e737a"}
                    //  color={theme.colors.black.light20}
                />
            </ButtonWhite>
            <HorizontalDivider />
            <ActionButton
                disabled={props.isLoading || props.disabled}
                padding={{ leftRight: 15, topBottom: 10 }}
                css={css`
                    flex-basis: 0;
                    flex-grow: 1;
                    max-height: 52.5px;
                `}
                onClick={props.onAction}>
                {props.isLoading ? (
                    <Icon
                        icon={faSpinnerThird}
                        size={1.5 as RemSize}
                        spin={true}
                    />
                ) : (
                    <TextBox
                        text={props.actionTitle ?? text.save}
                        weight="700"
                        // color={theme.colors.primary.text}
                    />
                )}
            </ActionButton>
        </FlexRow>
    );
};
type DangerProps = Omit<Props, "isActionDanger">;

const DangerActionAndCancelButtons = (props: DangerProps) => {
    return <ActionAndCancelButtons {...props} isActionDanger />;
};

const text = {
    save: translation({
        sv: "Spara",
        en: "Save",
    }),
    cancel: translation({
        sv: "Avbryt",
        en: "Cancel",
    }),
};
export { ActionAndCancelButtons, DangerActionAndCancelButtons };
