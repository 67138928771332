import { useTranslate } from "@@/translations/use-translate";
import {
    asDate,
    DateRange,
    DAY,
    DurationInSeconds,
    HOUR,
    isDate,
    isoAndUnixFactory,
    IsoAndUnixTimestamp,
    joinTranslatables,
    MINUTE,
    RequiredTranslatable,
    TimeRange,
    Translatable,
    translation,
    WEEK,
    YEAR,
} from "@towni/common";
import { isThisYear } from "date-fns";
import { Except } from "type-fest";
import { useDateTimeFormatter } from "../use-date-time-formatter";
import { TextBox, TextBoxProps } from "./text-box";

type Format = "COMPACT" | "SHORT" | "MEDIUM" | "LONG" | "YEAR" | "DAY_OF_MONTH";

type Props = Except<TextBoxProps, "text" | "children"> & {
    readonly date: IsoAndUnixTimestamp | Date | undefined;
    /**
     * _Exempel_ \
     * COMPACT: `ÅÅ-MM-DD` \
     * SHORT: `ÅÅÅÅ-MM-DD` \
     * MEDIUM: `11:e mars` eller `11 mars 2020` \
     * LONG: `Måndag den 11:e mars` eller `Måndag den 11 mars 2020` \
     * _defaults to type `MEDIUM`
     */
    readonly format?: Format;
    /**
     * _defaults to `false` \
     * Can't be true together with `hideYear`
     * @type {boolean}
     */
    readonly forceIncludeYear?: boolean;
    /**
     * Can't be true together with `forceIncludeYear`
     * @type {boolean}
     */
    readonly hideYear?: boolean;
    readonly fallbackWhenUndefined?: string;
    readonly className?: string;
};

const useDateFormatterSelector = (format: Format) => {
    const dateFormatter = useDateTimeFormatter();
    switch (format) {
        case "COMPACT":
            return dateFormatter.formatDateShort;
        case "SHORT":
            return dateFormatter.formatDate;
        case "MEDIUM":
            return dateFormatter.formatDateMedium;
        case "LONG":
            return dateFormatter.formatDateLong;
        case "DAY_OF_MONTH":
            return dateFormatter.formatDayOfMonth;
        case "YEAR":
            return (
                dateTime: IsoAndUnixTimestamp,
                _options?: { includeYear?: boolean },
            ) => asDate(dateTime).getFullYear().toString();
        default:
            throw new Error(
                "Unhandled date format type; " + JSON.stringify(format),
            );
    }
};

const useDateRangeFormatted = (
    dateRange: DateRange | undefined,
    options?: {
        /**
         * _Exempel_ \
         * COMPACT: `ÅÅ-MM-DD` \
         * SHORT: `ÅÅÅÅ-MM-DD` \
         * MEDIUM: `11:e mars` eller `11 mars 2020` \
         * LONG: `Måndag den 11:e mars` eller `Måndag den 11 mars 2020` \
         * _defaults to type `MEDIUM`
         */
        readonly format?: Format;
        /**
         * _defaults to `false` \
         * Can't be true together with `hideYear`
         * @type {boolean}
         */
        readonly forceIncludeYear?: boolean;
        /**
         * Can't be true together with `forceIncludeYear`
         * @type {boolean}
         */
        readonly hideYear?: boolean;
        /** Defaults to `-` */
        readonly whenUndefined?: Translatable;
    },
): string => {
    const translate = useTranslate();
    const forceIncludeYear = !!options?.forceIncludeYear;
    const hideYear = !!options?.hideYear;
    const format = options?.format ?? "MEDIUM";
    const formatter = useDateFormatterSelector(format);
    if (forceIncludeYear && hideYear)
        throw new Error("force year and hide year can't be true together");

    if (!dateRange)
        return options?.whenUndefined ? translate(options.whenUndefined) : "-";

    const start = dateRange.start;
    const end = dateRange.end;

    const formattedStart = formatter(start, {
        includeYear:
            !hideYear && (!!forceIncludeYear || !isThisYear(asDate(start))),
    });
    const formattedEnd = formatter(end, {
        includeYear:
            !hideYear && (!!forceIncludeYear || !isThisYear(asDate(start))),
    });

    return formattedStart === formattedEnd
        ? formattedStart
        : `${formattedStart} - ${formattedEnd}`;
};

const DateTextBox = (props: Props) => {
    const { format, forceIncludeYear, hideYear, ...textBoxProps } = props;
    if (forceIncludeYear && hideYear)
        throw new Error("force year and hide year can't be true together");
    const date = isDate(props.date)
        ? isoAndUnixFactory(props.date)
        : props.date;
    const formatter = useDateFormatterSelector(format ?? "MEDIUM");
    const formattedDate = date
        ? formatter(date, {
              includeYear:
                  !hideYear &&
                  (!!forceIncludeYear || !isThisYear(asDate(date))),
          })
        : (props.fallbackWhenUndefined ?? "");
    return <TextBox {...textBoxProps} text={formattedDate} />;
};
const DateRangeTextBox = (props: {
    dateRange: DateRange | undefined;
    /**
     * _Exempel_ \
     * COMPACT: `ÅÅ-MM-DD` \
     * SHORT: `ÅÅÅÅ-MM-DD` \
     * MEDIUM: `11:e mars` eller `11 mars 2020` \
     * LONG: `Måndag den 11:e mars` eller `Måndag den 11 mars 2020` \
     * _defaults to type `MEDIUM`
     */
    readonly format?: Format;
    className?: string;
    /**
     * _defaults to `false` \
     * Can't be true together with `hideYear`
     * @type {boolean}
     */
    readonly forceIncludeYear?: boolean;
    /**
     * Can't be true together with `forceIncludeYear`
     * @type {boolean}
     */
    readonly hideYear?: boolean;
    /** Defaults to `-` */
    readonly whenUndefined?: string;
    readonly align?: TextBoxProps["align"];
    readonly color?: TextBoxProps["color"];
    readonly weight?: TextBoxProps["weight"];
    readonly size?: TextBoxProps["size"];
    readonly case?: TextBoxProps["case"];
}) => {
    const { forceIncludeYear, hideYear, ...textBoxProps } = props;

    const formattedDateRange = useDateRangeFormatted(props.dateRange, {
        format: props.format,
        forceIncludeYear: forceIncludeYear,
        hideYear: hideYear,
        whenUndefined: props.whenUndefined,
    });

    return <TextBox {...textBoxProps} text={formattedDateRange} />;
};

const TimeRangeTextBox = (props: {
    readonly timeRange: TimeRange | undefined;
    /** Defaults to `-` */
    readonly delimiter?: Translatable;
    readonly className?: string;
    /**
     * _defaults to `false` \
     * Can't be true together with `hideYear`
     * @type {boolean}
     */
    readonly forceIncludeYear?: boolean;
    /**
     * Can't be true together with `forceIncludeYear`
     * @type {boolean}
     */
    readonly hideYear?: boolean;
    /** Defaults to `-` */
    readonly whenUndefined?: string;
    readonly color?: TextBoxProps["color"];
    readonly weight?: TextBoxProps["weight"];
    readonly size?: TextBoxProps["size"];
    readonly hideDates?: boolean;
    readonly case?: TextBoxProps["case"];
}) => {
    const translate = useTranslate();
    const { forceIncludeYear, hideYear, ...textBoxProps } = props;
    const formatter = useDateTimeFormatter();

    if (forceIncludeYear && hideYear)
        throw new Error("force year and hide year can't be true together");

    if (!props.timeRange)
        return <TextBox {...textBoxProps} text={props.whenUndefined ?? "-"} />;

    const start = props.timeRange.start;
    const end = props.timeRange.end;

    const formattedStart = !props.hideDates
        ? formatter.formatDateAndTimeLong(start, {
              includeYear:
                  !hideYear &&
                  (!!forceIncludeYear || !isThisYear(asDate(start))),
          })
        : formatter.formatTime(start);
    const formattedEnd = !props.hideDates
        ? formatter.formatDateAndTimeLong(end, {
              includeYear:
                  !hideYear &&
                  (!!forceIncludeYear || !isThisYear(asDate(start))),
          })
        : formatter.formatTime(end);
    return (
        <TextBox
            {...textBoxProps}
            text={
                formattedStart === formattedEnd
                    ? formattedStart
                    : `${formattedStart} ${translate(props.delimiter ?? "-")} ${formattedEnd}`
            }
        />
    );
};

const svRelativeTimeShortFormatter = new Intl.RelativeTimeFormat("sv", {
    style: "short",
    numeric: "auto",
});
const enRelativeTimeShortFormatter = new Intl.RelativeTimeFormat("en", {
    style: "short",
    numeric: "auto",
});
const deRelativeTimeShortFormatter = new Intl.RelativeTimeFormat("de", {
    style: "short",
    numeric: "auto",
});

type TimeUnit =
    | "second"
    | "minute"
    | "hour"
    | "day"
    | "week"
    | "month"
    | "year";
const determineUnit = (seconds: DurationInSeconds): TimeUnit => {
    const _ms = Math.abs(seconds) * 1000;
    if (_ms < MINUTE) return "second";
    if (_ms < HOUR) return "minute";
    if (_ms < DAY) return "hour";
    if (_ms < WEEK) return "day";
    if (_ms < WEEK * 4) return "week";
    if (_ms < YEAR) return "month";
    return "year";
};

const getUnitValue = (seconds: DurationInSeconds, toUnit: TimeUnit): number => {
    const _ms = Math.abs(seconds) * 1000;
    if (toUnit === "second") return seconds;
    if (toUnit === "minute") return Math.round(_ms / MINUTE);
    if (toUnit === "hour") return Math.round(_ms / HOUR);
    if (toUnit === "day") return Math.round(_ms / DAY);
    if (toUnit === "week") return Math.round(_ms / WEEK);
    if (toUnit === "month") return Math.round((_ms / WEEK) * 4);
    return Math.round(_ms / YEAR);
};

const RelativeTimeTextBox = (props: {
    readonly timeRange: TimeRange | undefined;
    readonly className?: string;
    /** Defaults to `-` */
    readonly whenUndefined?: string;
    readonly color?: TextBoxProps["color"];
    readonly weight?: TextBoxProps["weight"];
    readonly size?: TextBoxProps["size"];
    readonly case?: TextBoxProps["case"];
    readonly align?: TextBoxProps["align"];
    readonly timeDirection: "future" | "past";
}) => {
    // const translate = useTranslate();
    const formatters = {
        sv: svRelativeTimeShortFormatter,
        en: enRelativeTimeShortFormatter,
        de: deRelativeTimeShortFormatter,
    };

    if (!props.timeRange)
        return <TextBox {...props} text={props.whenUndefined ?? "-"} />;
    const unit = determineUnit(props.timeRange.duration);
    const unitValue =
        props.timeDirection === "past"
            ? -getUnitValue(props.timeRange.duration, unit)
            : getUnitValue(props.timeRange.duration, unit);
    const formatted = translation({
        sv: formatters.sv.format(unitValue, unit),
        en: formatters.en.format(unitValue, unit),
        de: formatters.de.format(unitValue, unit),
    });

    return <TextBox {...props} text={formatted} />;
};

const DurationTextBox = (props: {
    readonly timeRange: TimeRange | undefined;
    readonly className?: string;
    /** Defaults to `-` */
    readonly whenUndefined?: string;
    readonly color?: TextBoxProps["color"];
    readonly weight?: TextBoxProps["weight"];
    readonly size?: TextBoxProps["size"];
    readonly case?: TextBoxProps["case"];
    readonly align?: TextBoxProps["align"];
    /** Defaults to `true` */
    readonly ignoreSeconds?: boolean;
}) => {
    if (!props.timeRange)
        return <TextBox {...props} text={props.whenUndefined ?? "-"} />;

    const durationInMs = props.timeRange.duration * 1000;
    // how many years are the durationInSeconds?
    const years = Math.floor(durationInMs / YEAR);
    const msLeftAfterYears = durationInMs % YEAR;
    // how many months are the durationInSeconds?
    const months = Math.floor(msLeftAfterYears / (WEEK * 4));
    const msLeftAfterMonths = msLeftAfterYears % (WEEK * 4);
    // how many weeks are the durationInSeconds?
    const weeks = Math.floor(msLeftAfterMonths / WEEK);
    const msLeftAfterWeeks = msLeftAfterMonths % WEEK;
    // how many days are the durationInSeconds?
    const days = Math.floor(msLeftAfterWeeks / DAY);
    const msLeftAfterDays = msLeftAfterWeeks % DAY;
    // how many hours are the durationInSeconds?
    const hours = Math.floor(msLeftAfterDays / HOUR);
    const msLeftAfterHours = msLeftAfterDays % HOUR;
    // how many minutes are the durationInSeconds?
    const minutes = Math.floor(msLeftAfterHours / MINUTE);
    const msLeftAfterMinutes = msLeftAfterHours % MINUTE;
    // how many seconds are the durationInSeconds?
    const seconds = Math.floor(msLeftAfterMinutes / 1000);

    const formatted = (() => {
        const parts: RequiredTranslatable[] = [];
        if (years > 0)
            parts.push(
                translation({
                    sv: `${years} år`,
                    en: `${years} years`,
                    de: `${years} Jahre`,
                }),
            );
        if (months > 0)
            parts.push(
                translation({
                    sv: `${months} månader`,
                    en: `${months} months`,
                    de: `${months} Monate`,
                }),
            );
        if (weeks > 0)
            parts.push(
                translation({
                    sv: `${weeks} veckor`,
                    en: `${weeks} weeks`,
                    de: `${weeks} Wochen`,
                }),
            );
        if (days > 0)
            parts.push(
                translation({
                    sv: `${days} dagar`,
                    en: `${days} days`,
                    de: `${days} Tage`,
                }),
            );
        if (hours > 0)
            parts.push(
                translation({
                    sv: `${hours} timmar`,
                    en: `${hours} hours`,
                    de: `${hours} Stunden`,
                }),
            );
        if (minutes > 0)
            parts.push(
                translation({
                    sv: `${minutes} minuter`,
                    en: `${minutes} minutes`,
                    de: `${minutes} Minuten`,
                }),
            );
        if (props.ignoreSeconds === false && seconds > 0)
            parts.push(
                translation({
                    sv: `${seconds} sekunder`,
                    en: `${seconds} seconds`,
                    de: `${seconds} Sekunden`,
                }),
            );
        if (parts.length === 0)
            return translation({ sv: "< 1s", en: "< 1s", de: "< 1s" });
        return joinTranslatables(parts, ", ");
    })();

    return <TextBox {...props} text={formatted} />;
};

export {
    DateRangeTextBox,
    DateTextBox,
    DurationTextBox,
    RelativeTimeTextBox,
    TimeRangeTextBox,
    useDateRangeFormatted,
};
