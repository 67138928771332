import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { TextBox } from "@@/shared/text";
import { useGiftCardsForOrder } from "@@/tickets/giftcards/use-giftcards";
import { css, useTheme } from "@emotion/react";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Order,
    TicketGiftCard,
    isGiftCard,
    productTypeIcon,
    translation,
} from "@towni/common";

type Props = { order: Order };

const OrderGiftCardCodes = ({ order }: Props) => {
    const hasGift = order.orderItems.filter(o => isGiftCard(o));
    const [giftCards] = useGiftCardsForOrder(order._id);
    if (hasGift.length === 0) return null;
    return (
        <>
            <FlexColumn tag="order_extras">
                <VerticalDivider M />

                <HorizontalLine />

                <VerticalDivider M />
                <FlexColumn padding={{ leftRight: 20 }}>
                    <ForEach
                        itemOf={giftCards}
                        getKey={gc => gc._id}
                        divider={<VerticalDivider />}>
                        {giftCard => {
                            return <GiftCardBox giftCard={giftCard} />;
                        }}
                    </ForEach>
                </FlexColumn>

                <VerticalDivider M />
            </FlexColumn>
        </>
    );
};

const GiftCardBox = ({ giftCard }: { giftCard: TicketGiftCard }) => {
    const theme = useTheme();
    const _isValid = giftCard.status === "ACTIVE";
    const color = _isValid ? theme.colors.primary : theme.colors.black;

    const splitGiftCode = [...giftCard.code]
        .map((d, i) => (i % 4 === 0 ? " " + d : d))
        .join("")
        .trim();

    return (
        <FlexColumn
            fillParentWidth
            css={{
                borderRadius: 8,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: theme.colors.black.light70.asString,
                opacity: _isValid ? 1 : 0.6,
            }}>
            <FlexRow
                mainAxis="flex-start"
                padding={{ leftRight: 15, topBottom: 10 }}>
                <FlexRow fillParent mainAxis="flex-start">
                    <FlexColumn
                        padding={{ all: 10 }}
                        css={{
                            borderRadius: 5,
                        }}>
                        <FontAwesomeIcon
                            icon={productTypeIcon.GIFTCARD}
                            style={{
                                fontSize: "1.5rem",
                                width: "30px",
                                color: color.asString,
                            }}
                        />
                    </FlexColumn>
                    <HorizontalDivider M />

                    <FlexColumn>
                        <TextBox
                            text={
                                _isValid
                                    ? texts.giftCard
                                    : texts.giftCardCancelled
                            }
                            weight="700"
                            color={theme.colors.white.main.light40}
                        />
                        <VerticalDivider XS />

                        <TextBox
                            css={css`
                                text-decoration: ${!_isValid
                                    ? `line-through`
                                    : ""};
                            `}
                            text={splitGiftCode}
                            weight="700"
                            color={theme.colors.white.main.light40}
                        />
                    </FlexColumn>

                    <HorizontalDivider XS />
                </FlexRow>
                <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{
                        fontSize: "2rem",
                        color: color.asString,
                    }}
                />
            </FlexRow>
        </FlexColumn>
    );
};

const texts = {
    giftCard: translation({ sv: "Presentkort", en: "Giftcard" }),
    giftCardCancelled: translation({
        sv: "Presentkort annullerat",
        en: "Giftcard cancelled",
    }),
};

export { OrderGiftCardCodes };
