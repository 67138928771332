import { browserLogger } from "@@/settings/browser-logger";
import * as Sentry from "@sentry/react";
import { ZodError, ZodSchema } from "zod";

const inMemoryStorage = new Map();
const storageType: "LOCAL" | "SESSION" | "MEMORY" = (() => {
    // Local storage check
    try {
        localStorage.setItem("TEST", "TEST");
        localStorage.removeItem("TEST");
        return "LOCAL";
    } catch (error) {
        browserLogger.log("local storage not available");
    }

    // Session storage check
    try {
        sessionStorage.setItem("TEST", "TEST");
        sessionStorage.removeItem("TEST");
        return "SESSION";
    } catch (error) {
        browserLogger.log("session storage not available");
    }
    Sentry.captureException(
        new Error(
            "User fell back to in memory storage, should still work but...",
        ),
    );
    return "MEMORY";
})();

const loadFromStorage = <T>(key: string, schema?: ZodSchema<T>) => {
    const deserialize: (json: string) => T | undefined = (json: string) => {
        try {
            if (!json?.trim()) return undefined;
            const parsed = JSON.parse(json);
            if (schema) return schema.parse(parsed);
            return parsed;
        } catch (error) {
            if (error instanceof ZodError) {
                Sentry.withScope(scope => {
                    scope.setExtra("key", key);
                    scope.setExtra("json", json);
                    const _error = new Error(
                        `Zod validation when loading "${key}" from storage failed. returning undefined.`,
                        { cause: error },
                    );
                    Sentry.captureException(_error);
                });
                return undefined;
            }

            // probably a plain string value
            // try returning the value if it's a string
            if (typeof json === "string") {
                return json;
            }

            // else throw error
            throw error;
        }
    };

    switch (storageType) {
        case "LOCAL": {
            const serialized = localStorage.getItem(key);
            return serialized ? deserialize(serialized) : undefined;
        }
        case "SESSION": {
            const serialized = sessionStorage.getItem(key);
            return serialized ? deserialize(serialized) : undefined;
        }
        case "MEMORY": {
            const serialized = inMemoryStorage.get(key);
            return serialized ? deserialize(serialized) : undefined;
        }
        default: {
            const error = new Error("Unhandled storage type");
            Sentry.captureException(error);

            throw error;
        }
    }
};

const saveToStorage = <T>(
    key: string,
    item: T,
    serialize: (item: T) => string = item => JSON.stringify(item),
): void => {
    const serialized = serialize(item);
    switch (storageType) {
        case "LOCAL": {
            localStorage.setItem(key, serialized);
            return;
        }
        case "SESSION": {
            sessionStorage.setItem(key, serialized);
            return;
        }
        case "MEMORY": {
            inMemoryStorage.set(key, serialized);
            return;
        }
        default: {
            const error = new Error("Unhandled storage type");
            Sentry.captureException(error);
            throw error;
        }
    }
};

const clearStorage = (key: string) => {
    switch (storageType) {
        case "LOCAL": {
            localStorage.removeItem(key);
            return;
        }
        case "SESSION": {
            sessionStorage.removeItem(key);
            return;
        }
        case "MEMORY": {
            inMemoryStorage.delete(key);
            return;
        }
        default: {
            const error = new Error("Unhandled storage type");
            Sentry.captureException(error);
            throw error;
        }
    }
};

export { loadFromStorage, saveToStorage, clearStorage, storageType };
