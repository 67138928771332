import { IsoAndUnixTimestamp } from "@towni/common";
import { useMemo } from "react";
import { useFullDateRangeContext } from "./date-range.context";
import { useDateSelectabilityMapContext } from "./date-selectability-map.context";
import { DateStatus } from "./date-status";
import { useSelectedDateRangeContext } from "./selected-date-range.context";

/**
 * Calculates the status of a given date based on the provided parameters.
 *
 * @param date - The date to determine the status for.
 * @returns The status of the date, which can be one of the following:
 *   - "SELECTED__SOLO": If the date is the only one selected date.
 *   - "SELECTED__FIRST": If the date is the first selected date in a range.
 *   - "SELECTED__LAST": If the date is the last selected date in a range.
 *   - "SELECTED__PART_OF_RANGE": If the date is part of a selected range, but not the first or last date.
 *   - "SELECTABLE__START_OF_RANGE": If the date is selectable as the start of a range.
 *   - "SELECTABLE__START_OF_NEW_RANGE": If the date is selectable as the start of a range but not in the current range.
 *   - "SELECTABLE__END_OF_RANGE": If the date is selectable as the end of a range.
 *   - "SELECTABLE__PART_OF_RANGE": If the date is selectable as part of a range, but not the start or end date.
 *   - "SELECTABLE__PART_OF_SELECTED_RANGE": If the date is selectable as part of the currently selected range.
 *   - "PASSED": Date is in the passed, and cannot be selected.
 *   - "UNAVAILABLE": If the date is not selectable and not selected.
 */
const useDateStatus = (date: IsoAndUnixTimestamp): DateStatus => {
    // First, lets get the metadata needed to determine the status of the date
    // const { timeZone } = useTimeZoneContext();
    const { passedBefore } = useFullDateRangeContext();
    const { firstSelected, lastSelected, selectedDatesIso } =
        useSelectedDateRangeContext();
    const isFirstSelected = firstSelected?.iso === date.iso;
    const isLastSelected = lastSelected?.iso === date.iso;
    const isSelected = selectedDatesIso.has(date.iso);
    const { selectabilityMap } = useDateSelectabilityMapContext();
    const selectability = selectabilityMap.get(date.iso);

    const status = useMemo((): DateStatus => {
        // Now... check if the current date is selected
        if (date.unix < passedBefore.unix) return "PASSED";
        if (isFirstSelected && isLastSelected) return "SELECTED__SOLO";
        if (isFirstSelected) return "SELECTED__FIRST";
        if (isLastSelected) return "SELECTED__LAST";
        if (isSelected) return "SELECTED__PART_OF_RANGE";
        const onlyOneDateSelected = selectedDatesIso.size === 1;
        // Ok, so it's not selected.
        // - Now, lets figure out if the date is selectable
        // - and if the date is clickable, not the same thing, it could be selectable
        // - but only as a part of a range, not a beginning or end of a range
        // - If a firstDate is selected already, we should be able to figure out if
        // - the current date is selectable as end of range or only as part of the range
        // - or neither, if firstDate and lastDate are the same, only one date is selected

        // So, current date is not selected.
        // If no date is selected, we can only check if the date is selectable as first of range
        if (!firstSelected && selectability?.asStartOfRange)
            return "SELECTABLE__START_OF_RANGE";

        // If no date is selected, we can also check if the date is selectable as end or part of range
        // Since no date is currently selected though they won't be selectable until a start date is selected
        if (
            !firstSelected &&
            !selectability?.asStartOfRange &&
            (!!selectability?.asEndOfRange?.possible ||
                !!selectability?.asPartOfRange?.possible)
        )
            return "SELECTABLE__PART_OF_RANGE";

        // If a date is selected already, we can check if the current date
        // is selectable as end or part of range
        if (firstSelected) {
            // Can it be selected as end of range?
            if (
                selectability?.asEndOfRange?.withStartAt?.has(firstSelected.iso)
            )
                return "SELECTABLE__END_OF_RANGE";
            // Can it be selected as part of range?
            if (
                selectability?.asPartOfRange?.withStartAt?.has(
                    firstSelected.iso,
                )
            )
                return "SELECTABLE__PART_OF_RANGE";

            // Can it be selected as a new start of range and let
            // the old start of range be part or end of the new range?
            if (selectability?.asStartOfRange) {
                if (onlyOneDateSelected) {
                    const firstSelectedSelectability = selectabilityMap.get(
                        firstSelected.iso,
                    );
                    if (!firstSelectedSelectability)
                        throw new Error("Cannot be undefined");
                    if (
                        firstSelectedSelectability.asEndOfRange.withStartAt?.has(
                            date.iso,
                        )
                    ) {
                        return "SELECTABLE__START_OF_RANGE";
                    }
                } else {
                    const lastSelectedSelectability = lastSelected?.iso
                        ? selectabilityMap.get(lastSelected?.iso)
                        : undefined;
                    if (!lastSelectedSelectability) {
                        throw new Error("Cannot be undefined"); // if there is a first there must be a last, even if it's the same
                    }
                    if (
                        lastSelectedSelectability.asEndOfRange.withStartAt?.has(
                            date.iso,
                        )
                    ) {
                        return "SELECTABLE__START_OF_RANGE";
                    }
                }
            }
        }

        if (selectability?.asStartOfRange)
            return "SELECTABLE__START_OF_NEW_RANGE";

        // If we get here, the date is not selectable and not selected
        return "UNAVAILABLE";
    }, [
        date.unix,
        date.iso,
        passedBefore.unix,
        isFirstSelected,
        isLastSelected,
        isSelected,
        selectedDatesIso.size,
        firstSelected,
        selectability?.asStartOfRange,
        selectability?.asEndOfRange?.possible,
        selectability?.asEndOfRange?.withStartAt,
        selectability?.asPartOfRange?.possible,
        selectability?.asPartOfRange?.withStartAt,
        selectabilityMap,
        lastSelected?.iso,
    ]);

    return status;
};

export { useDateStatus };
