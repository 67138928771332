import { useTranslate } from "@@/translations/use-translate";
import { css, useTheme } from "@emotion/react";
import { ColorItem, Padding, Translatable, colorAsString } from "@towni/common";
import * as React from "react";
import { FlexRow } from "../flex-containers";
import { paddingToCssValue } from "../padding";

type Props = {
    children: React.ReactNode;
    description?: Translatable;
    disabled?: boolean;
    onClick: (() => void) | undefined;
    backgroundColor?: ColorItem;
    backgroundColorGradientAlt?: ColorItem;
    borderColor?: ColorItem;
    textColor?: ColorItem;
    fillParentWidth?: boolean;
    padding?: Padding;
    opacity?: number;
    className?: string;
    removeBorder?: boolean; //Remove border to quickfix glitch with backgroundColorGradientAlt
};
const PillButton = ({
    children,
    description,
    onClick: onClick,
    disabled,
    ...props
}: Props) => {
    const theme = useTheme();
    const translate = useTranslate();

    const backgroundColor = colorAsString(
        props.backgroundColor || theme.colors.primary,
    );
    const backgroundColorGradientAlt = colorAsString(
        props.backgroundColorGradientAlt || backgroundColor,
    );

    const borderRadius = 3000;
    const styles = css({
        display: "flex",
        padding: props.padding ? paddingToCssValue(props.padding) : "4px 6px",
        border: `${props.removeBorder ? 0 : 1}px solid ${colorAsString(
            props.borderColor || props.backgroundColor || theme.colors.primary,
        )}`,
        borderRadius: borderRadius,
        background:
            typeof props.backgroundColorGradientAlt === "undefined"
                ? backgroundColor
                : `linear-gradient( 96.92deg, ${backgroundColorGradientAlt} 0%, ${backgroundColor} 100% )`,
        color: colorAsString(props.textColor || theme.colors.primary.text),
        textAlign: "center",
        cursor: "pointer",
        opacity: disabled ? 0.4 : 1,
        alignItems: "center",
        flexShrink: 0,
        justifyContent: "center",
        ":active": disabled
            ? {}
            : {
                  opacity: 0.7,
              },
    });

    return (
        <FlexRow
            onClick={() => {
                if (!disabled) onClick?.();
            }}
            css={{
                opacity: props.opacity,
            }}
            shrink={0}
            fillParentWidth={props.fillParentWidth}
            radius={borderRadius}
            overflow="hidden"
            crossAxis="stretch">
            <div
                css={styles}
                className={props.className}
                title={translate(description)}>
                {children}
            </div>
        </FlexRow>
    );
};

export { PillButton };
