import { useTanstackSingleQuery } from "@@/shared/use-tanstack-query";
import { Product, ProductId } from "@towni/common";
import { productMainQueryKey } from "../main-query-key";

const useProduct = (productId: ProductId | undefined) =>
    useTanstackSingleQuery<Product>(
        `/products/${productId}`,
        [productMainQueryKey, productId],
        {
            enabled: !!productId,
        },
    );

const useProductLegacy = (productId: ProductId | undefined) => {
    const query = useProduct(productId);
    return [query.data, query] as const;
};

export { useProduct, useProductLegacy };
