import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useModalContext } from "./modals/context/modal.context";
import { useHistoryContext } from "./pages/page-navigation/history-context";

const KeyboardListener = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [, keys] = useHistoryContext();

    const { modals, hideTop } = useModalContext(context => ({
        modals: context.modals,
        hideTop: context.hideTop,
    }));

    const escFunction = React.useCallback(
        (keyEvent: unknown) => {
            const event = keyEvent as React.KeyboardEvent;
            switch (event.key) {
                case "Escape": {
                    // Ignore if in input field
                    if (
                        ["INPUT", "SELECT", "TEXTAREA"].includes(
                            (event.target as HTMLElement).tagName.toUpperCase(),
                        )
                    ) {
                        break;
                    }
                    const openModals = Array.from(modals.values()).filter(
                        modal => modal.status === "SHOW",
                    );
                    if (openModals.length > 0) {
                        void hideTop();
                        break;
                    }
                    if (
                        keys.length > 1 && // if there are previous pages in app nav history
                        !location.pathname.startsWith("/pages/") && // if we are not on a page
                        location.pathname !== "/" // if we are not in root
                    ) {
                        navigate(-1);
                        break;
                    }
                }
            }
        },
        [modals, keys.length, location.pathname, hideTop],
    );

    React.useEffect(() => {
        document.addEventListener("keyup", escFunction, {
            passive: true,
        });

        return () => {
            document.removeEventListener("keyup", escFunction);
        };
    }, [escFunction]);

    return <React.Fragment />;
};

export { KeyboardListener };
