import { welcomeMessage } from "@@/authentication/me/egg/bunny";
import { meQuery } from "@@/authentication/me/me.query";
import { browserLogger } from "@@/settings";
import { apiFetchClient } from "@@/shared/fetch-client";
import { loadFromStorage, saveToStorage } from "@@/shared/local-storage";
import { useUrlStateBoolean } from "@@/shared/use-url-state";
import { useToast } from "@@/toasts/context/toast-context";
import * as Sentry from "@sentry/react";
import {
    UseQueryResult,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import {
    DurationInSeconds,
    Me,
    Permission,
    ProviderId,
    assertNever,
    fullName,
    generateId,
    hejalfredScope,
    parseScope,
} from "@towni/common";
import * as React from "react";
import { useEffect } from "react";

type State = readonly [
    me: Me | null,
    {
        signOut: () => Promise<void>; // sign out,
        anonymousTag: string;
        meQuery: UseQueryResult<Me | null>;
    },
];
const MeStateContext = React.createContext<State | undefined>(undefined);

type Props = {
    children?: React.ReactNode;
};
const MeProvider = (props: Props) => {
    const [noWelcomeToast] = useUrlStateBoolean({ key: "no-welcome-toast" });
    const [anonymousTag] = React.useState(() => {
        const fromStorage = loadFromStorage<string>("ano");
        if (fromStorage) return fromStorage;

        const newTag = generateId({ prefix: "anon_tag__" });
        saveToStorage<string>("ano", newTag);
        return newTag;
    });
    const [meQueryKey] = React.useState(["me"]);
    const toast = useToast();
    const queryClient = useQueryClient();
    const _meQuery = useQuery<Me | null>(meQuery);
    const me = _meQuery.data || null;

    useEffect(() => {
        if (!me) {
            Sentry.setUser({
                id: anonymousTag,
            });
            return;
        }

        // A new user has been signed in
        if (!noWelcomeToast) {
            const welcome = welcomeMessage(me);
            // if (Array.isArray(welcome.confetti)) {
            //     confetti({
            //         scalar: 2,
            //         spread: 180,
            //         particleCount: 100,
            //         ticks: 300,
            //         origin: { y: -0.4 },
            //         startVelocity: -45,
            //         shapes: welcome.confetti,
            //     });
            // }
            toast.success({
                title: welcome.message,
                message: fullName(me),
                timeout: 2 as DurationInSeconds,
                dataTestId: "welcome-toast",
            });
        }

        Sentry.setUser({
            id: me._id,
            username: me.firstName,
            scopes: me.scopes,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [anonymousTag, me?._id, toast]);

    const signOut = React.useCallback(async () => {
        await apiFetchClient.post({
            route: "/authentications/sign-out",
            body: {
                _type: "SIGN_OUT_COMMAND",
            },
        });
        queryClient.removeQueries({ queryKey: meQueryKey });
        browserLogger.log("sign out");
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        _meQuery
            .refetch()
            .then(data => {
                browserLogger.log("SIGN OUT", { after: "yes", data, _meQuery });
            })
            .catch(browserLogger.error);
    }, [queryClient, meQueryKey, _meQuery, me]);

    const state = React.useMemo(
        () =>
            [
                me,
                {
                    signOut,
                    anonymousTag,
                    meQuery: _meQuery,
                },
            ] as const,
        [anonymousTag, me, _meQuery, signOut],
    );

    return (
        <MeStateContext.Provider value={state}>
            {props.children}
        </MeStateContext.Provider>
    );
};

const useMe = () => React.useContext(MeStateContext) as State;

const useHasAlfredScope = () => {
    const [me] = useMe();
    const hasHejAlfredScope = me?.scopes.includes(hejalfredScope);
    return hasHejAlfredScope;
};

type UseHasProviderScopeInput = [
    providerId: ProviderId,
    requestedPermission: Permission,
];
const useHasProviderScope = (input: UseHasProviderScopeInput[]) => {
    const [me] = useMe();
    const hasProviderScope = React.useMemo(() => {
        if (!me?.scopes?.length) return false;
        const result =
            me?.scopes
                // Ignore checking hejalfred scopes and
                // other non provider scopes
                .filter(scope => scope.startsWith("provider_"))
                .some(userScope => {
                    const parsedUserScope = parseScope(userScope);
                    const _hasProviderScope = input.some(
                        ([providerId, requestedPermission]) => {
                            const hasProviderAccess =
                                parsedUserScope.segments[0] === providerId;
                            const userPermission = parsedUserScope.permission;
                            switch (requestedPermission) {
                                case "owner":
                                    // If owner permission is requested
                                    // only owners should have access
                                    return (
                                        hasProviderAccess &&
                                        userPermission === "owner"
                                    );
                                case "write":
                                    // If write permission is requested
                                    // writers and owners should have access
                                    return (
                                        hasProviderAccess &&
                                        (userPermission === "write" ||
                                            userPermission === "owner")
                                    );
                                case "read":
                                    // If read permission is requested
                                    // everyone with access to the provider
                                    // should have access
                                    return hasProviderAccess;
                                default:
                                    assertNever(requestedPermission);
                                    break;
                            }
                        },
                    );
                    return _hasProviderScope;
                }) || false;
        return result;
    }, [me?.scopes, input]);
    return hasProviderScope;
};

export { MeProvider, useHasAlfredScope, useHasProviderScope, useMe };
export type { UseHasProviderScopeInput };
