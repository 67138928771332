import {
    TanstackQueryOptions,
    TanstackQueryOptionsForUserQueries,
    useTanstackSingleQueries,
    useTanstackSingleQuery,
} from "@@/shared/use-tanstack-query";
import { UseQueryResult } from "@tanstack/react-query";
import {
    isStorageItemImageId,
    isStorageItemImageReference,
    StorageItemImage,
    StorageItemImageId,
    StorageItemImageReference,
} from "@towni/common";
import { useMemo } from "react";
import { storageItemMainQueryKey } from "../main-query-key";

const useStorageItemImageReferenceQuery = (
    imageRef: StorageItemImageId | StorageItemImageReference | undefined,
    options: TanstackQueryOptions<StorageItemImage> = {},
): UseQueryResult<StorageItemImage> => {
    const storageItemId = isStorageItemImageReference(imageRef)
        ? imageRef.storageItemId
        : imageRef; // quick fix

    return useTanstackSingleQuery<StorageItemImage>(
        `/storage-items/${storageItemId}`,
        [storageItemMainQueryKey, storageItemId],
        {
            ...options,
            enabled:
                options.enabled !== false &&
                isStorageItemImageId(storageItemId),
        },
    );
};
/**
 * Will keep this for a while instead of changing
 * all the files currently using the hook
 * @param imageRef
 * @param options
 * @returns
 */
const useStorageItemImageReference = (
    imageRef: StorageItemImageId | StorageItemImageReference | undefined,
    options: TanstackQueryOptions<StorageItemImage> = {},
): readonly [
    StorageItemImage | undefined,
    UseQueryResult<StorageItemImage>,
] => {
    const queryResult = useStorageItemImageReferenceQuery(imageRef, options);

    return useMemo(() => {
        return [queryResult.data, queryResult] as const;
    }, [queryResult]);
};

const useStorageItemImageReferenceQueries = (
    storageItemImageIds: StorageItemImageId[],
    options: TanstackQueryOptionsForUserQueries<StorageItemImage> = {},
) => {
    const queryData = storageItemImageIds.map(id => {
        return {
            route: `/storage-items/${id}`,
            queryKey: [storageItemMainQueryKey, id],
            options: {
                ...options,
                enabled: options.enabled !== false && isStorageItemImageId(id),
            },
        };
    });
    return useTanstackSingleQueries<StorageItemImage>(queryData);
};

export {
    useStorageItemImageReference,
    useStorageItemImageReferenceQueries,
    useStorageItemImageReferenceQuery,
};
