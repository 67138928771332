import { useEnvironmentRuntimeSettings } from "@@/settings/app-settings/environment-settings-context";
import { useStorageItemImageReferenceQueries } from "@@/storage-items/queries/use-storage-item-reference";
import {
    exists,
    getImageUrlFactory,
    ImageRef,
    ImageUrlCreatorOptions,
    isStorageItemImageId,
} from "@towni/common";
import { useCallback, useMemo } from "react";

const useImageUrl = () => {
    const { MEDIA_ENDPOINT } = useEnvironmentRuntimeSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const result = useCallback(getImageUrlFactory(MEDIA_ENDPOINT), [
        MEDIA_ENDPOINT,
    ]);
    return result;
};

const useGetImageUrlsFromImageRefs = (
    imageSources: ImageRef[],
    options: ImageUrlCreatorOptions = {},
): {
    /** The index corresponding to the imageSources index */
    index: number;
    /** The image url */
    url: string | undefined;
}[] => {
    const getImageUrl = useImageUrl();
    const storageItemImageIds = useMemo(
        () => imageSources.filter(imgSrc => isStorageItemImageId(imgSrc)),
        [imageSources],
    );
    const storageItemImageQuery =
        useStorageItemImageReferenceQueries(storageItemImageIds);
    const storageItemImages = storageItemImageQuery
        .map(query => query.data)
        .filter(exists);
    const urls = useMemo(() => {
        const result = imageSources.map((imgSrc, index) => {
            storageItemImages.forEach(img => img._id);
            if (isStorageItemImageId(imgSrc)) {
                const storageItemImage = storageItemImages.find(
                    img => img._id === imgSrc,
                );
                return {
                    index,
                    url: getImageUrl(storageItemImage, options),
                };
            }
            return {
                index,
                url: getImageUrl(imgSrc, options),
            };
        });
        return result.filter(exists);
    }, [imageSources, storageItemImages, getImageUrl, options]);

    return urls;
};

export { useGetImageUrlsFromImageRefs, useImageUrl };
