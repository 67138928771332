import { useWizardDispatch, useWizardState } from "@@/pages/wizard-context";
import { browserLogger } from "@@/settings";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { queryClient } from "@@/shared/queries/query-client";
import { TextBox } from "@@/shared/text";
import { VerificationCodeInput } from "@@/shared/text/text-input";
import { useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/react";
import { Translatable, isApiError, translation } from "@towni/common";
import * as React from "react";
import { useMe } from "../../me/me-context";
import {
    usePhoneSignInOrUpDispatch,
    usePhoneSignInOrUpState,
} from "../phone-sign-in-or-sign-in-context";
import { usePhoneSignInInitiateCommand } from "../use-phone-sign-in-initiate-command";
import { usePhoneSignInVerifyCommand } from "../use-phone-sign-in-verify-command";

const PhoneVerification = () => {
    const theme = useTheme();
    const wizardState = useWizardState();
    const wizardDispatch = useWizardDispatch();

    const state = usePhoneSignInOrUpState();
    const [dispatch] = usePhoneSignInOrUpDispatch();
    const verifyPhoneSignIn = usePhoneSignInVerifyCommand();
    const reInitiatePhoneSignIn = usePhoneSignInInitiateCommand();
    const [errorMessage, setErrorMessage] = React.useState<Translatable>("");
    const [sendingCode, setSendingCode] = React.useState(false);
    const [me] = useMe();

    React.useEffect(() => {
        if (!me) return;
        // User has been signed in
        // Nothing more to do
        wizardDispatch.finish();
    }, [me, wizardDispatch]);

    const disabled = verifyPhoneSignIn.isPending;

    React.useEffect(() => {
        if (disabled) return;
        setErrorMessage("");
        if (state.data.verificationCode?.toString().length === 6) {
            // Trigger verification
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            verifyPhoneSignIn
                .mutateAsync({
                    phoneNumber: state.data.phoneNumber,
                    verificationCode: state.data.verificationCode,
                })
                .catch(error => {
                    //Dont log 401 errors to sentry when login fails
                    if (isApiError(error) && error.statusCode !== 401) {
                        Sentry.captureException(error);
                    }
                    browserLogger.error(error);
                    setErrorMessage(text.verificationFailed);
                    return;
                })
                .then(verificationResult => {
                    if (!verificationResult) {
                        setErrorMessage(text.verificationFailedCheckCode);
                        return;
                    }

                    switch (verificationResult.mode) {
                        case "SIGN_UP": {
                            if (!wizardState.isLast) {
                                // Probably a sign up step
                                // After this one
                                wizardDispatch.goForward();
                            } else {
                                // Not doing sign ups here
                                // Can't sign in when no user exists
                                setErrorMessage(
                                    text.userPhoneNumberMatchNotFound(
                                        state.data.phoneNumber,
                                    ),
                                );
                            }
                            break;
                        }
                        case "SIGN_IN": {
                            void queryClient.invalidateQueries({
                                queryKey: ["orders"],
                            });
                            void queryClient.invalidateQueries({
                                queryKey: ["order-groups"],
                            });
                            wizardDispatch.finish();
                            break;
                        }
                    }
                });
        }
    }, [state.data.verificationCode]);

    return (
        <FlexColumn
            overflowY="scroll"
            crossAxis="center"
            padding={{ leftRight: 20 }}>
            <TextBox text={text.enterCodeYouRecieved} weight="700" size={1.3} />
            <VerticalDivider />
            <FlexRow wrap="wrap" mainAxis="center" crossAxis="flex-start">
                <TextBox
                    align="center"
                    text={text.weJustSent}
                    weight="300"
                    size="ML"
                />
                <FlexRow mainAxis="flex-start">
                    <TextBox
                        align="center"
                        onClick={() => wizardDispatch.goBack()}
                        text={state.data.phoneNumber}
                        weight="300"
                        size="ML"
                        color={theme.colors.primary}
                    />
                    <TextBox
                        align="center"
                        text={text.enterItBelow}
                        weight="300"
                        size="ML"
                    />
                </FlexRow>
            </FlexRow>
            <VerticalDivider XL />
            <FlexRow fillParentWidth crossAxis="flex-start">
                <VerificationCodeInput
                    fillParentWidth
                    length={6}
                    disabled={disabled}
                    onChange={dispatch.setVerificationCode}
                    errorMessage={
                        verifyPhoneSignIn.isError
                            ? (verifyPhoneSignIn.error?.message ??
                              errorMessage ??
                              text.verificationFailed)
                            : (errorMessage ?? "")
                    }
                />
                {disabled ? (
                    <FontAwesomeIcon
                        icon={faSpinnerThird}
                        color={theme.colors.textInput.placeholder.asString}
                        spin
                        style={{
                            position: "relative",
                            zIndex: 100,
                            marginLeft: -34,
                            marginTop: 18,
                        }}
                    />
                ) : null}
            </FlexRow>
            <VerticalDivider XL />
            <TextBox
                text={text.sendCodeAgain}
                case="UPPERCASE"
                color={
                    sendingCode
                        ? theme.colors.black.light40
                        : theme.colors.textInput.placeholder
                }
                size="S"
                onClick={async () => {
                    if (!state.data.phoneNumber || sendingCode) return;
                    setSendingCode(true);
                    try {
                        await reInitiatePhoneSignIn.mutateAsync({
                            phoneNumber: state.data.phoneNumber,
                            signUpId: state.data._id,
                        });
                    } catch (error) {
                        if (isApiError(error)) {
                            // Dont log 429 errors to sentry as they are when the user has sent too many requests
                            if (error.statusCode === 429) {
                                browserLogger.error(error);
                            } else {
                                Sentry.captureException(error);
                            }
                        } else {
                            Sentry.captureException(error);
                        }
                        setErrorMessage(text.failedToSendNewCode);
                    } finally {
                        setTimeout(() => {
                            setSendingCode(false);
                        }, 1000);
                    }
                }}
            />
            <VerticalDivider XS />
            <FontAwesomeIcon
                icon={faSpinnerThird}
                color={theme.colors.black.light40.asString}
                spin
                style={{ visibility: sendingCode ? "visible" : "hidden" }}
            />
        </FlexColumn>
    );
};

const text = {
    verificationFailed: translation({
        sv: "Verifiering misslyckades",
        en: "Verification failed",
    }),
    verificationFailedCheckCode: translation({
        sv: "Verifiering misslyckades, kontrollera koden",
        en: "Verification failed, double check you verification code",
    }),
    userPhoneNumberMatchNotFound: (phoneNumber: string): Translatable => {
        return translation({
            sv: `Hittade ingen användare med telefonnummer ${phoneNumber}`,
            en: `User with phone number ${phoneNumber} not found`,
        });
    },
    enterCodeYouRecieved: translation({
        sv: "Ange koden du fått",
        en: "Enter the code you received",
    }),
    weJustSent: translation({
        sv: `Vi skickade precis en sexsiffrig kod till `,
        en: `We just sent a six-digit code to `,
    }),
    enterItBelow: translation({
        sv: `, ange den nedan`,
        en: `, enter it below`,
    }),
    sendCodeAgain: translation({
        sv: "Skicka kod igen",
        en: "Send code again",
    }),
    failedToSendNewCode: translation({
        sv: "Misslyckades att skicka ny kod, avvakta en stund och försök igen",
        en: "Failed to send a new verification code, wait a bit and try again",
    }),
};

export { PhoneVerification };
