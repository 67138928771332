import { useFetchClient } from "@@/shared/fetch-client";
import {
    type MutationFunction,
    useMutation,
    UseMutationOptions,
} from "@tanstack/react-query";
import {
    chargeTokenizedCardCommandFactory,
    type ChargeTokenizedCardCommandFactoryParams,
} from "@towni/common";
import type { Except } from "type-fest";

const useChargeTokenizedCardWithStripeMutation = (
    options: Except<
        UseMutationOptions<
            unknown,
            Error,
            ChargeTokenizedCardCommandFactoryParams
        >,
        "mutationFn"
    > = {},
) => {
    const fetchClient = useFetchClient();
    const mutationFn: MutationFunction<
        unknown,
        ChargeTokenizedCardCommandFactoryParams
    > = async _command => {
        const command = chargeTokenizedCardCommandFactory(_command);
        if (!command.tokenizedCardData?.tokenizedCardNumber) return;
        return await fetchClient.post({
            route: "/payments/stripe/charge-tokenized-card",
            body: command,
        });
    };
    return useMutation<unknown, Error, ChargeTokenizedCardCommandFactoryParams>(
        {
            mutationFn,
            ...options,
        },
    );
};

export { useChargeTokenizedCardWithStripeMutation };
