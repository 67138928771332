import { useModal } from "@@/modals/use-modal";
import { useProviders } from "@@/providers/state/queries/providers/use-providers";
import { browserLogger } from "@@/settings";
import { AnimatedButton } from "@@/shared/buttons/animated-button";
import { Circle } from "@@/shared/circle";
import { VerticalDivider } from "@@/shared/dividers";
import { ErrorBoundary } from "@@/shared/error-boundary";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { isRunningAsPwa } from "@@/shared/is-running-as-pwa";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { Spacer } from "@@/shared/spacer";
import { TextBox } from "@@/shared/text";
import { use100vhMotionValue } from "@@/shared/use-100-vh";
import { useTranslate } from "@@/translations/use-translate";
import { css, useTheme } from "@emotion/react";
import { faShoppingCart } from "@fortawesome/pro-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { isProductOrderItem, translation } from "@towni/common";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { CartModal } from "./cart-modal";
import { useCartContext } from "./multi-carts/cart.context";

const ShowCartButton = () => {
    const theme = useTheme();
    const location = useLocation();
    const isDesktop = usePageBreakpoint({ when: "🖥" });

    const [{ show }, modalId] = useModal("cart_modal__");
    const queryClient = useQueryClient();
    const { cart, clearCart } = useCartContext(context => ({
        cart: context.cart,
        clearCart: context.clearCart,
    }));

    const showCartModal = async () => {
        await queryClient.invalidateQueries({ queryKey: ["products"] });
        show();
    };

    const translate = useTranslate();
    const height = use100vhMotionValue();
    const orderItems = cart.orderItems;
    const providerIds = Array.from(
        new Set(cart.orderItems?.map(oi => oi.providerId) || []),
    );
    const [providers, { error, status }] = useProviders(providerIds);

    const itemCount = orderItems
        .filter(isProductOrderItem)
        .map(oi => oi.quantity.value)
        .reduce((sum, c) => sum + c, 0);

    const animatedCartButtonStyles = css({
        flexBasis: 0,
        backgroundColor: theme.colors.primary.asString,
        color: theme.colors.primary.text.asString,
        cursor: "pointer",
        borderRadius: 40,
        display: orderItems.length ? "block" : "none",
        flex: 0.95,
    });

    if (!cart) return <TextBox text="NO CART" />;
    if (status === "error") {
        browserLogger.warn(error || "Show cart button failed to render");
        clearCart();
    }

    const providerTitle = translate(providers?.[0]?.meta.title);
    const showLarger =
        isDesktop && location.pathname.toLowerCase().includes("/pages/");

    return (
        <>
            <ErrorBoundary>
                <motion.div
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                    }}
                    exit={{
                        opacity: 0,
                    }}
                    transition={theme.spring}
                    style={{ height }}
                    css={{
                        display: "block",
                        position: "absolute",
                        pointerEvents: "none",
                        width: "100%",
                        zIndex: 9999,
                        top: 0,
                        left: 0,
                    }}>
                    <FlexColumn
                        fillParent
                        mainAxis="flex-end"
                        crossAxis="center">
                        <FlexRow
                            css={{ pointerEvents: "all" }}
                            mainAxis="center"
                            padding={{
                                top: 20,
                                bottom: isRunningAsPwa
                                    ? 25
                                    : showLarger
                                      ? 40
                                      : 20,
                            }}
                            fillParentWidth>
                            <Spacer grow={0.2} shrink={0} />
                            <AnimatedButton
                                styling={animatedCartButtonStyles}
                                onClick={showCartModal}
                                baseColor={theme.colors.primary.main.value.withAlpha(
                                    0.9,
                                )}
                                css={{ maxWidth: 420 }}
                                pressedColor={theme.colors.primary.dark}>
                                <FlexRow
                                    mainAxis="space-between"
                                    crossAxis="center"
                                    padding={{
                                        leftRight: 10,
                                        topBottom: 10,
                                    }}>
                                    <FlexRow
                                        basis={0.2}
                                        padding={{ left: 13 }}
                                        mainAxis="flex-start">
                                        <Icon
                                            icon={faShoppingCart}
                                            size={showLarger ? 1.4 : "L"}
                                            color={theme.colors.primary.text}
                                        />
                                    </FlexRow>
                                    <FlexRow
                                        basis={0.6}
                                        mainAxis="center"
                                        css={{ fontSize: "1.1rem" }}>
                                        <FlexColumn crossAxis="center">
                                            <TextBox
                                                text={translation({
                                                    sv: "KUNDKORG",
                                                    en: "SHOPPING CART",
                                                })}
                                                color={
                                                    theme.colors.primary.text
                                                }
                                                size={showLarger ? 0.8 : "XXS"}
                                                weight={
                                                    showLarger ? "700" : "900"
                                                }
                                                case="UPPERCASE"
                                            />
                                            <VerticalDivider
                                                size={
                                                    showLarger ? "3XS" : "XXS"
                                                }
                                            />
                                            <TextBox
                                                text={providerTitle}
                                                color={theme.colors.primary.text.withAlpha(
                                                    0.7,
                                                )}
                                                align="center"
                                                weight={
                                                    showLarger ? "700" : "900"
                                                }
                                                size={
                                                    (providerTitle?.length ??
                                                        0) <= 13
                                                        ? showLarger
                                                            ? 1.3
                                                            : 1
                                                        : (providerTitle?.length ??
                                                                0) <= 18
                                                          ? showLarger
                                                              ? 1.25
                                                              : 0.95
                                                          : showLarger
                                                            ? 1.2
                                                            : 0.8
                                                }
                                            />
                                        </FlexColumn>
                                    </FlexRow>
                                    <FlexRow basis={0.2} mainAxis="flex-end">
                                        <Circle
                                            size={36}
                                            backgroundColor={theme.colors.black.value.withAlpha(
                                                0.1,
                                            )}>
                                            <TextBox
                                                text={`${itemCount}`}
                                                color={
                                                    theme.colors.primary.text
                                                }
                                                weight={
                                                    showLarger ? "700" : "900"
                                                }
                                                size={showLarger ? 1.4 : "L"}
                                                css={{ lineHeight: 1 }}
                                            />
                                        </Circle>
                                    </FlexRow>
                                </FlexRow>
                            </AnimatedButton>
                            <Spacer grow={0.2} shrink={0} />
                        </FlexRow>
                    </FlexColumn>
                </motion.div>
                {orderItems.length ? (
                    <div
                        css={{
                            height: 100,
                            position: "absolute",
                            left: 0,
                            bottom: 0,
                            right: 0,
                            zIndex: 999,
                            label: "white-shadow",
                            background: isDesktop
                                ? undefined
                                : "linear-gradient(0deg, hsla(0,0%,100%,1), hsla(0,0%,100%,0.001))",
                        }}></div>
                ) : null}
            </ErrorBoundary>
            <CartModal modalId={modalId} />
        </>
    );
};

export { ShowCartButton };
