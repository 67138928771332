import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { Translatable } from "@towni/common";

type Props = {
    onClick?: () => void | Promise<void>;
    spin?: boolean;
    title: Translatable;
    icon: IconDefinition;
    label: JSX.Element;
    disabled?: boolean;
};

const OnPremisePaymentButton = (props: Props) => {
    const theme = useTheme();
    const { spin, onClick, disabled } = props;

    return (
        <ButtonWhite
            spin={spin}
            disabled={disabled}
            css={{
                flex: 1,
                width: "auto",
                maxHeight: 55,
                padding: 20,
                backgroundColor: theme.colors.default.background.asString,
                borderColor: theme.isDarkTheme
                    ? theme.colors.black.light50.asString
                    : theme.colors.black.light80.asString,
            }}
            contentContainerCss={{
                flex: 1,
            }}
            textColor={
                theme.isLightTheme
                    ? theme.colors.black.light10
                    : theme.colors.black.light90
            }
            onClick={async () => {
                if (spin || disabled) return;
                await onClick?.();
            }}>
            <FlexRow
                css={{ flex: 1 }}
                mainAxis="space-between"
                crossAxis="center">
                <FlexRow
                    css={{ flex: 1 }}
                    mainAxis="flex-start"
                    crossAxis="center">
                    <Icon icon={props.icon} fixedWidth />
                    <HorizontalDivider S />
                    <TextBox text={props.title} />
                </FlexRow>
                <HorizontalDivider S />
                {props.label}
            </FlexRow>
        </ButtonWhite>
    );
};

export { OnPremisePaymentButton };
