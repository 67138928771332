import { useTheme } from "@emotion/react";
import { useMemo } from "react";
import { GroupBase, StylesConfig } from "react-select";

const useDefaultReactSelectStyles = <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    hasErrors = false,
): StylesConfig<Option, IsMulti, Group> => {
    const theme = useTheme();
    return useMemo(
        (): StylesConfig<Option, IsMulti, Group> => ({
            option: (current, state) => ({
                ...current,
                paddingTop: 12,
                paddingBottom: 12,
                "&:hover": {
                    backgroundColor: theme.colors.primary.asString,
                    color: theme.colors.primary.text.asString,
                },
                ...(state.isSelected || state.isFocused
                    ? {
                          backgroundColor: theme.isDarkTheme
                              ? theme.colors.primary.asString
                              : theme.colors.primary.light.asString,
                          color: theme.colors.textInput.text.asString,
                      }
                    : {}),
            }),
            singleValue: current => ({
                ...current,
                color: theme.colors.textInput.text.asString,
            }),
            multiValue: current => ({
                ...current,
                backgroundColor: theme.colors.black.light90.asString,
                borderRadius: 4,
                padding: 5,
                color: theme.colors.default.text.asString,
            }),
            multiValueRemove: current => ({
                ...current,
                color: theme.colors.white.main.light40.asString,
            }),
            multiValueLabel: current => ({
                ...current,
                color: theme.colors.white.main.light40.asString,
            }),
            container: (current, state) => ({
                ...current,

                ...(state.isFocused
                    ? {
                          borderColor: theme.colors.primary.asString,
                      }
                    : {}),
            }),
            valueContainer: (current, _state) => ({
                ...current,
                minHeight: 40,
            }),
            control: (current, state) => ({
                ...current,
                borderRadius: 6,
                paddingTop: 5,
                paddingBottom: 5,
                // width: 100,
                flex: 1,
                border: `1px solid ${
                    state.isFocused
                        ? theme.colors.textInput.border.asString
                        : theme.colors.textInput.border.asString
                }`,
                boxShadow: state.isFocused
                    ? `0 0 0 1px #${theme.colors.textInput.border.asString}`
                    : "none",
                ...(state.isMulti
                    ? {
                          backgroundColor:
                              theme.colors.textInput.background.asString,
                      }
                    : {
                          backgroundColor:
                              theme.colors.textInput.background.asString,
                      }),
                "&:hover": {
                    border: `1px solid ${theme.colors.black.light80.asString}`,
                },
                ...(hasErrors
                    ? {
                          borderColor: theme.colors.warning.asString,
                          backgroundColor: theme.colors.warning.light.asString,
                      }
                    : {}),
            }),
            placeholder: current => ({
                ...current,
                color: theme.colors.textInput.placeholder.asString,
            }),

            menuList: current => ({
                ...current,
                backgroundColor: theme.colors.textInput.background.asString,
            }),
        }),
        [hasErrors, theme],
    );
};

export { useDefaultReactSelectStyles };
