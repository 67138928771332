import { HorizontalDivider } from "@@/shared/dividers/horizontal-divider";
import { useTheme } from "@emotion/react";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { exists, type ImageRef, type Translatable } from "@towni/common";
import { useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { ButtonTransparent } from "./buttons_v2/button-gray";
import { FlexColumn, FlexRow } from "./flex-containers";
import { Icon } from "./icons/icon";
import { useGetImageUrlsFromImageRefs } from "./image-url";
import { ImageSlider } from "./pictures/image-slider";
import { ImageSliderImage } from "./pictures/image-slider-image";
import { usePageBreakpoint } from "./responsiveness/use-page-breakpoint";
import { TextBox } from "./text";

type Props = {
    readonly title?: Translatable;
    readonly images: ImageRef[];
    readonly startIndex?: number;
    readonly show: boolean;
    readonly close: () => void;
    readonly hideIndex?: boolean;
};

const ImageViewer = (props: Props) => {
    const theme = useTheme();
    const isSmallScreen = usePageBreakpoint({ when: "SMALL_SCREEN" });
    const portalElement = document.getElementById("image-viewer");
    const [currentIndex, setCurrentIndex] = useState(props.startIndex ?? 0);
    const largeImageUrls = useGetImageUrlsFromImageRefs(props.images, {
        imageWidth: theme.sizes.imageSizes.large,
    });
    const thumbnailImageUrls = useGetImageUrlsFromImageRefs(props.images, {
        imageWidth: theme.sizes.imageSizes.thumb,
    });
    const images: ImageSliderImage[] = useMemo(() => {
        return props.images
            .map((_, index) => {
                const originalUrl = largeImageUrls[index].url ?? "";
                const thumbnailUrl = thumbnailImageUrls[index].url;
                if (!originalUrl || !thumbnailUrl) return undefined;
                return {
                    _id: index.toString(),
                    _type: "IMAGE_SLIDER_IMAGE",
                    originalUrl,
                    thumbnailUrl,
                } satisfies ImageSliderImage;
            })
            .filter(exists);
    }, [largeImageUrls, props.images, thumbnailImageUrls]);
    if (!portalElement)
        throw new Error(
            "Image viewer div portal container can't be found; id: " +
                "image-viewer",
        );

    if (!props.show) return null;
    return createPortal(
        <div
            onClick={props.close}
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                overflow: "hidden",
                width: "100%",
                height: "100%",
                backgroundColor: theme.colors.black.withAlpha(0.8).asString,
                display: "flex",
                flexDirection: "column",
                justifyContent: "stretch",
                alignItems: "stretch",
            }}>
            <FlexRow fillParentWidth mainAxis="space-between">
                <div css={{ flex: 1, flexBasis: 1 }} />
                <HorizontalDivider />
                <TextBox
                    text={`${currentIndex + 1} / ${images.length}`}
                    align="center"
                    color={theme.colors.white.main.light60.asString}
                    css={{
                        flex: 3,
                        flexBasis: 1,
                        display: props.hideIndex ? "none" : "block",
                    }}
                />
                <HorizontalDivider />
                <FlexRow
                    mainAxis="flex-end"
                    crossAxis="flex-start"
                    css={{
                        flex: 1,
                        flexBasis: 1,
                    }}>
                    <ButtonTransparent
                        onClick={props.close}
                        css={{
                            padding: "20px",
                            color: theme.colors.white.main.light60.asString,
                        }}>
                        <Icon icon={faTimes} fixedWidth size="XXL" />
                    </ButtonTransparent>
                </FlexRow>
            </FlexRow>
            <FlexColumn
                mainAxis="center"
                crossAxis="center"
                css={{
                    padding: isSmallScreen ? "unset" : "5%",
                    flex: 1,
                    overflow: "hidden",
                }}>
                <ImageSlider
                    images={images}
                    navButtonType="large"
                    startIndex={props.startIndex}
                    fullWidth
                    onIndexChange={setCurrentIndex}
                />
            </FlexColumn>
        </div>,
        portalElement,
    );
};

export { ImageViewer };
