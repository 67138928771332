import { AccountIcon } from "@@/shared/account-icon";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { GlobalMaxWidthCss } from "@@/shared/global-max-width";
import { Icon } from "@@/shared/icons/icon";
import { LoginButton } from "@@/shared/login-button";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { Spacer } from "@@/shared/spacer";
import { TextBox } from "@@/shared/text";
import { useUpdateEffect } from "@@/shared/use-update-effect";
import { useTheme } from "@emotion/react";
import { faSadCry } from "@fortawesome/pro-light-svg-icons";
import { ApiError, RemSize, isApiError, translation } from "@towni/common";
import { useRef } from "react";
import { useLocation } from "react-router-dom";

type Props = {
    error?: Error | ApiError | unknown;
    hideUserAvatar?: boolean;
    reset?: () => void;
};

const ErrorPage = ({ error, ...props }: Props) => {
    const theme = useTheme();
    const location = useLocation();
    const isMobile = usePageBreakpoint({ when: "📱" });
    const title =
        typeof error === "undefined"
            ? undefined
            : isApiError(error)
              ? error?.reasonTitle
              : undefined;

    const message = (() => {
        if (typeof error === "undefined") return undefined;
        if (isApiError(error)) return error?.reason;
        if (typeof error === "string") return error;
        if (error instanceof Error) return error.message;
        return undefined;
    })();

    const errorLocation = useRef(location.pathname + location.search);
    useUpdateEffect(() => {
        if (errorLocation.current !== location.pathname + location.search) {
            // Navigation has occurred, so reset the error
            props.reset?.();
        }
    }, [location.pathname + location.search]);

    const isUnauthenticated = isApiError(error) && error.statusCode === 401;
    return (
        <>
            <GlobalMaxWidthCss />
            <FlexColumn
                fillParent
                mainAxis="flex-start"
                crossAxis="center"
                css={{ userSelect: "text", flex: 1, shrink: 1 }}>
                <FlexRow
                    fillParentWidth
                    css={{ display: isUnauthenticated ? "none" : "flex" }}
                    mainAxis="space-between"
                    crossAxis="center"
                    padding={{ all: 20 }}>
                    <Spacer width={40} grow={1} shrink={0} />
                    <Spacer width={40} grow={1} shrink={0}>
                        <FlexRow
                            fillParentWidth
                            mainAxis="flex-end"
                            crossAxis="center">
                            {!props.hideUserAvatar && (
                                <AccountIcon
                                    disableShadow
                                    color={theme.colors.default.text.withAlpha(
                                        0.6,
                                    )}
                                />
                            )}
                        </FlexRow>
                    </Spacer>
                </FlexRow>
                <VerticalDivider size={isMobile ? "L" : "3XL"} />
                <FlexRow
                    fillParentWidth
                    mainAxis="center"
                    crossAxis="center"
                    css={{ paddingTop: 100 }}>
                    <Icon
                        icon={faSadCry}
                        size={6 as RemSize}
                        color={theme.colors.black.light40}
                        onClick={props.reset}
                    />
                </FlexRow>
                <VerticalDivider size={isMobile ? "L" : "3XL"} />
                <TextBox
                    text={translation({
                        sv: "Oops, något gick fel",
                        en: "Oops, something went wrong",
                    })}
                    weight="900"
                    size={isMobile ? 2 : 3}
                    align="center"
                    padding={{ all: 40 }}
                    userSelect="text"
                />
                <VerticalDivider size={isMobile ? "L" : "3XL"} />
                {title ? (
                    <TextBox
                        text={title}
                        weight="900"
                        size={1.6}
                        align="center"
                        userSelect="text"
                        padding={{ all: 20 }}
                    />
                ) : null}
                {message ? (
                    <TextBox
                        text={message}
                        weight="700"
                        size={1.4}
                        align="center"
                        userSelect="text"
                        padding={{ all: 20 }}
                    />
                ) : null}
                {isUnauthenticated ? (
                    <>
                        <VerticalDivider M />
                        <LoginButton theme="PRIMARY" size={1.5 as RemSize} />
                    </>
                ) : null}
            </FlexColumn>
        </>
    );
};
export { ErrorPage };
