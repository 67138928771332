import { AccommodationCart } from "@@/carts/accommodation-checkout/accommodation-cart";
import { ContactInfoContextProvider } from "@@/carts/bookable-checkout/input-contact-info-context";
import { CheckoutContextProvider } from "@@/carts/checkout.context";
import { DeliveryProvider } from "@@/carts/deliverypicker/delivery-context";
import {
    CartContextProvider,
    useCart,
} from "@@/carts/multi-carts/cart.context";
import { MultiCartContextProvider } from "@@/carts/multi-carts/multi-cart.context";
import { ModalId } from "@@/modals/context/modal-id";
import { createMagicModalContext } from "@@/modals/magic-modal.context";
import { Modal } from "@@/modals/modal";
import { useModalActions } from "@@/modals/use-modal-actions";
import { useOptionalGroups } from "@@/optional-groups/state/optional-group-fetchers";
import { WizardPages, wizardPage } from "@@/pages/wizard";
import { WizardProvider } from "@@/pages/wizard-context";
import { AccommodationExtrasPickerPage } from "@@/products/accommodations/accommodation-extras-picker-page";
import { AccommodationPicker } from "@@/products/accommodations/accommodation-picker";
import { AccommodationResourcePickerPage } from "@@/products/accommodations/accommodation-resource-picker-page";
import { BookingAccommodationContextProvider } from "@@/products/accommodations/booking-accommodation-context";
import { useHasAvailableExtrasOrOptionals } from "@@/products/bookables/booking-context";
import { BookingWizardModalHeader } from "@@/products/bookables/booking-wizard/booking-wizard-modal-header";
import { useBookableResources } from "@@/resources/resources/use-resources";
import { FlexColumn } from "@@/shared/flex-containers";
import { MultiContextProvider } from "@@/shared/multi-provider";
import {
    Accommodation,
    BookableSessionId,
    IsoAndUnixTimestamp,
    OrderGroupId,
    OrderId,
    Product,
    Provider,
    findInventoryForPickedResource,
    generateId,
    isOrderItemAccommodation_V2,
} from "@towni/common";
import { useState } from "react";

type Props = {
    modalId: ModalId;
    product: Product | undefined;
    provider: Provider;
    onCheckoutComplete?: (params: {
        bookedSessionId?: BookableSessionId;
        orderGroupId: OrderGroupId;
        orderId: OrderId;
    }) => void | Promise<void>;
    onHide?: () => void;
    buyer?: "CUSTOMER" | "ON_PREMISE";
    preferredStartDate?: IsoAndUnixTimestamp;
};

const getAccommodationSKU = (product: Product) => {
    if (product.acquirableAs !== "ACCOMMODATION") return undefined;
    const accommodation = product.skus[0].acquire;
    if (!accommodation) return undefined;
    if (accommodation._type !== "ACCOMMODATION") return undefined;
    return accommodation;
};

const AccommodationWizardModal = (props: Props) => {
    return (
        <WizardProvider ignoreAnimation={false}>
            <Modal
                modalId={props.modalId}
                // maxWidth={800}
                // minWidth={450}
                height={"full"}
                forceFullHeightMode
                header={
                    <BookingWizardModalHeader
                        buyer={props.buyer}
                        modalId={props.modalId}
                    />
                }
                onHide={props.onHide}>
                <AccommodationWizardModalContent__ {...props} />
            </Modal>
        </WizardProvider>
    );
};

const AccommodationWizardModalContent__ = (props: Props) => {
    const [contextId] = useState(() => generateId(`ctx_${props.product?._id}`));

    if (!props.product) return null;

    const accommodation = getAccommodationSKU(props.product);
    if (!accommodation) return null;

    return (
        <MultiCartContextProvider
            contextId={contextId}
            preservationDisabled={true}>
            <CartContextProvider
                key={contextId}
                providerId={props.provider._id}
                doNotPreserveCart>
                <MultiContextProvider
                    providers={[
                        [
                            CheckoutContextProvider,
                            {
                                onPremise: props.buyer === "ON_PREMISE",
                            },
                        ],
                        DeliveryProvider,
                        ContactInfoContextProvider,
                        [
                            BookingAccommodationContextProvider,
                            {
                                product: props.product,
                                preferredStartDate: props.preferredStartDate,
                            },
                        ],
                    ]}>
                    <AccommodationWizard
                        modalId={props.modalId}
                        product={props.product}
                        accommodation={accommodation}
                        provider={props.provider}
                        onCheckoutComplete={props.onCheckoutComplete}
                        onHide={props.onHide}
                        buyer={props.buyer}
                    />
                </MultiContextProvider>
            </CartContextProvider>
        </MultiCartContextProvider>
    );
};

type Props2 = {
    modalId: ModalId;
    product: Product;
    accommodation: Accommodation;
    provider: Provider;
    onCheckoutComplete?: (params: {
        bookedSessionId?: BookableSessionId;
        orderGroupId: OrderGroupId;
        orderId: OrderId;
    }) => void | Promise<void>;
    onHide?: () => void;
    buyer?: "CUSTOMER" | "ON_PREMISE";
};

const AccommodationWizard = (props: Props2) => {
    const { hide } = useModalActions(props.modalId);
    const [optionalGroups] = useOptionalGroups(props.product?.optionalGroupIds);

    const hasAvailableExtrasOrOptionals = useHasAvailableExtrasOrOptionals(
        props.product?._id,
        props.buyer,
    );
    const [productResources] = useBookableResources(props.product._id);

    const cart = useCart();
    const orderItems = cart.orderItems.filter(isOrderItemAccommodation_V2);

    //Picked resource group, does not support multiple
    const rootResourceGroup = orderItems[0]?.accommodation.resourceId;

    const inventory = findInventoryForPickedResource(
        props.accommodation,
        productResources,
        rootResourceGroup,
    );

    const resourcePickerEnabled =
        inventory?.resourcePickerEnabled === "ALWAYS" ||
        (props.buyer === "ON_PREMISE" &&
            inventory?.resourcePickerEnabled === "PROVIDER");

    return (
        <FlexColumn
            fillParent
            css={{
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                position: "relative",
                label: "booking_wizard_modal_container",
            }}
            crossAxis="stretch"
            mainAxis="flex-start"
            overflow="hidden">
            <FlexColumn
                crossAxis="stretch"
                mainAxis="flex-start"
                overflowY={"hidden"}
                tag="booking_wiz_without_context_wrapper"
                css={{ flexGrow: 1 }}>
                <WizardPages ignoreAnimation={false}>
                    {[
                        wizardPage(
                            "accommodation-picker",
                            <AccommodationPicker product={props.product} />,
                        ),

                        ...(resourcePickerEnabled
                            ? [
                                  wizardPage(
                                      "resource-picker",
                                      <AccommodationResourcePickerPage
                                          provider={props.provider}
                                          product={props.product}
                                      />,
                                  ),
                              ]
                            : []),
                        ...(hasAvailableExtrasOrOptionals
                            ? [
                                  wizardPage(
                                      "extras-picker",
                                      <AccommodationExtrasPickerPage
                                          provider={props.provider}
                                          product={props.product}
                                          optionals={optionalGroups}
                                      />,
                                  ),
                              ]
                            : []),

                        wizardPage(
                            "acc-cart",
                            <AccommodationCart
                                hide={() => {
                                    hide();
                                }}
                                onCheckoutComplete={props.onCheckoutComplete}
                            />,
                        ),
                    ]}
                </WizardPages>
            </FlexColumn>
        </FlexColumn>
    );
};

const {
    MagicModalContextProvider,
    useMagicModalContext: useAccommodationWizardModalContext,
} = createMagicModalContext<
    Props,
    "product" | "provider" | "onCheckoutComplete" | "buyer" | "onHide"
>(AccommodationWizardModal);

const AccommodationWizardModalContextProvider = (props: {
    readonly children: React.ReactNode;
}) => {
    return (
        <MagicModalContextProvider initProps={{}}>
            {props.children}
        </MagicModalContextProvider>
    );
};

export {
    AccommodationWizardModal,
    AccommodationWizardModalContextProvider,
    getAccommodationSKU,
    useAccommodationWizardModalContext,
};
