import { Conditional } from "@@/shared/conditional";
import { FlexColumn } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { useTheme } from "@emotion/react";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { ColorSet, Percentage } from "@towni/common";
import { useState } from "react";

type Props = {
    readonly className?: string;
    readonly children?: React.ReactNode;
    readonly collapsible?: boolean;
    readonly collapsedHeight?: number;
    readonly fadeHeight?: Percentage;
    readonly neverHideAgain?: boolean;
    /** defaults to `theme.colors.orange` for background but with a gray text */
    readonly colorSet?: ColorSet;
};

const InfoBox_FlexColumn = (props: Props) => {
    const theme = useTheme();
    const colorSet = props.colorSet ?? theme.colors.orange;
    const textColorSet = props.colorSet ?? theme.colors.lightGray;
    const [collapsed, setCollapsed] = useState(!!props.collapsible);
    const collapsedHeight = props.collapsedHeight ?? 80;
    const defaultTextColor = theme.isLightTheme
        ? textColorSet.dark.asString
        : textColorSet.light.asString;
    // theme.colors.default.text.withAlpha(0.6).asString;
    const backgroundColor = theme.isLightTheme
        ? colorSet.light.asString
        : colorSet.dark.asString;
    return (
        <FlexColumn
            fillParentWidth
            mainAxis="stretch"
            crossAxis="stretch"
            className={props.className}
            onClick={() => {
                if (!props.collapsible) return;
                if (props.neverHideAgain && collapsed === false) return;
                setCollapsed(current => !current);
            }}
            css={{
                position: "relative",
                borderRadius: 10,
                overflow: collapsed ? "hidden" : "unset",
                fontSize: "0.875rem",
                color: defaultTextColor,
                minHeight: collapsed ? collapsedHeight : undefined,
                height: collapsed ? collapsedHeight : undefined,
                maxHeight: collapsed ? collapsedHeight : undefined,
                backgroundColor,
                label: "info_box",
            }}>
            <FlexColumn
                css={{
                    flex: 1,
                    padding: 15,
                    label: "info_box_inner_container",
                    overflow: "hidden",
                    minHeight: collapsed ? collapsedHeight : undefined,
                    maxHeight: collapsed ? collapsedHeight : undefined,
                }}>
                {props.children}
            </FlexColumn>
            <Conditional when={collapsed}>
                <div
                    css={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        padding: 7,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        minHeight: collapsedHeight * (props.fadeHeight ?? 0.9),
                        zIndex: 100,
                        background: `linear-gradient(180deg, ${theme.colors.transparent.asString} 0%, ${backgroundColor} 100%)`,
                        // backgroundColor: "blue",
                    }}>
                    <Icon icon={faChevronDown} css={{ opacity: 0.8 }} />
                </div>
            </Conditional>
        </FlexColumn>
    );
};

export { InfoBox_FlexColumn };
