import { useMe } from "@@/authentication/me/me-context";
import { useCheckoutContext } from "@@/carts/checkout.context";
import { useFetchClient } from "@@/shared/fetch-client";
import { usePrevious } from "@@/shared/use-previous";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import {
    Bookable,
    BookableOptionValue,
    BookableSession,
    BookableSessionId,
    GetSingleResponse,
    OrderId,
    PickedOptional,
    Product,
    ProductId,
    Provider,
    Quantity,
    QuantityPickerBookableOptionValue,
    ResourceId,
    Sku,
    TimeRange,
    asArray,
    asDate,
    calculatePossibleSessionLengths,
    defaultAmount,
    emptyArrayOf,
    getBookableResourceReferences,
    isBookable,
    isQuantityPickerBookableOption,
    isResourceReference,
    overrideBookableSettings,
    quantityPickerBookableOptionValueFactory,
    timeRangeByDurationFactory,
    timeRangeFactory,
    unique,
} from "@towni/common";
import { addYears, startOfToday, startOfYesterday } from "date-fns";
import * as React from "react";
import { useEffect } from "react";
import { StoreApi, create as zustand } from "zustand";
import { shallow } from "zustand/shallow";
import { useSessions } from "../state/queries/session/use-sessions";

import { createContext } from "react";
import { useStoreWithEqualityFn } from "zustand/traditional";

type State = {
    readonly customerHasSeenResourceModal: boolean;
    readonly addToCartDisabled: boolean;
    readonly bookable: Bookable;
    readonly freeBookable: boolean;
    readonly resourcePickerEnabled: boolean;
    readonly hasAvailableExtrasOrOptionals: boolean;
    readonly messageToProvider: string;
    readonly optionValues: BookableOptionValue[];
    readonly requestedResources: ResourceId[];
    readonly product: Product;
    readonly provider: Provider;
    readonly session: BookableSession | undefined;
    readonly sku: Sku;
    readonly timeRange: TimeRange | undefined;
    readonly sessions: BookableSession[];
    readonly originalSessions: BookableSession[];
    readonly isLoadingSessions: boolean;
    readonly isOnPremiseBooking: boolean;
    readonly allowBookingIsSplit: boolean;
    readonly pickedOptionalsMap: Map<string, PickedOptional[]>;
    readonly hasSetSessionFromParent: boolean;

    readonly ignoreOrder: OrderId | undefined;
};

type Actions = {
    setCustomerHasSeenResourceModal: (value: boolean) => void;
    setAddToCartDisabled: (value: boolean) => void;
    setOptionValue: (value: BookableOptionValue) => void;
    setTimeRange: (timeRange: TimeRange) => void;
    setSessionAndTimeRange: (
        timeRange: TimeRange | undefined,
        session: BookableSession,
    ) => void;
    setResourcePickerEnabled: (enabled: boolean) => void;
    setSession: (session: BookableSession, fromParent: boolean) => void;
    setSessions: (sessions: BookableSession[]) => void;
    setIsLoadingSessions: (isLoading: boolean) => void;
    setIsOnPremiseBooking: (isOnPremiseBooking: boolean) => void;
    clearSession: () => void;
    setMessageToProvider: (message: string) => void;
    selectResource: (resourceId: ResourceId, maxSelect?: number) => void;
    deselectResource: (resourceId: ResourceId) => void;
    setResource: (resourceId: ResourceId) => void;
    setPickedOptionalsMap: (picked: Map<string, PickedOptional[]>) => void;
    setAllowSplitBooking: (allow: boolean) => void;
    setHasAvailableExtrasOrOptionals: (
        hasAvailableExtrasOrOptionals: boolean,
    ) => void;
    setProduct: (product: Product) => void;
};

type Context = State & Actions;

const BookingContext = createContext<StoreApi<Context>>(
    undefined as unknown as StoreApi<Context>,
);

type Props = {
    children: React.ReactNode;
    preferredResourceId?: ResourceId;
    product: Product;
    provider: Provider;
    sessionId?: BookableSessionId;
    timeRange?: TimeRange;
    type: "CUSTOMER" | "ON_PREMISE" | undefined;
    hideResourceModalOnStart?: boolean;
    optionalValues?: QuantityPickerBookableOptionValue[];
    pickedOptionalsMap?: Map<string, PickedOptional[]>;
    ignoreOrder?: OrderId;
};

const BookingContextProvider = (props: Props) => {
    // const product = props.product;

    const createStore = () => {
        return zustand<Context>((set, get) => {
            const sku = props.product.skus[0];
            if (!isBookable(sku.acquire)) {
                throw new Error("Booking context sku must be a bookable");
            }

            const optionValues =
                getBookableResourceReferences(sku.acquire).flatMap(r => {
                    return r.options.flatMap(option => {
                        if (isQuantityPickerBookableOption(option)) {
                            const old = props.optionalValues?.find(
                                o =>
                                    o.parentId === option._id &&
                                    o.resourcePrice._id ===
                                        option.resourcePrice._id,
                            );
                            if (old)
                                return {
                                    ...old,
                                    resourcePrice: option.resourcePrice,
                                };

                            const amount =
                                props.optionalValues?.find(
                                    o =>
                                        o.parentId === option._id &&
                                        o.resourcePrice._id ===
                                            option.resourcePrice._id,
                                )?.quantity.amount ?? defaultAmount;

                            const optionValue =
                                quantityPickerBookableOptionValueFactory({
                                    meta: option.meta,
                                    quantity: {
                                        amount: amount,
                                        value:
                                            option.default ??
                                            option.minMax.min ??
                                            0,
                                    } as Quantity,

                                    parentId: option._id,
                                    resourceId: option.resourceId,
                                    resourceReferenceId:
                                        option.resourceReferenceId,
                                    resourcePrice: option.resourcePrice,
                                });
                            return optionValue;
                        }

                        return [];
                    });
                }) ?? [];
            const state: State = {
                customerHasSeenResourceModal: !!props.hideResourceModalOnStart,
                addToCartDisabled: false,
                bookable: sku.acquire,
                hasAvailableExtrasOrOptionals:
                    !!props.product.optionalGroupIds?.length ||
                    !!sku.acquire.extras.length,
                optionValues,
                freeBookable:
                    props.product.price.amountIncludingVat === 0 &&
                    !optionValues.find(
                        f =>
                            f.resourcePrice &&
                            f.resourcePrice.price.amountIncludingVat > 0,
                    ),
                messageToProvider: "",
                resourcePickerEnabled: false,
                requestedResources: asArray(props.preferredResourceId),
                product: props.product,
                provider: props.provider,
                session: undefined as BookableSession | undefined,
                sessions: emptyArrayOf<BookableSession>(),
                originalSessions: emptyArrayOf<BookableSession>(),
                sku,
                timeRange: props.timeRange, //undefined as TimeRange | undefined,
                isLoadingSessions: false,
                isOnPremiseBooking: props.type === "ON_PREMISE",
                allowBookingIsSplit: false,
                pickedOptionalsMap: props.pickedOptionalsMap ?? new Map(),
                hasSetSessionFromParent: false,
                ignoreOrder: props.ignoreOrder,
            };

            const actions: Actions = {
                setAddToCartDisabled: (disabled: boolean) => {
                    set(state => {
                        return {
                            ...state,
                            addToCartDisabled: disabled,
                        };
                    });
                },
                setProduct: (product: Product) => {
                    if (product._id === get().product._id) return;
                    const sku = product.skus[0];
                    if (!isBookable(sku.acquire)) {
                        throw new Error(
                            "Booking context sku must be a bookable",
                        );
                    }
                    const optionValues =
                        getBookableResourceReferences(sku.acquire).flatMap(
                            r => {
                                return r.options.flatMap(option => {
                                    if (
                                        isQuantityPickerBookableOption(option)
                                    ) {
                                        const old = props.optionalValues?.find(
                                            o =>
                                                o.parentId === option._id &&
                                                o.resourcePrice._id ===
                                                    option.resourcePrice._id,
                                        );
                                        if (old)
                                            return {
                                                ...old,
                                                resourcePrice:
                                                    option.resourcePrice,
                                            };

                                        const amount =
                                            props.optionalValues?.find(
                                                o =>
                                                    o.parentId === option._id &&
                                                    o.resourcePrice._id ===
                                                        option.resourcePrice
                                                            ._id,
                                            )?.quantity.amount ?? defaultAmount;

                                        const optionValue =
                                            quantityPickerBookableOptionValueFactory(
                                                {
                                                    meta: option.meta,
                                                    quantity: {
                                                        amount: amount,
                                                        value:
                                                            option.default ??
                                                            option.minMax.min ??
                                                            0,
                                                    } as Quantity,

                                                    parentId: option._id,
                                                    resourceId:
                                                        option.resourceId,
                                                    resourceReferenceId:
                                                        option.resourceReferenceId,
                                                    resourcePrice:
                                                        option.resourcePrice,
                                                },
                                            );
                                        return optionValue;
                                    }

                                    return [];
                                });
                            },
                        ) ?? [];

                    set({
                        bookable: sku.acquire,
                        hasAvailableExtrasOrOptionals:
                            !!product.optionalGroupIds?.length ||
                            !!sku.acquire.extras.length,
                        optionValues,
                        freeBookable:
                            product.price.amountIncludingVat === 0 &&
                            !optionValues.find(
                                f =>
                                    f.resourcePrice &&
                                    f.resourcePrice.price.amountIncludingVat >
                                        0,
                            ),
                        messageToProvider: "",
                        resourcePickerEnabled: false,
                        product: product,
                        provider: props.provider,
                        session: undefined,
                        sessions: emptyArrayOf<BookableSession>(),
                        originalSessions: emptyArrayOf<BookableSession>(),
                        sku,
                        timeRange: undefined, //undefined as TimeRange | undefined,
                        isLoadingSessions: false,
                        isOnPremiseBooking: props.type === "ON_PREMISE",
                        allowBookingIsSplit: false,
                        pickedOptionalsMap: new Map(),
                        hasSetSessionFromParent: false,
                    });
                },
                setCustomerHasSeenResourceModal: (seen: boolean) => {
                    set(state => {
                        return {
                            ...state,
                            customerHasSeenResourceModal: seen,
                        };
                    });
                },
                setIsOnPremiseBooking: (value: boolean) => {
                    set(state => {
                        return {
                            ...state,
                            isOnPremiseBooking: value,
                        };
                    });
                },
                setResourcePickerEnabled: (enabled: boolean) => {
                    set(state => {
                        return {
                            ...state,
                            resourcePickerEnabled: enabled,
                        };
                    });
                },
                setHasAvailableExtrasOrOptionals: (
                    hasAvailableExtrasOrOptionals: boolean,
                ) => {
                    set(state => {
                        return {
                            ...state,
                            hasAvailableExtrasOrOptionals,
                        };
                    });
                },
                setOptionValue: (value: BookableOptionValue) => {
                    set(state => {
                        return {
                            ...state,
                            requestedResources: asArray(
                                props.preferredResourceId,
                            ),

                            optionValues: [
                                ...state.optionValues.filter(
                                    o => o.parentId !== value.parentId,
                                ),
                                value,
                            ],
                        };
                    });
                },
                setSessionAndTimeRange: (
                    timeRange: TimeRange | undefined,
                    session: BookableSession,
                ) => {
                    set(state => {
                        return {
                            ...state,
                            session,
                            timeRange,
                        };
                    });
                },
                setSession: (session: BookableSession, fromParent) => {
                    // Same as above ^^ (setSessionAndTimeRange), but with calculated timeRange
                    set(state => {
                        return {
                            ...state,
                            session,
                            timeRange: timeRangeByDurationFactory(
                                session.start,
                                {
                                    value: session.durationMinMax.min,
                                    granularity: session.durationGranularity,
                                },
                            ),
                            hasSetSessionFromParent: fromParent,
                        };
                    });
                },
                setSessions: (sessions: BookableSession[]) => {
                    if (
                        !sessions.every(
                            session => session.productId === get().product._id,
                        )
                    ) {
                        throw new Error(
                            `Sessions must belong to the product of the context; Context ProductId: ${
                                get().product._id
                            }, Session ProductIds: ${unique(
                                sessions.map(s => s.productId),
                            ).join(", ")}`,
                        );
                    }
                    const overrideSessions = state.isOnPremiseBooking
                        ? sessions.map(overrideBookableSettings)
                        : sessions;

                    set(state => {
                        return {
                            ...state,
                            sessions: overrideSessions,
                            originalSessions: sessions,
                        };
                    });
                },
                clearSession: () => {
                    set(state => {
                        return {
                            ...state,
                            session: undefined,
                            timeRange: undefined,
                        };
                    });
                },
                selectResource: (
                    resourceId: ResourceId,
                    maxSelect?: number,
                ) => {
                    set(state => {
                        return {
                            ...state,
                            requestedResources: [
                                resourceId,
                                ...state.requestedResources,
                            ].slice(0, maxSelect),
                        };
                    });
                },
                deselectResource: (resourceId: ResourceId) => {
                    set(state => {
                        return {
                            ...state,
                            requestedResources: state.requestedResources.filter(
                                _resourceId => _resourceId !== resourceId,
                            ),
                        };
                    });
                },
                setTimeRange: (timeRange: TimeRange | undefined) => {
                    set(state => {
                        return {
                            ...state,
                            timeRange,
                        };
                    });
                },
                setIsLoadingSessions: (isLoadingSessions: boolean) => {
                    set(state => {
                        return {
                            ...state,
                            isLoadingSessions,
                        };
                    });
                },
                setMessageToProvider: (messageToProvider: string) => {
                    set(state => {
                        return {
                            ...state,
                            messageToProvider,
                        };
                    });
                },
                setResource: (resourceId: ResourceId) => {
                    set(state => {
                        return {
                            ...state,
                            requestedResources: [resourceId],
                        };
                    });
                },
                setPickedOptionalsMap: (
                    picked: Map<string, PickedOptional[]>,
                ) => {
                    set(state => {
                        return {
                            ...state,
                            pickedOptionalsMap: picked,
                        };
                    });
                },
                setAllowSplitBooking: (allow: boolean) => {
                    set(state => {
                        return {
                            ...state,
                            allowBookingIsSplit: allow,
                        };
                    });
                },
            };

            const context: Context = {
                ...state,
                ...actions,
            };

            return context;
        });
    };

    const store = React.useRef(createStore());
    return (
        <BookingContext.Provider value={store.current}>
            <ZustandSessionUpdater sessionId={props.sessionId}>
                {props.children}
            </ZustandSessionUpdater>
        </BookingContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useBookingContext = <U extends unknown = Context>(
    selector: (context: Context) => U = context => context as unknown as U,
): U => {
    const store = React.useContext(BookingContext);
    if (store === undefined) {
        throw new Error(
            "useBookingContext must be used within a BookingContext",
        );
    }
    return useStoreWithEqualityFn(store, selector, shallow);
};

//const useBookingContext = useStore;

const ZustandSessionUpdater = (props: {
    sessionId: BookableSessionId | undefined;
    children: React.ReactNode;
}) => {
    const [me] = useMe();
    const onPremise = useCheckoutContext(context => context.onPremise);

    //Only set session once, if has been unset dont set it again caused problem #1272
    // const [hasSetSessionFromParent, setHasSetSession] = React.useState(false);
    // browserLogger.info("hasSetSessionFromParent", hasSetSessionFromParent);
    const state = useBookingContext(context => ({
        bookable: context.bookable,
        product: context.product,
        session: context.session,
        timeRange: context.timeRange,
        resourcePickerEnabled: context.resourcePickerEnabled,
        isOnPremiseBooking: context.isOnPremiseBooking,
        hasSetSessionFromParent: context.hasSetSessionFromParent,
    }));
    const actions = useBookingContext(context => ({
        setTimeRange: context.setTimeRange,
        setSession: context.setSession,
        setSessions: context.setSessions,
        setIsLoadingSessions: context.setIsLoadingSessions,
        setResourcePickerEnabled: context.setResourcePickerEnabled,
        setIsOnPremiseBooking: context.setIsOnPremiseBooking,
        setAllowSplitBooking: context.setAllowSplitBooking,
        setHasAvailableExtrasOrOptionals:
            context.setHasAvailableExtrasOrOptionals,
    }));

    const hasAvailableExtrasOrOptionals = useHasAvailableExtrasOrOptionals(
        state.product._id,
    );

    useEffect(() => {
        actions.setHasAvailableExtrasOrOptionals(hasAvailableExtrasOrOptionals);
    }, [hasAvailableExtrasOrOptionals]);

    const getStartDateForSessionInterval = () => {
        const startOfYester = startOfYesterday();
        if (state.timeRange) {
            const start = asDate(state.timeRange?.start);
            if (start < startOfYester) return start;
        }
        return startOfYester;
    };

    const [sessionRange] = React.useState(
        timeRangeFactory(
            getStartDateForSessionInterval(),
            addYears(startOfToday(), 2),
        ),
    );

    const [sessions, { isPending: isLoadingSessions }] = useSessions(
        state.product._id,
        sessionRange,
    );

    useEffect(() => {
        actions.setIsLoadingSessions(isLoadingSessions);
    }, [isLoadingSessions]);
    useEffect(() => {
        actions.setIsOnPremiseBooking(onPremise);
    }, [onPremise]);
    useEffect(() => {
        const _allowSplitBooking =
            state.resourcePickerEnabled &&
            state.bookable.type === "TABLE_BOOKING" &&
            state.isOnPremiseBooking;

        //If we can pick, is on premise booking, and is a table booking, allow split booking
        actions.setAllowSplitBooking(_allowSplitBooking);
    }, [onPremise, state.isOnPremiseBooking, state.resourcePickerEnabled]);
    useEffect(() => {
        // Enable or disable resource picker for booking wizard
        const resourcePickerEnabled =
            !!isResourceReference(state.bookable.main) &&
            state.bookable.main.resourcePickerEnabled &&
            state.bookable.main.resourcePickerEnabled !== "NEVER" &&
            state.bookable.main.resourcePickerEnabled;

        if (!resourcePickerEnabled) {
            actions.setResourcePickerEnabled(false);
        }

        switch (resourcePickerEnabled) {
            case "ALWAYS": {
                actions.setResourcePickerEnabled(true);
                break;
            }
            case "LIMIT_FOR_CUSTOMER": {
                actions.setResourcePickerEnabled(true);
                break;
            }
            case "PROVIDER": {
                if (onPremise) actions.setResourcePickerEnabled(true);
                else actions.setResourcePickerEnabled(false);
                break;
            }
            default: {
                actions.setResourcePickerEnabled(false);
                break;
            }
        }
    }, [state.bookable.main, onPremise, me, actions.setResourcePickerEnabled]);

    const prevSessions = usePrevious(sessions, { startFromUndefined: true });
    useEffect(() => {
        if (prevSessions === sessions) return;
        actions.setSessions(sessions);
        if (
            props.sessionId &&
            !state.session &&
            !state.hasSetSessionFromParent
        ) {
            const pickedSession = sessions.find(s => s._id === props.sessionId);
            if (pickedSession) {
                actions.setSession(pickedSession, true);
                if (!state.timeRange) {
                    const sessionLengths =
                        calculatePossibleSessionLengths(pickedSession);
                    if (sessionLengths.length)
                        actions.setTimeRange(sessionLengths[0]);
                } else {
                    actions.setTimeRange(state.timeRange);
                }
            }
        }
    }, [
        prevSessions,
        sessions,
        props.sessionId,
        state.timeRange,
        actions.setTimeRange,
        actions.setSession,
        actions,
        state.session,
        state.hasSetSessionFromParent,
    ]);
    return <>{props.children}</>;
};

const useHasAvailableExtrasOrOptionals = (
    productId?: ProductId,
    buyer?: "ON_PREMISE" | "CUSTOMER",
) => {
    const fetchClient = useFetchClient();
    const hasAvailableExtrasOrOptionalsCheck: QueryFunction<
        GetSingleResponse<boolean>
    > = React.useCallback(
        ({ signal }) => {
            return fetchClient.get<GetSingleResponse<boolean>>({
                route: `/api/products/${productId}/has-available-extras-or-optionals?buyer=${buyer}`,
                customConfig: { signal },
            });
        },
        [fetchClient, productId],
    );
    const hasAvailableExtrasOrOptionals = useQuery({
        queryKey: ["products", productId, "hasAvailableExtrasOrOptionals"],
        queryFn: hasAvailableExtrasOrOptionalsCheck,
        enabled: !!productId,
    });

    return hasAvailableExtrasOrOptionals.data?.item || false;
};

export {
    BookingContextProvider,
    useBookingContext,
    useHasAvailableExtrasOrOptionals,
};
