import { usePriceFormatter } from "@@/translations/use-price-formatter";
import { useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { Direction, isNotUndefined, Price } from "@towni/common";
import { Except } from "type-fest";
import { HorizontalDivider } from "../dividers/horizontal-divider";
import { FlexColumn, FlexRow } from "../flex-containers";
import { Icon } from "../icons/icon";
import { TextBox, TextBoxProps } from "./text-box";

type PriceFractions = "AUTO" | "NONE" | "TWO";

type Props = Except<TextBoxProps, "text" | "children"> & {
    price: Price | undefined;
    fractions?: PriceFractions;
    fallbackPriceZero?: JSX.Element | null;
    originalPrice?: Price;
    reversed?: boolean;
    direction?: Direction;
    hideWhenZero?: boolean;
    valueToFormat?:
        | "AMOUNT_INCLUDING_VAT"
        | "AMOUNT_EXCLUDING_VAT"
        | "VAT_AMOUNT";
};

const defaultProps: Partial<Props> = {
    valueToFormat: "AMOUNT_INCLUDING_VAT",
    fractions: "AUTO",
    direction: "HZ",
};

const PriceTextBox = (props: Props) => {
    props = { ...defaultProps, ...props } as const;
    const theme = useTheme();
    const priceFormat = usePriceFormatter();
    const { price, originalPrice, ...textBoxProps } = props;
    const valueToFormat = props.valueToFormat ?? "AMOUNT_INCLUDING_VAT";
    const fractions = props.fractions ?? "AUTO";
    const direction = props.direction ?? "HZ";

    // Todo: Decide on business logic
    // Todo: For now pick price or else buyable price or else bookable price.

    if (!price)
        return typeof props.fallbackPriceZero === "undefined" ? (
            <FlexRow mainAxis="flex-start">
                <Icon
                    icon={faSpinnerThird}
                    spin
                    size={props.size}
                    color={props.color}
                />
            </FlexRow>
        ) : (
            props.fallbackPriceZero
        );
    if (
        price.amountIncludingVat === 0 &&
        isNotUndefined(props.fallbackPriceZero)
    ) {
        return props.fallbackPriceZero;
    }

    const formattedPrice = priceFormat(price, valueToFormat, fractions);

    const Container = direction === "VT" ? FlexColumn : FlexRow;
    const isNotDiscountedPrice =
        !originalPrice ||
        originalPrice.amountIncludingVat === price.amountIncludingVat;
    const hide = props.hideWhenZero && price.amountIncludingVat === 0;

    return isNotDiscountedPrice ? (
        <TextBox
            {...textBoxProps}
            text={formattedPrice}
            css={{ display: hide ? "none" : "block", whiteSpace: "nowrap" }}
        />
    ) : (
        <Container reversed={!!props.reversed} shrink={0}>
            <PriceTextBox
                price={price}
                weight="700"
                {...textBoxProps}
                color={theme.colors.secondary}
                css={{
                    whiteSpace: "nowrap",
                }}
            />
            {props.direction === "VT" ? null : <HorizontalDivider size="XS" />}
            <PriceTextBox
                {...textBoxProps}
                price={originalPrice}
                color={theme.colors.black.light60}
                css={{
                    textDecoration: "line-through",
                    whiteSpace: "nowrap",
                }}
            />
        </Container>
    );
};

export { PriceTextBox };
export type { PriceFractions, Props as PriceTextBoxProps };
