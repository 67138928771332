type ImageSliderImage = {
    _id: string;
    _type: "IMAGE_SLIDER_IMAGE";
    originalUrl: string;
    thumbnailUrl: string;
};

const isImageSliderImage = (image: any): image is ImageSliderImage =>
    image?._type === "IMAGE_SLIDER_IMAGE";

export { isImageSliderImage };
export type { ImageSliderImage };
