import { useStorageItemImageReferenceQueries } from "@@/storage-items/queries/use-storage-item-reference";
import {
    asArray,
    emptyArrayOf,
    exists,
    Resource,
    ResourceImageReference,
} from "@towni/common";
import { useMemo } from "react";

/**
 * Extracts image references from a given resource or array of resources.
 *
 * @param resources - A single resource, an array of resources, or undefined.
 * @returns An array of image references extracted from the provided resources.
 *
 * If the input is undefined, an empty array is returned.
 * If the input is a single resource or an array of resources, the function
 * will extract and return all image references found within those resources.
 */
const getImageRefsFromResources = (
    resources: undefined | Resource | Resource[],
): ResourceImageReference[] => {
    const _resources =
        typeof resources === "undefined"
            ? emptyArrayOf<Resource>()
            : asArray(resources);
    if (!_resources.length) return emptyArrayOf<ResourceImageReference>();
    return _resources.flatMap(resource => {
        if (!("imageRefs" in resource)) return [];
        if (!resource.imageRefs) return [];
        return resource.imageRefs;
    });
};

/**
 * Custom hook to retrieve storage item image references for given resources.
 *
 * @param resources - A single resource or an array of resources to get image references from.
 * @returns An array of image references for the provided resources.
 */
const useResourceImageQueries = (
    resources: undefined | Resource | Resource[],
) => {
    const images = useMemo(
        () => getImageRefsFromResources(resources),
        [resources],
    );
    return useStorageItemImageReferenceQueries(
        images.map(image => image.imageId).filter(exists),
    );
};

export { getImageRefsFromResources, useResourceImageQueries };
