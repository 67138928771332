import { useTheme } from "@emotion/react";
import { Translatable, translation } from "@towni/common";
import * as React from "react";
import { useCallback } from "react";
import { Button_v2 } from "../buttons_v2/button";
import { TextBox } from "../text/text-box";

type Props = {
    readonly selected?: boolean;
    readonly onClick: (event: React.MouseEvent) => void;
    readonly text?: Translatable;
    readonly spin?: boolean;
    readonly dataTestId?: string;
};

const ListSelectButton = (props: Props) => {
    const theme = useTheme();
    const { selected, spin, onClick: onClickFromProps } = props;
    const stopPropagationWhenOnClick = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();
        },
        [],
    );
    const onClick = useCallback(
        (event: React.MouseEvent) => {
            event.preventDefault();
            event.stopPropagation();
            onClickFromProps(event);
        },
        [onClickFromProps],
    );
    return (
        <Button_v2
            padding={{
                leftRight: 20,
                topBottom: 6,
            }}
            dataTestId={props.dataTestId}
            spin={spin}
            disabled={spin}
            css={{
                color: theme.colors.default.text.asString,
                background: selected
                    ? theme.colors.primary.asString
                    : theme.colors.default.background.asString,
                borderStyle: "solid",
                borderRadius: 6,
                ":active": {
                    color: "white",
                    backgroundColor: theme.colors.primary.asString,
                    opacity: 0.6,
                },
                ":hover": {
                    opacity: 0.8,
                },
            }}
            onMouseUp={stopPropagationWhenOnClick}
            onClick={onClick}>
            <TextBox
                css={{
                    minWidth: 32,
                }}
                align="center"
                text={
                    selected
                        ? translation({
                              sv: "Vald",
                              en: "Selected",
                          })
                        : translation({
                              sv: "Välj",
                              en: "Select",
                          })
                }
                color={
                    selected ? theme.colors.primary.text : theme.colors.primary
                }
            />
        </Button_v2>
    );
};

export { ListSelectButton as RowSelectButton };
