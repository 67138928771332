import { useProduct } from "@@/products/state/queries/product/use-product";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import {
    isGiftCard,
    isProductId,
    parseSafely,
    ProductId,
    productIdZodSchema,
} from "@towni/common";
import { useParams } from "react-router-dom";

const useGiftCardData = (params?: { giftCardId?: ProductId }) => {
    const urlParams = useParams<{
        giftCardId?: ProductId;
        productId?: ProductId;
    }>();

    const giftCardId = parseSafely({
        schema: productIdZodSchema,
        value:
            params?.giftCardId ?? urlParams.giftCardId ?? urlParams.productId,
    });

    if (!isProductId(giftCardId))
        throw new Error(`GiftCard id missing; ${giftCardId}`);

    const giftCardQuery = useProduct(giftCardId);
    const giftCard = giftCardQuery.data;
    if (!isGiftCard(giftCard)) throw new Error(`Product is not a giftCard`);
    const [provider, providerQuery] = useProvider(giftCard?.providerId);

    return {
        giftCard,
        provider,
        hasError: giftCardQuery.isError || providerQuery.isError,
        errors: {
            giftCardError: giftCardQuery.error,
            providerError: providerQuery.error,
            hasGiftCardError: giftCardQuery.isError,
            hasProviderError: providerQuery.isError,
        },
        isLoading: giftCardQuery.isPending || providerQuery.isPending,
        loading: {
            giftCardIsLoading: giftCardQuery.isPending,
            providerIsLoading: providerQuery.isPending,
        },
        isStale: giftCardQuery.isStale || providerQuery.isStale,
        stale: {
            giftCardIsStale: giftCardQuery.isStale,
            providerIsStale: providerQuery.isStale,
        },
    };
};

export { useGiftCardData };
