import { browserLogger } from "@@/settings";
import { useFormField } from "@@/shared/form/use-form-field";
import { useUpdateEffect } from "@@/shared/use-update-effect";
import { useTranslate } from "@@/translations/use-translate";
import {
    TranslatableValue,
    generateId,
    translatableString,
} from "@towni/common";
import { useCallback, useRef, useState } from "react";
import { ZodIssueCode } from "zod";

type SchemaGetter<State, Value> = (
    state: Partial<State>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Value;

type ManualTrigger = () => void;

function useFormFieldValidation<
    State extends Record<string, unknown>,
    Value,
>(params: {
    readonly field: ReturnType<typeof useFormField<State, Value>>;
    readonly initialValidationType: "automatic";
    readonly preProcessValue?: (value: Value | undefined) => Value | undefined;
    readonly customFieldValidationErrorMessage?: TranslatableValue;
}): void;
function useFormFieldValidation<
    State extends Record<string, unknown>,
    Value,
>(params: {
    readonly field: ReturnType<typeof useFormField<State, Value>>;
    readonly initialValidationType: "manual";
    readonly preProcessValue?: (value: Value | undefined) => Value | undefined;
    readonly customFieldValidationErrorMessage?: TranslatableValue;
}): ManualTrigger;
function useFormFieldValidation<
    State extends Record<string, unknown>,
    Value,
>(params: {
    readonly field: ReturnType<typeof useFormField<State, Value>>;
    readonly initialValidationType: "automatic" | "manual";
    readonly preProcessValue?: (value: Value | undefined) => Value | undefined;
    readonly customFieldValidationErrorMessage?: TranslatableValue;
}): ManualTrigger | void {
    const field = params.field;
    const initialValidationType = params.initialValidationType ?? "automatic";
    const translate = useTranslate();

    const [triggerId, setTriggerId] = useState({
        type: initialValidationType,
        id: generateId(),
    });
    const validationCountRef = useRef(0);
    const triggerFunc = useCallback((type: "manual" | "automatic") => {
        setTriggerId({ type, id: generateId() });
    }, []);
    useUpdateEffect(
        function autoValidationTrigger() {
            // Automatically trigger a validation when the value changes
            // make sure it's not the initial value when the validation
            // has not been triggered before
            if (
                validationCountRef.current > 0 ||
                (initialValidationType === "automatic" &&
                    field.value !== field.initialValue)
            ) {
                triggerFunc("automatic");
            }
        },
        [field.value],
    );
    const fieldSchema = field.fieldSchema;
    useUpdateEffect(
        function validate() {
            let timeout: NodeJS.Timeout;
            const delay = triggerId.type === "automatic" ? 300 : 0;
            // console.log("VALIDATION TRIGGERED", {
            //     field,
            //     valCount: validationCountRef.current,
            //     triggerId,
            // });
            if (fieldSchema?.safeParseAsync) {
                timeout = setTimeout(() => {
                    validationCountRef.current += 1;
                    const valueToValidate = params.preProcessValue
                        ? params.preProcessValue(field.value)
                        : field.value;

                    fieldSchema
                        .safeParseAsync(valueToValidate)
                        .then(result => {
                            if (result.success) {
                                field.setErrors([]);
                            } else {
                                if (params.customFieldValidationErrorMessage) {
                                    field.setErrors([
                                        translate(
                                            params.customFieldValidationErrorMessage,
                                        ),
                                    ]);
                                    return;
                                }
                                field.setErrors(
                                    result.error.issues.map(issue => {
                                        if (
                                            issue.code === ZodIssueCode.custom
                                        ) {
                                            if (
                                                issue.params?.translatedMessage
                                            ) {
                                                return translate(
                                                    issue.params
                                                        ?.translatedMessage,
                                                );
                                            }
                                        }
                                        const message = translate(
                                            translatableString.fromTranslatableString(
                                                issue.message,
                                            ),
                                        );
                                        return message;
                                    }),
                                );
                            }
                        })
                        .catch(err => {
                            browserLogger.error(err);
                            field.setErrors(["Something went wrong"]);
                        });
                }, delay);
            }
            return () => {
                if (timeout) clearTimeout(timeout);
            };
        },
        [triggerId],
    );

    const manualTrigger = useCallback(
        // Trigger a validation, type manual
        () => {
            triggerFunc("manual");
        },
        [triggerFunc],
    );
    return manualTrigger;
}

export { useFormFieldValidation };
export type { SchemaGetter };
