import { hasStockBalance } from "@@/backoffice/for-providers/products/stock-balance/has-stock-balance";
import { useStockBalanceSku } from "@@/backoffice/for-providers/products/stock-balance/use-stock-balance-sku";
import { useCart } from "@@/carts/multi-carts/cart.context";
import { useModal } from "@@/modals/use-modal";
import { useProductLegacy } from "@@/products/state/queries/product/use-product";
import { darkeningGradientReverse } from "@@/shared/bg-darkening-gradient";
import { Circle } from "@@/shared/circle";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { useImageUrl } from "@@/shared/image-url";
import { SectionAreaCard } from "@@/shared/section-area/section-area-card";
import { TextBox } from "@@/shared/text";
import { css, useTheme } from "@emotion/react";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    AspectRatio,
    ProductId,
    RemSize,
    SkuId,
    Translatable,
    isProductOrderItem,
    sum,
    translation,
} from "@towni/common";
import { ExtrasProps } from "../product-details-page";
import { BookableProductExtraModal } from "./bookable-product-extra-modal";
import { BookableProductExtrasGroupModal } from "./bookable-product-extras-group-modal";

type Props = {
    productId: ProductId;
    /**
     * default to `3:4`
     * @type {Translatable}
     */
    aspectRatio?: AspectRatio;
    /**
     * default to product title
     * @type {Translatable}
     */
    title?: Translatable;
    /**
     * defaults to "Beställ"/"Order"
     * @type {Translatable}
     */
    actionText?: Translatable;
    kicker?: Translatable;
    imageIdOrUrl?: string;
    cardSize?: RemSize;

    extras?: ExtrasProps;
};

const BookableGridProductExtraCardV2 = (props: Props) => {
    const theme = useTheme();
    const [product] = useProductLegacy(props.productId);

    const cart = useCart();
    const cartItems = cart.orderItems.filter(
        item =>
            isProductOrderItem(item) &&
            item.productId === props.productId &&
            item.extraGroup?._id === props.extras?.extras._id &&
            item.parentId === props.extras?.parentOrderItemId,
    );
    const amount = sum(cartItems, item => item.quantity.value);

    const sku = product?.skus[0];

    const stockBalanceEnabled: boolean =
        hasStockBalance(sku) && !!sku.trackStockBalance;
    const [stockBalance, _stockBalanceQuery] = useStockBalanceSku(
        {
            providerId: cart.providerId,
            skuId: product?.skus[0]._id ?? ("" as SkuId),
            productId: product?._id ?? props.productId,
            cartId: cart._id,
        },
        !stockBalanceEnabled,
    );

    const isOutOfStock =
        stockBalanceEnabled && (!stockBalance || stockBalance.quantity <= 0);

    const [{ show: showProduct }, modalId] = useModal("product_modal__");

    const [{ show: showPickedExtras }, extraModalId] =
        useModal("extras_modal__");

    const getImageUrl = useImageUrl();

    if (!product || product.status.type === "PAUSED") return null;

    const imageRef = props.imageIdOrUrl
        ? (product.meta.images.find(image =>
              typeof image === "string"
                  ? image === props.imageIdOrUrl
                  : image._id === props.imageIdOrUrl,
          ) ?? product.meta.images[0])
        : product.meta.images[0];

    const imageUrl = getImageUrl(imageRef, { imageWidth: 500 });

    const title = props.title ?? product.meta.title;
    const kicker = props.kicker ?? "";

    return (
        <>
            <SectionAreaCard
                aspectRatio={props.aspectRatio ?? "3:4"}
                radius={10}
                onClick={() => {
                    if (props.extras?.disabled) return;
                    showProduct();
                    //  e.stopPropagation();
                }}
                background={{
                    color: theme.colors.primary,
                    fit: "COVER",
                    repeat: "NO-REPEAT",
                    imageUrl,
                }}
                css={{
                    borderRadius: 10,
                    overflow: "hidden",
                    opacity: isOutOfStock ? 0.5 : 1,
                }}>
                <FlexColumn
                    fillParent
                    tag="SECTION_AREA_CARD"
                    shrink={0}
                    grow={1}
                    padding={{ all: 20 }}
                    background={{ linearGradient: darkeningGradientReverse }}
                    mainAxis="space-between"
                    crossAxis="stretch">
                    <FlexColumn shrink={0} crossAxis="flex-start">
                        <TextBox
                            text={title}
                            weight="800"
                            size={1}
                            color={theme.colors.white}
                            css={{
                                textShadow: "0 2px 5px rgba(0, 0, 0, 0.4)",
                            }}
                        />
                        <VerticalDivider XS />
                        <TextBox
                            text={kicker}
                            weight="800"
                            size={1}
                            color={theme.colors.white}
                            css={{
                                textShadow: "0 2px 5px rgba(0, 0, 0, 0.4)",
                            }}
                        />
                    </FlexColumn>
                    <FlexRow fillParentWidth mainAxis="space-between">
                        <Conditional
                            when={amount > 0}
                            else={() => <VerticalDivider />}>
                            <Circle
                                onClick={e => {
                                    showPickedExtras();
                                    e.stopPropagation();
                                }}
                                size={50}>
                                <TextBox
                                    weight="900"
                                    text={amount.toString()}
                                />
                            </Circle>
                        </Conditional>

                        <Conditional
                            when={!props.extras?.disabled}
                            else={() => {
                                return (
                                    <FlexColumn
                                        background={{
                                            color: theme.colors.black.withAlpha(
                                                0.2,
                                            ),
                                        }}
                                        padding={{ all: 4 }}
                                        css={css`
                                            border-radius: ${theme.radius}px;
                                            border: 1px solid
                                                ${theme.colors.white.asString};
                                        `}>
                                        <TextBox
                                            text={translation({
                                                sv: "Max uppnått",
                                                en: "Max reached",
                                            })}
                                            size="XXS"
                                            case="UPPERCASE"
                                            weight="800"
                                            align="center"
                                            padding={{
                                                leftRight: 6,
                                                topBottom: 6,
                                            }}
                                            color={theme.colors.white}
                                            css={{
                                                textShadow:
                                                    "0 2px 5px rgba(0, 0, 0, 0.4)",
                                                wordBreak: "break-word",
                                                maxWidth: "60px",
                                            }}
                                        />
                                    </FlexColumn>
                                );
                            }}>
                            <Conditional
                                when={!isOutOfStock}
                                else={() => {
                                    return (
                                        <FlexColumn
                                            background={{
                                                color: theme.colors.black.withAlpha(
                                                    0.2,
                                                ),
                                            }}
                                            padding={{ all: 4 }}
                                            css={css`
                                                border-radius: ${theme.radius}px;
                                                border: 1px solid
                                                    ${theme.colors.white
                                                        .asString};
                                            `}>
                                            <TextBox
                                                text={translation({
                                                    sv: "SLUT",
                                                    en: "OUT",
                                                })}
                                                size="XXS"
                                                case="UPPERCASE"
                                                weight="800"
                                                align="center"
                                                padding={{
                                                    leftRight: 6,
                                                    topBottom: 6,
                                                }}
                                                color={theme.colors.white}
                                                css={{
                                                    textShadow:
                                                        "0 2px 5px rgba(0, 0, 0, 0.4)",
                                                    wordBreak: "break-word",
                                                    maxWidth: "60px",
                                                }}
                                            />
                                        </FlexColumn>
                                    );
                                }}>
                                <div
                                    css={css`
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        position: relative;
                                        border-style: solid;
                                        border-radius: 50%;
                                        width: 50px;
                                        height: 50px;
                                        flex-grow: 0;
                                        flex-shrink: 0;
                                        border-width: 1.5px;
                                        cursor: pointer;
                                        background-color: ${theme.colors.black.withAlpha(
                                            0.2,
                                        ).asString};
                                        border-color: ${theme.colors.white
                                            .asString};
                                    `}>
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        color={theme.colors.white.asString}
                                    />
                                </div>
                            </Conditional>
                        </Conditional>
                    </FlexRow>
                </FlexColumn>
            </SectionAreaCard>
            <BookableProductExtraModal
                modalId={modalId}
                product={product}
                extras={props.extras}
            />
            <BookableProductExtrasGroupModal
                product={product}
                modalId={extraModalId}
                extras={props.extras ? [props.extras] : []}
            />
        </>
    );
};

export { BookableGridProductExtraCardV2 };
