import { currentTimezoneName } from "@@/shared/current-timezone-name";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { TimeRange, formatBookedTime, translation } from "@towni/common";
type Props = {
    time: TimeRange;
    hideEnd?: boolean;
    isTableBooking: boolean;
};

const BookedTimeRow = (props: Props) => {
    const theme = useTheme();
    return (
        <FlexRow padding={{ leftRight: 20, topBottom: 15 }}>
            <FlexColumn width="100%">
                <TextBox
                    color={theme.colors.white.main.light50}
                    text={translation({ sv: "Datum", en: "Date" })}
                />

                <VerticalDivider XXS />
                <TextBox
                    size="ML"
                    weight="800"
                    dataTestId="bd-booked-time"
                    text={formatBookedTime({
                        time: props.time,
                        onlyStartTime: props.isTableBooking,
                        currentTimezoneName: currentTimezoneName,
                    })}
                />
            </FlexColumn>
        </FlexRow>
    );
};

export { BookedTimeRow };
