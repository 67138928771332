import {
    VirtualList,
    VirtualRow,
    VirtualRowId,
    VirtualRowRenderer,
} from "@@/backoffice/shared/virtual-list";
import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModalActions } from "@@/modals/use-modal-actions";
import { browserLogger } from "@@/settings/browser-logger";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Inputs } from "@@/shared/form/standalones/inputs";
import { TextBox } from "@@/shared/text/text-box";
import { CheckBoxWithText, TextInput } from "@@/shared/text/text-input";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    Product,
    ProductId,
    Translatable,
    generateId,
    remSize,
    translation,
} from "@towni/common";
import * as React from "react";
import { ProductPickerRow } from "./product-picker-row";
import { useAvailableProducts } from "./use-avaible-bookable-products";

type Props = {
    modalId: ModalId;
    title?: Translatable;
    products: Product[];
    onToggle: (productId: ProductId) => void;
    selected: Set<ProductId>;
    mode: "SINGLE" | "MULTI";
};

const ProductPickerModal = (props: Props) => {
    const theme = useTheme();
    const [_textFilter, setTextFilter] = React.useState("");
    const { title, modalId } = props;
    const { hide } = useModalActions(props.modalId);

    const onToggle = React.useCallback(
        (productId: ProductId) => {
            props.onToggle(productId);
            if (props.mode === "SINGLE") hide();
        },
        [hide, props],
    );

    return (
        <Modal
            header={
                <ModalHeader
                    modalId={modalId}
                    bottomBorder
                    title={
                        title ??
                        (props.mode === "MULTI"
                            ? translation({
                                  sv: "Välj produkter",
                                  en: "Select products",
                              })
                            : translation({
                                  sv: "Välj produkt",
                                  en: "Select product",
                              }))
                    }
                    topRightButton={
                        props.mode === "MULTI"
                            ? {
                                  _type: "TOP_RIGHT_ACTION_TEXT",
                                  text: translation({
                                      sv: "Klar",
                                      en: "Ready",
                                  }),
                                  action: () => {
                                      hide();
                                      // setSelectedFilter(false);
                                      setTextFilter("");
                                  },
                              }
                            : undefined
                    }
                />
            }
            modalId={modalId}>
            <FlexColumn
                fillParentWidth
                crossAxis="flex-start"
                overflowY="hidden"
                background={{ color: theme.colors.default.background }}
                css={{
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                }}>
                <ProductPickerComponent {...props} onToggle={onToggle} />
            </FlexColumn>
        </Modal>
    );
};
type PickerProps = {
    title?: Translatable;
    products: Product[];
    onToggle: (productId: ProductId) => void;
    selected: Set<ProductId>;
    mode: "SINGLE" | "MULTI";
};
const ProductPickerComponent = (props: PickerProps) => {
    const theme = useTheme();
    const [listId] = React.useState(() => generateId({ length: 6 }));
    const translate = useTranslate();
    const [textFilter, setTextFilter] = React.useState("");
    const [activeFilter, setActiveFilter] = React.useState(true);
    const [selectedFilter, setSelectedFilter] = React.useState(false);
    const { products, selected } = props;
    const [availableProducts] = useAvailableProducts(
        products.length > 0 ? products[0].providerId : undefined,
    );
    const filteredProducts = React.useMemo(() => {
        return products.filter(product => {
            // Filter by text value
            const textFilterMatch =
                !textFilter ||
                translate(product.meta.title)
                    .toLocaleLowerCase()
                    .includes(textFilter.toLocaleLowerCase());

            // Filter if selected
            const selectedFilterMatch =
                !selectedFilter || selected.has(product._id);

            // Hide all inactive products; that are not already selected
            if (product.status.type !== "ACTIVE" && !selectedFilterMatch) {
                return false;
            }

            const hasSessionAndFiltered =
                !activeFilter ||
                product.acquirableAs !== "BOOKABLE" ||
                availableProducts?.some(p => p._id === product._id);

            // TODO! filter by provider names as well
            return (
                textFilterMatch && selectedFilterMatch && hasSessionAndFiltered
            );
        });
    }, [
        products,
        textFilter,
        translate,
        selectedFilter,
        selected,
        activeFilter,
        availableProducts,
    ]);

    const listData: VirtualRow<
        "PRODUCT_ROW",
        {
            _type: "PRODUCT_ROW";
            product: Product;
        }
    >[] = React.useMemo(() => {
        const items = filteredProducts.map(product => {
            return {
                _id: `${product._id}` as VirtualRowId,
                data: {
                    product,
                    _type: "PRODUCT_ROW" as const,
                },
            };
        });
        return items;
    }, [filteredProducts]);

    const onToggle = React.useCallback(
        (productId: ProductId) => {
            props.onToggle(productId);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.onToggle],
    );

    const renderRow: VirtualRowRenderer<
        "PRODUCT_ROW",
        {
            _type: "PRODUCT_ROW";
            product: Product;
        }
    > = params => {
        const { item, onHeightChange, isLast } = params;

        return (
            <ProductPickerRow
                key={item._id}
                product={item.data.product}
                onSizeChange={onHeightChange}
                toggle={onToggle}
                selected={selected.has(item.data.product._id)}
                padding={{ top: 12, bottom: isLast ? 40 : 0 }}
            />
        );
    };

    return (
        <>
            <FlexColumn
                fillParentWidth
                background={{
                    color: theme.isLightTheme
                        ? theme.colors.primary.light
                        : theme.colors.default.background.value.withLight(0.1),
                }}
                crossAxis="flex-start"
                padding={{ leftRight: 20, top: 0 }}>
                <VerticalDivider S />
                <FlexRow fillParentWidth mainAxis="space-between">
                    <TextInput
                        type="text"
                        grow={1}
                        padding={{ leftRight: 15, topBottom: 12 }}
                        size={remSize(0.875)}
                        placeholder={translation({
                            sv: "Sök",
                            en: "Search",
                        })}
                        initialValue={textFilter}
                        onChange={setTextFilter}
                        onValidationFailed={browserLogger.log}
                    />

                    <Conditional when={props.mode === "MULTI"}>
                        <HorizontalDivider S />
                        <CheckBoxWithText
                            checked={selectedFilter}
                            text={
                                props.mode === "MULTI"
                                    ? translation({
                                          sv: "Endast valda",
                                          en: "Only selected",
                                      })
                                    : translation({
                                          sv: "Endast vald",
                                          en: "Only selected",
                                      })
                            }
                            onChange={setSelectedFilter}
                        />
                    </Conditional>
                </FlexRow>
                <VerticalDivider S />
                <Inputs.Checkbox
                    initialValue={activeFilter}
                    onChange={setActiveFilter}
                    text={translation({
                        sv: "Visa bara aktiva och bokningsbara",
                        en: "Show only active and bookable",
                    })}
                />
                <VerticalDivider S />
            </FlexColumn>
            <FlexRow fillParentWidth mainAxis="stretch" crossAxis="stretch">
                <FlexColumn fillParentWidth grow={1} shrink={1} height="60vh">
                    <Conditional when={!filteredProducts.length}>
                        <FlexRow
                            padding={{ all: 40 }}
                            fillParentWidth
                            mainAxis="center">
                            <TextBox
                                text={translation({
                                    sv: "(inga produkter)",
                                    en: "(no products)",
                                })}
                                align="center"
                                size={0.875}
                                css={{ width: "100%" }}
                                color={theme.colors.disabled.text.value.withAlpha(
                                    0.5,
                                )}
                            />
                        </FlexRow>
                    </Conditional>
                    <Conditional when={!!filteredProducts.length}>
                        <VirtualList
                            items={listData}
                            listId={listId}
                            rowRenderer={renderRow}
                            whenNoContent={
                                <TextBox
                                    text={
                                        props.mode === "MULTI"
                                            ? translation({
                                                  sv: "(inga valda produkter)",
                                                  en: "(no selected products)",
                                              })
                                            : translation({
                                                  sv: "(ingen produkt vald)",
                                                  en: "(no product selected)",
                                              })
                                    }
                                    align="center"
                                    size={0.875}
                                    padding={{ all: 20 }}
                                    color={theme.colors.disabled.text.value.withAlpha(
                                        0.5,
                                    )}
                                />
                            }
                        />
                    </Conditional>
                </FlexColumn>
            </FlexRow>
        </>
    );
};

export { ProductPickerComponent, ProductPickerModal };
