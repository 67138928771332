import { isNotUndefined, setWithinRange } from "@towni/common";
import * as React from "react";
import { useCallback, useContext, useMemo } from "react";

const WIZARD_STATE = "WIZARD_STATE";
type WIZARD_STATE = typeof WIZARD_STATE;

type State = {
    readonly _type: WIZARD_STATE;
    readonly status: "IN_PROGRESS" | "CANCELLED" | "FINISHED";
    readonly index: number;
    readonly pages: string[];
    readonly isFirst: boolean;
    readonly isLast: boolean;
    readonly latestDirection: "FORWARD" | "BACKWARD";
    readonly ignoreAnimation: boolean;
};

const createInitialState = (params: {
    numberOfPages: number;
    ignoreAnimation: boolean;
}): State => {
    const lastIndex = setWithinRange(params.numberOfPages - 1, { min: 0 });
    return {
        _type: WIZARD_STATE,
        status: "IN_PROGRESS",
        index: 0,
        pages: [],
        isFirst: true,
        isLast: lastIndex === 0,
        latestDirection: "FORWARD",
        ignoreAnimation: params.ignoreAnimation,
    };
};

type Action =
    | {
          type: "GO_FORWARD";
          steps?: number;
      }
    | {
          type: "ADD_PAGE";
          pageId: string;
          at?: number;
      }
    | {
          type: "SET_PAGES";
          pageIds: string[];
          startIndex: number;
      }
    | {
          type: "FINISHED";
      }
    | {
          type: "CANCELLED";
      }
    | {
          type: "GO_BACK";
          steps?: number;
      }
    | {
          type: "GO_TO_INDEX";
          index: number;
      }
    | {
          type: "GO_TO";
          pageId: string;
      };

type Dispatch = (action: Action) => void;
const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "ADD_PAGE": {
            const newPages = isNotUndefined(action.at)
                ? action.at >= state.pages.length
                    ? [...state.pages, action.pageId]
                    : [...state.pages.splice(action.at, 0, action.pageId)]
                : [...state.pages, action.pageId];
            return {
                ...state,
                pages: newPages,
            };
        }
        case "SET_PAGES": {
            const newState = {
                ...state,
                pages: action.pageIds,
                index: action.startIndex ?? 0,
                isFirst: action.startIndex === 0,
                isLast: action.pageIds.length - 1 === action.startIndex,
            };
            // console.log("SET_PAGES", { newState, state });
            return newState;
        }
        case "GO_BACK": {
            const steps = action.steps ?? 1;
            const lastIndex = state.pages.length - 1;
            const newIndex = setWithinRange(state.index - steps, { min: 0 });
            const newState: State = {
                ...state,
                index: newIndex,
                isFirst: newIndex === 0,
                isLast: newIndex === lastIndex,
                latestDirection: "BACKWARD",
            };
            // console.log("BACKWARD", {
            //     thing: state.index - steps,
            //     steps,
            //     lastIndex,
            //     newIndex,
            //     oldState: state,
            //     newState: {
            //         ...state,
            //         index: newIndex,
            //         isFirst: newIndex === 0,
            //         isLast: newIndex === lastIndex,
            //         latestDirection: "BACKWARD",
            //     },
            // });
            return newState;
        }
        case "GO_FORWARD": {
            const steps = action.steps ?? 1;
            const lastIndex = state.pages.length - 1;
            const newIndex = setWithinRange(state.index + steps, {
                max: lastIndex,
            });
            // console.log("FORWARD", {
            //     steps,
            //     lastIndex,
            //     newIndex,
            //     oldState: state,
            //     newState: {
            //         ...state,
            //         index: newIndex,
            //         isFirst: newIndex === 0,
            //         isLast: newIndex === lastIndex,
            //         latestDirection: "FORWARD",
            //     },
            // });
            return {
                ...state,
                index: newIndex,
                isFirst: newIndex === 0,
                isLast: newIndex === lastIndex,
                latestDirection: "FORWARD",
            };
        }
        case "GO_TO_INDEX": {
            const lastIndex = state.pages.length - 1;
            const newIndex = action.index;
            if (newIndex < 0 || newIndex > lastIndex) {
                throw new Error(
                    "Can't go to index outside of wizard step range",
                );
            }
            return {
                ...state,
                index: newIndex,
                isFirst: newIndex === 0,
                isLast: newIndex === lastIndex,
            };
        }
        case "GO_TO": {
            const lastIndex = state.pages.length - 1;
            const newIndex = state.pages.indexOf(action.pageId);
            if (newIndex === -1) {
                throw new Error(`Page ${action.pageId} not found in wizard`);
            }
            return {
                ...state,
                index: newIndex,
                isFirst: newIndex === 0,
                isLast: newIndex === lastIndex,
            };
        }
        case "CANCELLED": {
            return {
                ...state,
                status: "CANCELLED",
            };
        }
        case "FINISHED": {
            return { ...state, status: "FINISHED" };
        }
        default:
            return state;
    }
};

const WizardStateContext = React.createContext<State | undefined>(undefined);
const WizardDispatchContext = React.createContext<
    | {
          dispatch: Dispatch;
          onFinish?: () => void;
          onCancel?: () => void;
      }
    | undefined
>(undefined);

const WizardProvider = ({
    children,
    ...props
}: {
    children?: React.ReactNode;
    ignoreAnimation: boolean;
    onFinish?: () => void | Promise<void>;
    onCancel?: () => void | Promise<void>;
}) => {
    const [state, dispatch] = React.useReducer(
        reducer,
        createInitialState({
            numberOfPages: 0,
            ignoreAnimation: props.ignoreAnimation,
        }),
    );

    const actions = React.useMemo(
        () => ({
            dispatch,
            onFinish: props.onFinish,
            onCancel: props.onCancel,
        }),
        [dispatch, props.onCancel, props.onFinish],
    );

    return (
        <WizardStateContext.Provider value={state}>
            <WizardDispatchContext.Provider value={actions}>
                {children}
            </WizardDispatchContext.Provider>
        </WizardStateContext.Provider>
    );
};
const useWizardState = () => {
    const context = React.useContext(WizardStateContext);
    if (context === undefined) {
        throw new Error("useWizardState must be used within a WizardProvider");
    }
    //console.log("WIZARD STATE", context);
    return context;
};

const useWizardDispatch = () => {
    const actions = useContext(WizardDispatchContext);
    if (actions === undefined) {
        throw new Error(
            "useWizardDispatch must be used within a WizardProvider",
        );
    }
    const { dispatch, onCancel, onFinish } = actions;

    const goForward = useCallback(
        (steps = 1) => {
            //console.log("GO FORWARD", steps);
            dispatch({ type: "GO_FORWARD", steps });
        },
        [dispatch],
    );
    const goBack = useCallback(
        (steps = 1) => dispatch({ type: "GO_BACK", steps }),
        [dispatch],
    );
    const goToIndex = useCallback(
        (index: number) => dispatch({ type: "GO_TO_INDEX", index }),
        [dispatch],
    );
    const goTo = useCallback(
        (pageId: string) => dispatch({ type: "GO_TO", pageId }),
        [dispatch],
    );
    const finish = useCallback(() => {
        onFinish?.();
        dispatch({ type: "FINISHED" });
    }, [onFinish, dispatch]);
    const cancel = useCallback(() => {
        onCancel?.();
        dispatch({ type: "CANCELLED" });
    }, [onCancel, dispatch]);
    const addPage = useCallback(
        (pageId: string, at?: number) => {
            dispatch({
                type: "ADD_PAGE",
                pageId,
                at,
            });
        },
        [dispatch],
    );
    const setPages = useCallback(
        (pageIds: string[], startIndex = 0) => {
            dispatch({
                type: "SET_PAGES",
                pageIds,
                startIndex,
            });
        },
        [dispatch],
    );

    return useMemo(
        () => ({
            dispatch,
            goForward,
            goBack,
            goToIndex,
            goTo,
            finish,
            cancel,
            addPage,
            setPages,
        }),
        [
            dispatch,
            goForward,
            goBack,
            goToIndex,
            goTo,
            finish,
            cancel,
            addPage,
            setPages,
        ],
    );
};

const WithWizardProvider = (props: {
    readonly children: (
        state: ReturnType<typeof useWizardState>,
        actions: ReturnType<typeof useWizardDispatch>,
    ) => JSX.Element;
}) => {
    const state = useWizardState();
    const actions = useWizardDispatch();
    return props.children?.(state, actions) ?? null;
};

type WizardDispatchActions = ReturnType<typeof useWizardDispatch>;

export {
    WithWizardProvider,
    WizardProvider,
    useWizardDispatch,
    useWizardState,
};
export type { WizardDispatchActions, State as WizardState };
