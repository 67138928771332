import { FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { css, useTheme } from "@emotion/react";
import { faMinus } from "@fortawesome/pro-light-svg-icons";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";

type Props = {
    quantity: number;
    disabled?: boolean;
    increase: () => void;
    decrease: () => void;
};
const CartQuantityPicker = ({
    disabled,
    increase,
    decrease,
    quantity,
}: Props) => {
    const theme = useTheme();
    const ifNotDisabled = (action: () => void) => {
        if (disabled === true) return;
        action();
    };

    const disabledStyle = disabled
        ? css`
              background-color: ${theme.colors.disabled.asString};
              color: ${theme.colors.disabled.text.asString};
          `
        : css``;

    const mainStyle = css`
        color: black;
        background-color: ${theme.colors.white.main.light90.asString};
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        user-select: none;
        font-size: 1rem;
        ${disabledStyle}
    `;

    const subtractContainerStyle = css`
        ${mainStyle}
        width: 50px;
        height: 50px;
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
        font-size: 0.75rem;
        cursor: pointer;
        :active {
            opacity: 0.7;
        }
    `;

    const addContainerStyle = css`
        ${mainStyle}
        color: ${theme.colors.primary.asString};
        width: 50px;
        height: 50px;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        font-size: 0.75rem;
        cursor: pointer;
        :active {
            opacity: 0.7;
        }
    `;

    return (
        <FlexRow grow={1} mainAxis="flex-start" crossAxis="stretch">
            <a
                css={subtractContainerStyle}
                data-testid="subtract-quantity"
                onClick={() => ifNotDisabled(decrease)}>
                <Icon icon={faMinus} />
            </a>
            <TextBox
                text={quantity.toString()}
                size={1.5}
                padding={{ leftRight: 10 }}
                dataTestId="current-quantity"
                css={[
                    {
                        minWidth: 40,
                    },
                    mainStyle,
                ]}
            />
            <a
                css={addContainerStyle}
                data-testid="add-quantity"
                onClick={() => ifNotDisabled(increase)}>
                <Icon icon={faPlus} />
            </a>
        </FlexRow>
    );
};

export { CartQuantityPicker };
