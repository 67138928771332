import { useOrderSocketEventsForMe } from "@@/backoffice/for-providers/orders/use-orders-events-for-me";
import { PageNotFound } from "@@/backoffice/shared/not-found-page";
import { PageLoad } from "@@/pages/page-load";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { CompanyDetails } from "@@/receipts/shared/company-details";
import {
    BrandingProvider,
    useBrandingContext,
} from "@@/routes/shared/branding-context";
import { useBrandingBackgroundCss } from "@@/routes/shared/default-branding";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { backendHost } from "@@/shared/fetch-client";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { GlobalMaxWidthCss } from "@@/shared/global-max-width";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { ShowOrTextShimmer } from "@@/shared/show-or-spin";
import {
    Paragraph,
    PriceTextBox,
    TextBox,
    paragraphLineBreakFactory,
} from "@@/shared/text";
import { NavigateTo } from "@@/shared/text/navigate-to";
import { ProviderLogoLinkCircle } from "@@/store-fronts/towni/sections/providers/provider-logo-link-circle";
import { css, useTheme } from "@emotion/react";
import {
    PayRequestForPayerProjection,
    isPayRequestId,
    parseProviderPosterImage,
    parseSafely,
    payRequestIdZodSchema,
    payRequestStripeCheckoutCommandFactory,
    translation,
} from "@towni/common";
import { useParams } from "react-router-dom";
import { PayRequestItemPriceTable } from "./pay-request-item-price-table";
import { PaymentOptionContextProvider } from "./payment-options/payment-option-context";
import { useCheckoutPayRequestCommand } from "./use-checkout-pay-request-command";
import { usePayRequestForPayerQuery } from "./use-pay-request-for-payer-query";

const logoLinkCss = css({
    color: "#ffffff",
    fontStyle: "italic",
    ":hover": {
        color: "palegreen",
    },
});

type Props = {
    readonly payRequest: PayRequestForPayerProjection;
};

const PaymentInfo__ = (props: Props) => {
    const theme = useTheme();
    const [provider, _providerQuery] = useProvider(
        props.payRequest.paymentFor.providerId,
    );

    return (
        <>
            <LayoutGrid>
                <LayoutCell
                    css={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        alignItems: "stretch",
                        justifyContent: "stretch",
                        label: "payment_info_container",
                    }}>
                    <FlexColumn
                        mainAxis="flex-start"
                        crossAxis="stretch"
                        css={{
                            flex: 1,
                            padding: 20,
                        }}>
                        <TextBox
                            text={translation({
                                sv: "Betalning",
                                en: "Payment",
                            })}
                            size={1.9}
                            weight="900"
                            align="center"
                            css={{
                                flex: 1,
                            }}
                        />
                        <VerticalDivider M />
                        <FlexRow mainAxis="center" css={{ flex: 1 }}>
                            <Paragraph
                                css={{
                                    fontSize: "0.825rem",
                                    maxWidth: 220,
                                    color: theme.colors.default.text.withAlpha(
                                        0.3,
                                    ).asString,
                                    textAlign: "center",
                                }}
                                content={[
                                    translation({
                                        sv: "Slutför din betalning till ",
                                        en: "Complete your payment to ",
                                    }),
                                    provider?.meta.title ??
                                        translation({
                                            sv: "säljaren",
                                            en: "the seller",
                                        }),
                                    translation({
                                        sv: " här via Towni",
                                        en: " here using Towni",
                                    }),
                                ]}
                            />
                        </FlexRow>
                        <VerticalDivider XL />
                        <PayRequestItemPriceTable
                            items={props.payRequest.items}
                            payRequestPrice={props.payRequest.price}
                        />
                        <ShowOrTextShimmer
                            showWhen={provider}
                            shimmerCss={{
                                paddingTop: 30,
                            }}>
                            {provider => (
                                <>
                                    <VerticalDivider L />
                                    <CompanyDetails
                                        provider={provider}
                                        options={{
                                            hideProviderTitleAndId: true,
                                        }}
                                    />
                                </>
                            )}
                        </ShowOrTextShimmer>
                    </FlexColumn>
                </LayoutCell>
            </LayoutGrid>
        </>
    );
};

const PayRequestPageContent = (props: Props) => {
    const isMedium = usePageBreakpoint({ when: "M" });
    const isLarge = usePageBreakpoint({ when: "L" });
    // const isDesktop = isMedium || isLarge;
    const theme = useTheme();
    // const [, modalId] = useModal();
    const [provider, _providerQuery] = useProvider(
        props.payRequest.paymentFor.providerId,
    );
    const { branding } = useBrandingContext();
    const backgroundCss = useBrandingBackgroundCss(
        branding?.receipt.background,
    );
    const checkout = useCheckoutPayRequestCommand();
    if (!provider) return <PageLoad />;
    const poster = parseProviderPosterImage(provider);
    return (
        <div
            css={[
                {
                    width: "100%",
                    height: "100%",
                    overflowX: "hidden",
                    overflowY: "scroll",
                    backgroundColor: theme.colors.primary.dark.asString,
                },
                backgroundCss,
            ]}>
            <FlexColumn
                mainAxis="stretch"
                crossAxis="center"
                css={{
                    width: "100%",
                }}>
                <PaymentOptionContextProvider provider={provider}>
                    <GlobalMaxWidthCss />
                    <VerticalDivider XXL />
                    <LayoutGrid
                        css={{
                            backgroundColor:
                                theme.colors.default.background.asString,
                            width: isMedium ? 440 : isLarge ? 500 : "93%",
                            minWidth: 340,
                            borderWidth: 0,
                            borderRadius: 10,
                            boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.1)",
                            overflow: "hidden",
                            transition: "all 0.2s ease",
                        }}>
                        <LayoutCell>
                            <FlexRow
                                mainAxis="center"
                                crossAxis="center"
                                background={{
                                    imageReference: poster,
                                    fit: "COVER",
                                }}
                                css={{
                                    minHeight: 120,
                                    flex: 1,
                                }}
                            />
                            <FlexColumn>
                                <VerticalDivider XXS />
                                <FlexRow css={{ padding: 15 }}>
                                    <ProviderLogoLinkCircle
                                        provider={provider}
                                        disableLink
                                        hideTitle
                                        size="3XS"
                                    />
                                    <HorizontalDivider />
                                    <FlexColumn>
                                        <TextBox
                                            text={provider.meta.title}
                                            weight="900"
                                            size="M"
                                            color={theme.colors.black.light10}
                                        />
                                        <TextBox
                                            text={provider.meta.subtitle}
                                            size="S"
                                            color={theme.colors.black.light10}
                                        />
                                    </FlexColumn>
                                </FlexRow>
                            </FlexColumn>
                            <HorizontalLine size={1} />
                            <VerticalDivider ML />
                            <PaymentInfo__ payRequest={props.payRequest} />
                        </LayoutCell>
                        <LayoutCell
                            css={{
                                padding: 30,
                                paddingTop: 35,
                                backgroundColor:
                                    theme.colors.primary.light.asString,
                            }}>
                            <form
                                action={`/api/payments/stripe/checkouts/pay-requests/${props.payRequest._id}`}>
                                <input
                                    type="hidden"
                                    name="relativeSuccessPath"
                                    value=""
                                />
                                <ButtonPrimary
                                    spin={checkout.isPending}
                                    onClick={() => {
                                        const orderGroupId =
                                            props.payRequest.paymentFor
                                                .orderGroupId;
                                        if (!orderGroupId)
                                            throw new Error(
                                                "OrderGroupId must be available in pay request payment for",
                                            );

                                        checkout.mutate(
                                            payRequestStripeCheckoutCommandFactory(
                                                {
                                                    payRequestId:
                                                        props.payRequest._id,
                                                    relativeSuccessPath: `/pay-requests/${props.payRequest._id}/processing`,
                                                    relativeCancelPath: `/pay-requests/${props.payRequest._id}`,
                                                },
                                            ),
                                        );
                                        return;
                                    }}
                                    css={{ width: "auto", height: 55 }}>
                                    <FlexRow>
                                        <TextBox
                                            size={1.1}
                                            text={translation({
                                                sv: "Betala",
                                                en: "Pay",
                                            })}
                                        />
                                        <HorizontalDivider />
                                        <PriceTextBox
                                            price={props.payRequest.price}
                                            size={1.1}
                                        />
                                    </FlexRow>
                                </ButtonPrimary>
                            </form>
                            <FlexRow mainAxis="center" fillParentWidth>
                                <TextBox
                                    text={translation({
                                        sv: "Flera betalmetoder finns tillgängliga",
                                        en: "Multiple payment methods are available",
                                    })}
                                    size={0.725}
                                    align="center"
                                    color={theme.colors.default.text.withAlpha(
                                        0.3,
                                    )}
                                    padding={{ all: 10, bottom: 0 }}
                                />
                            </FlexRow>
                        </LayoutCell>
                    </LayoutGrid>
                    <VerticalDivider XXL />
                    <a
                        href={`https://towni.se`}
                        title="Towni"
                        target="_blank"
                        rel="noreferrer"
                        css={logoLinkCss}>
                        <img
                            src={`${backendHost}/public/storage/towni-brand/logos/towni-logo-hz-white-on-dark-medium.png`}
                            // src={`${backendHost}/public/storage/towni-brand/logos/towni-logo-circle-green-on-white-small.png`}
                            alt="Towni logo"
                            style={{ height: 40, opacity: 0.3 }}
                        />
                    </a>
                    <VerticalDivider XXL />
                    <VerticalDivider XXL />
                    {/* <PayRequestPaymentModal
                        modalId={modalId}
                        payRequest={props.payRequest}
                    /> */}
                </PaymentOptionContextProvider>
            </FlexColumn>
        </div>
    );
};

const PayRequestPage = () => {
    const payRequestId = parseSafely({
        schema: payRequestIdZodSchema,
        value: useParams<{ payRequestId: string }>()?.payRequestId,
    });
    const payRequestQuery = usePayRequestForPayerQuery(payRequestId);
    useOrderSocketEventsForMe({
        onOrderEvent: (event, _room) => {
            const orderId = payRequestQuery?.data?.item.paymentFor.orderId;
            if (!orderId) return;
            if (event.data.orderId === orderId) {
                void payRequestQuery.refetch();
            }
        },
    });

    const payRequest = payRequestQuery.data?.item;

    if (payRequestQuery.isInitialLoading) return <PageLoad />;
    if (!payRequest)
        return (
            <PageNotFound
                reason={translation({
                    sv: "Betallänken ogiltig",
                    en: "The payment link is invalid",
                })}
            />
        );
    if (payRequest.paymentFulfilled)
        return <NavigateTo to={`/pay-requests/${payRequestId}/processing`} />;
    if (payRequest.status.name === "cancelled") {
        if (
            isPayRequestId(payRequest.replacedBy) &&
            payRequest.replacedBy !== payRequest._id
        ) {
            // If payment request has been replaced by another payment request
            // navigate to the new payment request instead
            return <NavigateTo to={`/pay-requests/${payRequest.replacedBy}`} />;
        }

        // Pay request has been cancelled and
        // no replacement pay request exists
        return (
            <PageNotFound
                reason={[
                    translation({
                        sv: "Länken är inte längre aktiv.",
                        en: "The link is no longer active.",
                    }),
                    paragraphLineBreakFactory(),
                    translation({
                        sv: "Betalningsförfrågan har avbrutits.",
                        en: "The payment request has been cancelled.",
                    }),
                ]}
            />
        );
    }

    return (
        <BrandingProvider providerId={payRequest.paymentFor.providerId}>
            <PayRequestPageContent payRequest={payRequest} />
        </BrandingProvider>
    );
};
export { PayRequestPage };
