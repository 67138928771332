import { useImageUrl } from "@@/shared/image-url";
import { AppTheme } from "@@/styles/theme";
import { lightColorTheme } from "@@/styles/themes/default.theme";
import { Interpolation } from "@emotion/react";
import {
    Branding,
    BrandingBackground,
    brandingIdFactory,
    colorAsString,
    gradientToCssValue,
    hexColor,
    now,
} from "@towni/common";

const defaultBranding: Branding = {
    _id: brandingIdFactory(),
    _createdAt: now(),
    _type: "BRANDING",
    name: "Lasse & Maja",
    state: "PUBLISHED",

    colorThemes: {
        light: lightColorTheme,
    },
    product: {
        margin: [0, 0, 0],
        background: {
            color: hexColor("#ffffff"),
            image: undefined,
            textColor: hexColor("#000000"),
            gradient: undefined,
        },
        peripheralBackground: {
            color: lightColorTheme.white.main.light95.asHex,
            image: undefined,
            textColor: lightColorTheme.black.light40.asHex,
            gradient: undefined,
        },
    },
    receipt: {
        background: {
            color: lightColorTheme.primary.main.value.asHex,
            image: undefined,
            textColor: lightColorTheme.primary.text.value.asHex,
            gradient: {
                _type: "LINEAR_GRADIENT",
                steps: [
                    {
                        color: "rgba(17, 20, 37, 0.5)",
                        percentage: 0.32,
                    },
                    {
                        color: "rgba(17, 20, 37, 0)",
                        percentage: 0.974,
                    },
                ],
                degrees: 333.07,
            },
        },
        peripheralBackground: {
            image: undefined,
            color: lightColorTheme.white.main.light95.asHex,
            textColor: lightColorTheme.black.light40.asHex,
            gradient: undefined,
        },
    },
    // linear-gradient(333.07deg, rgba(17, 20, 37, 0.5) 31.71%, rgba(17, 20, 37, 0) 97.4%)
};

const parseBackgroundCss = (
    backgroundImageCssValue: string | undefined,
    backgroundGradientCssValue: string | undefined,
    backgroundColor: string | undefined,
): Interpolation<AppTheme> => {
    const _backgroundImageCssValue = backgroundImageCssValue;
    const _backgroundGradientCssValue = backgroundGradientCssValue;
    const _backgroundColor = backgroundColor
        ? backgroundColor
        : lightColorTheme.black.light95.asString;

    if (_backgroundImageCssValue && _backgroundGradientCssValue) {
        return {
            backgroundColor: _backgroundColor,
            backgroundImage: `${_backgroundGradientCssValue}, ${_backgroundImageCssValue}`,
        };
    }
    if (_backgroundImageCssValue && !_backgroundGradientCssValue) {
        return {
            backgroundColor: _backgroundColor,
            backgroundImage: _backgroundImageCssValue,
        };
    }
    if (_backgroundGradientCssValue && !_backgroundImageCssValue) {
        return {
            backgroundColor: _backgroundColor,
            backgroundImage: _backgroundGradientCssValue,
        };
    }
    return {
        backgroundColor: _backgroundColor,
    };
};
const useBrandingBackgroundCss = (
    background: BrandingBackground | undefined,
): Interpolation<AppTheme> => {
    const getImageUrl = useImageUrl();
    if (!background) return {};

    const backgroundColor = background.color
        ? colorAsString(background.color)
        : lightColorTheme.white.main.light95.asString;

    const backgroundImageUrl =
        typeof background.image === "string"
            ? background.image
            : getImageUrl(background.image);
    const backgroundImage = backgroundImageUrl?.trim().length
        ? backgroundImageUrl
        : undefined;
    const backgroundImageCssValue = backgroundImage
        ? `url(${backgroundImage})`
        : undefined;
    const backgroundGradientCssValue = gradientToCssValue(background.gradient);

    const backgroundCss = parseBackgroundCss(
        backgroundImageCssValue,
        backgroundGradientCssValue,
        backgroundColor,
    );
    return backgroundCss;
};
export { defaultBranding, useBrandingBackgroundCss };
