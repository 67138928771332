import { useProductLegacy } from "@@/products/state/queries/product/use-product";
import { useProducts } from "@@/products/state/queries/products/use-products";
import { useSection } from "@@/sections/state/section-fetchers";
import { browserLogger } from "@@/settings";
import { useFetchMultipleForSingle } from "@@/shared/generic-fetchers";
import {
    AlfredItemType,
    Product,
    ProductReference,
    SectionId,
} from "@towni/common";
// TODO: MOVE BELOW
const useAlfredItem = (reference: ProductReference) => {
    switch (reference.type) {
        case "PRODUCT":
            return useProductLegacy(reference.id);
        default:
            throw new Error(
                `unknown reference type: ${reference?.type ?? "[undefined]"}`,
            );
    }
};
const useAlfredItemsOfOneType = (references: ProductReference[]) => {
    const types = new Set<AlfredItemType>(references.map(item => item.type));
    if (types.size !== 1)
        throw new Error(
            "useAlfredItemsOfOneType cant use more than one type at a time",
        );

    const type = types.values().next().value as AlfredItemType;

    switch (type) {
        case "PRODUCT":
            return useProducts(references.map(item => item.id));
        default:
            throw new Error(
                "unknown reference type: " + (type ?? "[undefined]"),
            );
    }
};
const useAlfredItemsForSection = (sectionId: SectionId | undefined) => {
    const [section, _] = useSection(sectionId);
    if (section?.items.some(item => item.type !== "PRODUCT")) {
        browserLogger.error(
            `Doesn't support other reference types than "PRODUCT" yet: ${section.items
                .filter(item => item.type !== "PRODUCT")
                .map(item => JSON.stringify(item))}`,
        );
    }
    const query = useFetchMultipleForSingle<Product>({
        mainQueryKey: "products",
        mainRoute: "products",
        fetchFor: "section",
        id: sectionId,
    });
    return query;
};

export { useAlfredItem, useAlfredItemsForSection, useAlfredItemsOfOneType };
