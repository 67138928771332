import {
    emptySignUpData,
    InvitationId,
    Password,
    PhoneNumber,
    SignUpData,
} from "@towni/common";
import * as React from "react";
import { usePhoneSignInOrUpState } from "../phone/phone-sign-in-or-sign-in-context";

const SIGN_UP_STATE = "SIGN_UP_STATE";
type SIGN_UP_STATE = typeof SIGN_UP_STATE;

type Action =
    | { readonly type: "SET_FIRST_NAME"; readonly value: string }
    | { readonly type: "SET_LAST_NAME"; readonly value: string }
    | { readonly type: "SET_INVITATION_ID"; readonly value: InvitationId }
    | { readonly type: "SET_EMAIL_ADDRESS"; readonly value: string }
    | { readonly type: "SET_PHONE_NUMBER"; readonly value: PhoneNumber }
    | { readonly type: "SET_PASSWORD"; readonly value: Password }
    | { readonly type: "SET_INTERESTED"; readonly value: boolean };

type State = {
    readonly _type: SIGN_UP_STATE;
    readonly data: SignUpData;
};

const createInitialState = (initialData?: Partial<SignUpData>): State => ({
    _type: SIGN_UP_STATE,
    data: emptySignUpData(initialData),
});

type Dispatch = (action: Action) => void;
const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "SET_FIRST_NAME": {
            return {
                ...state,
                data: {
                    ...state.data,
                    firstName: action.value,
                },
            };
        }
        case "SET_LAST_NAME": {
            return {
                ...state,
                data: {
                    ...state.data,
                    lastName: action.value,
                },
            };
        }
        case "SET_INVITATION_ID": {
            return {
                ...state,
                data: {
                    ...state.data,
                    invitationId: action.value,
                },
            };
        }
        case "SET_PASSWORD": {
            return {
                ...state,
                data: {
                    ...state.data,
                    password: action.value,
                },
            };
        }
        case "SET_EMAIL_ADDRESS": {
            return {
                ...state,
                data: {
                    ...state.data,
                    emailAddress: action.value,
                },
            };
        }
        case "SET_PHONE_NUMBER": {
            return {
                ...state,
                data: {
                    ...state.data,
                    phoneNumber: action.value,
                },
            };
        }
        case "SET_INTERESTED": {
            return {
                ...state,
                data: {
                    ...state.data,
                    tips: action.value,
                },
            };
        }
        default:
            return state;
    }
};

const SignUpStateContext = React.createContext<State | undefined>(undefined);
const SignUpDispatchContext = React.createContext<Dispatch | undefined>(
    undefined,
);

const SignUpDataProvider = ({ children }: { children: React.ReactNode }) => {
    const phoneSignUp = usePhoneSignInOrUpState();
    const [state, dispatch] = React.useReducer(
        reducer,
        createInitialState({
            phoneNumber: phoneSignUp.data.phoneNumber,
            signUpId: phoneSignUp.data._id,
        }),
    );

    return (
        <SignUpStateContext.Provider value={state}>
            <SignUpDispatchContext.Provider value={dispatch}>
                {children}
            </SignUpDispatchContext.Provider>
        </SignUpStateContext.Provider>
    );
};

const useSignUpState = () => {
    const context = React.useContext(SignUpStateContext);
    if (context === undefined) {
        throw new Error("useSignUpState must be used within a SignUpProvider");
    }
    return context;
};

const useSignUpDispatch = () => {
    const dispatch = React.useContext(SignUpDispatchContext);
    if (dispatch === undefined) {
        throw new Error(
            "useSignUpDispatch must be used within a SignUpProvider",
        );
    }

    const setSignUpData = {
        setFirstName: (value: string) =>
            dispatch({ type: "SET_FIRST_NAME", value }),
        setLastName: (value: string) =>
            dispatch({ type: "SET_LAST_NAME", value }),
        setInvitationid: (value: InvitationId) =>
            dispatch({ type: "SET_INVITATION_ID", value }),
        setPassword: (value: Password) =>
            dispatch({ type: "SET_PASSWORD", value }),
        setEmailAddress: (value: string) =>
            dispatch({ type: "SET_EMAIL_ADDRESS", value }),
        setPhoneNumber: (value: PhoneNumber) =>
            dispatch({ type: "SET_PHONE_NUMBER", value }),
        setInterest: (value: boolean) =>
            dispatch({ type: "SET_INTERESTED", value }),
    };

    return [setSignUpData] as const;
};
export type { Action as SignUpAction, State as SignUpState };
export { SignUpDataProvider, useSignUpState, useSignUpDispatch };
