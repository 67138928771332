import { useMe } from "@@/authentication/me/me-context";
import { Conditional } from "@@/shared/conditional";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import {
    Paragraph,
    TextBox,
    paragraphHzSpaceFactory,
    paragraphTextFactory,
} from "@@/shared/text";
import { getProviderScope } from "@@/users/scopes";
import { useTheme } from "@emotion/react";
import {
    capitalCase,
    consolidateResourceRequestsOption,
    groupBy,
    isManifestBookableOptionValue,
    translation,
    type ProviderId,
    type ResourceReservation,
    type ResourceReservationRequest,
} from "@towni/common";
import * as React from "react";
import { ResourceReservationRequestRow } from "./resource-reservation-request-row";

type Props = {
    providerId: ProviderId;
    resourceReservations: (ResourceReservation | ResourceReservationRequest)[];
};

const ResourceReservationRequestList = (props: Props) => {
    const [me] = useMe();
    const theme = useTheme();
    const scopes = getProviderScope({
        providerId: props.providerId,
        access: "read",
    });
    const hasScope = me?.scopes.some(item => scopes.includes(item)) ?? false;
    const [showDetails, setShowDetails] = React.useState(false);

    if (props.resourceReservations.length === 0) return null;

    const groupByTime = groupBy(
        props.resourceReservations,
        t => t.timeRange._id,
    );
    const firstInList = groupByTime.get(
        props.resourceReservations[0].timeRange._id,
    );
    if (!firstInList) return null;

    const groupByResource = groupBy(
        firstInList,
        t => t.resourceUnit.name.sv.plural,
    );

    const units = Array.from(groupByResource.keys());

    const showForCustomers = props.resourceReservations.filter(
        r => r.displayResourceTitle,
    );
    const otherReservations = props.resourceReservations.filter(
        r => !r.displayResourceTitle,
    );
    return (
        <FlexColumn
            css={{
                cursor: "default",
            }}
            onClick={() => {
                if (hasScope && otherReservations.length > 0)
                    setShowDetails(!showDetails);
            }}>
            {showForCustomers.map((b, index) => (
                <ResourceReservationRequestRow
                    key={b._id}
                    resourceReservation={b}
                    displayTime={false}
                    index={index}
                />
            ))}
            <Conditional when={showDetails}>
                {otherReservations.map((b, index) => (
                    <ResourceReservationRequestRow
                        key={b._id}
                        resourceReservation={b}
                        displayTime={true}
                        index={index}
                    />
                ))}
            </Conditional>
            <Conditional when={!showDetails && otherReservations.length > 0}>
                {units.map(unit => {
                    const reservations = groupByResource.get(unit);
                    if (!reservations) return null;

                    const optionValues =
                        consolidateResourceRequestsOption(reservations);
                    if (optionValues.length === 0) return null;

                    return (
                        <FlexRow
                            key={unit}
                            padding={{ leftRight: 20, topBottom: 15 }}>
                            <FlexColumn width="100%">
                                <TextBox
                                    dataTestId={`_bd-resource-title-0`}
                                    color={theme.colors.white.main.light50}
                                    text={capitalCase(unit)}
                                />
                                <VerticalDivider XXS />
                                <FlexRow>
                                    {optionValues.map((optionValue, i) => {
                                        if (
                                            isManifestBookableOptionValue(
                                                optionValue,
                                            )
                                        )
                                            return null;
                                        return (
                                            <Paragraph
                                                key={optionValue.parentId}
                                                dataTestId={`_bd-resource-title-0-value-${i}`}
                                                css={{
                                                    fontWeight: 800,
                                                    fontSize: "1.1rem",
                                                }}
                                                content={[
                                                    optionValue.quantity.value.toLocaleString(),
                                                    paragraphHzSpaceFactory(),
                                                    optionValue.meta.title,
                                                    optionValues.length !==
                                                    i + 1
                                                        ? paragraphTextFactory({
                                                              css: {
                                                                  whiteSpace:
                                                                      "pre",
                                                                  color: theme
                                                                      .colors
                                                                      .white
                                                                      .main
                                                                      .light50
                                                                      .asString,
                                                              },
                                                              text: translation(
                                                                  {
                                                                      sv: ", ",
                                                                  },
                                                              ),
                                                          })
                                                        : undefined,
                                                ]}
                                            />
                                        );
                                    })}
                                </FlexRow>
                            </FlexColumn>
                        </FlexRow>
                    );
                })}
            </Conditional>
        </FlexColumn>
    );
};

export { ResourceReservationRequestList };
