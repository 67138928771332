/* eslint-disable react/display-name */
import { VatPicker } from "@@/backoffice/shared/vat-picker";
import { browserLogger } from "@@/settings";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import { useElementSize } from "@@/shared/use-element-size";
import { AppTheme } from "@@/styles/theme";
import { Interpolation, useTheme } from "@emotion/react";
import {
    CurrencyCode,
    Percentage,
    Price,
    Translatable,
    isTranslatable,
    priceForHumansFactory,
    translation,
} from "@towni/common";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Conditional } from "../conditional";
import { HorizontalDivider, VerticalDivider } from "../dividers";
import { FlexRow } from "../flex-containers";
import { useCachedState } from "../use-cached-state";
import { FieldTitle } from "./field-title";
import { TextBox } from "./text-box";

type Props = {
    onFocus?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
    onChange: (value: Price | undefined) => void | Promise<void>;
    required?: boolean | "optional" | "required" | "recommended";
    initialPrice?: Price;
    vat: Percentage;
    currencyCode?: CurrencyCode;
    hideVatPicker?: boolean;

    disabled?: boolean;
    label?: React.ReactNode | Translatable;
    labelDescription?: Translatable;
    errorMessage?: React.ReactNode | Translatable;
    /** Switches input to be excl. vat instead of incl. vat */
    inputExclVat?: boolean;
    styling?: {
        container?: Interpolation<AppTheme>;
        inputContainer?: Interpolation<AppTheme>;
        input?: Interpolation<AppTheme>;
    };
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PriceEdit_V2 = (props: Props) => {
    const theme = useTheme();
    const inputElement = useRef<HTMLInputElement>(null);
    const onChange = useRef(props.onChange);
    onChange.current = props.onChange;

    const focusOnInput = (event: React.MouseEvent<Element, MouseEvent>) => {
        event.stopPropagation();
        if (!inputElement.current) return;
        if (props.disabled) return;
        inputElement.current.focus();
    };
    const [valueHasBeenChanged, setValueHasBeenChanged] = useState(false);
    const [touched, setTouched] = useState(false);
    const [value, setValue] = useCachedState<number | undefined>(
        props.initialPrice?.amountIncludingVat
            ? props.initialPrice?.amountIncludingVat / 100
            : undefined,
    );
    const [vat, setVat] = useState<Percentage>(props.vat);

    useEffect(() => {
        browserLogger.debug("PriceEdit_V2: useEffect: value", value);
        if (typeof value === "undefined") {
            void onChange.current(undefined);
            return;
        }

        // - Else notify parent of new price
        const price = props.inputExclVat
            ? priceForHumansFactory({
                  amountExcludingVat: value,
                  vat: vat,
                  currencyCode: props.currencyCode ?? "SEK",
              })
            : priceForHumansFactory({
                  amountIncludingVat: value,
                  vat: vat,
                  currencyCode: props.currencyCode ?? "SEK",
              });
        browserLogger.debug("PriceEdit_V2: useEffect: value", value);
        void onChange.current(price);
    }, [value, vat, props.currencyCode, props.inputExclVat]);

    // When user edits the price
    // update the local state
    const onValueChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const incoming = event.target.value;
            const newValue = incoming?.replace(/^(?!-)\D/g, "").trim() ?? "";
            const newNumber = Math.round(Number(newValue));
            if (newNumber === value) return;

            setValueHasBeenChanged(true);
            setValue(!newValue || isNaN(newNumber) ? undefined : newNumber);
        },
        [value, setValue],
    );

    const onFocus = useRef(props.onFocus);
    onFocus.current = props.onFocus;
    const _onFocus = useCallback(
        (event: React.FocusEvent<HTMLInputElement, Element>) => {
            setTouched(true);
            if (!onFocus.current) return;
            onFocus.current(event);
        },
        [],
    );

    const inputError = touched && valueHasBeenChanged && value === undefined;
    const required =
        typeof props.required === "boolean"
            ? props.required
            : props.required === "required";

    const inputContainerRef = useRef<HTMLDivElement>(null);
    const [size] = useElementSize({ targetRef: inputContainerRef });

    return (
        <LayoutGrid
            css={[
                {
                    gridTemplateColumns: props.hideVatPicker
                        ? "1fr"
                        : "1fr auto",
                    gridTemplateRows: props.hideVatPicker
                        ? props.label
                            ? "auto 1fr"
                            : "1fr"
                        : "auto 1fr",
                    gap: 8,
                    rowGap: 5,
                    opacity: props.disabled ? 0.5 : 1,
                    filter: props.disabled ? "grayscale(100%)" : undefined,
                    willChange: "filter, opacity",
                    transition: "all 0.3s ease-in-out",
                },
                props.styling?.container,
            ]}>
            {/* Price label */}
            {props.label ? (
                <LayoutCell>
                    {isTranslatable(props.label) ? (
                        <>
                            <FlexRow mainAxis="space-between">
                                <FieldTitle
                                    text={props.label}
                                    required={required}
                                />
                                <Conditional when={!!props.labelDescription}>
                                    <HorizontalDivider />
                                    <FieldTitle
                                        text={props.labelDescription ?? ""}
                                        css={{
                                            textAlign: "right",
                                        }}
                                        weight="400"
                                        color={theme.colors.default.text.withAlpha(
                                            0.5,
                                        )}
                                    />
                                </Conditional>
                            </FlexRow>
                            <VerticalDivider XXS />
                        </>
                    ) : (
                        props.label
                    )}
                </LayoutCell>
            ) : null}
            {!props.label && !props.hideVatPicker ? <LayoutCell /> : null}

            {/* Vat label */}
            {!props.hideVatPicker ? (
                <LayoutCell>
                    <FieldTitle
                        required={required}
                        text={translation({ sv: "Moms", en: "VAT" })}
                    />
                </LayoutCell>
            ) : null}

            {/* Price input */}
            <LayoutCell
                css={{
                    overflow: "hidden",
                    display: "flex",
                    width: "100%",
                    alignItems: "stretch",
                    justifyContent: "stretch",
                }}>
                <FlexRow
                    ref={inputContainerRef}
                    css={[
                        {
                            minWidth: 100,
                            flex: 1,
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderColor:
                                props.errorMessage || inputError
                                    ? theme.colors.danger.asString
                                    : theme.colors.textInput.border.asString,
                            borderRadius: 5,
                            backgroundColor: props.errorMessage
                                ? theme.colors.danger.light.asString
                                : theme.colors.textInput.background.asString,
                            transition: "all 0.3s ease-in-out",
                            overflow: "hidden",
                        },
                        props.styling?.inputContainer,
                    ]}
                    onClick={focusOnInput}
                    mainAxis="space-between"
                    crossAxis="stretch">
                    <input
                        type="number"
                        ref={inputElement}
                        disabled={props.disabled}
                        css={[
                            {
                                minWidth: 80,
                                width: "100%",
                                padding: 8,
                                paddingLeft: 10,
                                paddingRight: 10,
                                flex: 1,
                                fontSize: "1rem",
                                fontWeight: 700,
                                borderStyle: "none",
                                textAlign: "right",
                                color: props.errorMessage
                                    ? theme.colors.danger.asString
                                    : undefined,
                                backgroundColor:
                                    theme.colors.transparent.asString,
                                transition: "all 0.3s ease-in-out",
                                "::placeholder": {
                                    color: props.errorMessage
                                        ? theme.colors.danger.main.withAlpha(
                                              0.7,
                                          ).asString
                                        : theme.colors.textInput.placeholder
                                              .asString,
                                },
                            },
                            props.styling?.input,
                        ]}
                        value={value}
                        step={1}
                        onChange={onValueChange}
                        onBlur={props.onBlur}
                        onFocus={_onFocus}
                    />
                    <FlexRow
                        crossAxis="center"
                        css={
                            size && size.width < 120
                                ? {
                                      display: "none",
                                  }
                                : undefined
                        }>
                        <TextBox
                            text={translation({
                                sv: "kr",
                                en: "SEK",
                            })}
                            onClick={focusOnInput}
                            color={
                                props.errorMessage
                                    ? theme.colors.danger.main.withAlpha(0.7)
                                          .asString
                                    : theme.colors.default.placeholder
                            }
                        />
                        <HorizontalDivider S />
                    </FlexRow>
                </FlexRow>
            </LayoutCell>

            {/* Vat picker */}
            {!props.hideVatPicker ? (
                <LayoutCell css={{ minWidth: 90 }}>
                    <VatPicker
                        selectedVat={vat}
                        onChange={newVat => {
                            setVat(newVat as Percentage);
                        }}
                    />
                </LayoutCell>
            ) : null}
            {props.errorMessage ? (
                <LayoutCell css={{ gridColumn: "1 / -1" }}>
                    {isTranslatable(props.errorMessage) ? (
                        <>
                            <VerticalDivider XXS />
                            <TextBox
                                text={props.errorMessage}
                                color={theme.colors.danger}
                                padding={{ left: 2 }}
                            />
                        </>
                    ) : (
                        props.errorMessage
                    )}
                </LayoutCell>
            ) : null}
        </LayoutGrid>
    );
};

const PriceEdit_V2_WithLegacyPadding = (props: Props) => {
    return <PriceEdit_V2 {...props} styling={{ input: { padding: 16 } }} />;
};

export { PriceEdit_V2, PriceEdit_V2_WithLegacyPadding };
