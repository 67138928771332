import { IsoAndUnixTimestamp, asDate, isoAndUnixFactory } from "@towni/common";
import { isThisYear } from "date-fns";
import { Except } from "type-fest";
import { useDateTimeFormatter } from "../use-date-time-formatter";
import { TextBox, TextBoxProps } from "./text-box";

const useDateTimeFormatterSelector = (format: Format) => {
    const dateFormatter = useDateTimeFormatter();
    switch (format) {
        case "MEDIUM":
            return dateFormatter.formatDateAndTime;
        case "LONG":
            return dateFormatter.formatDateAndTimeLong;
        default:
            throw new Error(
                "Unhandled date format type; " + JSON.stringify(format),
            );
    }
};

type Format = "MEDIUM" | "LONG";
type Props = Except<TextBoxProps, "text" | "children"> & {
    dateTime: IsoAndUnixTimestamp | Date | undefined;
    /**
     * _Exempel_ \
     * MEDIUM: `2021-03-11 08:54` \
     * LONG: `11:e mars 08:58` eller `11:e mars 08:58 2020` \
     * _defaults to type `LONG`
     */
    format?: Format;
    /**
     * _defaults to `false`
     */
    forceIncludeYear?: boolean;
};

const DateTimeTextBox = (props: Props) => {
    const { dateTime, format, forceIncludeYear, ...textBoxProps } = props;
    const formatter = useDateTimeFormatterSelector(format ?? "LONG");
    const includeYear =
        !!forceIncludeYear || (dateTime && !isThisYear(asDate(dateTime)));
    const formattedDateTime = dateTime
        ? formatter(isoAndUnixFactory(dateTime), {
              includeYear,
          })
        : undefined;
    return <TextBox {...textBoxProps} text={formattedDateTime} />;
};

export { DateTimeTextBox };
