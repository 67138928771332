import { useLanguageContext } from "@@/settings/language-context";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import {
    getDayOfWeekAsNumber,
    getNameOfMonth,
    isoAndUnixFactory,
    type IsoAndUnixTimestamp,
    type MonthNumber,
    repeatIndex,
    repeatIndexFromAndTo,
    weekdayNames,
} from "@towni/common";
import { addDays, lastDayOfMonth } from "date-fns";
import { useMemo } from "react";
import { DateCell } from "./date-cell";

// Helper to make jsx more readable
const Month_FlexColumn = FlexColumn;

const DateSelectionVt_Month = (props: {
    year: number;
    month: MonthNumber;
    options?: {
        hideWeekdays?: boolean;
        /** If true, nothing can be selected */
        readonly?: boolean;
        /** Dates before this date will be marked as passed */
        cutoffDate?: IsoAndUnixTimestamp;
    };
    /** defaults to "Europe/Stockholm" */
    timeZone?: string;
}) => {
    const theme = useTheme();
    const { language } = useLanguageContext();
    const timeZone = props.timeZone ?? "Europe/Stockholm";
    const firstDay = useMemo(
        () => new Date(props.year, props.month, 1),
        [props.year, props.month],
    );
    const lastDay = useMemo(() => lastDayOfMonth(firstDay), [firstDay]);
    const daysOfMonth = useMemo(() => {
        return repeatIndex(lastDay.getDate()).map(index => {
            const day = addDays(firstDay, index);
            const dayIsoAndUnix = isoAndUnixFactory(day);
            const dayOfMonthIndex = index + 1;
            const dayOfWeekIndex = getDayOfWeekAsNumber(isoAndUnixFactory(day));
            return {
                dayOfMonthIndex,
                dayOfWeekIndex,
                day,
                dayIsoAndUnix,
            };
        });
    }, [firstDay, lastDay]);

    // Get index of weekday of first day of month
    const firstDayWeekdayIndex = getDayOfWeekAsNumber(
        isoAndUnixFactory(firstDay),
        timeZone,
    );

    const numberOfDateCellsForMonth = daysOfMonth.length + firstDayWeekdayIndex;

    return (
        <Month_FlexColumn
            fillParentWidth
            css={{ paddingLeft: 20, paddingRight: 20 }}>
            <FlexRow padding={{ bottom: 3, left: 5 }}>
                <TextBox
                    color={
                        theme.isLightTheme
                            ? theme.colors.black.light15
                            : theme.colors.black.light75
                    }
                    weight="800"
                    text={getNameOfMonth(
                        isoAndUnixFactory(firstDay),
                        "long",
                        language,
                        timeZone,
                    )}
                />
                <HorizontalDivider XS />
                <TextBox
                    weight="400"
                    color={
                        theme.isLightTheme
                            ? theme.colors.black.light15
                            : theme.colors.black.light75
                    }
                    text={firstDay.getFullYear().toString()}
                />
            </FlexRow>
            <VerticalDivider XXS />
            <LayoutGrid
                css={{
                    gridTemplateColumns: "repeat(7, 1fr)",
                }}>
                {/* Show weekday names as header row for month, if not requested to be hidden */}
                {props.options?.hideWeekdays
                    ? null
                    : repeatIndex(7).map(index => {
                          return (
                              <LayoutCell
                                  key={`weekday_name_${props.year}_${props.month}_${index}`}
                                  css={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginTop: 5,
                                      paddingTop: 5,
                                      paddingBottom: 5,
                                      marginBottom: 10,
                                      borderTop: `1px solid ${
                                          theme.isLightTheme
                                              ? theme.colors.black.light95
                                                    .asString
                                              : theme.colors.black.light30
                                                    .asString
                                      }
                                        `,
                                      borderBottom: `1px solid ${
                                          theme.isLightTheme
                                              ? theme.colors.black.light95
                                                    .asString
                                              : theme.colors.black.light30
                                                    .asString
                                      }`,
                                  }}>
                                  <TextBox
                                      text={weekdayNames.short[index]}
                                      align="center"
                                      case="UPPERCASE"
                                      size={0.5625}
                                      color={theme.colors.black.light50}
                                  />
                              </LayoutCell>
                          );
                      })}

                {/* Show days of month */}
                {/* How many weekdays before the first day of the month? */}
                {/* Get index of weekday of first day of month */}
                {repeatIndexFromAndTo(0, numberOfDateCellsForMonth - 1).map(
                    cellIndex => {
                        const isEmpty = cellIndex < firstDayWeekdayIndex;
                        if (isEmpty)
                            return <LayoutCell key={`empty_${cellIndex}`} />;
                        const {
                            dayIsoAndUnix,
                            dayOfMonthIndex,
                            dayOfWeekIndex,
                        } = daysOfMonth[cellIndex - firstDayWeekdayIndex];
                        return (
                            <LayoutCell
                                key={dayIsoAndUnix.iso}
                                css={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                }}>
                                <DateCell
                                    date={dayIsoAndUnix}
                                    dayOfMonthIndex={dayOfMonthIndex}
                                    dayOfWeekIndex={dayOfWeekIndex}
                                />
                            </LayoutCell>
                        );
                    },
                )}
            </LayoutGrid>
        </Month_FlexColumn>
    );
};

export { DateSelectionVt_Month };
