import { ButtonTransparent } from "@@/shared/buttons_v2/button-gray";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { FloatingBox, FloatingBoxActions } from "@@/shared/floating-box";
import { _FormFieldDescription } from "@@/shared/form/_form-field-description";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { useUpdateEffect } from "@@/shared/use-update-effect";
import { customThinScrollbarCss } from "@@/styles/themes/custom-thin-scrollbars";
import { useToast } from "@@/toasts/context/toast-context";
import { css, useTheme } from "@emotion/react";
import { faClock, faCopy } from "@fortawesome/pro-regular-svg-icons";
import {
    Hour,
    HourMinute,
    hourMinuteFactory,
    hours,
    isIsoAndUnix,
    Minute,
    minutes,
    Translatable,
    translation,
} from "@towni/common";
import copy from "copy-to-clipboard";
import { Draft } from "immer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "react-day-picker/dist/style.css";
import { ZodSchema } from "zod";
import { useFormId } from "./form-id.context";
import { useFormField } from "./use-form-field";
import { useFormFieldValidation } from "./use-form-field-validation";

const TimePickerContainer_FlexRow = FlexRow;
const TimePicker_FlexColumn = FlexColumn;
const HourPicker_FlexColumn = FlexColumn;
const MinutePicker_FlexColumn = FlexColumn;
const ActionRows_FlexRow = FlexRow;

type Value = HourMinute | undefined;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (draft: Draft<Partial<State>>, newValue: Value) => void;
    readonly fieldSchema?: ZodSchema;

    readonly description?: Translatable;
    readonly disabled?: boolean;

    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly required?: boolean;
    readonly hideDescriptionAfterInput?: boolean;
    /** Defaults to 1. When 1 pickers shows every minute, when 5 every 5 minutes is shown in picker and so on. */
    readonly minuteOptionStep?: 1 | 5 | 10 | 15 | 20;
    /**
     * When `true` as soon as a date, hour or minute is picked the form value is set.
     * When `false`, the user has to click the "Select/Ready" button to update the form value. \
     * Defaults to `false`
     */
    readonly selectDirect?: boolean;
};
const Form2TimePicker = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    const theme = useTheme();
    const toast = useToast();

    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        fieldSchema: props.fieldSchema,
        formId: props.formId,
    });
    if (!field)
        throw new Error(`Field ${props.fieldId} in form ${formId} not found`);

    const hasErrors = field.errors.length > 0;
    const validationTrigger = useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "manual",
    });

    /** Store the initial value of calculated or provided hour and minute */
    const initialHourMinuteRef = useRef(
        (() => {
            if (!field.value) return undefined;
            if (field.value instanceof Date)
                return hourMinuteFactory({ date: field.value });
            if (isIsoAndUnix(field.value))
                return hourMinuteFactory({ date: field.value });
            return field.value;
        })(),
    );
    /** Keeps track of the current selected hour and minute */
    const [hourMinute, _setHourMinute] = useState<HourMinute | undefined>(
        initialHourMinuteRef.current,
    );
    /**
     * Keeps track of the current selected hour and minute in a ref, to allow access to current
     * value without triggering a re-render in some effects
     */
    const hourMinuteRef = useRef<HourMinute | undefined>(hourMinute);

    /**
     * A function to set the current hour and minute.
     * Sets both the state and the ref value.
     */
    const setHourMinute = useCallback((hourMinute: HourMinute | undefined) => {
        _setHourMinute(hourMinute);
        hourMinuteRef.current = hourMinute;
    }, []);

    /** Keeps track of the current selected hour */
    const [selectedHour, setSelectedHour] = useState<Hour | undefined>(
        hourMinute?.hour,
    );
    /** Keeps track of the current selected minute */
    const [selectedMinute, setSelectedMinute] = useState<Minute | undefined>(
        hourMinute?.minute,
    );

    /**
     * Runs to check if a new minute and hour has been updated
     * and if so, updates the hourMinute state and ref.
     * And if `selectDirect` is true, updates the form value.
     */
    useUpdateEffect(() => {
        const currentHourMinute = hourMinuteRef.current;
        if (
            typeof selectedHour === "undefined" ||
            typeof selectedMinute === "undefined"
        ) {
            if (typeof currentHourMinute === "undefined") return;
            setHourMinute(undefined);
            if (props.selectDirect) field.setValue(undefined);
            return;
        }

        const newValue = hourMinuteFactory({
            hour: selectedHour,
            minute: selectedMinute,
        });
        if (currentHourMinute?.formatted === newValue.formatted) return;

        setHourMinute(newValue);
        if (props.selectDirect) field.setValue(newValue);
        field.setDirty(true);
        field.setTouched(true);
        validationTrigger();
    }, [
        field.setValue,
        field.setDirty,
        field.setTouched,
        selectedHour,
        selectedMinute,
        validationTrigger,
    ]);

    /** Keep track of a ref to the hour and minute scroll containers */
    const hourListRef = useRef<HTMLDivElement | null>(null);
    const minuteListRef = useRef<HTMLDivElement | null>(null);
    /** Keep track of if the hour and minute lists have been auto-scrolled */
    const hourListHasBeenScrolled = useRef(false);
    const minuteListHasBeenScrolled = useRef(false);

    /**
     * Scroll the selected hour into view when the component mounts
     * and the hour list have not been scrolled yet.
     */
    const scrollHourIntoView = useCallback(
        (hour: Hour, scrollBehavior: "instant" | "smooth" = "instant") => {
            if (!hourListRef.current) return;
            const hourElement = document.getElementById(
                `hour_${hour}_${field.fieldId}`,
            );
            hourElement?.scrollIntoView({
                behavior: scrollBehavior,
                block: "center",
                inline: "nearest",
            });
            hourListHasBeenScrolled.current = true;
        },
        [field.fieldId],
    );

    /**
     * Scroll the selected minute into view when the component mounts
     * and the minute list have not been scrolled yet.
     */
    const scrollMinuteIntoView = useCallback(
        (minute: Minute, scrollBehavior: "instant" | "smooth" = "instant") => {
            if (!minuteListRef.current) return;
            const minuteElement = document.getElementById(
                `minute_${minute}_${field.fieldId}`,
            );
            minuteElement?.scrollIntoView({
                behavior: scrollBehavior,
                block: "center",
                inline: "nearest",
            });
            minuteListHasBeenScrolled.current = true;
        },
        [field.fieldId],
    );

    /**
     * When react renders the component it triggers this ref callback
     * and we use the ref to the element to scroll the selected hour
     * into view
     */
    const hourRefCallback = useCallback(
        (node: HTMLDivElement | null) => {
            const prev = hourListRef.current;
            hourListRef.current = node;
            if (!node) return;
            if (!selectedHour) return;
            if (prev) return;
            scrollHourIntoView(selectedHour);
        },
        [scrollHourIntoView, selectedHour],
    );
    /**
     * When react renders the component it triggers this ref callback
     * and we use the ref to the element to scroll the selected minute
     * into view
     */
    const minuteRefCallback = useCallback(
        (node: HTMLDivElement | null) => {
            const prev = minuteListRef.current;
            minuteListRef.current = node;
            if (!node) return;
            if (!selectedMinute) return;
            if (prev) return;
            scrollMinuteIntoView(selectedMinute, "instant");
        },
        [scrollMinuteIntoView, selectedMinute],
    );

    /**
     * When the component mounts, start listening for paste events
     * and if the paste event is in the time picker field,
     * parse the pasted text and set the selected hour and minute
     * if the text is a valid time string.
     */
    const componentKey = `${formId}_${props.fieldId}`;
    useEffect(() => {
        // Handle paste from clipboard
        const onPaste = (ev: Event) => {
            if (document.activeElement?.id !== componentKey) return;
            const pastedText = (ev as ClipboardEvent).clipboardData?.getData(
                "Text",
            );
            if (!pastedText?.trim()) return;
            const isTimeString = new RegExp(/(\d{1,2}):(\d{1,2})/g).test(
                pastedText,
            );
            if (!isTimeString) return;
            const [hour, minute] = pastedText.split(":").map(Number);
            if (
                !hours.includes(hour as Hour) ||
                !minutes.includes(minute as Minute)
            )
                return;
            setSelectedHour(hour as Hour);
            setSelectedMinute(minute as Minute);
        };

        window.addEventListener("paste", onPaste);
        return () => {
            window.removeEventListener("paste", onPaste);
        };
    }, [componentKey]);

    const hourPickerCss = useMemo(
        () =>
            css([
                {
                    flex: 1,
                    maxWidth: 150,
                    minHeight: 150,
                    maxHeight: 200,
                    overflowX: "hidden",
                    overflowY: "auto",
                    borderRightStyle: "solid",
                    borderRightWidth: 1,
                    borderRightColor: theme.colors.default.border.asString,
                },
                customThinScrollbarCss,
            ]),
        [theme],
    );
    const minutePickerCss = useMemo(
        () =>
            css([
                {
                    flex: 1,
                    maxWidth: 150,
                    minHeight: 150,
                    maxHeight: 200,
                    overflowX: "hidden",
                    overflowY: "auto",
                },
                customThinScrollbarCss,
            ]),
        [],
    );

    const floatingBoxContent = useCallback(
        (actions: FloatingBoxActions) => (
            <TimePickerContainer_FlexRow
                key={"time-picker"}
                css={{
                    backgroundColor: "white",
                    borderRadius: 10,
                    padding: 5,
                    willChange: "filter",
                    transition: "opacity 0.2s ease-in-out",
                    filter: `drop-shadow(5px 5px 10px ${theme.colors.black.light75.asString})`,
                }}>
                {/* TIDSVÄLJARE */}
                <TimePicker_FlexColumn
                    css={{
                        minWidth: "max-content",
                    }}>
                    <FlexRow
                        mainAxis="space-evenly"
                        crossAxis="stretch"
                        css={{ flex: 1, position: "relative" }}>
                        <HourPicker_FlexColumn
                            ref={hourRefCallback}
                            css={hourPickerCss}>
                            {/* Timmar */}
                            <_TimePickerHeader
                                text={translation({
                                    sv: "Timme",
                                    en: "Hour",
                                })}
                            />
                            {hours.map(hour => {
                                const id = `hour_${hour}_${field.fieldId}`;
                                return (
                                    <ButtonTransparent
                                        key={id}
                                        id={id}
                                        padding={{
                                            topBottom: 5,
                                            right: 15,
                                            left: 15,
                                        }}
                                        onClick={() => {
                                            setSelectedHour(hour);
                                        }}>
                                        <TextBox
                                            text={hour
                                                .toString()
                                                .padStart(2, "0")}
                                            padding={{
                                                leftRight: 15,
                                                topBottom: 5,
                                            }}
                                            weight="700"
                                            css={{
                                                backgroundColor:
                                                    hour === selectedHour
                                                        ? theme.colors.primary
                                                              .light.asString
                                                        : undefined,
                                                border:
                                                    hour === selectedHour
                                                        ? `1px solid ${theme.colors.primary.border.withAlpha(0.5).asString}`
                                                        : `1px solid ${theme.colors.transparent.asString}`,

                                                borderRadius: 5,
                                                color:
                                                    hour === selectedHour
                                                        ? theme.colors.primary
                                                              .background
                                                              .asString
                                                        : undefined,
                                            }}
                                        />
                                    </ButtonTransparent>
                                );
                            })}
                        </HourPicker_FlexColumn>
                        <MinutePicker_FlexColumn
                            ref={minuteRefCallback}
                            css={minutePickerCss}>
                            {/* Minuter */}
                            <_TimePickerHeader
                                text={translation({
                                    sv: "Minut",
                                    en: "Minute",
                                })}
                            />
                            {minutes.map(minute => {
                                const id = `minute_${minute}_${field.fieldId}`;
                                return (
                                    <ButtonTransparent
                                        key={id}
                                        id={id}
                                        padding={{
                                            topBottom: 5,
                                            right: 15,
                                            left: 15,
                                        }}
                                        onClick={() => {
                                            setSelectedMinute(minute);
                                        }}>
                                        <TextBox
                                            text={minute
                                                .toString()
                                                .padStart(2, "0")}
                                            padding={{
                                                leftRight: 15,
                                                topBottom: 5,
                                            }}
                                            weight="700"
                                            css={{
                                                backgroundColor:
                                                    minute === selectedMinute
                                                        ? theme.colors.primary
                                                              .light.asString
                                                        : undefined,
                                                borderRadius: 5,
                                                border:
                                                    minute === selectedMinute
                                                        ? `1px solid ${theme.colors.primary.border.withAlpha(0.5).asString}`
                                                        : `1px solid ${theme.colors.transparent.asString}`,
                                                color:
                                                    minute === selectedMinute
                                                        ? theme.colors.primary
                                                              .background
                                                              .asString
                                                        : undefined,
                                            }}
                                        />
                                    </ButtonTransparent>
                                );
                            })}
                        </MinutePicker_FlexColumn>
                    </FlexRow>
                    <HorizontalLine />
                    <ActionRows_FlexRow
                        mainAxis="space-between"
                        fillParentWidth
                        padding={{ all: 10 }}>
                        <ButtonTransparent
                            onClick={() => {
                                setSelectedHour(new Date().getHours() as Hour);
                                setSelectedMinute(
                                    new Date().getMinutes() as Minute,
                                );
                            }}>
                            <TextBox
                                text={translation({
                                    sv: "Nu",
                                    en: "Now",
                                })}
                            />
                        </ButtonTransparent>
                        <HorizontalDivider />
                        <ButtonPrimary
                            onClick={() => {
                                field.setValue(hourMinuteRef.current);
                                actions.close();
                            }}>
                            <TextBox
                                text={translation({
                                    sv: "Klar",
                                    en: "Ready",
                                })}
                            />
                        </ButtonPrimary>
                    </ActionRows_FlexRow>
                </TimePicker_FlexColumn>
                {/* TIDSVÄLJARE */}
            </TimePickerContainer_FlexRow>
        ),
        [
            theme,
            hourRefCallback,
            hourPickerCss,
            minuteRefCallback,
            minutePickerCss,
            field,
            selectedHour,
            selectedMinute,
        ],
    );

    const floatingBoxTrigger = useCallback(
        (actions: FloatingBoxActions) => (
            <FlexRow
                crossAxis="center"
                onClick={actions.toggle}
                css={{
                    cursor: "pointer",
                    backgroundColor: theme.colors.textInput.background.asString,
                    borderRadius: theme.radius,
                    border: `1px solid ${theme.colors.textInput.border.asString}`,
                }}>
                <TextBox
                    text={
                        field.value
                            ? field.value.formatted
                            : translation({
                                  sv: "Välj en tid",
                                  en: "Pick a time",
                              })
                    }
                    css={{
                        flex: 1,
                        padding: 15,
                        userSelect: "text",
                    }}
                    color={
                        !field.value
                            ? theme.colors.textInput.placeholder.asString
                            : undefined
                    }
                />
                <FlexRow>
                    <Conditional when={!!hourMinute}>
                        <HorizontalDivider />
                        <Icon
                            icon={faCopy}
                            fixedWidth
                            onClick={ev => {
                                ev.stopPropagation();
                                copy(hourMinute?.formatted ?? "");
                                toast.info({
                                    message: translation({
                                        sv: "Tiden kopierad",
                                        en: "Time copied",
                                    }),
                                });
                            }}
                            opacity={props.disabled ? 0.5 : 1}
                        />
                    </Conditional>
                    <HorizontalDivider />
                    <Icon
                        icon={faClock}
                        css={{ paddingRight: 10 }}
                        fixedWidth
                        opacity={props.disabled ? 0.5 : 1}
                    />
                </FlexRow>
            </FlexRow>
        ),
        [theme, hourMinute, props.disabled, toast, field.value],
    );

    return (
        <FlexColumn
            id={componentKey}
            key={componentKey}
            tabIndex={0}
            tag={`form-field_${field.fieldId}`}
            crossAxis="stretch">
            <Conditional when={!!props.label}>
                <FlexRow shrink={0} crossAxis="center" mainAxis="space-between">
                    <FieldTitle
                        htmlFor={field.fieldId}
                        padding={{ left: 2 }}
                        text={props.label ?? ""} // already checked with conditional above
                        required={props.required}
                    />
                    <Conditional when={!!props.labelDescription}>
                        <HorizontalDivider XXS />
                        <FieldTitle
                            padding={{ left: 2 }}
                            text={props.labelDescription ?? ""} // already checked with conditional above
                            weight="700"
                            size="S"
                            css={{
                                opacity: 0.5,
                            }}
                        />
                    </Conditional>
                </FlexRow>
                <VerticalDivider XS />
            </Conditional>
            <FloatingBox
                content={floatingBoxContent}
                trigger={floatingBoxTrigger}
            />
            <_FormFieldDescription
                hasErrors={hasErrors}
                isDirty={field.dirty}
                description={props.description}
                hideDescriptionAfterInput={!!props.hideDescriptionAfterInput}
                css={{
                    marginTop: 5,
                }}
            />
            <FormErrorMessages errors={field.errors} />
        </FlexColumn>
    );
};

const _TimePickerHeader = (props: { text: Translatable }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme = useTheme();
    return (
        <TextBox
            text={props.text}
            weight="700"
            css={{
                backgroundColor: theme.colors.default.background.asString,
                padding: 5,
                paddingRight: 15,
                paddingLeft: 15,
                position: "sticky",
                borderBottom: `1px solid ${theme.colors.default.border.asString}`,
                top: 0,
                zIndex: 10,
            }}
        />
    );
};

export { Form2TimePicker };
