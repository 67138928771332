import { useAlfredItemsForSection } from "@@/providers/state/provider-fetchers";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { ErrorBoundary } from "@@/shared/error-boundary";
import { Join } from "@@/shared/join";
import { Padding } from "@@/shared/padding";
import { TextBox } from "@@/shared/text";
import {
    directionsMap,
    isBuyable,
    Product,
    ProductReference,
    sectionDisplayTypesMap,
    SectionId,
    SectionTheme,
    SortSettings,
    translation,
} from "@towni/common";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { SectionList, SectionSingle } from "./content";
import { SectionDisplayProps } from "./content/display/section-content-display";
import { SectionItem } from "./content/items/section-item";

type Props = {
    sectionItemReferences: ProductReference[];
    sectionTheme: SectionTheme;
    sortSettings: SortSettings;
    sectionId: SectionId;
};

const { SINGLE, LIST } = sectionDisplayTypesMap;
const ContentDisplay = (props: SectionDisplayProps) => {
    switch (props.sectionTheme.displayType) {
        case SINGLE:
            return <SectionSingle {...props} />;
        case LIST:
        default:
            return <SectionList {...props} />;
    }
};

const SectionContent = ({ sectionTheme, ...props }: Props) => {
    const referrerUrl = window.location.pathname + window.location.search;

    const navigate = useNavigate();
    const maxItems = sectionTheme.maxItems;
    const [products, _queryData] = useAlfredItemsForSection(props.sectionId);

    const sortedProductIds = props.sortSettings.sortOrder ?? [];
    const sortedSectionItems = sortedProductIds
        .map(productId => products.find(product => product._id === productId))
        .filter(Boolean) as Product[];

    const sectionItems = [
        ...sortedSectionItems,
        ...products.filter(product => !sortedProductIds.includes(product._id)),
    ]
        .filter(product => product.status.type === "ACTIVE")
        .map(product => (
            <SectionItem
                key={product._id}
                item={product}
                onClick={() => {
                    if (isBuyable(product))
                        navigate(
                            `/products/${product._id}?ref=${encodeURIComponent(
                                referrerUrl,
                            )}&refPID=${product.providerId}`,
                        );
                    else
                        navigate(
                            `/bookables/${product._id}?ref=${encodeURIComponent(
                                referrerUrl,
                            )}&refPID=${product.providerId}`,
                        );
                }}
                sectionTheme={sectionTheme}
            />
        ));
    const activeProducts = sectionItems.filter(Boolean);
    const itemsToShow = !maxItems
        ? activeProducts
        : activeProducts.slice(0, maxItems);
    const hasMoreItems = maxItems && activeProducts.length > maxItems;
    const direction = sectionTheme.direction;
    const { HZ, VT } = directionsMap;
    const items = (
        <Join
            direction={direction}
            // eslint-disable-next-line react/display-name
            start={_direction => {
                if (sectionTheme.wrap)
                    return <React.Fragment key="startdivider" />;
                switch (_direction) {
                    case HZ:
                        return <HorizontalDivider S key="startdivider" />;
                    case VT:
                    default:
                        return <VerticalDivider M key="startdivider" />;
                }
            }}
            end={_direction => {
                if (sectionTheme.wrap)
                    return <React.Fragment key="enddivider" />;
                switch (_direction) {
                    case HZ:
                        return <HorizontalDivider S key="enddivider" />;
                    case VT:
                    default:
                        return <VerticalDivider M key="enddivider" />;
                }
            }}
            // eslint-disable-next-line react/display-name
            modify={(item, _isFirst, _isLast) => {
                if (sectionTheme.wrap) return item;
                return direction === VT ? (
                    <Padding leftRight={20}>{item}</Padding>
                ) : (
                    <Padding leftRight={0}>{item}</Padding>
                );
            }}
            divider={sectionTheme.wrap ? <></> : undefined}>
            {itemsToShow}
        </Join>
    );

    return (
        <ErrorBoundary>
            <ContentDisplay
                sectionId={props.sectionId}
                sectionTheme={sectionTheme}>
                {items}
                {hasMoreItems && (
                    <React.Fragment key={"showmore"}>
                        <VerticalDivider />
                        <TextBox
                            text={translation({
                                sv: "visa mer",
                                en: "show more",
                            })}
                        />
                        <VerticalDivider S />
                    </React.Fragment>
                )}
            </ContentDisplay>
        </ErrorBoundary>
    );
};

export { SectionContent };
export type { Props as SectionContentProps };
