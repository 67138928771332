import { InfoBox_FlexColumn } from "@@/backoffice/shared/info-box.flex-column";
import { useProviderFromContext } from "@@/providers/state/contexts/use-provider-from-context";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useTheme } from "@emotion/react";
import { faCcMastercard, faCcVisa } from "@fortawesome/free-brands-svg-icons";
import {
    faCreditCard,
    faExclamationCircle,
} from "@fortawesome/pro-regular-svg-icons";
import {
    isApiError,
    OrderGroupId,
    OrderId,
    Translatable,
    translation,
} from "@towni/common";
import React, { useState } from "react";
import { useChargeTokenizedCardWithStripeMutation } from "./use-charge-tokenized-card-with-stripe-mutation";
import { useTokenizedCardFromOrder } from "./use-tokenized-card-from-order";

type Props = {
    readonly orderId: OrderId;
    readonly spin?: boolean;
    readonly disabled?: boolean;
    readonly orderGroupId: OrderGroupId;
    readonly amountToPayInclVat: number;
    readonly onSuccess: () => void;
};

const OnPremiseTokenizedCardPaymentButton = (props: Props) => {
    const theme = useTheme();
    const provider = useProviderFromContext();
    const [error, setError] = useState<Translatable>();
    // translation({ sv: "Allt har gått fel", en: "Something went wrong" }),

    const tokenizedCard = useTokenizedCardFromOrder(props.orderId);
    const chargeTokenizedCard = useChargeTokenizedCardWithStripeMutation({
        onSuccess: () => {
            props.onSuccess();
        },
        onError: (error, _command) => {
            if (isApiError(error)) {
                setError(error.reason);
                return;
            }
            setError(
                translation({
                    sv: "Ett okänt fel uppstod; " + error.message,
                    en: "An unknown error occurred; " + error.message,
                }),
            );
        },
    });
    const onInitiateTokenizedCardPayment = (_event: React.MouseEvent) => {
        if (!tokenizedCard) return;
        if (props.disabled) return;
        if (props.spin) return;
        chargeTokenizedCard.mutate({
            amountToPayInclVat: props.amountToPayInclVat,
            orderId: props.orderId,
            orderGroupId: props.orderGroupId,
            tokenizedCardData: tokenizedCard,
            providerId: provider._id,
        });
    };
    if (!tokenizedCard) return null;
    const spin = props.spin || chargeTokenizedCard.isPending;
    const disabled = props.disabled || !tokenizedCard;

    return (
        <FlexColumn fillParentWidth>
            <ButtonWhite
                renderAs="div"
                spin={spin}
                disabled={disabled}
                css={{
                    flex: 1,
                    width: "100%",
                    padding: 20,
                    backgroundColor: theme.colors.default.background.asString,
                    borderColor: theme.isDarkTheme
                        ? theme.colors.black.light50.asString
                        : theme.colors.black.light80.asString,
                }}
                contentContainerCss={{
                    flex: 1,
                    minWidth: "100%",
                }}
                textColor={
                    theme.isLightTheme
                        ? theme.colors.black.light10
                        : theme.colors.black.light90
                }
                onClick={onInitiateTokenizedCardPayment}>
                <FlexColumn fillParentWidth>
                    <FlexRow
                        css={{ flex: 1 }}
                        mainAxis="space-between"
                        crossAxis="center">
                        <FlexRow
                            css={{ flex: 1 }}
                            mainAxis="flex-start"
                            crossAxis="center">
                            <Icon
                                icon={
                                    tokenizedCard.cardType === "visa"
                                        ? faCcVisa
                                        : tokenizedCard.cardType ===
                                            "mastercard"
                                          ? faCcMastercard
                                          : faCreditCard
                                }
                                fixedWidth
                            />
                            <HorizontalDivider S />
                            <TextBox
                                text={{
                                    sv: `Dra pengar från ${tokenizedCard.cardType}-kort`,
                                    en: `Charge ${tokenizedCard.cardType}-card`,
                                }}
                            />
                        </FlexRow>
                        <HorizontalDivider S />
                        {/* {props.label} */}
                    </FlexRow>
                    <Conditional when={!!error}>
                        <VerticalDivider M />
                        <InfoBox_FlexColumn
                            css={{
                                color: theme.isLightTheme
                                    ? theme.colors.danger.asString
                                    : theme.colors.danger.text.asString,
                                backgroundColor: theme.isLightTheme
                                    ? theme.colors.danger.light.asString
                                    : theme.colors.danger.main.value.withAlpha(
                                          0.5,
                                      ).asString,
                            }}>
                            <FlexRow
                                crossAxis="flex-start"
                                css={{ cursor: "default" }}>
                                <Icon
                                    icon={faExclamationCircle}
                                    fixedWidth
                                    css={{
                                        display: "inline",
                                        // color: theme.colors.danger.asString,
                                        marginTop: 1,
                                        marginRight: 5,
                                    }}
                                />
                                <TextBox text={error} userSelect="text" />
                            </FlexRow>
                        </InfoBox_FlexColumn>
                    </Conditional>
                </FlexColumn>
            </ButtonWhite>
        </FlexColumn>
    );
};

export { OnPremiseTokenizedCardPaymentButton };
