import { browserLogger } from "@@/settings";
import { FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { useTheme } from "@emotion/react";
import { isVisibleHz, sortBy } from "@towni/common";
import { motion } from "framer-motion";
import debounce from "lodash.debounce";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { useContainerNavigationContext } from "../container-navigation.context";
import { AppPageId } from "../page-navigation/page-navigation-context";
import { AnchorId } from "./anchor-id";
import { ContainerNavigationBarItem } from "./container-navigation-bar-item";
import { useScrollPositionXForElement } from "./use-scroll-position-x-for-element";

type Props = {
    pageId: AppPageId;
};

const NavigationBarContainer = (props: Props) => {
    const theme = useTheme();
    const {
        anchors,
        anchorOrder,
        containerElement,
        activeAnchorId,
        setActiveAnchorId,
        setNavigateToAnchorId,
        setNavBarElement,
        navBarElement,
        containerScrollPositionY,
    } = useContainerNavigationContext();

    const contentContainer = containerElement;
    const navBarContainerRef = useCallback(
        (navBarElement: HTMLDivElement) => {
            setNavBarElement(navBarElement);
        },
        [setNavBarElement],
    );

    const scrollPositionX = useScrollPositionXForElement(navBarElement ?? null);

    useEffect(() => {
        // When page content with anchors scroll
        // Check and scroll nav bar to correspond to
        // current visible anchor on page
        const positionY = containerScrollPositionY;
        if (positionY <= 0) {
            const newActiveAnchorId = anchorOrder[0];
            if (activeAnchorId === newActiveAnchorId) return;
            setActiveAnchorId(newActiveAnchorId);
            return;
        }
        const scrollHeight = containerElement?.scrollHeight;
        const clientHeight = containerElement?.clientHeight;
        if (
            scrollHeight &&
            clientHeight &&
            positionY >= scrollHeight - clientHeight
        ) {
            const newActiveAnchorId = anchorOrder[anchorOrder.length - 1];
            if (activeAnchorId === newActiveAnchorId) return;
            setActiveAnchorId(newActiveAnchorId);
            return;
        }
        const anchorContainerPositions = anchorOrder.map(anchorId => {
            const anchor = anchors.get(anchorId);
            if (!anchor) throw Error(`anchor with id ${anchorId} is missing`);
            return [
                anchorId,
                anchor.containerItemElement?.offsetTop ?? 0,
            ] as const;
        });

        anchorContainerPositions.sort(sortBy(([_, position]) => position));
        const containerPositionsReversed = [
            ...anchorContainerPositions,
        ].reverse();
        const closestAnchorIdAndPosition =
            containerPositionsReversed.find(
                ([_, position]) => position <= positionY + 150, // calc offset (100)
            ) || anchorContainerPositions[0];

        if (closestAnchorIdAndPosition) {
            // scroll to closest anchors position by setting
            // active anchor id to closesAnchorId
            const [closestAnchorId] = closestAnchorIdAndPosition;
            const closestAnchor = anchors.get(closestAnchorId);
            if (!closestAnchor) {
                browserLogger.error("Anchor not found");
                return;
            }

            const positionX = closestAnchor?.navBarItemElement?.offsetLeft;
            if (positionX) {
                // Scroll to position X in navbar
                // scrollPositionX.set(positionX - 10 < 0 ? 0 : positionX - 10);
                if (activeAnchorId === closestAnchorId) return;
                setActiveAnchorId(closestAnchorId);
            }
        }
    }, [
        containerElement,
        navBarElement,
        anchors,
        anchorOrder,
        containerScrollPositionY,
        activeAnchorId,
        setActiveAnchorId,
    ]);

    React.useLayoutEffect(() => {
        // Find active anchor and check if it's visible
        // If it's not, scroll a bit so it's completely visible
        const _activeAnchorId = activeAnchorId ?? anchorOrder[0];
        if (!_activeAnchorId) return;
        const activeAnchor = anchors.get(_activeAnchorId);
        if (!activeAnchor) return;

        if (
            typeof activeAnchor.navBarItemElement?.offsetLeft !== "undefined" &&
            navBarElement
        ) {
            const navBarItem = activeAnchor.navBarItemElement;
            if (!isVisibleHz(navBarItem, navBarElement)) {
                requestAnimationFrame(() => {
                    scrollPositionX.set(navBarItem.offsetLeft - 10);
                });
            }
        }
    }, [activeAnchorId, anchorOrder, anchors, navBarElement, scrollPositionX]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleScroll = useCallback(
        debounce(() => {
            scrollPositionX.stop();
            if (contentContainer?.offsetLeft) {
                requestAnimationFrame(() => {
                    scrollPositionX.set(contentContainer?.offsetLeft);
                });
            }
        }, 100),
        [scrollPositionX, contentContainer],
    );
    const _setNavigateToAnchorId = useCallback(
        (anchorId: AnchorId) => () => {
            setNavigateToAnchorId(anchorId);
        },
        [setNavigateToAnchorId],
    );

    return (
        <motion.div
            css={{
                overflowY: "hidden",
                overflowX: "scroll",
                width: "100%",
                boxShadow: `0px 0px 15px 0px ${
                    theme.colors.black.value.withAlpha(0.3).asString
                }`,
                padding: 10,
                transform: "translate3d(0, 0, 0)",
                WebkitOverflowScrolling: "touch",
            }}
            ref={navBarContainerRef}
            onWheel={handleScroll}
            onMouseDown={handleScroll}
            onTouchMove={handleScroll}
            onTouchStart={handleScroll}>
            <FlexRow crossAxis="center" css={{ flexShrink: 0 }}>
                <ForEach itemOf={anchorOrder} getKey={anchorId => anchorId}>
                    {anchorId => {
                        const anchor = anchors.get(anchorId);
                        if (!anchor) return null;
                        return (
                            <ContainerNavigationBarItem
                                key={anchorId}
                                pageId={props.pageId}
                                anchorId={anchorId}
                                onClick={_setNavigateToAnchorId(anchorId)}
                            />
                        );
                    }}
                </ForEach>
            </FlexRow>
        </motion.div>
    );
};

export { NavigationBarContainer };
