import { DateRangePicker } from "@@/shared/form/standalones/date-range-picker";
import { DateTimePicker } from "@@/shared/form/standalones/date-time-picker";
import { PhoneNumberInput } from "@@/shared/form/standalones/phone-input";
import { ResourcePickerSingle } from "@@/shared/form/standalones/resource-picker-single";
import { Select } from "@@/shared/form/standalones/select";
import { TextInput } from "@@/shared/form/standalones/text-input";
import { Checkbox } from "./checkbox";
import { DatePicker } from "./date-picker";
import { GiftCardPriceSettingsInput } from "./gift-card-price-settings-input";
import { NumberInput } from "./number-input";

const Inputs = {
    DateRangePicker,
    DateTimePicker,
    DatePicker,
    TextInput,
    ResourcePickerSingle,
    PhoneNumberInput,
    Select,
    NumberInput,
    GiftCardPriceSettingsInput,
    Checkbox,
};

export { Inputs };
