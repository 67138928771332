import {
    TanstackQueryOptions,
    useTanstackSingleQuery,
} from "@@/shared/use-tanstack-query";
import { HomeSettingGlobal } from "@towni/common";

const useHomeSettingQuery = (
    options?: TanstackQueryOptions<HomeSettingGlobal>,
) =>
    useTanstackSingleQuery<HomeSettingGlobal>(
        "/settings/home",
        ["home-setting"],
        options,
    );

export { useHomeSettingQuery };
