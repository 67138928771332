import { ErrorPage } from "@@/backoffice/shared/error-page";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { ErrorFilterBoundary } from "./shared/error-filter-boundary";
import { ErrorHandlerBoundary } from "./shared/error-handler-boundary";

type Props = {
    children?: React.ReactNode;
};
const MainErrorBoundaries = (props: Props) => {
    const children = props.children;
    const searchParams = new URLSearchParams(location.search);

    return (
        <Sentry.ErrorBoundary
            beforeCapture={scope => {
                scope.setTag("errorBoundary", "MainRoutes");
                scope.setTag("fatal", true);
            }}
            fallback={errorInfo => {
                return (
                    <ErrorPage
                        error={errorInfo.error}
                        reset={errorInfo.resetError.bind(errorInfo)}
                    />
                );
            }}>
            <ErrorFilterBoundary
                errorsToIgnore={[
                    {
                        name: [
                            "React ErrorBoundary Error",
                            "React ErrorBoundary TypeError",
                            "TypeError",
                        ],
                        message: "Load failed",
                    },
                    {
                        name: [
                            "React ErrorBoundary Error",
                            "React ErrorBoundary TypeError",
                            "TypeError",
                        ],
                        message: "Failed to fetch",
                    },
                ]}>
                <ErrorHandlerBoundary
                    whenMessageIncludes={[
                        "Dynamic import of back office failed",
                        // "Failed to fetch dynamically imported module",
                        // "assets/backoffice.routes",
                    ]}
                    onCatch={error => {
                        // Try to reload, but only once
                        // don't get stuck in a loop
                        const params = Array.from(searchParams.entries())
                            .map(([key, value]) => {
                                return `${key}=${value}`;
                            })
                            .join("&");
                        if (!params.includes("rl=true")) {
                            const url =
                                window.location.origin +
                                window.location.pathname;
                            window.location.href = `${url}?${params}&rl=true`;
                            return;
                        }
                        // If we couldn't solve it with a reload
                        // throw the error
                        throw error;
                    }}>
                    {children}
                </ErrorHandlerBoundary>
            </ErrorFilterBoundary>
        </Sentry.ErrorBoundary>
    );
};

export { MainErrorBoundaries };
