import { getBlockContentTargetUrl } from "@@/backoffice/for-providers/pages/blocks/content-blocks/shared/grid-views/shared/get-block-content-target-url";
import { inactiveCssWithoutHeightSetting } from "@@/backoffice/for-providers/pages/blocks/content-blocks/shared/grid-views/shared/inactive-css";
import { usePageViewContext } from "@@/backoffice/for-providers/pages/editor/page-view-context";
import { FlexColumn } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { HzScrollContainer } from "@@/shared/hz-scroll-container/hz-scroll-container";
import { LayoutGrid } from "@@/shared/layout-grid";
import { Link } from "@@/shared/link";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { useTheme } from "@emotion/react";
import { BlockContent, ContentAutoPageBlock, ProviderId } from "@towni/common";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";

type Props = {
    readonly block: ContentAutoPageBlock;
    readonly content: BlockContent[];
    readonly width?: React.CSSProperties["width"];
    readonly className?: string;
};

const ContentSquaresWithoutTextView = (props: Props) => {
    const { block } = props;

    switch (block.layout) {
        case "hzScroll":
            return <ContentSquaresWithoutTextsHzScroll {...props} />;
        default:
            return <ContentSquaresWithoutTextGrid {...props} />;
    }
};

const getNumberOfUnusedCells = (element: HTMLDivElement | null): number => {
    const gridContainer = element;
    if (!gridContainer) return 0;
    const gridItems = Array.from(element.children);
    if (!gridItems?.length) return 0;

    const computedStyles = window.getComputedStyle(gridContainer);
    const gridColumns = computedStyles
        .getPropertyValue("grid-template-columns")
        .split(" ");
    const gridRowCount = Math.ceil(gridItems.length / gridColumns.length);
    const lastRowIndex = gridRowCount - 1;
    const filledCellsInLastRow =
        gridItems.length - gridColumns.length * lastRowIndex;
    const unusedCellsOnLastRow = gridColumns.length - filledCellsInLastRow;

    return unusedCellsOnLastRow;
};

const ContentSquaresWithoutTextGrid = (props: Props) => {
    const { width, block, content } = props;
    const isSmallScreen = usePageBreakpoint({ when: "SMALL_SCREEN" });
    const {
        // pageInnerWidth,
        pageMaxWidth,
        // defaultGridGap,
        // defaultTextShadow,
        providerId,
    } = usePageViewContext();
    const [unusedCellCount, setUnusedCellCount] = useState(0);
    const [gridElement, setGridElement] = useState<HTMLDivElement | null>(null);
    const gridRefCallback = useCallback((element: HTMLDivElement | null) => {
        setGridElement(element);
    }, []);

    useLayoutEffect(() => {
        const numColumns = getNumberOfUnusedCells(gridElement);
        setUnusedCellCount(numColumns);
    }, [gridElement]);
    const squareMinSize = getSquareSize(block.preferredContentSize);

    const _content = useMemo(
        () => [
            ...content,
            ...Array.from({ length: unusedCellCount }).map(
                () => "NONE" as const,
            ),
        ],
        [content, unusedCellCount],
    );

    return (
        <LayoutGrid
            ref={gridRefCallback}
            css={{
                gridTemplateColumns: `repeat(auto-fill, minmax(${squareMinSize}px, 1fr))`,
                gap: 10,
                width: width ?? "100%",
                maxWidth: pageMaxWidth * 0.8,
                padding: isSmallScreen ? 20 : 40,
                paddingTop: 10,
                paddingBottom: 30,
            }}>
            <ForEach
                itemOf={_content}
                getKey={(item, index) =>
                    item === "NONE" ? `index_${index}` : item._id
                }>
                {item => (
                    <ContentSquareWithoutText
                        block={block}
                        content={item === "NONE" ? undefined : item}
                        providerId={providerId}
                        pillMaxWidth={pageMaxWidth * 0.85}
                    />
                )}
            </ForEach>
        </LayoutGrid>
    );
};

const getSquareSize = (preferredContentSize: "S" | "M" | "L"): number => {
    if (preferredContentSize === "L") return 320;
    if (preferredContentSize === "M") return 220;
    return 150;
};

const ContentSquaresWithoutTextsHzScroll = (props: Props) => {
    const { block, content } = props;
    const { defaultGridGap, providerId } = usePageViewContext();

    const size = getSquareSize(block.preferredContentSize);

    const items = useMemo(
        () =>
            content.map(item => (
                <div key={item._id} css={{ width: size, height: size }}>
                    <ContentSquareWithoutText
                        key={item._id}
                        block={block}
                        content={item}
                        providerId={providerId}
                    />
                </div>
            )),
        [content, size, block, providerId],
    );

    return (
        <HzScrollContainer
            gap={{
                startGap: defaultGridGap,
                dividingGap: defaultGridGap,
                endGap: defaultGridGap,
                bottomGap: defaultGridGap,
            }}
            items={items}
        />
    );
};

const ContentSquareWithoutText = (props: {
    block: ContentAutoPageBlock;
    content: BlockContent | undefined;
    providerId: ProviderId | undefined;
    pillMaxWidth?: number;
    className?: string;
}) => {
    const theme = useTheme();
    const { content: item, providerId } = props;

    return (
        <div
            css={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                paddingBottom: "100%",
            }}>
            <div
                css={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: "flex",
                    alignItems: "stretch",
                    justifyContent: "stretch",
                }}>
                <Link
                    to={item ? getBlockContentTargetUrl(item, providerId) : ""}
                    title={item?.title}
                    disabled={!item || item.inactive}
                    css={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "stretch",
                        alignItems: "stretch",
                        cursor: "pointer",
                        width: props.pillMaxWidth ? "fit-content" : undefined,
                    }}>
                    <FlexColumn
                        key={item?._id ?? "empty"}
                        crossAxis="center"
                        className={props.className}
                        css={[
                            {
                                flex: 1,
                                backgroundColor:
                                    theme.colors.primary.light.asString,
                                color: theme.colors.primary.asString,
                            },
                            ...(item?.inactive
                                ? [inactiveCssWithoutHeightSetting]
                                : []),
                        ]}
                        background={
                            item
                                ? {
                                      color: theme.colors.primary.light,
                                      imageReference: item.poster,
                                      borderRadius: 0,
                                      fit: "COVER",
                                  }
                                : null
                        }></FlexColumn>
                </Link>
            </div>
        </div>
    );
};

export { ContentSquaresWithoutTextView };
