import { AppTheme } from "@@/styles/theme";
import { Interpolation } from "@emotion/react";
import { isValidElement } from "react";
import { Except } from "type-fest";
import { Conditional } from "./conditional";
import { TextShimmer, TextShimmerProps } from "./pictures/shimmers";
import { CircleSpinner, CircleSpinnerProps } from "./spinners/circle-spinner";

type Props1<T> = {
    readonly spinWhen: T;
    readonly children?: React.ReactNode;
    readonly spin?: boolean;
    readonly spinner?: React.ReactNode;
    readonly options?: {
        defaultSpinnerProps: CircleSpinnerProps;
    };
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ConditionalSpin = <T extends unknown = unknown>(props: Props1<T>) => {
    const spinner = props.spinner || (
        <CircleSpinner {...(props.options?.defaultSpinnerProps || {})} />
    );
    return (
        <Conditional
            when={!props.spin && !props.spinWhen}
            else={() => <>{spinner}</>}>
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            {props.children}
        </Conditional>
    );
};
type Props<T> = {
    readonly showWhen: T;
    readonly children?: (item: NonNullable<T>) => React.ReactNode;
    readonly spin?: boolean;
    readonly spinner?: React.ReactNode;
    readonly options?: {
        defaultSpinnerProps: CircleSpinnerProps;
    };
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ShowOrSpin = <T extends unknown = unknown>(props: Props<T>) => {
    const spinner = props.spinner || (
        <CircleSpinner {...(props.options?.defaultSpinnerProps || {})} />
    );
    return (
        <Conditional
            when={!props.spin && !!props.showWhen}
            else={() => <>{spinner}</>}>
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            {isValidElement(props.children)
                ? props.children
                : // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                  props.children?.(props.showWhen!)}
        </Conditional>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const ShowOrTextShimmer = <T extends unknown = unknown>(
    props: Except<Props<T>, "spinner"> & {
        shimmerSettings?: TextShimmerProps;
        shimmerCss?: Interpolation<AppTheme>;
    },
) => {
    return (
        <ShowOrSpin
            {...props}
            spinner={
                <TextShimmer
                    {...(props.shimmerSettings || { rows: 3 })}
                    css={props.shimmerCss}
                />
            }
        />
    );
};

export { ShowOrSpin, ShowOrTextShimmer };
export { ConditionalSpin };
