import { useAudio } from "@@/audio/use-audio";
import { browserLogger } from "@@/settings/browser-logger";
import { useDebugEnabled } from "@@/settings/debug/debug-toggle";
import { apiFetchClient } from "@@/shared/fetch-client";
import { useIsMountedRef } from "@@/shared/use-is-mounted-ref";
import { useToast, useToastContext } from "@@/toasts/context/toast-context";
import { toastIdFromOrderIdFactory } from "@@/toasts/context/toast-id";
import * as Sentry from "@sentry/react";
import {
    GetResponse,
    MINUTES,
    Order,
    OrderGroupId,
    OrderId,
    OrderStatusType,
    OrderType,
    OrdersFulfillCommand,
    ProviderId,
    UnixTimestamp,
    UserId,
    emptyArrayOf,
    isApiError,
    isProviderId,
    svTranslator,
    translation,
} from "@towni/common";
import confetti from "canvas-confetti";
import { addMinutes, isBefore } from "date-fns";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { MergeExclusive } from "type-fest";
import { useOrderSocketEventsForMe } from "./use-orders-events-for-me";

const defaultStaleTime = 2 * MINUTES;
const defaultCacheTime = 30 * MINUTES;

type QueryId = string;
type State = {
    orders: Order[];
    orderMap: Map<OrderId, Order>;
    status: "FETCHING" | "IDLE";
    fetching: Set<QueryId>;
    errors: Map<QueryId, unknown>;
    queries: Map<
        QueryId,
        {
            stale: Date;
            cache: Date;
        }
    >;
    verbose: boolean;
    hasFetched: boolean;
};

const createInitState = (verbose = false): State => {
    return {
        orderMap: new Map(),
        orders: emptyArrayOf<Order>(),
        status: "IDLE",
        fetching: new Set(),
        queries: new Map(),
        errors: new Map(),
        hasFetched: false,
        verbose,
    };
};
type FetchOrderRequest = { type: "FETCH" } & MergeExclusive<
    { by: "ORDER_ID"; orderId: OrderId | OrderId[] },
    MergeExclusive<
        { by: "ORDER_GROUP_ID"; orderGroupId: OrderGroupId },
        { by: "ORDER_STATUS_TYPE"; orderStatusType: OrderStatusType }
    >
>;
type Action =
    | FetchOrderRequest
    | { type: "CLEAR" }
    | { type: "FETCH_START"; queryId: QueryId }
    | {
          type: "FETCH_END";
          queryId: QueryId;
          error?: unknown;
          orders?: Order | Order[];
      };

type Dispatch = (action: Action) => void;

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "FETCH_START": {
            const fetching = new Set(state.fetching);
            fetching.add(action.queryId);
            return { ...state, status: "FETCHING", fetching };
        }
        case "FETCH_END": {
            const newErrors = new Map(state.errors);
            let hasErrorChange = false;
            if (action.error) {
                hasErrorChange = true;
                newErrors.set(action.queryId, action.error);
            }
            if (!action.error && newErrors.has(action.queryId)) {
                hasErrorChange = true;
                newErrors.delete(action.queryId);
            }

            let updatedOrderState: State | undefined;
            if (action.orders) {
                const orders = Array.isArray(action.orders)
                    ? action.orders
                    : [action.orders];
                updatedOrderState = orders.reduce((_state, _order) => {
                    const exists = _state.orderMap.has(_order._id);
                    const newOrderMap = new Map(_state.orderMap);
                    newOrderMap.set(_order._id, _order);
                    return {
                        ..._state,
                        orderMap: newOrderMap,
                        orders: exists
                            ? _state.orders.map(order =>
                                  order._id === _order._id ? _order : order,
                              )
                            : [..._state.orders, _order],
                    };
                }, state);
            }

            const _state = updatedOrderState ?? state;
            const queries = new Map(_state.queries);
            queries.set(action.queryId, {
                stale: addMinutes(new Date(), defaultStaleTime),

                cache: addMinutes(new Date(), defaultCacheTime),
            });

            const fetching = new Set(_state.fetching);
            fetching.delete(action.queryId);
            const newState: State = {
                ..._state,
                status: fetching.size ? "FETCHING" : "IDLE",
                fetching,
                errors: hasErrorChange ? newErrors : state.errors,
                queries: new Map(_state.queries),
                hasFetched: true,
            };
            return newState;
        }
        case "CLEAR": {
            return createInitState(state.verbose);
        }
        default:
            return state;
    }
};

type Props = {
    readonly options?: {
        readonly verbose?: boolean;
    };
    readonly children?: React.ReactNode;
};

type FetchOrderOptions = {
    includeFilter?: OrderStatusType[];
    excludeFilter?: OrderStatusType[];
    from?: UnixTimestamp;
    to?: UnixTimestamp;
    type?: OrderType;
};
const fetchOptionsToQuery = (options?: FetchOrderOptions) => {
    if (!options) return "";
    const queryValues: string[] = [];
    if (options.includeFilter)
        queryValues.push(
            `include=${encodeURIComponent(options.includeFilter.join(","))}`,
        );
    if (options.excludeFilter)
        queryValues.push(
            `exclude=${encodeURIComponent(options.excludeFilter.join(","))}`,
        );
    if (options.from)
        queryValues.push(`from=${encodeURIComponent(options.from)}`);
    if (options.to) queryValues.push(`to=${encodeURIComponent(options.to)}`);
    if (options.type)
        queryValues.push(`type=${encodeURIComponent(options.type)}`);
    const query = queryValues.join("&");
    return query ? "?" + query : query;
};

const fetchForProvider = async (
    params: {
        providerId: ProviderId;
    } & FetchOrderOptions,
): Promise<Order[]> => {
    const query = fetchOptionsToQuery(params);
    const { items: orders } = await apiFetchClient.get<GetResponse<Order>>({
        route: `/orders/for-provider/${params.providerId}${query}`,
    });
    return orders;
};
const fetchForUser = async (
    params: {
        userId: UserId;
    } & FetchOrderOptions,
): Promise<Order[]> => {
    const query = fetchOptionsToQuery(params);
    const { items: orders } = await apiFetchClient.get<GetResponse<Order>>({
        route: `/orders/for-user/${params.userId}${query}`,
    });
    return orders;
};
const fetchById = async (params: { orderId: OrderId }): Promise<Order[]> => {
    const { items: orders } = await apiFetchClient.get<GetResponse<Order>>({
        route: `/orders/${params.orderId}`,
    });
    return orders;
};
const fetchForOrderGroup = async (params: {
    orderGroupId: OrderGroupId;
}): Promise<Order[]> => {
    const { items: orders } = await apiFetchClient.get<GetResponse<Order>>({
        route: `/orders/for-order-group/${params.orderGroupId}`,
    });
    return orders;
};

// DISPATCH STUFF
type OrderFetcher<Params> = (
    queryId: QueryId,
    params: Params,
    options?: {
        force?: boolean;
    },
) => Promise<void>;
type DispatchActions = {
    dispatch: Dispatch;
    fetchOrdersForProvider: OrderFetcher<
        { providerId: ProviderId } & FetchOrderOptions
    >;
    fetchOrdersForUser: OrderFetcher<{ userId: UserId } & FetchOrderOptions>;
    fetchOrderById: OrderFetcher<{ orderId: OrderId }>;
    fetchOrdersForOrderGroupId: OrderFetcher<
        { orderGroupId: OrderGroupId } & FetchOrderOptions
    >;
};

const OrderContext = React.createContext<State | undefined>(undefined);
const OrderDispatchContext = React.createContext<DispatchActions | undefined>(
    undefined,
);

const OrderProvider = (props: Props) => {
    const [audio] = useAudio();
    const [debugEnabled] = useDebugEnabled();
    const hideToast = useToastContext(context => context.hide);
    const toast = useToast();
    const [state, dispatch] = React.useReducer(reducer, createInitState());
    const navigate = useNavigate();

    // Dispatch
    // TODO: SWITCH TO REACT-QUERY IF POSSIBLE
    // TODO: USING OUR OWN QUERY STUFF TO HAVE BETTER CONTROL
    // TODO: OVER WHAT ORDERS TO REFRESH WHEN, BY ID
    const orderFetcher = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
        async <Params extends unknown>(
            queryId: string,
            params: Params,
            fetcher: (params: Params) => Promise<Order[]>,
            options?: {
                force?: boolean;
            },
        ) => {
            const _queryId = queryId;
            try {
                if (!options?.force) {
                    const stale = state.queries.get(_queryId)?.stale;
                    const isStale = !stale || isBefore(stale, new Date());
                    if (!isStale) {
                        browserLogger.log("ignoring fetch, data not stale");
                        return;
                    }
                }
                if (state.fetching.has(_queryId)) {
                    browserLogger.log("ignoring fetch, already fetching");
                }
                dispatch({ type: "FETCH_START", queryId: _queryId });
                const orders = await fetcher(params);
                dispatch({ type: "FETCH_END", queryId: _queryId, orders });
            } catch (error) {
                dispatch({ type: "FETCH_END", queryId: _queryId, error });

                Sentry.withScope(scope => {
                    if (isApiError(error)) {
                        scope.setExtras({
                            from: window.location.href,
                            apiError: error,
                        });

                        scope.setTransactionName(`OrderFetcher error`.trim());

                        Sentry.captureMessage(
                            `WebClient: Api Fetch Error; ${svTranslator(
                                error.reason,
                            )}; ${svTranslator(error.explanation)}`.trim(),
                            "error",
                        );
                    } else {
                        scope.setExtras({
                            from: window.location.href,
                            error,
                        });
                        Sentry.captureException(error);
                    }
                });

                browserLogger.error(error);
            }
        },
        [state.queries, state.fetching],
    );

    const dispatchActions: DispatchActions = React.useMemo(
        () => ({
            dispatch,
            fetchOrderById: (queryId, params, options) =>
                orderFetcher(queryId, params, fetchById, options),
            fetchOrdersForOrderGroupId: (queryId, params, options) =>
                orderFetcher(queryId, params, fetchForOrderGroup, options),
            fetchOrdersForProvider: (queryId, params, options) =>
                orderFetcher(queryId, params, fetchForProvider, options),
            fetchOrdersForUser: (queryId, params, options) =>
                orderFetcher(queryId, params, fetchForUser, options),
        }),
        [dispatch, orderFetcher],
    );

    useOrderSocketEventsForMe({
        verbose: state.verbose,
        onOrderEvent: async (event, room) => {
            browserLogger.log(
                `🔌 socket order event; ${event.type}; room: ${room}; orderId: ${event.data.orderId}`,
                event,
                room,
            );
            try {
                await dispatchActions.fetchOrderById(event.data.orderId, {
                    orderId: event.data.orderId,
                });
            } catch (error) {
                Sentry.captureException(error);
                browserLogger.error(error);
                // TODO: NOTIFY USER
            }
            if (isProviderId(room)) {
                switch (event.type) {
                    case "order:pending_confirmation": {
                        const toastId = toastIdFromOrderIdFactory(
                            `${event.data.orderId as string}__`,
                        );
                        if (event.data.needsConfirmationByProvider) {
                            if (debugEnabled) {
                                void confetti({
                                    scalar: 2,
                                    spread: 180,
                                    particleCount: 100,
                                    origin: { y: -0.3 },
                                    startVelocity: -45,
                                    colors: [
                                        "#F4E72A",
                                        "#FFF55E",
                                        "#FFD700",
                                        "#FFA500",
                                    ],
                                })?.catch(browserLogger.error);
                            }
                            toast.warning({
                                _id: toastId,
                                message: "Ny order finns att bekräfta",
                                sticky: true,
                                onShow: () => {
                                    audio.playSound();
                                },
                                onClick: () => {
                                    navigate(
                                        `/bo/providers/${event.data.providerId}/orders/${event.data.orderId}`,
                                    );
                                    hideToast(toastId);
                                },
                            });
                        }
                        break;
                    }
                    case "order:confirmed": {
                        const toastId = toastIdFromOrderIdFactory(
                            `${event.data.orderId as string}__`,
                        );
                        hideToast(toastId);
                        break;
                    }
                    default:
                        break;
                }
            }
        },
    });

    return (
        <OrderContext.Provider value={state}>
            <OrderDispatchContext.Provider value={dispatchActions}>
                {props.children}
            </OrderDispatchContext.Provider>
        </OrderContext.Provider>
    );
};
const useOrderState = () => {
    const state = React.useContext(OrderContext);
    if (!state) throw new Error("hook must be used within a OrderContext");
    return state;
};
const useOrdersForProviderContext = (providerId: ProviderId | undefined) => {
    const state = useOrderState();
    const [empty] = React.useState(() => ({ orders: [], orderMap: new Map() }));
    const previous = React.useRef<{
        orders: Order[];
        orderMap: Map<OrderId, Order>;
    }>(empty);

    const orders = React.useMemo(() => {
        if (!providerId) return empty;
        const filtered = state.orders.filter(
            order => order.providerId === providerId,
        );
        const hasChanges =
            previous.current.orders.length !== filtered.length ||
            filtered.some(order => !previous.current.orders.includes(order));
        // browserLogger.log("USE ORD PRO", { hasChanges, filtered });
        if (hasChanges) {
            previous.current.orders = filtered;
            previous.current.orderMap = new Map(
                filtered.map(order => [order._id, order] as const),
            );
        }
        return previous.current;
    }, [empty, providerId, state.orders]);
    const output = React.useMemo(() => {
        return {
            ...state,
            ...orders,
        };
    }, [state, orders]);
    return output;
};
const useOrderDispatchContext = () => {
    const state = React.useContext(OrderContext);
    const dispatch = React.useContext(OrderDispatchContext);
    if (!state) throw new Error("hook must be used within a OrderContext");
    if (!dispatch)
        throw new Error("hook must be used within a OrderDispatchContext");
    return dispatch;
};

const useMarkPickupOrdersAsFulfilled = (params?: {
    disabledNotifications?: boolean;
}) => {
    const toast = useToast();
    const [isLoading, setIsLoading] = React.useState(false);
    const isMounted = useIsMountedRef();

    return [
        async (command: OrdersFulfillCommand) => {
            const _isMounted = isMounted;
            if (isLoading) return;
            setIsLoading(true);
            try {
                await apiFetchClient.post<OrdersFulfillCommand, null>({
                    route: `/commands/orders-fulfill`,
                    body: command,
                });
                if (!params?.disabledNotifications) {
                    toast.success({
                        message: translation({
                            sv: "Ordrarna är nu satta som klara",
                            en: "Orders has been set to fulfilled status",
                        }),
                    });
                }
            } catch (error) {
                if (!params?.disabledNotifications) {
                    toast.fromError(error);
                }
                browserLogger.error(error);
            } finally {
                if (_isMounted.current) {
                    setIsLoading(false);
                }
            }
        },
        isLoading,
    ] as const;
};

export {
    OrderContext,
    OrderProvider,
    useMarkPickupOrdersAsFulfilled,
    useOrderDispatchContext,
    useOrderState,
    useOrdersForProviderContext,
};
export type { FetchOrderOptions, QueryId };
