import { MutableRefObject, useEffect, useRef, useState } from "react";

const useScrollWidth = (): readonly [
    scrollWidthRef: MutableRefObject<HTMLDivElement | null>,
    scrollWidth: number,
] => {
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);
    const [scrollWidth, setScrollWidth] = useState(0);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;

        if (!scrollContainer) return;

        // Update the scroll width state
        const updateScrollWidth = () => {
            setScrollWidth(scrollContainer.scrollWidth);
        };

        // Set initial scroll width on load
        updateScrollWidth();

        // Use ResizeObserver to track changes in the container's size
        const resizeObserver = new ResizeObserver(updateScrollWidth);
        resizeObserver.observe(scrollContainer);

        // Clean up observer on component unmount
        return () => {
            if (scrollContainer) {
                resizeObserver.unobserve(scrollContainer);
            }
        };
    }, []);

    return [scrollContainerRef, scrollWidth] as const;
};

export { useScrollWidth };
