import { useMe } from "@@/authentication/me/me-context";
import { browserLogger } from "@@/settings";
import * as Sentry from "@sentry/react";
import { CardElement, useElements } from "@stripe/react-stripe-js";
import {
    InitiateStripePaymentCommandResponse,
    fullName,
    support,
} from "@towni/common";
import * as React from "react";
import { useStripePayment } from "../stripe-payment.context";
import { useStripeClient } from "../stripe.context";

const useStripeFormSubmit = (params: {
    readonly selectedCardExternalId: string | undefined;
    readonly saveCard: boolean;
    readonly onFinish: () => void | Promise<void>;
}) => {
    const [me] = useMe();
    const stripe = useStripeClient();
    const [errorMessage, setErrorMessage] = React.useState<
        string | undefined
    >();
    const elements = useElements();
    const { selectedCardExternalId, saveCard, onFinish } = params;
    const [stripePayment, stripePaymentActions] = useStripePayment();

    const handleSubmit = React.useCallback(
        async (
            event: React.FormEvent,
            overrides?: {
                intent: InitiateStripePaymentCommandResponse;
            },
        ) => {
            if (!stripe?.stripeClient)
                throw new Error(
                    `Kan inte verifiera betalningar för tillfället, försök igen om en stund eller kontakta ${support.towni.supportEmail}`,
                );
            // We don't want to let default form submission happen here,
            // which would refresh the page.
            event.preventDefault();
            // console.log("HANDLING SUBMIT", stripePayment);

            const intentResponse = overrides?.intent ?? stripePayment.intent;
            if (!me || !intentResponse || stripePayment.status === "submitting")
                return;

            const orderGroupId = intentResponse.orderGroupId;
            const intentSecret = intentResponse.intentSecret;
            if (!intentSecret || !elements) return;

            const card = elements.getElement(CardElement);
            if (!card && !selectedCardExternalId) return;

            try {
                stripePaymentActions.setStatus("submitting");
                const confirmation =
                    await stripe.stripeClient.confirmCardPayment(intentSecret, {
                        // Use saved card if selected, else use stripe
                        // card element details
                        payment_method: selectedCardExternalId || {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            card: card!, // must exist since selected card doesn't
                            metadata: {
                                userId: me._id,
                            },
                            billing_details: {
                                name: fullName(me),
                                email: me.email?.emailAddress, // todo! require verified? letting them input another one?
                                phone: me.phone?.phoneNumber,
                            },
                        },
                        // If save card is checked, request save for future
                        // on session usage
                        ...(saveCard
                            ? { setup_future_usage: "on_session" }
                            : {}),
                    });
                if (confirmation.error) {
                    // Show error to your customer (e.g., insufficient funds)
                    const errorMessage = confirmation.error.message;
                    if (!import.meta.env.VITEST) {
                        // Dont need to log error in jest
                        browserLogger.error(errorMessage);
                    }
                    setErrorMessage?.(errorMessage);
                    stripePaymentActions.setStatus("idle");
                    return;
                }

                if (
                    confirmation.paymentIntent.status === "succeeded" ||
                    (confirmation.paymentIntent.capture_method === "manual" &&
                        confirmation.paymentIntent.status ===
                            "requires_capture")
                ) {
                    setErrorMessage?.(undefined);
                    stripePaymentActions.setStatus("idle");

                    // PAYMENT FINISHED/SUCCEEDED
                    if (intentResponse.amountCapturable > 0) {
                        Sentry.captureMessage(
                            "payment completed but it's not paid in full!?",
                            scope => {
                                scope.setExtras({
                                    orderGroupId,
                                    amountStillCapturable:
                                        intentResponse.amountCapturable,
                                    commandId:
                                        intentResponse.responseToCommandId,
                                    delayed: intentResponse.delayed,
                                });
                                return scope;
                            },
                        );
                    }
                    stripePaymentActions.setStatus("idle");
                    await onFinish();
                }
            } catch (error) {
                // Show error to your customer (e.g., insufficient funds)
                if (error instanceof Error) {
                    const errorMessage = error.message;
                    browserLogger.error(errorMessage);
                    setErrorMessage?.(errorMessage);
                    stripePaymentActions.setStatus("idle");
                    return;
                }
                throw error;
            }
        },
        [
            stripePayment,
            me,
            stripePaymentActions,
            selectedCardExternalId,
            saveCard,
        ],
    );

    const output = React.useMemo(
        () => [handleSubmit, errorMessage] as const,
        [handleSubmit, errorMessage],
    );
    return output;
};

export { useStripeFormSubmit };
