import { useContainerNavigationContext } from "@@/pages/container-navigation.context";
import { Anchor } from "@@/pages/container-navigation/anchor";
import { BreadCrumbPathProvider } from "@@/shared/bread-crumb-id-context";
import { VerticalDivider } from "@@/shared/dividers";
import { ErrorBoundary } from "@@/shared/error-boundary";
import { FlexColumn } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { useObjectTranslator } from "@@/translations/use-object-translator";
import { useTheme } from "@emotion/react";
import type { Section as TSection } from "@towni/common";
import { BackgroundStyle, MetaContent } from "@towni/common";
import { useCallback } from "react";
import { SectionContent } from "./section-content";
import { useActiveSectionItemCount } from "./use-active-section-item-count";

type Props = {
    section: TSection;
    anchor?: Anchor;
};

const Section = ({ section, anchor }: Props) => {
    const theme = useTheme();
    const objectTranslator = useObjectTranslator();
    const [activeContentCount] = useActiveSectionItemCount(section);
    const setAnchorContainerItemElement = useContainerNavigationContext(
        context => context.setAnchorContainerItemElement,
    );
    const anchorRef = useCallback(
        (anchorElement: HTMLDivElement) => {
            if (!anchor || !anchorElement) return;
            setAnchorContainerItemElement(anchor._id)(anchorElement);
        },
        [anchor],
    );
    // Quickfix, ugly, to make it look like design in figma
    // Needs to be added to section, it's not supposed to be generic
    const defaultBackground: BackgroundStyle | null =
        section?.sectionTheme.itemDisplayType.startsWith("CARD")
            ? {
                  color: theme.colors.white.main.value.withLight(0.98),
              }
            : null;

    if (!section) return null;
    if (!section.items.length) return null;
    if (!activeContentCount) return null;

    const meta = objectTranslator<MetaContent>(section.meta);
    return (
        <ErrorBoundary>
            <BreadCrumbPathProvider node={section._id}>
                <FlexColumn
                    ref={anchorRef}
                    crossAxis="stretch"
                    background={meta.background ?? defaultBackground}>
                    <VerticalDivider L />
                    <TextBox
                        text={meta.title}
                        padding={{ leftRight: 20 }}
                        size="XL"
                        weight="700"
                        // styling={parseStyles(meta.title)}
                    />
                    {meta.subtitle && (
                        <>
                            <VerticalDivider XS />
                            <TextBox
                                text={meta.subtitle}
                                padding={{ leftRight: 20 }}
                                // styling={parseStyles(meta.subtitle)}
                            />
                        </>
                    )}
                    {meta.description && (
                        <>
                            <VerticalDivider XS />
                            <TextBox
                                text={meta.description}
                                padding={{ leftRight: 20 }}
                                // styling={parseStyles(meta.description)}
                            />
                        </>
                    )}
                    <VerticalDivider S />
                    <SectionContent
                        sectionId={section._id}
                        sectionItemReferences={section.items}
                        sectionTheme={section.sectionTheme}
                        sortSettings={section.sorting}
                    />
                    <VerticalDivider XS />
                </FlexColumn>
            </BreadCrumbPathProvider>
        </ErrorBoundary>
    );
};

export { Section };
