import { createContext, useContext, useEffect, useMemo, useRef } from "react";

type State = {
    readonly altKeyPressedRef: React.MutableRefObject<boolean>;
};

const ContextMenuContext = createContext<State | undefined>(undefined);

const ContextMenuContextProvider = (props: {
    readonly children: React.ReactNode;
}) => {
    const altKeyPressed = useRef(false);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            altKeyPressed.current = event.key === "Alt";
        };
        const handleKeyUp = (event: KeyboardEvent) => {
            if (event.key === "Alt") altKeyPressed.current = false;
        };
        const handleContextMenu = (_event: MouseEvent) => {
            if (altKeyPressed.current) {
                altKeyPressed.current = false;
                return;
            }
        };
        document.addEventListener("contextmenu", handleContextMenu);
        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("keyup", handleKeyUp);
        return () => {
            document.addEventListener("contextmenu", handleContextMenu);
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("keyup", handleKeyUp);
        };
    }, []);

    const state = useMemo(
        () => ({
            altKeyPressedRef: altKeyPressed,
        }),
        [],
    );

    return (
        <ContextMenuContext.Provider value={state}>
            {props.children}
        </ContextMenuContext.Provider>
    );
};

const useContextMenuContext = () => {
    const context = useContext(ContextMenuContext);
    if (context === undefined) {
        throw new Error(
            "useContextMenuContext must be used within a ContextMenuContextProvider",
        );
    }
    return context;
};

export { ContextMenuContextProvider, useContextMenuContext };
