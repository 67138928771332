import { AccommodationOrderItemBody } from "@@/carts/accommodation-checkout/accommodation-order-item-body";
import { AccommodationOrderItemHeader } from "@@/carts/accommodation-checkout/accommodation-order-item-header";
import { useProductLegacy } from "@@/products/state/queries/product/use-product";
import { FlexColumn } from "@@/shared/flex-containers";
import {
    OrderItemAccommodation_V2,
    Resource,
    type ColorItem,
    type ResourceReservation,
    type ResourceReservationRequest,
} from "@towni/common";

type Props = {
    orderItem: OrderItemAccommodation_V2;
    resourceReservations: (ResourceReservation | ResourceReservationRequest)[];
    resources: Resource[];
    backgroundColor?: ColorItem;
};

const Cart_AccommodationPanel = (props: Props) => {
    const { orderItem, backgroundColor } = props;
    const [product, _] = useProductLegacy(orderItem.productId);
    return (
        <FlexColumn>
            <AccommodationOrderItemHeader
                orderItem={orderItem}
                imageReference={product?.meta.images[0]}
            />

            <AccommodationOrderItemBody
                orderItem={orderItem}
                backgroundColor={backgroundColor}
                resourceReservations={props.resourceReservations}
                resources={props.resources}
            />
        </FlexColumn>
    );
};

export { Cart_AccommodationPanel };
