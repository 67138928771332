import { browserLogger } from "@@/settings";
import { apiFetchClient } from "@@/shared/fetch-client";
import { Elements } from "@stripe/react-stripe-js";
import {
    PaymentRequest,
    PaymentRequestPaymentMethodEvent,
} from "@stripe/stripe-js";
import {
    InitiateStripePaymentCommand,
    InitiateStripePaymentCommandResponse,
    InitiateStripeVirtualCardPaymentCommand,
    support,
} from "@towni/common";
import * as React from "react";
import { usePaymentDetailsContext } from "../payment-details.context";
import { useStripeClient } from "./stripe.context";
type State = {
    readonly status:
        | "submitting"
        | "idle"
        | "creating_intent"
        | "pr_success"
        | "pr_error";
    readonly error: string;
    readonly paymentRequest: PaymentRequest | null;
    readonly intent: InitiateStripePaymentCommandResponse | undefined;
};
type Dispatch = {
    readonly setStatus: (status: State["status"]) => void;
    readonly createPaymentIntent: (
        params:
            | InitiateStripePaymentCommand
            | InitiateStripeVirtualCardPaymentCommand,
        source: string,
    ) => Promise<InitiateStripePaymentCommandResponse>;
};

const StripePaymentContext = React.createContext<
    readonly [State, Dispatch] | undefined
>(undefined);

const StripePaymentProvider = (props: {
    readonly children?: React.ReactNode;
}) => {
    const stripe = useStripeClient();
    const [status, setStatus] = React.useState<State["status"]>("idle");
    const [error, setError] = React.useState<string>("");
    const paymentDetails = usePaymentDetailsContext(
        context => context.paymentDetails,
    );

    if (!paymentDetails)
        throw Error("stripe payment context must know the payment details");

    // PaymentRequest
    const [paymentRequest, setPaymentRequest] =
        React.useState<PaymentRequest | null>(null);

    React.useEffect(() => {
        if (!stripe.stripeClient) return;
        const pr = stripe.stripeClient.paymentRequest({
            country: "SE",
            currency: "sek",
            total: {
                label: paymentDetails.title ?? "Townibetalning",
                amount: paymentDetails.price.amountIncludingVat,
                pending: true,
            },
            requestPayerName: true,
            requestPayerEmail: true,
        });
        pr.canMakePayment()
            .then(result => {
                if (result) {
                    setPaymentRequest(pr);
                } else {
                    setError("something went wrong");
                }
            })
            .catch(error => {
                throw error;
            });
    }, [stripe?.stripeClient, stripe.stripeClientStatus]);

    React.useEffect(() => {
        if (!paymentDetails || !paymentRequest) return;
        paymentRequest.update({
            currency: "sek",
            total: {
                label: paymentDetails.title ?? "Townibetalning",
                amount: paymentDetails.price.amountIncludingVat,
                pending: true,
            },
        });
    }, [paymentDetails, paymentRequest]);

    const [intent, setIntent] = React.useState<
        InitiateStripePaymentCommandResponse | undefined
    >();
    React.useEffect(() => {
        if (!paymentRequest || !intent?.intentSecret) return;
        const handler = async (ev: PaymentRequestPaymentMethodEvent) => {
            // Confirm the PaymentIntent without handling potential next actions (yet).
            if (!stripe.stripeClient)
                throw new Error(
                    `Kan inte verifiera betalningar för tillfället, försök igen senare eller kontakta ${support.towni.supportEmail}`,
                );
            const { paymentIntent, error: confirmError } =
                await stripe.stripeClient.confirmCardPayment(
                    intent.intentSecret,
                    { payment_method: ev.paymentMethod.id },
                    { handleActions: false },
                );
            if (confirmError) {
                // Report to the browser that the payment failed, prompting it to
                // re-show the payment interface, or show an error message and close
                // the payment interface.
                setStatus("pr_error");
                setError(confirmError.message ?? confirmError.type);
                browserLogger.error(confirmError);
                ev.complete("fail");
            } else {
                // Report to the browser that the confirmation was successful, prompting
                // it to close the browser payment method collection interface.
                ev.complete("success");
                // Check if the PaymentIntent requires any actions and if so let Stripe.js
                // handle the flow. If using an API version older than "2019-02-11"
                // instead check for: `paymentIntent.status === "requires_source_action"`.
                if (paymentIntent?.status === "requires_action") {
                    // Let Stripe.js handle the rest of the payment flow.
                    const { error } =
                        await stripe.stripeClient.confirmCardPayment(
                            intent.intentSecret,
                        );
                    if (error) {
                        // The payment failed -- ask your customer for a new payment method.
                        setError(error.message ?? error.type);
                        browserLogger.error(confirmError);
                        setStatus("pr_error");
                    } else {
                        // The payment has succeeded.
                        setStatus("pr_success");
                    }
                } else {
                    // The payment has succeeded.
                    setStatus("pr_success");
                }
            }
        };
        paymentRequest.on("paymentmethod", handler);
        return () => {
            paymentRequest.off("paymentmethod", handler);
        };
    }, [intent, paymentRequest]);

    // PaymentIntent
    const createPaymentIntent = React.useCallback(
        async (
            params:
                | InitiateStripePaymentCommand
                | InitiateStripeVirtualCardPaymentCommand,
            _source: string,
        ) => {
            setStatus("creating_intent");
            // console.log("CREATE PAYMENT INTENT", source, params);
            const intentData = await apiFetchClient.post<
                | InitiateStripePaymentCommand
                | InitiateStripeVirtualCardPaymentCommand,
                InitiateStripePaymentCommandResponse
            >({
                route: `/payments/stripe/intents`,
                body: params,
            });

            // console.log("PAYMENT INTENT DATA", intentData);
            setIntent(intentData);

            setStatus(oldStatus => {
                return oldStatus === "creating_intent" ? "idle" : oldStatus;
            });

            return intentData;
        },
        [],
    );

    // Build state/dispatch
    const state: State = React.useMemo(
        () => ({
            status,
            intent,
            error,
            paymentRequest,
        }),
        [intent, status, paymentRequest, error],
    );
    const dispatch: Dispatch = React.useMemo(
        () => ({
            createPaymentIntent,
            setStatus,
        }),
        [createPaymentIntent],
    );
    const output = React.useMemo(
        () => [state, dispatch] as const,
        [state, dispatch],
    );

    return (
        <StripePaymentContext.Provider value={output}>
            <Elements stripe={stripe.stripeClient ?? null}>
                {props.children}
            </Elements>
        </StripePaymentContext.Provider>
    );
};

// Hooks
const useStripePayment = () => {
    const context = React.useContext(StripePaymentContext);
    if (!context)
        throw new Error(
            "useStripePayment must be used within a StripePaymentProvider",
        );

    return context;
};
const useStripePaymentState = () => {
    const context = React.useContext(StripePaymentContext);
    if (!context?.[0])
        throw new Error(
            "useStripePaymentState must be used within a StripePaymentProvider",
        );

    return context[0];
};
const useStripePaymentDispatch = () => {
    const context = React.useContext(StripePaymentContext);
    if (!context?.[1])
        throw new Error(
            "useStripePaymentDispatch must be used within a StripePaymentProvider",
        );

    return context[1];
};

export {
    StripePaymentProvider,
    useStripePayment,
    useStripePaymentDispatch,
    useStripePaymentState,
};
