import { paddingToCssValue } from "@@/shared/padding";
import { DOMRectJSON } from "@@/shared/use-size-tracker";
import { AppTheme } from "@@/styles/theme";
import { Interpolation, useTheme } from "@emotion/react";
import {
    ColorItem,
    ColorSet,
    Padding,
    RemSize,
    colorAsString,
    setWithinPercentageRange,
} from "@towni/common";
import confetti from "canvas-confetti";
import * as React from "react";
import { useCallback } from "react";
import { FlexRow } from "../flex-containers";
import { CircleSpinner } from "../spinners/circle-spinner";

const defaultButtonClickConfettiOptions = (
    x: number,
    y: number,
): confetti.Options => {
    const centerXPercent = x
        ? setWithinPercentageRange(x / window.innerWidth)
        : 0.5;
    const centerYPercent = y
        ? setWithinPercentageRange(y / window.innerHeight)
        : 0.5;
    return {
        particleCount: 10,
        shapes: ["star", "circle"],
        spread: 90,
        startVelocity: 10,
        ticks: 30,
        origin: {
            x: centerXPercent,
            y: centerYPercent,
        },
    };
};

type ButtonProps = {
    readonly id?: string;
    readonly children?: React.ReactNode;
    readonly confetti?: boolean | confetti.Options;
    readonly onClick?:
        | ((
              event: React.MouseEvent,
              elementBoundingBox: DOMRectJSON & {
                  centerX: number;
                  centerY: number;
              },
          ) => void)
        | undefined;
    readonly disabled?: boolean;
    readonly removeBorder?: boolean;
    /**
     * defaults to padding: `{topBottom: 10, leftRight: 15}`
     * @type {Padding}
     */
    readonly padding?: Padding;
    readonly className?: string;
    readonly spin?: boolean;
    readonly spinnerSize?: RemSize;
    readonly fillParentWidth?: boolean;
    readonly radius?: number;
    readonly dataTestId?: string;
    readonly contentContainerCss?: Interpolation<AppTheme>;
    readonly renderAs?: "div" | "a";
    readonly onMouseDown?: React.MouseEventHandler<HTMLDivElement> &
        React.MouseEventHandler<HTMLAnchorElement>;
    readonly onMouseEnter?: React.MouseEventHandler<HTMLDivElement> &
        React.MouseEventHandler<HTMLAnchorElement>;
    readonly onMouseUp?: React.MouseEventHandler<HTMLDivElement> &
        React.MouseEventHandler<HTMLAnchorElement>;
    // Drag events
    readonly onPointerDown?: React.PointerEventHandler<HTMLDivElement> &
        React.PointerEventHandler<HTMLAnchorElement>;
    readonly onDrag?: React.DragEventHandler<HTMLDivElement> &
        React.DragEventHandler<HTMLAnchorElement>;
    readonly onDragCapture?: React.DragEventHandler<HTMLDivElement> &
        React.DragEventHandler<HTMLAnchorElement>;
    readonly onDragEnd?: React.DragEventHandler<HTMLDivElement> &
        React.DragEventHandler<HTMLAnchorElement>;
    readonly onDragEndCapture?: React.DragEventHandler<HTMLDivElement> &
        React.DragEventHandler<HTMLAnchorElement>;
    readonly onDragEnter?: React.DragEventHandler<HTMLDivElement> &
        React.DragEventHandler<HTMLAnchorElement>;
    readonly onDragEnterCapture?: React.DragEventHandler<HTMLDivElement> &
        React.DragEventHandler<HTMLAnchorElement>;
    readonly onDragExit?: React.DragEventHandler<HTMLDivElement> &
        React.DragEventHandler<HTMLAnchorElement>;
    readonly onDragExitCapture?: React.DragEventHandler<HTMLDivElement> &
        React.DragEventHandler<HTMLAnchorElement>;
    readonly onDragLeave?: React.DragEventHandler<HTMLDivElement> &
        React.DragEventHandler<HTMLAnchorElement>;
    readonly onDragLeaveCapture?: React.DragEventHandler<HTMLDivElement> &
        React.DragEventHandler<HTMLAnchorElement>;
    readonly onDragOver?: React.DragEventHandler<HTMLDivElement> &
        React.DragEventHandler<HTMLAnchorElement>;
    readonly onDragOverCapture?: React.DragEventHandler<HTMLDivElement> &
        React.DragEventHandler<HTMLAnchorElement>;
    readonly onDragStart?: React.DragEventHandler<HTMLDivElement> &
        React.DragEventHandler<HTMLAnchorElement>;
    readonly onDragStartCapture?: React.DragEventHandler<HTMLDivElement> &
        React.DragEventHandler<HTMLAnchorElement>;
} & (
    | {
          /**
           * defaults to `theme.colors.primary`
           */
          readonly colorSet?: Pick<ColorSet, "border" | "main" | "text">;
          readonly disabledColorSet?: Pick<
              ColorSet,
              "border" | "main" | "text"
          >;
          readonly borderColor?: never;
          readonly textColor?: never;
          readonly backgroundColor?: never;
      }
    | {
          readonly colorSet?: never;
          readonly borderColor?: ColorItem;
          readonly textColor?: ColorItem;
          readonly backgroundColor?: ColorItem;
          readonly disabledColorSet?: Pick<
              ColorSet,
              "border" | "main" | "text"
          >;
      }
);
type ButtonColorPropNames = keyof Pick<
    ButtonProps,
    "backgroundColor" | "borderColor" | "textColor" | "colorSet"
>;

const Button = (props: ButtonProps) => {
    const theme = useTheme();
    const padding = paddingToCssValue(
        props.padding ?? { all: 10, leftRight: 15 },
    );

    const colors = React.useMemo(() => {
        const defaultColors = props.colorSet ?? theme.colors.primary;
        const backgroundColor = ((): ColorItem => {
            if (props.disabled) {
                return (
                    props.disabledColorSet?.main ??
                    theme.colors.disabled.background
                );
            }
            if (props.colorSet?.main) return props.colorSet.main;
            if (props.backgroundColor) return props.backgroundColor;
            return defaultColors.main;
        })();
        const borderColor = ((): ColorItem => {
            if (props.disabled) {
                return (
                    props.disabledColorSet?.border ??
                    theme.colors.disabled.border
                );
            }
            if (props.colorSet?.border) return props.colorSet.border;
            if (props.borderColor) return props.borderColor;
            if (backgroundColor) return backgroundColor;
            return defaultColors.border;
        })();
        const textColor = ((): ColorItem => {
            if (props.disabled) {
                return (
                    props.disabledColorSet?.text ?? theme.colors.disabled.text
                );
            }
            if (props.colorSet?.text) return props.colorSet.text;
            if (props.textColor) return props.textColor;
            return defaultColors.text;
        })();
        return {
            border: colorAsString(borderColor),
            main: colorAsString(backgroundColor),
            text: colorAsString(textColor),
        };
    }, [
        props.colorSet,
        props.disabled,
        props.backgroundColor,
        props.disabledColorSet?.main,
        props.disabledColorSet?.border,
        props.disabledColorSet?.text,
        props.borderColor,
        props.textColor,
        theme.colors.primary,
        theme.colors.disabled.background,
        theme.colors.disabled.border,
        theme.colors.disabled.text,
    ]);

    const disabled = props.disabled || false;
    const onClick = useCallback(
        (
            event: React.MouseEvent<
                typeof props.renderAs extends "div"
                    ? HTMLDivElement
                    : HTMLAnchorElement
            >,
        ) => {
            if (props.onClick) {
                event.preventDefault();
                event.stopPropagation();
                if (disabled || props.spin) return;
                if (props.confetti) {
                    void confetti(
                        typeof props.confetti !== "boolean"
                            ? props.confetti
                            : defaultButtonClickConfettiOptions(
                                  event.clientX,
                                  event.clientY,
                              ),
                    );
                }
                const boundingBox = event.currentTarget.getBoundingClientRect();
                props.onClick?.(event, {
                    height: boundingBox.height,
                    width: boundingBox.width,
                    top: boundingBox.top,
                    right: boundingBox.right,
                    bottom: boundingBox.bottom,
                    left: boundingBox.left,
                    x: boundingBox.x,
                    y: boundingBox.y,
                    centerX: boundingBox.left + boundingBox.width / 2,
                    centerY: boundingBox.top + boundingBox.height / 2,
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [disabled, props.spin, props.onClick],
    );

    const Element = props.renderAs ?? "a";

    return (
        <Element
            id={props.id}
            data-testid={props.dataTestId}
            onMouseDown={props.onMouseDown}
            onMouseEnter={props.onMouseEnter}
            onMouseUp={props.onMouseUp}
            onDrag={props.onDrag}
            onDragCapture={props.onDragCapture}
            onDragEnd={props.onDragEnd}
            onDragEndCapture={props.onDragEndCapture}
            onDragEnter={props.onDragEnter}
            onDragEnterCapture={props.onDragEnterCapture}
            onDragExit={props.onDragExit}
            onDragExitCapture={props.onDragExitCapture}
            onDragLeave={props.onDragLeave}
            onDragLeaveCapture={props.onDragLeaveCapture}
            onDragOver={props.onDragOver}
            onDragOverCapture={props.onDragOverCapture}
            onDragStart={props.onDragStart}
            onDragStartCapture={props.onDragStartCapture}
            onPointerDown={props.onPointerDown}
            css={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                borderRadius: props.radius ?? theme.radius,
                width: props.fillParentWidth ? "100%" : "fit-content",
                border: !props.removeBorder
                    ? `1px solid ${colors.border}`
                    : undefined,
                pointerEvents: disabled ? "none" : "auto",
                backgroundColor: colors.main,
                color: colors.text,
                cursor: disabled ? "default" : "pointer",
                padding,
                transition:
                    "opacity .15s ease-in-out,color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
                "&:active": {
                    opacity: disabled ? 1 : 0.8,
                },
            }}
            className={props.className}
            onClick={
                onClick as (
                    event: React.MouseEvent<HTMLDivElement | HTMLAnchorElement>,
                ) => void
            }>
            <div
                css={[
                    {
                        width: props.fillParentWidth ? "100%" : "max-content",
                        minWidth: "fit-content",
                        maxWidth: "100%",
                        opacity: props.spin ? 0 : 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    },
                    props.contentContainerCss,
                ]}>
                {props.children}
            </div>
            {props.spin ? (
                <FlexRow
                    css={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 2,
                    }}
                    mainAxis="center"
                    crossAxis="center">
                    <CircleSpinner
                        size={props.spinnerSize ?? 1.25}
                        color={colors.text}
                        opacity={0.7}
                    />
                </FlexRow>
            ) : null}
        </Element>
    );
};

export { Button as Button_v2 };
export type { ButtonColorPropNames, ButtonProps as ButtonProps_v2 };
