// Adapted from https://codesandbox.io/s/framer-motion-image-gallery-pqvx3?file=/src/Example.tsx
import { css } from "@emotion/react";
import { type StorageItemImage } from "@towni/common";
import * as React from "react";
import { useCallback, useMemo } from "react";
import type { HzScrollNavButtonType } from "../hz-scroll-container/hx-scroll-nav-button";
import { HzScrollContainer } from "../hz-scroll-container/hz-scroll-container";
import type { HzScrollGap } from "../hz-scroll-container/hz-scroll-gap";
import {
    isImageSliderImage,
    type ImageSliderImage,
} from "./image-slider-image";
import { Picture } from "./picture";

type Props = {
    images: (StorageItemImage | ImageSliderImage)[];
    heightPx?: number;
    /** defaults to 2px dividing gap */
    gap?: HzScrollGap;
    /** Remember to wrap onImageClick func in useCallback to prevent unnecessary rerenders */
    onImageClick?: (
        image: StorageItemImage | ImageSliderImage,
        index: number,
    ) => void;
    /** defaults to small */
    navButtonType?: HzScrollNavButtonType;
    startIndex?: number;
    fullWidth?: boolean;
    onIndexChange?: (index: number) => void;
};

const ImageSlider = (props: Props) => {
    const [loaded, setLoaded] = React.useState(new Set<string>());
    const onLoaded = useCallback(
        (key: string) => {
            setLoaded(current => {
                const update = new Set(current);
                update.add(key);
                return update;
            });
        },
        [setLoaded],
    );

    const items = useMemo(
        () =>
            props.images.map((image, index) => {
                return isImageSliderImage(image) ? (
                    <img
                        src={image.originalUrl}
                        onClick={() => props.onImageClick?.(image, index)}
                        onLoad={() => {
                            onLoaded(image.originalUrl);
                        }}
                        css={{
                            display: "block",
                            objectFit: "contain",
                            minHeight: props.heightPx,
                            width: "100%",
                            height: "100%",
                            cursor: props.onImageClick ? "pointer" : "default",
                        }}
                    />
                ) : (
                    <div
                        key={image._id}
                        onClick={() => props.onImageClick?.(image, index)}
                        css={{
                            position: "relative",
                            width: "100%",
                            minHeight: props.heightPx,
                            paddingBottom: "100%",
                            cursor: props.onImageClick ? "pointer" : "default",
                        }}>
                        <Picture
                            key={image._id}
                            imageRef={image}
                            onImageLoad={() => {
                                onLoaded(image._id);
                            }}
                            styling={css({
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                width: "100%",
                                height: "100%",
                            })}
                        />
                    </div>
                );
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.images, props.heightPx, props.onImageClick],
    );
    const numberOfItemsPending = React.useMemo(() => {
        return items.length - loaded.size;
    }, [items, loaded]);

    return (
        <HzScrollContainer
            items={items}
            numberOfItemsPending={numberOfItemsPending}
            startIndex={props.startIndex}
            navButtonType={props.navButtonType || "small"}
            onIndexChange={props.onIndexChange}
            gap={
                props.gap || {
                    dividingGap: 5,
                }
            }
            itemContainerCss={{
                width: props.fullWidth ? "100%" : "95%",
            }}
        />
    );
};

export { ImageSlider };
