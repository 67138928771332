import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModal } from "@@/modals/use-modal";
import { ButtonTransparent } from "@@/shared/buttons_v2/button-gray";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { _FormFieldDescription } from "@@/shared/form/_form-field-description";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { useFormField } from "@@/shared/form/use-form-field";

import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { Icon } from "@@/shared/icons/icon";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { ModalTitle } from "@@/shared/text/modal-title";
import { use100vh } from "@@/shared/use-100-vh";
import { useDateTimeFormatter } from "@@/shared/use-date-time-formatter";
import { useUpdateEffect } from "@@/shared/use-update-effect";
import { customThinScrollbarCss } from "@@/styles/themes/custom-thin-scrollbars";
import { useToast } from "@@/toasts/context/toast-context";
import { useTheme } from "@emotion/react";
import { faClock, faCopy } from "@fortawesome/pro-regular-svg-icons";
import {
    Hour,
    HourMinute,
    IsoAndUnixTimestamp,
    Minute,
    Percentage,
    Translatable,
    asDate,
    generateId,
    getMinutes,
    hourMinuteFactory,
    hours,
    isIsoAndUnix,
    isIsoTimestamp,
    isoAndUnixFactory,
    joinTranslatables,
    translation,
} from "@towni/common";
import copy from "copy-to-clipboard";
import { startOfDay } from "date-fns";
import { Draft } from "immer";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DayPicker, Matcher, PropsSingle } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { Except } from "type-fest";
import { ZodSchema } from "zod";
import { FloatingBox, FloatingBoxActions } from "../floating-box";
import { useDayPickerDefaults } from "./shared/use-day-picker-defaults";
import { Inputs } from "./standalones/inputs";

type Value = IsoAndUnixTimestamp | undefined;

const DateTimePickerContainer_FlexColumn = FlexColumn;
const DatePicker_FlexColumn = FlexColumn;
const TimePicker_FlexColumn = FlexColumn;
const Footer_FlexRow = FlexRow;
const HourPicker_FlexColumn = FlexColumn;
const MinutePicker_FlexColumn = FlexColumn;

type Props<State> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value;
    readonly setter: (state: Draft<Partial<State>>, newValue: Value) => void;
    readonly fieldSchema?: ZodSchema;

    readonly datePickerProps?: Except<
        PropsSingle,
        "selected" | "onSelect" | "mode"
    >;

    readonly disabled?: boolean;
    readonly label?: Translatable;
    readonly labelDescription?: Translatable;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;
    /** Defaults to 1. When 1 pickers shows every minute, when 5 every 5 minutes is shown in picker and so on. */
    readonly minuteOptionStep?: 1 | 5 | 10 | 15 | 20;
    /**
     * When `true` as soon as a date, hour or minute is picked `onChange` is triggered.
     * When `false`, the user has to click the "Select/Ready" button to trigger `onChange`. \
     * Defaults to `false`
     */
    readonly selectDirect?: boolean;
};

const Form2DateTimePicker = <State extends Record<string, unknown>>(
    props: Props<State>,
) => {
    // const isSmallScreen = usePageBreakpoint({ when: "SMALL_SCREEN" });
    const theme = useTheme();
    const dayPickerDefaults = useDayPickerDefaults();

    const toast = useToast();
    const [{ show, hide }, modalId] = useModal("date_time_picker_modal__");
    const isSmallScreen = usePageBreakpoint({ when: "SMALL_SCREEN" });
    const { formatDateAndTimeLong, formatDateMedium } = useDateTimeFormatter();
    const formIdFromContext = useFormId({ doNotThrow: true });
    const formId = props.formId || formIdFromContext;
    const field = useFormField<State, Value>({
        fieldId: props.fieldId,
        getter: props.getter,
        setter: props.setter,
        fieldSchema: props.fieldSchema,
        formId: props.formId,
    });
    if (!field)
        throw new Error(`Field ${props.fieldId} in form ${formId} not found`);

    const hasErrors = field.errors.length > 0;
    const [pickerIsOpen, setPickerIsOpen] = useState(false);

    const validationTrigger = useFormFieldValidation<State, Value>({
        field,
        initialValidationType: "manual",
        customFieldValidationErrorMessage: translation({
            sv: "Du måste välja ett datum och en tid",
            en: "You must choose a date and time",
        }),
    });

    /** Store the initial value of calculated or provided hour and minute */
    const initialHourMinuteRef = useRef(
        (() => {
            if (!field.value) return undefined;
            if (field.value instanceof Date)
                return hourMinuteFactory({ date: field.value });
            if (isIsoAndUnix(field.value))
                return hourMinuteFactory({ date: field.value });
            return field.value;
        })(),
    );
    /** Keeps track of the current selected hour and minute */
    const [hourMinute, _setHourMinute] = useState<HourMinute | undefined>(
        initialHourMinuteRef.current,
    );
    /**
     * Keeps track of the current selected hour and minute in a ref, to allow access to current
     * value without triggering a re-render in some effects
     */
    const hourMinuteRef = useRef<HourMinute | undefined>(hourMinute);

    /**
     * A function to set the current hour and minute.
     * Sets both the state and the ref value.
     */
    const setHourMinute = useCallback((hourMinute: HourMinute | undefined) => {
        _setHourMinute(hourMinute);
        hourMinuteRef.current = hourMinute;
    }, []);

    /** Keeps track of the current selected hour */
    const [selectedHour, setSelectedHour] = useState<Hour | undefined>(
        hourMinute?.hour,
    );
    /** Keeps track of the current selected minute */
    const [selectedMinute, setSelectedMinute] = useState<Minute | undefined>(
        hourMinute?.minute,
    );

    const [selectedDate, setSelectedDate] = useState<Date | undefined>(() => {
        const date = asDate(field.initialValue);
        return date ? startOfDay(date) : undefined;
    });

    const [result, _setResult] = useState<Value | undefined>(field.value);
    const resultRef = useRef(result);
    const setResult = useCallback((result: Value | undefined) => {
        _setResult(result);
        resultRef.current = result;
    }, []);
    const viewHeight = use100vh();

    /**
     * Runs to check if a new minute and hour has been updated
     * and if so, updates the hourMinute state and ref.
     * And if `selectDirect` is true, updates the form value.
     */
    useUpdateEffect(() => {
        const currentResult = resultRef.current;
        const currentHourMinute = hourMinuteRef.current;
        if (
            typeof selectedDate === "undefined" ||
            typeof selectedHour === "undefined" ||
            typeof selectedMinute === "undefined"
        ) {
            if (typeof currentHourMinute !== "undefined") {
                setHourMinute(undefined);
            }
            if (typeof currentResult !== "undefined") {
                setResult(undefined);
            }
            if (typeof field.value !== "undefined") {
                if (props.selectDirect) field.setValue(undefined);
            }
            return;
        }

        const newHourMinute = hourMinuteFactory({
            hour: selectedHour,
            minute: selectedMinute,
        });
        const newDate = new Date(selectedDate);
        newDate.setHours(newHourMinute.hour);
        newDate.setMinutes(newHourMinute.minute);

        const result = isoAndUnixFactory(newDate);
        if (result.iso !== currentResult?.iso) {
            setResult(result);
        }
        if (field.value?.iso !== result.iso) {
            if (props.selectDirect) field.setValue(result);
        }
        if (currentHourMinute?.formatted !== newHourMinute.formatted) {
            setHourMinute(newHourMinute);
        }
    }, [
        field.setValue,
        props.selectDirect,
        selectedDate,
        selectedHour,
        selectedMinute,
    ]);

    /** Keep track of a ref to the hour and minute scroll containers */
    const hourListRef = useRef<HTMLDivElement | null>(null);
    const minuteListRef = useRef<HTMLDivElement | null>(null);
    /** Keep track of if the hour and minute lists have been auto-scrolled */
    const hourListHasBeenScrolled = useRef(false);
    const minuteListHasBeenScrolled = useRef(false);

    /**
     * Scroll the selected hour into view when the component mounts
     * and the hour list have not been scrolled yet.
     */
    const scrollHourIntoView = useCallback(
        (hour: Hour, scrollBehavior: "instant" | "smooth" = "instant") => {
            if (!hourListRef.current) return;
            const hourElement = document.getElementById(
                `hour_${hour}_${field.fieldId}`,
            );
            hourElement?.scrollIntoView({
                behavior: scrollBehavior,
                block: "center",
                inline: "nearest",
            });
            hourListHasBeenScrolled.current = true;
        },
        [field.fieldId],
    );

    /**
     * Scroll the selected minute into view when the component mounts
     * and the minute list have not been scrolled yet.
     */
    const scrollMinuteIntoView = useCallback(
        (minute: Minute, scrollBehavior: "instant" | "smooth" = "instant") => {
            if (!minuteListRef.current) return;
            const minuteElement = document.getElementById(
                `minute_${minute}_${field.fieldId}`,
            );
            minuteElement?.scrollIntoView({
                behavior: scrollBehavior,
                block: "center",
                inline: "nearest",
            });
            minuteListHasBeenScrolled.current = true;
        },
        [field.fieldId],
    );

    /**
     * When react renders the component it triggers this ref callback
     * and we use the ref to the element to scroll the selected hour
     * into view
     */
    const hourRefCallback = useCallback(
        (node: HTMLDivElement | null) => {
            const prev = hourListRef.current;
            hourListRef.current = node;
            if (!node) return;
            if (!selectedHour) return;
            if (prev) return;
            scrollHourIntoView(selectedHour);
        },
        [scrollHourIntoView, selectedHour],
    );
    /**
     * When react renders the component it triggers this ref callback
     * and we use the ref to the element to scroll the selected minute
     * into view
     */
    const minuteRefCallback = useCallback(
        (node: HTMLDivElement | null) => {
            const prev = minuteListRef.current;
            minuteListRef.current = node;
            if (!node) return;
            if (!selectedMinute) return;
            if (prev) return;
            scrollMinuteIntoView(selectedMinute, "instant");
        },
        [scrollMinuteIntoView, selectedMinute],
    );

    const componentKey = `${formId}_${props.fieldId}`;

    /**
     * When the component mounts, start listening for paste events
     * and if the paste event is in the time picker field,
     * parse the pasted text and set the selected hour and minute
     * if the text is a valid time string.
     */
    useEffect(() => {
        // Handle paste from clipboard
        const onPaste = (ev: Event) => {
            if (document.activeElement?.id !== componentKey) return;
            const pastedText = (ev as ClipboardEvent).clipboardData?.getData(
                "Text",
            );
            if (!isIsoTimestamp(pastedText)) return;
            const datePasted = new Date(pastedText);
            setSelectedHour(datePasted.getHours() as Hour);
            setSelectedMinute(datePasted.getMinutes() as Minute);
            setSelectedDate(startOfDay(datePasted));
        };

        window.addEventListener("paste", onPaste);
        return () => {
            window.removeEventListener("paste", onPaste);
        };
    }, [componentKey, field.fieldId]);

    const minuteOptions = useMemo(() => {
        return getMinutes(props.minuteOptionStep);
    }, [props.minuteOptionStep]);

    const [key, setKey] = useState(generateId);
    const [defaultMonth, _setDefaultMonth] = useState<Date | undefined>(
        asDate(field.value),
    );
    const setDefaultMonth = useCallback((date: Date | undefined) => {
        _setDefaultMonth(date);
        setKey(generateId());
    }, []);

    const timePicker = useMemo(
        () => (
            <TimePicker_FlexColumn
                mainAxis="stretch"
                crossAxis="stretch"
                css={{
                    flex: 1,
                    minWidth: "max-content",
                    overflow: "hidden",
                }}>
                <FlexRow
                    mainAxis="stretch"
                    crossAxis="stretch"
                    css={{
                        flex: 1,
                        position: "relative",
                        overflow: "hidden",
                    }}>
                    <HourPicker_FlexColumn
                        ref={hourRefCallback}
                        css={[
                            {
                                flex: 1,
                                // maxWidth: 150,
                                overflowX: "hidden",
                                overflowY: "auto",
                                borderRightStyle: "solid",
                                borderRightWidth: 1,
                                borderRightColor:
                                    theme.colors.default.border.asString,
                            },
                            customThinScrollbarCss,
                        ]}>
                        {/* Timmar */}
                        <_TimePickerHeader
                            text={translation({
                                sv: "Timme",
                                en: "Hour",
                            })}
                        />
                        <ForEach itemOf={hours} getKey={hour => hour}>
                            {hour => (
                                <ButtonTransparent
                                    id={`hour_${hour}_${field.fieldId}`}
                                    padding={{
                                        topBottom: 5,
                                        right: 15,
                                        left: 15,
                                    }}
                                    onClick={() => {
                                        setSelectedHour(hour);
                                    }}>
                                    <TextBox
                                        text={hour.toString().padStart(2, "0")}
                                        padding={{
                                            leftRight: 15,
                                            topBottom: 5,
                                        }}
                                        weight="700"
                                        css={{
                                            backgroundColor:
                                                hour === selectedHour
                                                    ? theme.colors.primary.light
                                                          .asString
                                                    : undefined,
                                            border:
                                                hour === selectedHour
                                                    ? `1px solid ${theme.colors.primary.border.withAlpha(0.5).asString}`
                                                    : `1px solid ${theme.colors.transparent.asString}`,

                                            borderRadius: 5,
                                            color:
                                                hour === selectedHour
                                                    ? theme.colors.primary
                                                          .background.asString
                                                    : undefined,
                                        }}
                                    />
                                </ButtonTransparent>
                            )}
                        </ForEach>
                    </HourPicker_FlexColumn>
                    <MinutePicker_FlexColumn
                        ref={minuteRefCallback}
                        css={[
                            {
                                flex: 1,
                                // maxWidth: 150,
                                overflowX: "hidden",
                                overflowY: "auto",
                            },
                            customThinScrollbarCss,
                        ]}>
                        {/* Minuter */}
                        <_TimePickerHeader
                            text={translation({
                                sv: "Minut",
                                en: "Minute",
                            })}
                        />
                        <ForEach
                            itemOf={minuteOptions}
                            getKey={minute => minute}>
                            {minute => (
                                <ButtonTransparent
                                    id={`minute_${minute}_${field.fieldId}`}
                                    padding={{
                                        topBottom: 5,
                                        right: 15,
                                        left: 15,
                                    }}
                                    onClick={() => {
                                        setSelectedMinute(minute);
                                    }}>
                                    <TextBox
                                        text={minute
                                            .toString()
                                            .padStart(2, "0")}
                                        padding={{
                                            leftRight: 15,
                                            topBottom: 5,
                                        }}
                                        weight="700"
                                        css={{
                                            backgroundColor:
                                                minute === selectedMinute
                                                    ? theme.colors.primary.light
                                                          .asString
                                                    : undefined,
                                            borderRadius: 5,
                                            border:
                                                minute === selectedMinute
                                                    ? `1px solid ${theme.colors.primary.border.withAlpha(0.5).asString}`
                                                    : `1px solid ${theme.colors.transparent.asString}`,
                                            color:
                                                minute === selectedMinute
                                                    ? theme.colors.primary
                                                          .background.asString
                                                    : undefined,
                                        }}
                                    />
                                </ButtonTransparent>
                            )}
                        </ForEach>
                    </MinutePicker_FlexColumn>
                </FlexRow>
            </TimePicker_FlexColumn>
        ),
        [
            field.fieldId,
            hourRefCallback,
            minuteOptions,
            minuteRefCallback,
            selectedHour,
            selectedMinute,
            theme,
        ],
    );

    const footer = useCallback(
        (actions: FloatingBoxActions) => (
            <Footer_FlexRow fillParentWidth mainAxis="space-between">
                <HorizontalDivider />
                <ButtonPrimary
                    disabled={!result}
                    onClick={() => {
                        field.setValue(result);
                        actions.close();
                    }}>
                    <TextBox
                        text={
                            result
                                ? joinTranslatables([
                                      translation({
                                          sv: "Välj ",
                                          en: "Choose ",
                                      }),
                                      formatDateAndTimeLong(result, {
                                          includeYearIfNotCurrent: true,
                                      }),
                                  ])
                                : translation({
                                      sv: "Välj datum och tid",
                                      en: "Choose a date and time",
                                  })
                        }
                    />
                </ButtonPrimary>
            </Footer_FlexRow>
        ),
        [field, formatDateAndTimeLong, result],
    );

    const _props = props;

    // Custom modifiers object
    const selectedAndTodayModifier: Matcher = useCallback(
        (day: Date): boolean => {
            return (
                selectedDate?.toDateString() === day.toDateString() &&
                day.toDateString() === new Date().toDateString()
            );
        },
        [selectedDate],
    );
    const dateAndTimePicker = useCallback(
        (actions: FloatingBoxActions) => (
            <DateTimePickerContainer_FlexColumn
                key={"dateAndTimePicker"}
                crossAxis="stretch"
                css={{
                    backgroundColor: "white",
                    borderRadius: 10,
                    padding: 5,
                    willChange: "filter",
                    transition: "opacity 0.2s ease-in-out",
                    filter: `drop-shadow(5px 5px 10px ${theme.colors.black.light75.asString})`,
                    opacity: pickerIsOpen ? 1 : 0,
                }}>
                <FlexRow
                    css={{ flex: 1, maxHeight: 350 }}
                    mainAxis="stretch"
                    crossAxis="stretch">
                    <DatePicker_FlexColumn
                        css={{
                            borderRight: `1px solid ${theme.colors.default.border.asString}`,
                        }}>
                        <DayPicker
                            key={key}
                            {...(_props.datePickerProps ?? {})}
                            mode="single"
                            defaultMonth={defaultMonth}
                            disabled={_props.disabled || field.isSubmitting}
                            selected={selectedDate}
                            modifiers={{
                                selectedAndToday: selectedAndTodayModifier,
                            }}
                            onDayBlur={(_date, _modifiers, _focusEvent) => {
                                field.setTouched(true);
                            }}
                            css={dayPickerDefaults.css}
                            styles={dayPickerDefaults.styles}
                            modifiersStyles={{
                                ...dayPickerDefaults.modifiersStyles,
                                selectedAndToday: {
                                    backgroundColor:
                                        theme.colors.primary.asString,
                                    borderRadius: 0,
                                    borderColor:
                                        theme.colors.primary.border.withAlpha(
                                            0.5,
                                        ).asString,
                                    color: theme.colors.primary.text.asString,
                                    textDecoration: "underline",
                                    fontWeight: 400,
                                },
                            }}
                            locale={dayPickerDefaults.locale}
                            onSelect={date => {
                                setSelectedDate(date);
                                setDefaultMonth(date);
                                validationTrigger();
                            }}
                        />
                    </DatePicker_FlexColumn>
                    <VerticalDivider />
                    {timePicker}
                </FlexRow>
                <HorizontalLine />
                <FlexRow fillParentWidth css={{ padding: 10 }}>
                    {footer(actions)}
                </FlexRow>
            </DateTimePickerContainer_FlexColumn>
        ),
        [
            _props.datePickerProps,
            _props.disabled,
            dayPickerDefaults,
            defaultMonth,
            field,
            footer,
            key,
            pickerIsOpen,
            selectedAndTodayModifier,
            selectedDate,
            setDefaultMonth,
            theme.colors,
            timePicker,
            validationTrigger,
        ],
    );

    const pickerField = useCallback(
        (actions: FloatingBoxActions) => (
            <FlexRow
                crossAxis="center"
                onClick={actions.toggle}
                css={{
                    cursor: "pointer",
                    backgroundColor: theme.colors.textInput.background.asString,
                    borderRadius: theme.radius,
                    border: `1px solid ${theme.colors.textInput.border.asString}`,
                }}>
                <TextBox
                    text={
                        field.value
                            ? formatDateAndTimeLong(field.value, {
                                  includeYearIfNotCurrent: true,
                              })
                            : translation({
                                  sv: "Välj datum och tid",
                                  en: "Choose a date and time",
                              })
                    }
                    css={{
                        flex: 1,
                        padding: 15,
                        userSelect: "text",
                    }}
                    color={
                        !field.value
                            ? theme.colors.textInput.placeholder.asString
                            : undefined
                    }
                />
                <FlexRow>
                    <Conditional when={!!field.value}>
                        <HorizontalDivider />
                        <Icon
                            icon={faCopy}
                            fixedWidth
                            onClick={ev => {
                                ev.stopPropagation();
                                copy(field.value?.iso ?? "");
                                toast.info({
                                    message: translation({
                                        sv: "Tiden kopierad i ISO8601 format",
                                        en: "Time copied in ISO8601 format",
                                    }),
                                });
                            }}
                            opacity={props.disabled ? 0.5 : 1}
                        />
                    </Conditional>
                    <HorizontalDivider />
                    <Icon
                        icon={faClock}
                        css={{ paddingRight: 10 }}
                        fixedWidth
                        opacity={props.disabled ? 0.5 : 1}
                    />
                </FlexRow>
            </FlexRow>
        ),
        [theme, field.value, formatDateAndTimeLong, props.disabled, toast],
    );

    const picker = useMemo(
        () =>
            isSmallScreen ? (
                <>
                    {pickerField({
                        close: () => setPickerIsOpen(false),
                        open: () => setPickerIsOpen(true),
                        toggle: () => setPickerIsOpen(value => !value),
                    })}
                    <Modal
                        modalId={modalId}
                        onHide={() => {
                            setDefaultMonth(asDate(field.value));
                        }}
                        height={
                            viewHeight > 700
                                ? (0.7 as Percentage)
                                : (0.8 as Percentage)
                        }
                        header={
                            <ModalHeader
                                modalId={modalId}
                                bottomBorder
                                topRightButton={
                                    result
                                        ? {
                                              _type: "TOP_RIGHT_ACTION_TEXT",
                                              text: translation({
                                                  sv: "Klar",
                                                  en: "Ready",
                                              }),
                                              action: () => {
                                                  field.setValue(result);
                                                  setPickerIsOpen(false);
                                              },
                                          }
                                        : undefined
                                }
                                title={
                                    <ModalTitle
                                        text={translation({
                                            sv: "Välj ett datum",
                                            en: "Pick a date",
                                        })}
                                    />
                                }
                            />
                        }>
                        <FlexColumn
                            fillParentWidth
                            css={{
                                flex: 1,
                                overflow: "hidden",
                                paddingTop: 20,
                            }}>
                            <FlexColumn
                                fillParentWidth
                                crossAxis="center"
                                css={{
                                    flex: 1,
                                    overflow: "hidden",
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                }}>
                                <Inputs.DatePicker
                                    initialDate={selectedDate}
                                    css={{ width: "100%" }}
                                    onChange={selected => {
                                        setSelectedDate(asDate(selected));
                                        validationTrigger();
                                    }}
                                />
                                <VerticalDivider M />
                                <FlexColumn
                                    fillParentWidth
                                    crossAxis="stretch"
                                    css={{
                                        flex: 1,
                                        overflow: "hidden",
                                    }}>
                                    <FieldTitle
                                        text={translation({
                                            sv: "Välj en tid",
                                            en: "Pick a time",
                                        })}
                                        required={field.isRequired}
                                    />
                                    <VerticalDivider XXS />
                                    <FlexColumn
                                        fillParentWidth
                                        mainAxis="stretch"
                                        css={{
                                            flex: 1,
                                            borderStyle: "solid",
                                            borderWidth: 1,
                                            borderColor:
                                                theme.colors.default.border
                                                    .asString,
                                            borderRadius: theme.radius,
                                            minHeight: 200,
                                            overflow: "hidden",
                                        }}>
                                        {timePicker}
                                    </FlexColumn>
                                </FlexColumn>
                                <VerticalDivider M />
                            </FlexColumn>
                            <HorizontalLine size={1} css={{ width: "100%" }} />
                            <FlexRow fillParentWidth css={{ padding: 20 }}>
                                {footer({
                                    close: () => setPickerIsOpen(false),
                                    open: () => setPickerIsOpen(true),
                                    toggle: () =>
                                        setPickerIsOpen(value => !value),
                                })}
                            </FlexRow>
                        </FlexColumn>
                    </Modal>
                </>
            ) : (
                <FloatingBox
                    isOpen={pickerIsOpen}
                    content={dateAndTimePicker}
                    trigger={pickerField}
                    onOpen={() => {
                        setPickerIsOpen(true);
                    }}
                    onClose={() => {
                        setPickerIsOpen(false);
                        setDefaultMonth(asDate(field.value));
                    }}
                />
            ),
        [
            dateAndTimePicker,
            field,
            footer,
            formatDateMedium,
            isSmallScreen,
            modalId,
            pickerField,
            pickerIsOpen,
            result,
            selectedDate,
            setDefaultMonth,
            theme,
            timePicker,
            validationTrigger,
            viewHeight,
        ],
    );
    useEffect(() => {
        console.log("TICKYA TACKY", isSmallScreen, pickerIsOpen);
        if (!isSmallScreen) return;
        if (pickerIsOpen) show();
        else hide();
    }, [isSmallScreen, pickerIsOpen, show, hide]);

    return (
        <FlexColumn
            id={componentKey}
            key={componentKey}
            tabIndex={0}
            tag={`form-field_${props.fieldId}`}
            crossAxis="stretch">
            <Conditional when={!!props.label}>
                <FlexRow shrink={0} crossAxis="center" mainAxis="space-between">
                    <FieldTitle
                        htmlFor={props.fieldId}
                        padding={{ left: 2 }}
                        text={props.label ?? ""} // already checked with conditional above
                        required={field.isRequired}
                    />
                    <Conditional when={!!props.labelDescription}>
                        <HorizontalDivider XXS />
                        <FieldTitle
                            padding={{ left: 2 }}
                            text={props.labelDescription ?? ""} // already checked with conditional above
                            weight="700"
                            size="S"
                            css={{
                                opacity: 0.5,
                            }}
                        />
                    </Conditional>
                </FlexRow>
                <VerticalDivider XS />
            </Conditional>
            {picker}
            <_FormFieldDescription
                hasErrors={hasErrors}
                isDirty={field.dirty}
                description={props.description}
                hideDescriptionAfterInput={!!props.hideDescriptionAfterInput}
                css={{
                    marginTop: 5,
                }}
            />
            <FormErrorMessages errors={field.errors} />
        </FlexColumn>
    );
};

const _TimePickerHeader = (props: { text: Translatable }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme = useTheme();
    return (
        <TextBox
            text={props.text}
            weight="700"
            css={{
                backgroundColor: theme.colors.default.background.asString,
                padding: 5,
                paddingRight: 15,
                paddingLeft: 15,
                position: "sticky",
                borderBottom: `1px solid ${theme.colors.default.border.asString}`,
                top: 0,
                zIndex: 10,
            }}
        />
    );
};

export { Form2DateTimePicker };
