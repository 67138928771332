import { css, SerializedStyles, useTheme } from "@emotion/react";
import { colorAsString, ColorItem } from "@towni/common";
import * as React from "react";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { Except } from "type-fest";
import { ContextMenuProps } from "../context-menu/context-menu";
import { TextShimmer } from "../pictures/shimmers";

type Props = {
    readonly to: string | undefined;
    readonly target?: string;
    readonly styling?: SerializedStyles;
    readonly color?: ColorItem;
    readonly hoverColor?: ColorItem;
    readonly className?: string;
    readonly children?: React.ReactNode;
    readonly spin?: boolean;
    readonly hide?: boolean;
    readonly onClick?: (event: React.MouseEvent) => void;
    ["data-testid"]?: string;
};
const LinkTo = (props: Props) => {
    const theme = useTheme();
    const linkColor = props.color
        ? colorAsString(props.color)
        : theme.colors.primary.asString;
    const hoverColor = props.hoverColor
        ? colorAsString(props.hoverColor)
        : theme.colors.primary.dark.asString;

    const styles = [
        {
            color: linkColor,
            ":visited,:focus,:active": {
                color: linkColor,
                textDecoration: "none",
            },
            ":hover": {
                color: hoverColor,
                textDecoration: "underline",
            },
            cursor: "pointer",
        },
        css(props.styling),
    ];
    if (props.hide) return null;
    if (props.spin) {
        return <TextShimmer rows={1} />;
    }
    if (!props.to) return <>{props.children}</>;
    if (typeof props.to === "string") {
        if (
            props.to.startsWith("http") ||
            props.to.startsWith("mailto") ||
            props.to.startsWith("tel") ||
            props.to.startsWith("callto")
        ) {
            return (
                <a
                    href={props.to}
                    data-testid={props["data-testid"]}
                    {...{ target: props.target ?? "" }}
                    css={styles}
                    className={props.className}>
                    {props.children}
                </a>
            );
        }
    }

    return (
        <Link
            to={props.to}
            data-testid={props["data-testid"]}
            {...{ target: props.target ?? "" }}
            css={styles}
            className={props.className}
            onClick={props.onClick}
            style={{
                color: props.color ? colorAsString(props.color) : undefined,
            }}>
            {props.children}
        </Link>
    );
};

const ContextMenuLinkTo = (
    props: Except<ContextMenuProps<Props>, "onClick">,
) => {
    const onLinkClick = useCallback(
        (event: React.MouseEvent): void => {
            event.stopPropagation();
            props.actions.close();
            // setTimeout(() => {
            // }, 0);
        },
        [props.actions],
    );

    return (
        <LinkTo {...props} onClick={onLinkClick}>
            {props.children}
        </LinkTo>
    );
};

export { ContextMenuLinkTo, LinkTo };
