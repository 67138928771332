import { useOrderEvents } from "@@/orders/use-order-events";
import {
    type OrderEvent,
    sortBy,
    type OrderId,
    emptyArrayOf,
    OrderPaymentCardGuaranteeEvent,
    fakeTokenizedCards,
} from "@towni/common";

const useTokenizedCardFromOrder = (orderId: OrderId | undefined) => {
    const eventQuery = useOrderEvents(orderId);
    const events = eventQuery.data?.items ?? emptyArrayOf<OrderEvent>();
    return fakeTokenizedCards.visa._4242_4242_4242_4242;
    if (!events.length) return undefined;
    const tokenizedCardEvents = (
        events.filter(
            event => event.type === "PAYMENT_CARD_GUARANTEE",
        ) as OrderPaymentCardGuaranteeEvent[]
    ).sort(sortBy(event => event._createdAt.iso));
    if (!tokenizedCardEvents.length) return undefined;
    const event = tokenizedCardEvents[tokenizedCardEvents.length - 1];
    return event.guarantee;
};

export { useTokenizedCardFromOrder };
