import { Form } from "@@/shared/form/form.zustand-store";
import type {
    Form2NumberInputPossibleValueTypes,
    Form2NumberInputProps,
} from "@@/shared/form/form2-number-input";
import { DurationInMs, parseSafely, Translatable } from "@towni/common";
import { ReactNode } from "react";
import { Except } from "type-fest";
import { z } from "zod";

const _stateZodObject = z.object({
    value: z.number().optional(),
});
type State = z.infer<typeof _stateZodObject>;
type Value = Form2NumberInputPossibleValueTypes;

type Props = {
    readonly initialValue?: number;
    readonly onChange: (value: number | undefined) => void;
    readonly children?: ReactNode;
    readonly label?: Translatable;
    readonly zodSchema?: z.ZodType<Value>;
    /** defaults to blur, no delay */
    readonly validationTrigger?:
        | {
              type: "blur";
              delay?: DurationInMs;
          }
        | {
              type: "focus";
              delay?: DurationInMs;
          }
        | {
              type: "key_down";
              delay?: DurationInMs;
          };
} & Except<Form2NumberInputProps<State>, "fieldId" | "getter" | "setter">;

const NumberInput = (props: Props) => {
    const valueZodSchema = props.zodSchema ?? z.number().optional();
    return (
        <Form
            formParams={{
                clearOnUnmount: true,
                initializeIfNotExists: {
                    zodObject: z.object({
                        value: valueZodSchema,
                    }),
                    initialState: {
                        value: props.initialValue ?? undefined,
                    },
                },
            }}>
            {({ FormComponents }) => (
                <FormComponents.NumberInput
                    {...props}
                    fieldId="numberInput"
                    label={props.label}
                    getter={data => data.value}
                    validationTrigger={props.validationTrigger}
                    setter={(data, newValue) => {
                        data.value = newValue;
                        if (props.onChange) {
                            const validatedNewValue = parseSafely({
                                schema: valueZodSchema,
                                value: newValue,
                            });
                            props.onChange(validatedNewValue);
                        }
                    }}
                />
            )}
        </Form>
    );
};

export { NumberInput };
