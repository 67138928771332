import { calculateCartOrderItemPrices } from "@@/carts/calculate-order-item-prices";
import { browserLogger } from "@@/settings";
import { useMountEffect } from "@@/shared/use-mount-effect";
import { useUpdateEffect } from "@@/shared/use-update-effect";
import {
    AccommodationTypeAndSubTypeId,
    addOrReplace,
    deliveryMethodType,
    isAccommodation,
    isBuyable,
    isGiftCard,
    isManifestBookableOptionValue,
    isOrderItemOptional_V2,
    isProductOrderItem,
    isVoucher,
    repeat,
    setWithinRange,
    sumPrices,
    zeroPrice,
    type BookableOptionValue,
    type BookableOptionValueId,
    type Cart,
    type DeliverySelection,
    type ManifestBookableOptionValue,
    type ManifestFieldValue,
    type OrderItem,
    type OrderItemId,
    type ProductId,
    type ProviderId,
    type Quantity,
    type ResourceReservationRequest,
} from "@towni/common";
import objectHash from "object-hash";
import { Key, createContext, useContext, useRef } from "react";
import { StoreApi, createStore } from "zustand";
import { shallow } from "zustand/shallow";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { createEmptyCart } from "./create-empty-cart";
import { useMultiCartStore } from "./multi-cart.context";

type ContextState = State & Actions;
type State = {
    readonly cart: Cart;
    readonly preserveCart: boolean;
};
type Actions = {
    readonly addItemToCart: (item: OrderItem) => void;
    readonly setItemInCart: (item: OrderItem) => void;
    readonly addItemsToCart: (items: OrderItem[]) => void;
    readonly removeItemFromCart: (orderItemId: OrderItemId) => void;
    readonly removeProductFromCart: (productId: ProductId) => void;
    readonly clearCart: () => void;
    readonly setMessageToProvider: (message: string) => void;
    readonly setCart: (cart: Cart) => void;
    readonly increaseQuantity: (
        orderItemId: OrderItemId,
        quantity?: number,
    ) => void;
    readonly decreaseQuantity: (
        orderItemId: OrderItemId,
        quantity?: number,
    ) => void;
    readonly setDeliverySelection: (
        deliverySelection: DeliverySelection,
    ) => void;
    readonly setDeliveryInstructions: (deliveryInstructions: string) => void;
    readonly setManifestRow: (
        manifestId: BookableOptionValueId,
        row: ManifestFieldValue,
    ) => void;
    readonly setReservationRequests: (
        reservations: ResourceReservationRequest[],
    ) => void;
    readonly setBookableOptionValues: (
        optionValues: BookableOptionValue[],
    ) => void;
    // readonly setAccommodationSubType: (
    //     subType: AccommodationSubType,
    //     reservation: ResourceReservationRequest,
    //     orderItem: OrderItemAccommodation_V2,
    // ) => void;
    readonly setAccommodationSubTypes: (
        values: Record<AccommodationTypeAndSubTypeId, number>,
    ) => void;
};

const CartContext = createContext<StoreApi<ContextState> | undefined>(
    undefined,
);

type Props = {
    readonly key: Key; // unavailable in props (react thingy, not passed on), here to make it required everywhere, should be set to same as providerId
    readonly onPremiseCart?: boolean;
    readonly providerId: ProviderId;
    readonly doNotPreserveCart?: boolean;
    readonly children?: React.ReactNode;
    readonly initialCart?: Cart;
    readonly initialOrderItems?: OrderItem[];
};

const addItemToCart =
    (getCart: () => Cart, store: ReturnType<typeof useMultiCartStore>) =>
    (item: OrderItem) => {
        // Check if there already are items in order of the same order item in cart
        const cart = getCart();

        const newOrderItem = calcNewOrderItem(cart.orderItems, item);

        // Update the order items list
        const newOrderItems = addOrReplace({
            list: cart.orderItems,
            itemsToAddOrReplace: newOrderItem,
            predicate: item => item._id,
        });

        // Const calculate new cart prices
        const newCartPrices = calculateCartOrderItemPrices(
            newOrderItems,
            cart.tip,
            cart.reservationRequests,
        );

        const isVouchersOnly = newOrderItems
            .filter(isProductOrderItem)
            .every(oi => isVoucher(oi) || isGiftCard(oi));

        const update = {
            ...cart,
            ...newCartPrices,
            orderItems: newOrderItems,
            delivery: isVouchersOnly
                ? {
                      method: deliveryMethodType.DIGITAL,
                      price: zeroPrice,
                  }
                : cart.delivery,
        };
        browserLogger.info("addItemToCart");
        void store.getState().setCart(update);
    };
const addItemsToCart =
    (getCart: () => Cart, store: ReturnType<typeof useMultiCartStore>) =>
    (items: OrderItem[]) => {
        // Check if there already are items in order of the same order item in cart
        const cart = getCart();

        const newCalculatedOrderItems = calcNewOrderItems(
            cart.orderItems,
            items,
        );

        // Update the order items list
        const newOrderItems = addOrReplace({
            list: cart.orderItems,
            itemsToAddOrReplace: newCalculatedOrderItems,
            predicate: item => item._id,
        });

        // Const calculate new cart prices
        const newCartPrices = calculateCartOrderItemPrices(
            newOrderItems,
            cart.tip,
            cart.reservationRequests,
        );

        //
        const deliveryIsDigital = newOrderItems
            .filter(isProductOrderItem)
            .every(
                oi => isVoucher(oi) || isGiftCard(oi) || isAccommodation(oi),
            );

        const update: Cart = {
            ...cart,
            ...newCartPrices,
            orderItems: newOrderItems,
            delivery: deliveryIsDigital
                ? {
                      method: deliveryMethodType.DIGITAL,
                      price: zeroPrice,
                  }
                : cart.delivery,
        };

        browserLogger.info("addItemsToCart");
        void store.getState().setCart(update);
    };

const CartContextProvider = (props: Props) => {
    const multiCartStore = useMultiCartStore();

    const store = useRef<StoreApi<ContextState>>(
        createStore<ContextState>()((_set, _get) => {
            const getCart = (): Cart => {
                const cartForProvider = multiCartStore
                    .getState()
                    .getCart(props.providerId);

                if (cartForProvider) return cartForProvider;

                if (
                    props.initialOrderItems &&
                    props.initialOrderItems.length > 0
                ) {
                    const newCartPrices = calculateCartOrderItemPrices(
                        props.initialOrderItems,
                        0,
                        [],
                    );
                    const cart = createEmptyCart(
                        props.providerId,
                        !props.doNotPreserveCart,
                        objectHash,
                    );
                    const isVoucherOrGiftCardOnly = props.initialOrderItems
                        .filter(isProductOrderItem)
                        .every(
                            oi =>
                                isVoucher(oi) ||
                                isGiftCard(oi) ||
                                isAccommodation(oi),
                        );

                    if (isVoucherOrGiftCardOnly)
                        browserLogger.warn(
                            "Voucher or gift card only cart",
                            { isVoucherOrGiftCardOnly },
                            {
                                method: deliveryMethodType.DIGITAL,
                                price: zeroPrice,
                            },
                        );

                    const update: Cart = {
                        ...cart,
                        ...newCartPrices,
                        orderItems: props.initialOrderItems,
                        delivery: isVoucherOrGiftCardOnly
                            ? {
                                  method: deliveryMethodType.DIGITAL,
                                  price: zeroPrice,
                              }
                            : cart.delivery,
                    };
                    return multiCartStore.getState().addCart(update);
                }

                return multiCartStore
                    .getState()
                    .addCart(
                        createEmptyCart(
                            props.providerId,
                            !props.doNotPreserveCart,
                            objectHash,
                        ),
                    );
            };
            const _cart = getCart();

            const _addItemToCart = addItemToCart(getCart, multiCartStore);
            const _addItemsToCart = addItemsToCart(getCart, multiCartStore);
            const clearCart = () => {
                const currentCart = multiCartStore
                    .getState()
                    .carts.get(props.providerId);
                if (!currentCart) return;

                const emptyCart = createEmptyCart(
                    currentCart.providerId,
                    !props.doNotPreserveCart,
                    objectHash,
                );
                multiCartStore.getState().setCart(emptyCart);
            };
            const removeItemFromCart = (orderItemId: OrderItemId) => {
                const cart = getCart();

                // Find item in current order items list
                const matchingItem = cart.orderItems.filter(
                    oi => oi._id === orderItemId,
                );
                if (!matchingItem) return;

                // Create new order items list without the matching item
                const updatedOrderItems = [
                    ...cart.orderItems.filter(
                        oi =>
                            !matchingItem.some(
                                mi =>
                                    mi._id === oi._id || oi.parentId === mi._id,
                            ),
                    ),
                ];

                // Calculate new cart prices
                const updatedPrices = calculateCartOrderItemPrices(
                    updatedOrderItems,
                    cart.tip,
                    cart.reservationRequests,
                );

                // Updated cart
                const updated = {
                    ...cart,
                    orderItems: updatedOrderItems,
                    ...updatedPrices,
                };

                multiCartStore.getState().setCart(updated);
            };
            const removeProductFromCart = (productId: ProductId) => {
                const cart = getCart();

                // Find item in current order items list
                const matchingItems = cart.orderItems.filter(
                    oi => isProductOrderItem(oi) && oi.productId === productId,
                );
                if (!matchingItems?.length) return;

                // Create new order items list without the matching item
                const updatedOrderItems = [
                    ...cart.orderItems.filter(
                        oi =>
                            !matchingItems.some(
                                match =>
                                    match._id === oi._id ||
                                    oi.parentId === match._id,
                            ),
                    ),
                ];

                // Calculate new cart prices
                const updatedPrices = calculateCartOrderItemPrices(
                    updatedOrderItems,
                    cart.tip,
                    cart.reservationRequests,
                );

                // Updated cart
                const update = {
                    ...cart,
                    orderItems: updatedOrderItems,
                    ...updatedPrices,
                };

                multiCartStore.getState().setCart(update);
            };
            const setMessageToProvider = (message: string) => {
                const cart = getCart();

                const update = {
                    ...cart,
                    messageToProvider: message,
                };
                multiCartStore.getState().setCart(update);
            };
            const increaseQuantity = (
                orderItemId: OrderItemId,
                quantity = 1,
            ) => {
                if (!Number.isInteger(quantity))
                    throw new Error("Item quantity must be an integer");

                const cart = getCart();
                // Round quantity to nearest integer can be negative!
                const quantityChange = Math.round(quantity);

                // Find item in cart
                const matchingItems = cart.orderItems.filter(
                    oi => orderItemId === oi._id || oi.parentId === orderItemId,
                );

                // If item not found, nothing to do
                if (!matchingItems.length) return;

                // If item is not buyable, voucher or gift card, nothing to do
                if (
                    !matchingItems.some(
                        t => isBuyable(t) || isVoucher(t) || isGiftCard(t),
                    )
                ) {
                    return;
                }

                // Update the quantity of the matching item
                const updatedMatchingItems = matchingItems.map(matchingItem => {
                    const newQuantityValue = setWithinRange(
                        matchingItem.quantity.value + quantityChange,
                        { min: 0 },
                    );
                    const price = sumPrices(
                        repeat(matchingItem.pricePerItem, newQuantityValue),
                    );
                    const originalPrice = matchingItem.originalPricePerItem
                        ? sumPrices(
                              repeat(
                                  matchingItem.originalPricePerItem,
                                  newQuantityValue,
                              ),
                          )
                        : undefined;

                    const updatedOrderItem: OrderItem = {
                        ...matchingItem,
                        quantity: {
                            ...matchingItem.quantity,
                            value: newQuantityValue,
                        },
                        price,
                        originalPrice,
                    };

                    return updatedOrderItem;
                });

                // Update the order items list
                const updatedOrderItems = addOrReplace({
                    list: cart.orderItems,
                    itemsToAddOrReplace: updatedMatchingItems,
                    predicate: item => item._id,
                }).filter(uo => uo.quantity.value > 0);

                // Const calculate new cart prices
                const updatedCartPrices = calculateCartOrderItemPrices(
                    updatedOrderItems,
                    cart.tip,
                    cart.reservationRequests,
                );
                const update = {
                    ...cart,
                    ...updatedCartPrices,
                    orderItems: updatedOrderItems,
                };

                multiCartStore.getState().setCart(update);
                return;
            };
            const decreaseQuantity = (orderItemId: OrderItemId, quantity = 1) =>
                increaseQuantity(orderItemId, -quantity);
            const setDeliveryInstructions = (deliveryInstructions: string) => {
                const cart = getCart();

                if (cart.delivery.method === "HOME_DELIVERY") {
                    const update = {
                        ...cart,
                        delivery: {
                            ...cart.delivery,
                            deliveryInstruction: deliveryInstructions,
                        },
                    };
                    multiCartStore.getState().setCart(update);
                }
            };
            const setDeliverySelection = (
                deliverySelection: DeliverySelection,
            ) => {
                const cart = getCart();

                const update = {
                    ...cart,
                    delivery: deliverySelection,
                };
                multiCartStore.getState().setCart(update);
            };
            const setManifestRow = (
                manifestId: BookableOptionValueId,
                row: ManifestFieldValue,
            ) => {
                const cart = getCart();

                const reservationRequests = cart.reservationRequests.map(
                    resRequest => {
                        const option = resRequest.optionValues.find(
                            f => f._id === manifestId,
                        );

                        if (!isManifestBookableOptionValue(option))
                            return resRequest;

                        const newManifest: ManifestBookableOptionValue = {
                            ...option,
                            manifest: option.manifest.map(i => {
                                if (i.index === row.index) {
                                    return row;
                                } else {
                                    return i;
                                }
                            }),
                        };
                        const output: ResourceReservationRequest = {
                            ...resRequest,
                            optionValues: resRequest.optionValues.map(o =>
                                o._id !== option._id ? o : newManifest,
                            ),
                        };
                        return output;
                    },
                );
                const update = {
                    ...cart,
                    reservationRequests,
                };

                multiCartStore.getState().setCart(update);
            };
            const setReservationRequests = (
                reservationRequests: ResourceReservationRequest[],
            ) => {
                const cart = getCart();

                const update = {
                    ...cart,
                    reservationRequests: reservationRequests.filter(
                        item => item.quantity > 0,
                    ),
                };
                multiCartStore.getState().setCart(update);
            };
            const setAccommodationSubTypes = (
                selections: Record<AccommodationTypeAndSubTypeId, number>,
            ) => {
                const cart = getCart();
                const update: Cart = {
                    ...cart,
                    accommodationSubTypeCount: selections,
                };
                multiCartStore.getState().setCart(update);
                browserLogger.info("setAccommodationSubType", { update });
                multiCartStore.getState().setCart(update);
            };
            const setBookableOptionValues = (
                optionValues: BookableOptionValue[],
            ) => {
                const cart = getCart();

                const update = {
                    ...cart,
                    optionValues,
                };

                multiCartStore.getState().setCart(update);
            };

            const setItemInCart = (item: OrderItem) => {
                const cart = getCart();

                const updatedOrderItems = addOrReplace({
                    list: cart.orderItems,
                    itemsToAddOrReplace: item,
                    predicate: item => item._id,
                });

                const updatedCartPrices = calculateCartOrderItemPrices(
                    updatedOrderItems,
                    cart.tip,
                    cart.reservationRequests,
                );

                const update = {
                    ...cart,
                    ...updatedCartPrices,
                    orderItems: updatedOrderItems,
                };

                multiCartStore.getState().setCart(update);
            };

            return {
                cart: getCart(),
                preserveCart: !props.doNotPreserveCart,
                addItemToCart: _addItemToCart,
                addItemsToCart: _addItemsToCart,
                clearCart,
                removeItemFromCart,
                removeProductFromCart,
                setMessageToProvider,
                setCart: multiCartStore.getState().setCart,
                // selectCartId,
                increaseQuantity,
                decreaseQuantity,
                setDeliverySelection,
                setDeliveryInstructions,
                setManifestRow,
                setReservationRequests,
                setAccommodationSubTypes,
                setBookableOptionValues,
                setItemInCart,
            };
        }),
    );

    useMountEffect(() => {
        // console.log("CART SETTING STATE 0");

        const unsubscribe = multiCartStore.subscribe((state, prevState) => {
            // console.log("CART SETTING STATE 1", {
            //     now: state.carts.get(props.providerId),
            //     prev: prevState.carts.get(props.providerId),
            //     same:
            //         state.carts.get(props.providerId) ===
            //         prevState.carts.get(props.providerId),
            // });

            if (
                state.carts.get(props.providerId) !==
                prevState.carts.get(props.providerId)
            ) {
                // console.log("SETTING STATE 2");
                store.current.setState({
                    cart: state.carts.get(props.providerId),
                });
            }
        });
        return () => {
            unsubscribe();
        };
    });

    useUpdateEffect(() => {
        if (props.providerId !== store.current.getState().cart.providerId) {
            throw new Error(
                "ProviderId of CartContextProvider cannot be changed",
            );
            // TODO: Could add switching logic here if we want to support switching providerId
        }
    }, [props.providerId]);

    return (
        <CartContext.Provider value={store.current}>
            {props.children}
        </CartContext.Provider>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const useCartContext = <U extends unknown = ContextState>(
    selector: (context: ContextState) => U = context => context as unknown as U,
): U => {
    const store = useContext(CartContext);
    if (store === undefined) {
        throw new Error(
            "useCartContext must be used within a CartContextProvider",
        );
    }
    return useStoreWithEqualityFn(store, selector, shallow);
};

const useCart = () => useCartContext(context => context.cart);

function calcNewOrderItem(
    currentOrderItems: OrderItem[],
    orderItem: OrderItem,
) {
    if (isProductOrderItem(orderItem)) {
        const matchingItem = currentOrderItems.find(
            item => isProductOrderItem(item) && item.skuId === orderItem.skuId,
        );

        // if (orderItem.acquireType === "BUYABLE") {
        // Calculate new quantity of order item
        const newQuantity: Quantity = {
            ...orderItem.quantity,
            value:
                orderItem.quantity.value + (matchingItem?.quantity.value ?? 0),
        };

        // Create new order item
        const price = sumPrices(
            repeat(orderItem.pricePerItem, newQuantity.value),
        );
        const originalPrice = orderItem.originalPricePerItem
            ? sumPrices(
                  repeat(orderItem.originalPricePerItem, newQuantity.value),
              )
            : undefined;

        const newOrderItem: OrderItem = {
            ...orderItem,
            quantity: newQuantity,
            price,
            originalPrice,
        };
        return newOrderItem;
    }

    return orderItem;
    // }
}

function calcNewOrderItems(
    currentOrderItems: OrderItem[],
    orderItems: OrderItem[],
) {
    const newOrderItem = orderItems.find(isProductOrderItem);
    if (!newOrderItem) throw new Error("No product order item found");

    const matchingItems = currentOrderItems.filter(
        item =>
            isProductOrderItem(item) &&
            item.skuId === newOrderItem.skuId &&
            item.parentId === newOrderItem.parentId &&
            item.extraGroup?._id === newOrderItem.extraGroup?._id &&
            item.messageToProvider === newOrderItem.messageToProvider,
    );
    const newChildren = orderItems.filter(
        oi => oi.parentId === newOrderItem._id,
    );

    //All are new
    if (matchingItems.length === 0) {
        return [...currentOrderItems, ...orderItems];
    }

    const matches = matchingItems.flatMap(matchingItem => {
        const allChildren = currentOrderItems.filter(
            oi => oi.parentId === matchingItem._id,
        );
        if (newChildren.length !== allChildren.length) return [];

        const allChildrenIsMatched = allChildren.every(
            child =>
                isOrderItemOptional_V2(child) &&
                newChildren.some(
                    oi =>
                        isOrderItemOptional_V2(oi) &&
                        oi.optional._id === child.optional._id,
                ),
        );
        if (allChildrenIsMatched) return [matchingItem, ...allChildren];
        return [];
    });

    if (matches.length) {
        const toUpdate = matches;
        const matchingItem = matches.find(isProductOrderItem);
        const newQuantity: Quantity = {
            ...newOrderItem.quantity,
            value:
                newOrderItem.quantity.value +
                (matchingItem?.quantity.value ?? 0),
        };

        return toUpdate.map(oi => {
            // Create new order item
            const price = sumPrices(repeat(oi.pricePerItem, newQuantity.value));
            const originalPrice = oi.originalPricePerItem
                ? sumPrices(repeat(oi.originalPricePerItem, newQuantity.value))
                : undefined;

            const newOrderItem: OrderItem = {
                ...oi,
                quantity: newQuantity,
                price,
                originalPrice,
            };
            return newOrderItem;
        });
    }

    return [...currentOrderItems, ...orderItems];
    // }
}

export {
    CartContextProvider,
    addItemToCart,
    addItemsToCart,
    useCart,
    useCartContext,
};
