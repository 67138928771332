import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModal } from "@@/modals/use-modal";
import { useModalActions } from "@@/modals/use-modal-actions";
import { ButtonTransparent } from "@@/shared/buttons_v2/button-gray";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { DynamicIcon } from "@@/shared/icons/dynamic-icon";
import { Icon } from "@@/shared/icons/icon";
import { ImageViewer } from "@@/shared/image-gallery.modal";
import { ImageSlider } from "@@/shared/pictures/image-slider";
import { ImageSliderImage } from "@@/shared/pictures/image-slider-image";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { TextBox } from "@@/shared/text";
import { use100vh } from "@@/shared/use-100-vh";
import {
    useIsTranslatableEmpty,
    useTranslate,
} from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons";
import {
    AccommodationType,
    AccommodationTypeId,
    Amenity,
    AmenityId,
    emptyArrayOf,
    exists,
    isResourceAccommodation,
    isResourceGroup,
    makeResourceGraph,
    OrganizedGraph,
    organizeGraph,
    Resource,
    ResourceGraph,
    ResourceGroup,
    sortBy,
    StorageItemImage,
    Translatable,
    translation,
    uniqueBy,
} from "@towni/common";
import { useCallback, useMemo, useState } from "react";
import {
    getImageRefsFromResources,
    useResourceImageQueries,
} from "../hooks/use-resource-images";

type Props = {
    readonly modalId: ModalId;
    readonly title?: Translatable;
    readonly onClose?: () => void;
    readonly resource: ResourceGroup;
    readonly graph: ResourceGraph;
};
/**
 *
 *
 * @param {Props} props
 * @return {*}
 */
const AccommodationInfoModal = (props: Props) => {
    const _theme = useTheme();
    const isMobile = usePageBreakpoint({ when: "📱" });
    const _modalActions = useModalActions(props.modalId);
    const _resourceGroup = isResourceGroup(props.resource)
        ? props.resource
        : undefined;

    //const resourceQuery = useResourcesForResourceGroupQuery(resourceGroup?._id);
    // const resources = useMemo(
    //     () =>
    //         resourceGroup?._id
    //             ? (resourceQuery.data ?? emptyArrayOf<Resource>())
    //             : [props.resource],
    //     [resourceGroup?._id, resourceQuery.data, props.resource],
    // );

    // const graph = useMemo(
    //     () =>
    //         resourceQuery.isPending
    //             ? undefined
    //             : resourceGroup?._id
    //               ? makeResourceGraph(resourceGroup, resources)
    //               : undefined,
    //     [resourceGroup, resourceQuery.isPending, resources],
    // );

    const graph = props.graph;

    const organizedGraph = useMemo(
        () => (graph ? organizeGraph(graph) : undefined),
        [graph],
    );

    return (
        <Modal
            modalId={props.modalId}
            css={{
                maxWidth: !isMobile ? 750 : undefined,
                minWidth: !isMobile ? 450 : undefined,
                marginLeft: 20,
                marginRight: 20,
            }}
            header={
                <ModalHeader
                    title={
                        props.title ??
                        organizedGraph?.root.meta.title ??
                        translation({
                            sv: "Boendeinformation",
                            en: "Accommodation information",
                        })
                    }
                    bottomBorder
                    modalId={props.modalId}
                />
            }
            onHide={props.onClose}>
            <FlexColumn crossAxis="stretch">
                {!organizedGraph ? null : (
                    <AccommodationResourceGraphInfo
                        resourceGraph={organizedGraph}
                    />
                )}
            </FlexColumn>
        </Modal>
    );
};

const AccommodationResourceGraphInfo = (props: {
    /** The resource graph to show info about */
    resourceGraph: OrganizedGraph;
}) => {
    const theme = useTheme();
    const translate = useTranslate();
    const isTranslatableEmpty = useIsTranslatableEmpty();
    const organizedGraph = props.resourceGraph;
    const rootResource = organizedGraph.root;
    const vh100 = use100vh();

    // Find all images in the graph
    // and load their metadata from the server
    const rootImageIds = getImageRefsFromResources(organizedGraph?.root).map(
        img => img.imageId,
    );
    const imageRefQueries = useResourceImageQueries(
        organizedGraph?.all ?? emptyArrayOf<Resource>(),
    );
    const imageRefs = imageRefQueries
        .map(imageRef => imageRef.data)
        .filter(exists);
    const _imageRefsArePending = imageRefQueries.some(
        imageRef => imageRef.isPending,
    );

    // Split the images into root images and other images
    // and return the root images first in the array
    const images = useMemo(() => {
        const rootImages: StorageItemImage[] = [];
        const otherImages: StorageItemImage[] = [];
        uniqueBy(imageRefs, img => img._id).forEach(imageRef => {
            if (rootImageIds.includes(imageRef._id)) rootImages.push(imageRef);
            else otherImages.push(imageRef);
        });
        return [...rootImages, ...otherImages];
    }, [imageRefs, rootImageIds]);

    // Get the accommodation type and amenities
    const accommodationTypes = useMemo(() => {
        const accommodationResources = organizedGraph.resources.filter(
            isResourceAccommodation,
        );
        const accommodationTypeIds = new Set<AccommodationTypeId>();
        const accommodationTypeMap = new Map<
            AccommodationTypeId,
            AccommodationType
        >();
        for (const resource of accommodationResources) {
            accommodationTypeIds.add(resource.accommodationType._id);
            accommodationTypeMap.set(
                resource.accommodationType._id,
                resource.accommodationType,
            );
        }
        return Array.from(accommodationTypeIds)
            .map(id => accommodationTypeMap.get(id))
            .filter(exists)
            .sort(sortBy(item => translate(item.title)));
    }, [organizedGraph.resources, translate]);

    /**
     * Amenities
     * Shared amenities are shared by all resources in the graph
     * Sporadic amenities are only shared by some of the resources in the graph
     */
    const [sharedAmenities, sporadicAmenities]: [
        sharedAmenities: Amenity[],
        sporadicAmenities: Amenity[],
    ] = useMemo(() => {
        const accommodationResources = organizedGraph.resources.filter(
            isResourceAccommodation,
        );
        const totalCount = accommodationResources.length;
        const amenityCounts = new Map<AmenityId, number>();
        const amenities = new Map<AmenityId, Amenity>();
        for (const resource of organizedGraph.resources) {
            if (!isResourceAccommodation(resource)) continue;
            for (const amenity of resource.amenities) {
                amenities.set(amenity._id, amenity);
                const count = amenityCounts.get(amenity._id) ?? 0;
                amenityCounts.set(amenity._id, count + 1);
            }
        }
        const amenityCountsArray = Array.from(amenityCounts.entries());
        const sharedAmenityIds = new Set<AmenityId>();
        const sporadicAmenityIds = new Set<AmenityId>();
        for (const [amenityId, count] of amenityCountsArray) {
            if (count === totalCount) sharedAmenityIds.add(amenityId);
            else sporadicAmenityIds.add(amenityId);
        }
        return [
            Array.from([...sharedAmenityIds])
                .map(id => amenities.get(id))
                .filter(exists)
                .sort(sortBy(item => translate(item.title))),
            Array.from([...sporadicAmenityIds])
                .map(id => amenities.get(id))
                .filter(exists)
                .sort(sortBy(item => translate(item.title))),
        ];
    }, [organizedGraph.resources, translate]);
    const allAmenities = useMemo(
        () => [...sharedAmenities, ...sporadicAmenities], // [...sharedAmenities, ...sporadicAmenities],
        [sharedAmenities, sporadicAmenities],
    );

    const [showGallery, setShowGallery] = useState(false);
    const closeGallery = useCallback(() => {
        setShowGallery(false);
    }, [setShowGallery]);
    const [imageGalleryStartIndex, setImageGalleryStartIndex] = useState(0);
    const onImageClick = useCallback(
        (_: StorageItemImage | ImageSliderImage, index: number) => {
            setImageGalleryStartIndex(index);
            setShowGallery(true);
        },
        [],
    );
    // If the resource ain't a resource group or an accommodation
    // We won't show anything, so we return null
    if (
        !isResourceGroup(rootResource) &&
        !isResourceAccommodation(rootResource)
    ) {
        return null;
    }

    return (
        <FlexColumn crossAxis="stretch">
            <div css={{ flex: 1, height: vh100 * 0.4, maxHeight: vh100 * 0.4 }}>
                <ImageSlider
                    images={images}
                    heightPx={vh100 * 0.4}
                    onImageClick={onImageClick}
                />
            </div>
            <VerticalDivider L />
            <FlexColumn
                crossAxis="stretch"
                css={{
                    paddingLeft: 20,
                    paddingRight: 20,
                }}>
                {/* <FlexRow
                wrap="wrap"
                wrapMargin={5}
                css={{
                    border: "1px solid black",
                    padding: 5,
                    borderRadius: 5,
                }}>
                <ResourceIcon resource={rootResource} />
                <HorizontalDivider />
                <TextBox text={rootResource.meta.title} lineClamp={1} />
            </FlexRow> */}
                <TextBox
                    text={rootResource.meta.title}
                    lineClamp={1}
                    size="XXL"
                    weight="900"
                />
                <Conditional
                    when={!isTranslatableEmpty(rootResource.meta.subtitle)}
                    render={() => (
                        <>
                            <VerticalDivider S />
                            <TextBox
                                text={rootResource.meta.subtitle}
                                size={1.125}
                                color={
                                    theme.isLightTheme
                                        ? theme.colors.white.main.light30
                                              .asString
                                        : theme.colors.white.main.light70
                                              .asString
                                }
                            />
                            <VerticalDivider M />
                            <HorizontalLine />
                            <VerticalDivider M />
                        </>
                    )}
                    else={() => <VerticalDivider M />}
                />
                <Conditional
                    when={
                        accommodationTypes.length === 1 &&
                        allAmenities.length <= 1
                    }
                    render={() => (
                        <FlexRow wrap="wrap">
                            <_AccommodationTypes
                                accommodationTypes={accommodationTypes}
                            />
                            <_Amenities amenities={allAmenities} />
                        </FlexRow>
                    )}
                    else={() => (
                        <>
                            <FlexRow wrap="wrap">
                                <_AccommodationTypes
                                    accommodationTypes={accommodationTypes}
                                />
                            </FlexRow>
                            <VerticalDivider S />
                            <Conditional when={!!allAmenities.length}>
                                <Conditional
                                    when={
                                        !sharedAmenities.length ||
                                        !sporadicAmenities.length
                                    }>
                                    {/* 
                All amenities are either shared by all resources 
                or only shared by some resources 
            */}
                                    <FlexRow wrap="wrap">
                                        <_Amenities amenities={allAmenities} />
                                    </FlexRow>
                                </Conditional>
                                <Conditional
                                    when={
                                        !!sharedAmenities.length &&
                                        !!sporadicAmenities.length
                                    }>
                                    {/*
                Some amenities are shared by all resources
                and some are only shared by some resources
            */}
                                    <FlexRow
                                        mainAxis="space-between"
                                        crossAxis="flex-start">
                                        <FlexColumn
                                            crossAxis="stretch"
                                            css={{
                                                padding: 0,
                                                marginRight: 20,
                                                width: "fit-content",
                                                maxWidth: "50%",
                                            }}>
                                            <TextBox
                                                size={0.625}
                                                weight="400"
                                                case="UPPERCASE"
                                                css={{
                                                    paddingLeft: 5,
                                                    color: theme.colors.black
                                                        .light60.asString,
                                                }}
                                                text={{
                                                    sv: "Ingår för alla",
                                                    en: "Included for all",
                                                }}
                                            />
                                            <VerticalDivider XXS />
                                            <FlexRow wrap="wrap">
                                                <_Amenities
                                                    amenities={sharedAmenities}
                                                />
                                            </FlexRow>
                                        </FlexColumn>
                                        <FlexColumn
                                            crossAxis="stretch"
                                            css={{
                                                flex: 1,
                                                padding: 0,
                                            }}>
                                            <TextBox
                                                size={0.625}
                                                weight="400"
                                                case="UPPERCASE"
                                                css={{
                                                    paddingLeft: 5,
                                                    color: theme.colors.black
                                                        .light60.asString,
                                                }}
                                                text={{
                                                    sv: "Ingår för vissa",
                                                    en: "Included for some",
                                                }}
                                            />
                                            <VerticalDivider XXS />
                                            <FlexRow wrap="wrap">
                                                <_Amenities
                                                    amenities={
                                                        sporadicAmenities
                                                    }
                                                />
                                            </FlexRow>
                                        </FlexColumn>
                                    </FlexRow>
                                </Conditional>
                            </Conditional>
                        </>
                    )}
                />
                <VerticalDivider S />
                <VerticalDivider XXS />
                <Conditional
                    when={!isTranslatableEmpty(rootResource.meta.description)}>
                    <HorizontalLine />
                    <VerticalDivider M />
                    <TextBox
                        text={rootResource.meta.description}
                        renderMarkdown
                        color={
                            theme.isLightTheme
                                ? theme.colors.white.main.light30.asString
                                : theme.colors.white.main.light70.asString
                        }
                    />
                </Conditional>
                <VerticalDivider XXL />
            </FlexColumn>
            <ImageViewer
                close={closeGallery}
                show={showGallery}
                images={images}
                startIndex={imageGalleryStartIndex}
            />
        </FlexColumn>
    );
};

const _AccommodationTypes = (props: {
    readonly accommodationTypes: AccommodationType[];
}) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme = useTheme();
    const accommodationTypes = props.accommodationTypes;
    const pillCss = {
        overflow: "hidden",
        padding: "5px 12px",
        borderRadius: 3000,
        marginRight: 5,
        marginBottom: 5,
        backgroundColor: theme.colors.white.main.light95.asString,
    };
    return (
        <ForEach itemOf={accommodationTypes} getKey={a => a._id}>
            {accommodationType => {
                return (
                    <FlexRow crossAxis="center" css={pillCss}>
                        <DynamicIcon
                            icon={accommodationType.iconName ?? ""}
                            title={accommodationType.title}
                            size={1}
                            fixedWidth
                        />
                        <HorizontalDivider />
                        <TextBox
                            text={accommodationType.title}
                            lineClamp={0.825}
                            weight="700"
                        />
                    </FlexRow>
                );
            }}
        </ForEach>
    );
};
const _Amenities = (props: { readonly amenities: Amenity[] }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme = useTheme();
    const amenities = props.amenities;
    const pillCss = {
        overflow: "hidden",
        padding: "5px 12px",
        borderRadius: 3000,
        marginRight: 5,
        marginBottom: 5,
        backgroundColor: theme.colors.white.main.light95.asString,
    };
    return (
        <ForEach itemOf={amenities} getKey={a => a._id}>
            {amenity => {
                return (
                    <FlexRow crossAxis="center" css={pillCss}>
                        <DynamicIcon
                            icon={amenity.iconName ?? ""}
                            title={amenity.title}
                            size={1}
                            fixedWidth
                        />
                        <HorizontalDivider />
                        <TextBox
                            text={amenity.title}
                            lineClamp={0.825}
                            weight="700"
                        />
                    </FlexRow>
                );
            }}
        </ForEach>
    );
};

const AccommodationInfoModalButton = (props: {
    readonly resource: Resource;
    readonly allResources: Resource[];
}) => {
    const theme = useTheme();
    const [{ show }, modalId] = useModal("accommodation_info__");

    const graph = useMemo(() => {
        if (!isResourceGroup(props.resource)) {
            return null;
        }
        return makeResourceGraph(props.resource, props.allResources);
    }, [props.allResources, props.resource]);

    if (!graph) return null;

    if (!isResourceGroup(props.resource)) {
        return null;
    }

    return (
        <>
            <ButtonTransparent onClick={show} css={{ padding: 0 }}>
                <Icon
                    icon={faInfoCircle}
                    size="XL"
                    colors={{
                        primaryColor: theme.colors.black,
                        secondaryColor: theme.colors.white,
                        secondaryOpacity: 0.8,
                    }}
                />
            </ButtonTransparent>
            <AccommodationInfoModal
                modalId={modalId}
                resource={props.resource}
                graph={graph}
            />
        </>
    );
};

export { AccommodationInfoModal, AccommodationInfoModalButton };
