import type { ModalId } from "@@/modals/context/modal-id";
import { useModal } from "@@/modals/use-modal";
import { browserLogger } from "@@/settings";
import { generateId } from "@towni/common";

import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import type { Except } from "type-fest";

/**
 * Creates a magic modal context.
 *
 * @template AllProps - All props the modal will require.
 * @template ShowProps - The props (keys of `AllProps`) that will be passed to the modal when showing it and not when initiating it.
 * @param ModalComponent - The component that represents the modal.
 * @returns An object containing the MagicModalContextProvider and useMagicModalContext functions.
 */
const createMagicModalContext = <
    /** All props the modal will require */
    AllProps extends { modalId: ModalId },
    /** The props (part of `AllProps`) that will be passed to the modal when showing it and not when initiating it */
    ShowProps extends keyof AllProps = keyof AllProps,
>(
    ModalComponent: React.ComponentType<AllProps>,
) => {
    type ModalShowProps = Pick<AllProps, ShowProps>;
    type ModalInitProps = Except<AllProps, "modalId" | ShowProps> & {
        modalId?: ModalId;
    };

    type ShowModalFn = (props: ModalShowProps) => void;
    type HideModalFn = () => void;

    const MagicModalContext = createContext<
        | {
              show: ShowModalFn;
              hide: HideModalFn;
              modalId: ModalId;
          }
        | undefined
    >(undefined);

    type Props = {
        modalId?: ModalId;
        initProps: ModalInitProps;
        children: React.ReactNode;
    };

    const MagicModalContextProvider = (props: Props) => {
        const [key, _setKey] = useState(generateId);
        const [{ show, hide }, modalId] = useModal(props.modalId);
        const [initialProps] = useState(() => props.initProps);
        const modalPropsRef = useRef<AllProps | undefined>();
        const [triggerShow, setTriggerShow] = useState(false);

        const showModal = useCallback(
            (props: ModalShowProps) => {
                modalPropsRef.current = {
                    ...initialProps,
                    ...props,
                    modalId,
                } as unknown as AllProps;
                setTriggerShow(true);
            },
            [initialProps, modalId],
        );

        useEffect(() => {
            if (triggerShow) {
                if (!modalPropsRef.current) {
                    browserLogger.warn("No modal props, can't render modal");
                    setTriggerShow(false);
                    return;
                }
                show();
                setTriggerShow(false);
                return;
            }
        }, [show, triggerShow]);

        const state = useMemo(
            () => ({ show: showModal, hide, modalId }),
            [showModal, hide, modalId],
        );

        const modalProps = modalPropsRef.current;

        return (
            <MagicModalContext.Provider value={state}>
                {props.children}
                {modalProps ? (
                    // ! Casting to any is a quick fix for the following error:
                    // ! Type '{ key: string; } & AllProps' is not assignable to type 'IntrinsicAttributes & LibraryManagedAttributes<ComponentType<AllProps>, AllProps>'. Type '{ key: string; } & AllProps' is not assignable to type 'LibraryManagedAttributes<ComponentType<AllProps>, AllProps>'
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    <ModalComponent key={key} {...(modalProps as any)} />
                ) : null}
            </MagicModalContext.Provider>
        );
    };

    const useMagicModalContext = () => {
        const state = useContext(MagicModalContext);
        if (!state)
            throw new Error(
                "useMagicModalContext must be used within a MagicModalContext",
            );
        return state;
    };

    return { MagicModalContextProvider, useMagicModalContext };
};

export { createMagicModalContext };
