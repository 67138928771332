import { useCheckoutContext } from "@@/carts/checkout.context";
import { useDeliveryDispatch } from "@@/carts/deliverypicker/delivery-context";
import { useCartContext } from "@@/carts/multi-carts/cart.context";
import { browserLogger } from "@@/settings";
import { useToast } from "@@/toasts/context/toast-context";
import { useQueryClient } from "@tanstack/react-query";
import {
    ApiError,
    CheckoutCommandResponse,
    VerificationError,
    generateWordSlug,
    isApiError,
    isVerificationError,
} from "@towni/common";
import * as React from "react";
import { calculateNewCart } from "./calculate-new-cart";

const useInitCheckout = (props: {
    onVerificationError: (error: VerificationError) => Promise<void> | void;
    onCheckoutError: (error: ApiError) => Promise<void> | void;
}) => {
    // const { contactInfo, checkoutId, onPremise, leaveMyStuffOutside } =
    //     useCheckoutContext(context => ({
    //         contactInfo: context.contactInfo,
    //         checkoutId: context._id,
    //         leaveMyStuffOutside: context.leaveMyStuffOutside,
    //         onPremise: context.onPremise,
    //     }));
    const slug = React.useMemo(() => generateWordSlug(), []);
    const initiateCheckout = useCheckoutContext(
        context => context.initiateCheckout,
    );
    const toast = useToast();

    const { cart, setCart } = useCartContext(context => ({
        cart: context.cart,
        setCart: context.setCart,
    }));
    const { reset } = useDeliveryDispatch();

    const [checkoutData, setCheckoutData] =
        React.useState<CheckoutCommandResponse>();

    const queryClient = useQueryClient();
    React.useEffect(() => {
        browserLogger.info("initiateCheckout", slug);
        if (!checkoutData) {
            initiateCheckout()
                .then(setCheckoutData)
                .catch(error => {
                    browserLogger.error(error);
                    if (isApiError(error)) {
                        void props.onCheckoutError(error);
                        return;
                    }
                    if (isVerificationError(error)) {
                        //try to fix, WHAT?
                        void queryClient
                            .invalidateQueries({
                                queryKey: ["shipping"],
                            })
                            .then(() => {
                                calculateNewCart(
                                    cart,
                                    error,
                                    toast,
                                    queryClient,
                                )
                                    .then(newCart => {
                                        setCart(newCart);
                                        reset(newCart);
                                        void props.onVerificationError(error);
                                    })
                                    .catch(error => {
                                        throw error;
                                    });
                            })
                            .catch(error => {
                                throw error;
                            });
                    }
                    //throw error;
                });
        }

        return () => {
            browserLogger.log("init cleanup", slug);
        };
    }, [checkoutData]);

    return checkoutData;
};

export { useInitCheckout };
