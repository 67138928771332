import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { useModalActions } from "@@/modals/use-modal-actions";
import { ButtonGray } from "@@/shared/buttons_v2/button-gray";
import { ButtonPrimaryLight } from "@@/shared/buttons_v2/button-primary-light";
import { VerticalDivider } from "@@/shared/dividers";
import { HorizontalDivider } from "@@/shared/dividers/horizontal-divider";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Form } from "@@/shared/form/form.zustand-store";
import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { parseZodError } from "@@/shared/form/parse-zod-error";
import { TextBox } from "@@/shared/text/text-box";
import { useTranslate } from "@@/translations/use-translate";
import { useMeFromContext } from "@@/users/use-me-from-context";
import {
    AccommodationSubType,
    AccommodationSubTypeFactoryParams,
    accommodationSubTypeFactory,
    accommodationSubTypeFactoryParamsZodObject,
    translation,
} from "@towni/common";
import { useState } from "react";
const AddAccommodationSubTypeModal = (props: {
    modalId: ModalId;
    onAdd: (subType: AccommodationSubType) => Promise<void> | void;
}) => {
    const me = useMeFromContext();
    const translate = useTranslate();
    const [errors, setErrors] = useState<string[]>([]);
    const { hide } = useModalActions(props.modalId);

    return (
        <Modal
            modalId={props.modalId}
            header={
                <ModalHeader
                    modalId={props.modalId}
                    title={translation({
                        sv: "Lägg till subtyp",
                        en: "Add sub type",
                    })}
                    bottomBorder
                />
            }>
            <Form<AccommodationSubTypeFactoryParams>
                formParams={{
                    clearOnUnmount: true,
                    initializeIfNotExists: {
                        zodObject: accommodationSubTypeFactoryParamsZodObject,
                        initialState: {
                            _createdBy: me._id,
                            iconName: undefined,
                            shortDescription: undefined,
                            title: undefined,
                        },
                    },
                }}>
                {({ FormComponents, ...form }) => {
                    return (
                        <FlexColumn fillParentWidth padding={{ all: 20 }}>
                            <FormComponents.IconNameInput
                                fieldId={"icon"}
                                label={translation({
                                    sv: "Ikon",
                                    en: "Icon",
                                })}
                                getter={state => state.iconName}
                                setter={(state, newValue) => {
                                    state.iconName = newValue;
                                }}
                            />
                            <VerticalDivider M />
                            <FormComponents.TranslatableInput
                                fieldId={"title"}
                                label={translation({
                                    sv: "Titel",
                                    en: "Title",
                                })}
                                getter={state => state.title}
                                setter={(state, newValue) => {
                                    state.title = newValue;
                                }}
                            />
                            <VerticalDivider M />
                            <FormComponents.TranslatableInput
                                fieldId={"shortDescription"}
                                label={translation({
                                    sv: "Kort beskrivning",
                                    en: "Short description",
                                })}
                                getter={state => state.shortDescription}
                                setter={(state, newValue) => {
                                    state.shortDescription = newValue;
                                }}
                            />
                            <VerticalDivider M />
                            <FlexRow
                                fillParentWidth
                                mainAxis="stretch"
                                crossAxis="stretch">
                                <ButtonGray onClick={hide} css={{ flex: 1 }}>
                                    <TextBox
                                        text={translation({
                                            sv: "Avbryt",
                                            en: "Cancel",
                                        })}
                                    />
                                </ButtonGray>
                                <HorizontalDivider />
                                <ButtonPrimaryLight
                                    css={{ flex: 1 }}
                                    onClick={() => {
                                        const subTypeResult = form.safeParse();
                                        if (subTypeResult.success) {
                                            const subType =
                                                accommodationSubTypeFactory(
                                                    subTypeResult.data,
                                                );
                                            void props.onAdd(subType);
                                            hide();
                                            return;
                                        }
                                        setErrors(
                                            parseZodError(
                                                subTypeResult.error,
                                                translate,
                                            ),
                                        );
                                    }}>
                                    <TextBox
                                        text={translation({
                                            sv: "Lägg till",
                                            en: "Add",
                                        })}
                                    />
                                </ButtonPrimaryLight>
                            </FlexRow>
                            <FormErrorMessages errors={errors} />
                        </FlexColumn>
                    );
                }}
            </Form>
        </Modal>
    );
};

export { AddAccommodationSubTypeModal };
