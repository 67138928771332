import {
    asDate,
    DateRange,
    isoAndUnixFactory,
    IsoAndUnixTimestamp,
    getDatesInDateRange,
    IsoTimestamp,
    startOfDayZoned,
} from "@towni/common";
import { createContext, useContext, useMemo, useState } from "react";
import { useTimeZoneContext } from "./time-zone.context";

const FullDateRangeContext = createContext<
    | {
          dateRange: DateRange | undefined;
          datesIso: Set<IsoTimestamp>;
          setDateRange: (dateRange: DateRange | undefined) => void;
          passedBefore: IsoAndUnixTimestamp;
      }
    | undefined
>(undefined);
const FullDateRangeProvider = (props: {
    readonly initialValue: DateRange | undefined;
    /** defaults to today */
    readonly passedBefore?: IsoAndUnixTimestamp;
    readonly children: React.ReactNode;
}) => {
    const { timeZone } = useTimeZoneContext();
    const [dateRange, setDateRange] = useState(() => props.initialValue);
    const passedBefore = useMemo(
        () =>
            isoAndUnixFactory(
                props.passedBefore
                    ? startOfDayZoned(asDate(props.passedBefore), timeZone)
                    : startOfDayZoned(new Date(), timeZone),
            ),
        [props.passedBefore, timeZone],
    );
    const datesIso = useMemo(() => {
        if (!dateRange) return new Set<IsoTimestamp>();
        return new Set<IsoTimestamp>(
            getDatesInDateRange(dateRange).map(item => item.iso),
        );
    }, [dateRange]);

    const state = useMemo(
        () => ({
            dateRange,
            datesIso,
            setDateRange,
            passedBefore,
        }),
        [dateRange, datesIso, setDateRange, passedBefore],
    );

    return (
        <FullDateRangeContext.Provider value={state}>
            {props.children}
        </FullDateRangeContext.Provider>
    );
};

const useFullDateRangeContext = () => {
    const context = useContext(FullDateRangeContext);
    if (!context) throw new Error("No DateRangeContextProvider found");
    return context;
};

export { FullDateRangeProvider, useFullDateRangeContext, FullDateRangeContext };
