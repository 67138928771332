import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { _FormFieldDescription } from "@@/shared/form/_form-field-description";
import { FieldId, FormId } from "@@/shared/form/form-and-field-id";
import { useFormId } from "@@/shared/form/form-id.context";
import { FormErrorMessages } from "@@/shared/form/form2-error-messages";
import { useFormField } from "@@/shared/form/use-form-field";
import { useFormFieldValidation } from "@@/shared/form/use-form-field-validation";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text/text-box";
import { useTheme } from "@emotion/react";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faSquare } from "@fortawesome/pro-light-svg-icons";
import { faLock } from "@fortawesome/pro-regular-svg-icons";
import {
    IconDefinition,
    faCheckSquare,
} from "@fortawesome/pro-solid-svg-icons";
import {
    Color,
    ColorSet,
    RemSize,
    Translatable,
    isTranslatable,
} from "@towni/common";
import { Draft } from "immer";
import { ZodSchema } from "zod";
import { useFormColors } from "./use-form-colors";

// type Value = boolean | undefined | null;

type Props<State, Value extends boolean> = {
    readonly className?: string;
    readonly fieldId: FieldId;
    readonly formId?: FormId;
    readonly getter: (state: Partial<State>) => Value | undefined;
    readonly setter: (
        state: Draft<Partial<State>>,
        newValue: Value | undefined,
    ) => void;
    readonly fieldSchema?: ZodSchema;
    readonly text?: Translatable | JSX.Element;
    readonly size?: RemSize;
    readonly description?: Translatable;
    readonly hideDescriptionAfterInput?: boolean;
    readonly lineClamp?: number;
    readonly disabled?: boolean;
    readonly locked?: boolean;
    readonly spin?: boolean;
    readonly checkedIcon?: IconDefinition;
    readonly uncheckedIcon?: IconDefinition;
    readonly colorSet?: ColorSet;
    /** Defaults to theme.primary */
    readonly accentColor?: Color;
};

const createFormCheckboxComponent = <
    State extends Record<string, unknown>,
>() => {
    const Form2Checkbox = <Value extends boolean>(
        props: Props<State, Value>,
    ) => {
        const theme = useTheme();
        const formIdFromContext = useFormId({ doNotThrow: true });
        const formId = props.formId || formIdFromContext;
        const field = useFormField<State, Value | undefined>({
            fieldId: props.fieldId,
            getter: props.getter,
            setter: props.setter,
            fieldSchema: props.fieldSchema,
            formId: props.formId,
        });
        if (!field)
            throw new Error(
                `Field ${props.fieldId} in form ${formId} not found`,
            );

        const size = props.size ?? (1 as RemSize);
        const disabled = props.disabled || props.spin || field.isSubmitting;
        const hasErrors = field.errors.length > 0;
        const { backgroundColor, textColor } = useFormColors({
            hasErrors,
            colorSet: props.colorSet,
        });
        const accentColor = props.accentColor ?? theme.colors.primary;

        const validationTrigger = useFormFieldValidation<
            State,
            Value | undefined
        >({
            field,
            initialValidationType: "manual",
        });
        const disabledOrLocked = disabled || !!props.locked;
        const cursor = disabledOrLocked ? "default" : "pointer";

        return (
            <FlexColumn
                css={{ flex: 1 }}
                crossAxis="stretch"
                className={props.className}
                tag={props.fieldId}>
                <FlexRow
                    fillParentWidth
                    mainAxis="space-between"
                    crossAxis="center">
                    <label
                        css={{
                            cursor,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                        }}>
                        <input
                            type="checkbox"
                            disabled={disabledOrLocked}
                            onBlur={() => {
                                field.setTouched(true);
                                validationTrigger();
                            }}
                            checked={field.value as Value}
                            onChange={element => {
                                field.setValue(element.target.checked as Value);
                                field.setTouched(true);
                                field.setDirty(
                                    element.target.checked !==
                                        field.initialValue,
                                );
                            }}
                            css={{
                                cursor,
                                opacity: 0,
                                display: "block",
                                height: 0,
                                width: 0,
                                position: "absolute",
                                overflow: "hidden",
                            }}
                        />
                        <span
                            css={{
                                cursor,
                                display: "inline-block",
                            }}>
                            <Icon
                                icon={
                                    props.spin
                                        ? faSpinnerThird
                                        : field.value
                                          ? (props.checkedIcon ?? faCheckSquare)
                                          : (props.uncheckedIcon ?? faSquare)
                                }
                                color={
                                    field.value
                                        ? accentColor
                                        : theme.colors.black.light70
                                }
                                spin={props.spin}
                                style={{
                                    filter: props.locked
                                        ? "grayscale(100%)"
                                        : "",
                                    backgroundColor,
                                    fontSize: size * 1.5 * theme.sizes.base,
                                    opacity: disabledOrLocked ? 0.3 : 1,
                                }}
                            />
                        </span>
                        {isTranslatable(props.text) ||
                        typeof props.text === "undefined" ? (
                            <TextBox
                                text={props.text}
                                size={size}
                                weight={"500"}
                                padding={{ topBottom: 0, left: 8 }}
                                lineClamp={props.lineClamp ?? 1}
                                css={{
                                    color: textColor,
                                    overflow: "hidden",
                                    opacity: disabledOrLocked ? 0.3 : 1,
                                }}
                            />
                        ) : (
                            <>{props.text}</>
                        )}
                    </label>
                    <Conditional when={!!props.locked}>
                        <HorizontalDivider />
                        <Icon icon={faLock} css={{ opacity: 0.5 }} />
                    </Conditional>
                </FlexRow>
                <_FormFieldDescription
                    hasErrors={hasErrors}
                    isDirty={field.dirty}
                    description={props.description}
                    hideDescriptionAfterInput={
                        !!props.hideDescriptionAfterInput
                    }
                    css={{
                        paddingLeft: 0,
                        marginTop: 5,
                        opacity: disabledOrLocked ? 0.5 : 1,
                    }}
                />
                <FormErrorMessages
                    errors={field.errors}
                    css={{
                        opacity: disabledOrLocked ? 0.5 : 1,
                    }}
                />
            </FlexColumn>
        );
    };
    return Form2Checkbox;
};

export { createFormCheckboxComponent };
export type { Props as Form2CheckboxProps };
