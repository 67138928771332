import { TicketSvg } from "@@/orders/order-groups/ticket-svg";
import { Conditional } from "@@/shared/conditional";
import { currentTimezoneName } from "@@/shared/current-timezone-name";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Icon } from "@@/shared/icons/icon";
import { TextBox } from "@@/shared/text";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { faBadgeCheck, faBan } from "@fortawesome/pro-solid-svg-icons";
import {
    Order,
    OrderItem,
    RemSize,
    ResourceReservation,
    Translatable,
    UserReference,
    consolidateResourceRequestsOption,
    formatBookedTime,
    fullName,
    translation,
} from "@towni/common";
import React from "react";
type Props = {
    readonly order: Order;
    readonly orderItem: OrderItem;
    readonly customer: UserReference;
    readonly resourceReservations: ResourceReservation[];
    readonly inactive: boolean;
    readonly inactiveText?: Translatable;
    readonly className?: string;
};

const BuyableReceipt_Ticket = (props: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    const {
        inactive,
        inactiveText,
        order,
        orderItem,
        resourceReservations,
        customer,
    } = props;

    return (
        <React.Fragment key={orderItem._id}>
            <div
                css={{ label: "BuyableReceipt_Ticket" }}
                className={props.className}
                style={{
                    position: "relative",
                    width: "100%",
                    maxWidth: 450,
                    padding: "0 20px",
                }}>
                <TicketSvg width="100%" height="100%" />
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 999,
                        overflowX: "hidden",
                        overflowY: "scroll",
                    }}>
                    <FlexColumn
                        shrink={0}
                        grow={0}
                        fillParent
                        padding={{
                            top: 15,
                            bottom: 20,
                        }}
                        mainAxis="stretch"
                        crossAxis="stretch">
                        <FlexColumn fillParentWidth>
                            <FlexColumn
                                fillParentWidth
                                padding={{
                                    leftRight: 45,
                                }}>
                                <TextBox
                                    text={orderItem.meta.title}
                                    size={1.25 as RemSize}
                                    color={theme.colors.black}
                                    weight="800"
                                />
                                <VerticalDivider />
                                <TextBox
                                    text={formatBookedTime({
                                        time: orderItem.time,
                                        currentTimezoneName:
                                            currentTimezoneName,
                                    })}
                                    color={theme.colors.black.light30}
                                />
                            </FlexColumn>
                        </FlexColumn>
                        <FlexColumn
                            grow={1}
                            shrink={0}
                            padding={{
                                leftRight: 45,
                            }}
                            crossAxis="flex-start"
                            mainAxis="space-between"
                            fillParentWidth>
                            <FlexColumn mainAxis="flex-start">
                                <ForEach
                                    itemOf={consolidateResourceRequestsOption(
                                        resourceReservations,
                                    ).filter(
                                        optionValue =>
                                            optionValue.quantity.value !== 0,
                                    )}>
                                    {optionValue => (
                                        <TextBox
                                            key={optionValue._id}
                                            text={`${
                                                optionValue.quantity.value
                                            } x ${translate(
                                                optionValue.meta.title,
                                            )}`}
                                            size={0.875 as RemSize}
                                            color={theme.colors.black.light30}
                                            weight="700"
                                            padding={{
                                                top: 6,
                                            }}
                                        />
                                    )}
                                </ForEach>
                                {/* {pickedOptional.map(optionValue => {
                                    if (optionValue.quantity.value === 0)
                                        return null;
                                    return (
                                        <TextBox
                                            key={optionValue.optional._id}
                                            text={`${translate(
                                                optionValue.optional.meta.title
                                            )}`}
                                            size={0.875}
                                            color={theme.colors.black.light30}
                                            weight="600"
                                            padding={{
                                                top: 6,
                                            }}
                                        />
                                    );
                                })} */}
                            </FlexColumn>
                            <FlexRow
                                crossAxis="flex-end"
                                mainAxis="space-between"
                                fillParentWidth>
                                <FlexRow
                                    crossAxis="center"
                                    mainAxis="flex-start">
                                    <Icon
                                        size={1.2}
                                        icon={inactive ? faBan : faBadgeCheck}
                                        color={
                                            inactive
                                                ? theme.colors.danger
                                                : theme.colors.primary.main.value.withAlpha(
                                                      0.8,
                                                  )
                                        }
                                    />
                                    <Conditional
                                        when={inactive}
                                        render={() => (
                                            <TextBox
                                                text={inactiveText}
                                                color={theme.colors.danger}
                                                size={1}
                                                padding={{
                                                    left: 5,
                                                }}
                                                weight="900"
                                            />
                                        )}
                                    />
                                </FlexRow>

                                <FlexColumn crossAxis="flex-end" grow={1}>
                                    <TextBox
                                        text={fullName(customer)}
                                        color={theme.colors.black.light10}
                                        size={0.875 as RemSize}
                                        weight="700"
                                        align="right"
                                    />
                                    <TextBox
                                        text={`ref: ${order.orderGroupReference}`}
                                        align="right"
                                        padding={{
                                            top: 3,
                                        }}
                                        size={0.75 as RemSize}
                                        color={theme.colors.black.light30}
                                    />
                                </FlexColumn>
                            </FlexRow>
                        </FlexColumn>
                    </FlexColumn>
                </div>
            </div>
            <Conditional when={!props.order.inactive}>
                <FlexRow fillParentWidth mainAxis="center">
                    <FlexColumn>
                        <TextBox
                            align="center"
                            text={translation({
                                sv: "Den här sidan kan visas för bekräftelse av köp",
                                en: "This page can be shown for purchase confirmation",
                            })}
                            size={0.9 as RemSize}
                            padding={{ top: 10 }}
                            color={theme.colors.white}
                            css={{
                                textShadow: "0 0 50px rgba(0, 0, 0, 0.5)",
                            }}
                        />
                        <VerticalDivider XXS />
                        <TextBox
                            align="center"
                            text={translation({
                                sv: "(länk till denna sida finns i order e-post och sms)",
                                en: "(link to this page can be found in order e-post and text)",
                            })}
                            size={0.75 as RemSize}
                            color={theme.colors.white.main.value.withAlpha(0.5)}
                            css={{
                                textShadow: "0 0 50px rgba(0, 0, 0, 0.75)",
                            }}
                        />
                    </FlexColumn>
                </FlexRow>
            </Conditional>
        </React.Fragment>
    );
};

export { BuyableReceipt_Ticket };
