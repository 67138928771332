import { useTheme } from "@emotion/react";
import {
    autoPlacement,
    autoUpdate,
    FloatingOverlay,
    FloatingPortal,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
} from "@floating-ui/react";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { FloatingBoxActions } from "../floating-box";
import { useContextMenuContext } from "./context-menu-context";

type MenuComponentParams = { actions: FloatingBoxActions };
type MenuComponent = (params: MenuComponentParams) => JSX.Element | null;
type ContextMenuProps<Props extends Record<string, unknown>> =
    MenuComponentParams & Props;

/**
 * @param children - The child element that triggers the context menu on right-click.
 * @param className - Optional class name for the wrapper div.
 */
type ContextMenuWrapperProps = {
    readonly lockScroll?: boolean;
    readonly children: React.ReactNode;
    readonly className?: string;
};

/**
 * A component that renders a context menu when a user right-clicks on its child element.
 * The context menu can be customized by passing a `menu` component and its props.
 *
 * @template MenuProps - The props type for the menu component, which must include `actions`.
 *
 * @param props - The props for the ContextMenu component.
 * @param props.menu - The menu component to be rendered inside the context menu.
 * @param props.menuProps - The props to be passed to the menu component, excluding `actions`.
 * @param props.children - The child element that triggers the context menu on right-click.
 * @param props.className - Optional class name for the wrapper div.
 *
 * @returns A JSX element that wraps the child element and renders the context menu on right-click.
 */
function ContextMenu(
    props: {
        readonly menu: MenuComponent;
    } & ContextMenuWrapperProps,
) {
    const theme = useTheme();
    const xy = useRef<[x: number, y: number]>([0, 0]);
    const [showMenu, setShowMenu] = useState(false);
    const showMenuRef = useRef(showMenu);
    showMenuRef.current = showMenu;

    const altKeyPressedRef = useContextMenuContext().altKeyPressedRef;

    const { refs, floatingStyles, context } = useFloating({
        open: showMenu,
        onOpenChange: (open, _, __) => {
            if (open) return;
            setShowMenu(false);
        },
        whileElementsMounted: autoUpdate,
        middleware: [
            shift({
                crossAxis: true,
                mainAxis: true,
            }),
            autoPlacement({
                allowedPlacements: [
                    "bottom-start",
                    "bottom-end",
                    "top-start",
                    "top-end",
                ],
            }),
        ],
    });

    const handleContextMenu = useCallback(
        (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (altKeyPressedRef.current) {
                altKeyPressedRef.current = false;
                return;
            }

            event.preventDefault();
            xy.current = [event.clientX, event.clientY];
            refs.setPositionReference({
                getBoundingClientRect() {
                    return {
                        width: 0,
                        height: 0,
                        x: event.clientX,
                        y: event.clientY,
                        top: event.clientY,
                        right: event.clientX,
                        bottom: event.clientY,
                        left: event.clientX,
                    };
                },
            });
            setShowMenu(true);
        },
        [altKeyPressedRef, refs],
    );

    const actions: FloatingBoxActions = useMemo(
        () => ({
            close: () => setShowMenu(false),
            open: () => setShowMenu(true),
            toggle: (value?: boolean) =>
                typeof value === "undefined"
                    ? setShowMenu(current => !current)
                    : setShowMenu(value),
        }),
        [setShowMenu],
    );

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        dismiss,
    ]);

    const trigger = props.children;
    const menuFromProps = props.menu;
    const menu = useMemo(
        () => menuFromProps({ actions }),
        [menuFromProps, actions],
    );

    return (
        <>
            <div
                className={props.className}
                ref={refs.setReference}
                {...getReferenceProps()}
                onContextMenu={handleContextMenu}>
                {trigger}
            </div>
            {!showMenu ? null : (
                <>
                    <FloatingPortal>
                        <FloatingOverlay
                            lockScroll={props.lockScroll}
                            css={{
                                zIndex: 1000,
                                // backgroundColor: "lightgoldenrodyellow",
                            }}>
                            <div
                                ref={refs.setFloating}
                                style={floatingStyles}
                                {...getFloatingProps()}
                                css={{
                                    backgroundColor: "white",
                                    borderRadius: 10,
                                    willChange: "filter",
                                    transition: "opacity 0.2s ease-in-out",
                                    filter: `drop-shadow(5px 5px 10px ${theme.colors.black.light75.asString})`,
                                    zIndex: 33,
                                }}>
                                {menu}
                            </div>
                        </FloatingOverlay>
                    </FloatingPortal>
                </>
            )}
        </>
    );
}

export { ContextMenu };
export type {
    ContextMenuProps,
    ContextMenuWrapperProps,
    MenuComponent,
    MenuComponentParams,
};
