import { useTheme } from "@emotion/react";
// import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { useDefaultReactSelectStyles } from "@@/shared/form/use-default-react-select-styles";
import { TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { useTranslate } from "@@/translations/use-translate";
import {
    AccommodationPurpose,
    accommodationPurposeMeta,
    accommodationPurposes,
    isOrderItemAccommodation,
    sortBy,
    translation,
} from "@towni/common";
import ReactSelect from "react-select";
import { useCheckoutContext } from "../checkout.context";

type AccommodationPurposeOption = {
    value: AccommodationPurpose;
    label: string;
};

//TODO MAKE IT NOT DEPENDANT ON useCheckoutDispatch
const AccommodationPurpose_Select = () => {
    const theme = useTheme();
    const translate = useTranslate();

    const hasAccommodationOrderItem = useCheckoutContext(context =>
        context.cart.orderItems.some(item => isOrderItemAccommodation(item)),
    );
    const setAccommodationPurpose = useCheckoutContext(
        context => context.setAccommodationPurpose,
    );

    const options: AccommodationPurposeOption[] = accommodationPurposes
        .map(purpose => {
            return {
                value: purpose,
                label: translate(accommodationPurposeMeta[purpose].title),
            };
        })
        .sort(sortBy(item => item.label));
    const defaultOption: AccommodationPurposeOption | undefined = options.find(
        option => option.value === "LEISURE",
    );

    const reactSelectStyles = useDefaultReactSelectStyles<
        (typeof options)[0],
        false
    >();
    if (!hasAccommodationOrderItem) return null;

    return (
        <FlexColumn
            fillParentWidth
            css={{
                padding: 20,
                backgroundColor: theme.isDarkTheme
                    ? theme.colors.warning.dark.light05.asString
                    : theme.colors.warning.light.light95.asString,
            }}>
            <FieldTitle
                required
                text={translation({
                    sv: "Syfte med bokningen",
                    en: "Purpose of the booking",
                })}
            />
            <VerticalDivider XS />
            <ReactSelect<AccommodationPurposeOption>
                options={options}
                defaultValue={defaultOption}
                styles={reactSelectStyles}
                css={{
                    flex: 1,
                    minWidth: 180,
                }}
                placeholder={translate(
                    translation({
                        sv: "Syfte med bokningen",
                        en: "Purpose of the booking",
                    }),
                )}
                onChange={option => {
                    setAccommodationPurpose(option?.value);
                }}
            />
            <VerticalDivider XS />
            <TextBox
                size={0.825}
                color={theme.colors.white.main.light60.asString}
                text={translation({
                    sv: "Krävs för statistikändamål. Exempelvis kräver SCB att syftet för en boendebokning rapporteras.",
                    en: "Required for statistical purposes. For example, Statistics Sweden requires that the purpose of an accommodation booking is reported.",
                })}
            />
        </FlexColumn>
    );
};

export { AccommodationPurpose_Select };
