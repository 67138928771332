import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { useReservationCheckInMutation } from "@@/shared/statuses/use-reservation-check-in-mutation";
import { useReservationCheckInStatusQuery } from "@@/shared/statuses/use-reservation-check-in-status-query";
import { Paragraph, TextEdit, paragraphLineBreakFactory } from "@@/shared/text";
import { useUrlState } from "@@/shared/use-url-state";
import { useQueryClient } from "@tanstack/react-query";
import {
    ResourceReservationId,
    resourceReservationIdZodSchema,
} from "@towni/common";

const DevToolResCheck = () => {
    const queryClient = useQueryClient();
    const [resourceReservationId, setResourceReservationId] = useUrlState({
        key: "resId",
        schema: resourceReservationIdZodSchema,
    });

    const statusQuery = useReservationCheckInStatusQuery(resourceReservationId);
    const mutation = useReservationCheckInMutation();

    return (
        <FlexColumn
            css={{
                padding: 10,
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "black",
                borderRadius: 5,
            }}>
            <TextEdit
                type="text"
                placeholder={"ResourceReservationId to check in or out"}
                value={resourceReservationId}
                onChange={value => {
                    setResourceReservationId(value as ResourceReservationId);
                }}
            />
            <Paragraph
                css={{
                    fontSize: "0.8rem",
                }}
                content={[
                    statusQuery.isPending ? "Loading..." : "Loaded",
                    paragraphLineBreakFactory(),
                    statusQuery.data?.status ?? "No status",
                    paragraphLineBreakFactory(),
                    paragraphLineBreakFactory(),
                    JSON.stringify(statusQuery.data ?? "No data", null, 4),
                ]}
            />
            <VerticalDivider />
            <FlexRow>
                <ButtonPrimary
                    onClick={() => {
                        if (!resourceReservationId) return;
                        mutation.mutate(
                            {
                                statusToSet: "check-in",
                                resourceReservationId,
                            },
                            {
                                onSuccess: async () => {
                                    await queryClient.invalidateQueries({
                                        queryKey: ["check-in-statuses"],
                                    });
                                },
                            },
                        );
                    }}>
                    Check in
                </ButtonPrimary>
                <HorizontalDivider />
                <ButtonPrimary
                    onClick={() => {
                        if (!resourceReservationId) return;
                        mutation.mutate(
                            {
                                statusToSet: "check-out",
                                resourceReservationId,
                            },
                            {
                                onSuccess: async () => {
                                    await queryClient.invalidateQueries({
                                        queryKey: ["check-in-statuses"],
                                    });
                                },
                            },
                        );
                    }}>
                    Check out
                </ButtonPrimary>
                <HorizontalDivider />
                <ButtonPrimary
                    onClick={() => {
                        if (!resourceReservationId) return;
                        mutation.mutate(
                            {
                                statusToSet: "pending",
                                resourceReservationId,
                            },
                            {
                                onSuccess: async () => {
                                    await queryClient.invalidateQueries({
                                        queryKey: ["check-in-statuses"],
                                    });
                                },
                            },
                        );
                    }}>
                    Set as pending
                </ButtonPrimary>
            </FlexRow>
        </FlexColumn>
    );
};

export { DevToolResCheck };
