import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import {
    asDate,
    dateRangeFactory,
    defaultTimeZone,
    type DurationInSeconds,
    getMonthsAndYearsOf,
    isoAndUnixFactory,
    type IsoAndUnixTimestamp,
    repeatIndex,
    type TimeRange,
} from "@towni/common";
import {
    addDays,
    addMonths,
    differenceInCalendarDays,
    endOfDay,
    isBefore,
    startOfDay,
} from "date-fns";
import * as React from "react";
import {
    DateSelectType,
    useDateSelectFixedLength,
    useDateSelectRange,
    useDateSelectSingle,
} from "./date-select-logic";
import { DateSelectionMonth } from "./date-selection-month";
import { currentTimezoneName } from "@@/shared/current-timezone-name";

type SingleProps = {
    readonly timeRange: TimeRange;
    readonly initiallySelected?: IsoAndUnixTimestamp;
    readonly onSelect: (date: IsoAndUnixTimestamp | undefined) => void;
    readonly duration?: never;
    readonly selectableDates: IsoAndUnixTimestamp[];
};

const DateSelectionVtSingle = (props: SingleProps) => {
    const { initiallySelected, onSelect, timeRange, selectableDates } = props;
    const [selectedDate, setSelectedDate] = useDateSelectSingle({
        onSelect,
        initiallySelected,
    });

    let availableStartDate = asDate(timeRange.start);
    let availableEndDate = asDate(timeRange.end);
    // if start and end as has been switched, switch them back
    if (isBefore(availableEndDate, availableStartDate))
        [availableStartDate, availableEndDate] = [
            availableEndDate,
            availableStartDate,
        ];

    const selectedDates = (selectedDate && [selectedDate]) || [];

    const months = getMonthsAndYearsOf(timeRange, currentTimezoneName).map(
        ({ year, month }, index) => (
            <React.Fragment key={`${year}${month}`}>
                <DateSelectionMonth
                    hideWeekdays={index !== 0}
                    year={year}
                    month={month}
                    selectableDates={selectableDates}
                    selectableDatesForTimeRange={[]}
                    selectedDates={selectedDates}
                    onDateSelect={setSelectedDate}
                    bookedDates={[]}
                />
                <VerticalDivider XL />
            </React.Fragment>
        ),
    );

    return <FlexColumn>{months}</FlexColumn>;
};

type RangeProps = {
    readonly timeRange: TimeRange;
    readonly initiallySelected?: TimeRange;
    readonly onSelect: (
        timeRange: TimeRange | IsoAndUnixTimestamp | undefined,
    ) => void;
    readonly validate: (timeRange: TimeRange) => boolean;
    readonly selectableDates: IsoAndUnixTimestamp[];
    readonly selectableDatesForTimeRange: IsoAndUnixTimestamp[];
    readonly bookedDates: IsoAndUnixTimestamp[];
    //Dates before this date will be marked as passed
    readonly cutoffDate?: IsoAndUnixTimestamp;
};

const DateSelectionVtRange = (props: RangeProps) => {
    const {
        initiallySelected,
        onSelect,
        validate,
        timeRange,
        selectableDates,
        bookedDates,
        selectableDatesForTimeRange,
    } = props;

    const [selectedStartDate, selectedEndDate, toggleDate] = useDateSelectRange(
        {
            onSelect,
            initiallySelected,
            selectableDates,
            validate,
        },
    );

    let availableStartDate = asDate(timeRange.start);
    let availableEndDate = addMonths(asDate(timeRange.end), 1);

    // if start and end as has been switched, switch them back
    if (isBefore(availableEndDate, availableStartDate))
        [availableStartDate, availableEndDate] = [
            availableEndDate,
            availableStartDate,
        ];
    // Calculate selected dates and output as an array
    const selectedDates = !selectedStartDate
        ? []
        : repeatIndex(
              Math.abs(
                  differenceInCalendarDays(
                      startOfDay(asDate(selectedStartDate)),
                      (selectedEndDate && endOfDay(asDate(selectedEndDate))) ||
                          startOfDay(asDate(selectedStartDate)),
                  ),
              ) + 1, //Magic +1
          )
              .reduce((dates, index) => {
                  dates.push(
                      addDays(startOfDay(asDate(selectedStartDate)), index),
                  );
                  return dates;
              }, [] as Date[])
              .map(isoAndUnixFactory);

    const monthsBetween = React.useMemo(() => {
        return getMonthsAndYearsOf(
            dateRangeFactory(availableStartDate, availableEndDate),
            defaultTimeZone,
        );
    }, [availableEndDate, availableStartDate]);

    return (
        <FlexColumn>
            <ForEach
                itemOf={monthsBetween}
                getKey={({ year, month }) => `${year}${month}`}
                divider={<VerticalDivider XL />}>
                {({ year, month }) => (
                    <DateSelectionMonth
                        key={`${year}${month}`}
                        year={year}
                        month={month}
                        selectableDates={selectableDates}
                        selectableDatesForTimeRange={
                            selectableDatesForTimeRange
                        }
                        selectedDates={selectedDates}
                        onDateSelect={toggleDate}
                        bookedDates={bookedDates}
                        hideWeekdays={false}
                        cutoffDate={props.cutoffDate}
                    />
                )}
            </ForEach>
        </FlexColumn>
    );
};

type FixedLengthProps = {
    readonly timeRange: TimeRange;
    readonly initiallySelected?: IsoAndUnixTimestamp;
    readonly onSelect: (timeRange: TimeRange) => void;
    readonly duration: DurationInSeconds;
    selectableDates: IsoAndUnixTimestamp[];
};

const DateSelectionVtFixedLength = (props: FixedLengthProps) => {
    const {
        initiallySelected,
        onSelect,
        duration,
        timeRange,
        selectableDates,
    } = props;
    const [selectedStartDate, selectedEndDate, toggleDate] =
        useDateSelectFixedLength({
            duration,
            onSelect,
            initiallySelected,
        });

    let availableStartDate = asDate(timeRange.start);
    let availableEndDate = asDate(timeRange.end);

    // if start and end as has been switched, switch them back
    if (isBefore(availableEndDate, availableStartDate))
        [availableStartDate, availableEndDate] = [
            availableEndDate,
            availableStartDate,
        ];

    // Calculate selected dates and output as an array
    const selectedDates = !selectedStartDate
        ? []
        : repeatIndex(
              differenceInCalendarDays(
                  asDate(selectedStartDate),
                  (selectedEndDate && asDate(selectedEndDate)) ||
                      asDate(selectedStartDate),
              ),
          )
              .reduce((dates, index) => {
                  dates.push(addDays(asDate(selectedStartDate), index));
                  return dates;
              }, [] as Date[])
              .map(isoAndUnixFactory);

    const months = getMonthsAndYearsOf(timeRange, defaultTimeZone).map(
        ({ year, month }) => (
            <DateSelectionMonth
                key={`${year}${month}`}
                year={year}
                month={month}
                selectableDates={selectableDates}
                selectableDatesForTimeRange={[]}
                selectedDates={selectedDates}
                onDateSelect={toggleDate}
                bookedDates={[]}
            />
        ),
    );

    return <FlexColumn>{months}</FlexColumn>;
};

const dateSelectionVt = (type: DateSelectType) => {
    switch (type) {
        case "SINGLE":
            return DateSelectionVtSingle;
        case "FIXED-LENGTH":
            return DateSelectionVtFixedLength;
        case "RANGE":
            return DateSelectionVtRange;
        default:
            throw new Error("Bad date select type");
    }
};

export {
    dateSelectionVt,
    DateSelectionVtFixedLength,
    DateSelectionVtRange,
    DateSelectionVtSingle,
};
