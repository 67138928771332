import { useActiveSectionItemCountBySectionId } from "@@/sections/use-active-section-item-count";
import { ErrorBoundary } from "@@/shared/error-boundary";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import { motion, Transition, useAnimation } from "framer-motion";
import * as React from "react";
import { useCallback, useEffect } from "react";
import { useContainerNavigationContext } from "../container-navigation.context";
import { AnchorId } from "./anchor-id";

type Props = {
    pageId: string;
    anchorId: AnchorId;
    onClick: () => void;
};

const easeInTransition: Transition = {
    type: "tween",
    ease: "easeIn",
    duration: 0.2,
};
const easeOutTransition: Transition = {
    type: "tween",
    ease: "easeOut",
    duration: 0.2,
};
const ContainerNavigationBarItem = (props: Props) => {
    const theme = useTheme();
    const translate = useTranslate();
    const animationControls = useAnimation();
    const containerNavigation = useContainerNavigationContext(context => ({
        activeAnchorId: context.activeAnchorId,
        navigateToAnchorId: context.navigateToAnchorId,
        anchors: context.anchors,
        anchorOrder: context.anchorOrder,
        setActiveAnchorId: context.setActiveAnchorId,
        setAnchorNavBarItemElement: context.setAnchorNavBarItemElement,
    }));

    const anchor = React.useMemo(
        () => containerNavigation.anchors.get(props.anchorId),
        [props.anchorId, containerNavigation.anchors],
    );

    const [activeContentCount, contentCountIsLoaded] =
        useActiveSectionItemCountBySectionId(anchor?.sectionId);

    const anchorRef = useCallback(
        (anchorElement: HTMLDivElement) => {
            if (!anchor || anchor.navBarItemElement === anchorElement) {
                return;
            }
            containerNavigation.setAnchorNavBarItemElement(anchor._id)(
                anchorElement,
            );
        },
        [anchor],
    );

    const isActive = containerNavigation.activeAnchorId === anchor?._id;
    const isNavigateTarget =
        containerNavigation.navigateToAnchorId === anchor?._id;

    useEffect(() => {
        if (!containerNavigation.activeAnchorId) return;
        if (!isActive) return;

        void animationControls.start({
            color: theme.colors.primary.text.asString,
            transition: easeInTransition,
        });
        // return () => animationControls.stop();
    }, [isActive]);

    useEffect(() => {
        if (
            !containerNavigation.navigateToAnchorId &&
            !containerNavigation.activeAnchorId
        )
            return;
        if (isActive) return;

        // If item is deselected as a navigation target or active
        void animationControls.start({
            color: theme.colors.default.text.asString,
            transition: easeOutTransition,
        });
        // return () => animationControls.stop();
    }, [isNavigateTarget, isActive]);

    if (!anchor) return null;
    if (anchor?.sectionId && !activeContentCount && contentCountIsLoaded) {
        if (isActive && containerNavigation.activeAnchorId) {
            const index = containerNavigation.anchorOrder.findIndex(
                anchorId => anchorId === anchor._id,
            );
            const nextIndex = index + 1;
            const prevIndex = index - 1;

            const toAnchorId =
                containerNavigation.anchorOrder[prevIndex] ??
                containerNavigation.anchorOrder[nextIndex];
            if (toAnchorId) {
                setTimeout(() => {
                    containerNavigation.setActiveAnchorId(toAnchorId);
                }, 0);
            }
        }
        return null;
    }

    const { onClick } = props;
    const { title, description } = anchor;

    return (
        <ErrorBoundary>
            <div
                css={{
                    position: "relative",
                    borderRadius: 15,
                    backgroundColor: "#ffffff",
                    padding: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    userSelect: "none",
                    flexShrink: 0,
                    fontWeight: 700,
                    cursor: "pointer",
                    transform: "translate3d(0, 0, 0)",
                }}
                ref={anchorRef}
                onClick={() => {
                    void animationControls.start({
                        color: theme.colors.default.text.value.withAlpha(0.5)
                            .asString,
                        transition: easeInTransition,
                    });
                    onClick();
                }}>
                {isActive ? (
                    <motion.div
                        layoutId={"selection"}
                        title={translate(description)}
                        initial={false}
                        css={{
                            position: "absolute",
                            display: "block",
                            width: "100%",
                            paddingLeft: 10,
                            paddingRight: 10,
                            backgroundColor: theme.colors.primary.asString,
                            borderRadius: 1000,
                            height: 26,
                            zIndex: -1,
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 500,
                            damping: 50,
                        }}
                    />
                ) : null}
                <motion.div
                    style={{
                        display: "block",
                        position: "relative",
                        zIndex: 10,
                        padding: "5px 10px",
                        fontWeight: 800,
                    }}
                    initial={{
                        color: isActive
                            ? theme.colors.primary.text.asString
                            : theme.colors.default.text.asString,
                    }}
                    animate={animationControls}>
                    {translate(title)}
                </motion.div>
            </div>
        </ErrorBoundary>
    );
};

export { ContainerNavigationBarItem };
