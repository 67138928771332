import { useSessionAvailability } from "@@/routes/products/$productId/_bookable/generic/use-sessions-available";
import { RowSelectButton as ListSelectButton } from "@@/shared/buttons/list-select-button";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Avatar } from "@@/shared/pictures/avatar";
import { TextBox } from "@@/shared/text";
import { DateTimeTextBox } from "@@/shared/text/date-time-text-box";
import { useSizeTracker } from "@@/shared/use-size-tracker";
import { WidthAndHeight } from "@@/shared/width-and-height";
import { useTheme } from "@emotion/react";
import {
    isBookable,
    Padding,
    Product,
    ProductId,
    remSize,
    sortBy,
    translation,
} from "@towni/common";
import { useMemo } from "react";
import { ProductContextMenu } from "../context-menus/product-context-menu";

type Props = {
    product: Product;
    selected: boolean;
    onSizeChange?: (size: WidthAndHeight) => void;
    toggle: (productId: ProductId) => void;
    topElement?: JSX.Element;
    bottomElement?: JSX.Element;
    /**
     * defaults to leftRight: 20
     */
    padding?: Padding;
    disabledIfNoSessions?: boolean;
};

const ProductPickerRow = (props: Props) => {
    const theme = useTheme();
    const { product, selected, toggle, disabledIfNoSessions } = props;

    const [
        _hasAvailableSessions,
        sessions,
        { isLoading: _sessionsAvailabilityIsLoading },
    ] = useSessionAvailability(isBookable(product) ? product : undefined);

    const session = useMemo(() => {
        if (!sessions) return undefined;
        const availableSessions = sessions.sort(sortBy(s => s._createdAt.unix));
        if (availableSessions.length === 0) return undefined;
        return availableSessions[0];
    }, [sessions]);

    // Track size
    const [elementRef] = useSizeTracker(props.onSizeChange);

    const inactive = product.status.type !== "ACTIVE";

    const nonePickable = !!disabledIfNoSessions && !session;

    return (
        <FlexColumn
            fillParentWidth
            ref={elementRef}
            mainAxis="center"
            crossAxis="flex-start"
            padding={{ leftRight: 20, ...(props.padding ?? {}) }}>
            {props.topElement ?? null}
            <ProductContextMenu
                product={product}
                css={{
                    width: "100%",
                }}>
                <FlexRow
                    fillParentWidth
                    background={{
                        color: inactive
                            ? theme.colors.disabled.background
                            : selected
                              ? theme.colors.primary.light
                              : theme.colors.default.background,
                    }}
                    css={{
                        borderRadius: 10,
                        borderStyle: "solid",
                        borderWidth: 1,
                        borderColor: theme.colors.default.border.asString,
                    }}
                    padding={{ all: 15 }}
                    mainAxis="space-between"
                    crossAxis="center"
                    onClick={
                        nonePickable ? undefined : () => toggle(product._id)
                    }>
                    <FlexRow grow={1} shrink={1}>
                        <Avatar
                            title={product.meta.title}
                            image={product.meta.images[0]}
                            size={remSize(2.5)}
                            backgroundColor={theme.colors.primary.main.value.withAlpha(
                                0.6,
                            )}
                            borderColor={theme.colors.primary.main.value.withAlpha(
                                0.6,
                            )}
                        />
                        <HorizontalDivider M />
                        <FlexColumn mainAxis="flex-end">
                            <TextBox
                                text={product.meta.title}
                                color={
                                    inactive
                                        ? theme.colors.disabled.text
                                        : theme.colors.default.text
                                }
                                lineClamp={2}
                                shrink={1}
                                grow={1}
                            />
                            <Conditional when={isBookable(product)}>
                                <Conditional
                                    when={!!session}
                                    else={() => (
                                        <TextBox
                                            text={translation({
                                                sv: "Saknas tider",
                                                en: "No times",
                                            })}
                                            size={0.825}
                                            css={{
                                                opacity: 0.5,
                                                fontWeight: "700",
                                            }}
                                        />
                                    )}>
                                    <DateTimeTextBox
                                        dateTime={session?.start}
                                        size={0.825}
                                        css={{
                                            opacity: 0.5,
                                            fontWeight: "700",
                                        }}
                                    />
                                </Conditional>
                            </Conditional>
                        </FlexColumn>
                        <HorizontalDivider />
                    </FlexRow>
                    <Conditional when={!nonePickable}>
                        <ListSelectButton
                            selected={selected}
                            onClick={event => {
                                event.stopPropagation();
                                toggle(product._id);
                            }}
                        />
                    </Conditional>
                </FlexRow>
            </ProductContextMenu>
            {props.bottomElement ?? null}
        </FlexColumn>
    );
};

export { ProductPickerRow };
