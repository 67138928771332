import { ErrorPage } from "@@/backoffice/shared/error-page";
import { Conditional } from "@@/shared/conditional";
import { ErrorBoundary } from "@@/shared/error-boundary";
import { FlexColumn } from "@@/shared/flex-containers";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { use100vh } from "@@/shared/use-100-vh";
import { useMountEffect } from "@@/shared/use-mount-effect";
import { usePrevious } from "@@/shared/use-previous";
import { useTheme } from "@emotion/react";
import * as Sentry from "@sentry/react";
import { Padding, Percentage, setWithinPercentageRange } from "@towni/common";
import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import { Except } from "type-fest";
import { ModalId } from "./context/modal-id";
import { useModalContext } from "./context/modal.context";
import { ModalBackdrop } from "./modal-backdrop";
import { WithinModalContextProvider } from "./within-modal.context";

type Props = {
    readonly children: React.ReactNode;
    readonly header?: JSX.Element;
    readonly initiallyShow?: boolean;
    readonly modalId: ModalId;
    readonly padding?: Padding;
    readonly width?: React.CSSProperties["width"];
    readonly height?: "full" | Percentage;
    readonly forceFullHeightMode?: boolean;

    //Force the modal to have a minimum height of the max of the viewport height
    readonly forceMinHeight?: boolean;

    readonly disableModalMaxWidth?: boolean;
    readonly ignoreAnimation?: boolean;
    /**
     * defaults to 450px
     * @type {number}
     */
    readonly minWidth?: number;
    /**
     * defaults to 450px
     * @type {number}
     */
    readonly maxWidth?: number;
    readonly className?: string;
    readonly backgroundCloseDisabled?: boolean;
    /** Defaults to "auto" */
    readonly contentOverflowY?: "auto" | "hidden";
    readonly onHide?: () => void;
    readonly onShow?: () => void;
};

const _isFullHeight = (height: "full" | Percentage | undefined) => {
    if (typeof height === "undefined") return false;
    if (height === "full") return true;
    return height >= 0.97;
};

const Modal__ = (
    props: Except<Props, "onShow" | "onHide"> & {
        onHide: () => void;
    },
): React.ReactPortal | null => {
    const theme = useTheme();
    const isMobile = usePageBreakpoint({ when: "📱" });
    const modalMinWidth = props.minWidth ?? props.maxWidth ?? 450;
    const modalMaxWidth = props.maxWidth ?? 450;

    // Modal stuff
    const modalId = props.modalId;
    const modals = useModalContext(context => context.modals);
    const modal = modals.get(props.modalId);
    const portalElementId = useModalContext(context => context.portalElementId);
    const modalSortOrder = useModalContext(context => context.sortOrder);
    const hideModal = useModalContext(context => context.hideModal);
    const isModalVisible = modal?.status === "SHOW";

    // Position stuff
    const viewHeight = use100vh();
    const scrollY = useMemo(() => {
        return window.scrollY;
    }, []);

    // if (!modal) return null;

    const anyFullHeightModalShowing =
        _isFullHeight(props.height) ||
        Array.from(modals.values()).some(
            item => item.isFullHeightModal && item.status === "SHOW",
        ) ||
        props.forceFullHeightMode;

    // const howManyModalsAreShowing = Array.from(modals.values()).filter(
    //     item => item.status === "SHOW",
    // ).length;

    // What to do on click on backdrop
    const onBackdropClick = modal?.backgroundCloseDisabled
        ? undefined
        : props.onHide;

    // What to render in portal
    const Output = (
        <ErrorBoundary>
            <WithinModalContextProvider>
                <div
                    css={{
                        // - full view port size, contains both
                        // - backdrop and modal
                        position: "absolute",
                        pointerEvents: "none",
                        height: viewHeight,
                        width: "100vw",
                        right: 0,
                        left: 0,
                        label: "modal_container_" + props.modalId,
                        zIndex:
                            modalSortOrder.findIndex(
                                id => id === modal?.modalId,
                            ) ?? -1,
                    }}
                    onClick={event => {
                        event.stopPropagation();
                    }}>
                    <AnimatePresence
                        onExitComplete={() => {
                            void hideModal(modalId);
                        }}>
                        <Conditional when={isModalVisible} type="no_render">
                            {/* Backdrop */}
                            <ModalBackdrop
                                modalId={modalId}
                                onClick={onBackdropClick}
                                ignoreAnimation={!!props.ignoreAnimation}
                            />
                            {/* Modal */}
                            <motion.div
                                key={modalId}
                                layoutId={modalId}
                                layout="position"
                                css={{
                                    position: "absolute",
                                    pointerEvents: "none",
                                    whiteSpace: "normal",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent:
                                        isMobile || anyFullHeightModalShowing
                                            ? "flex-end"
                                            : "center",
                                    alignItems: "center",
                                    width: "100%",
                                    height: _isFullHeight(props.height)
                                        ? viewHeight * 0.97
                                        : viewHeight,
                                    bottom: _isFullHeight(props.height)
                                        ? 0
                                        : undefined,
                                    zIndex: 10,
                                    label: "modal_anim_container",
                                }}
                                transition={
                                    props.ignoreAnimation
                                        ? {
                                              duration: 0,
                                          }
                                        : theme.spring
                                }
                                initial={{
                                    y: scrollY + viewHeight * 0.5,
                                    opacity: 0,
                                }}
                                animate={{
                                    y: scrollY,
                                    opacity: 1,
                                }}
                                exit={{
                                    y: scrollY + viewHeight * 0.4,
                                    opacity: 0,
                                }}>
                                <ErrorBoundary>
                                    <FlexColumn
                                        crossAxis="center"
                                        className={props.className}
                                        css={{
                                            width: isMobile
                                                ? "100vw"
                                                : typeof props.width ===
                                                    "number"
                                                  ? `${props.width}px`
                                                  : (props.width ?? "100%"),
                                            pointerEvents: "auto",
                                            overflow: "hidden",
                                            borderRadius: isMobile
                                                ? "10px 10px 0 0"
                                                : "10px",
                                            boxShadow:
                                                "0px 0px 45px 0px rgba(0, 0, 0, 0.35)",
                                            minWidth: isMobile
                                                ? undefined
                                                : props.disableModalMaxWidth
                                                  ? undefined
                                                  : modalMinWidth,
                                            maxWidth: isMobile
                                                ? undefined
                                                : props.disableModalMaxWidth
                                                  ? undefined
                                                  : modalMaxWidth,
                                            height: _isFullHeight(props.height)
                                                ? "100%"
                                                : undefined,
                                            position: "relative",
                                            zIndex: 0,
                                            label: "modal_container",
                                            backgroundColor:
                                                theme.colors.default.background
                                                    .asString,
                                            minHeight: props.forceMinHeight
                                                ? _isFullHeight(props.height)
                                                    ? viewHeight * 0.97
                                                    : viewHeight *
                                                      (typeof props.height ===
                                                      "number"
                                                          ? setWithinPercentageRange(
                                                                props.height,
                                                            )
                                                          : 0.9)
                                                : undefined,

                                            maxHeight: _isFullHeight(
                                                props.height,
                                            )
                                                ? viewHeight * 0.97
                                                : viewHeight *
                                                  (typeof props.height ===
                                                  "number"
                                                      ? setWithinPercentageRange(
                                                            props.height,
                                                        )
                                                      : 0.9),
                                        }}>
                                        {props.header}
                                        <FlexColumn
                                            fillParentWidth
                                            crossAxis="stretch"
                                            overflowY={
                                                props.contentOverflowY ?? "auto"
                                            }
                                            css={{
                                                flex: 1,
                                                label: "modal_content",
                                                position: props.contentOverflowY
                                                    ? "relative"
                                                    : undefined,
                                            }}
                                            padding={props.padding}
                                            height={
                                                _isFullHeight(props.height)
                                                    ? "100%"
                                                    : undefined
                                            }>
                                            <Sentry.ErrorBoundary
                                                beforeCapture={scope => {
                                                    scope.setTag(
                                                        "errorBoundary",
                                                        `Modal ${props.modalId} Children`,
                                                    );
                                                    scope.setTag("fatal", true);
                                                }}
                                                fallback={errorInfo => (
                                                    <ErrorPage
                                                        hideUserAvatar
                                                        error={errorInfo.error}
                                                    />
                                                )}>
                                                {props.children}
                                            </Sentry.ErrorBoundary>
                                        </FlexColumn>
                                    </FlexColumn>
                                </ErrorBoundary>
                            </motion.div>
                        </Conditional>
                    </AnimatePresence>
                </div>
            </WithinModalContextProvider>
        </ErrorBoundary>
    );

    // Render in portal
    const portalElement = document.getElementById(portalElementId);
    if (!portalElement)
        throw new Error(
            "Portal div container can't be found; id: " + portalElementId,
        );
    return ReactDOM.createPortal(Output, portalElement);
};

const Modal = (props: Props) => {
    const modalId = props.modalId;
    const modals = useModalContext(context => context.modals);
    const hideModal = useModalContext(context => context.hideModal);
    const setModal = useModalContext(context => context.setModal);
    const removeModal = useModalContext(context => context.removeModal);

    const modal = useMemo(() => modals.get(modalId), [modalId, modals]);
    const isModalVisible = modal?.status === "SHOW";

    // Parse props
    const { onHide, onShow, ...modalProps } = props;

    // Ref the onHide and onShow functions
    // to make sure we have the latest ones
    // but don't trigger re-renders or
    // re-runs of effects since they most
    // probably often will be new on every run
    const onHideRef = useRef(onHide);
    onHideRef.current = onHide;
    const onShowRef = useRef(onShow);
    onShowRef.current = onShow;

    // To hide the modal we call this function
    // which will call onHide if there is one
    // and then set the modal to hidden in context
    const hide = useCallback(() => {
        onHideRef.current?.();
        void hideModal(modalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hideModal, modalId]);

    useMountEffect(() => {
        // Register modal on mount
        void setModal({
            modalId,
            status: props.initiallyShow ? "SHOW" : "HIDE",
            backgroundCloseDisabled: props.backgroundCloseDisabled,
            isFullHeightModal: _isFullHeight(props.height),
        });
        return () => {
            // unregister modal on unmount
            // (since it doesn't exist anymore)
            void removeModal(modalId);
        };
    });

    // When modal visible status changes
    // do stuff and hide modal if requested
    const prevIsModalVisible = usePrevious(isModalVisible);
    useEffect(() => {
        // If visible status has not changed, do nothing
        if (prevIsModalVisible === isModalVisible) return;
        // When modal status changes to hidden
        // Call onHide in case there is one
        if (!isModalVisible) {
            hide();
            return;
        }

        // When modal status changes to visible
        // Call onShow in case there is one
        onShowRef.current?.();
    }, [hide, isModalVisible, prevIsModalVisible]);

    return <Modal__ key={props.modalId} {...modalProps} onHide={hide} />;
};

export { Modal };
