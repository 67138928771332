// BASED ON https://usehooks.com/useLocalStorage/

import { browserLogger } from "@@/settings/browser-logger";
import { parseSafely } from "@towni/common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { z } from "zod";

const alfredLocalStorageFlagChangeCustomEventName =
    "alfredLocalStorageFlagChanged";
const alfredLocalStorageSettingChangeCustomEventName =
    "alfredLocalStorageSettingChanged";
interface LocalStorageFlagChanged {
    key: string;
    oldValue: boolean;
    newValue: boolean;
}
interface LocalStorageSettingChanged<T extends string | undefined> {
    key: string;
    oldValue: T;
    newValue: T;
}
const hasLocalStorage = typeof window !== "undefined" && window.localStorage;

/**  Store a boolean flag in local storage and sync it between usage of hook */
const useLocalStorageFlag = (
    uniqueKey: string,
    defaultValue: boolean,
): readonly [boolean, (valueToStore: boolean) => void] => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<boolean>(() => {
        if (!hasLocalStorage) {
            return defaultValue;
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(uniqueKey);
            // Parse stored json or if none return initialValue
            const value =
                item === "true"
                    ? true
                    : item === "false"
                      ? false
                      : defaultValue;
            return value;
        } catch (error) {
            // If error also return initialValue
            browserLogger.log(error);
            return defaultValue;
        }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = useCallback(
        (valueToStore: boolean) => {
            try {
                // Save state
                const prevValue = storedValue;
                const newValue = valueToStore;
                setStoredValue(newValue);
                // Save to local storage
                if (hasLocalStorage) {
                    window.localStorage.setItem(
                        uniqueKey,
                        JSON.stringify(newValue),
                    );
                    window.dispatchEvent(
                        new CustomEvent<LocalStorageFlagChanged>(
                            alfredLocalStorageFlagChangeCustomEventName,
                            {
                                detail: {
                                    key: uniqueKey,
                                    oldValue: prevValue,
                                    newValue: newValue,
                                },
                            },
                        ),
                    );
                }
            } catch (error) {
                // A more advanced implementation would handle the error case
                browserLogger.log(error);
            }
        },
        [storedValue, uniqueKey],
    );

    // Listen for changes outside of this hook
    useEffect(() => {
        const handleStorageChange = (event: Event) => {
            const _event = event as CustomEvent<LocalStorageFlagChanged>;
            // We don't care about this value if it's not the key we're looking for
            if (_event.detail.key !== uniqueKey) return;

            // When local storage changes, set the new value in state
            setStoredValue(_event.detail.newValue);
        };

        // Listen for changes to this specific key
        window.addEventListener(
            alfredLocalStorageFlagChangeCustomEventName,
            handleStorageChange,
        );

        return () => {
            // Clean up listener when component unmounts
            window.removeEventListener(
                alfredLocalStorageFlagChangeCustomEventName,
                handleStorageChange,
            );
        };
    }, [defaultValue, setValue, uniqueKey]);

    return useMemo(
        () => [storedValue, setValue] as const,
        [storedValue, setValue],
    );
};

/**
 * Store a string flag in local storage and sync it between usage of hook \
 * NOTE: Do not use this for large objects, rather small setting values
 */
const useLocalStorageSetting = <T extends string | undefined>(
    uniqueKey: string | undefined,
    defaultValue: T,
): readonly [T, (valueToStore: T) => void] => {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        if (!hasLocalStorage || !uniqueKey) {
            return defaultValue;
        }
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(uniqueKey);
            // Parse stored json or if none return initialValue
            const value = item
                ? parseSafely({
                      schema: z.string(),
                      value: item,
                  })
                : defaultValue;
            return value as T;
        } catch (error) {
            // If error also return initialValue
            browserLogger.log(error);
            return defaultValue;
        }
    });
    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = useCallback(
        (valueToStore: T) => {
            try {
                // Save state
                const prevValue = storedValue;
                const newValue = valueToStore;
                setStoredValue(newValue);
                // Save to local storage
                if (hasLocalStorage && uniqueKey) {
                    if (!newValue) window.localStorage.removeItem(uniqueKey);
                    else window.localStorage.setItem(uniqueKey, newValue);
                    window.dispatchEvent(
                        new CustomEvent<LocalStorageSettingChanged<T>>(
                            alfredLocalStorageSettingChangeCustomEventName,
                            {
                                detail: {
                                    key: uniqueKey,
                                    oldValue: prevValue,
                                    newValue: newValue,
                                },
                            },
                        ),
                    );
                }
            } catch (error) {
                // A more advanced implementation would handle the error case
                browserLogger.log(error);
            }
        },
        [storedValue, uniqueKey],
    );

    // Listen for changes outside of this hook
    useEffect(() => {
        const handleStorageChange = (event: Event) => {
            const _event = event as CustomEvent<LocalStorageSettingChanged<T>>;
            // We don't care about this value if it's not the key we're looking for
            if (_event.detail.key !== uniqueKey) return;

            // When local storage changes, set the new value in state
            setStoredValue(_event.detail.newValue);
        };

        // Listen for changes to this specific key
        window.addEventListener(
            alfredLocalStorageSettingChangeCustomEventName,
            handleStorageChange,
        );

        return () => {
            // Clean up listener when component unmounts
            window.removeEventListener(
                alfredLocalStorageSettingChangeCustomEventName,
                handleStorageChange,
            );
        };
    }, [defaultValue, setValue, uniqueKey]);

    return useMemo(
        () => [storedValue, setValue] as const,
        [storedValue, setValue],
    );
};

export {
    alfredLocalStorageFlagChangeCustomEventName,
    alfredLocalStorageSettingChangeCustomEventName,
    useLocalStorageFlag,
    useLocalStorageSetting,
};

export type { LocalStorageFlagChanged, LocalStorageSettingChanged };
