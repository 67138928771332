import { useHomePath } from "@@/backoffice/for-providers/settings/settings/use-home-path";
import { CheckoutContextProvider } from "@@/carts/checkout.context";
import { CartContextProvider } from "@@/carts/multi-carts/cart.context";
import { WithProvider } from "@@/providers/state/queries/provider/with-provider";
import { NavigateTo } from "@@/shared/text/navigate-to";
import { DaftoStoreFront } from "@@/store-fronts/dafto/dafto-store-front";
import { DestinationName, destinationTargets, Provider } from "@towni/common";
import { Navigate } from "react-router-dom";
import { useDestination } from "../shared/destination-context";
import { KronocampingStoreFront } from "../store-fronts/kronocamping/kronocamping-store-front";
import { LackoStoreFront } from "../store-fronts/lacko/lacko-store-front";
import { setCurrentStorefront } from "../store-fronts/shared/current-store-front";
import { StoreFront } from "../store-fronts/towni/store-front";

const StoreFrontByDomainPicker = (props: {
    provider: Provider | undefined;
}) => {
    const providerHomePath = useHomePath(props.provider, false);
    const destinationState = useDestination();

    // If provider home path is undefined
    // use default destination store front
    // console.log(
    //     "STOREFRONT BY DOMAIN PICKER",
    //     destinationState,
    //     providerHomePath,
    //     props.provider
    // );
    switch (destinationState.destinationName) {
        case "daftö":
        case "dafto":
            setCurrentStorefront("daftö");
            if (providerHomePath) return <NavigateTo to={providerHomePath} />;
            else return <DaftoStoreFront />;
        case "lacko":
        case "läckö":
            setCurrentStorefront("läckö");
            if (providerHomePath) return <NavigateTo to={providerHomePath} />;
            else return <LackoStoreFront />;
        case "kronocamping":
            setCurrentStorefront("kronocamping");
            if (providerHomePath) return <NavigateTo to={providerHomePath} />;
            else return <KronocampingStoreFront />;
        default:
            if (providerHomePath) return <NavigateTo to={providerHomePath} />;
            else return <StoreFront />;
    }
};

const domain = window.location.hostname?.toLocaleLowerCase();
const getDestinationNameFromDomain = (): DestinationName => {
    const destinationDomain = domain.split(".")?.[0];
    switch (destinationDomain) {
        case "daftö":
        case "dafto":
            return "dafto";
        case "lacko":
        case "läckö":
            return "lacko";
        case "kronocamping":
        case "kronocamping-old":
            return "kronocamping";
        default:
            return "towni";
    }
};

const MainStoreFront = () => {
    // Get subdomain name or main domain name if no subdomain
    // Return towni if no subdomain or invalid subdomain
    // Return correct store front if subdomain is valid
    const destinationName = getDestinationNameFromDomain();
    const destinationTarget = destinationTargets.get(destinationName);
    if (destinationName === "towni") return <StoreFront />;
    // console.log("MainStoreFront", destinationName, destinationTarget);

    if (destinationTarget?.type === "PROVIDER_HOME") {
        return (
            <WithProvider providerIdOrSlug={destinationTarget.providerSlug}>
                {provider => {
                    return (
                        <CartContextProvider
                            key={provider._id}
                            providerId={provider._id}>
                            <CheckoutContextProvider>
                                <StoreFrontByDomainPicker provider={provider} />
                            </CheckoutContextProvider>
                        </CartContextProvider>
                    );
                }}
            </WithProvider>
        );
    }

    if (destinationTarget?.type === "PATH")
        return <Navigate to={destinationTarget.path} replace />;

    throw new Error("Invalid destination");
};
export { MainStoreFront };
