import { useCart, useCartContext } from "@@/carts/multi-carts/cart.context";
import { PageLoad } from "@@/pages/page-load";
import { useWizardDispatch } from "@@/pages/wizard-context";
import { useBookingAccommodationContext } from "@@/products/accommodations/booking-accommodation-context";
import { useCheckAccommodationAvailability } from "@@/products/accommodations/hooks/use-accommodation-feed";
import { useCreateAccommodationReservationRequests } from "@@/products/accommodations/hooks/use-create-accommodation-reservation-requests";
import { ResourcePickerList } from "@@/products/bookables/booking-wizard/bookable-resource-map-picker/resource-picker-list";
import { useBookableResources } from "@@/resources/resources/use-resources";
import { browserLogger } from "@@/settings";
import {
    blackeningGradient20,
    whiteningGradient20,
} from "@@/shared/bg-lightening-gradient";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { Conditional } from "@@/shared/conditional";
import { HorizontalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { useFindHtmlElementForResourceId } from "@@/shared/resource-maps/use-html-element-resource-map-selection";
import { ZoomableResourceMapView } from "@@/shared/resource-maps/zoomable-resource-map-view";
import { Spacer } from "@@/shared/spacer";
import { TextBox } from "@@/shared/text";
import { usePrevious } from "@@/shared/use-previous";
import { useToast } from "@@/toasts/context/toast-context";
import { useTranslate } from "@@/translations/use-translate";
import { useTheme } from "@emotion/react";
import {
    CapacityHash,
    FlattenedResourceGraphItem,
    Product,
    Provider,
    Resource,
    ResourceGraph,
    ResourceId,
    TimeRange,
    emptyArrayOf,
    findGraphInGraph,
    flattenGraphKeepParents,
    getPossibilities,
    isOrderItemAccommodation_V2,
    isResourceAccommodation,
    minOf,
    translation,
    unique,
} from "@towni/common";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type { ReactZoomPanPinchRef } from "react-zoom-pan-pinch";

type Props1 = {
    product: Product;
    provider: Provider;
};
/**
 * Returns available resources for a product. \
 * `NOTE!` The result doesn't take accommodation capacities into account.
 * That has to be handled separately.
 * @param product
 * @param resourceIds
 * @param timeRange
 * @returns
 */
const useAvailableResourcesForProduct = (
    product: Product,
    resourceIds: ResourceId[],
    timeRange?: TimeRange,
) => {
    // Finds resource graph for product
    const [productResourceGraphs, { isPending: isLoadingResources }] =
        useBookableResources(product._id);

    // Load session resource availability data
    const checkAvailability = useCheckAccommodationAvailability(product);

    const resourcesAvailable = useMemo(() => {
        if (!timeRange) return [];
        // Find availability for current session
        const sessionResourceAvailability = checkAvailability({
            timeRange,
            quantity: 1,
            resourceIds,
            // quantities,
            // ⬆️ We don't need send quantities cause we don't want the
            // check to check for resources only matching the quantities
        });
        // Return available resources for current session
        return sessionResourceAvailability.resourcesAvailability.filter(
            item => item.available,
        );
    }, [checkAvailability, resourceIds, timeRange]);
    // Find available resource graphs for product
    const resourceGraphsWithAvailableResources = useMemo(() => {
        if (!resourcesAvailable?.length) return emptyArrayOf<ResourceGraph>();

        // Filter out graphs that are available
        const resourceGraphsAvailable = productResourceGraphs.filter(graph => {
            for (const resourceAvailable of resourcesAvailable) {
                const result = findGraphInGraph(
                    graph,
                    resourceAvailable.resourceId,
                );
                if (result) return true;
            }
            return false;
        });

        return resourceGraphsAvailable;
    }, [productResourceGraphs, resourcesAvailable]);

    // Flatten all resources in available graphs
    const flattenedAvailableResources = useMemo(() => {
        if (!resourceGraphsWithAvailableResources?.length)
            return emptyArrayOf<FlattenedResourceGraphItem>();
        return resourceGraphsWithAvailableResources?.flatMap(graph => {
            if (graph) return flattenGraphKeepParents(graph);
            return emptyArrayOf<FlattenedResourceGraphItem>();
        });
    }, [resourceGraphsWithAvailableResources]);

    // Sort resources by resource group ids
    const availableResourcesByParent = useMemo(() => {
        const byParent = new Map<ResourceId, Resource[]>();
        if (!flattenedAvailableResources) return byParent;
        const parentIds = flattenedAvailableResources.flatMap(
            item => item.parentIds,
        );
        for (const parentId of parentIds) {
            byParent.set(
                parentId,
                flattenedAvailableResources
                    .filter(item => item.parentIds.includes(parentId))
                    .filter(item =>
                        resourcesAvailable
                            ?.flatMap(ra => ra.availableSubResources)
                            ?.some(ra => ra._id === item.resource._id),
                    )
                    .map(item => item.resource),
            );
        }

        return byParent;
    }, [flattenedAvailableResources, resourcesAvailable]);

    const isLoading = isLoadingResources;

    return [availableResourcesByParent, isLoading] as const;
};

const AccommodationResourcePickerPage = (props: Props1) => {
    const wizardActions = useWizardDispatch();

    const [, bookableResourceQuery] = useBookableResources(props.product._id);
    const isLoadingProductResources = bookableResourceQuery.isPending;
    const productResources =
        bookableResourceQuery.data ?? emptyArrayOf<ResourceGraph>();

    const goNext = () => {
        wizardActions.goForward();
    };

    const cart = useCart();
    const orderItems = cart.orderItems.filter(isOrderItemAccommodation_V2);

    //Picked resource group, does not support multiple
    const pickedResourceGroup = orderItems[0]?.accommodation.resourceId;

    const rootGraph = useMemo(() => {
        const graphs = productResources.flatMap(item => {
            const graph = findGraphInGraph(item, pickedResourceGroup);
            if (!graph) return [];
            return graph;
        });

        return graphs.find(item => item._id === pickedResourceGroup);
    }, [pickedResourceGroup, productResources]);

    if (isLoadingProductResources) {
        return (
            <PageLoad
                text={translation({
                    sv: "Laddar resurser",
                    en: "Loading resources",
                })}
            />
        );
    }

    if (!rootGraph)
        return (
            <PageLoad
                text={translation({
                    sv: "Laddar resurser",
                    en: "Loading resources",
                })}
            />
        );
    return (
        <ResourcePickerPageInternal__
            goNext={goNext}
            rootGraph={rootGraph}
            product={props.product}
        />
    );
};

type Props = {
    product: Product;
    rootGraph: ResourceGraph;
    goNext: () => void;
};

const traverseToFindBestStart = (rootGraph: ResourceGraph): ResourceGraph => {
    //Go deeper if there is no choice
    if (
        rootGraph.children.length === 1 &&
        rootGraph.children[0]._type === "RESOURCE_GRAPH"
    ) {
        const next = rootGraph.children[0];
        if (next._type === "RESOURCE_GRAPH" && next.children.length > 0)
            return traverseToFindBestStart(next);
    }

    return rootGraph;
};

const ResourcePickerPageInternal__ = ({
    rootGraph,
    goNext,
    product,
}: Props) => {
    const theme = useTheme();
    const toast = useToast();
    const translate = useTranslate();
    const zoomableResourceMapViewRef = useRef<ReactZoomPanPinchRef>(null);

    const cart = useCart();
    const cartActions = useCartContext();
    const orderItems = cart.orderItems.filter(isOrderItemAccommodation_V2);
    const selectableQuantity = orderItems[0].quantity.value;
    const quantities = useBookingAccommodationContext(
        state => state.quantities,
    );

    const [availableResourcesMap] = useAvailableResourcesForProduct(
        product,
        [rootGraph.resourceGroup._id],
        orderItems[0].time,
    );
    const createReservationRequests =
        useCreateAccommodationReservationRequests(product);
    const [selectedResources, setSelectedResources] = useState<ResourceId[]>(
        () => cart.reservationRequests.map(r => r.resourceId), //Initial selection
    );
    const saveAndGoNext = () => {
        const item = cart.orderItems.find(isOrderItemAccommodation_V2);
        if (!item) return;

        const time = item.time;
        const quantities = item.quantities;
        const newReservations = createReservationRequests(time, [
            {
                resourceGroupId: item.accommodation.resourceId,
                quantity: item.quantity.value,
                requestedResources: selectedResources,
                quantities: {
                    ADULT:
                        quantities.find(r => r.type.type === "ADULT")?.quantity
                            .value || 0,
                    CHILD:
                        quantities.find(r => r.type.type === "CHILD")?.quantity
                            .value || 0,
                    INFANT:
                        quantities.find(r => r.type.type === "INFANT")?.quantity
                            .value || 0,
                    GUEST:
                        quantities.find(r => r.type.type === "GUEST")?.quantity
                            .value || 0,
                    ANIMAL:
                        quantities.find(r => r.type.type === "ANIMAL")?.quantity
                            .value || 0,
                },
                displayResourceTitle: cart.reservationRequests.some(
                    r => r.displayResourceTitle,
                ),
            },
        ]);
        if (newReservations.length === 0) {
            //Todo: log to sentry? why did this happen?
            toast.danger({
                message: translation({
                    sv: "Det gick inte att skapa reservation",
                    en: "Could not create reservation",
                }),
            });
            cartActions.clearCart();
            return;
        }
        cartActions.setReservationRequests(newReservations);
        goNext();
    };

    const [currentGraph, setCurrentGraph] = useState<ResourceGraph>(() => {
        return traverseToFindBestStart(rootGraph);
    });

    // Resources within the above resource references
    const resourceMapConnection =
        currentGraph.resourceGroup?.resourceMapConnection;

    const findElementForResourceId = useFindHtmlElementForResourceId(
        resourceMapConnection,
    );
    const previousRequestedResources = usePrevious(selectedResources);
    useEffect(() => {
        const additions =
            selectedResources.filter(
                item => !previousRequestedResources?.includes(item),
            ) ?? [];
        const subtractions =
            previousRequestedResources?.filter(
                item => !selectedResources?.includes(item),
            ) ?? [];
        if (subtractions.length) {
            // zoom out
            // if (zoomableResourceMapViewRef.current) {
            //     zoomableResourceMapViewRef.current.resetTransform();
            // }
        }
        if (additions.length) {
            if (zoomableResourceMapViewRef.current) {
                // zoom in on element if we can find it
                const element = findElementForResourceId(additions[0]);
                if (element) {
                    // the elements in the svg doesn't seem to have an offsetWidth and offsetHeight
                    // which seems to be required to to this, check on it later.
                    // just zoom out instead for now
                    // zoomableResourceMapViewRef.current.zoomToElement(element);
                    // zoomableResourceMapViewRef.current.zoomToElement(element);
                    // zoomableResourceMapViewRef.current.resetTransform();
                    return;
                }

                // else just reset zoom to initial values
                // zoomableResourceMapViewRef.current.resetTransform();
            }
        }
    }, [
        findElementForResourceId,
        previousRequestedResources,
        selectedResources,
    ]);

    // Check what resources are available to select
    // and which resources that are required to be selected
    const availableResources = useMemo(
        () =>
            (
                availableResourcesMap.get(currentGraph.resourceGroup._id) ?? []
            ).filter(isResourceAccommodation),
        [availableResourcesMap, currentGraph.resourceGroup._id],
    );
    const possibilities = useMemo(() => {
        return getPossibilities({
            availableResources,
            preferredResourceCombination: selectedResources,
            numberOfResources: selectableQuantity,
            requestedQuantities: quantities,
            translate,
        });
    }, [
        availableResources,
        selectedResources,
        selectableQuantity,
        quantities,
        translate,
    ]);

    const possibleResourceIds = possibilities.possibleResourceIds;
    const requiredResourceIds = useMemo(() => {
        // Find the resourceIds we for a fact can now needs to be selected
        // Their hash need to part of every possible combination
        // And the number of hashes in each of these combinations
        // need to be equal to the number of resources with the hash

        // Loop through all unique hash combinations
        const hashesCounted: Set<CapacityHash> = new Set();
        /** The minimum number of times a resource is required in any of the possible combinations  */
        const minHashCount = new Map<CapacityHash, number>();
        let iterationCount = 0;
        for (const combination of possibilities.possibleUniqueCapacityCombinations) {
            iterationCount += 1;
            const firstIteration = iterationCount === 1;
            /** Count of how many times a hash is used in the current combination */
            const hashCounts = new Map<CapacityHash, number>();

            // For each hash in current combination,
            // count how many times it appears
            for (const capacity of combination) {
                hashCounts.set(
                    capacity.hash,
                    (hashCounts.get(capacity.hash) ?? 0) + 1,
                );
                hashesCounted.add(capacity.hash);
            }

            // For each hash in all hashes counted so far
            // find the minimum number of times it appears
            for (const hash of Array.from(hashesCounted)) {
                const count = hashCounts.get(hash) ?? 0;
                const current = firstIteration // if it's the first iteration, it's never been unused in any combination
                    ? minHashCount.get(hash)
                    : (minHashCount.get(hash) ?? 0); // if it's not the first iteration, it has been unused in a previous combination (that's 0)
                if (typeof current === "undefined")
                    minHashCount.set(hash, count);
                else minHashCount.set(hash, minOf([current, count]));
            }
        }

        /** Contains possible resources grouped by their hash */
        const resourcesByHash = new Map<CapacityHash, Set<ResourceId>>();

        // Loop through all resources and group them by their capacity hash
        for (const resourceId of possibleResourceIds) {
            const resource =
                possibilities.resourcesWithHashMapped.get(resourceId);
            if (!resource) continue; // resource not found. weird.

            const set =
                resourcesByHash.get(resource.capacity.hash) ??
                new Set<ResourceId>();
            set.add(resourceId);
            resourcesByHash.set(resource.capacity.hash, set);
        }

        // Loop through all hashes and check if the resources belonging to
        // the hash are required to be selected
        const requiredResourceIds = new Set<ResourceId>();
        for (const [hash, count] of Array.from(minHashCount.entries())) {
            // If the hash is not part of all combinations
            // then it's not required
            if (count <= 0) continue; // the hash is not required

            // Find the resources with the hash
            const resources =
                resourcesByHash.get(hash) ?? new Set<ResourceId>();
            if (resources.size <= count) {
                // If the number of resources with the hash is equal to
                // the minimum number of times the hash appears in
                // all combinations, then it's required
                Array.from(resources).forEach(resourceId =>
                    requiredResourceIds.add(resourceId),
                );
                //? If there are less, then it's impossible to pick resources that
                //? will match request shouldn't be here in the code if it's
                //? impossible to pick resources that will match request 🤔
            }
        }

        return requiredResourceIds;
    }, [
        possibilities.possibleUniqueCapacityCombinations,
        possibilities.resourcesWithHashMapped,
        possibleResourceIds,
    ]);

    const select = useCallback(
        (resourceId: ResourceId, maxSelect?: number) => {
            // Always make sure that the required resources are included in the selection
            // (if they are less then the maxSelect)
            const requestedIsRequired = requiredResourceIds.has(resourceId);
            const required = [
                ...(requestedIsRequired ? [resourceId] : []),
                ...selectedResources.filter(item =>
                    requiredResourceIds.has(item),
                ),
            ];
            const notRequired = [
                ...(requestedIsRequired ? [] : [resourceId]),
                ...selectedResources.filter(
                    item => !requiredResourceIds.has(item),
                ),
            ];
            const all = unique([...required, ...notRequired]);
            // console.log("💜 SELECT", {
            //     resourceId,
            //     requestedIsRequired,
            //     required,
            //     notRequired,
            //     all,
            //     probableResult: selectedResources.filter(
            //         item => item !== resourceId,
            //     ),
            // });
            setSelectedResources(all.slice(0, maxSelect));
        },
        [selectedResources, requiredResourceIds, setSelectedResources],
    );
    const deselect = useCallback(
        (resourceId: ResourceId) => {
            // console.log("💜 DESELECT", {
            //     resourceId,
            //     required: requiredResourceIds.has(resourceId),
            //     selected: selectedResources.includes(resourceId),
            //     probableResult: selectedResources.filter(
            //         item => item !== resourceId,
            //     ),
            // });
            if (requiredResourceIds.has(resourceId)) return;
            setSelectedResources(
                selectedResources.filter(item => item !== resourceId),
            );
        },
        [requiredResourceIds, selectedResources],
    );
    const goBack = useMemo(() => {
        if (currentGraph.parentId !== rootGraph._id) return undefined;
        return () => {
            setCurrentGraph(
                old => findGraphInGraph(rootGraph, old.parentId) ?? rootGraph,
            );
        };
    }, [currentGraph, rootGraph]);

    useEffect(
        function autoSelectRequiredResourceIds() {
            if (requiredResourceIds.size <= 0) return;
            if (requiredResourceIds.size > selectableQuantity) {
                browserLogger.error(
                    "More resources are required to be selected than the max number of resources selectable, this will not work.",
                );
                // TODO! what to do what to do
                return;
            }
            const unselectedRequiredResourceIds = Array.from(
                requiredResourceIds.values(),
            ).filter(item => !selectedResources.includes(item));

            // Select the required resources
            unselectedRequiredResourceIds.forEach(resourceId => {
                select(resourceId, selectableQuantity);
            });
        },
        [requiredResourceIds, select, selectableQuantity, selectedResources],
    );

    return (
        <FlexColumn
            fillParent
            mainAxis="stretch"
            crossAxis="stretch"
            css={{
                position: "relative",
                backgroundColor: theme.colors.default.background.asString,
                label: "resource-picker-page",
            }}>
            <Conditional
                when={!!resourceMapConnection}
                render={() => (
                    <FlexColumn
                        fillParentWidth
                        tag="map"
                        css={{
                            width: "100%",
                            position: "relative",
                            label: "map",
                            flex: 1.2,
                            maxHeight: "100%",
                        }}>
                        <div
                            css={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                zIndex: 1,
                                overflow: "hidden",
                            }}>
                            <ZoomableResourceMapView
                                ref={zoomableResourceMapViewRef}
                                resourceMapConnection={resourceMapConnection!}
                                maxSelect={selectableQuantity}
                                selectResource={select}
                                deselectResource={deselect}
                                possibleResourceIds={
                                    possibilities.possibleResourceIds
                                }
                                requestedResources={selectedResources}
                            />
                        </div>
                        <div
                            css={{
                                position: "absolute",
                                left: 0,
                                bottom: 0,
                                right: 0,
                                zIndex: 100,
                                pointerEvents: "none",
                                label: "continue_container",
                            }}>
                            <FlexColumn fillParentWidth>
                                <FlexRow
                                    fillParentWidth
                                    mainAxis="flex-end"
                                    background={{
                                        linearGradient: theme.isLightTheme
                                            ? whiteningGradient20
                                            : blackeningGradient20,
                                    }}
                                    padding={{ all: 20, top: 40 }}
                                    crossAxis="center">
                                    <FlexColumn crossAxis="flex-end"></FlexColumn>
                                    <HorizontalDivider M />
                                    <ButtonPrimary
                                        radius={3000}
                                        css={{
                                            pointerEvents: "all",
                                            label: "continue_button",
                                        }}
                                        onClick={saveAndGoNext}
                                        disabled={
                                            !possibilities
                                                .possibleUniqueCapacityCombinations
                                                .length
                                        }>
                                        <TextBox
                                            padding={{
                                                leftRight: 15,
                                                topBottom: 2,
                                            }}
                                            text={translation({
                                                sv: "Välj »",
                                                en: "Select »",
                                            })}
                                        />
                                    </ButtonPrimary>
                                </FlexRow>
                            </FlexColumn>
                        </div>
                    </FlexColumn>
                )}
                else={() => (
                    <FlexRow
                        fillParentWidth
                        mainAxis="space-between"
                        padding={{ all: 20 }}
                        crossAxis="center">
                        <Conditional
                            when={
                                possibilities.isPreferredResourceCombinationPossible
                            }>
                            <Spacer basis={0.2} shrink={0} />
                            <FlexRow
                                mainAxis="center"
                                css={{
                                    height: 35,
                                    backgroundColor:
                                        theme.colors.default.background
                                            .asString,
                                }}>
                                <TextBox
                                    text={{
                                        sv: "Välj i listan",
                                        en: "Select in list",
                                    }}
                                    size={1.125}
                                    weight="900"
                                    color={theme.colors.black.light65}
                                />
                            </FlexRow>
                        </Conditional>
                        <Conditional
                            when={
                                !possibilities.isPreferredResourceCombinationPossible
                            }>
                            <FlexRow css={{ flex: 1, paddingRight: 10 }}>
                                <TextBox
                                    text={translation({
                                        en: "Your selected combination is not possible for the quantity you've chosen. For example, it might not fit the number of people or pets you've requested. If you continue, some picked resource may be switched to accommodate.",
                                        sv: "Din valda kombination är inte möjlig för det antal du valt. Ex, den kanske inte rymmer det antal personer eller husdjur du önskat. Om du fortsätter kan vissa val bytas ut eller läggas till för att passa.",
                                    })}
                                    size="XS"
                                    color={theme.colors.lightGray}
                                />
                            </FlexRow>
                        </Conditional>
                        <ButtonPrimary
                            radius={3000}
                            onClick={() => saveAndGoNext()}
                            //  disabled={!selectionComplete}
                        >
                            <TextBox
                                padding={{
                                    leftRight: 15,
                                    topBottom: 2,
                                }}
                                text={{
                                    sv: "Välj »",
                                    en: "Select »",
                                }}
                            />
                        </ButtonPrimary>
                    </FlexRow>
                )}
            />
            <FlexColumn
                fillParentWidth
                css={{
                    // height: resourceMapConnection ? "50%" : undefined,
                    height: "min-content",
                    maxHeight: resourceMapConnection ? "50%" : "100%",
                }}>
                <ResourcePickerList
                    graph={currentGraph}
                    maxSelect={selectableQuantity}
                    onGraphClick={setCurrentGraph}
                    selectResource={select}
                    deselectResource={deselect}
                    requiredResourceIds={requiredResourceIds}
                    possibleResourceIds={possibilities.possibleResourceIds}
                    requestedResources={selectedResources}
                    hasMap={!!resourceMapConnection}
                    goBack={goBack}
                />
            </FlexColumn>
        </FlexColumn>
    );
};

export { AccommodationResourcePickerPage };
