import { useMe } from "@@/authentication/me/me-context";
import { apiFetchClient } from "@@/shared/fetch-client";
import { useMutation } from "@tanstack/react-query";
import {
    ClassicSignInCommand,
    classicSignInCommandFactory,
    ClassicSignInCommandFactoryParams,
} from "@towni/common";

const classicSignInCommand = async (
    params: ClassicSignInCommandFactoryParams,
) => {
    return await apiFetchClient.post<ClassicSignInCommand>({
        route: `/commands/classic-sign-in`,
        body: classicSignInCommandFactory(params),
    });
};

const useClassicSignInCommand = () => {
    const [, { meQuery }] = useMe();
    return useMutation<unknown, Error, ClassicSignInCommandFactoryParams>({
        mutationFn: classicSignInCommand,
        onError: error => {
            throw error;
        },
        onSuccess: () => {
            meQuery.refetch();
        },
    });
};

export { useClassicSignInCommand };
