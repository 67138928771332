import { VatPicker } from "@@/backoffice/shared/vat-picker";
import { browserLogger } from "@@/settings";
import { ButtonTransparent } from "@@/shared/buttons_v2/button-gray";
import { ButtonPrimary } from "@@/shared/buttons_v2/button-primary";
import { HorizontalDivider, VerticalDivider } from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { ForEach } from "@@/shared/for-each";
import { Form } from "@@/shared/form/form.zustand-store";
import { Icon } from "@@/shared/icons/icon";
import { LayoutCell } from "@@/shared/layout-cell";
import { LayoutGrid } from "@@/shared/layout-grid";
import { SelectBox } from "@@/shared/select-box";
import { PriceTextBox, TextBox } from "@@/shared/text";
import { FieldTitle } from "@@/shared/text/field-title";
import { useTheme } from "@emotion/react";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import {
    GiftCardPriceSettings,
    minOf,
    Percentage,
    percentageZodSchema,
    priceForHumansFactory,
    sortNumerically,
    TranslatableString,
    translation,
    unique,
} from "@towni/common";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { z } from "zod";
import { FormErrorMessages } from "../form2-error-messages";

const _stateZodObject = z.object({
    /**
     * When enabled pricing is enabled to be selected completely free by writing their
     * own amount, if disabled the customer can only select from the available options
     */
    freePricingEnabled: z.boolean(),
    /** The value currently inputted by user into input element (in kr as inputted, not öre/cent e.t.c.) */
    priceInputValue: z.number().int().min(0).optional(),
    /** The default value to auto-pick for user from options (in kr as inputted, not öre/cent e.t.c.) */
    defaultAmountInclVat: z.number().int().min(0).optional(),
    /** If free pricing is enabled, this is the minimal value that can be added (in kr as inputted, not öre/cent e.t.c.) */
    minAmountInclVat: z.number().int().min(1).optional(),
    /** If free pricing is enabled, this is the maximal value that can be added (in kr as inputted, not öre/cent e.t.c.) */
    maxAmountInclVat: z.number().int().min(1).optional(),
    /** The VAT percentage */
    vat: percentageZodSchema,
    /** The available price options for customer to select from (in kr as inputted, not öre/cent e.t.c.) */
    options: z.array(z.number().int().min(0)).min(1),
});

type State = z.infer<typeof _stateZodObject>;

type Props = {
    readonly initialPriceSettings?: GiftCardPriceSettings;
    readonly onChange: (value: State | undefined) => void;
};

/**
 * GiftCardPriceSettingsInput component allows users to configure price settings for a gift card.
 * It provides options for setting a fixed price options and/or enabling free pricing where customers can set their own price within a specified range.
 */
const GiftCardPriceSettingsInput = (props: Props) => {
    const theme = useTheme();
    const initialPriceSettings = props.initialPriceSettings;
    const initialVat = initialPriceSettings?.vat ?? (0.25 as Percentage);
    const onChangeRef = useRef(props.onChange);
    const [errors, setErrors] = useState<TranslatableString[]>([
        // toTranslatableString("Allt är fel"),
        // toTranslatableString("Ingenting är rätt"),
    ]);
    onChangeRef.current = props.onChange;
    const onChange = useCallback(
        (state: Partial<State>) => {
            browserLogger.log("ON CHANGE", state, onChangeRef.current);
            if (!onChangeRef.current) return;
            const vat = state.vat ?? initialVat;
            const parseResult = _stateZodObject.safeParse({
                freePricingEnabled: !!state.freePricingEnabled,
                defaultAmountInclVat: state.defaultAmountInclVat,
                minAmountInclVat: state.minAmountInclVat,
                maxAmountInclVat: state.maxAmountInclVat,
                priceInputValue: state.priceInputValue,
                options: state.options ?? [],
                vat,
            } satisfies State);
            if (parseResult.success) {
                onChangeRef.current(parseResult.data);
                setErrors([]);
            } else {
                setErrors(
                    parseResult.error.issues
                        ?.filter(issue => {
                            const pathJoined = issue.path.join(".");
                            return (
                                pathJoined !== "minAmountInclVat" &&
                                pathJoined !== "maxAmountInclVat"
                            );
                        })
                        .map(issue => {
                            return issue.message as TranslatableString;
                        }) ?? [],
                );
            }
            return;
        },
        [initialVat],
    );

    const formParams = useMemo(() => {
        browserLogger.log("INITIAL PRICE SETTINGS", initialPriceSettings);
        const freePricingEnabled =
            !!props.initialPriceSettings?.freePricingEnabled;
        const minAmountInclVat = props.initialPriceSettings?.minAmountInclVat;
        const maxAmountInclVat = props.initialPriceSettings?.maxAmountInclVat;
        const initialMinAmountInclVat = minAmountInclVat
            ? minAmountInclVat
            : 100;
        const initialMaxAmountInclVat = maxAmountInclVat
            ? maxAmountInclVat
            : 10_000;
        const initialDefaultAmountInclVat =
            props.initialPriceSettings?.defaultAmountInclVat ??
            (props.initialPriceSettings?.freePricingEnabled
                ? initialMinAmountInclVat
                : undefined);
        const initialVat =
            props.initialPriceSettings?.vat ?? (0.25 as Percentage);
        const initialOptions =
            props.initialPriceSettings?.options?.map(
                opt => opt.amountIncludingVat / 100,
            ) ?? [];

        // Make sure initial min is less than or equal to initial max
        if (initialMinAmountInclVat > initialMaxAmountInclVat) {
            throw new Error(
                "The initial minimum amount must be less than or equal to the initial maximum amount",
            );
        }

        return {
            clearOnUnmount: true,
            onFormChange: onChange,
            initializeIfNotExists: {
                zodObject: _stateZodObject,
                initialState: {
                    freePricingEnabled,
                    defaultAmountInclVat: initialDefaultAmountInclVat,
                    minAmountInclVat: initialMinAmountInclVat,
                    maxAmountInclVat: initialMaxAmountInclVat,
                    vat: initialVat,
                    options: initialOptions,
                } satisfies State,
            },
        };
    }, [
        initialPriceSettings,
        props.initialPriceSettings?.minAmountInclVat,
        props.initialPriceSettings?.maxAmountInclVat,
        props.initialPriceSettings?.defaultAmountInclVat,
        props.initialPriceSettings?.freePricingEnabled,
        props.initialPriceSettings?.vat,
        props.initialPriceSettings?.options,
        onChange,
    ]);

    const priceInputRef = useRef<HTMLInputElement | null>(null);

    return (
        <Form formParams={formParams}>
            {({ FormComponents, formState, setFieldValue }) => {
                const checked = formState.state.freePricingEnabled;
                const addPrice = (_event?: React.MouseEvent) => {
                    setFieldValue(draft => {
                        if (!draft.priceInputValue) return;
                        const options = draft.options ? [...draft.options] : [];
                        options.push(draft.priceInputValue);
                        draft.options = unique(options).sort(sortNumerically);

                        // If there is only one option, set it as the customer default
                        if (draft.options.length === 1) {
                            draft.defaultAmountInclVat = draft.options[0];
                        }

                        // If the selected customer default is not available
                        // among the options, (and there are options)
                        if (
                            draft.options.length &&
                            (!draft.defaultAmountInclVat ||
                                !draft.options.includes(
                                    draft.defaultAmountInclVat,
                                ))
                        ) {
                            // set the first option as the default
                            draft.defaultAmountInclVat = draft.options[0];
                        }

                        // Clear the input value
                        draft.priceInputValue = undefined;
                        if (priceInputRef.current) {
                            // ... and clear the input field
                            priceInputRef.current.value = "";
                        }
                    });
                };
                const removePrice = (priceAmount: number) => () => {
                    // Remove the price from the list of
                    // price options, and then..
                    setFieldValue(draft => {
                        draft.options = draft.options?.filter(
                            option => option !== priceAmount,
                        );
                    });
                    //.. if the removed price was the default price
                    // set the first price as the new default
                    if (formState.state.defaultAmountInclVat === priceAmount) {
                        setFieldValue(draft => {
                            draft.defaultAmountInclVat = draft.options?.[0];
                        });
                    }
                };

                return (
                    <FlexColumn fillParentWidth>
                        <FieldTitle
                            text={{
                                sv: "Prissättning",
                                en: "Pricing",
                            }}
                            required
                        />
                        <VerticalDivider />
                        <FlexColumn
                            fillParentWidth
                            crossAxis="stretch"
                            css={{
                                backgroundColor:
                                    theme.colors.textInput.background.asString,
                                padding: 20,
                                border: `1px solid ${theme.colors.textInput.border.asString}`,
                                borderRadius: 5,
                                //         borderBottom: `1px solid ${theme.colors.textInput.border.asString}`,
                            }}>
                            <LayoutGrid
                                css={{
                                    gap: 20,
                                    gridTemplateAreas: checked
                                        ? `"prices prices" "vat vat" "checkbox checkbox" "minPrice maxPrice" "errors errors"`
                                        : `"prices prices" "vat vat" "checkbox checkbox" "errors errors"`,
                                }}>
                                <LayoutCell
                                    css={{
                                        gridArea: "prices",
                                    }}>
                                    <FlexColumn
                                        mainAxis="flex-start"
                                        crossAxis="stretch"
                                        css={{ flex: 1 }}>
                                        {formState.state.options?.length ? (
                                            <>
                                                <TextBox
                                                    css={{ opacity: 0.5 }}
                                                    text={{
                                                        sv: "Priser tillagda som kunden kan välja mellan vid köp. Ett av priserna är valt att vara förvalt för kunden.",
                                                        en: "Prices added that the customer can choose from when purchasing. One of the prices is selected to be the default for the customer.",
                                                    }}
                                                />
                                                <VerticalDivider />
                                            </>
                                        ) : null}
                                        <FlexRow
                                            wrap="wrap"
                                            wrapMargin={5}
                                            css={{ width: "fit-content" }}>
                                            <ForEach
                                                getKey={item => item}
                                                itemOf={
                                                    formState.state.options
                                                }>
                                                {option => (
                                                    <FlexRow
                                                        mainAxis="space-between"
                                                        wrap="nowrap"
                                                        key={option}
                                                        css={{
                                                            width: "fit-content",
                                                            paddingLeft: 10,
                                                            borderRadius: 5,
                                                            boxShadow: `0 0 2px ${theme.colors.black.light80.asString}`,
                                                            backgroundColor:
                                                                theme.colors
                                                                    .white
                                                                    .asString,
                                                        }}>
                                                        <FlexRow
                                                            css={{ flex: 1 }}>
                                                            <SelectBox
                                                                selected={
                                                                    formState
                                                                        .state
                                                                        .defaultAmountInclVat ===
                                                                    option
                                                                }
                                                                size={"M"}
                                                                shape={"CIRCLE"}
                                                                toggle={() => {
                                                                    setFieldValue(
                                                                        draft => {
                                                                            // we won't toggle since we prefer one option to
                                                                            // be selected at all times as the default
                                                                            draft.defaultAmountInclVat =
                                                                                option;
                                                                        },
                                                                    );
                                                                }}
                                                            />
                                                            <HorizontalDivider />
                                                            <PriceTextBox
                                                                price={priceForHumansFactory(
                                                                    {
                                                                        amountIncludingVat:
                                                                            option,
                                                                        vat: formState
                                                                            .state
                                                                            .vat,
                                                                    },
                                                                )}
                                                            />
                                                        </FlexRow>
                                                        <ButtonTransparent
                                                            css={{
                                                                paddingRight: 5,
                                                            }}
                                                            onClick={removePrice(
                                                                option,
                                                            )}>
                                                            <Icon
                                                                icon={faTimes}
                                                                fixedWidth
                                                            />
                                                        </ButtonTransparent>
                                                    </FlexRow>
                                                )}
                                            </ForEach>
                                        </FlexRow>
                                        {formState.state.options?.length ? (
                                            <VerticalDivider M />
                                        ) : null}
                                        <FlexRow
                                            css={{ flex: 1 }}
                                            mainAxis="space-between"
                                            crossAxis="stretch">
                                            <FormComponents.NumberInput
                                                onRef={value =>
                                                    (priceInputRef.current =
                                                        value)
                                                }
                                                colorSet={theme.colors.white}
                                                fieldId={"price"}
                                                css={{ flex: 1 }}
                                                placeholder={
                                                    formState.state.options
                                                        ?.length
                                                        ? translation({
                                                              sv: "Pris att lägga till",
                                                              en: "Price to add",
                                                          })
                                                        : translation({
                                                              sv: "Ange pris",
                                                              en: "Enter price",
                                                          })
                                                }
                                                onKeyDown={event => {
                                                    if (event.key === "Enter") {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                        addPrice();
                                                        priceInputRef.current?.focus();
                                                    }
                                                }}
                                                getter={data =>
                                                    data.priceInputValue
                                                }
                                                setter={(data, newValue) => {
                                                    data.priceInputValue =
                                                        newValue;
                                                }}
                                            />
                                            <HorizontalDivider />
                                            <ButtonPrimary
                                                css={{ minHeight: "100%" }}
                                                contentContainerCss={{
                                                    paddingRight: 10,
                                                }}
                                                disabled={
                                                    !formState.state
                                                        .priceInputValue
                                                }
                                                onClick={addPrice}>
                                                <FlexRow>
                                                    <Icon
                                                        icon={faPlus}
                                                        fixedWidth
                                                    />
                                                    <HorizontalDivider />
                                                    <TextBox
                                                        text={{
                                                            sv: "Lägg till",
                                                            en: "Add",
                                                        }}
                                                    />
                                                </FlexRow>
                                            </ButtonPrimary>
                                        </FlexRow>
                                    </FlexColumn>
                                </LayoutCell>
                                <LayoutCell css={{ gridArea: "checkbox" }}>
                                    <FormComponents.Checkbox
                                        fieldId={"enabled"}
                                        colorSet={theme.colors.white}
                                        accentColor={theme.colors.primary.main}
                                        text={translation({
                                            sv: "Tillåt kund att ange eget pris",
                                            en: "Allow customer to set own price",
                                        })}
                                        description={translation({
                                            sv: "Aktivera för att låta kunden sätta sitt eget pris/värde på presentkortet inom ett angivet prisspann",
                                            en: "Enable to let the customer set their own price/value on the gift card within a specified price range",
                                        })}
                                        getter={data => data.freePricingEnabled}
                                        setter={(data, newValue) => {
                                            data.freePricingEnabled = newValue;
                                        }}
                                    />
                                </LayoutCell>
                                <LayoutCell
                                    css={{
                                        gridArea: "minPrice",
                                        display: checked ? "unset" : "none",
                                    }}>
                                    <FormComponents.NumberInput
                                        fieldId={"minPrice"}
                                        disabled={!checked}
                                        colorSet={theme.colors.white}
                                        label={translation({
                                            sv: "Minpris",
                                            en: "Minimum price",
                                        })}
                                        description={translation({
                                            sv: "Lägsta pris kund kan ange",
                                            en: "The lowest price the customer can choose",
                                        })}
                                        onBlur={() => {
                                            // If the min and max are both numbers
                                            // make sure the min is the min and max is the max
                                            const _min =
                                                formState.state
                                                    .minAmountInclVat;
                                            const _max =
                                                formState.state
                                                    .maxAmountInclVat;
                                            if (
                                                typeof _min === "number" &&
                                                typeof _max === "number"
                                            ) {
                                                const min = minOf([_min, _max]);
                                                // If the min is the min, do nothing
                                                if (_min === min) return;
                                                setFieldValue(draft => {
                                                    // Swap min and max since the min is not the min
                                                    [
                                                        draft.minAmountInclVat,
                                                        draft.maxAmountInclVat,
                                                    ] = [
                                                        draft.maxAmountInclVat,
                                                        draft.minAmountInclVat,
                                                    ];
                                                });
                                            }
                                        }}
                                        getter={data => data.minAmountInclVat}
                                        setter={(data, newValue) => {
                                            data.minAmountInclVat = newValue;
                                        }}
                                    />
                                </LayoutCell>
                                <LayoutCell
                                    css={{
                                        gridArea: "maxPrice",
                                        display: checked ? "unset" : "none",
                                    }}>
                                    <FormComponents.NumberInput
                                        fieldId={"maxPrice"}
                                        disabled={!checked}
                                        colorSet={theme.colors.white}
                                        label={translation({
                                            sv: "Maxpris",
                                            en: "Maximum price",
                                        })}
                                        description={translation({
                                            sv: "Högsta pris kund kan ange",
                                            en: "The highest price the customer can choose",
                                        })}
                                        onBlur={() => {
                                            // If the min and max are both numbers
                                            // make sure the min is the min and max is the max
                                            const _min =
                                                formState.state
                                                    .minAmountInclVat;
                                            const _max =
                                                formState.state
                                                    .maxAmountInclVat;
                                            if (
                                                typeof _min === "number" &&
                                                typeof _max === "number"
                                            ) {
                                                const min = minOf([_min, _max]);
                                                // If the min is the min, do nothing
                                                if (_min === min) return;
                                                setFieldValue(draft => {
                                                    // Swap min and max since the min is not the min
                                                    [
                                                        draft.minAmountInclVat,
                                                        draft.maxAmountInclVat,
                                                    ] = [
                                                        draft.maxAmountInclVat,
                                                        draft.minAmountInclVat,
                                                    ];
                                                });
                                            }
                                        }}
                                        getter={data => data.maxAmountInclVat}
                                        setter={(data, newValue) => {
                                            data.maxAmountInclVat = newValue;
                                        }}
                                    />
                                </LayoutCell>
                                <LayoutCell css={{ gridArea: "vat" }}>
                                    <FlexColumn css={{ flex: 1 }}>
                                        <TextBox
                                            text={{
                                                sv: "Moms",
                                                en: "VAT",
                                            }}
                                        />
                                        <VerticalDivider XS />
                                        <VatPicker
                                            selectedVat={
                                                formState.state.vat ??
                                                initialVat
                                            }
                                            description={translation({
                                                sv: "Vilken momssats ska användas för ett presentkort? Säljer din verksamhet bara produkter med en och samma momssats ska du ange den momssatsen och momsen redovisas direkt. Kan presentkortet användas för flera produkter med olika momssats? Sätt då momsen till 0% och redovisa den när presentkortet används. Vid frågor ta kontakt med din redovisningskonsult eller skatteverket.",
                                                en: "Which VAT rate should be used for a gift card? If your business only sells products with one and the same VAT rate, you should specify that VAT rate and the VAT is reported directly. Can the gift card be used for several products with different VAT rates? Then set the VAT to 0% and report it when the gift card is used. If you have any questions, please contact your accounting consultant or the tax authorities.",
                                            })}
                                            colorSet={theme.colors.white}
                                            onChange={value => {
                                                setFieldValue(draft => {
                                                    draft.vat =
                                                        value as Percentage;
                                                });
                                            }}
                                        />
                                    </FlexColumn>
                                </LayoutCell>
                                <LayoutCell css={{ gridArea: "errors" }}>
                                    <FormErrorMessages errors={errors} />
                                </LayoutCell>
                            </LayoutGrid>
                        </FlexColumn>
                    </FlexColumn>
                );
            }}
        </Form>
    );
};

export { GiftCardPriceSettingsInput };
