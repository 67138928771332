import { useFetchClient } from "@@/shared/fetch-client";
import { QueryFunction, UseQueryResult, useQuery } from "@tanstack/react-query";
import { GetResponse, OrderEvent, OrderId } from "@towni/common";

const useOrderEvents = (
    orderId: OrderId | undefined,
): UseQueryResult<GetResponse<OrderEvent>> => {
    const apiFetchClient = useFetchClient();
    const queryFn: QueryFunction<GetResponse<OrderEvent>> = ({ signal }) => {
        return apiFetchClient.get<GetResponse<OrderEvent>>({
            route: `/orders/order-events/${encodeURIComponent(orderId ?? "")}`,
            customConfig: {
                signal,
            },
        });
    };
    return useQuery<GetResponse<OrderEvent>>({
        queryKey: ["orders", orderId, "events"],
        queryFn,
        enabled: !!orderId,
    });
};

export { useOrderEvents };
