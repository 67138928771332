import { useReducer, useRef } from "react";

type MapExtended<Key extends string, Value> = Map<Key, Value> & {
    reset: () => void;
};
const useMap = <Key extends string, Value>(
    initialMap?: Map<Key, Value>,
): MapExtended<Key, Value> => {
    const initialMapRef = useRef(initialMap);
    const mapRef = useRef<MapExtended<Key, Value>>(
        initialMap
            ? (new Map<Key, Value>(initialMap) as MapExtended<Key, Value>)
            : (new Map<Key, Value>() as MapExtended<Key, Value>),
    );
    const [, reRender] = useReducer(x => (x + 1) % 10, 0);

    mapRef.current.set = (
        ...params: Parameters<NonNullable<typeof initialMap>["set"]>
    ) => {
        Map.prototype.set.apply(mapRef.current, params);
        reRender();
        return mapRef.current;
    };

    mapRef.current.clear = () => {
        Map.prototype.clear.apply(mapRef.current);
        reRender();
    };

    mapRef.current.delete = (key: Key) => {
        const result = Map.prototype.delete.apply(mapRef.current, [key]);
        reRender();
        return result;
    };

    mapRef.current.reset = () => {
        mapRef.current = new Map<Key, Value>(
            initialMapRef.current,
        ) as MapExtended<Key, Value>;
        reRender();
        return mapRef.current;
    };

    return mapRef.current;
};

export { useMap };
export type { MapExtended };
