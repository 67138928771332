import { useHasAlfredScope } from "@@/authentication/me/me-context";
import { ModalId } from "@@/modals/context/modal-id";
import { Modal } from "@@/modals/modal";
import { ModalHeader } from "@@/modals/modal-header";
import { TestModalButton } from "@@/modals/test-modal/test-modal";
import { PreferredUserLanguageSelection } from "@@/settings/preferred-language/preferred-language-selection";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { TextBox } from "@@/shared/text";
import { DevAiFred } from "@@/users/modal/dev-aifred";
import { DevToolResCheck } from "@@/users/modal/dev-tool-res-check";
import { useTheme } from "@emotion/react";
import { translation } from "@towni/common";
import { DebugToggle } from "../../settings/debug/debug-toggle";
import { DoNotToggle } from "./do-not-toggle";

type Props = { modalId: ModalId };
const DevToolsModal = (props: Props) => {
    const theme = useTheme();
    const hasAlfred = useHasAlfredScope();
    if (!hasAlfred) return null;
    return (
        <Modal
            modalId={props.modalId}
            header={
                <ModalHeader
                    modalId={props.modalId}
                    bottomBorder
                    title={translation({
                        sv: "Devtools",
                        en: "Dev tools",
                    })}
                />
            }>
            <FlexColumn fillParent padding={{ all: 20 }}>
                <TextBox
                    size="XS"
                    color={theme.colors.default.text.value.withAlpha(0.5)}
                    text={translation({
                        sv: "Här finner du utvecklarverktyg av alla de slag, just nu finns det bara en debug-toggle. Men tänk vad det i framtiden kan ligga här!",
                        en: "Here you will find developer tools of all kinds, right now there is only a debug toggle. But think what can be here in the future!",
                    })}
                />
                <VerticalDivider M />
                <DebugToggle />
                <VerticalDivider M />
                <PreferredUserLanguageSelection />
                <VerticalDivider M />
                <DevAiFred />
                <VerticalDivider M />
                {/* <ButtonDangerLight
                    onClick={() => {
                        showPTPicker();
                    }}>
                    <TextBox text={"ProductTemplatePicker"} />
                </ButtonDangerLight>
                <VerticalDivider M /> */}
                <DevToolResCheck />
                <VerticalDivider M />
                <DoNotToggle />
                <VerticalDivider M />
                <TestModalButton index={1} />
            </FlexColumn>
            {/* <ProductTemplatePickerModal modalId={ptPickerModalId} /> */}
        </Modal>
    );
};
export { DevToolsModal };
