import { useMe } from "@@/authentication/me/me-context";
import { useProvidersForUser } from "@@/providers/state/queries/providers-for-user/use-providers-for-user";
import {
    createSocketEventListeners,
    useSocket,
} from "@@/shared/sockets/use-socket";
import { OrderSocketEvent, orderSocketEventTypes } from "@towni/common";
import * as React from "react";
/**
 * Listen to realtime order updates for given provider
 */
const useOrderSocketEventsForMe = (params: {
    onOrderEvent: (
        event: OrderSocketEvent,
        room: string | undefined,
    ) => void | Promise<void>;
    verbose?: boolean;
}) => {
    const [me] = useMe();
    const [{ onOrderEvent, verbose }] = React.useState(params);
    const [providersForUser] = useProvidersForUser(me?._id);
    const socketListeners = React.useMemo(() => {
        if (!me) return undefined;
        return [
            ...createSocketEventListeners<OrderSocketEvent>({
                eventTypes: [...orderSocketEventTypes],
                rooms: [
                    me._id,
                    ...providersForUser.map(provider => provider._id),
                ],
                onEvent: onOrderEvent,
            }),
        ];
    }, [me, providersForUser, onOrderEvent]);

    return useSocket(socketListeners, { verbose });
};

export { useOrderSocketEventsForMe };
