import { AppTheme } from "@@/styles/theme";
import { useLocale } from "@@/translations/use-locale";
import { Interpolation, useTheme } from "@emotion/react";
import { useMemo } from "react";
import {
    DeprecatedUI,
    Locale,
    Matcher,
    ModifiersStyles,
    Styles,
} from "react-day-picker";

// TODO! THIS SHOULD PROBABLY BE A SHARED CONTEXT INSTEAD
// TODO! NO NEED FOR IT TO BE ONE PER USE
// TODO! THE ISSUE WITH A CONTEXT IS THOUGH THAT THE THEME
// TODO! WOULD PROBABLY NOT ALWAYS BE THE SAME IF IT WAS IN A CONTEXT
// TODO! SO MAYBE HOOK IS BETTER ANYWAYS..
const useDayPickerDefaults = (): {
    css: Exclude<Interpolation<AppTheme>, string | number | boolean>;
    locale: Locale;
    modifiersStyles: ModifiersStyles;
    modifiers?: Record<string, Matcher | Matcher[] | undefined> | undefined;
    styles: Partial<Styles> & Partial<DeprecatedUI<React.CSSProperties>>;
} => {
    const theme = useTheme();
    const locale = useLocale();

    return useMemo(
        () => ({
            css: {
                "--rdp-accent-color": theme.colors.primary.asString,
                "--rdp-font-family": theme.fontFamily,
                "--rdp-range_start-date-background-color":
                    theme.colors.primary.asString,
                "--rdp-range_end-date-background-color":
                    theme.colors.primary.asString,
                "--rdp-range_start-date-color":
                    theme.colors.primary.text.asString,
                "--rdp-range_end-date-color":
                    theme.colors.primary.text.asString,
                ".rdp-range_start": {
                    background: `linear-gradient(90deg, ${theme.colors.transparent.asString} 50%, ${theme.colors.primary.light.asString} 50%) !important`,
                },
                ".rdp-range_end.rdp-range_start": {
                    background: `${theme.colors.transparent.asString} !important`,
                },
                ".rdp-range_end:not(.rdp-range_start)": {
                    background: `linear-gradient(270deg, ${theme.colors.transparent.asString} 50%, ${theme.colors.primary.light.asString} 50%) !important`,
                },
                ".rdp-range_start .rdp-selected": {
                    backgroundColor: "purple",
                },
                ".rdp-range_start > .rdp-day_button": {
                    borderRadius: 3000,
                },
                ".rdp-range_end > .rdp-day_button": {
                    borderRadius: 3000,
                },
                ".rdp-caption_label": {
                    paddingLeft: 13,
                },
            },
            locale,
            modifiersStyles: {
                today: {
                    backgroundColor: theme.colors.blue.light.asString,
                    color: theme.colors.blue.asString,
                    textDecoration: "underline",
                    fontWeight: 400,
                },
                selected: {
                    // THIS IS NOT THE DEFAULT FOR A DATE RANGE
                    // BUT FOR ALL OTHERS, OVERRIDE IF NEEDED
                    backgroundColor: theme.colors.primary.asString,
                    borderRadius: 3000,
                    borderColor:
                        theme.colors.primary.border.withAlpha(0.5).asString,
                    color: theme.colors.primary.text.asString,
                    fontWeight: 400,
                },
                range_start: {
                    backgroundColor: theme.colors.primary.asString,
                    color: theme.colors.primary.text.asString,
                    fontWeight: 400,
                },
                range_end: {
                    backgroundColor: theme.colors.primary.asString,
                    color: theme.colors.primary.text.asString,
                    fontWeight: 400,
                },
                range_middle: {
                    backgroundColor: theme.colors.primary.light.asString,
                    color: theme.colors.primary.text.asString,
                    fontWeight: 400,
                },
            },
            styles: {
                day: {
                    borderRadius: 3000,
                    width: 40,
                    height: 40,
                    fontSize: "1em",
                },
                day_button: {
                    width: 40,
                    height: 40,
                    borderColor: theme.colors.transparent.asString,
                    borderWidth: 1,
                },
                week: {
                    borderTop: `1px solid ${theme.colors.white.asString}`,
                    borderBottom: `1px solid ${theme.colors.white.asString}`,
                },
            },
        }),
        [theme, locale],
    );
};

export { useDayPickerDefaults };
