import { closestCenter, DndContext, DragEndEvent } from "@dnd-kit/core";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import { useTheme } from "@emotion/react";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import {
    ImageReference,
    ImageReferenceId,
    Padding,
    Percentage,
    RemSize,
    setWithinPercentageRange,
    StorageItemImage,
    StorageItemImageId,
    translation,
} from "@towni/common";
import { FlexRow } from "../../flex-containers";
import { ForEach } from "../../for-each";
import { Icon } from "../../icons/icon";
import { LayoutGrid } from "../../layout-grid";
import { ImageV2 } from "../../pictures/image";
import { Sortable } from "../../pictures/sortable";
import { moveWithinArrayImmutable } from "../move-within-array";

type Props<
    ImageId extends StorageItemImageId | ImageReferenceId =
        | StorageItemImageId
        | ImageReferenceId,
    ImageRef extends StorageItemImage | ImageReference =
        | StorageItemImage
        | ImageReference,
> = {
    imageSize: RemSize;
    items: ImageRef[];
    reorder: (order: ImageId[]) => void;
    remove?: (itemId: ImageId) => void;
    opacity?: Percentage;
    padding?: Padding;
};

const FormImageGallery = <
    ImageId extends StorageItemImageId | ImageReferenceId =
        | StorageItemImageId
        | ImageReferenceId,
    ImageRef extends ImageReference | StorageItemImage =
        | ImageReference
        | StorageItemImage,
>(
    props: Props<ImageId, ImageRef>,
) => {
    const theme = useTheme();
    const handleDragEnd = ({ active, over }: DragEndEvent) => {
        if (!over) return;
        if (active.id !== over?.id) {
            const from = props.items.findIndex(item => item._id === active.id);
            const target = props.items.findIndex(item => item._id === over.id);
            props.reorder(
                moveWithinArrayImmutable(
                    props.items.map(item => item._id as ImageId),
                    from,
                    target,
                ),
            );
        }
    };

    return (
        // TODO! ADD DRAG OVERLAY
        <DndContext
            onDragEnd={handleDragEnd}
            collisionDetection={closestCenter}>
            <SortableContext
                items={props.items.map(item => item._id)}
                strategy={rectSortingStrategy}>
                <LayoutGrid
                    fillParentWidth
                    gap={10}
                    gridAutoFlow="row"
                    padding={props.padding}
                    gridTemplateColumns={`repeat(auto-fill, minmax(${
                        props.imageSize * theme.sizes.base
                    }px, 1fr))`}
                    css={{
                        pointerEvents: "all",
                        opacity: setWithinPercentageRange(props.opacity ?? 1),
                    }}>
                    <ForEach itemOf={props.items} getKey={item => item._id}>
                        {(storageItem, index) => {
                            const isFirst = index === 0;
                            return (
                                <Sortable
                                    key={storageItem._id}
                                    id={storageItem._id}
                                    style={{
                                        // note: this below is to make the grid
                                        // note: a responsive square grid
                                        width: "100%",
                                        paddingBottom: "120%",
                                        overflow: "hidden",
                                        position: "relative",
                                        willChange: "filter",
                                        filter: "drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2))",
                                        border:
                                            "9px solid " +
                                            (isFirst
                                                ? theme.colors.white.asString
                                                : theme.colors.white.main
                                                      .light95.asString),
                                        borderBottomWidth: 3,
                                        borderRadius: theme.radius + 2,
                                        backgroundColor: isFirst
                                            ? theme.colors.white.asString
                                            : theme.colors.white.main.light95
                                                  .asString,
                                    }}>
                                    <ImageV2
                                        imageSource={storageItem}
                                        title={
                                            storageItem?.originalFilename ??
                                            translation({
                                                sv: "(inget namn)",
                                                en: "(no name)",
                                            })
                                        }
                                        radius={theme.radius + 2}
                                        // topRadius={theme.radius}
                                        style={{
                                            // note: this is also part of it
                                            position: "absolute",
                                            width: "100%",
                                            height: "80%",
                                        }}
                                    />
                                    <div
                                        style={{
                                            position: "absolute",
                                            backgroundColor: isFirst
                                                ? theme.colors.white.asString
                                                : theme.colors.white.main
                                                      .light95.asString,
                                            width: "100%",
                                            height: "20%",
                                            top: "80%",
                                        }}>
                                        <FlexRow
                                            fillParent
                                            mainAxis="flex-end"
                                            padding={{ leftRight: 3 }}
                                            crossAxis="center">
                                            <div
                                                onMouseDown={event => {
                                                    event.stopPropagation();
                                                    props.remove?.(
                                                        storageItem._id as ImageId,
                                                    );
                                                }}
                                                css={{
                                                    pointerEvents: "all",
                                                    padding: 1,
                                                    borderRadius: 3000,
                                                    backgroundColor:
                                                        theme.colors.white
                                                            .asString,
                                                }}>
                                                <Icon
                                                    icon={faTimes}
                                                    fixedWidth
                                                    size={1 as RemSize}
                                                    color={theme.colors.black}
                                                    css={{
                                                        pointerEvents: "all",
                                                    }}
                                                />
                                            </div>
                                        </FlexRow>
                                    </div>
                                </Sortable>
                            );
                        }}
                    </ForEach>
                </LayoutGrid>
            </SortableContext>
        </DndContext>
    );
};

export { FormImageGallery };
