import { IsoTimestamp } from "@towni/common";

/**
 * Contains meta data about a dates selectability as part or end of range.
 * If it is possible be used as part or end of range.
 *
 * @property `possible` is true if the date can be used as part or end of the range.
 * @property `withStartAt` contains a set of dates that it can be used as a part or end of range with.
 */
type EndOrPartOfRangeStatus =
    | { possible: true; withStartAt: Set<IsoTimestamp> }
    | { possible: false; withStartAt?: never };

/**
 * Creates an EndOrPartOfRangeStatus object based on the provided set of start timestamps.
 * If the set is empty, the object will have `possible` set to false.
 *
 * If the set is not empty, the object will have `possible` set to true and `withStartAt` set to the provided set of range start timestamps.
 *
 * @param withStartAt - The set of start timestamps.
 * @returns The EndOrPartOfRangeStatus object.
 */
const endOrPartOfRangeStatusFactory = (
    withStartAt: Set<IsoTimestamp>,
): EndOrPartOfRangeStatus => {
    if (withStartAt.size)
        return {
            possible: true,
            withStartAt,
        };

    return {
        possible: false,
        withStartAt: undefined,
    };
};

export { endOrPartOfRangeStatusFactory };
export type { EndOrPartOfRangeStatus };
