import { ModalId } from "@@/modals/context/modal-id";
import { createMagicModalContext } from "@@/modals/magic-modal.context";
import { Modal } from "@@/modals/modal";
import { useModalActions } from "@@/modals/use-modal-actions";
import { WizardProvider } from "@@/pages/wizard-context";
import { FlexColumn } from "@@/shared/flex-containers";
import { BookableSessionId, OrderGroupId, OrderId } from "@towni/common";
import React from "react";
import {
    BookingWizardProps,
    BookingWizardWithoutWizardContext,
} from "./booking-wizard";
import { BookingWizardModalHeader } from "./booking-wizard-modal-header";

type Props = BookingWizardProps & {
    modalId: ModalId;
    onCheckoutComplete?: (params: {
        bookedSessionId?: BookableSessionId;
        orderGroupId: OrderGroupId;
        orderId: OrderId;
    }) => void;
    onHide?: () => void;
};

const BookingWizardModal = (props: Props) => {
    const { hide } = useModalActions(props.modalId);

    return (
        <WizardProvider ignoreAnimation={false}>
            <Modal
                modalId={props.modalId}
                forceFullHeightMode
                height={"full"}
                header={
                    <BookingWizardModalHeader
                        buyer={props.buyer}
                        modalId={props.modalId}
                    />
                }
                onHide={props.onHide}>
                <FlexColumn
                    fillParent
                    css={{
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        position: "relative",
                        label: "booking_wizard_modal_container",
                    }}
                    crossAxis="stretch"
                    mainAxis="flex-start"
                    overflow="hidden">
                    <FlexColumn
                        crossAxis="stretch"
                        mainAxis="flex-start"
                        overflowY={"hidden"}
                        tag="booking_wiz_without_context_wrapper"
                        grow={1}>
                        {props.product ? (
                            <BookingWizardWithoutWizardContext
                                disablePageAnimation={true}
                                product={props.product}
                                provider={props.provider}
                                buyer={props.buyer}
                                sessionId={props.sessionId}
                                preferredResourceId={props.preferredResourceId}
                                onCheckoutComplete={params => {
                                    if (props.onCheckoutComplete)
                                        props.onCheckoutComplete(params);
                                    hide();
                                }}
                            />
                        ) : null}
                    </FlexColumn>
                </FlexColumn>
            </Modal>
        </WizardProvider>
    );
};

const {
    MagicModalContextProvider,
    useMagicModalContext: useBookingWizardModalContext,
} = createMagicModalContext<
    Props,
    | "onCheckoutComplete"
    | "onHide"
    | "product"
    | "provider"
    | "buyer"
    | "disablePageAnimation"
    | "modalEmbedded"
    | "sessionId"
    | "preferredResourceId"
    | "modalHeader"
    | "wizardElement"
>(BookingWizardModal);

const BookingWizardModalContextProvider = (props: {
    readonly children: React.ReactNode;
}) => {
    return (
        <MagicModalContextProvider initProps={{}}>
            {props.children}
        </MagicModalContextProvider>
    );
};

export {
    BookingWizardModal,
    BookingWizardModalContextProvider,
    useBookingWizardModalContext,
};
export type { Props as BookingWizardModalProps };
