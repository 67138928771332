import { useHomePath } from "@@/backoffice/for-providers/settings/settings/use-home-path";
import { darkeningGradient } from "@@/shared/bg-darkening-gradient";
import { VerticalDivider } from "@@/shared/dividers";
import { FlexColumn } from "@@/shared/flex-containers";
import { useDimensions } from "@@/shared/section-area/card-size";
import { SectionAreaSizedCard } from "@@/shared/section-area/section-area-card";
import { TextBox } from "@@/shared/text";
import { LinkTo } from "@@/shared/text/link-to";
import { useTranslate } from "@@/translations/use-translate";
import { css, useTheme } from "@emotion/react";
import {
    AspectRatio,
    Provider,
    SizeName,
    asDate,
    getProviderStatus,
    translation,
} from "@towni/common";
import { isFuture } from "date-fns";
import { FC } from "react";

type Props = {
    provider: Provider;
};
const ProviderCard: FC<Props> = ({ provider }) => {
    const theme = useTheme();
    const translate = useTranslate();
    const getDimensions = useDimensions();

    const posterImage = provider.meta.images[0];
    const providerStatus = getProviderStatus(provider);

    const isHidden = providerStatus === "HIDDEN";
    const isPaused =
        provider.options.paused &&
        isFuture(asDate(provider.options.paused?.until));

    const pauseReasonTitle = isHidden
        ? translate(
              translation({
                  sv: "gömd",
                  en: "hidden",
              }),
          )
        : isPaused
          ? translate(provider.options.paused?.reasonTitle)
          : null;

    const size: SizeName = "M";
    const aspectRatio: AspectRatio = "4:3";
    const dimensions = getDimensions({ aspectRatio, size });
    const homePath = useHomePath(provider);

    return (
        <LinkTo key={provider._id} to={homePath}>
            <FlexColumn>
                <SectionAreaSizedCard
                    aspectRatio={aspectRatio}
                    size={size}
                    background={{
                        color: theme.colors.primary,
                        imageReference: posterImage,
                        fit: "COVER",
                        repeat: "NO-REPEAT",
                    }}>
                    <FlexColumn
                        fillParent
                        tag="SECTION_AREA_SIZED_CARD"
                        shrink={0}
                        grow={1}
                        padding={{ all: 20 }}
                        mainAxis="flex-end"
                        crossAxis="center"
                        background={{
                            linearGradient: posterImage
                                ? darkeningGradient
                                : undefined,
                        }}>
                        <TextBox
                            text={provider.meta.title}
                            color={theme.colors.white}
                            weight="700"
                            align="center"
                            size={"L"}
                            styling={css`
                                text-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
                            `}
                        />
                        <VerticalDivider XXS />
                        {pauseReasonTitle ? (
                            <TextBox
                                text={`(${pauseReasonTitle})`}
                                case="LOWERCASE"
                                color={theme.colors.white.main.light90}
                                weight="700"
                                styling={css`
                                    text-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
                                `}
                            />
                        ) : null}
                    </FlexColumn>
                </SectionAreaSizedCard>
                <FlexColumn width={dimensions.width} padding={{ all: 2 }}>
                    <VerticalDivider />
                    <TextBox
                        lineClamp={2}
                        text={provider.meta.subtitle}
                        color={theme.colors.black}
                        weight="700"
                        size="S"
                    />
                </FlexColumn>
            </FlexColumn>
        </LinkTo>
    );
};

export { ProviderCard };
