import { useMe } from "@@/authentication/me/me-context";
import { browserLogger, useAppSettings } from "@@/settings";
import { generateComboId } from "@towni/common";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Socket, io } from "socket.io-client";
import { useIsAppHidden } from "../visibility/page-visibility.context";

const _defaultSocketEventNames = ["authenticated", "unauthenticated"] as const;
type DefaultSocketEventName = (typeof _defaultSocketEventNames)[number];
type DefaultSocketEventMap = {
    [key in DefaultSocketEventName]: unknown;
};

const WebSocketContext: React.Context<{
    readonly socket: Socket | undefined;
    readonly connected: boolean;
    readonly rooms: Set<string>;
    readonly localConnectionId: string | undefined;
}> = React.createContext<{
    readonly socket: Socket | undefined;
    readonly connected: boolean;
    readonly rooms: Set<string>;
    readonly localConnectionId: string | undefined;
}>({
    socket: undefined,
    connected: false,
    rooms: new Set<string>(),
    localConnectionId: undefined,
});

const WebSocketProvider = (props: {
    children?: React.ReactNode;
    verbose?: boolean;
}) => {
    const [me] = useMe();
    const settings = useAppSettings();
    const pageIsHidden = useIsAppHidden();
    const [rooms, setRooms] = useState<Set<string>>(new Set<string>());
    const [connected, setConnected] = useState<boolean>(false);
    const [localConnectionId, setLocalConnectionId] = useState<
        string | undefined
    >(undefined);
    const [socket, setSocket] = useState<
        Socket<DefaultSocketEventMap> | undefined
    >();

    const oldMe = useRef(me);
    useEffect(() => {
        // When page is hidden, we don't care about socket events
        // And when me is the same as before, and we're already connected,
        // we don't care either
        const sameMeAsBefore = oldMe.current?._id === me?._id;
        if (pageIsHidden || (sameMeAsBefore && !!socket?.connected)) {
            return;
        }

        // Update old me to be current me
        oldMe.current = me;

        // If there are no settings available, we can't do anything
        // they are required to used this provider
        if (!settings) throw new Error("required settings missing");

        // Get the current url to the websocket connection
        const host =
            (typeof import.meta.env.VITE_API_HOST_ENDPOINT === "string" &&
                import.meta.env.VITE_API_HOST_ENDPOINT) ||
            settings.host;

        // Get a socket connection
        const _socket = io(host, {
            withCredentials: true,
            autoConnect: true,
            reconnection: true,
            transports: ["websocket"],
        });

        // Log function for verbose logging
        const verboseLogging = (...args: unknown[]) => {
            browserLogger.log("🔌 ws event", ...args);
        };

        if (props.verbose) {
            // If verbose logging is requested
            // log all events
            _socket.onAny(verboseLogging);
        }

        // When the socket is connected
        // set a local connection id
        // and set connected to true
        const onConnect = (..._args: unknown[]) => {
            browserLogger.log("🔌 ws connected");
            setLocalConnectionId(generateComboId());
            setConnected(true);
        };
        _socket.on("connect", onConnect);

        // When the socket is disconnected
        // set local connection id to undefined
        // and set connected to false
        const onDisconnect = (..._args: unknown[]) => {
            browserLogger.log("🔌 ws disconnected");
            setLocalConnectionId(undefined);
            setConnected(false);
            setRooms(new Set<string>());
        };
        _socket.on("disconnect", onDisconnect);

        // Keep track of registered rooms
        const onRegistration = (data: { regId: string; rooms: string[] }) => {
            setRooms(new Set(data.rooms));
        };
        _socket.on("registered", onRegistration);

        // Set the socket
        // to the socket we just created
        setSocket(_socket);

        // Return a cleanup function
        return () => {
            if (props.verbose) _socket.offAny(verboseLogging);
            _socket.off("connect", onConnect);
            _socket.off("disconnect", onDisconnect);
            _socket.disconnect();
            setSocket(undefined);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me, pageIsHidden, props.verbose, settings]);

    // Memoize the state to make sure
    // we don't cause bad unnecessary re-renders
    const state = React.useMemo(() => {
        return {
            socket,
            connected,
            rooms,
            localConnectionId,
        };
    }, [socket, connected, localConnectionId, rooms]);

    // Wrap the children with the current context
    return (
        <WebSocketContext.Provider value={state}>
            {props.children}
        </WebSocketContext.Provider>
    );
};

export { WebSocketContext, WebSocketProvider };
