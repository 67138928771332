import { PayRequestItemPriceTable } from "@@/pay-requests/pay-request-item-price-table";
import { useProvider } from "@@/providers/state/queries/provider/use-provider";
import { ButtonWhite } from "@@/shared/buttons_v2/button-gray";
import { Conditional } from "@@/shared/conditional";
import {
    HorizontalDivider,
    HorizontalLine,
    VerticalDivider,
} from "@@/shared/dividers";
import { FlexColumn, FlexRow } from "@@/shared/flex-containers";
import { Icon } from "@@/shared/icons/icon";
import { usePageBreakpoint } from "@@/shared/responsiveness/use-page-breakpoint";
import { Paragraph, TextBox, paragraphLineBreakFactory } from "@@/shared/text";
import { DateTimeTextBox } from "@@/shared/text/date-time-text-box";
import { ClassNames, useTheme } from "@emotion/react";
import { faPrint } from "@fortawesome/pro-solid-svg-icons";
import {
    OnPremiseReceiptForCustomer,
    OrderGroup,
    translation,
} from "@towni/common";
import { MutableRefObject, ReactInstance, useMemo, useRef } from "react";
import ReactToPrint from "react-to-print";

type Props = {
    orderGroup: OrderGroup;
    receipt: OnPremiseReceiptForCustomer;
    ref?: MutableRefObject<ReactInstance>;
};

/**
 * Since a regular payment receipt only can be available for
 * orders that are paid for by customer directly, we know that
 * this is the original receipt for the order group.
 * This type of payment can only be made for an order created event.
 * @param props
 * @returns
 */
const OnPremiseReceipt = (props: Props) => {
    const theme = useTheme();
    const isExtraSmall = usePageBreakpoint({ when: "XS" });
    const isMobile = usePageBreakpoint({ when: "📱" });
    const { receipt } = props;
    const [provider, _providerQuery] = useProvider(
        receipt.receiptFor.providerId,
    );
    const items = receipt.items;
    const printRef = useRef(null);
    const textColor = theme.colors.black.light60;

    const title = useMemo(() => {
        const orderCreated = receipt.originTypes.includes("ORDER_CREATED");
        const orderChanged = receipt.originTypes.includes("ORDER_CHANGED");

        if (orderCreated && orderChanged) {
            return translation({
                sv: "Order och orderändring",
                en: "Order and order change",
            });
        }

        if (orderChanged) {
            return translation({
                sv: "Orderändring",
                en: "Order change",
            });
        }

        return translation({
            sv: "Order",
            en: "Order",
        });
    }, [receipt.originTypes]);

    if (receipt.status === "CANCELLED") return null;

    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "0 20px",
            }}>
            <FlexRow
                fillParentWidth
                crossAxis="stretch"
                ref={printRef}
                css={{
                    maxWidth: 700,
                    borderRadius: 20,
                    backgroundColor: theme.colors.white.asString,
                    overflow: "hidden",
                }}>
                <Conditional when={receipt.status === "PENDING"}>
                    <div
                        css={{
                            flex: 1,
                            width: 20,
                            maxWidth: 20,
                            backgroundColor: theme.colors.danger.asString,
                        }}
                    />
                </Conditional>
                <FlexColumn
                    css={{
                        flex: 1,
                        zoom: isMobile ? (isExtraSmall ? 0.6 : 0.8) : 1,
                    }}>
                    {/* Header */}
                    <FlexRow
                        fillParentWidth
                        crossAxis="flex-start"
                        mainAxis="space-between"
                        css={{ opacity: 0.8 }}
                        padding={{ leftRight: 20, top: 30, bottom: 15 }}>
                        <FlexColumn>
                            <TextBox text={title} weight="700" size={1.125} />
                            <VerticalDivider XXS />
                            <DateTimeTextBox
                                dateTime={receipt._createdAt}
                                userSelect="text"
                                weight="700"
                                size={0.9}
                                color={textColor}
                                forceIncludeYear
                            />
                        </FlexColumn>
                        <Conditional when={receipt.status !== "FULFILLED"}>
                            <TextBox
                                size={1.2}
                                css={{
                                    borderRadius: 5,
                                    padding: 15,
                                    paddingLeft: 30,
                                    paddingRight: 30,
                                    backgroundColor:
                                        theme.colors.danger.light.asString,
                                    color: theme.colors.danger.asString,
                                }}
                                weight="700"
                                text={translation({
                                    sv: "Ej betald",
                                    en: "Payment not settled",
                                })}
                            />
                        </Conditional>
                        <Conditional when={receipt.status === "FULFILLED"}>
                            <ButtonWhite
                                css={{ border: "none", paddingRight: 8 }}>
                                <ClassNames>
                                    {content => (
                                        <ReactToPrint
                                            copyStyles={true}
                                            bodyClass={content.css({
                                                overflow: "visible",
                                                height: "auto",
                                                width: "100%",
                                                backgroundColor: "white",
                                                boxSizing: "border-box",
                                                justifyContent: "stretch",
                                                alignItems: "center",
                                                display: "flex",
                                                fontFamily: theme.fontFamily,
                                                fontSize: theme.sizes.base,
                                                letterSpacing: "0.01em",
                                                zoom: 1,
                                                margin: 40,

                                                userSelect: "none",
                                            })}
                                            trigger={() => (
                                                <Icon
                                                    icon={faPrint}
                                                    size={1.1}
                                                    css={{
                                                        "@media print": {
                                                            display: "none",
                                                        },
                                                    }}
                                                />
                                            )}
                                            content={() =>
                                                printRef?.current || null
                                            }
                                        />
                                    )}
                                </ClassNames>
                            </ButtonWhite>
                        </Conditional>
                    </FlexRow>
                    <FlexRow
                        fillParentWidth
                        crossAxis="flex-start"
                        padding={{ leftRight: 20, bottom: 15 }}>
                        <VerticalDivider S />
                        <FlexColumn>
                            <FlexRow crossAxis="flex-start">
                                <TextBox
                                    text={translation({
                                        sv: "Detta är inget kvitto",
                                        en: "This is not a receipt",
                                    })}
                                    case="UPPERCASE"
                                    size={0.725}
                                    weight="900"
                                    color={theme.colors.warning.dark}
                                    css={{
                                        marginLeft: -8,
                                        marginRight: 8,
                                        padding: "4px 8px",
                                        backgroundColor:
                                            theme.colors.warning.light.asString,
                                    }}
                                />
                            </FlexRow>
                            <FlexColumn fillParentWidth crossAxis="stretch">
                                <FlexRow>
                                    <TextBox
                                        text={
                                            receipt.status === "PENDING"
                                                ? translation({
                                                      sv: "Betalning hanteras ej online",
                                                      en: "Payment not handled online",
                                                  })
                                                : translation({
                                                      sv: "Betalning ej hanterad online",
                                                      en: "Payment not handled online",
                                                  })
                                        }
                                        case="UPPERCASE"
                                        size={0.725}
                                        weight="900"
                                        color={theme.colors.warning.dark}
                                        css={{
                                            marginLeft: -8,
                                            marginRight: 8,
                                            padding: "4px 8px",
                                            backgroundColor:
                                                theme.colors.warning.light
                                                    .asString,
                                        }}
                                    />
                                </FlexRow>
                                <Conditional
                                    when={receipt.status === "FULFILLED"}>
                                    <VerticalDivider S />
                                    <Paragraph
                                        css={{
                                            fontSize: "0.9rem",
                                            color: theme.colors.default.text.withAlpha(
                                                0.5,
                                            ).asString,
                                        }}
                                        content={[
                                            translation({
                                                sv: "Du har betalat kontant i kassa tillhörande ",
                                                en: "You have paid cash in ",
                                            }),
                                            provider?.meta.title ??
                                                translation({
                                                    sv: "leverantören",
                                                    en: "the provider",
                                                }),
                                            translation({
                                                sv: ".",
                                                en: ".",
                                            }),
                                            paragraphLineBreakFactory(),
                                            translation({
                                                sv: "Ta kontakt med ",
                                                en: "Contact ",
                                            }),
                                            provider?.meta.title ??
                                                translation({
                                                    sv: "leverantören",
                                                    en: "the provider",
                                                }),
                                            translation({
                                                sv: " vid frågor gällande kvitto.",
                                                en: " if you have any questions regarding receipt.",
                                            }),
                                        ]}
                                    />
                                </Conditional>
                                <Conditional
                                    when={receipt.status === "PENDING"}>
                                    <VerticalDivider S />
                                    <Paragraph
                                        css={{
                                            fontSize: "0.9rem",
                                            color: theme.colors.default.text.withAlpha(
                                                0.5,
                                            ).asString,
                                        }}
                                        content={[
                                            translation({
                                                sv: "Betalning sker kontant direkt till ",
                                                en: "Payment is made in cash directly to ",
                                            }),
                                            provider?.meta.title ??
                                                translation({
                                                    sv: "leverantören",
                                                    en: "the provider",
                                                }),
                                            translation({
                                                sv: ".",
                                                en: ".",
                                            }),
                                            paragraphLineBreakFactory(),
                                            translation({
                                                sv: "Ta kontakt med ",
                                                en: "Contact ",
                                            }),
                                            provider?.meta.title ??
                                                translation({
                                                    sv: "leverantören",
                                                    en: "the provider",
                                                }),
                                            translation({
                                                sv: " för att ordna med betalning.",
                                                en: " to arrange payment.",
                                            }),
                                        ]}
                                    />
                                </Conditional>
                            </FlexColumn>
                        </FlexColumn>
                        <HorizontalDivider />
                    </FlexRow>
                    <FlexRow fillParentWidth padding={{ topBottom: 10 }}>
                        <HorizontalLine fillParentWidth />
                    </FlexRow>
                    <FlexColumn fillParentWidth>
                        <VerticalDivider S />
                        <FlexColumn
                            fillParentWidth
                            css={{ paddingLeft: 20, paddingRight: 20 }}>
                            <PayRequestItemPriceTable
                                items={items}
                                payRequestPrice={receipt.amount}
                            />
                        </FlexColumn>
                        <VerticalDivider L />
                    </FlexColumn>
                </FlexColumn>
            </FlexRow>
        </div>
    );
};

export { OnPremiseReceipt };
