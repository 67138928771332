import { shortId } from "@towni/common";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import * as ReactDOM from "react-dom";
import { useToastContext } from "./context/toast-context";
import { Toast } from "./toast";

const ToastContainer = () => {
    const [mountId] = useState(() => shortId());
    const activeToasts = useToastContext(context => context.activeToasts);
    const toasts = activeToasts.map(item => {
        return <Toast key={item._id} toast={item} />;
    });

    const output = (
        <AnimatePresence mode="wait">
            <motion.div
                key={mountId}
                style={{
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: 30,
                    paddingBottom: 10,
                    paddingLeft: 40,
                    paddingRight: 40,
                }}
                layout>
                <AnimatePresence mode="popLayout">{toasts}</AnimatePresence>
            </motion.div>
        </AnimatePresence>
    );

    const portalElement = document.getElementById("toasts");

    if (!portalElement)
        throw new Error('Missing required "toasts"-portal in html');
    return ReactDOM.createPortal(output, portalElement);
};

export { ToastContainer };
