import {
    TanstackQueryOptions,
    useTanstackMultiQuery,
    useTanstackSingleQuery,
} from "@@/shared/use-tanstack-query";
import { ProviderId, ResourceMap, ResourceMapId } from "@towni/common";
import { resourceMapMainQueryKey } from "./resource-map-main-query-key";

const useResourceMapQuery = (
    resourceMapId: ResourceMapId | undefined,
    options?: TanstackQueryOptions<ResourceMap>,
) =>
    useTanstackSingleQuery<ResourceMap>(
        `/resources/maps/${encodeURIComponent(resourceMapId ?? "unknown")}`,
        [resourceMapMainQueryKey, resourceMapId],
        { enabled: !!resourceMapId, ...(options ?? {}) },
    );

const useResourceMapsQuery = (
    providerId: ProviderId,
    options?: TanstackQueryOptions<ResourceMap[]>,
) =>
    useTanstackMultiQuery<ResourceMap>(
        `/resources/maps/for-provider/${encodeURIComponent(providerId)}`,
        [resourceMapMainQueryKey, providerId],
        { enabled: !!providerId, ...(options ?? {}) },
    );

export { useResourceMapQuery, useResourceMapsQuery };
