import { AppTheme } from "@@/styles/theme";
import { customThinScrollbarCss } from "@@/styles/themes/custom-thin-scrollbars";
import { Interpolation } from "@emotion/react";
import { setWithinRange } from "@towni/common";
import debounce from "lodash.debounce";
import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import { FlexColumn, FlexRow } from "../flex-containers";
import { ForEach } from "../for-each";
import { useScrollWidth } from "../use-scroll-width";
import {
    HzScrollNavButton,
    type HzScrollNavButtonType,
} from "./hx-scroll-nav-button";
import type { HzScrollGap } from "./hz-scroll-gap";

type Props = {
    readonly className?: string;
    readonly items: JSX.Element[];
    readonly numberOfItemsPending?: number;
    readonly gap?: HzScrollGap;
    readonly itemContainerCss?: Interpolation<AppTheme>;
    /** defaults to small */
    readonly navButtonType?: HzScrollNavButtonType;
    readonly startIndex?: number;
    onIndexChange?: (index: number) => void;
};

const getWidthOfElement = (container: HTMLDivElement | null | undefined) => {
    if (container) {
        const computedStyle = window.getComputedStyle(container);
        const scrollAmount = parseFloat(computedStyle.width);
        return scrollAmount;
    }
    return 0;
};

const minHeight = 50;

const HzScrollContainer = (props: Props) => {
    /** remember, to get loaded they also have to be rendered below in the scroll container */
    const _isPending =
        typeof props.numberOfItemsPending === "number" &&
        props.numberOfItemsPending > 0;
    const navButtonType = props.navButtonType ?? "small";
    const containerRef = useRef<HTMLDivElement | null>(null);
    const leftButton = useRef<HTMLDivElement | null>(null);
    const rightButton = useRef<HTMLDivElement | null>(null);
    const [isScrollable, setIsScrollable] = useState(true);
    const [isScrollableToRight, setIsScrollableToRight] = useState(false);
    const [isScrollableToLeft, setIsScrollableToLeft] = useState(false);
    const [itemWidth, setItemWidth] = useState(0);
    const [scrollViewWidth, setScrollViewWidth] = useState(0);
    const [scrollAmountPerItem, setScrollAmountPerItem] = useState(0);
    const dividingGap =
        typeof props.gap === "number"
            ? props.gap
            : (props.gap?.dividingGap ?? 0);
    const startGap =
        typeof props.gap === "number" ? props.gap : (props.gap?.startGap ?? 0);
    const endGap =
        typeof props.gap === "number" ? props.gap : (props.gap?.endGap ?? 0);
    const bottomGap =
        typeof props.gap === "number" ? props.gap : (props.gap?.bottomGap ?? 0);

    const [currentIndex, setCurrentIndex] = useState(props.startIndex ?? 0);
    const onNavigateLeft = useCallback(() => {
        setCurrentIndex(prev =>
            setWithinRange(prev - 1, { min: 0, max: props.items.length - 1 }),
        );
    }, [props.items.length]);
    const onNavigateRight = useCallback(() => {
        setCurrentIndex(prev =>
            setWithinRange(prev + 1, { min: 0, max: props.items.length - 1 }),
        );
    }, [props.items.length]);
    useEffect(() => {
        props.onIndexChange?.(currentIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentIndex, props.onIndexChange]);

    const [scrollWidthRef, scrollWidth] = useScrollWidth();
    const [scrollContainerElement, setScrollContainerElement] =
        useState<HTMLDivElement | null>(null);
    const setScrollContainerRef = useCallback(
        (element: HTMLDivElement | null) => {
            setScrollContainerElement(element);
            scrollWidthRef.current = element;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const itemRefs = useRef<Map<number, HTMLDivElement | null>>(new Map());
    const setItemRef = useCallback(
        (element: HTMLDivElement | null, index: number) => {
            itemRefs.current.set(index, element);
            if (!element) return;
            setItemWidth(getWidthOfElement(element));
        },
        [],
    );
    useLayoutEffect(() => {
        setScrollAmountPerItem(itemWidth + dividingGap);
    }, [dividingGap, itemWidth]);

    useLayoutEffect(() => {
        const _scrollContainerElement = scrollContainerElement;
        if (!_scrollContainerElement) return;
        setScrollViewWidth(getWidthOfElement(_scrollContainerElement));
        const scrollCheck = (element: HTMLDivElement | null) => {
            const isScrollable =
                !!element && element.scrollWidth > element.clientWidth;
            const isScrollableToRight =
                isScrollable &&
                element.scrollWidth - element.scrollLeft > element.clientWidth;
            const isScrollableToLeft = isScrollable && element.scrollLeft > 0;

            setIsScrollable(isScrollable);
            setIsScrollableToRight(isScrollableToRight);
            setIsScrollableToLeft(isScrollableToLeft);

            return {
                isScrollable,
                isScrollableToRight,
                isScrollableToLeft,
            };
        };

        const calculateAndSetCurrentIndex = (element: HTMLDivElement) => {
            const newIndex = Math.round(element.scrollLeft / itemWidth);
            setCurrentIndex(newIndex);
        };
        const onScroll = debounce((scrollEvent: Event) => {
            const target: HTMLDivElement = scrollEvent.target as HTMLDivElement;
            scrollCheck(target);
            calculateAndSetCurrentIndex(_scrollContainerElement);
        }, 100);

        _scrollContainerElement.addEventListener("scroll", onScroll);
        const scrollCheckResult = scrollCheck(_scrollContainerElement);

        if (!scrollCheckResult.isScrollable) return;
        const startPosX = scrollAmountPerItem * (props.startIndex ?? 0);
        if (_scrollContainerElement.scrollLeft !== startPosX) {
            _scrollContainerElement.scrollBy({
                left: startPosX,
                behavior: "instant",
            });
            _scrollContainerElement.scrollLeft = startPosX;
        }

        return () => {
            _scrollContainerElement?.removeEventListener("scroll", onScroll);
        };
    }, [
        itemWidth,
        props.startIndex,
        scrollAmountPerItem,
        scrollContainerElement,
        scrollWidth,
    ]);

    return (
        <div
            ref={containerRef}
            className={props.className}
            onClick={event => {
                event.stopPropagation();
            }}
            css={{
                width: "100%",
                height: "100%",
                minHeight,
                position: "relative",
            }}>
            <FlexColumn
                mainAxis="stretch"
                css={{ width: "100%", height: "100%" }}>
                <FlexRow
                    ref={setScrollContainerRef}
                    crossAxis="stretch"
                    mainAxis={isScrollable ? "flex-start" : "center"}
                    css={[
                        {
                            flex: 1,
                            overflowX: "auto",
                            width: "100%",
                            height: "100%",
                            overflowY: "hidden",
                            scrollSnapType: "x mandatory",
                        },
                        customThinScrollbarCss,
                    ]}>
                    <ForEach
                        itemOf={props.items}
                        getKey={(item, index) => item.key || index}>
                        {(item, index) => {
                            const isFirst = index === 0;
                            const isLast = index === props.items.length - 1;
                            return (
                                <FlexColumn
                                    key={item.key}
                                    ref={element => {
                                        setItemRef(element, index);
                                    }}
                                    css={[
                                        {
                                            marginLeft: isFirst
                                                ? startGap
                                                : dividingGap,
                                            marginRight: isLast ? endGap : 0,
                                            scrollSnapAlign: "start",
                                            flex: "none",
                                            width: "100%",
                                            height: "100%",
                                            minHeight,
                                            marginBottom: bottomGap,
                                        },
                                        props.itemContainerCss,
                                    ]}>
                                    {item}
                                </FlexColumn>
                            );
                        }}
                    </ForEach>
                </FlexRow>
            </FlexColumn>
            <FlexColumn
                mainAxis="center"
                ref={leftButton}
                css={{
                    position: "absolute",
                    zIndex: 100,
                    top: 0,
                    bottom: 0,
                    left: 10,
                    transition: "opacity 0.2s",
                    opacity: isScrollableToLeft ? 1 : 0,
                    height: "100%",
                }}>
                <HzScrollNavButton
                    direction="left"
                    type={navButtonType}
                    scrollContainer={scrollContainerElement}
                    scrollAmountPerItem={scrollAmountPerItem}
                    isScrollableToRight={isScrollableToRight}
                    scrollViewWidth={scrollViewWidth}
                    itemWidth={itemWidth}
                    bottomGap={bottomGap}
                    onClick={onNavigateLeft}
                />
            </FlexColumn>
            <FlexColumn
                mainAxis="center"
                ref={rightButton}
                css={{
                    position: "absolute",
                    zIndex: 100,
                    top: 0,
                    bottom: 0,
                    right: 10,
                    transition: "opacity 0.2s",
                    opacity: isScrollableToRight ? 1 : 0,
                    height: "100%",
                }}>
                <HzScrollNavButton
                    direction="right"
                    type={navButtonType}
                    scrollContainer={scrollContainerElement}
                    scrollAmountPerItem={scrollAmountPerItem}
                    isScrollableToRight={isScrollableToRight}
                    scrollViewWidth={scrollViewWidth}
                    itemWidth={itemWidth}
                    bottomGap={bottomGap}
                    onClick={onNavigateRight}
                />
            </FlexColumn>
        </div>
    );
};

export { HzScrollContainer };
